import { ConfirmationModal, HeaderLabel, StepProgress } from 'components';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, { useCallback, useState, useMemo } from 'react';
import PerkembanganAnggota from './PerkembanganAnggota';
import Layanan from './Layanan';
import { useNavigate } from 'react-router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth } from 'hooks/auth';
import {
  getChannelingDirectApplicationDebtorAccess,
  updateChannelingDirectApplicationDebtorAccess,
  updateDraftChannelingDirectApplicationDebtorAccess,
} from 'services';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage, convertCurrencyToFloat } from 'helpers';

const breadCrumbs = [
  {
    label: 'Daftar Permohonan',
    path: '/firma',
  },
  {
    label: 'Daftar Permohonan Baru',
    path: '/firma/supplier-application',
  },
  {
    label: 'Formulir Permohonan',
  },
];

const stepProgress = [
  {
    label: 'Perkembangan Anggota',
    step: 1,
  },
  {
    label: 'Layanan',
    step: 2,
  },
];

const DebiturAksesForm = () => {
  const navigate = useNavigate();
  const { id } = useAuth();

  const { data } = useQuery(['supplier-application-debtor-access'], () =>
    getChannelingDirectApplicationDebtorAccess(id)
  );

  console.log(data);

  const [activeStep, setActiveStep] = useState(1);
  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  const onBack = useCallback(() => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, navigate]);

  const onDraft = useCallback((payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));
    setDraftModal(true);
  }, []);

  const onSubmit = useCallback(
    (payload) => {
      setBodyToSend((state) => ({ ...state, ...payload }));
      if (activeStep < stepProgress.length) {
        setActiveStep(activeStep + 1);
      } else {
        setConfirmModal(true);
      }
    },
    [activeStep]
  );

  const params = useMemo(() => {
    if (!bodyToSend) return {};

    const startYear = dayjs(bodyToSend.first_year).format('YYYY');
    const body = new FormData();
    const members = bodyToSend.anggota;
    const services = bodyToSend.produk_layanan;
    members?.forEach((item, index) => {
      body.append(`member[${index}][year]`, +startYear + +index);
      body.append(
        `member[${index}][amount_of_member_entered]`,
        item.anggota_masuk
      );
      body.append(
        `member[${index}][amount_of_member_out]`,
        item.anggota_keluar
      );
      body.append(`member[${index}][amount_of_member]`, item.total_anggota);
      body.append(
        `member[${index}][precentage_previous_year]`,
        item.presentase_tahun_sebelumnya
      );
    });
    services?.forEach((item, index) => {
      body.append(`service[${index}][type]`, item.jenis_layanan);
      body.append(`service[${index}][name]`, item.nama_layanan);
      body.append(
        `service[${index}][income]`,
        convertCurrencyToFloat(item.pendapatan)
      );
      body.append(`service[${index}][descrifirmaion]`, item.keterangan);
    });
    return body;
  }, [bodyToSend]);

  const onError = useCallback((error) => {
    enqueueSnackbar(getErrorMessage(error), {
      variant: 'error',
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar('Data Berhasil Disimpan!', {
      variant: 'success',
    });
    navigate('/firma/supplier-application');
  }, [navigate]);

  const { mutate: updateDebtor } = useMutation({
    mutationFn: () => {
      return updateChannelingDirectApplicationDebtorAccess(id, params);
    },
    onError,
    onSuccess,
  });

  const { mutate: updateDraftDebtor } = useMutation({
    mutationFn: () => {
      return updateDraftChannelingDirectApplicationDebtorAccess(id, params);
    },
    onError,
    onSuccess,
  });

  const onCreate = () => {
    updateDebtor();
  };

  const onCreateDraft = () => {
    updateDraftDebtor();
  };

  return (
    <div>
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onCreate}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        open={draftModal}
        onClose={setDraftModal}
        onSubmit={onCreateDraft}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan menyimpan data ini sebagai draft?
        </div>
        <div className="text-sm font-[400]">
          Setelah Anda menyimpan data sebagai draft anda masih bisa
          melanjutkannya.
        </div>
      </ConfirmationModal>

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-36">
        <BreadCrumbs routes={breadCrumbs} />

        <div className="flex">
          <HeaderLabel
            text="Formulir Permohonan"
            descrifirmaion="Buat permohonan baru."
          />
        </div>

        <div className="border-b" />

        <div className="w-full flex justify-center">
          <div className="w-[800px] mb-10">
            <StepProgress
              stepProgressData={stepProgress}
              activeStep={activeStep}
            />
          </div>
        </div>

        {activeStep === 1 && (
          <PerkembanganAnggota
            prefix={'perkembangan_anggota'}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
        {activeStep === 2 && (
          <Layanan
            prefix={'layanan'}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
      </div>
    </div>
  );
};

export default DebiturAksesForm;
