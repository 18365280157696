import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const submitSafeguards = (params) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/safeguards/submit`,
    params,
    {
      headers,
    }
  );
};

export const getSafeguards = (idUser) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/safeguards/findByUserId/${idUser}`,
    { headers }
  );
};
