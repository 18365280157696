import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Button, InputForm, InputFormUpload, Table } from "components";
import CardForm from "components/molecules/CardForm";
import { AnimatePresence, motion } from "framer-motion";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  RiCloseLine,
  RiDeleteBin5Line,
  RiDownloadCloud2Line,
  RiFile2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { getFile } from "services/danaProgram/fileService";
import { postFileServices } from "services/fdb/akunKelompok";
import { deleteDocumentDeferred } from "services/fdb/permohonan-pembiayaan";
import * as yup from "yup";

export const requiredDocsTT = [
  {
    title:
      "Dokumen ijin penguasaan lahan (Letter C/Surat Girik/Sertifikat/Lainnya)",
    desc: "Dokumen ijin penguasaan lahan (Letter C/Surat Girik/Sertifikat/Lainnya)",
    key: "ijin-penguasaan-lahan",
  },
  {
    title:
      "Dokumen pendukung penguasaan lahan (surat keterangan riwayat tanah)",
    desc: "Dokumen pendukung penguasaan lahan (surat keterangan riwayat tanah)",
    key: "ket-riwayat-tanah",
  },
  {
    title:
      "Dokumen pendukung penguasaan lahan (surat pernyataan jual beli/hibah/waris)",
    desc: "Dokumen pendukung penguasaan lahan (surat pernyataan jual beli/hibah/waris)",
    key: "pernyataan-jual-beli",
  },
  {
    title: "Dokumen pendukung penguasaan lahan (SPPT)",
    desc: "Dokumen pendukung penguasaan lahan (SPPT)",
    key: "sppt",
  },
  {
    title: "Foto Lahan Debitur",
    desc: "Foto Lahan Debitur",
    key: "foto-lahan-debitur",
  },
];

const DocSection = ({ mode }) => {
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "document",
  });

  const mutateFileService = useMutation(["File Service"], postFileServices);
  const mutateDeleteDocument = useMutation(
    ["DELETE DOCUMENT DEBITUR DEFERRED"],
    ({ id }) => deleteDocumentDeferred(id)
  );

  const handleUpload = useCallback(
    (field, files, index) => {
      const formData = new FormData();

      formData.append("file", files[0]);

      mutateFileService.mutate(formData, {
        onSuccess: (res) => {
          enqueueSnackbar({
            message: "Dokumen Berhasil Disimpan!",
            variant: "success",
          });
          methods.setValue(`document[${index}].file`, res.data.data.id);
          field.onChange(res.data.data);
        },
        onError: (err) => {
          enqueueSnackbar({
            message: "Dokumen Gagal Disimpan!",
            variant: "failed",
          });
        },
      });
    },
    [mutateFileService, methods]
  );

  const handleDelete = useCallback(
    (field, index) => {
      if (field.id) {
        mutateDeleteDocument.mutate(
          { id: field.id },
          { onSuccess: (res) => remove(index) }
        );
      }
      remove(index);
    },
    [mutateDeleteDocument, remove]
  );

  const handleMountingFile = useCallback(async () => {
    const docs = methods.getValues("document");

    for (let i = 0; i < docs.length; i++) {
      if (
        !docs[i].id ||
        typeof docs[i].selected_file === "object" ||
        docs[i].selected_file === "" ||
        typeof docs[i].selected_file === "undefined"
      ) {
        continue;
      }

      const res = await getFile(docs[i].selected_file);

      methods.setValue(`document[${i}].file`, res.data.data.id);
      methods.setValue(`document[${i}].selected_file`, res.data.data);
    }
  }, [methods]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "no",
        dataIndex: "#",
        columnClassName: "w-[50px]",
        render: (_, __, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        id: "doc",
        title: "document",
        dataIndex: "doc",
        columnClassName: "",
        render: (_, data, index) => {
          return (
            <div className="flex flex-col">
              <p className="text-sm font-bold">
                {data.desc} <span className="text-sm text-red-600">*</span>
              </p>
            </div>
          );
        },
      },
      {
        id: "doc",
        title: "Deskripsi",
        dataIndex: "doc",
        columnClassName: "",
        render: (_, data, index) => {
          return (
            <div className="flex gap-2 items-center text-blue-500 cursor-pointer">
              <RiDownloadCloud2Line size={20} />
              <p className="text-sm font-semibold">Unduh Dokumen Rujukan</p>
            </div>
          );
        },
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        columnClassName: "w-min",
        fixed: "right",
        render: (_, data, index) => {
          return (
            <div className="flex flex-col">
              <Controller
                control={methods.control}
                name={`document[${index}].selected_file`}
                render={({ field }) => {
                  const doc = methods.watch(`document[${index}]`);

                  return (
                    <div className="flex flex-col gap-2" key={field.id}>
                      {(typeof doc.selected_file === "undefined" ||
                        typeof doc.selected_file !== "object") && (
                        <label
                          htmlFor={`upload_files${index}`}
                          className="flex gap-2 items-center text-primary-700"
                          type="button"
                        >
                          <input
                            type="file"
                            name=""
                            id={`upload_files${index}`}
                            className="hidden"
                            onChange={(e) => {
                              handleUpload(field, e.target.files, index);
                            }}
                          />
                          <RiUploadCloud2Line size={20} />
                          <h5 className="text-sm font-semibold">Upload</h5>
                        </label>
                      )}

                      {typeof doc.selected_file === "object" && (
                        <div className="flex flex-col gap-3">
                          <div className="flex gap-2 items-center">
                            <div className="p-2 bg-primary-100 rounded-full text-primary-700">
                              <RiFile2Line size={18} />
                            </div>
                            <p className="whitespace-nowrap text-sm font-medium">
                              {doc?.selected_file?.name}
                            </p>
                          </div>

                          {!isView && (
                            <div className="flex gap-4">
                              {index > 4 ? (
                                <button
                                  className="flex gap-1 items-center text-red-700 font-semibold text-sm"
                                  onClick={() => handleDelete(doc, index)}
                                  type="button"
                                >
                                  <RiDeleteBin5Line size={20} />
                                  <p>Delete</p>
                                </button>
                              ) : null}

                              <label
                                htmlFor="upload_file"
                                className="flex gap-2 items-center text-primary-700"
                                type="button"
                              >
                                <input
                                  type="file"
                                  name=""
                                  id="upload_file"
                                  className="hidden"
                                  onChange={(e) => {
                                    handleUpload(field, e.target.files, index);
                                  }}
                                />
                                <RiUploadCloud2Line size={20} />
                                <h5 className="text-sm font-semibold">
                                  Upload
                                </h5>
                              </label>
                            </div>
                          )}
                        </div>
                      )}
                      <p>Tipe File : PDF ; Max Size : 5mb</p>
                    </div>
                  );
                }}
              />
            </div>
          );
        },
      },
    ];
  }, [methods, handleUpload, handleDelete, isView]);

  useEffect(() => {
    handleMountingFile();
  }, [handleMountingFile]);

  const [isUploadOtherDoc, setIsUploadOtherDoc] = useState(false);

  const handleAddOtherDoc = (payload) => {
    const formData = new FormData();

    formData.append("file", payload.selected_file);

    mutateFileService.mutate(formData, {
      onSuccess: (res) => {
        enqueueSnackbar({
          message: "Dokumen Berhasil Disimpan!",
          variant: "success",
        });

        const otherDoc = {
          id: null,
          name: payload.selected_file.name,
          desc: payload.desc,
          key: "OTHERDOC",
          file: res.data.data.id,
          selected_file: res.data.data,
        };

        append(otherDoc);
        setIsUploadOtherDoc(false);

        // field.onChange(res.data.data);
      },
      onError: (err) => {
        enqueueSnackbar({
          message: "Dokumen Gagal Disimpan!",
          variant: "error",
        });
      },
    });
  };

  return (
    <section>
      {createPortal(
        <UploadOtherDocumentModal
          open={isUploadOtherDoc}
          onClose={() => setIsUploadOtherDoc(false)}
          onSubmitForm={handleAddOtherDoc}
        />,
        document.body
      )}

      <CardForm label="Dokumen Pendukung">
        <div className="flex justify-between">
          <h5 className="text-lg font-semibold text-primary-700">
            Daftar Dokumen
          </h5>
          {!isView && (
            <Button
              className="bg-primary-600 text-white"
              label={
                <div className="flex gap-2 items-center">
                  <RiUploadCloud2Line size={20} />
                  <h5>Upload Dokumen Lainnya</h5>
                </div>
              }
              type="button"
              onClick={() => setIsUploadOtherDoc(true)}
            />
          )}
        </div>

        <Table
          columns={tableColumns}
          dataSource={fields}
          withPagination={false}
        />
      </CardForm>
    </section>
  );
};

export default DocSection;

const otherSchemaValidation = yup.object({
  name: yup.string().required("Nama dokumen lainnya harus diisi"),
  selected_file: yup.string().required("Dokumen harus diisi"),
  desc: yup.string().required("Deskripsi harus diisi"),
});

const UploadOtherDocumentModal = (props) => {
  const methods = useForm({
    resolver: yupResolver(otherSchemaValidation),
    mode: "onChange",
  });

  const handleClose = () => {
    methods.reset();

    props.onClose();
  };

  const onSubmit = () => {
    const payload = methods.getValues();

    props.onSubmitForm(payload);

    methods.reset();
  };

  return (
    <AnimatePresence mode="wait">
      {props.open && (
        <div className="h-screen w-full bg-black/70 z-50 fixed inset-0 overflow-y-hidden flex justify-center items-center">
          <motion.div
            key="modal-confirmation"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="flex flex-col gap-5 px-8 py-6 bg-white rounded-xl min-w-[400px] shadow-md"
          >
            {/* HEADER */}
            <div className="flex items-center">
              <h5 className="flex flex-grow text-lg font-semibold">
                Upload Dokumen Lainnya
              </h5>
              <RiCloseLine
                size={24}
                className="text-gray-500 cursor-pointer"
                onClick={handleClose}
              />
            </div>

            {/* CONTENT */}
            <FormProvider {...methods}>
              <div className="flex flex-col space-y-5">
                <InputForm
                  label="Nama Dokumen Lainnya"
                  controllerName="name"
                  className="w-full"
                  required
                />

                <InputFormUpload
                  controllerName="selected_file"
                  label="Dokumen Lainnya"
                />

                <div className="flex flex-col gap-1">
                  <InputForm
                    label="Deskripsi"
                    controllerName="desc"
                    className="w-full h-[100px]"
                    required
                  />
                </div>
              </div>

              <div className="flex gap-3">
                <Button
                  label="Batal"
                  className="flex-grow"
                  outline
                  onClick={handleClose}
                  type="button"
                />
                <Button
                  label="Submit"
                  className="flex-grow bg-primary-600 hover:bg-primary-700 text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </FormProvider>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

// import { InputFormUpload } from "components";
// import CardForm from "components/molecules/CardForm";
// import React, {
//   forwardRef,
//   useCallback,
//   useEffect,
//   useImperativeHandle,
//   useMemo,
//   useState,
// } from "react";
// import { createPortal } from "react-dom";
// import {
//   Controller,
//   useFieldArray,
//   useFormContext,
//   useWatch,
// } from "react-hook-form";
// import {
//   RiDeleteBin2Line,
//   RiDeleteBin5Line,
//   RiDownloadCloud2Line,
//   RiFile2Line,
//   RiUploadCloud2Line,
// } from "react-icons/ri";
// import * as Yup from "yup";
// import { DOC_UPLOAD_EXT, MAX_DOC_UPLOAD } from "../../../../../app/config";
// import Button from "../../../../../components/atoms/Button";
// import Tag from "../../../../../components/atoms/Tag";
// import UploadDocModal from "../../../../../components/molecules/Modal/UploadDocModal";
// import { humanizeFileSize } from "../../../../../helpers";
// import {
//   isFileSize,
//   isFileTypeMatches,
// } from "../../../../../helpers/validation";
// import {
//   validationCustomErrorMsg,
//   validationErrorMsg,
// } from "../../../../../locale/yup_id";
// import { FieldLabel } from "../FormKomoditasNonKehutanan/docsSection";
// import { getFile } from "services/danaProgram/fileService";
// import { enqueueSnackbar } from "notistack";
// import { useMutation } from "@tanstack/react-query";
// import { postFileServices } from "services/fdb/akunKelompok";

// export const requiredDocsTT = [
//   {
//     title:
//       "Dokumen ijin penguasaan lahan (Letter C/Surat Girik/Sertifikat/Lainnya)",
//     desc: "Dokumen ijin penguasaan lahan (Letter C/Surat Girik/Sertifikat/Lainnya)",
//     key: "ijin-penguasaan-lahan",
//   },
//   {
//     title:
//       "Dokumen pendukung penguasaan lahan (surat keterangan riwayat tanah)*",
//     desc: "Dokumen pendukung penguasaan lahan (surat keterangan riwayat tanah)*",
//     key: "ket-riwayat-tanah",
//   },
//   {
//     title:
//       "Dokumen pendukung penguasaan lahan (surat pernyataan jual beli/hibah/waris)*",
//     desc: "Dokumen pendukung penguasaan lahan (surat pernyataan jual beli/hibah/waris)*",
//     key: "pernyataan-jual-beli",
//   },
//   {
//     title: "Dokumen pendukung penguasaan lahan (SPPT)*",
//     desc: "Dokumen pendukung penguasaan lahan (SPPT)*",
//     key: "sppt",
//   },
//   {
//     title: "Foto Lahan Debitur*",
//     desc: "Foto Lahan Debitur*",
//     key: "foto-lahan-debitur",
//   },
// ];

// export const validationSchema = Yup.object().shape({
//   docs: Yup.array()
//     .of(
//       Yup.object().shape({
//         title: Yup.string(),
//         file: Yup.mixed()
//           .test("ktp", validationErrorMsg.mixed.required, (value, context) => {
//             const allowedTypes = DOC_UPLOAD_EXT;

//             // if has value, validate the file
//             if (value) {
//               // validate file type by extension
//               const v1 = isFileTypeMatches({ ext: allowedTypes })(
//                 value,
//                 context
//               );
//               if (!v1) {
//                 return context.createError({
//                   message: validationCustomErrorMsg.isFiletype(
//                     allowedTypes.join(", ")
//                   )({ label: FieldLabel.FILE }),
//                 });
//               }

//               // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
//               const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
//               if (!v2) {
//                 return context.createError({
//                   message: validationCustomErrorMsg.isFileSize(
//                     humanizeFileSize(MAX_DOC_UPLOAD)
//                   )({
//                     label: FieldLabel.FILE,
//                   }),
//                 });
//               }

//               return true;
//             }

//             // otherwise, return false to render the field required
//             return false;
//           })
//           .required()
//           .label(FieldLabel.FILE),
//         desc: Yup.string(),
//       })
//     )
//     .test("docs", validationErrorMsg.mixed.required, (value, context) => {
//       // check only first 4 values, as it is the required ones and set by default
//       const required = value?.slice(0, requiredDocsTT.length);

//       if (required?.length) {
//         const valid =
//           required.filter((e) => !!e?.file)?.length === requiredDocsTT.length;
//         return valid;
//       }

//       return true;
//     })
//     .required()
//     .label(FieldLabel.DOCS),
// });

// const DocsSection = forwardRef(({ formPrefix, ...props }, ref) => {
//   const [uploadModal, setUploadModal] = useState(false);
//   const [triggerValCount, setTriggerValCount] = useState(0);
//   const [formInit, setFormInit] = useState(false);

//   const prefix = formPrefix ? `${formPrefix}.` : "";

//   const methods = useFormContext();

//   const { fields, append, remove } = useFieldArray({
//     control: methods.control,
//     name: `${prefix}docs`,
//   });
//   const watchFieldArray = useWatch({
//     control: methods.control,
//     name: `${prefix}docs`,
//   });
//   const controlledFields = fields.map((field, index) => {
//     return {
//       ...field,
//       ...watchFieldArray[index],
//     };
//   });

//   useImperativeHandle(ref, () => ({
//     getFormValues() {
//       return methods.getValues();
//     },
//   }));

//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: "smooth" });

//     // mark the form as finished initializing
//     setFormInit(true);
//   }, []);

//   useEffect(() => {
//     if (formInit) {
//       // only trigger validation when array field value changes
//       triggerValidation();
//     }
//   }, [watchFieldArray, watchFieldArray]);

//   const triggerValidation = async () => {
//     await methods.trigger(`${prefix}docs`);

//     // trigger rerender to update array field form state to
//     // as running `methods.trigger()` does not seem to trigger rerender
//     setTriggerValCount(triggerValCount + 1);
//   };

//   const onUploadMoreDocClick = (e) => {
//     e?.preventDefault();

//     setUploadModal(true);
//   };

//   const onRowDownloadClick = (e) => {
//     e.preventDefault();
//   };

//   const onRowDeleteClick = (index) => (e) => {
//     e.preventDefault();
//     remove(index);
//   };

//   const doUpload = (data) => {
//     append(data);
//     setUploadModal(false);
//   };

//   const mutateFileService = useMutation(["File Service"], postFileServices);

//   const handleUpload = useCallback(
//     (field, files, index) => {
//       const formData = new FormData();

//       formData.append("file", files[0]);

//       mutateFileService.mutate(formData, {
//         onSuccess: (res) => {
//           enqueueSnackbar({
//             message: "Dokumen Berhasil Disimpan!",
//             variant: "success",
//           });
//           field.onChange(res.data.data);
//         },
//         onError: (err) => {
//           enqueueSnackbar({
//             message: "Dokumen Gagal Disimpan!",
//             variant: "failed",
//           });
//         },
//       });
//     },
//     [mutateFileService]
//   );

//   // !!!

//   const handleDelete = useCallback((field) => {
//     field.onChange("");
//   }, []);

//   const handleMountingFile = useCallback(async () => {
//     const docs = methods.getValues("docs.docs");

//     for (let i = 0; i < docs.length; i++) {
//       if (
//         !docs[i].id ||
//         typeof docs[i].selected_file === "object" ||
//         docs[i].selected_file === "" ||
//         docs[i].selected_file === "undefined"
//       ) {
//         continue;
//       }

//       const res = await getFile(docs[i].selected_file);

//       methods.setValue(`docs.docs[${i}].selected_file`, res.data.data);
//     }
//   }, [methods]);

//   const tableColumns = useMemo(() => {
//     return [
//       {
//         id: "no",
//         title: "no",
//         dataIndex: "#",
//         columnClassName: "w-[50px]",
//         render: (_, __, index) => {
//           return <div>{index + 1}</div>;
//         },
//       },
//       {
//         id: "document",
//         title: "document",
//         dataIndex: "document",
//         columnClassName: "",
//         render: (_, data, index) => {
//           return (
//             <div className="flex flex-col">
//               <p className="text-sm font-bold">
//                 {data.desc} <span className="text-sm text-red-600">*</span>
//               </p>
//             </div>
//           );
//         },
//       },
//       {
//         id: "document",
//         title: "Deskripsi",
//         dataIndex: "document",
//         columnClassName: "",
//         render: (_, data, index) => {
//           return (
//             <div className="flex gap-2 items-center text-blue-500 cursor-pointer">
//               <RiDownloadCloud2Line size={20} />
//               <p className="text-sm font-semibold">Unduh Dokumen Rujukan</p>
//             </div>
//           );
//         },
//       },
//       {
//         id: "actions",
//         title: "Aksi",
//         dataIndex: "actions",
//         columnClassName: "w-min",
//         fixed: "right",
//         render: (_, data, index) => {
//           return (
//             <div className="flex flex-col">
//               <Controller
//                 control={methods.control}
//                 name={`docs.docs[${index}].selected_file`}
//                 render={({ field }) => {
//                   const doc = methods.watch(`docs.docs[${index}]`);
//                   return (
//                     <div className="flex flex-col gap-2">
//                       {(typeof doc.selected_file === "undefined" ||
//                         typeof doc.selected_file !== "object") && (
//                         <label
//                           htmlFor="upload_files"
//                           className="flex gap-2 items-center text-primary-700"
//                           type="button"
//                         >
//                           <input
//                             type="file"
//                             name=""
//                             id="upload_files"
//                             className="hidden"
//                             onChange={(e) => {
//                               console.log(e, "E");
//                               handleUpload(field, e.target.files, index);
//                             }}
//                           />
//                           <RiUploadCloud2Line size={20} />
//                           <h5 className="text-sm font-semibold">Upload</h5>
//                         </label>
//                       )}

//                       {typeof doc.selected_file === "object" && (
//                         <div className="flex flex-col gap-3">
//                           <div className="flex gap-2 items-center">
//                             <div className="p-2 bg-primary-100 rounded-full text-primary-700">
//                               <RiFile2Line size={18} />
//                             </div>
//                             <p className="whitespace-nowrap text-sm font-medium">
//                               {doc.selected_file.name}
//                             </p>
//                           </div>

//                           <div className="flex gap-4">
//                             <button
//                               className="flex gap-1 items-center text-red-700 font-semibold text-sm"
//                               onClick={() => handleDelete(field)}
//                               type="button"
//                             >
//                               <RiDeleteBin5Line size={20} />
//                               <p>Delete</p>
//                             </button>

//                             <label
//                               htmlFor="upload_file"
//                               className="flex gap-2 items-center text-primary-700"
//                               type="button"
//                             >
//                               <input
//                                 type="file"
//                                 name=""
//                                 id="upload_file"
//                                 className="hidden"
//                                 onChange={(e) => {
//                                   handleUpload(field, e.target.files, index);
//                                 }}
//                               />
//                               <RiUploadCloud2Line size={20} />
//                               <h5 className="text-sm font-semibold">Upload</h5>
//                             </label>
//                           </div>
//                         </div>
//                       )}
//                       <p>Tipe File : PDF ; Max Size : 5mb</p>
//                     </div>
//                   );
//                 }}
//               />
//             </div>
//           );
//         },
//       },
//     ];
//   }, [methods, handleUpload, handleDelete]);

//   return (
//     <section>
//       {createPortal(
//         <UploadOtherDocumentModal
//           open={isUploadOtherDoc}
//           onClose={() => setIsUploadOtherDoc(false)}
//           onSubmitForm={handleAddOtherDoc}
//         />,
//         document.body
//       )}

//       <CardForm label="Dokumen Pendukung">
//         <div className="flex justify-between">
//           <h5 className="text-lg font-semibold text-primary-700">
//             Daftar Dokumen
//           </h5>
//           <Button
//             className="bg-primary-600 text-white"
//             label={
//               <div className="flex gap-2 items-center">
//                 <RiUploadCloud2Line size={20} />
//                 <h5>Upload Dokumen Lainnya</h5>
//               </div>
//             }
//             type="button"
//             onClick={() => setIsUploadOtherDoc(true)}
//           />
//         </div>

//         <Table
//           columns={tableColumns}
//           dataSource={fields}
//           withPagination={false}
//         />
//       </CardForm>
//     </section>
//   );
// });

// export default DocsSection;
