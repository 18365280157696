import React from "react";

import { Controller, useFormContext } from "react-hook-form";

const Radio = ({
  controllerName,
  className,
  label,
  required = false,
  textArea = false,
  wrapperClass,
  labelClass,
  inputWrapperClass,
  showErrorLabel = true,
  ...props
}) => {
  const { control } = useFormContext();

  const renderLabel = () => {
    if (typeof label === "function") {
      return label();
    }

    return label;
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      defaultValue={""}
      render={({
        field: { onChange, value, onBlur, ...fprops },
        fieldState: { invalid, error },
      }) => {
        return (
          <div className={["form-control w-full", wrapperClass || ""]}>
            <label className={inputWrapperClass}>
              <input
                {...props}
                onChange={onChange}
                value={props.value}
                checked={value == props.value}
                type={"radio"}
                className={"h-[1em] w-[1rem] my-[0.2em]"}
              />
              <div className={["label", labelClass || ""].join(" ")}>
                <span className={`label-text`}>{renderLabel()}</span>
                {required && <span className="text-[#F04438]">*</span>}
              </div>
            </label>

            {invalid && showErrorLabel && (
              <label className="label">
                <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                  {error?.message}
                </div>
              </label>
            )}
          </div>
        );
      }}
    />
  );
};

export default Radio;
