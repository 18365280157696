import { yupResolver } from '@hookform/resolvers/yup';
import {
  BottomFormAction,
  InputForm,
  InputFormUpload,
  Button,
} from 'components';
import AtomSelect from 'components/atoms/Select';
import CardForm from 'components/molecules/CardForm';
import React, { useMemo, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import {
  isCitizenIdValid,
  // isCitizenIdValid,
  isFileSize,
  isFileTypeMatches,
  // isPhoneValid,
  // isTaxIdValid,
} from '../../../../helpers/validation';
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from '../../../../locale/yup_id';
import { RiAddLine, RiDeleteBin6Line } from 'react-icons/ri';
import { useLocation } from 'react-router';
import { last } from 'lodash';
// import { find } from "lodash";

const GeneralInformationForm = ({ onBack, onDraft, onSubmit, draftData }) => {
  const location = useLocation();

  const validationSchema = Yup.object().shape({
    // general informasi
    latar_belakang: Yup.string()
      .required('Latar Belakang wajib diisi')
      .min(8, 'Latar Belakang harus memiliki minimal 8 karakter'),
    visi_misi: Yup.string()
      .required('Visi Misi wajib diisi')
      .min(8, 'Visi Misi harus memiliki minimal 8 karakter'),
    jumlah_pengguna: Yup.number()
      .typeError('Jumlah Anggota harus berupa angka')
      .required('Jumlah Anggota wajib diisi')
      .min(1, 'Minimal Jumlah Anggota adalah 1'),
    jumlah_cabang: Yup.number()
      .typeError('Jumlah Cabang harus berupa angka')
      .required('Jumlah Cabang wajib diisi')
      .min(1, 'Minimal Jumlah Cabang adalah 1'),
    jumlah_pegawai: Yup.number()
      .typeError('Jumlah Pegawai harus berupa angka')
      .required('Jumlah Pegawai wajib diisi')
      .min(1, 'Minimal Jumlah Pegawai adalah 1'),
    // struktur organisasi
    daftardireksi: Yup.array()
      .of(
        Yup.object().shape({
          nama: Yup.string()
            .required('nama wajib diisi')
            .min(1, 'Jabatan harus memiliki minimal 1 karakter'),
          jabatan: Yup.string()
            .required('jabatan wajib diisi')
            .min(1, 'Jabatan harus memiliki minimal 1 karakter'),
          NIK: Yup.string()
            .required('No Telepon wajib diisi')
            .length(16, 'KTP number must be exactly 16 digits')
            .matches(/^[0-9]+$/, 'KTP number must contain only digits'),
          no_telepon: Yup.string()
            .required('No Telepon wajib diisi')
            .min(8, 'No Telepon minimal 8 digit')
            .matches(/^[0-9]+$/, 'KTP number must contain only digits'),
        })
      )
      .min(1),
    struktur_organisasi_upload: Yup.mixed()
      .test(
        'ktp',
        ({ label }) =>
          validationErrorMsg.mixed.required.replace('${path}', label),
        (value, context) => {
          const allowedTypes = ['jpg', 'png', 'jpeg', 'pdf'];

          // if has value, validate the file
          if (value) {
            // validate file type by extension
            const v1 = isFileTypeMatches({ ext: allowedTypes })(value, context);
            if (!v1) {
              return context.createError({
                message: validationCustomErrorMsg.isFiletype(
                  allowedTypes.join(', ')
                )({ label: 'Dokumen Struktur Organisasi' }),
              });
            }

            // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
            const v2 = isFileSize(5 * 1048576)(value, context);
            if (!v2) {
              return context.createError({
                message: validationCustomErrorMsg.isFileSize('5MB')({
                  label: 'Dokumen Struktur Organisasi',
                }),
              });
            }

            return true;
          }

          // otherwise, return false to render the field required
          return false;
        }
      )
      .required()
      .label('Dokumen Struktur Organisasi'),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      daftardireksi: Array.from(
        { length: draftData?.daftardireksi?.length || 1 },
        (_, index) => ({
          nama: draftData?.daftardireksi?.[index]?.nama || '',
          jabatan: draftData?.daftardireksi?.[index]?.jabatan || '',
          NIK: draftData?.daftardireksi?.[index]?.NIK || '',
          no_telepon: draftData?.daftardireksi?.[index]?.no_telepon || '',
        })
      ),
    },
  });

  const { setValue, getValues } = methods;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (draftData && Object.keys(draftData).length > 0) {
      Object.keys(draftData).forEach((key) => {
        setValue(key, draftData[key]);
      });
      methods.trigger();
    }
  }, [draftData, setValue, methods]);

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const {
    fields: shareDaftarDireksi,
    append: appendDaftarDireksi,
    remove: removeDaftarDireksi,
  } = useFieldArray({
    control,
    name: 'daftardireksi',
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit()} className="space-y-8">
        <CardForm label="General Informasi">
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            <InputForm
              controllerName={'latar_belakang'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'Latar Belakang'}
              placeholder={'Latar Belakang'}
              required
              textArea={true}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            <InputForm
              controllerName={'visi_misi'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'Visi Misi'}
              placeholder={'Visi Misi'}
              required
              textArea={true}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <InputForm
              controllerName={'jumlah_pengguna'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'Jumlah Pengguna'}
              placeholder={'Jumlah Pengguna'}
              required
            />
            <InputForm
              controllerName={'jumlah_cabang'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'Jumlah Cabang'}
              placeholder={'Jumlah Cabang'}
              required
            />
            <InputForm
              controllerName={'jumlah_pegawai'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'Jumlah Pegawai'}
              placeholder={'Jumlah Pegawai'}
              required
            />
          </div>
        </CardForm>
        {
          <CardForm label="Daftar Direksi (Direksi/Komisaris/Pemegang Saham)">
            {shareDaftarDireksi.length > 0
              ? shareDaftarDireksi.map((data, index) => (
                  <React.Fragment key={data.id}>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                      <InputForm
                        controllerName={`daftardireksi.${index}.nama`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md ${
                          errors.nama ? 'border-[#F04438]' : 'border-green'
                        }`}
                        label={'Nama'}
                        placeholder={'Nama'}
                        required
                      />
                      <InputForm
                        controllerName={`daftardireksi.${index}.jabatan`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md ${
                          errors.jabatan ? 'border-[#F04438]' : 'border-green'
                        }`}
                        label={'Jabatan'}
                        placeholder={'Jabatan'}
                        required
                      />
                      <InputForm
                        controllerName={`daftardireksi.${index}.NIK`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md ${
                          errors.NIK ? 'border-[#F04438]' : 'border-green'
                        }`}
                        label={'NIK'}
                        placeholder={'NIK'}
                        required
                      />
                      <InputForm
                        controllerName={`daftardireksi.${index}.no_telepon`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md ${
                          errors.no_telepon
                            ? 'border-[#F04438]'
                            : 'border-green'
                        }`}
                        label={'No Telepon'}
                        placeholder={'No Telepon'}
                        required
                      />
                    </div>
                    {index !== 0 && (
                      <div className="flex flex-row-reverse my-5 mx-4 ">
                        <Button
                          type="button"
                          className="p-4 border rounded-lg"
                          label={
                            <div className="flex items-center gap-2 font-[14px]">
                              <RiDeleteBin6Line />
                              Hapus Data
                            </div>
                          }
                          onClick={() => removeDaftarDireksi(index)}
                        />
                      </div>
                    )}
                    <div className="border-b" />
                  </React.Fragment>
                ))
              : ''}
            <div className="flex flex-row-reverse my-5 mx-4 ">
              <Button
                className="p-4 border rounded-lg"
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine />
                    Tambah Data Lain
                  </div>
                }
                onClick={() => {
                  appendDaftarDireksi({
                    nama: '',
                    jabatan: '',
                    NIK: '',
                    no_telepon: '',
                  });
                }}
              />
            </div>
          </CardForm>
        }
        <CardForm label="Struktur Organisasi">
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            <InputFormUpload
              controllerName={`struktur_organisasi_upload`}
              label={'Upload Dokumen Struktur Organisasi '}
              required
              fileType="JPEG, PNG, JPG, PDF"
              maxSize={5 * 1048576}
            />
          </div>
        </CardForm>
        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={onFormSubmit()}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
        />
        <div className="border-b" />
      </form>
    </FormProvider>
  );
};

export default GeneralInformationForm;
