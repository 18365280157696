import React from "react";
import classNames from "classnames";

const TableRow = ({ columns, datas }) => {
  const getCellValue = (object, data, key) => {
    if (object?.render) {
      if (typeof object?.render === "string") return object.render;
      if (typeof object?.render === "function")
        return object.render(data[object.dataIndex], data, key);
    } else return data[object.dataIndex];
    return;
  };

  if (!columns.length) return null;
  if (!datas.length) {
    return (
      <tr className="text-gray-600 text-sm text-center font-semibold">
        <td colSpan={columns.length} className="text-gray-600 text-sm">
          No data to display
        </td>
      </tr>
    );
  }
  return datas.map((data, key) => {
    return (
      <tr key={key}>
        {columns.map((col, idx) => (
          <td
            key={idx}
            className={classNames(
              ["text-gray-600", "text-sm"],
              { sticky: Boolean(col?.fixed) },
              { "bg-white": Boolean(col?.fixed) },
              { "right-0": col?.fixed && col?.fixed === "right" },
              { "left-0": col?.fixed && col?.fixed === "left" },
              // `${(key % 2) !== 0 ? 'bg-white' : 'bg-gray-200'}`,
              `${key === datas.length - 1 ? "border-b-[1px]" : ""}`,
              col?.className && [...col.className.split(" ")]
            )}
          >
            {getCellValue(col, data, key)}
          </td>
        ))}
      </tr>
    );
  });
};

export default React.memo(TableRow);
