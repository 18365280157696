import BreadCrumbs from "components/molecules/Breadcrumbs";
import { useMemo } from "react";

const PermohonanPencairanDetailAssessment = () => {
  const breadcrumbs = useMemo(
    () => [
      {
        label: "Perohonan Pencairan",
        path: "/kelompok/non-perhutanan-sosial/permohonan-pencairan",
      },
      {
        label: "Detail",
        path: "",
      },
      {
        label: "Assessment",
        path: "",
      },
    ],
    []
  );

  return (
    <section className="space-y-5">
      <div className="bg-[#FFFFFF] rounded-xl p-10">
        <div className="flex flex-col w-full">
          <BreadCrumbs routes={breadcrumbs} />
          <div className="mt-6 flex flex-row">
            <div className="flex-1">
              <div className="flex flex-col">
                <span className="font-semibold text-[24px] text-[#101828]">
                  Detail
                </span>
                <span className="font-normal text-[14px] text-[#667085]"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PermohonanPencairanDetailAssessment;
