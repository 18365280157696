import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { getReportProgress } from "services/report/progress";

const useReportProgress = ({ 
  page,
  limit,
  name,
  status,
  submited_date 
}) => {
  const objQuery = {
    page,
    limit
  }
  if(name)
    objQuery['name'] = name
  if(status)
    objQuery['status'] = status
  if(submited_date)
    objQuery['submited_date'] = submited_date

  const {
    data: reportProgressData,
    isLoading: isLoadingReportProgressData,
    isFetching: isFetchingReportProgressData,
    refetch: refetchReportProgressData,
  } = useQueryReportProgress(objQuery);

  return {
    reportProgressData: reportProgressData?.data?.data || [],
    isLoadingReportProgressData,
    isFetchingReportProgressData,
    refetchReportProgressData,
  };
}

export const useQueryReportProgress = (params) => {
  return useQuery({
    queryKey: ["list-report-progess", params],
    queryFn: () => getReportProgress(params),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export default useReportProgress