/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import _, { filter } from "lodash";
import { useNavigate } from "react-router";
import { Button, HeaderNoAuth, InputForm } from "components";
import { motion } from "framer-motion";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AtomSelect from "components/atoms/Select";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "features/auth/authSlice";
import { registerAccountLemtara } from "services/user";
import { useMutation } from "@tanstack/react-query";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import {
  isMatchField,
  isPhoneValid,
  isPasswordValid,
} from "helpers/validation";

import { getPositionService } from "services/masterData/position";
import {
  addNewPosition,
  getInstitutionTypes,
  getIssueFocuses,
} from "services/danaProgram/masterDataService";
import { updateDraftData } from "app/actions";
import {
  getCityService,
  getCountryService,
  getDistrictService,
  getPostCodeService,
  getProvinceService,
  getWardService,
} from "services/masterData/region";

const RegisterDanaProgram = ({ children, sidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const regionStore = useSelector(
    (state) => state?.regionDraftData?.data || {}
  );
  const masterData = useSelector((state) => state?.draftMasterData?.data || {});

  const [countries, setCountries] = useState();
  const [provinces, setProvinces] = useState();
  const [cities, setCities] = useState();
  const [districts, setDistricts] = useState();
  const [wards, setWards] = useState();
  const [zipCodes, setZipCodes] = useState();
  const [loading, setLoading] = useState();
  const [jenisLembaga, setJenisLembaga] = useState([]);
  const [fokusIsu, setFokusIsu] = useState([]);

  const [positions, setPositions] = useState();

  const draftDataOnRedux = useSelector(
    (state) => state?.draftDataGeneralProfile?.data || {}
  );

  const validationSchema = useMemo(() => {
    return yup.object({
      lemtaraName: yup
        .string()
        .trim()
        .required(`nama lemtara wajib diisi`)
        .label("Nama LEMTARA")
        .matches(
          /^[a-zA-Z\s]*$/,
          "nama hanya boleh menggunakan huruf dan spasi"
        ),
      jenisLembaga: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("jenis lembaga wajib diisi"),
      no_akta: yup.string().required(`nomor akta wajib diisi`),
      npwpNumber: yup
        .string()
        .required(`nomor npwp wajib diisi`)
        .label("NPWP")
        .matches(/^[0-9]*$/, "No. NPWP hanya boleh menggunakan angka")
        .matches(/^\d{1,16}$/, "No. NPWP wajib terdiri dari 16 digit"),
      country: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("negara wajib diisi"),
      province: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("provinsi wajib diisi"),
      city: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("provinsi wajib diisi"),
      kelurahan: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("kelurahan wajib diisi"),
      kecamatan: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("kecamatan wajib diisi"),
      posCode: yup.mixed().required("kode pos wajib diisi"),
      address: yup.string().required(`alamat wajib diisi`),
      gender: yup.mixed().required(`Jenis Kelamin wajib diisi`),
      narahubungName: yup
        .string()
        .required(`nama narahubung wajib diisi`)
        .matches(
          /^[a-zA-Z\s]*$/,
          "nama hanya boleh menggunakan huruf dan spasi"
        ),

      lembagaFocusIssue: yup.mixed().required("fokus isu lembaga wajib diisi"),
      position: yup.mixed().required(`jabatan wajib diisi`),
      phone: yup
        .string()
        .matches(
          /^(0|\+62)\d{10,12}$/,
          "Telepon harus angka, minimal diisi 10 dan maksimal 13 karakter, dan di awali dengan 0 atau +62"
        )
        .required("No. Telepon wajib diisi")
        .label("Telepon"),
      email: yup
        .string()
        .trim()
        .email()
        .required()
        .label("Email Narahubung")
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Email Narahubung bukan email yang valid"
        ),
      password: yup
        .string()
        .test("password", validationErrorMsg.mixed.required, isPasswordValid)
        .required()
        .label("Kata Sandi"),
      confirmPassword: yup
        .string()
        .when(["password"], ([password]) => {
          if (password) {
            return yup
              .string()
              .test(
                "confpass",
                validationCustomErrorMsg.matchesField("Kata Sandi"),
                isMatchField("password")
              )
              .required()
              .label("Konfirmasi Kata Sandi");
          }

          return yup.string().required().label("Konfirmasi Kata Sandi");
        })
        .required()
        .label("Konfirmasi Kata Sandi"),
      lembagaFocusIssue: yup
        .array()
        .required(({ label }) => `${label} wajib diisi`)
        .min(1, ({ label }) => `${label} wajib diisi`)
        .label("Fokus isu atau Intervansi Lembaga"),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const {
    formState: { errors, isValid },
  } = methods;

  const { watch } = methods;

  const mutation = useMutation({
    mutationFn: async (params) => {
      try {
        const regisAccount = await registerAccountLemtara(params);
        console.log(`regisAccount`, regisAccount);
        if (regisAccount?.status === 200) {
          const redirectUrl = `${process.env.REACT_APP_AUTH_URL}/account/LoginEndUser?type=Dana%20Program`;
          window.location.href = redirectUrl;
        }
      } catch (error) {
        console.error("Registration error:", error);
      }
    },
  });

  const getInstitution = useMutation(async () => {
    try {
      const institutionResponse = await getInstitutionTypes();
      setJenisLembaga(
        institutionResponse?.data?.data?.map((data) => ({
          label: data?.nama,
          value: data?.id,
        }))
      );
    } catch (error) {
      throw error;
    }
  });

  const getIssue = useMutation(async () => {
    try {
      const issueResponse = await getIssueFocuses();
      setFokusIsu(
        issueResponse?.data?.data?.map((data) => ({
          label: data?.nama,
          value: data?.id,
        }))
      );
    } catch (error) {
      throw error;
    }
  });

  function transformDataToValues(data) {
    return data?.map((item) => item.id);
  }

  const onSubmit = (data) => {
    let params = {
      general_information: {
        name_corporate: data?.lemtaraName ?? "",
        intervansi_lembaga: data?.lembagaFocusIssue ?? "",
        type_corporate: data?.jenisLembaga ?? "",
        no_npwp: data?.npwpNumber ?? null,
        no_akta: data?.no_akta ?? null,
        nation: data?.country?.value ?? "",
        province_id: data?.province?.value ?? "",
        city_id: data?.city?.value ?? "",
        district_id: data?.kecamatan?.value ?? "",
        village_id: data?.kelurahan?.value ?? "",
        zip_code: data?.posCode?.label ?? "",
        address: data?.address ?? "",
      },
      pic_information: {
        name: data?.narahubungName ?? "",
        position: data?.position.label ?? "",
        phone: data?.phone ?? null,
        gender: data?.gender ?? null,
        gender_pic: data?.gender_pic ?? null,
      },
      account: {
        email: data?.email ?? "",
        password: data?.password ?? "",
        confirmation_password: data?.confirmPassword ?? "",
      },
    };

    data && dispatch(signIn("fakeToken"));
    mutation.mutate(params);
  };

  const getPosition = useMutation({
    mutationFn: async () => {
      try {
        const positionResponse = await getPositionService();
        const transformedData = positionResponse?.data?.data?.map(
          (item, index) => ({
            label: item.nama,
            value: item.id,
          })
        );
        setPositions(transformedData);
      } catch (error) {
        throw error;
      }
    },
  });

  const getRegion = useMutation(async (name) => {
    try {
      const countryResponse = await getCountryService();
      _mapCountry(countryResponse?.data?.data);
      const provinceResponse = await getProvinceService();
      _mapProvince(provinceResponse?.data?.data);
      const citiesResponse = await getCityService();
      _mapCity(citiesResponse?.data?.data);
      const districtResponse = await getDistrictService();
      _mapDistrict(districtResponse?.data?.data);
      const wardResponse = await getWardService();
      _mapWard(wardResponse?.data?.data);
      const zipCodeResponse = await getPostCodeService();
      _mapZipCode(zipCodeResponse?.data?.data);
    } catch (error) {
      throw error;
    }
  });

  const _mapCountry = (data) => {
    const temp = data?.map((item, index) => ({
      label: item.namaNegara,
      value: item.id,
    }));
    setCountries(temp);
  };

  const _mapProvince = (data) => {
    if (methods.getValues("country")) {
      const filteredData = data?.map((item, index) => ({
        label: item.namaProvinsi,
        value: item.id,
        country: item.namaNegara,
      }));

      const transformedData = filteredData.filter(
        (item) => item.country === methods.getValues("country").label
      );

      setProvinces(transformedData);
    } else {
      const transformedData = data?.map((item, index) => ({
        label: item.namaProvinsi,
        value: item.id,
        country: item.namaNegara,
      }));
      setProvinces(transformedData);
    }
  };

  const _mapCity = (data) => {
    if (methods.getValues("province")) {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKabupatenKota,
        value: item.id,
        province: item.namaProvinsi,
      }));

      const filteredData = transformedData.filter(
        (item) => item.province === methods.getValues("province").label
      );

      setCities(filteredData);
    } else {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKabupatenKota,
        value: item.id,
        province: item.namaProvinsi,
      }));
      setCities(transformedData);
    }
  };

  const _mapDistrict = (data) => {
    if (methods.getValues("city")) {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKecamatan,
        value: item.id,
        city: item.namaKabupatenKota,
      }));

      const filteredData = transformedData.filter(
        (item) => item.city === methods.getValues("city").label
      );

      setDistricts(filteredData);
    } else {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKecamatan,
        value: item.id,
        city: item.namaKabupatenKota,
      }));
      setDistricts(transformedData);
    }
  };

  const _mapWard = (data) => {
    if (methods.getValues("kecamatan")) {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKelurahan,
        value: item.id,
        district: item.namaKecamatan,
      }));
      const filteredData = transformedData.filter(
        (item) => item.district === methods.getValues("kecamatan").label
      );

      setWards(filteredData);
    } else {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKelurahan,
        value: item.id,
        district: item.namaKecamatan,
      }));
      setWards(transformedData);
    }
  };

  const _mapZipCode = (data) => {
    if (methods.getValues("kelurahan")) {
      const transformedData = data?.map((item, index) => ({
        label: item.kodePos,
        value: item.id,
        ward: item.namaKelurahan,
      }));
      const filteredData = transformedData.filter(
        (item) => item.ward === methods.getValues("kelurahan").label
      );

      setZipCodes(filteredData);
    } else {
      const transformedData = data?.map((item, index) => ({
        label: item.kodePos,
        value: item.id,
        ward: item.namaKelurahan,
      }));
      setZipCodes(transformedData);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryResponse = await getCountryService();
        _mapCountry(countryResponse?.data?.data);

        const provinceResponse = await getProvinceService();
        _mapProvince(provinceResponse?.data?.data);

        const citiesResponse = await getCityService();
        _mapCity(citiesResponse?.data?.data);

        const districtResponse = await getDistrictService();
        _mapDistrict(districtResponse?.data?.data);

        const wardResponse = await getWardService();
        _mapWard(wardResponse?.data?.data);

        const zipCodeResponse = await getPostCodeService();
        _mapZipCode(zipCodeResponse?.data?.data);
      } catch (error) {
        // Handle errors here, e.g., display an error message or log the error
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const callback = () => {
    getPosition.mutate();
    getRegion.mutate();
  };

  useEffect(() => {
    getInstitution.mutate();
    getIssue.mutate();
  }, []);

  useEffect(() => {
    callback();
  }, [
    watch("country"),
    watch("province"),
    watch("city"),
    watch("kecamatan"),
    watch("kelurahan"),
    watch("posCode"),
  ]);

  return (
    <div className="w-full bg-gray-25">
      <div className="flex flex-col h-screen">
        <HeaderNoAuth />
        <div className="md:px-28 flex flex-row flex-1 py-20">
          <div className="flex-1 ">
            <motion.main
              className="flex-1 paragraph pl-10 mb-4"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
              <>
                <FormProvider {...methods}>
                  <div className="w-full space-y-4">
                    <div className="flex flex-col gap-5">
                      <div className="flex flex-col">
                        <label className="font-semibold text-[36px]">
                          Daftar Akun Lembaga
                        </label>
                        <label className="text-[#475467]">
                          Silahkan lengkapi data-data di bawah ini untuk Anda
                          bisa mendapatkan Akun.
                        </label>
                      </div>

                      <div className="flex flex-col gap-5 py-1">
                        <div className="py-1">
                          <label className="font-semibold text-[#01A24A]">
                            Informasi Umum
                          </label>
                        </div>
                        <div className="flex gap-4">
                          <InputForm
                            controllerName={"lemtaraName"}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Nama LEMTARA"}
                            placeholder={"nama lemtara"}
                            required={true}
                          />
                          <AtomSelect
                            controllerName={"jenisLembaga"}
                            options={jenisLembaga}
                            className="basic-single mt-2"
                            label="Jenis Lembaga"
                            required={true}
                          />
                        </div>
                        <div className="flex gap-4">
                          <InputForm
                            controllerName={"no_akta"}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={
                              "Nomor Akta Pendirian/SK Kemenkumham/Surat keterangan lainnya yang setara"
                            }
                            placeholder={
                              "Nomor Akta Pendirian/SK Kemenkumham/Surat keterangan lainnya yang setara"
                            }
                            required={true}
                          />
                        </div>
                        <div className="flex gap-4">
                          <InputForm
                            controllerName={"npwpNumber"}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Nomor NPWP"}
                            placeholder={"Nomor NPWP"}
                            required={true}
                          />
                        </div>
                        <div className="flex gap-4">
                          <AtomSelect
                            controllerName={"lembagaFocusIssue"}
                            options={fokusIsu}
                            className="basic-single mt-2"
                            label="Pilih Fokus Isu atau Intervansi Lembaga"
                            required={true}
                            isMulti
                          />
                        </div>
                        <div className="flex gap-4">
                          <AtomSelect
                            controllerName={"country"}
                            options={countries?.length > 0 ? countries : []}
                            className="basic-single mt-2"
                            label="Negara"
                            required={true}
                          />
                          <AtomSelect
                            controllerName={"province"}
                            options={
                              methods.getValues("country") ? provinces : []
                            }
                            className="basic-single mt-2"
                            label="Provinsi"
                            required={true}
                          />
                        </div>
                        <div className="flex gap-4">
                          <AtomSelect
                            controllerName={"city"}
                            options={
                              methods.getValues("province") ? cities : []
                            }
                            className="basic-single mt-2"
                            label="Kota/Kabupaten"
                            required={true}
                          />
                          <AtomSelect
                            controllerName={"kecamatan"}
                            options={methods.getValues("city") ? districts : []}
                            className="basic-single mt-2"
                            label="Kecamatan"
                            required={true}
                          />
                        </div>
                        <div className="flex gap-4">
                          <AtomSelect
                            controllerName={"kelurahan"}
                            options={
                              methods.getValues("kecamatan") ? wards : []
                            }
                            className="basic-single mt-2"
                            label="Kelurahan"
                            required={true}
                          />
                          <AtomSelect
                            controllerName={"posCode"}
                            options={
                              methods.getValues("kelurahan") ? zipCodes : []
                            }
                            className="basic-single mt-2"
                            label="Kode Pos"
                            required={true}
                          />
                        </div>
                        <div className="flex">
                          <InputForm
                            controllerName={`address`}
                            className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                            label={"Alamat"}
                            placeholder={"alamat"}
                            textArea={true}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="border-b" />

                      <div className="flex flex-col gap-5 py-1">
                        <div className="py-1">
                          <label className="font-semibold text-[#01A24A]">
                            Informasi Penanggung
                          </label>
                        </div>
                        <div className="flex gap-4">
                          <InputForm
                            controllerName={"narahubungName"}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Nama Narahubung"}
                            placeholder={"Nama Narahubung"}
                            required={true}
                          />
                          <AtomSelect
                            controllerName={`gender`}
                            options={[
                              { value: "L", label: "Laki-laki" },
                              { value: "P", label: "Perempuan" },
                            ]}
                            className="basic-single mt-2"
                            label="Jenis Kelamin"
                            required={true}
                          />
                        </div>
                        <div className="flex gap-4">
                          {positions && (
                            <AtomSelect
                              controllerName={`position`}
                              options={positions ? positions : []}
                              mutation={addNewPosition}
                              type={"dynamic"}
                              className="basic-single mt-2"
                              label="Jabatan"
                              required={true}
                            />
                          )}
                          <InputForm
                            controllerName={"phone"}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Telepon"}
                            placeholder={"Telepon"}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="border-b" />

                      <div className="flex flex-col gap-5 py-1">
                        <div className="py-1">
                          <label className="font-semibold text-[#01A24A]">
                            Informasi Akun LEMTARA
                          </label>
                        </div>
                        <div className="flex gap-4">
                          <InputForm
                            controllerName={"email"}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Email"}
                            placeholder={"Email"}
                            required={true}
                          />
                        </div>
                        <div className="flex gap-4">
                          <InputForm
                            controllerName={"password"}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Kata Sandi"}
                            placeholder={"Kata Sandi"}
                            required={true}
                            type="password"
                          />
                          <InputForm
                            controllerName={"confirmPassword"}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Konfirmasi Kata Sandi"}
                            placeholder={"Konfirmasi Kata Sandi"}
                            required={true}
                            type="password"
                          />
                        </div>
                      </div>

                      <div className="flex justify-end gap-4 cursor-pointer">
                        <button
                          className="border rounded-lg p-4 cursor-pointer"
                          onClick={() => navigate("/")}
                        >
                          Batal
                        </button>
                        <Button
                          className="bg-[#00BD52] text-white  rounded-lg p-4"
                          label={
                            <div className="flex items-center gap-2">
                              Daftar Akun
                            </div>
                          }
                          disabled={false}
                          onClick={() => {
                            methods.handleSubmit(onSubmit)();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </FormProvider>
              </>
            </motion.main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterDanaProgram;
