import * as Yup from "yup";
import {
  AgreementSection,
  validationSchema as AgreeValSchema,
} from "../../agreementSection";

import AddressSection, {
  validationSchema as AddressValSchema,
} from "./addressSection";
import DebiturSection, {
  validationSchema as DebiturValSchema,
} from "./debiturSection";
import DocsSection, {
  requiredDocs,
  validationSchema as DocValSchema,
} from "./docsSection";
import FinanceSection, {
  validationSchema as FinanceValSchema,
} from "./financeSection";
import FormulirSection, {
  validationSchema as FormValSchema,
} from "./formulirSection";
import PartnerSection, {
  validationSchema as PartnerValSchema,
} from "./partnerSection";

import { yupResolver } from "@hookform/resolvers/yup";

import { BottomFormAction, ConfirmationModal, StepProgress } from "components";
import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  getPengajuanAnggotaMemberDetail,
  putPengajuanAnggotaMemberNonPerhutananSosial,
  putPengajuanAnggotaMemberNonPerhutananSosialDebitur,
} from "services/fdb/akunKelompok";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createPortal } from "react-dom";
import ProgressModal from "components/molecules/Modal/ProgressModal";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";

const TabItems = {
  INFO: 1,
  LEGAL_DOCS: 2,
};

const TabSteps = [
  {
    label: "Data Calon Debitur",
    step: TabItems.INFO,
  },
  {
    label: "Dokumen Pendukung",
    step: TabItems.LEGAL_DOCS,
  },
];

const validationSchema = Yup.object().shape({
  form: FormValSchema,
  debitur: DebiturValSchema,
  partner: PartnerValSchema,
  address: AddressValSchema,
  finance: FinanceValSchema,
});

const validationSchemaStage2 = Yup.object().shape({
  docs: DocValSchema,
  agree: AgreeValSchema,
});

const FormNPSPendaftaranAnggota = () => {
  const [activeStep, setActiveStep] = useState(TabItems.INFO);
  const [draftModal, setDraftModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const location = useLocation();

  const mode = location.pathname
    .split("/")
    .find((path) => ["view", "edit", "delete"].includes(path));

  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const params = useParams();
  // const location = useLocation();
  const navigate = useNavigate();

  const { member } = location.state;

  const methods = useForm({
    resolver: isView
      ? yupResolver(Yup.object().notRequired())
      : activeStep === TabItems.INFO
      ? yupResolver(validationSchema)
      : yupResolver(validationSchemaStage2),
    mode: "onChange",
  });

  const [data, setData] = useState();

  useQuery({
    queryKey: [
      "pengajuan-permohonan-non-perhutanan-sosial-detail",
      params.memberId,
    ],
    queryFn: ({ queryKey }) => getPengajuanAnggotaMemberDetail(queryKey[1]),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!params.memberId,
    onSuccess: (data) => {
      setData(data);
      const defaultValues = data.data.data;

      const documments =
        defaultValues?.document.length > 0
          ? defaultValues.document
              .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
              .map((doc) => ({
                id: doc.id,
                file: doc.file,
                desc: doc.desc,
                name: doc.name,
                selected_file: doc.file,
              }))
          : requiredDocs;

      methods.reset({
        form: {
          group_name: member?.group_name,
          group_profile_id: defaultValues?.id,
          applicant_member_id: defaultValues?.profile_id,
        },
        debitur: {
          ktp: defaultValues?.identity_number ?? member?.identity_number,
          email: defaultValues?.email ?? member?.email ?? "",
          ktp_file: defaultValues?.identity_file ?? undefined,
          name: defaultValues?.name ?? member?.name_debtor,
          phone: defaultValues?.phone ?? member?.phone,
          npwp: defaultValues?.npwp,
          place_of_birth: defaultValues?.place_of_birth,
          date_of_birth: defaultValues?.date_of_birth
            ? new Date(defaultValues?.date_of_birth)
            : null ?? new Date(member?.date_of_birth),
          kk: defaultValues?.family_card_number,
          kk_file: defaultValues?.family_card_file ?? undefined,
          job: defaultValues?.main_job,
          income_file: defaultValues?.job_doc ?? undefined,
          religion: defaultValues?.religion,
          gender: member?.gender,
        },
        partner: {
          couple_ktp: defaultValues?.couple_identity_number ?? undefined,
          couple_name: defaultValues?.couple_name,
          couple_place_of_birth: defaultValues?.couple_place_of_birth,
          couple_date_of_birth: defaultValues?.couple_date_of_birth
            ? new Date(defaultValues?.couple_date_of_birth)
            : "",
          couple_ktp_file: defaultValues?.couple_identity_file ?? undefined,
          couple_gender: defaultValues?.couple_gender,
          couple_religion: defaultValues?.couple_religion,
          couple_phone_number: defaultValues?.couple_phone,
          couple_job: defaultValues?.couple_job,
        },
        address: {
          cid: {
            ktp_province_id: defaultValues?.province_id,
            ktp_regency_id: defaultValues?.city_id,
            ktp_district_id: defaultValues?.district_id,
            ktp_village_id: defaultValues?.village_id,
            ktp_zip_code_id: defaultValues?.zip_code_id,
            ktp_rt: defaultValues?.rt,
            ktp_rw: defaultValues?.rw,
            ktp_address: defaultValues?.address,
          },
          living: {
            domicile_province_id: defaultValues?.domicile_province_id,
            domicile_regency_id: defaultValues?.domicile_city_id,
            domicile_village_id: defaultValues?.domicile_village_id,
            domicile_district_id: defaultValues?.domicile_district_id,
            domicile_zip_code_id: defaultValues?.domicile_zip_code_id,
            domicile_rt: defaultValues?.domicile_rt,
            domicile_rw: defaultValues?.domicile_rw,
            domicile_address: defaultValues?.domicile_address,
            domicile_latitude: defaultValues?.latitude,
            domicile_longitude: defaultValues?.longitude,
            domicile_since_year: defaultValues?.domicile_since_year
              ? new Date(defaultValues?.domicile_since_year)
              : null,
          },
        },
        finance: {
          largest_expense: defaultValues?.biggest_outcome,
          expense: defaultValues?.routine_outcome_per_month,
          use_largest: defaultValues?.use_largest_outcome,
          income_file: defaultValues?.salary_slip_file ?? undefined,
          income:
            defaultValues?.income_info.length > 0 ?? []
              ? defaultValues?.income_info?.map((item) => ({
                  id: item.id,
                  other_business_type: item.type,
                  other_business_income: item.income,
                  other_business_cycle: item.income_cycle,
                  other_business_cycle_unit: item.income_cycle_unit,
                }))
              : [
                  {
                    id: null,
                    other_business_type: "",
                    other_business_income: "",
                    other_business_cycle: "",
                    other_business_cycle_unit: "",
                  },
                ],
        },
        docs: {
          docs: documments,
          // defaultValues?.document.length > 0
          //   ? defaultValues.document.map((doc) => ({
          //       id: doc.file,
          //       current_file: doc.file,
          //       file: "",
          //       desc: doc.desc,
          //       name: "",
          //       selected_file: undefined,
          //     }))
          //   : requiredDocs.map((doc) => ({
          //       ...doc,
          //     })),
          others: [],
        },
        agree: {
          agree: false,
          created_from: defaultValues?.created_at_place,
        },
      });
    },
    onError: (err) => {
      // enqueueSnackbar({
      //   message: "",
      //   variant: "error",
      // });
      methods.reset({
        form: {
          group_name: member?.group_name,
          // group_profile_id: defaultValues?.id,
          // applicant_member_id: defaultValues?.profile_id,
        },
        debitur: {
          ktp: member?.identity_number,
          email: location.state.email ?? "",
          ktp_file: undefined,
          name: member?.name_debtor,
          phone: member?.phone,

          date_of_birth: new Date(member?.date_of_birth),

          gender: member?.gender,
          docs: {
            docs: requiredDocs,
            others: [],
          },
        },
      });
    },
  });

  const submitMutationDebitur = useMutation({
    mutationKey: ["save-data-debitur"],
    mutationFn: (ctx) => {
      return putPengajuanAnggotaMemberNonPerhutananSosialDebitur(ctx);
    },
  });

  const submitMutation = useMutation({
    mutationKey: ["save-draft-member"],
    mutationFn: (ctx) =>
      putPengajuanAnggotaMemberNonPerhutananSosial(
        ctx.data,
        ctx.isDraft,
        ctx.group_profile_member_id,
        ctx.applicantId
      ),
  });

  const onBack = () => {
    if (activeStep === TabItems.LEGAL_DOCS) {
      navigate(0);
      return;
    }

    navigate(-1);
  };

  const handleMapPayload = () => {
    const data = methods.getValues();

    const documents =
      data.docs &&
      data.docs.docs.map((data) => ({
        ...data,
        name: data?.selected_file?.name ?? data?.name,
        file: data?.selected_file?.id ?? data?.file,
      }));

    const incomeInfo =
      data.finance.income &&
      (data.finance.income.some((key) => key.other_business_type === "")
        ? []
        : data.finance.income.map((data) => ({
            id: data.id === "" ? null : data.id,
            type: data.other_business_type,
            income: Number(data.other_business_income),
            income_cycle: Number(data.other_business_cycle),
            income_cycle_unit: data.other_business_cycle_unit,
          })));

    return {
      identity_number: data.debitur.ktp,
      identity_file: data.debitur.ktp_file,
      name: data.debitur.name,
      place_of_birth: data.debitur.place_of_birth,
      date_of_birth: data.debitur.date_of_birth,
      religion: data.debitur.religion,
      phone: data.debitur.phone,
      gender: data.debitur.gender,
      email: data.debitur.email ?? member?.email,
      family_card_number: data.debitur.kk,
      family_card_file: data.debitur.kk_file,
      npwp: data.debitur.npwp,
      main_job: data.debitur.job,
      // ? OTHER JOB
      // other_job: "string",
      job_doc: data.debitur.income_file,
      couple_identity_number: data.partner.couple_ktp,
      couple_identity_file: data.partner.couple_ktp_file,
      couple_name: data.partner.couple_name,
      couple_place_of_birth: data.partner.couple_place_of_birth,
      couple_date_of_birth: data.partner.couple_date_of_birth,
      couple_religion: data.partner.couple_religion,
      couple_gender: data.partner.couple_gender,
      couple_phone: data.partner.couple_phone_number,
      couple_job: data.partner.couple_job,
      province_id: data.address.cid.ktp_province_id,
      city_id: data.address.cid.ktp_regency_id,
      district_id: data.address.cid.ktp_district_id,
      village_id: data.address.cid.ktp_village_id,
      zip_code_id: data.address.cid.ktp_zip_code_id,
      address: data.address.cid.ktp_address,
      rt: data.address.cid.ktp_rt,
      rw: data.address.cid.ktp_rw,
      domicile_province_id: data.address.living.domicile_province_id,
      domicile_city_id: data.address.living.domicile_regency_id,
      domicile_district_id: data.address.living.domicile_district_id,
      domicile_village_id: data.address.living.domicile_village_id,
      domicile_zip_code_id: data.address.living.domicile_zip_code_id,
      domicile_address: data.address.living.domicile_address,
      domicile_rt: data.address.living.domicile_rt,
      domicile_rw: data.address.living.domicile_rw,
      domicile_since_year: data.address.living.domicile_since_year,
      latitude: data.address.living.domicile_latitude,
      longitude: data.address.living.domicile_longitude,
      routine_outcome_per_month: data.finance.expense,
      biggest_outcome: data.finance.largest_expense,
      use_largest_outcome: data.finance.use_largest,
      salary_slip_file: data.finance.income_file,
      // ? CREATED AT PLACE
      created_at_place: data.agree && data?.agree?.created_from,
      income_info: incomeInfo,
      document: documents,
    };
  };

  const handleSaveDraft = () => {
    const payload = handleMapPayload();

    if (location.pathname.split("/")[1] === "perorangan") {
      submitMutation.mutate(
        {
          data: payload,
          isDraft: true,
          group_profile_member_id: member?.id,
          applicantId: member?.applicant_member_id,
        },
        {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: "Data Berhasil Disimpan!",
              variant: "success",
            });

            navigate(-1);
            // queryClient.invalidateQueries();
          },
          onError: (err) => {
            enqueueSnackbar({
              message: getErrorMessage(err),
              variant: "error",
            });

            window.scrollTo({ behavior: "smooth", top: 0 });
          },
          onSettled: () => {
            setDraftModal(false);
          },
        }
      );

      return;
    }

    submitMutation.mutate(
      {
        data: payload,
        isDraft: true,
        group_profile_member_id: member?.group_profile_member_id,
        applicantId: member?.id,
      },
      {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: "Data Berhasil Disimpan!",
            variant: "success",
          });

          navigate(-1);
          // queryClient.invalidateQueries();
        },
        onError: (err) => {
          enqueueSnackbar({
            message: getErrorMessage(err),
            variant: "error",
          });

          window.scrollTo({ behavior: "smooth", top: 0 });
        },
        onSettled: () => {
          setDraftModal(false);
        },
      }
    );
  };

  const queryClient = useQueryClient();

  const handleOpenConfirmationModal = () => {
    const payload = handleMapPayload();

    if (activeStep === TabItems.INFO) {
      if (location.pathname.split("/")[1] === "perorangan") {
        submitMutation.mutate(
          {
            data: payload,
            isDraft: true,
            group_profile_member_id: member?.id,
            applicantId: member?.applicant_member_id,
          },
          {
            onSuccess: () =>
              queryClient.invalidateQueries([
                "pengajuan-permohonan-non-perhutanan-sosial-detail",
                params.memberId,
              ]),
          }
        );
        setActiveStep(TabItems.LEGAL_DOCS);
        return;
      }

      submitMutation.mutate(
        {
          data: payload,
          isDraft: true,
          group_profile_member_id: member?.group_profile_member_id,
          applicantId: member?.id ?? member?.applicant_member_id,
        },
        {
          onSuccess: () =>
            queryClient.invalidateQueries([
              "pengajuan-permohonan-non-perhutanan-sosial-detail",
              params.memberId,
            ]),
        }
      );
      setActiveStep(TabItems.LEGAL_DOCS);
      return;
    }

    setConfirmModal(true);
  };

  const handleSubmit = (data) => {
    methods.handleSubmit(() => {
      const payload = handleMapPayload();
      if (location.pathname.split("/")[1] === "perorangan") {
        submitMutation.mutate(
          {
            data: payload,
            isDraft: false,
            group_profile_member_id: member?.id,
            applicantId: member?.applicant_member_id,
          },
          {
            onSuccess: (data) => {
              enqueueSnackbar({
                message: "Data Berhasil Disimpan!",
                variant: "success",
              });

              navigate(-1);
            },
            onError: (err) => {
              enqueueSnackbar({
                message: getErrorMessage(err),
                variant: "error",
              });

              window.scrollTo({ behavior: "smooth", top: 0 });
            },
            onSettled: () => {
              setDraftModal(false);
            },
          }
        );

        return;
      }

      submitMutation.mutate(
        {
          data: payload,
          isDraft: false,
          group_profile_member_id: member?.group_profile_member_id,
          applicantId: member?.id,
        },
        {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: "Data Berhasil Disimpan!",
              variant: "success",
            });

            navigate(-1);
            // queryClient.invalidateQueries();
          },
          onError: (err) => {
            enqueueSnackbar({
              message: getErrorMessage(err),
              variant: "error",
            });

            window.scrollTo({ behavior: "smooth", top: 0 });
          },
          onSettled: () => {
            setConfirmModal(false);
          },
        }
      );
    })();
  };

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onClose={setDraftModal}
          onSubmit={handleSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={handleSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {/* {createPortal(
        <ProgressModal
          open={submitMutation.isLoading}
          className="space-y-2 mb-4"
        />,
        document.body
      )} */}

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleOpenConfirmationModal)}
          className="space-y-8"
        >
          <div className="w-full flex justify-center">
            <div className="w-[800px] mb-10">
              <StepProgress
                stepProgressData={TabSteps}
                activeStep={activeStep}
              />
            </div>
          </div>

          <div className="space-y-8">
            {activeStep === 1 && (
              <>
                <FormulirSection mode={mode} formPrefix={"form"} />
                <DebiturSection mode={mode} formPrefix={"debitur"} />
                <PartnerSection mode={mode} formPrefix={"partner"} />
                <AddressSection
                  mode={mode}
                  data={data?.data?.data}
                  formPrefix={"address"}
                />
                <FinanceSection
                  mode={mode}
                  data={data?.data?.data}
                  formPrefix={"finance"}
                />
              </>
            )}

            {activeStep === 2 && (
              <>
                <DocsSection
                  formPrefix={"docs"}
                  mode={mode}
                  // userId={userId}
                  // onBack={onBackClick}
                  // onSubmit={onSubmit}
                  // onSaveDraft={onDraft}
                />
                <AgreementSection formPrefix="agree" mode={mode} />
              </>
            )}

            <BottomFormAction
              lastStep={activeStep === TabSteps.at(-1).step}
              backButtonProps={{
                type: "button",
              }}
              backButtonAction={onBack}
              submitButtonProps={{
                type: "submit",
              }}
              // submitActionButton={handleSubmit}
              disableDrafButton={false}
              hideDraft={isView}
              hideSubmit={isView && activeStep === TabSteps.at(-1).step}
              drafButtonAction={() => setDraftModal(true)}
              draftButtonProps={{
                type: "button",
              }}
              disableButtonSubmit={false}
            />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default FormNPSPendaftaranAnggota;
