/* eslint-disable react-hooks/exhaustive-deps */
import { InputForm, InputFormUpload } from "components";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useLocation } from "react-router";
import { FieldLabel } from "../validation-schema";
import FileInfoField from "components/atoms/FileInfoField";
import SelectGender from "components/organisms/inputs/SelectGender";
import CardForm from "components/molecules/CardForm";
import AtomDatePicker from "components/atoms/Datepicker";
import SelectReligion from "components/organisms/inputs/SelectReligion";
import InputMaskForm from "components/V2/shared/input-mask-form";
import { useMutation } from "@tanstack/react-query";
import { postFileServices } from "services/fdb/akunKelompok";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { getFile } from "services/danaProgram/fileService";

export const DataCalonDebitur = ({ data }) => {
  const location = useLocation();
  const isView = location.pathname.includes("view");

  const [cidFile, setCidFile] = useState();
  const [familyRegFile, setFamilyRegFile] = useState();

  const methods = useFormContext();

  const cidField = useWatch({
    control: methods.control,
    name: "identity_file",
  });
  const familyRegField = useWatch({
    control: methods.control,
    name: "family_card_file",
  });

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "identity_file") {
        setCidFile(res);
        methods.setValue("identity_file", res.id);
      } else if (type === "family_card_file") {
        setFamilyRegFile(res);
        methods.setValue("family_card_file", res.id);
      } else {
        window.open(res.url, "_blank");
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      if (type === "identity_file") {
        methods.setValue(`identity_file`, res.id);
        setCidFile(res);
      } else if (type === "family_card_file") {
        methods.setValue(`family_card_file`, res.id);
        setFamilyRegFile(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {},
  });

  useEffect(() => {
    if (cidField instanceof File) {
      postFile.mutate({ payload: cidField, type: "identity_file" });
    } else if (typeof cidField === "string" && cidField !== "") {
      getDocsMutation.mutate({ payload: cidField, type: "identity_file" });
    }
  }, [cidField]);

  useEffect(() => {
    if (familyRegField instanceof File) {
      postFile.mutate({ payload: familyRegField, type: "family_card_file" });
    } else if (typeof familyRegField === "string" && familyRegField !== "") {
      getDocsMutation.mutate({
        payload: familyRegField,
        type: "family_card_file",
      });
    }
  }, [familyRegField]);

  return (
    <CardForm label="Data Calon Debitur">
      <div className="lg:grid lg:grid-cols-2 gap-4 space-y-4 lg:space-y-0">
        <InputForm
          controllerName={`identity_number`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          label={FieldLabel.CID}
          placeholder={FieldLabel.CID}
          required
          readOnly={isView}
          asText={isView}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />

        <div className={"col-span-1"}>
          {cidField || isView ? (
            <div>
              <label className="font-semibold text-sm my-1.5 block">
                {FieldLabel.CID_IMG}
              </label>
              <FileInfoField
                title={cidFile ? cidFile.name : ""}
                desc={cidFile ? `${cidFile.size} KB` : "Tidak ada file"}
                showIcon
                showDownload={true}
                showClose={!isView}
                onCloseClick={() => {
                  methods.setValue("identity_file", "");
                }}
                buttonLabel="Lihat Berkas"
                onDownloadClick={() => {
                  window.open(cidFile.url, "_blank");
                }}
              />
            </div>
          ) : (
            <InputFormUpload
              controllerName={`identity_file`}
              label={FieldLabel.CID_IMG}
              required
              readOnly={isView}
            />
          )}
        </div>

        <InputForm
          controllerName={`name`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={FieldLabel.FULL_NAME}
          placeholder={FieldLabel.FULL_NAME}
          required
          readOnly={isView}
          asText={isView}
        />

        {isView ? (
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">{FieldLabel.GENDER}</h5>
            <p>{data?.gender === "f" ? "Perempuan" : "Laki-laki"}</p>
          </div>
        ) : (
          <SelectGender
            controllerName={`gender`}
            label={FieldLabel.GENDER}
            wrapperClass={"col-span-1"}
            returnObject={false}
            menuPortalTarget={document.body}
            asText={isView}
          />
        )}

        <InputForm
          controllerName={`phone`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={FieldLabel.PHONE}
          placeholder={FieldLabel.PHONE}
          required
          readOnly={isView}
          asText={isView}
          maxLength={14}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />

        <InputMaskForm
          label="NPWP"
          name={`npwp`}
          placeholder="NPWP"
          mask="##.###.###.#-###.###"
          replacement={{ "#": /\d/ }}
          required
          disabled={isView}
          asText={isView}
        />
        {/* <InputForm
          controllerName={`npwp`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={FieldLabel.NPWP}
          placeholder={FieldLabel.NPWP}
          required
          readOnly={isView}
          asText={isView}
        /> */}

        <div className="col-span-2 grid grid-cols-3 gap-4">
          <InputForm
            controllerName={`place_of_birth`}
            className={`py-2 px-4 mt-2 border w-full rounded-md`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.BIRTHPLACE}
            placeholder={FieldLabel.BIRTHPLACE}
            required
            readOnly={isView}
            asText={isView}
          />

          <AtomDatePicker
            controllerName={`date_of_birth`}
            className={`py-2 px-4 border w-full rounded-md`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.BIRTHDAY}
            placeholder={FieldLabel.BIRTHDAY}
            datepickerOptions={{
              maxDate: new Date(),
              showMonthDropdown: true,
              showYearDropdown: true,
              scrollableYearDropdown: true,
              dropdownMode: "select",
            }}
            datePickerCommonStyles="!border border-gray-200"
            customInputClassname="border border-gray-200 h-[42px]"
            required
            showErrorLabel
            asText={isView}
          />

          <SelectReligion
            controllerName={`religion`}
            wrapperClass={"col-span-1 gap-2"}
            label={FieldLabel.RELIGION}
            placeholder={FieldLabel.RELIGION}
            required={true}
            returnObject={false}
            asText={isView}
          />
        </div>

        <InputForm
          controllerName={`family_card_number`}
          className={`py-2 px-4 mt-2 border w-full rounded-md col-span-1`}
          label={FieldLabel.FAMILY_REG_NO}
          placeholder={FieldLabel.FAMILY_REG_NO}
          required
          maxLength={16}
          readOnly={isView}
          asText={isView}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />

        <div className={"col-span-1"}>
          {familyRegField || isView ? (
            <>
              <label className="font-semibold text-sm my-1.5 block">
                {FieldLabel.FAMILY_REG_IMG}
              </label>
              <FileInfoField
                title={familyRegFile ? familyRegFile.name : ""}
                desc={
                  familyRegFile ? `${familyRegFile.size} KB` : "Tidak ada file"
                }
                showIcon
                showDownload={true}
                showClose={!isView}
                onCloseClick={() => {
                  methods.setValue("family_card_file", "");
                }}
                buttonLabel="Lihat Berkas"
                onDownloadClick={() => {
                  window.open(familyRegFile.url, "_blank");
                }}
              />
            </>
          ) : (
            <InputFormUpload
              controllerName={`family_card_file`}
              label={FieldLabel.FAMILY_REG_IMG}
              required
            />
          )}
        </div>

        <InputForm
          controllerName={`main_job`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.MAIN_JOB}
          placeholder={FieldLabel.MAIN_JOB}
          required
          readOnly={isView}
          asText={isView}
        />

        <InputForm
          controllerName={`other_job`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.OTHER_JOB}
          placeholder={FieldLabel.OTHER_JOB}
          required
          readOnly={isView}
          asText={isView}
        />
      </div>
    </CardForm>
  );
};
