import axios from "axios";
import { config_master } from "app/config";
import axiosInstance from "app/interceptors";

export const getCountryService = () => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/Negara/list`);
};

export const getProvinceService = () => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/Provinsi/list`);
};

export const getCityService = () => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/KabupatenKota/list`);
};

export const getDistrictService = () => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/Kecamatan/list`);
};

export const getWardService = () => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/Kelurahan/list`);
};

export const getPostCodeService = () => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/KodePos/list`);
};
