import { BottomFormAction } from 'components';
import React, { useEffect, useMemo } from 'react';
// import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import CardForm from 'components/molecules/CardForm';
import AtomDatePicker from 'components/atoms/Datepicker';
import Disclosure from '../../../../../components/atoms/Disclosure';
import {CurrencyField} from './components'

const InputCurrencyMemo = React.memo(CurrencyField);

const LabaRugi = ({ draftData, onSubmit, onBack, onDraft }) => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const [activeStep, setActiveStep] = useState(1);
  // const [, setBodyToSend] = useState(null);
  // const [confirmModal, setConfirmModal] = useState(false);
  // const [draftModal, setDraftModal] = useState(false);

  const validationSchema = Yup.object().shape({
    items: Yup.array()
      .of(
        Yup.object().shape({
          pendapatan_operational: Yup.string()
            .required()
            .label('Pendapatan Operational'),
          beban_operational: Yup.string().required().label('Beban Operational'),
          pendapatan_operational_lainnya: Yup.string()
            .required()
            .label('Pendapatan Operational Lainnya'),
          beban_operational_lainnya: Yup.string()
            .required()
            .label('Beban Operational Lainnya'),
          pendapatan_non_operational: Yup.string()
            .required()
            .label('Pendapatan Non Operational'),
          beban_non_operational: Yup.string()
            .required()
            .label('Beban Non Operational'),
          penghasilan_lainnya: Yup.string()
            .required()
            .label('Penghasilan Lainnya'),
          laba_rugi_periode_berjalan: Yup.string()
            .required()
            .label('Laba / Rugi Periode Berjalan'),
        })
      )
      .min(1),
  });

  const currentDate = useMemo(() => new Date(), []); // Using useMemo to calculate once
  const twoYearsAgo = useMemo(
    () =>
      new Date(
        currentDate.getFullYear() - 4,
        currentDate.getMonth(),
        currentDate.getDate()
      ),
    [currentDate] // Depend on currentDate
  );

  const methods = useForm(
    {
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
      defaultValues: {
        // range_tahun: [twoYearsAgo, currentDate],
        first_year: twoYearsAgo,
        end_year: currentDate,
        items: Array.from(
          { length: draftData?.items?.length || 4 },
          (_, index) => {
            // const isFirstElement = index === 0;
            const draftItem = draftData?.items?.[index] || {};

            const item = {
              pendapatan_operational: draftItem.pendapatan_operational || '',
              beban_operational: draftItem.beban_operational || '',
              pendapatan_operational_lainnya:
                draftItem.pendapatan_operational_lainnya || '',
              beban_operational_lainnya:
                draftItem.beban_operational_lainnya || '',
              pendapatan_non_operational:
                draftItem.pendapatan_non_operational || '',
              beban_non_operational: draftItem.beban_non_operational || '',
              penghasilan_lainnya: draftItem.penghasilan_lainnya || '',
              laba_rugi_periode_berjalan:
                draftItem.laba_rugi_periode_berjalan || '',
            };

            return item;
          }
        ),
      },
    },
    [validationSchema, currentDate, twoYearsAgo]
  );

  // const onCreate = () => {
  //   // let params = bodyToSend;
  //   navigate("/lembaga-penyalur");
  // };
  const { setValue, getValues, watch } = methods || {};

  const first_year = watch('first_year', twoYearsAgo);
  const end_year = watch('end_year', currentDate);

  const values = getValues();

  useEffect(() => {
    const startDate = first_year;
    const endDate = end_year;

    if (startDate && endDate) {
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const yearLength = endYear - startYear;

      if (yearLength !== values.items.length) {
        setValue(
          'items',
          Array.from({ length: yearLength }, (_, index) => {
            const draftItem = values.items?.[index] || {};
            // const isFirstElement = index === 0;

            const item = {
              pendapatan_operational: draftItem.pendapatan_operational || '',
              beban_operational: draftItem.beban_operational || '',
              pendapatan_operational_lainnya:
                draftItem.pendapatan_operational_lainnya || '',
              beban_operational_lainnya:
                draftItem.beban_operational_lainnya || '',
              pendapatan_non_operational:
                draftItem.pendapatan_non_operational || '',
              beban_non_operational: draftItem.beban_non_operational || '',
              penghasilan_lainnya: draftItem.penghasilan_lainnya || '',
              laba_rugi_periode_berjalan:
                draftItem.laba_rugi_periode_berjalan || '',
            };

            return item;
          })
        );
      }
    }
  }, [first_year, end_year, values, setValue]);

  const startFrom = first_year.getFullYear();

  const onFormSubmit = () => {
    return methods.handleSubmit(
      (data) => {
        onSubmit(data);
      },
      (errors, e) => console.log(errors, e)
    );
  };

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const { fields: items } = useFieldArray({
    control,
    name: 'items',
  });

  return (
    <div>
      <div className="">
        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit()} className="space-y-8">
            <CardForm label="Pilih Range Tahun">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <AtomDatePicker
                  controllerName={'first_year'}
                  label="Tahun"
                  placeholder="2021"
                  required={true}
                  datepickerOptions={{
                    dateFormat: 'yyyy',
                    maxDate: end_year,
                    showYearPicker: true,
                    minDate: first_year,
                  }}
                  showErrorLabel
                />
                <AtomDatePicker
                  controllerName={'end_year'}
                  label="Tahun"
                  placeholder="2023"
                  required={true}
                  datepickerOptions={{
                    dateFormat: 'yyyy',
                    maxDate: end_year,
                    showYearPicker: true,
                    minDate: first_year,
                  }}
                  showErrorLabel
                />
              </div>
            </CardForm>

            <div className="space-y-4">
              {items.length > 0
                ? items.map((data, index) => (
                    <React.Fragment key={data.id}>
                      <Disclosure
                        title={`Neraca Keuangan Lembaga - Tahun ${
                          index === 0 ? startFrom : startFrom + index
                        }`}
                        contentClass={'space-y-4'}
                        defaultValue={index === 0 || !!errors?.items?.[index]}
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <InputCurrencyMemo
                            controllerName={`items.${index}.pendapatan_operational`}
                            label={'Pendapatan Operational'}
                            placeholder={'Pendapatan Operational'}
                            required
                          />
                          <InputCurrencyMemo
                            controllerName={`items.${index}.beban_operational`}
                            label={'Beban Operational'}
                            placeholder={'Beban Operational'}
                            required
                          />
                          <InputCurrencyMemo
                            controllerName={`items.${index}.pendapatan_operational_lainnya`}
                            label={'Pendapatan Operational Lainnya'}
                            placeholder={'Pendapatan Operational Lainnya'}
                            required
                          />
                          <InputCurrencyMemo
                            controllerName={`items.${index}.beban_operational_lainnya`}
                            label={'Beban Operational Lainnya'}
                            placeholder={'Beban Operational Lainnya'}
                            required
                          />
                          <InputCurrencyMemo
                            controllerName={`items.${index}.pendapatan_non_operational`}
                            label={'Pendapatan Non Operational'}
                            placeholder={'Pendapatan Non Operational'}
                            required
                          />
                          <InputCurrencyMemo
                            controllerName={`items.${index}.beban_non_operational`}
                            label={'Beban Non Operational'}
                            placeholder={'Beban Non Operational'}
                            required
                          />
                          <InputCurrencyMemo
                            controllerName={`items.${index}.penghasilan_lainnya`}
                            label={'Penghasilan Lainnya'}
                            placeholder={'Penghasilan Lainnya'}
                            required
                          />
                          <InputCurrencyMemo
                            controllerName={`items.${index}.laba_rugi_periode_berjalan`}
                            label={'Laba / Rugi Periode Berlajan'}
                            placeholder={'Laba / Rugi Periode Berlajan'}
                            required
                          />
                        </div>
                      </Disclosure>
                    </React.Fragment>
                  ))
                : null}
            </div>
          </form>
        </FormProvider>
      </div>

      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onFormSubmit()}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        disableButtonSubmit={false}
      />
    </div>
  );
};

export default LabaRugi;
