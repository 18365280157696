/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { MAX_DOC_UPLOAD } from "../../../../../app/config";
import { InputForm, InputFormUpload } from "../../../../../components";
import AtomDatePicker from "../../../../../components/atoms/Datepicker";
import FileInfoField from "../../../../../components/atoms/FileInfoField";
import CardForm from "../../../../../components/molecules/CardForm";
import InputKTP from "../../../../../components/organisms/inputs/InputKTP";
import { getErrorMessage, humanizeFileSize } from "../../../../../helpers";
import {
  isCitizenIdValid,
  isFileSize,
  isFileTypeMatches,
} from "../../../../../helpers/validation";
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from "../../../../../locale/yup_id";
import SelectGender from "components/organisms/inputs/SelectGender";
import { useMutation } from "@tanstack/react-query";
import { getFile } from "services/danaProgram/fileService";
import { postFileServices } from "services/fdb/akunKelompok";
import { enqueueSnackbar } from "notistack";

export const FieldLabel = {
  CID: "Nomor NIK",
  CID_IMG: "Upload KTP",
  FULL_NAME: "Nama Lengkap Sesuai KTP",
  BIRTHDAY: "Tanggal Lahir Pasangan",
  BIRTHPLACE: "Tempat Lahir Pasangan",
  GENDER: "Jenis Kelamin Pasangan",
  RELIGION: "Agama Pasangan",
  PHONE: "No Telp Pasangan",
  JOB: "Pekerjaan Pasangan",
};

export const validationSchema = Yup.object().shape({
  couple_identity_number: Yup.string()
    .trim()
    .test("cid", validationErrorMsg.mixed.default, isCitizenIdValid)
    .notRequired()
    .label(FieldLabel.CID),
  couple_identity_file: Yup.mixed()
    .test("cid_img", validationErrorMsg.mixed.required, (value, context) => {
      const allowedTypes = MAX_DOC_UPLOAD;

      // if has value, validate the file
      if (value) {
        // validate file type by extension
        const v1 = isFileTypeMatches({ ext: allowedTypes })(value, context);
        if (!v1) {
          return context.createError({
            message: validationCustomErrorMsg.isFiletype(
              allowedTypes.join(", ")
            )({ label: FieldLabel.CID_IMG }),
          });
        }

        // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
        const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
        if (!v2) {
          return context.createError({
            message: validationCustomErrorMsg.isFileSize(
              humanizeFileSize(MAX_DOC_UPLOAD)
            )({
              label: FieldLabel.CID_IMG,
            }),
          });
        }

        return true;
      }

      // otherwise, return false to render the field required
      return true;
    })
    .notRequired()
    .label(FieldLabel.CID_IMG),
  couple_name: Yup.string().trim().notRequired().label(FieldLabel.FULL_NAME),
  couple_place_of_birth: Yup.string()
    .trim()
    .notRequired()
    .label(FieldLabel.BIRTHPLACE),
  couple_date_of_birth: Yup.string()
    .trim()
    .notRequired()
    .label(FieldLabel.BIRTHDAY),
  couple_gender: Yup.string().trim().notRequired().label(FieldLabel.GENDER),
  couple_job: Yup.string().trim().notRequired().label(FieldLabel.JOB),
});

function PartnerSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const [coupleCidFile, setCoupleCidFile] = useState();

  const methods = useFormContext();
  // const cidImgField = useWatch({
  //   control: methods.control,
  //   name: `couple_identity_file`,
  // });

  const cidCoupleField = methods.watch("couple_identity_file");

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "couple_identity_file") {
        setCoupleCidFile(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      if (type === "couple_identity_file") {
        methods.setValue(`couple_identity_file`, res.id);
        setCoupleCidFile(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {},
  });

  useEffect(() => {
    if (cidCoupleField instanceof File) {
      postFile.mutate({
        payload: cidCoupleField,
        type: "couple_identity_file",
      });
    } else if (typeof cidCoupleField === "string" && cidCoupleField !== "") {
      getDocsMutation.mutate({
        payload: cidCoupleField,
        type: "couple_identity_file",
      });
    }
  }, [cidCoupleField]);

  return (
    <CardForm label="Data Pasangan" {...rest}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputForm
          controllerName={`couple_identity_number`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={FieldLabel.CID}
          placeholder={FieldLabel.CID}
          asText={isView}
        />

        <InputForm
          controllerName={`couple_name`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.FULL_NAME}
          placeholder={FieldLabel.FULL_NAME}
          asText={isView}
        />

        <AtomDatePicker
          controllerName={`couple_date_of_birth`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          datepickerOptions={{ maxDate: new Date() }}
          label={FieldLabel.BIRTHDAY}
          placeholder={FieldLabel.BIRTHDAY}
          asText={isView}
        />

        <InputForm
          controllerName={`couple_place_of_birth`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.BIRTHPLACE}
          placeholder={FieldLabel.BIRTHPLACE}
          asText={isView}
        />

        <SelectGender
          controllerName={`couple_gender`}
          label={FieldLabel.GENDER}
          wrapperClass={"col-span-1"}
          returnObject={false}
          menuPortalTarget={document.body}
          asText={isView}
        />

        <InputForm
          controllerName={`couple_job`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.BIRTHPLACE}
          placeholder={FieldLabel.BIRTHPLACE}
          asText={isView}
        />

        <div className={"col-span-2"}>
          <div className={"col-span-1 row-span-2"}>
            {cidCoupleField || isView ? (
              <>
                <label className="font-semibold text-sm my-1.5 block">
                  {FieldLabel.FAMILY_REG_IMG}
                </label>
                <FileInfoField
                  title={coupleCidFile ? coupleCidFile.name : ""}
                  desc={
                    coupleCidFile
                      ? `${coupleCidFile.size} KB`
                      : "Tidak ada file"
                  }
                  showIcon
                  showDownload={true}
                  showClose={true}
                  onCloseClick={() => {
                    methods.setValue(`couple_identity_file`, "");
                  }}
                  buttonLabel="Lihat Berkas"
                  // onDownloadClick={() => {
                  //   openDocs(`couple_identity_file`);
                  // }}
                />
              </>
            ) : (
              <InputFormUpload
                controllerName={`couple_identity_file`}
                label={FieldLabel.FAMILY_REG_IMG}
                required
              />
            )}
          </div>
        </div>
      </div>
    </CardForm>
  );
}

PartnerSection.propTypes = {};

export default PartnerSection;
