import { useMutation, useQuery } from "@tanstack/react-query";
import Alert from "components/atoms/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {
  RiComputerLine,
  RiEdit2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import { ConfirmationModal, Spinner } from "../../../../components";
import Tag from "../../../../components/atoms/Tag";
import ProgressModal from "../../../../components/molecules/Modal/ProgressModal";
import { getErrorMessage } from "../../../../helpers";
import GeneralInformation from "../../../../assets/general_information.png";
import { getRegisterForm } from "services/fdb/perhutananSosial/registerForm";
import { useSelector } from "react-redux";

// TODO: change this to actual status.
const ProgressStatus = {
  INITIAL: 0,
  DRAFT: 1,
  SUBMITTED: "Menunggu Verifikasi",
  REJECTED: 3,
  APPROVED: 4,
};

const AkunKelompokRegisForm = ({ type }) => {
  const userId = useSelector((state) => state.oidc.oidc_user.access_token);

  const navigate = useNavigate();
  const location = useLocation();
  const [canUpload, setCanUpload] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["fdb-kelompok-form-regis", type, userId],
    queryFn: async () => {
      // TODO: Replace this code with actual API integration
      if (userId) {
        const res = await getRegisterForm(userId);
        // console.log("TEXT", res?.data?.data);
        return res?.data?.data || {};
      }
      return {};
    },
  });
  const [moduleItems] = useState([
    {
      icon: (
        <RiComputerLine
          className={data?.is_draft ? "text-[#E62E05]" : "text-white"}
        />
      ),
      label: "Formulir",
      path: `/kelompok/${type}/formulir-pendaftaran`,
      background: `${data?.is_draft ? "bg-[#FFE6D5]" : "bg-[#00BD52]"}`,
      backgroundOuter: `${data?.is_draft ? "bg-[#FFF4ED]" : "bg-[#D6FFE8]"}`,
    },
  ]);

  const submitMutation = useMutation({
    mutationFn: (params) => {
      // TODO: integrate submit api
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // resolve(true)
          reject(true);
        }, 3000);
      });
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setConfirmModal(false);
    },
  });

  useEffect(() => {
    // TODO: Replace this logic with actual logic to enable the upload button
    setCanUpload(
      moduleItems?.filter((e) =>
        [ProgressStatus.SUBMITTED, ProgressStatus.REJECTED].includes(e.status)
      )?.length === moduleItems?.length
    );
  }, [moduleItems]);

  const onUploadClick = () => {
    setConfirmModal(true);
  };

  const onSubmit = () => {
    // TODO: Pass new data as arg to mutation fn here
    submitMutation.mutate({});
  };

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={submitMutation.isLoading}
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-4">
        <div className="flex flex-col gap-y-6">
          {/* Header Section */}
          <div className="bg-[#FFFFFF] rounded-xl p-10">
            <div className="flex flex-col w-full">
              <BreadCrumbs
                routes={[
                  {
                    label: "Formulir Pendaftaran",
                    path: `/kelompok/${type}/formulir-pendaftaran`,
                  },
                  {
                    label: "Pendaftaran Kelompok",
                    path: `/kelompok/${type}/formulir-pendaftaran`,
                  },
                ]}
              />
              <div className="mt-6 flex flex-row">
                <div className="flex-1">
                  <div className="flex flex-col">
                    <span className="font-semibold text-[24px] text-[#101828]">
                      Formulir Pendaftaran Kelompok
                    </span>
                    <span className="font-normal text-[14px] text-[#667085]"></span>
                  </div>
                </div>
                <div className="flex-1 text-right">
                  <button
                    className={[
                      "py-3 px-4 rounded-lg text-sm",
                      canUpload
                        ? "bg-[#E34F13] text-white"
                        : "bg-[#D0D5DD]  text-[#667085]",
                    ].join(" ")}
                    disabled={!canUpload}
                    onClick={onUploadClick}
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <RiUploadCloud2Line />
                      <span className="font-semibold">
                        Upload Semua Persyaratan
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Alert
            theme={"warning"}
            title={"Lengkapi Dokumen"}
            description={
              "Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan"
            }
          />
          <div>
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <div className="flex flex-wrap gap-4">
                {_.map(
                  moduleItems,
                  (
                    { icon, label, path, background, backgroundOuter, status },
                    index
                  ) => (
                    <div
                      className="flex flex-col items-center w-[285px] gap-4 bg-white shadow-xl rounded-xl relative"
                      key={index}
                    >
                      <div className="p-4 absolute -top-10">
                        <div className="flex items-center gap-2">
                          <div
                            className={`${backgroundOuter} p-3 rounded-full`}
                          >
                            <div className={`${background} p-3 rounded-full`}>
                              {icon}
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="mt-12 font-semibold">
                        {_.upperFirst(label)}
                      </span>
                      <img
                        src={GeneralInformation}
                        alt="general-information-img"
                      />

                      <div className="px-4 space-y-4">
                        {data?.is_draft == false &&
                        data?.is_submitted == false ? (
                          <>
                            <Alert
                              theme={"warning"}
                              title={"Lengkapi Dokumen"}
                              description={
                                "Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan"
                              }
                            />
                          </>
                        ) : data?.is_draft == true &&
                          data?.is_submitted == false ? (
                          <>
                            <Alert
                              theme={"success"}
                              title={"Lengkapi Dokumen"}
                              description={
                                "Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan"
                              }
                            />
                            <Tag
                              label={"Data Belum Lengkap"}
                              theme={"warning"}
                            />
                          </>
                        ) : (
                          <>
                            <Alert
                              theme={"warning"}
                              title={"Data Sudah Lengkap"}
                              description={"Anda sudah melengkapi seluruh data"}
                            />
                          </>
                        )}
                      </div>

                      <div className="p-4 w-full border-t">
                        <div className="text-right">
                          {data?.is_draft == false &&
                          data?.is_submitted == false ? (
                            <button
                              // onClick={() => navigate(`${path}/edit`)}
                              onClick={() => {
                                navigate(`${data?.id}/edit`);
                              }}
                              className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                            >
                              Lengkapi
                            </button>
                          ) : undefined}

                          {data?.is_finish ? (
                            <button
                              onClick={() => navigate(`${path}/view`)}
                              className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                            >
                              Lihat Dokumen
                            </button>
                          ) : undefined}

                          {data?.is_draft == true &&
                          data?.is_submitted == true ? (
                            <button
                              // onClick={() => navigate(`${path}/edit`)}
                              onClick={() => {
                                navigate(`${data?.id}/edit`);
                              }}
                              className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                            >
                              Ubah
                            </button>
                          ) : undefined}

                          {data?.is_draft == true &&
                          data?.is_submitted == false ? (
                            <button
                              onClick={() => {
                                navigate(`${data?.id}/edit`);
                              }}
                              className="bg-white text-gray-700 border-[1px] border-gray-700 py-2 px-4 rounded-lg  w-full font-semibold text-[12px]"
                            >
                              <RiEdit2Line className="inline-block w-4 h-4 mr-2 align-middle " />
                              <span className={"align-middle "}>Lengkapi</span>
                            </button>
                          ) : undefined}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AkunKelompokRegisForm;
