import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";
import { RiArrowDownSLine, RiCalendarLine } from "react-icons/ri";
import styles from "./index.module.css";

const DatepickerCustomInput = forwardRef(
  ({ value, onClick, placeholder, wrapperClass, label, required }, ref) => (
    <div className={`${wrapperClass || ""}`} onClick={onClick}>
      <div className={`relative mt-2`}>
        <input
          className={
            "border rounded-lg py-2 pl-4 w-full pr-10 focus:outline-none bg-white"
          }
          onClick={onClick}
          ref={ref}
          disabled
          value={value}
          placeholder={placeholder}
        />
        <div className={"absolute top-[50%] translate-y-[-50%] right-2"}>
          <RiArrowDownSLine className={"w-6 h-6 text-gray-400"} />
        </div>
      </div>
    </div>
  )
);

const DatepickerCustomInputTextField = forwardRef(
  (
    { value, onClick, placeholder, wrapperClass, label, required, className },
    ref
  ) => (
    <div className={`relative`}>
      <div className={`${wrapperClass || ""}`} onClick={onClick}>
        <div className={`relative`}>
          <input
            className={`input w-full text-left bg-white p-2 mt-2 rounded-lg border ${className}`}
            onClick={onClick}
            ref={ref}
            disabled
            value={value}
            placeholder={placeholder}
          />
          <div className={"absolute top-[50%] translate-y-[-50%] right-2"}>
            <RiCalendarLine className="absolute top-[55%] right-3 -translate-y-1/2 text-gray-400 h-6 w-6" />
          </div>
        </div>
      </div>
    </div>
  )
);

const RangeDatepicker = ({
  controllerName,
  startDate = new Date(),
  setStartDate,
  isError = false,
  label,
  placeholder,
  required = false,
  wrapperClass,
  isClearable = false,
  showErrorLabel = false,
  isTextField = false,
  format = null,
  newDate = false,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={controllerName}
      control={control}
      rules={{ required }}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <div
            className={`form-control ${isTextField && "flex flex-col w-full"} ${
              styles["app-datepicker"]
            } ${wrapperClass || ""}`}
          >
            <label className="label font-semibold font-[14px] ">
              <span className={`label-text`}>{label}</span>
              {required && <span className="text-[#F04438]">*</span>}
            </label>
            <DatePicker
              selected={newDate ? new Date(value?.[0]) : value?.[0]}
              onChange={(date) => onChange(date)}
              startDate={newDate ? new Date(value?.[0]) : value?.[0]}
              endDate={newDate ? new Date(value?.[1]) : value?.[1]}
              selectsRange
              placeholderText={placeholder}
              isClearable={isClearable}
              dateFormat={format || "MM/dd/yyyy"}
              customInput={
                isTextField ? (
                  <DatepickerCustomInputTextField />
                ) : (
                  <DatepickerCustomInput />
                )
              }
            />
            {invalid && showErrorLabel && (
              <label className="label">
                <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                  {error?.message}
                </div>
              </label>
            )}
          </div>
        );
      }}
    />
  );
};

export default RangeDatepicker;
