import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";
import { data } from "autoprefixer";

export const getDetailPermohonanPembiayaan = async (id) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/request/deferred-detail/${id}`
  );
};

export const sendPermohonanPembiayaan = async (data, params) => {
  return await axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/request/send-deferred`,
    data,
    { params }
  );
};

export const deleteCollateralsDeffered = async (id) => {
  return await axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/request/deferred-remove-collaterals`,
    { params: { id } }
  );
};

export const deleteDocumentDeferred = async (id) => {
  return await axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/request/deferred-remove-documents`,
    { params: { id } }
  );
};

// * HHBK (Hasil Hutan Bukan Kayu)

export const sendApplicationFinancingHHBK = async (data, params) => {
  return await axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/request/send-hhbk`,
    data,
    { params }
  );
};

export const getDetailsApplicationFinancingHHBK = async (id) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/request/hhbk-detail/${id}`
  );
};

export const deleteCollateralsHHBK = async (id) => {
  return await axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/request/hhbk-remove-collaterals`,
    { params: { id } }
  );
};

export const deleteDocumentHHBK = async (id) => {
  return await axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/request/hhbk-remove-documents`,
    { params: { id } }
  );
};

// * Comodities

export const sendApplicationFinancingCommodities = async (data, params) => {
  return await axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/request/send-commodities`,
    data,
    { params }
  );
};

export const getDetailsApplicationFinancingComodities = async (id) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/request/commodities-detail/${id}`
  );
};

export const deleteCollateralsComodities = async (id) => {
  return await axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/request/commodities-remove-collaterals`,
    { params: { id } }
  );
};

export const deleteDocumentComodities = async (id) => {
  return await axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/request/commodities-remove-documents`,
    { params: { id } }
  );
};

export const createSelfAssesment = async (data) => {
  return await axiosInstance.post(
    `${config_fdb_v2.BASE_URL}/v1/request/self-assesment`,
    data
  );
};

export const getSelfAssesment = async () => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/request/self-assesment`
  );
};
