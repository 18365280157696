import React from "react";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";

import CardForm from "components/molecules/CardForm";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormUpload,
} from "components";
import AtomDatePicker from "components/atoms/Datepicker";
import { validationErrorMsg } from "locale/yup_id";
import { isCitizenIdValid } from "helpers/validation";
import { map } from "lodash";
import { RiAddLine } from "react-icons/ri";
import { BiTrash } from "react-icons/bi";

const ManagementInfoForm = ({ onBack, onSubmit, onDraft }) => {
  const validationSchema = Yup.object().shape({
    management_data: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().trim().required().label("Nama"),
        position: Yup.string().trim().required().label("Jabatan"),
        nik: Yup.string()
          .trim()
          .test(
            "nik",
            ({ label }) =>
              validationErrorMsg.mixed.default.replace("${path}", label),
            isCitizenIdValid
          )
          .required()
          .label("NIK"),
        phonenumber: Yup.number()
          .typeError("Nomor Telepon harus berupa angka")
          .required("Nomor Telepon wajib diisi")
          .min(8, "Minimal Nomor Telepon adalah 8"),
      })
    ),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      management_data: [
        {
          name: "",
          position: "",
          nik: "",
          phonenumber: "",
        },
      ],
    },
  });

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit()} className="space-y-8">
        <CardForm label="Info Kepengurusan">
          <FormData />
        </CardForm>
        <div className="h-10" />
        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={onFormSubmit()}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
        />
      </form>
    </FormProvider>
  );
};

const FormData = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "management_data",
  });
  return (
    <div>
      {map(fields, (field, key) => (
        <>
          <div key={field.id} className="my-4 flex items-center space-x-4">
            <div className="flex-1 md:grid md:grid-cols-4 gap-4 space-y-4 md:space-y-0">
              <InputForm
                controllerName={`management_data.${key}.name`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"Nama"}
                placeholder={"Nama"}
                required
              />
              <InputForm
                controllerName={`management_data.${key}.position`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"Jabatan"}
                placeholder={"Jabatan"}
                required
              />
              <InputForm
                controllerName={`management_data.${key}.nik`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"NIK"}
                placeholder={"NIK"}
                required
              />
              <InputForm
                controllerName={`management_data.${key}.phonenumber`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"Nomor Telepon"}
                placeholder={"Nomor Telepon"}
                required
              />
              <div className="col-span-2">
                <InputFormUpload
                  controllerName={`struktur_organisasi_upload`}
                  label={
                    "kartu tanda penduduk/surat izin mengemudi/paspor pengurus"
                  }
                  required
                  fileType="JPEG, PNG, JPG, PDF"
                  maxSize={5 * 1048576}
                />
              </div>
              <div className="col-span-2">
                <InputFormUpload
                  controllerName={`struktur_organisasi_upload`}
                  label={"Curicullum vitae pengurus"}
                  required
                  fileType="JPEG, PNG, JPG, PDF"
                  maxSize={5 * 1048576}
                />
              </div>
            </div>
          </div>
          {key > 0 && (
            <div className="flex pb-8  border-b items-center justify-end ">
              <Button
                type="button"
                onClick={() => remove(key)}
                className="p-3 rounded-lg bg-white flex-none"
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <BiTrash className="" /> Hapus Data
                  </div>
                }
              />
            </div>
          )}
        </>
      ))}
      <div className="flex items-center justify-end pt-2">
        <Button
          type="button"
          className="p-4 border rounded-lg bg-white"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah Data Lain
            </div>
          }
          onClick={() => {
            append({
              name: "",
              position: "",
              nik: "",
              phonenumber: "",
            });
          }}
        />
      </div>
    </div>
  );
};

export default ManagementInfoForm;
