import React from "react";

const defaultListClassName = [
  "before:[&:not(:first-child)]:col-start-1",
  "before:[&:not(:first-child)]:row-start-1",
  "before:[&:not(:first-child)]:w-full",
  "before:[&:not(:first-child)]:-ml-[100%]",
  "before:[&:not(:first-child)]:content['']",
  "before:[&:not(:first-child)]:text-[rgb(31,41,55)]",
  "before:[&:not(:first-child)]:transform",
  "grid",
  "text-center",
  "min-w-[4rem]",
  "place-items-center",
  "grid-cols-[auto]",
  "grid-rows-[40px_1fr]",
  "font-semibold",
  "text-base",
  "after:rounded-[9999px]",
  "after:grid",
  "after:place-items-center",
  "after:place-self-center",
  "after:relative",
  "after:z-[1]",
  "after:h-8",
  "after:w-8",
  "after:[counter-increment:step]",
  "after:col-start-1",
  "after:row-start-1",
  "after:content-[attr(data-content)]",
];

const Stepper = ({ currentStep = 0, items = [] }) => {
  return (
    <ul
      className={`mx-auto w-full inline-grid overflow-y-hidden overflow-x-auto auto-cols-[1fr] grid-flow-col [counter-reset:step] gap-4`}
    >
      {items.map((item, key) => (
        <li
          key={key}
          data-content="●"
          className={`${defaultListClassName.join(" ")} ${
            key > 0 ? "before:!h-[5px]" : ""
          } ${
            currentStep >= 0 && key === 0
              ? "after:!bg-primary-50 after:!text-primary-600 text-primary-700"
              : currentStep > 0 && key > 0 && currentStep >= key
              ? "before:!bg-primary-600 after:!bg-primary-50 after:!text-primary-600 text-primary-700"
              : "before:!bg-gray-200 after:!bg-gray-100 after:!text-gray-300 text-gray-700"
          }`}
        >
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default Stepper;
