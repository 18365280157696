import { useQuery } from "@tanstack/react-query";
import { list } from "services/danaProgram/disbursement";

export const useDisbursement = ({ params }) => {
  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const {
    data: disbursmenetData,
    isLoading: isLoadingDisbursementData,
    isFetching: isFetchingDisbursementData,
    refetch: refetchDisbursementData,
    isError: isErrorDisbursementData,
  } = useQuery(["list-disbursement"], async () =>
    list(queryParams)
  );

  return {
    disbursmenetData: disbursmenetData?.data?.data?.data || [],
    paginationDisbursementData: {
      lastPage: disbursmenetData?.data?.data?.lastPage,
      page: disbursmenetData?.data?.data?.page,
      perPage: disbursmenetData?.data?.data?.perPage,
      total: disbursmenetData?.data?.data?.total,
    },
    isLoadingDisbursementData,
    isFetchingDisbursementData,
    isErrorDisbursementData,
    refetch: refetchDisbursementData,
  };
};
