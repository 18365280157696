import { InputForm, InputFormUpload, Table } from "components";
import CardForm from "components/molecules/CardForm";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  RiCloseLine,
  RiDeleteBin5Line,
  RiDownloadCloud2Line,
  RiFile2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import * as Yup from "yup";
import Button from "../../../../../components/atoms/Button";

import { useMutation } from "@tanstack/react-query";
import { postFileServices } from "services/fdb/akunKelompok";
import { deleteDocumentComodities } from "services/fdb/permohonan-pembiayaan";
import { enqueueSnackbar } from "notistack";
import { getFile } from "services/danaProgram/fileService";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnimatePresence, motion } from "framer-motion";

export const FieldLabel = {
  FILE: "Dokumen",
  DOCS: "Daftar Dokumen",
};

export const requiredDocs = [
  {
    title: "Dokumen ijin penguasaan lahan",
    desc: "Dokumen ijin penguasaan lahan",
    key: "ijin-penguasaan-lahan",
  },
  {
    title: "Dokumen pendukung lainnya",
    desc: "Dokumen pendukung lainnya",
    key: "ket-riwayat-tanah",
  },
  {
    title: "Foto Lahan Debitur",
    desc: "Foto Lahan Debitur",
    key: "foto-lahan-debitur",
  },
];

const DocSection = ({ mode }) => {
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "document",
  });

  const mutateFileService = useMutation(["File Service"], postFileServices);
  const mutateDeleteDocument = useMutation(
    ["DELETE DOCUMENT DEBITUR DEFERRED"],
    ({ id }) => deleteDocumentComodities(id)
  );

  const handleUpload = useCallback(
    (field, files, index) => {
      const formData = new FormData();

      formData.append("file", files[0]);

      mutateFileService.mutate(formData, {
        onSuccess: (res) => {
          enqueueSnackbar({
            message: "Dokumen Berhasil Disimpan!",
            variant: "success",
          });
          methods.setValue(`document[${index}].file`, res.data.data.id);
          field.onChange(res.data.data);
        },
        onError: (err) => {
          enqueueSnackbar({
            message: "Dokumen Gagal Disimpan!",
            variant: "failed",
          });
        },
      });
    },
    [mutateFileService, methods]
  );

  const handleDelete = useCallback(
    (field, index) => {
      if (field.id) {
        mutateDeleteDocument.mutate(
          { id: field.id },
          { onSuccess: (res) => remove(index) }
        );
      }
      remove(index);
    },
    [mutateDeleteDocument, remove]
  );

  const handleMountingFile = useCallback(async () => {
    const docs = methods.getValues("document");

    for (let i = 0; i < docs.length; i++) {
      if (!docs[i].id || typeof docs[i].selected_file !== "undefined") {
        continue;
      }

      const res = await getFile(docs[i].file);

      methods.setValue(`document[${i}].selected_file`, res.data?.data);
      // methods.setValue(`document[${i}].selected_file`, res.data.data);
    }
  }, [methods]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "no",
        dataIndex: "#",
        columnClassName: "w-[50px]",
        render: (_, __, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        id: "doc",
        title: "document",
        dataIndex: "doc",
        columnClassName: "",
        render: (_, data, index) => {
          return (
            <div className="flex flex-col">
              <p className="text-sm font-bold">
                {data.name} <span className="text-sm text-red-600">*</span>
              </p>
            </div>
          );
        },
      },
      {
        id: "doc",
        title: "Deskripsi",
        dataIndex: "doc",
        columnClassName: "",
        render: (_, data, index) => {
          return (
            <div className="flex gap-2 items-center cursor-pointer">
              <div>
                <p className="text-sm font-bold">{data.desc}</p>

                <div className="text-blue-500 mt-2">
                  <RiDownloadCloud2Line size={20} />
                  <p className="text-sm font-semibold">Unduh Dokumen Rujukan</p>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        columnClassName: "w-min",
        fixed: "right",
        render: (_, data, index) => {
          return (
            <div className="flex flex-col">
              <Controller
                control={methods.control}
                name={`document[${index}].selected_file`}
                render={({ field }) => {
                  const doc = methods.watch(`document[${index}]`);

                  return (
                    <div className="flex flex-col gap-2" key={field.id}>
                      {(typeof doc.selected_file === "undefined" ||
                        typeof doc.selected_file !== "object") && (
                        <label
                          htmlFor={`upload_files${index}`}
                          className="flex gap-2 items-center text-primary-700 cursor-pointer"
                          type="button"
                        >
                          <input
                            type="file"
                            name=""
                            id={`upload_files${index}`}
                            className="hidden"
                            onChange={(e) => {
                              handleUpload(field, e.target.files, index);
                            }}
                          />
                          <RiUploadCloud2Line size={20} />
                          <h5 className="text-sm font-semibold">Upload</h5>
                        </label>
                      )}

                      {typeof doc.selected_file === "object" && (
                        <div className="flex flex-col gap-3">
                          <div className="flex gap-2 items-center">
                            <div className="p-2 bg-primary-100 rounded-full text-primary-700">
                              <RiFile2Line size={18} />
                            </div>
                            <p className="whitespace-nowrap text-sm font-medium">
                              {doc?.selected_file?.name}
                            </p>
                          </div>

                          {!isView && (
                            <div className="flex gap-4">
                              {index > 0 ? (
                                <button
                                  className="flex gap-1 items-center text-red-700 font-semibold text-sm"
                                  onClick={() => handleDelete(doc, index)}
                                  type="button"
                                >
                                  <RiDeleteBin5Line size={20} />
                                  <p>Delete</p>
                                </button>
                              ) : null}

                              <label
                                htmlFor="upload_file"
                                className="flex gap-2 items-center text-primary-700"
                                type="button"
                              >
                                <input
                                  type="file"
                                  name=""
                                  id="upload_file"
                                  className="hidden"
                                  onChange={(e) => {
                                    handleUpload(field, e.target.files, index);
                                  }}
                                />
                                <RiUploadCloud2Line size={20} />
                                <h5 className="text-sm font-semibold">
                                  Upload
                                </h5>
                              </label>
                            </div>
                          )}
                        </div>
                      )}
                      <p>Tipe File : PDF ; Max Size : 5mb</p>
                    </div>
                  );
                }}
              />
            </div>
          );
        },
      },
    ];
  }, [methods, handleUpload, handleDelete, isView]);

  useEffect(() => {
    handleMountingFile();
  }, [handleMountingFile]);

  const [isUploadOtherDoc, setIsUploadOtherDoc] = useState(false);

  const handleAddOtherDoc = (payload) => {
    const formData = new FormData();

    formData.append("file", payload.selected_file);

    mutateFileService.mutate(formData, {
      onSuccess: (res) => {
        enqueueSnackbar({
          message: "Dokumen Berhasil Disimpan!",
          variant: "success",
        });

        const otherDoc = {
          id: null,
          name: payload.name,
          desc: payload.desc,
          key: "OTHERDOC",
          file: res.data.data.id,
          selected_file: res.data.data,
        };

        append(otherDoc);
        setIsUploadOtherDoc(false);

        // field.onChange(res.data.data);
      },
      onError: (err) => {
        enqueueSnackbar({
          message: "Dokumen Gagal Disimpan!",
          variant: "error",
        });
      },
    });
  };

  return (
    <section>
      {createPortal(
        <UploadOtherDocumentModal
          open={isUploadOtherDoc}
          onClose={() => setIsUploadOtherDoc(false)}
          onSubmitForm={handleAddOtherDoc}
        />,
        document.body
      )}

      <CardForm label="Dokumen Pendukung">
        <div className="flex justify-between">
          <h5 className="text-lg font-semibold text-primary-700">
            Daftar Dokumen
          </h5>
          {!isView && (
            <Button
              className="bg-primary-600 text-white"
              label={
                <div className="flex gap-2 items-center">
                  <RiUploadCloud2Line size={20} />
                  <h5>Upload Dokumen Lainnya</h5>
                </div>
              }
              type="button"
              onClick={() => setIsUploadOtherDoc(true)}
            />
          )}
        </div>

        <Table
          columns={tableColumns}
          dataSource={fields}
          withPagination={false}
        />
      </CardForm>
    </section>
  );
};

export default DocSection;

const otherSchemaValidation = Yup.object({
  name: Yup.string().required("Nama dokumen lainnya harus diisi"),
  selected_file: Yup.string().required("Dokumen harus diisi"),
  desc: Yup.string().required("Deskripsi harus diisi"),
});

const UploadOtherDocumentModal = (props) => {
  const methods = useForm({
    resolver: yupResolver(otherSchemaValidation),
    mode: "onChange",
  });

  const handleClose = () => {
    methods.reset();

    props.onClose();
  };

  const onSubmit = () => {
    const payload = methods.getValues();

    props.onSubmitForm(payload);

    methods.reset();
  };

  return (
    <AnimatePresence mode="wait">
      {props.open && (
        <div className="h-screen w-full bg-black/70 z-50 fixed inset-0 overflow-y-hidden flex justify-center items-center">
          <motion.div
            key="modal-confirmation"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="flex flex-col gap-5 px-8 py-6 bg-white rounded-xl min-w-[400px] shadow-md"
          >
            {/* HEADER */}
            <div className="flex items-center">
              <h5 className="flex flex-grow text-lg font-semibold">
                Upload Dokumen Lainnya
              </h5>
              <RiCloseLine
                size={24}
                className="text-gray-500 cursor-pointer"
                onClick={handleClose}
              />
            </div>

            {/* CONTENT */}
            <FormProvider {...methods}>
              <div className="flex flex-col space-y-5">
                <InputForm
                  label="Nama Dokumen Lainnya"
                  controllerName="name"
                  className="w-full"
                  required
                />

                <InputFormUpload
                  controllerName="selected_file"
                  label="Dokumen Lainnya"
                />

                <div className="flex flex-col gap-1">
                  <InputForm
                    label="Deskripsi"
                    controllerName="desc"
                    className="w-full h-[100px]"
                    required
                  />
                </div>
              </div>

              <div className="flex gap-3">
                <Button
                  label="Batal"
                  className="flex-grow"
                  outline
                  onClick={handleClose}
                  type="button"
                />
                <Button
                  label="Submit"
                  className="flex-grow bg-primary-600 hover:bg-primary-700 text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </FormProvider>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};
