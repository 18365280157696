import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { Button, Table } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { RiEyeLine, RiFile3Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getFile } from "services/danaProgram/fileService";
import { getOfferList, postOffer } from "services/fdb/offer-institution-offer";

const statusBadge = {
  "Menunggu Keputusan": "bg-[#FFFAEB] text-[#B54708]",
  Negosiasi: "bg-[#F0F9FF] text-[#026AA2]",
  Diterima: "bg-[#ECFDF3] text-[#027A48]",
  Ditolak: "bg-[#FEF3F2] text-[#B42318]",
};

export default function Offer(props) {
  const { type, group } = props;
  const navigate = useNavigate();

  const methods = useForm();

  const [listOffer, setListOffer] = useState([]);
  const [filters, setFilters] = useState({
    paginate: true,
    page: 1,
    limit: 10,
    filter: {
      search: "",
      status: "",
      date: "",
    },
  });

  const queryClient = useQueryClient();
  const mutatePostOffer = useMutation({ mutationFn: postOffer });
  const { isLoading } = useQuery(
    ["GET_LIST_OFFER_INSTITUTION", filters],
    getOfferList,
    {
      select: (data) => data?.data?.data,
      onSuccess: async (res) => {
        for (let offer of res?.data) {
          const res = await getFile(offer.file);
          offer.files = res.data.data;
        }

        setListOffer(res);
      },
    }
  );

  const handleView = (id) => {
    navigate(`/penawaran/${group}/${type}/detail/${id}`);
  };

  const handleApprove = useCallback(
    (row) => {
      mutatePostOffer.mutate(
        {
          id: row?.id,
          data: {},
          params: { approval: "approve" },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              variant: "success",
              message: "Berhasil Submit Penawaran",
            });
            queryClient.invalidateQueries([
              "GET_LIST_OFFER_INSTITUTION",
              filters,
            ]);
            navigate(-1);
          },
          onError: (err) => {
            enqueueSnackbar({
              variant: "error",
              message: "Gagal Submit Penawaran",
            });
          },
        }
      );
    },
    [filters, mutatePostOffer, navigate, queryClient]
  );

  const handleReject = useCallback(
    (row) => {
      mutatePostOffer.mutate(
        {
          id: row?.id,
          data: {},
          params: { approval: "reject" },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              variant: "success",
              message: "Berhasil Submit Penawaran",
            });
            queryClient.invalidateQueries([
              "GET_LIST_OFFER_INSTITUTION",
              filters,
            ]);
            navigate(-1);
          },
          onError: (err) => {
            enqueueSnackbar({
              variant: "error",
              message: "Gagal Submit Penawaran",
            });
          },
        }
      );
    },
    [filters, mutatePostOffer, navigate, queryClient]
  );

  const tableColumns = useMemo(
    () => [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px] overflow-hidden",
        render: (value, data, index) => {
          const no =
            // parseInt(paginator?.page) * parseInt(paginator?.limit) -
            // parseInt(paginator?.limit) +
            index + 1;
          return no;
        },
      },
      {
        id: "files",
        title: "Dokumen Penawaran",
        dataIndex: "files",
        columnClassName: "w-[280px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="flex gap-3 items-center">
              <div className="p-[10px] rounded-full bg-primary-100 text-primary-600">
                <RiFile3Line />
              </div>
              <a
                href={data?.files?.url}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer"
              >
                <p className="line-clamp-2 text-sm font-medium">
                  {data?.files?.name ?? "-"}
                </p>
              </a>
            </div>
          );
        },
      },
      {
        id: "status_string",
        title: "Status",
        dataIndex: "status_string",
        columnClassName: "w-[220px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div
              className={classNames(
                "text-xs font-medium w-max py-0.5 px-2 rounded-full",
                statusBadge[value]
              )}
            >
              {value}
            </div>
          );
        },
      },
      {
        id: "#",
        title: "Aksi",
        fixed: "right",
        columnClassName: "w-[296px] overflow-hidden text-center",
        render: (value, data, index) => {
          return (
            <div className="flex gap-1 justify-center items-center">
              {data?.status_string === "Menunggu Keputusan" ? (
                <div className="flex gap-2">
                  <Button
                    type="button"
                    label="Terima"
                    size="sm"
                    className="bg-primary-600 hover:bg-primary-700 text-white"
                    onClick={() => {
                      handleApprove(data);
                    }}
                  />
                  <Button
                    type="button"
                    label="Tolak"
                    size="sm"
                    className="bg-red-600 hover:bg-red-700 text-white"
                    onClick={() => {
                      handleReject(data);
                    }}
                  />
                  <Button
                    type="button"
                    label="Negosiasi"
                    outline
                    size="sm"
                    onClick={() => {
                      navigate(
                        `detail/${data?.id}?status=${data?.status_string}`
                      );
                    }}
                  />
                </div>
              ) : (
                <RiEyeLine
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(
                      `detail/${data?.id}?status=${data?.status_string}`
                    );
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [navigate, handleApprove, handleReject]
  );

  const paginator = useMemo(() => {
    return {
      page: filters.page,
      limit: filters.limit,
      total: listOffer?.meta?.total,
      previous_pages: listOffer?.meta?.previous_page_url,
      next_pages: listOffer?.meta?.next_page_url,
    };
  }, [listOffer, filters]);

  const handleChangePage = useCallback((e) => {
    setFilters((prev) => ({ ...prev, page: e }));
  }, []);

  const handleChangeLimit = useCallback((e) => {
    setFilters((prev) => ({ ...prev, page: 1, limit: e }));
  }, []);

  return (
    <div className="space-y-6">
      <div className="w-full p-10 bg-white rounded-xl border border-gray-200 backdrop-blur-[80px] spcae-y-6">
        <BreadCrumbs
          routes={[
            {
              label: "Daftar Penawaran",
              path: `/penawaran/${group}/${type}`,
            },
          ]}
        />
        <div className="flex flex-row justify-between">
          <div className="flex-1">
            <h1 className="text-2xl font-bold">Penawaran</h1>
          </div>
        </div>
      </div>
      <Table
        columns={tableColumns}
        dataSource={listOffer?.data ?? []}
        isLoading={isLoading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeLimit}
        pagination={paginator}
      />
      {/* <table
        className="w-full bg-white border border-gray-200"
        style={{ boxShadow: "0px 20px 40px 0px rgba(51, 40, 123, 0.04)" }}
      >
        <thead className="bg-primary-800 text-white">
          <tr>
            <th className="px-6 py-3">No</th>
            <th className="px-6 py-3">Dokumen Penawaran</th>
            <th className="px-6 py-3">Status</th>
            <th className="px-6 py-3">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {dataDummy.map((data, index) => (
            <tr key={data.id}>
              <td colSpan={1} className="text-center py-3 px-6">
                {index + 1}
              </td>
              <td
                colSpan={1}
                className="flex justify-center text-center py-3 px-6 gap-2"
              >
                <RiFileFill size={20} />
                {data.document_name}
                <RiDownloadFill size={20} />
              </td>
              <td colSpan={1} className="py-3 px-6">
                {data.status}
              </td>
              <td
                colSpan={1}
                className="flex justify-center py-3 px-6 cursor-pointer"
                onClick={() => handleView(data.id)}
              >
                <BsEyeFill size={20} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="border-t ">
          <tr>
            <td colSpan={6} className="px-6 py-3">
              <FormProvider {...methods}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4 text-sm">
                    <TablePageSizeField />
                    <div>1-5 data dari 1.523 data.</div>
                  </div>
                  <TablePagination
                    page={1}
                    totalPage={10}
                    // onChange={(page) => methods.setValue("page", page)}
                  />
                </div>
              </FormProvider>
            </td>
          </tr>
        </tfoot>
      </table> */}
    </div>
  );
}
