export const FieldLabel = {
  general: {
    GROUP_NAME: "Nama Kelompok",
    DECREE: "SK Pembentukan atau berita acara pembentukan",
    ESTABLISHMENT_DATE: "Tanggal dan Tahun Berdiri",
    AREA: "Kawasan",
    AREA_ACCESS_AGGREMENT: "Dasar Perizinan/Perjanjian Akses Kawasan",
    AREA_ACCESS_AGGREMENT_EXPIRE: "Tahun Berakhirnya Izin Akses/Perjanjian",
    PROVINCE: "Provinsi",
    CITY: "Kota/Kabupaten",
    DISTRICT: "Kecamatan",
    SUBDISTRICT: "Kelurahan/Desa",
    ADDRESS: "Alamat",
    ZIP_CODE: "Kode Pos",
  },
  info: {
    LEADER_NAME: "Nama Ketua Kelompok",
    LEADER_PHONE: "Nomer Telepon Ketua Kelompok",
    LEADER_CID: "NIK KTP Ketua Kelompok",
    LEADER_GENDER: "Jenis Kelamin Ketua Kelompok",
    SECRETARY_NAME: "Nama Sekertaris",
    SECRETARY_PHONE: "Nomer Telepon Sekertaris",
    SECRETARY_GENDER: "Jenis Kelamin Sekertaris",
    TREASURER_NAME: "Nama Bendahara",
    TREASURER_PHONE: "Nomer Telepon Bendahara",
    TREASURER_GENDER: "Jenis Kelamin Bendahara",
    ASSOCIATE_NAME: "Nama Pendamping",
    ASSOCIATE_STATUS: "Status Pendamping",
    ASSOCIATE_PHONE: "Nomor Telepon Pendamping",
    ASSOCIATE_GENDER: "Jenis Kelamin Pendamping",
    PAST_ACTIVITIES: "Kegiatan - Kegiatan Kelompok yang telah dilaksanakan",
    MEMBERSHIP_COUNT: "Jumlah anggota Kelompok yang mengajukan permohonan",
    ANDIL_GARAPAN: "Jumlah Andil Garapan",
    TOTAL_AREA: "Total Luas Lahan",
    MEMBERSHIP_COUNT_ALL:
      "Jumlah anggotaKelompok (Termasuk yang tidak mengajukan pinjaman)",
    ANDIL_GARAPAN_ALL: "Jumlah Andil Garapan",
    TOTAL_AREA_ALL: "Total Luas Lahan",
    PARTNER: "Mitra Usaha Kelompok (Jika Ada)",
    GENERAL_OVERVIEW:
      "Gambaran umum jenis - jenis usaha anggota kelompok yang dibiayai",
    GROUP_INAUGURATION_DEADLINE: "Batas Waktu Pengukuhan Kelompok",
    GROUP_MANAGEMENT_DEADLINE: "Batas Waktu Kepengurusan Kelompok",
    LEGAL_TIME_LIMIT_FOR_GROUP_MEMBER_ACCESS_TO_LAND:
      "Batas waktu legalitas akses kelompok/anggota terhadap lahan",
    MANAGEMENT_DEADLINE: "Batas Akhir Kepengurusan",
  },
  DOCS: "Daftar Dokumen",
  FILE: "Dokumen",
};

export const RequiredDocType = {
  SK: "sk",
  AD: "ad",
  LETTER_OF_RECOMENDATION: "rekomendation",
  SUPPORT_DOCS: "other",
};

export const requiredDocs = [
  {
    title: "Dokumen SK Pembentukan / Berita Acara",
    desc: "dokumen SK Pembentukan / Berita Acara",
    key: RequiredDocType.SK,
  },
  {
    title: "Dokumen AD/ART",
    desc: "Dokumen AD/ART",
    key: RequiredDocType.AD,
  },
  {
    title: "Surat Rekomendasi Pendamping",
    desc: "Surat Rekomendasi Pendamping",
    key: RequiredDocType.LETTER_OF_RECOMENDATION,
  },
  // {
  //   title: "Dokumen Pendukung lainnya",
  //   desc: "Dokumen Pendukung lainnya",
  //   key: RequiredDocType.SUPPORT_DOCS,
  // },
];

export const RequiredDocTypeLembaga = {
  AP: "akta_pendirian",
  DP: "dokumen_perubahan",
  NIB: "dokumen_nib",
  BUSINESS_PERMIT: "dokumen_izin_usaha",
  SKDP: "dokumen_skdp",
  SOP: "dokumen_sop",
};
export const RequiredDocTypeNonLembaga = {
  AP: "akta_pendirian",
  DP: "dokumen_perubahan",
  NIB: "dokumen_nib",
  NIK: "dokumen_nik",
  BUSINESS_PERMIT: "dokumen_izin_usaha",
  SKDP: "dokumen_skdp",
  SOP: "dokumen_sop",
};

export const requiredDocsLembaga = [
  {
    title: "Dokumen Akta Pendirian dan pengesahannya",
    desc: "Dokumen Akta Pendirian dan pengesahannya",
    key: RequiredDocTypeLembaga.AP,
  },
  {
    title: "Dokumen Perubahan dan pengesahannya",
    desc: "Dokumen Perubahan dan pengesahannya",
    key: RequiredDocTypeLembaga.DP,
  },
  {
    title: "Dokumen NIB (no induk usaha) / TDP(optional)",
    desc: "Dokumen NIB (no induk usaha) / TDP(optional)",
    key: RequiredDocTypeLembaga.NIB,
  },
  {
    title: "Dokumen Izin Usaha Berlaku",
    desc: "Dokumen Izin Usaha Berlaku",
    key: RequiredDocTypeLembaga.BUSINESS_PERMIT,
  },
  {
    title: "Dokumen SKDP(Apabila ada)",
    desc: "Dokumen SKDP(Apabila ada)",
    key: RequiredDocTypeLembaga.SKDP,
  },
  {
    title: "Dokumen Standar Operasional (SOP)",
    desc: "Dokumen Standar Operasional (SOP)",
    key: RequiredDocTypeLembaga.SOP,
  },
];
export const requiredDocsNonLembaga = [
  {
    title: "Dokumen Akta Pendirian dan pengesahannya",
    desc: "Dokumen Akta Pendirian dan pengesahannya",
    key: RequiredDocTypeNonLembaga.AP,
  },
  {
    title: "Dokumen Akta Perubahan Dan Pengesahaannya (Jika Ada)",
    desc: "Dokumen Akta Perubahan Dan Pengesahaannya (Jika Ada)",
    key: RequiredDocTypeNonLembaga.DP,
  },
  {
    title: "Dokumen NIK",
    desc: "Dokumen NIK",
    key: RequiredDocTypeNonLembaga.NIK,
  },
  {
    title: "Dokumen NIB/TDP Yang Berlaku",
    desc: "Dokumen NIB/TDP Yang Berlaku",
    key: RequiredDocTypeNonLembaga.NIB,
  },
  {
    title: "Dokumen Izin Usaha (Jika NIB Tidak Ada)",
    desc: "Dokumen Izin Usaha (Jika NIB Tidak Ada)",
    key: RequiredDocTypeNonLembaga.BUSINESS_PERMIT,
  },
  {
    title: "Dokumen SKDP (Jika NIB Tidak Ada)",
    desc: "Dokumen SKDP (Jika NIB Tidak Ada)",
    key: RequiredDocTypeNonLembaga.SKDP,
  },
  {
    title: "Dokumen Standar Operasional (SOP)",
    desc: "Dokumen Standar Operasional (SOP)",
    key: RequiredDocTypeNonLembaga.SOP,
  },
];

export const MAX_FILE_UPLOAD = 10 * 1024 * 1024;
export const ALLOWED_UPLOAD_TYPES = ["png", "jpg", "pdf", "docx"];
