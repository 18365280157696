import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ToastContext } from "components/atoms/Toast";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { useAuthContext } from "providers/authProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import {
  getSafeguards,
  submitSafeguards,
} from "services/danaProgram/safeguards";
import * as yup from "yup";

const useSafeguards = () => {
  const [temp, setTemp] = useState();
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const [confirmDraftModal, setConfirmDraftModal] = useState(false);
  const [safeguardDraftData, setSafeguardDraftData] = useState();
  const { showToastMessage, showToastDescription } = useContext(ToastContext);
  const navigate = useNavigate();

  const user = JSON.parse(
    sessionStorage.getItem(
      "oidc.user:https://identity.bpldh-dev.groot.id:web_app_enduser"
    )
  );

  const userId = localStorage.getItem(`userId`);

  const validationSchema = useMemo(() => {
    return yup.object({
      is_safeguard: yup.boolean().required("Wajib diisi"),
      data_safeguard: yup
        .array()
        .of(
          yup.object().shape({
            desc: yup.string(),
            name: yup.string(),
            period: yup.string(),
            report: yup.mixed(),
          })
        )
        .when("is_safeguard", {
          is: true,
          then: () =>
            yup.array().of(
              yup.object().shape({
                desc: yup.string().required("Deskripsi wajib diisi"),
                name: yup.string().required("Nama Donor wajib diisi"),
                period: yup.string().required("Period Pelaporan wajib diisi"),
                report: yup.mixed(),
              })
            ),
          otherwise: () =>
            yup.array().of(
              yup.object().shape({
                desc: yup.string(),
                name: yup.string(),
                period: yup.string(),
                report: yup.mixed(),
              })
            ),
        }),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      user_id: userId,
      is_safeguard: "",
      data_safeguard: [
        {
          desc: "",
          name: "",
          period: "",
          report: "",
        },
      ],
    },
  });

  const {
    formState: { errors, isValid },
    control,
    setValue,
    getValues,
    reset,
    trigger,
    clearErrors,
  } = methods;

  const formMutation = useFormSafeGuards(reset);

  const {
    fields: dataSafeguardFields,
    append: appendDataSafeguard,
    remove: removeDataSafeguard,
  } = useFieldArray({
    control,
    name: "data_safeguard",
  });

  const {
    data: safeguardsData,
    isLoading: isLoadingSafeguardData,
    isFetching: isFetchingSafeguardData,
    refetch: refetchSafeguardData,
  } = useQuerySafeGuards(userId);

  useEffect(() => {
    const flatData = safeguardsData?.data?.data;
    if (![undefined, null].includes(flatData)) {
      setSafeguardDraftData(flatData);
      if (Object.keys(flatData).length) {
        Object.keys(flatData).forEach((key) => {
          if (typeof flatData[key] === "object") {
            onLoadFile.mutate({ data: flatData[key], key: key, array: true });
          } else {
            setValue(key, flatData[key]);
          }
        });
        trigger();
      }
    }
  }, [safeguardsData, setValue, methods]);

  const consumeAPI = async (data, statusDocs) => {
    const { status, _id, created_at, updated_at, data_safeguard, ...rest } =
      data;
    let params = {
      ...rest,
      data_safeguard: data_safeguard.map((e) => {
        return {
          desc: e.desc,
          name: e.name,
          period: e.period,
          report: e.report.id,
        };
      }),
      status: statusDocs,
    };
    const res = await formMutation.mutateAsync(params);
    return res;
  };

  const onDraftSubmit = async (data) => {
    setTemp(data);
    setConfirmDraftModal(true);
  };

  const onBack = async (data) => {
    setTemp(data);
    setConfirmBackModal(true);
  };

  const onSubmit = async (data) => {
    setTemp(data);
    setConfirmModal(true);
  };

  const onUploadFile = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      let temp = {
        url: response?.data?.data?.url,
        id: response?.data?.data?.id,
        type: response?.data?.data?.mimeType,
        name: response?.data?.data?.name,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const onLoadFile = useMutation(async ({ data, key, array = false }) => {
    try {
      let newResult = [];
      if (array) {
        for (const p in data) {
          let reportResult = await getFile(data[p].report);
          reportResult = {
            url: reportResult?.data?.data?.url,
            id: reportResult?.data?.data?.id,
            type: reportResult?.data?.data?.mimeType,
            name: reportResult?.data?.data?.name,
          };
          newResult.push({
            desc: data[p].desc,
            name: data[p].name,
            period: data[p].period,
            report: reportResult,
          });
        }
      } else {
        let reportResult = await getFile(data);
        reportResult = {
          url: reportResult?.data?.data?.url,
          id: reportResult?.data?.data?.id,
          type: reportResult?.data?.data?.mimeType,
          name: reportResult?.data?.data?.name,
        };
        newResult = reportResult;
      }
      setValue(key, newResult);
      clearErrors();
    } catch (error) {
      throw error;
    }
  });

  const onSubmitConfirmModal = async () => {
    const response = await consumeAPI(temp, "SUBMITTED");
    if (response.status === 200) {
      setConfirmModal(false);
      navigate("/penilaian-lemtara");
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      );
    }
  };

  const onSubmitConfirmBackModal = async () => {
    const response = await consumeAPI(temp, "DRAFTED");
    if (response.status === 200) {
      setConfirmBackModal(false);
      navigate("/penilaian-lemtara");
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      );
    }
  };

  const onSubmitConfirmDraftModal = async () => {
    const response = await consumeAPI(temp, "DRAFTED");
    if (response.status === 200) {
      setConfirmDraftModal(false);
      navigate("/penilaian-lemtara");
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      );
    }
  };

  return {
    ...methods,
    onDraftSubmit,
    onBack,
    onSubmit,
    onSubmitConfirmModal,
    onSubmitConfirmBackModal,
    onSubmitConfirmDraftModal,
    dataSafeguardFields,
    appendDataSafeguard,
    removeDataSafeguard,
    methods,
    temp,
    setTemp,
    consumeAPI,
    dispatch,
    confirmModal,
    setConfirmModal,
    confirmBackModal,
    setConfirmBackModal,
    confirmDraftModal,
    setConfirmDraftModal,
    safeguardDraftData,
    setSafeguardDraftData,
    safeguardsData,
    isLoadingSafeguardData,
    isFetchingSafeguardData,
    refetchSafeguardData,
    onUploadFile,
  };
};

export const useQuerySafeGuards = (userId) => {
  return useQuery(["list-safeguards"], () => getSafeguards(userId), {
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
  });
};

export const useFormSafeGuards = (reset) => {
  return useMutation(async (payload) => submitSafeguards(payload), {
    onSuccess: () => {
      // enqueueSnackbar({
      //   message: "Success",
      //   variant: "success",
      // });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });
};

export default useSafeguards;
