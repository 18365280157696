import { useQuery } from "@tanstack/react-query";
import Alert from "components/atoms/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { useEffect, useMemo, useState } from "react";
import { RiEyeLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Button, Table } from "../../../../../components";
import FormattedDate from "../../../../../components/atoms/FormattedDate";
import Tag from "../../../../../components/atoms/Tag";
import { formatCurrency } from "../../../../../helpers";
import { getPengajuanAnggotaKelompok } from "services/fdb/akunKelompok";
import { getProfileAccount } from "services/fdb/profile";

const Status = {
  DRAFT: "draft",
  WAIT_VERIF: "waiting_for_review",
  VERIFYING: "approved",
  VERIFIED: "approved",
  REJECTED: "rejected",
};

const AkunPeroranganPSPermohonanPembiayaanList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: listData, isFetching: listLoading } = useQuery({
    queryKey: [
      "get-member-list",
      {
        params: {
          paginate: true,
          page: page,
          limit: limit,
        },
      },
    ],
    queryFn: async ({ queryKey }) => {
      const res = await getPengajuanAnggotaKelompok(queryKey[1]);

      return res.data.data;
    },
    retry: 1,
  });

  const { data: profile } = useQuery(
    ["get-active-submission"],
    getProfileAccount,
    { select: (data) => data?.data?.data }
  );

  const paginator = useMemo(() => {
    return {
      page: page,
      limit: limit,
      total: listData?.meta?.total,
      previous_pages: listData?.meta?.previous_page_url,
      next_pages: listData?.meta?.next_page_url,
    };
  }, [listData, page, limit]);

  const tableColumns = useMemo(
    () => [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px] overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "registration_date",
        title: "Tanggal Didaftarkan Permohonan",
        dataIndex: "registration_date",
        columnClassName: "w-[274px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full ">
              <FormattedDate date={value} />
            </div>
          );
        },
      },
      {
        id: "request_date",
        title: "Tanggal Permohonan",
        dataIndex: "request_date",
        columnClassName: "w-[186px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full">
              <FormattedDate date={value} />
            </div>
          );
        },
      },
      {
        id: "value",
        title: "Nilai Permohonan",
        dataIndex: "value",
        columnClassName: "w-[196px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full">
              {formatCurrency(
                data?.social_forestry_applications?.application_value
              )}
            </div>
          );
        },
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "w-[170px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full ">
              {value === Status.DRAFT ? (
                <Tag
                  wrapperClass={
                    "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                  }
                  theme={"neutral"}
                >
                  Draft
                </Tag>
              ) : undefined}
              {value === Status.WAIT_VERIF ? (
                <Tag
                  wrapperClass={
                    "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                  }
                  theme={"warning"}
                >
                  Menunggu Verifikasi
                </Tag>
              ) : undefined}
              {value === Status.REJECTED ? (
                <Tag
                  wrapperClass={
                    "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                  }
                  theme={"error"}
                >
                  Ditolak
                </Tag>
              ) : undefined}
              {value === Status.VERIFIED ? (
                <Tag
                  wrapperClass={
                    "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                  }
                  theme={"success"}
                >
                  Disetujui
                </Tag>
              ) : undefined}
            </div>
          );
        },
      },
      {
        id: "no",
        title: "Aksi",
        dataIndex: "no",
        fixed: "right",
        columnClassName: "w-[220px] overflow-hidden text-center",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full flex justify-center">
              {data.status === Status.DRAFT ? (
                <Button
                  type={"button"}
                  className="bg-[#00BD52] hover:bg-primary-700 text-white py-2 px-3 rounded-lg font-semibold text-[12px]"
                  label={
                    <div className="flex items-center gap-2 font-[14px]">
                      Lanjutkan Permohonan
                    </div>
                  }
                  onClick={() => {
                    navigate(`edit/${data?.applicant_member_id}`, {
                      state: {
                        member: {
                          ...data,
                          group_name: profile?.group_profile?.name,
                        },
                      },
                    });
                  }}
                />
              ) : (
                data.status === Status.WAIT_VERIF && (
                  <Button
                    type={"button"}
                    className="p-4 bg-[transparent] rounded-full flex justify-center"
                    label={<RiEyeLine />}
                    onClick={() => {
                      navigate(`view/${data?.applicant_member_id}`, {
                        state: {
                          member: {
                            ...data,
                            group_name: profile?.group_profile?.name,
                          },
                        },
                      });
                    }}
                  />
                )
              )}

              {data.status === Status.VERIFIED && (
                <Button
                  type={"button"}
                  className="p-4 bg-[transparent] rounded-full flex justify-center"
                  label={<RiEyeLine />}
                  onClick={() => {
                    navigate(`view/${data?.applicant_member_id}`, {
                      state: {
                        member: {
                          ...data,
                          group_name: profile?.group_profile?.name,
                        },
                      },
                    });
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [paginator.limit, paginator.page, navigate, profile?.group_profile]
  );

  const handleChangeLimit = (e) => {
    setLimit(e);
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-auto");

    return () => {
      layouContentEl?.classList?.remove("overflow-auto");
    };
  }, []);

  return (
    <>
      <div className="w-full space-y-4 overflow-hidden">
        <div className="flex flex-col gap-y-6">
          {/* Header Section */}
          <div className="bg-[#FFFFFF] rounded-xl p-10">
            <div className="flex flex-col w-full">
              <BreadCrumbs
                routes={[
                  {
                    label: "Permohonan",
                    path: `/perorangan/non-perhutanan-sosial/permohonan-pembiayaan`,
                  },
                  {
                    label: "Daftar Permohonan",
                    path: location.pathname,
                  },
                ]}
              />
              <div className="mt-6 flex flex-row">
                <div className="flex-1">
                  <div className="flex flex-col">
                    <span className="font-semibold text-[24px] text-[#101828]">
                      Daftar Permohonan
                    </span>
                    <span className="font-normal text-[14px] text-[#667085]">
                      Daftar Permohonan - Non Perhutanan Sosial
                    </span>
                  </div>
                </div>
                <div className="flex-1 text-right">
                  {/* <Button
                    type="primary"
                    className="bg-[#E34F13] text-white py-3 px-4 rounded-lg"
                    label="Buat Permohonan Baru"
                  /> */}
                </div>
              </div>
            </div>
          </div>

          {listData?.results?.length ? (
            <Alert
              theme={"warning"}
              title={"Proses Permohonan Sedang Berjalan"}
              description={
                "Anda tidak bisa menambakan permohonan baru karena masih ada proses permohonan yang berjalan."
              }
            />
          ) : undefined}

          <Table
            columns={tableColumns}
            dataSource={listData?.data}
            layout="fixed"
            isLoading={listLoading}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeLimit}
            pagination={paginator}
          />
        </div>
      </div>
    </>
  );
};

export default AkunPeroranganPSPermohonanPembiayaanList;
