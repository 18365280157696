import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { useMemo, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router";
import Button from "components/atoms/Button/Button";
import { RiEyeLine } from "react-icons/ri";
import { useQuery } from "@tanstack/react-query";
import { getDisbursementList } from "services/fdb/insitution_disbursement";
import { Table } from "components";
import moment from "moment";

export default function DisbursementRequest(props) {
  const { type, group } = props;
  const navigate = useNavigate();

  const createRequest = () => {
    navigate(`/permohonan-pencairan/${group}/${type}/buat-permohonan`);
  };

  const [filters, setFilters] = useState({
    paginate: true,
    page: 1,
    limit: 10,
    filter: {
      search: "",
      date: "",
    },
  });

  const { data, isLoading } = useQuery(
    ["GET_DISBURSEMENT_LIST", filters],
    getDisbursementList,
    {
      select: (data) => data?.data?.data,
    }
  );

  const paginator = useMemo(() => {
    return {
      page: filters.page,
      limit: filters.limit,
      total: data?.meta?.total,
      previous_pages: data?.meta?.previous_page_url,
      next_pages: data?.meta?.next_page_url,
    };
  }, [data, filters]);

  const tableColumns = useMemo(
    () => [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px] overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return no;
        },
      },
      {
        id: "created",
        title: "Tanggal Permohonan",
        dataIndex: "created",
        columnClassName: "w-[220px] overflow-hidden",
        render: (value, data, index) => {
          return value ? moment(value).format("DD MMMM YYYY") : "-";
        },
      },
      {
        id: "value",
        title: "value",
        dataIndex: "value",
        columnClassName: "w-[220px] overflow-hidden",
        render: (value, data, index) => {
          return <div>{`Rp ${Math.floor(value).toLocaleString()}`}</div>;
        },
      },
      {
        id: "status_string",
        title: "Status",
        dataIndex: "status_string",
        columnClassName: "overflow-hidden",
        render: (value, data, index) => {
          return <div className="w-max">{value}</div>;
        },
      },
      {
        id: "document",
        title: "Dokumen Penyaluran",
        dataIndex: "document",
        columnClassName: "w-[220px] overflow-hidden",
        render: (value, data, index) => {
          return <div>{value ?? "-"}</div>;
        },
      },
      {
        id: "#",
        title: "Aksi",
        fixed: "right",
        columnClassName: "w-[296px] overflow-hidden text-center",
        render: (value, data, index) => {
          return (
            <div className="flex gap-1 justify-center items-center">
              {data?.status_string === "Menunggu Keputusan" ? (
                <>
                  <Button
                    type="button"
                    label="Negosiasi"
                    outline
                    size="sm"
                    onClick={() => {
                      navigate(`detail/${data?.id}`);
                    }}
                  />
                </>
              ) : (
                <RiEyeLine
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(`${data?.id}`);
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [navigate, paginator]
  );

  const handleChangePage = (e) => {
    setFilters((prev) => ({ ...prev, page: e }));
  };

  const handleChangeLimit = (e) => {
    setFilters((prev) => ({ ...prev, page: 1, limit: e }));
  };

  return (
    <div className="space-y-6">
      <div className="w-full p-10 bg-white rounded-xl border border-gray-200 backdrop-blur-[80px] spcae-y-6">
        <BreadCrumbs
          routes={[
            {
              label: "Daftar Permohonan Pencairan",
              path: `/permohonan-pencairan/${group}/${type}`,
            },
          ]}
        />
        <div className="flex items-center justify-between">
          <div className="text-gray-900 text-2xl font-semibold leading-loose">
            Daftar Permohonan Pencairan
          </div>
          <div className="justify-start items-center gap-3 inline-flex">
            {/* <Button
              color="error"
              // loading={isLoading}
              label={
                <div className="flex items-center space-x-2">
                  <FiPlus />
                  <p>Buat Permohonan Baru</p>
                </div>
              }
              onClick={createRequest}
            /> */}
          </div>
        </div>
      </div>{" "}
      <Table
        columns={tableColumns}
        dataSource={data?.data ?? []}
        isLoading={isLoading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeLimit}
        pagination={paginator}
      />
    </div>
  );
}
