import { useMask } from "@react-input/mask";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";

const InputMaskForm = ({ name, label, required, asText, ...rest }) => {
  const methods = useFormContext();

  const inputRef = useMask({ mask: rest.mask, replacement: rest.replacement });

  return (
    <Controller
      control={methods.control}
      name={name}
      defaultValue=""
      render={({ field, fieldState }) => (
        <div className="flex flex-col gap-[6px] w-full">
          <div className="flex gap-1 items-start">
            <label className="text-sm font-semibold">{label}</label>
            {required && <p className="font-medium text-red-500">*</p>}
          </div>
          <input
            ref={inputRef}
            value={field.value}
            className={classNames(
              "h-10 border border-gray-200 rounded-md outline-none px-4",
              asText && "bg-transparent border-none !p-0",
              fieldState.error && "!border-error-500"
            )}
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
            {...rest}
          />
          {fieldState.invalid && fieldState.error && (
            <small className="text-xs text-error-500">
              {fieldState.error.message}
            </small>
          )}
        </div>
      )}
    />
  );
};

export default InputMaskForm;
