import Agreement from "pages/FDB/agreement"

const AgreementComp = (props) => () => <Agreement {...props} />

const PageTypeLembaga = {
  bank_ventura: {
    group: "lembaga-penyalur",
    type: "bank-ventura"
  },
  koperasi: {
    group: "lembaga-penyalur",
    type: "koperasi"
  },
  non_koperasi: {
    group: "lembaga-penyalur",
    type: "non-koperasi"
  },
  badan_hukum: {
    group: "lembaga-penyalur",
    type: "badan-hukum"
  },
  bumdes: {
    group: "non-perorangan",
    type: "bumdes"
  },
  cv: {
    group: "non-perorangan",
    type: "cv"
  },
  pt: {
    group: "non-perorangan",
    type: "pt"
  },
  firma: {
    group: "non-perorangan",
    type: "firma"
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/perjanjian/lembaga-penyalur/bank-ventura",
    element: AgreementComp(PageTypeLembaga.bank_ventura),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/perjanjian/lembaga-penyalur/koperasi",
    element: AgreementComp(PageTypeLembaga.koperasi),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/perjanjian/lembaga-penyalur/non-koperasi",
    element: AgreementComp(PageTypeLembaga.non_koperasi),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/perjanjian/lembaga-penyalur/badan-hukum",
    element: AgreementComp(PageTypeLembaga.badan_hukum),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/perjanjian/non-perorangan/bumdes",
    element: AgreementComp(PageTypeLembaga.bumdes),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.bumdes,
  },
  {
    routeType: "private",
    path: "/perjanjian/non-perorangan/cv",
    element: AgreementComp(PageTypeLembaga.cv),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.cv,
  },
  {
    routeType: "private",
    path: "/perjanjian/non-perorangan/pt",
    element: AgreementComp(PageTypeLembaga.pt),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.pt,
  },
  {
    routeType: "private",
    path: "/perjanjian/non-perorangan/firma",
    element: AgreementComp(PageTypeLembaga.firma),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.firma,
  },
]