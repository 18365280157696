import React, { useMemo } from "react";
import { Button, Label } from "components";
import { InputForm } from "components";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import ImageLogin from "assets/ImageLogin.png";
import ImageLogin2 from "assets/ImageLogin2.png";
import iefLogo from "assets/iefLogo.png";
import { validationCustomErrorMsg } from "locale/yup_id";
import { isMatchField } from "helpers/validation";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .required()
    .test(
      "oneNumberOneLowerCaseOneUpperCaseLetter",
      "Minimal 1 huruf kapital dan 1 angka",
      (value) => {
        const regUpperCase = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/g);
        return regUpperCase.test(value);
      }
    )
    .label("Kata Sandi"),
  confirm_password: Yup.string()
    .when(["password"], ([password]) => {
      if (password) {
        return Yup.string()
          .test(
            "confpass",
            validationCustomErrorMsg.matchesField("Kata Sandi"),
            isMatchField("password")
          )
          .required()
          .label("Konfirmasi Kata Sandi");
      }

      return Yup.string().required().label("Konfirmasi Kata Sandi");
    })
    .required()
    .label("Konfirmasi Kata Sandi"),
});

const Login = () => {
  const dispatch = useDispatch();

  const defaultValues = useMemo(
    () => ({
      password: "",
      confirm_password: "",
    }),
    []
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = (payload) => {};

  return (
    <div className="w-screen h-screen flex">
      <div className="flex-1">
        <img
          src={ImageLogin}
          alt="login-banner"
          className="w-full h-full object-cover"
        />
      </div>
      <div
        className={`w-full h-full flex flex-col items-center justify-center flex-1 space-y-20`}
        style={{ backgroundImage: `url(${ImageLogin2})` }}
      >
        <div className="flex items-center">
          <img src={iefLogo} alt="alt" />
        </div>
        <div className="card w-[360px] space-y-8">
          <div className="space-y-3">
            <div className="font-bold text-4xl text-left">Ubah Kata Sandi</div>
            <div className="text-gray-600 text-left">
              Masukkan kata sandi baru Anda untuk mengatur ulang kata sandi
              Default Anda.
            </div>
          </div>
          <FormProvider {...methods}>
            <form
              noValidate
              autoComplete="off"
              className="space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                methods.handleSubmit(onSubmit)();
              }}
            >
              <div className="space-y-5">
                <div className="space-y-2 flex-1">
                  <InputForm
                    controllerName={"password"}
                    className="py-2 px-4 bg-white text-sm text-gray-800 disabled:bg-[#EFEFEF] border-[1px] border-gray-200 rounded-md focus-visible:!outline-none w-full"
                    label="Kata Sandi"
                    placeholder="Masukkan Kata Sandi"
                    required
                  />
                  <span className="text-gray-400 text-xs">
                    *Kata sandi harus 8 karakter (minimal 1 huruf kapital dan 1
                    angka)
                  </span>
                </div>
                <div className="space-y-2 flex-1">
                  <Label required>Password</Label>
                  <InputForm
                    controllerName={"confirm_password"}
                    className="py-2 px-4 bg-white text-sm text-gray-800 disabled:bg-[#EFEFEF] border-[1px] border-gray-200 rounded-md focus-visible:!outline-none w-full"
                    label="Konfirmasi Kata Sandi"
                    placeholder="Masukkan Konfirmasi Kata Sandi"
                    required
                  />
                </div>
              </div>
              <Button
                className="w-full bg-primary-600 border-primary-600 text-white font-medium rounded-lg py-3"
                type={"submit"}
                label="Ubah"
              />
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Login;
