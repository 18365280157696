import AlertBlankData from 'components/molecules/Alert';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import { map, upperFirst } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FiAlertCircle, FiUpload } from 'react-icons/fi';
import { HiChip, HiOutlinePencil } from 'react-icons/hi';
import { RiBankLine, RiCoinsLine, RiComputerLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth } from 'hooks/auth';
import {
  getChannelingDirectApplicationGuarantee,
  getChannelingDirectApplicationFinance,
  getChannelingDirectApplicationITSystem,
  getChannelingDirectApplicationDebtorAccess,
  submitChannelingDirectApplication,
} from 'services';
import Button from 'components/atoms/Button/Button';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';

const SupplierAplication = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useAuth();

  const { data: guaranteeData } = useQuery(['application-guarantee', id], () =>
    getChannelingDirectApplicationGuarantee(id)
  );

  const { data: financeData } = useQuery(['application-finance', id], () =>
    getChannelingDirectApplicationFinance(id)
  );

  const { data: itSystemData } = useQuery(['application-it-system', id], () =>
    getChannelingDirectApplicationITSystem(id)
  );

  const { data: debtorAccess } = useQuery(
    ['application-debtor-access', id],
    () => getChannelingDirectApplicationDebtorAccess(id)
  );

  const isSubmittable = useMemo(() => {
    return (
      guaranteeData?.data?.data?.is_submittable &&
      financeData?.data?.data?.is_submittable &&
      itSystemData?.data?.data?.is_submittable &&
      debtorAccess?.data?.data?.is_submittable
    );
  }, [guaranteeData, financeData, itSystemData, debtorAccess]);

  const moduleItems = useMemo(() => {
    return [
      {
        icon: <RiBankLine className="text-[#1570EF]" />,
        label: 'Permohonan & Jaminan',
        path: `${location.pathname}/aplication-form`,
        background: 'bg-[#D1E9FF]',
        backgroundOuter: 'bg-[#EFF8FF]',
        status: null,
        isSubmittable: guaranteeData?.data?.data?.is_submittable,
        isSubmitted: guaranteeData?.data?.data?.is_submitted,
      },
      {
        icon: <RiCoinsLine className="text-[#099250]" />,
        label: 'Keuangan',
        path: `${location.pathname}/finance/bank`,
        background: 'bg-[#D3F8DF]',
        backgroundOuter: 'bg-[#EDFCF2]',
        status: null,
        isSubmittable: financeData?.data?.data?.is_submittable,
        isSubmitted: financeData?.data?.data?.is_submitted,
      },
      {
        icon: <RiComputerLine className="text-[#E62E05]" />,
        label: 'Akses Debitur',
        path: `${location.pathname}/debtor-access`,
        background: 'bg-[#FFE6D5]',
        backgroundOuter: 'bg-[#FFF4ED]',
        status: null,
        isSubmittable: debtorAccess?.data?.data?.is_submittable,
        isSubmitted: debtorAccess?.data?.data?.is_submitted,
      },
      {
        icon: <HiChip className="text-[#6938EF]" />,
        label: 'Sistem IT',
        path: `${location.pathname}/it-system`,
        background: 'bg-[#EBE9FE]',
        backgroundOuter: 'bg-[#F4F3FF]',
        status: null,
        isSubmittable: itSystemData?.data?.data?.is_submittable,
        isSubmitted: itSystemData?.data?.data?.is_submitted,
      },
    ];
  }, [
    location.pathname,
    guaranteeData,
    financeData,
    itSystemData,
    debtorAccess,
  ]);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      const data = new FormData();
      data.append('user_id', id);
      return submitChannelingDirectApplication(data);
    },
    onSuccess() {
      enqueueSnackbar({
        message: 'Permohonan berhasil diajukan',
        variant: 'success',
      });
      navigate('/bumdes');
    },
    onError(error) {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: 'error',
      });
    },
  });

  const submitApplication = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <div className="space-y-6">
      <div className="w-full p-10 bg-white rounded-xl border border-gray-200 backdrop-blur-[80px] space-y-6">
        <BreadCrumbs
          routes={[
            {
              label: 'Daftar Permohonan',
              path: '/bumdes',
            },
            {
              label: 'Buat Permohonan Baru',
            },
          ]}
        />
        <div className="flex items-center justify-between">
          <div className="text-gray-900 text-2xl font-semibold leading-loose">
            Permohonan Penyalur
          </div>
          <div className="justify-start items-center gap-3 inline-flex">
            <Button
              color="error"
              disabled={!isSubmittable}
              loading={isLoading}
              label={
                <div className="flex items-center space-x-2">
                  {!isLoading && <FiUpload />}
                  <p>Upload Semua Persyaratan</p>
                </div>
              }
              onClick={submitApplication}
            />
          </div>
        </div>
      </div>
      <AlertBlankData />
      <div className="grid grid-cols-3 gap-8">
        {map(
          moduleItems,
          ({
            icon,
            label,
            path,
            background,
            backgroundOuter,
            isSubmittable,
            isSubmitted,
          }) => (
            // <div className="flex flex-col gap-4 bg-white shadow-xl rounded-xl">
            //   <div className="p-4">
            //     <div className="flex items-center gap-2">
            //       <div className={`${backgroundOuter} p-2 rounded-full`}>
            //         <div className={`${background} p-2 rounded-full`}>
            //           {icon}
            //         </div>
            //       </div>
            //       <span>{upperFirst(label)}</span>
            //     </div>
            //   </div>
            //   <div className="px-4">
            //     <AlertBlankData />
            //   </div>
            //   <div className="border" />
            //   <div className="p-4 mb-4">
            //     <div className="text-right">
            //       <button
            //         onClick={() => navigate(path)}
            //         className="bg-[#00BD52] text-white py-2 px-4 rounded-lg font-semibold text-[12px]"
            //       >
            //         Lengkapi
            //       </button>
            //     </div>
            //   </div>
            // </div>
            <div className="bg-white rounded-xl border border-gray-200 flex-col justify-end items-center inline-flex">
              <div className="self-stretch p-6 flex-col justify-start items-start gap-6 flex">
                <div className="self-stretch h-12 justify-start items-center gap-4 inline-flex">
                  <div className={`${backgroundOuter} p-2 rounded-full`}>
                    <div className={`${background} p-2 rounded-full`}>
                      {icon}
                    </div>
                  </div>
                  <div className="grow shrink basis-0 text-gray-900 text-base font-medium leading-normal">
                    {upperFirst(label)}
                  </div>
                </div>

                {!isSubmittable && (
                  <>
                    <AlertBlankData />
                    <div className="pl-2.5 pr-3 py-1 bg-amber-50 rounded-2xl justify-start items-center gap-1 inline-flex">
                      <FiAlertCircle className="text-warning-500 text-sm font-medium leading-tight" />
                      <div className="text-center text-amber-700 text-sm font-medium leading-tight">
                        Data Belum Lengkap
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="self-stretch h-[68px] border-t border-gray-200 flex-col justify-start items-center flex">
                <div className="flex items-center pt-3 px-4 gap-2 flex-col w-full">
                  {isSubmitted ? (
                    <Button
                      block
                      onClick={() => navigate(path)}
                      label={
                        <>
                          <HiOutlinePencil />
                          <span className="text-slate-700 text-sm font-semibold leading-tight">
                            Ubah
                          </span>
                        </>
                      }
                    />
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => navigate(path)}
                      label={'Lengkapi'}
                      block
                    />
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SupplierAplication;
