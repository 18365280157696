import { addOIDCUser, removeOIDCUser } from "redux/oidcSlice";
import store from "../app/store";

export class StorageAuthStateStore {
  constructor({ prefix = "oidc.", store = null } = {}) {
    this._store = store;
    this._prefix = prefix;
  }

  set(key, value) {
    key = this._prefix + key;
    const userData = JSON.parse(value);
    console.log(userData);
    store.dispatch(addOIDCUser(userData));
    return Promise.resolve();
  }

  get(key) {
    key = this._prefix + key;
    let item = store.getState().oidc.oidc_user;
    return Promise.resolve(item);
  }

  remove(key) {
    key = this._prefix + key;
    let item = store.getState().oidc.oidc_user;
    store.dispatch(removeOIDCUser());
    return Promise.resolve(item);
  }

  getAllKeys() {
    var keys = [];
    for (let index = 0; index < this._store.length; index++) {
      let key = this._store.key(index);
      if (key.indexOf(this._prefix) === 0) {
        keys.push(key.substr(this._prefix.length));
      }
    }
    return Promise.resolve(keys);
  }
}
