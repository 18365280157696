import { QueryClient, useQuery } from "@tanstack/react-query";

const client = new QueryClient()

const ProfileUmumGlobalState = (key, initialData) => [
    useQuery([key], () => initialData,
        {enabled: false, initialData}
    ).data,
    (value)=> client.setQueriesData([key], value)
]

export default ProfileUmumGlobalState;