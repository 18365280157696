import { Spinner } from 'components';
import Button from 'components/atoms/Button';
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from 'react-icons/ri';

const BottomFormAction = ({
  backButtonAction = null,
  disableDrafButton = true,
  drafButtonAction = null,
  submitActionButton = null,
  disableButtonSubmit,
  lastStep = false,
  hideDraft = false,
  hideSubmit = false,
  submitButtonProps,
  backButtonProps,
  draftButtonProps,
  isLoading = false,
  draftLabel = '',
}) => {
  return (
    <>
      <div className="fixed bottom-0 left-0 w-full p-3 flex justify-center items-center">
        <div className="flex w-[80%] bg-white rounded-3xl p-6 gap-4 border">
          <div className="flex-1">
            <Button
              {...(backButtonProps || {})}
              className="p-4 border rounded-lg"
              label={
                <div className="flex items-center gap-2">
                  <RiArrowLeftLine />
                  Kembali
                </div>
              }
              onClick={backButtonAction}
            />
          </div>
          {!hideDraft && (
            <div className="">
              <Button
                {...(draftButtonProps || {})}
                className="p-4 border border-gray-400 rounded-lg !bg-white"
                label={
                  <div className="flex items-center gap-2">
                    {draftLabel == '' && <RiSave3Line />}

                    {draftLabel == '' ? 'Simpan Sebagai Draft' : draftLabel}
                  </div>
                }
                disabled={disableDrafButton}
                onClick={drafButtonAction}
              />
            </div>
          )}
          {!hideSubmit && (
            <div className="">
              <Button
                {...(submitButtonProps || {})}
                className={` border rounded-lg ${
                  disableButtonSubmit ? '' : 'bg-[#00BD52] text-white'
                }${isLoading ? 'p-0' : 'p-4'}`}
                label={
                  submitButtonProps?.label || (
                    <>
                      {isLoading ? (
                        <Spinner className={'text-white'} />
                      ) : (
                        <div className="flex items-center gap-2 text-white">
                          {lastStep ? 'Submit' : 'Selanjutnya'}
                          <RiArrowRightLine />
                        </div>
                      )}
                    </>
                  )
                }
                disabled={disableButtonSubmit || isLoading}
                onClick={submitActionButton}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BottomFormAction;
