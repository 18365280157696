import { config_daprog, config_master } from 'app/config';
import axiosInstance from 'app/interceptors';

const headers = {
  'Content-Type': 'application/json',
};

export const list = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/disbursement-application/list`,
    {
      params,
      headers,
    }
  );
};

export const detail = (id) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/disbursement-application?id=${id}`
  );
};

export const getProject = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/disbursement-application/project`,
    {
      params,
      headers,
    }
  );
};

export const getProjectPermohonanPembayaran = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/awp/list?status=dikirim`,
    {
      params,
      headers,
    }
  );
};

export const getProjectOutput = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/disbursement-application/project-output`,
    {
      params,
      headers,
    }
  );
};

export const getProjectOutputPermohonanPembayaran = (params) => {
  return axiosInstance.get(`${config_daprog.BASE_URL}/v1/awp`, {
    params,
    headers,
  });
};

export const getListMasterActivity = (params) => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/KegiatanUtama/list`, {
    params,
    headers,
  });
};

export const getDetailMasterActivity = (id) => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/KegiatanUtama/${id}`);
};

export const getActivity = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/disbursement-application/activity`,
    {
      params,
      headers,
    }
  );
};

export const post = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/disbursement-application/store`,
    payload
  );
};

export const getBudgetAccountList = (params) => {
  return axiosInstance.get(`${config_master.BASE_URL}/v1/BudgetAccount/list`, {
    params,
    headers,
  });
};

export const postApprove = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/cms/disbursement-application/approve`,
    payload
  );
};
