import { yupResolver } from '@hookform/resolvers/yup';
import { BottomFormAction, Button, InputForm, InputFormUpload, Spinner } from 'components';
import AtomSelect from 'components/atoms/Select';
import CardForm from 'components/molecules/CardForm';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import {
  isCitizenIdValid,
  isFileSize,
  isFileTypeMatches,
  isPhoneValid,
  isTaxIdValid,
} from '../../../../helpers/validation';
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from '../../../../locale/yup_id';
import { find } from 'lodash';
import SelectCountry from 'components/organisms/inputs/SelectCountry';
import SelectProvince from 'components/organisms/inputs/SelectProvince';
import SelectCity from 'components/organisms/inputs/SelectCity';
import SelectDistrict from 'components/organisms/inputs/SelectDistrict';
import SelectSubdistrict from 'components/organisms/inputs/SelectServiceTypeNonPerhutananSosial';
import FileInfoField from 'components/atoms/FileInfoField';
import AtomDatePicker from 'components/atoms/Datepicker';
import { useLocation } from 'react-router';
import { DOC_UPLOAD_EXT, MAX_DOC_UPLOAD } from 'app/config';
import { RiDeleteBinLine, RiDownloadCloud2Line, RiUploadCloud2Line } from 'react-icons/ri';

const validationSchema = (typeLembaga) => Yup.object().shape({
  // General Inforamtion
  institution_name: Yup.string().trim().required().label('Nama Lembaga'),
  institution_type: Yup.string().required().label('Jenis Lembaga'),
  business_type: Yup.string()
    .trim()
    .required()
    .label('Jenis Usaha dan/atau Jenis Layanan'),
  email: Yup.string().trim().email().required().label('Email'),
  phone_number: Yup.string()
    .trim()
    .test(
      'phone_number',
      ({ label }) => validationErrorMsg.mixed.default.replace('${path}', label),
      isPhoneValid
    )
    .required()
    .label('No Telepon'),
  country: Yup.string().required().label('Negara'),
  province: Yup.string().required().label('Provinsi'),
  city: Yup.string().required().label('Kota/Kabupaten'),
  district: Yup.string().required().label('Kecamatan'),
  village: Yup.string().required().label('Kelurahan / Desa'),
  postal_code: Yup.string().required().label('Kode Pos'),
  address: Yup.string().trim().required().label('Alamat'),

  // Responsible person inforamtion 
  contact_person: Yup.object().shape({
    name: Yup.string().trim().required().label('Nama Narahubung'),
    position: Yup.string().trim().required().label('Jabatan'),
    email: Yup.string().trim().email().required().label('Email Narahubung'),
    phone_number: Yup.string()
      .trim()
      .required()
      .label('Telepon'),
    ktp_number: Yup.string()
      .trim()
      .required()
      .label('No KTP'),
    ktp: Yup.string().required().label('KTP'),
    npwp_number: Yup.string()
      .trim()
      .notRequired()
      .label('No NPWP'),
    npwp: Yup.string().notRequired().label('NPWP')
  }),

  // Background of Becoming a Distributor

  upload_letter_distribution_intitution: Yup.string().notRequired().label('Upload Surat Menjadi Lembaga Penyalur'),

  // organization structure
  total_members: Yup.string().required().label('Jumlah Anggota'),
  total_branches: Yup.string().required().label('Jumlah Cabang'),
  total_employees: Yup.string().required().label('Jumlah Pegawai'),
  upload_structure_org: Yup.string().required().label('Struktur organisasi'),

  // Legality of the institution
  institution_npwp_number: Yup.string()
    .label('Nomor NPWP'),

  intuition_npwp_doc: Yup.string().label('Dokumen NPWP'),
  deed_number: Yup.string().required().label('Nomor Akta Pendirian Dan Pengesahannya'),
  deed_date: Yup.string().required().label('Nomor Akta Pendirian Dan Pengesahannya'),
  deed_doc: Yup.string().required().label('Dokumen Akta Pendirian Dan pengesahannya Beserta Akta Perubahan'),
  nik_number: Yup.string()
    .when('typeLembaga', ([value], schema) => {
      return typeLembaga === 'koperasi' ? schema.required() : schema.notRequired()
    })
    .label('Nomor NIK (Nomor Induk Koperasi)'),
  nik_date: Yup.string()
    .when('typeLembaga', ([value], schema) => {
      return typeLembaga === 'koperasi' ? schema.required() : schema.notRequired()
    })
    .label('Tanggal Terbit NIK'),
  nik_docs: Yup.string()
    .when('value', ([value], schema) => {
      return typeLembaga === 'koperasi' ? schema.required() : schema.notRequired()
    })
    .label('Dokumen NIK'),
  nib_number: Yup.string().required().label('Nomor NIB/TDP/SKDP/Izin usaha'),
  nib_date: Yup.string().required().label('Tanggal Terbit NIB/TDP/SKDP/Izin usaha'),
  nib_docs: Yup.string().required().label('Dokumen NIB/TDP'),
  supporting_docs: Yup.string().label('Upload Dokumen pendukung lainnya Disatukan (SOP/ Ketetapan Kemenkumham dan dokumen lainnya)'),

  // Financial Information of the Last 3 Years
  finnancial_information: Yup.string().required().label('Informasi Keuangan 3 Tahun Terakhir'),

});

const GeneralInformationForm = ({ onBack, onSubmit, draftData, onDraft }) => {

  const location = useLocation();
  const typeLembaga = location.pathname.split('/')[2];


  const options = useMemo(
    () => [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
    ],
    []
  );

  const countryOptions = useMemo(
    () => [{ value: 'Indonesia', label: 'Indonesia' }],
    []
  );

  // const [typeLembaga, setTypeLembaga] = useState()


  const methods = useForm({
    resolver: yupResolver(validationSchema(typeLembaga)),
    mode: 'onChange',
    defaultValues: {
      supporting_documents: []
    },
  });

  const { append, remove } = useFieldArray({
    control: methods.control,
    name: `supporting_documents`,
  });

  useEffect(() => {
    methods.reset({ ...draftData });
    return;
  }, [draftData, methods]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  const onFormDraft = () => {
    return () => onDraft(methods.getValues());
  };

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const provinceId = useWatch({
    control,
    name: 'province',
  })?.value;
  const cityId = useWatch({
    control,
    name: 'city',
  })?.value;
  const districtId = useWatch({
    control,
    name: 'kecamatan',
  })?.value;

  const getLabel = useCallback(() => {
    switch (typeLembaga) {
      case 'bumdes': return 'Bumdes';
      case 'cv': return 'CV';
      case 'pt': return 'Lembaga';
      case 'firma': return 'Firma';
      default: return '';
    }
  }, [typeLembaga]);

  const orgStructureLabel = useCallback((type) => {
    if (type === 'label') {
      return typeLembaga === 'cv' ? 'Struktur Organisasi, Daftar Direksi & Info Kepengurusan' : 'Struktur Organisasi';
    } else if (type === 'description') {
      return typeLembaga === 'cv' ? 'Upload Struktur Organisasi, Daftar Direksi & Info Kepengurusan Lembaga (Curriculum vitae pengurus Dan kartu tanda penduduk/surat izin mengemudi/paspor pengurus) Disatukan *' : 'Upload Struktur Organisasi CV, Beserta Data Diri Sekutu Aktif & Pasif (KTP/SIM/Paspor,CV) Dijadikan Satu';
    }
    return 'Struktur Organisasi Beserta Daftar Direksi (Direksi/Komisaris/Pemegang Saham)';
  }, [typeLembaga]);

  const getInstitutionInfo = () => {
    switch (typeLembaga) {
      case 'bumdes':
        return 'Bumdes';
      case 'cv':
        return 'Penyalur';
      case 'pt':
        return 'Umum';
      case 'firma':
        return 'Lembaga';
      default:
        return '';
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit()} className="space-y-8">
        <CardForm label="General Informasi">
          <p className="text-lg text-primary-700 font-semibold">
            Informasi {getInstitutionInfo()}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputForm
              controllerName={'institution_name'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={`Nama ${getLabel()}`}
              required
              disabled
            />
            <AtomSelect
              controllerName={'institution_type'}
              options={options}
              className="basic-single mt-2 flex-1"
              label={`Jenis ${getLabel()}`}
              placeholder={'Lembaga Non Bank - Koperasi'}
              required={true}
              wrapperClass={"col-span-1"}
              isError={true}
              disable
            />
            {
              typeLembaga !== 'pt' ? (
                <InputForm
                  controllerName={'business_type'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'Jenis Usaha dan/atau Jenis Layanan'}
                  placeholder={'Jenis usaha dan/atau jenis layanan'}
                  required
                />
              ) : (
                <>
                  <AtomSelect
                    controllerName={'institution_type'}
                    options={options}
                    className="basic-single mt-2 flex-1"
                    label={`Klasifikasi KBLI`}
                    placeholder={'Lembaga Non Bank - Koperasi'}
                    required={true}
                    wrapperClass={"col-span-1"}
                    isError={true}
                  />
                  <AtomSelect
                    controllerName={'institution_type'}
                    options={options}
                    className="basic-single mt-2 flex-1"
                    label={`Jenis usaha (Sesuai KLASIFIKASI BAKU LAPANGAN USAHA INDONESIA/KBLI)`}
                    placeholder={'Lembaga Non Bank - Koperasi'}
                    required={true}
                    wrapperClass={"col-span-1"}
                    isError={true}
                  />
                </>
              )
            }
            {
              typeLembaga !== 'bumdes' && (
                <>
                  <InputForm
                    controllerName={'institution_name'}
                    className={`py-2 px-4 mt-2 border w-full rounded-md`}
                    label={`Nomor NPWP ${getLabel()}`}
                    placeholder={'____-____-___'}
                    required
                    disabled
                  />
                  <InputFormUpload
                    controllerName={`contact_person.ktp`}
                    label={`NPWP ${getLabel()}`}
                    required
                  />
                </>
              )
            }
            <InputForm
              controllerName={'email'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={`Email ${getLabel()}`}
              placeholder={'Email'}
              required
            />
            <InputForm
              controllerName={'phone_number'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'No Telepon'}
              placeholder={'No Telepon'}
              required
            />
            <SelectCountry
              controllerName={'country'}
              options={countryOptions}
              className="basic-single mt-2 flex-1"
              label="Negara"
              required={true}
            />
            <SelectProvince
              controllerName={'province'}
              className="basic-single mt-2 flex-1"
              label="Provinsi"
              required={true}
              selected={draftData?.province}
            />
            <SelectCity
              controllerName={'city'}
              className="basic-single mt-2 flex-1"
              label="Kota/Kabupaten"
              required={true}
              provinceId={provinceId}
            />
            <SelectDistrict
              controllerName={'district'}
              className="basic-single mt-2 flex-1"
              label="Kecamatan"
              required={true}
              cityId={cityId}
            />
            <SelectSubdistrict
              controllerName={'village'}
              className="basic-single mt-2 flex-1"
              label="Kelurahan/Desa"
              required={true}
              districtId={districtId}
            />
            <AtomSelect
              controllerName={'postal_code'}
              options={options}
              className="basic-single mt-2 flex-1"
              label="Kode Pos"
              required={true}
            />
            <InputForm
              controllerName={'address'}
              className={`py-2 px-4 mt-2 border w-full rounded-md`}
              label={'Alamat'}
              placeholder={'Alamat'}
              textArea
              required
            />
          </div>
          <div className="border-b" />
          <div className="space-y-6">
            <p className="text-lg text-primary-700 font-semibold">
              Informasi Penanggung Jawab {'(Narahubung)'}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputForm
                controllerName={'contact_person.name'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Nama Narahubung'}
                placeholder={'Nama Narahubung'}
                required
              />
              <InputForm
                controllerName={'contact_person.position'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Jabatan'}
                placeholder={'Jabatan'}
                required
              />
              <InputForm
                controllerName={'contact_person.email'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Email'}
                placeholder={'Email'}
                required
              />
              <InputForm
                controllerName={'contact_person.phone_number'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Telepon'}
                placeholder={'Telepon'}
                required
              />
              <InputForm
                controllerName={'contact_person.ktp_number'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'No KTP'}
                placeholder={'No KTP'}
                required
              />
              <InputFormUpload
                controllerName={`contact_person.ktp`}
                label={'KTP'}
                required
              />
              <InputForm
                controllerName={'contact_person.npwp_number'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'No NPWP'}
                placeholder={'No NPWP'}
              />
              <InputFormUpload
                controllerName={`contact_person.npwp`}
                label={'NPWP'}
              />
            </div>
          </div>
        </CardForm>
        <CardForm label={"Latar Belakang Menjadi Penyalur"}>
          <div className="gap-4 py-6">
            <div className="space-y-1 mb-5">
              <label htmlFor="" className="font-semibold">Format Surat Menjadi Lembaga Penyalur</label>
              <FileInfoField
                title={'Format Surat Menjadi Lembaga Penyalur'}
                desc={'2 MB'}
                showDownload
                showIcon
              // onDownloadClick={() => }
              />
            </div>

            <div className="space-y-1">
              <InputFormUpload
                required
                controllerName={`upload_letter_distribution_intitution`}
                label={'Upload Surat Menjadi Lembaga Penyalur'}
              />
            </div>
          </div>
        </CardForm>

        <CardForm label={orgStructureLabel('label')}>
          <div className="gap-4 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
              <div className="space-y-1">
                <InputForm
                  controllerName={'total_members'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'Jumlah Anggota'}
                  placeholder={'Jumlah Anggota'}
                  required
                />
                <p className='text-xs text-gray-400'>Jika Tidak Memiliki Anggota, Maka Diisi Angka 0</p>
              </div>
              <div className="space-y-1">
                <InputForm
                  controllerName={'total_branches'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'Jumlah Cabang'}
                  placeholder={'Jumlah Cabang'}
                  required
                />
                <p className='text-xs text-gray-400'>Jika Tidak Memiliki Cabang, Maka Diisi Angka 0</p>
              </div>
              <div className="space-y-1">
                <InputForm
                  controllerName={'total_employees'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'Jumlah Pegawai'}
                  placeholder={'Jumlah Pegawai'}
                  required
                />
                <p className='text-xs text-gray-400'>Jika Tidak Memiliki Pegawai, Maka Diisi Angka 0</p>
              </div>
            </div>

            <div className="space-y-1">
              <InputFormUpload
                required
                controllerName={`upload_structure_org`}
                label={orgStructureLabel('description')}
              />
            </div>
          </div>
        </CardForm>

        <CardForm label='Dokumen Legalitas'>
          <div className="gap-4">
            <LegalityDocuments removeDocument={remove} />
          </div>
        </CardForm>

        <CardForm label="Informasi Keuangan 3 Tahun Terakhir">
          <div className="gap-4 py-6">
            <div className="space-y-1 mb-5">
              <InputFormUpload
                required
                controllerName={`finnancial_information`}
                label={'Informasi Keuangan 3 Tahun Terakhir'}
              />
            </div>
          </div>
        </CardForm>

        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={onFormSubmit()}
          disableDrafButton={false}
          drafButtonAction={onFormDraft()}
          disableButtonSubmit={false}
          lastStep={true}
        />
      </form>
    </FormProvider>
  );
};

const LegalityDocuments = ({ removeDocument }) => {
  const { control } = useFormContext();
  const fields = useWatch({
    control,
    name: 'supporting_documents',
  });

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const uploadDocument = useCallback(
    (field, index) => {
      // setSelectedIndex(index);
      // const data = new FormData();
      // data.append('user_id', id);
      // data.append('name', field.title);
      // data.append('description', field.desc);
      // data.append('file', field.file);
      // mutate(data);
    },
    // [mutate, id, setSelectedIndex]
    [setSelectedIndex]
  );

  const removeDoc = useCallback(
    (index) => {
      removeDocument(index);
    },
    [removeDocument]
  );

  return (
    <table
      className='w-full bg-white border border-gray-200 rounded-lg'
      style={{ boxShadow: '0px 20px 40px 0px rgba(51, 40, 123, 0.04)' }}
    >

      <thead className="bg-primary-800 text-white rounded-t-lg">
        <tr className="rounded-t-lg">
          <td className="px-6 py-3 font-semibold rounded-tl-lg">No</td>
          <td className="px-6 py-3 font-semibold">Nama Dokumen</td>
          <td className="px-6 py-3 font-semibold">Deskripsi</td>
          <td className="px-6 py-3 font-semibold rounded-tr-lg text-center">
            Aksi
          </td>
        </tr>
      </thead>

      <tbody className="divide-y">
        {!fields?.length && (
          <tr className="rounded-b-lg">
            <td colSpan={4} className="px-6 rounded-b-lg py-3 text-center">
              <div className="text-gray-500 text-sm">Belum ada dokumen</div>
            </td>
          </tr>
        )}
        {
          fields?.map((field, index) => (
            <tr key={field.id}>
              <td className="px-6 py-3">{index + 1}</td>
              <td className="px-6 py-3 text-gray-600 text-sm">{field.title}</td>
              <td className="px-6 py-3 text-gray-600 text-sm">
                <div className="space-y-1">
                  <div className="text-sm text-gray-600">{field.desc}</div>
                  <Button
                    type="button"
                    variant="link"
                    color="blue"
                    label={
                      <>
                        <RiDownloadCloud2Line className="text-current" />
                        <span>Unduh Dokumen Rujukan</span>
                      </>
                    }
                  />
                </div>
              </td>
              <td colSpan={1} className="px-6 py-3 text-center">
                <div className="flex gap-4 items-center justify-center">
                  <Button
                    onClick={() => uploadDocument(field, index)}
                    type="button"
                    variant="link"
                    color="primary"
                    label={
                      <>
                        {isLoading && selectedIndex === index ? (
                          <Spinner />
                        ) : (
                          <RiUploadCloud2Line className="text-current text-sm" />
                        )}
                        <span>
                          {isLoading && selectedIndex === index
                            ? 'Uploading...'
                            : 'Upload'}
                        </span>
                      </>
                    }
                  />
                  <Button
                    color="error"
                    variant="link"
                    label={<RiDeleteBinLine class="text-sm" />}
                    onClick={() => removeDoc(index)}
                  />
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>

    </table>
  )
}

export default GeneralInformationForm;
