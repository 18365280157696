import { InputForm } from "components";
import CardForm from "components/molecules/CardForm";
import { FieldLabel } from "../validation-schema";
import { useLocation } from "react-router";

export const Formulir = () => {
  const location = useLocation();
  const isView = location.pathname.includes("view");

  return (
    <CardForm label="Formulir">
      <div className="grid grid-cols-1 gap-4">
        <InputForm
          controllerName={`group_name`}
          className={`py-2 px-4 mt-2 border w-full rounded-md col-span-1`}
          label={FieldLabel.GROUP_NAME}
          placeholder={FieldLabel.GROUP_NAME}
          required
          readOnly
          disabled
          asText={isView}
        />
      </div>
    </CardForm>
  );
};
