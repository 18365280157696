import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getDisbursementList = async ({ queryKey }) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/agency-disbursement`,
    {
      params: queryKey[1],
    }
  );
};

export const getDisbursementDetail = async ({ queryKey }) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/agency-disbursement/${queryKey[1]}`
  );
};

export const postDisbursement = async ({ id, data, params }) => {
  return await axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/agency-disbursement/${id}`,
    data
  );
};
