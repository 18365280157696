import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ConfirmationModal, HeaderLabel } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import StepProgress from "components/molecules/StepProgress";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { AUTOSAVE_DURATION } from "../../../../../app/config";
import ProgressModal from "../../../../../components/molecules/Modal/ProgressModal";
import { getErrorMessage } from "../../../../../helpers";
import useInterval from "../../../../../helpers/useInterval";
import {
  getPartner,
  getRegisNonPerhutananSosialAdditionalDocsDraft,
  getRegisNonPerhutananSosialDraft,
  postFileServices,
  putRegisNonPerhutananSosialDraft,
  putRegisNonPerhutananSosialSubmit,
} from "../../../../../services/fdb/akunKelompok";
import { FieldLabel, requiredDocs, RequiredDocType } from "./constants";
import AkunKelompokNPSFormInfo from "./infoForm";
import AkunKelompokNPSFormDocs from "./legalDocs";
import dayjs from "dayjs";
import { map, find } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationErrorMsg } from "locale/yup_id";
import { isCitizenIdValid } from "helpers/validation";
const TabItems = {
  INFO: 1,
  LEGAL_DOCS: 2,
};

const TabSteps = [
  {
    label: "Informasi Kelompok",
    step: TabItems.INFO,
  },
  {
    label: "Dokumen Legalitas",
    step: TabItems.LEGAL_DOCS,
  },
];

const getPayload = (params) => {
  const payload = {
    name: params?.general?.group_name,
    sk_number: params?.general?.decree,
    social_area_function_id: params?.general?.area,
    founding_date: params?.general?.establishment_date
      ? dayjs(params?.general?.establishment_date).format("YYYY-MM-DD")
      : null,
    agreement_access: params?.general?.area_access_agreement,
    end_year_of_agreement_access:
      params?.general?.area_access_agreement_year_expire,
    province_id: params?.general?.province,
    city_id: params?.general?.city,
    district_id: params?.general?.district,
    village_id: params?.general?.subdistrict,
    zip_code_id: params?.general?.zip_code,
    address: params?.general?.address,
    leader_name: params?.info?.leader_name,
    leader_identity: params?.info?.leader_cid,
    leader_phone: params?.info?.leader_phone,
    leader_gender: params?.info?.leader_gender,
    secretary_name: params?.info?.secretary_name,
    secretary_phone: params?.info?.secretary_phone,
    secretary_gender: params?.info?.secretary_gender,
    treasurer_name: params?.info?.treasurer_name,
    treasurer_phone: params?.info?.treasurer_phone,
    treasurer_gender: params?.info?.treasurer_gender,
    companion_name: params?.info?.associate_name,
    companion_status_id: params?.info?.associate_status,
    companion_phone: params?.info?.associate_phone,
    companion_gender: params?.info?.associate_gender,
    end_date_confirmation: params?.info?.end_date_confirmation
      ? dayjs(params?.info?.end_date_confirmation).format("YYYY-MM-DD")
      : null,
    end_date_permission: params?.info?.end_date_permission
      ? dayjs(params?.info?.end_date_permission).format("YYYY-MM-DD")
      : null,
    end_date_legality: params?.info?.end_date_legality
      ? dayjs(params?.info?.end_date_legality).format("YYYY-MM-DD")
      : null,
    end_date_management: params?.info?.end_date_management
      ? dayjs(params?.info?.end_date_management).format("YYYY-MM-DD")
      : null,
    administration_deadline_date: params?.info?.end_date,
    total_member_not_apply: params?.info?.all_membership_count,
    total_member_not_apply_persil: params?.info?.all_andil_garapan,
    total_member_not_apply_area: params?.info?.all_total_area,
    total_member_apply: params?.info?.membership_count,
    total_member_apply_persil: params?.info?.andil_garapan,
    total_member_apply_area: params?.info?.total_area,
    activities: params?.kegiatan_kelompok_dilaksanakan,
    partners: params?.mitra_usaha_kelompok,

    finance_businesses: params?.gambaran_umum_dibiayai,
    created_from: params?.agree?.created_from,
    documents: params?.docs,
    others: params?.others,
  };

  return payload;
};

const AkunKelompokNPSFormMain = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userId = useSelector((state) => state.profile?.data?.data?._id);
  const token = useSelector((state) => state.oidc.oidc_user.access_token);
  // get mode from path
  const splitPath = location?.pathname?.split("/").filter((e) => !!e);
  const mode = splitPath?.pop();
  const [isEdit, isView] = ["edit", "view"].map((e) => e === mode);

  const infoRef = useRef();
  const docsRef = useRef();

  const [activeStep, setActiveStep] = useState(TabItems.INFO);
  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);
  const [infoInitVal, setInfoInitVal] = useState({
    general: {
      group_name: "",
      decree: "",
      establishment_date: "",
      area: "",
      area_access_agreement: "",
      area_access_agreement_year_expire: "",
      province: "",
      city: "",
      district: "",
      subdistrict: "",
      zip_code: "",
      address: "",
    },
    info: {
      leader_name: "",
      leader_cid: "",
      leader_phone: "",
      leader_gender: "",
      secretary_name: "",
      secretary_phone: "",
      secretary_gender: "",
      treasurer_name: "",
      treasurer_phone: "",
      treasurer_gender: "",
      associate_name: "",
      associate_phone: "",
      associate_status: "",
      associate_gender: "",
      past_activities: "",
      all_membership_count: "",
      all_andil_garapan: "",
      all_total_area: "",
      membership_count: "",
      andil_garapan: "",
      total_area: "",
      partner: "",
      general_overview: "",
      created_in: "",
    },
    docs: "",
    gambaran_umum_dibiayai: "",
    mitra_usaha_kelompok: "",
    kegiatan_kelompok_dilaksanakan: "",
  });
  const [docsInitVal, setDocsInitVal] = useState({
    docs: [],
    others: [],
    created_loc: "",
  });
  // console.log(infoInitVal);
  const routes = useMemo(() => [
    {
      label: "Formulir Pendaftaran",
      path: "/kelompok/non-perhutanan-sosial/formulir-pendaftaran",
    },
    {
      label: "Pendaftaran Kelompok",
      path: "/kelompok/non-perhutanan-sosial/formulir-pendaftaran",
    },
    {
      label: "Formulir Permohonan Layanan",
      path: location.pathname,
    },
  ]);

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-hidden");

    return () => {
      layouContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  // api call
  const draftMutation = useMutation({
    mutationFn: (params) => {
      return putRegisNonPerhutananSosialDraft(token, params);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      if (activeStep === 1) {
        navigate(-1);
      }
      setConfirmModal(false);
      setDraftModal(false);
      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
      setConfirmModal(false);
      setDraftModal(false);
    },
  });
  const { mutateAsync } = useMutation({
    mutationFn: async (params) => {
      return postFileServices(params);
    },
    onSuccess: (res) => {
      enqueueSnackbar({
        message: "File Berhasil Disimpan!",
        variant: "success",
      });
      setConfirmModal(false);
      setDraftModal(false);
      window.scrollTo({ behavior: "smooth", top: 0 });
      return res;
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
      setConfirmModal(false);
      setDraftModal(false);
    },
  });
  const submitMutation = useMutation({
    mutationFn: (params) => {
      return putRegisNonPerhutananSosialDraft(token, params);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      navigate(
        "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan/add"
      );
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  const { data: sourceData } = useQuery({
    queryKey: ["fdb.kelompok.regisform.source", token],
    queryFn: async () => {
      const res = await getRegisNonPerhutananSosialDraft(token);

      return res?.data || {};
    },
    retry: 1,
    refetchOnWindowFocus: false, // disable refetch on window focus as to not override form during filling out form
  });

  const validationSchema = Yup.object().shape({
    general: Yup.object().shape({
      group_name: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.general.GROUP_NAME),
      decree: Yup.string().trim().required().label(FieldLabel.general.DECREE),
      province: Yup.mixed().required().label(FieldLabel.general.PROVINCE),
      city: Yup.mixed().required().label(FieldLabel.general.CITY),
      district: Yup.mixed().required().label(FieldLabel.general.DISTRICT),
      subdistrict: Yup.mixed().required().label(FieldLabel.general.SUBDISTRICT),
      address: Yup.string().trim().required().label(FieldLabel.general.ADDRESS),
    }),
    info: Yup.object().shape({
      leader_name: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.LEADER_NAME),
      leader_phone: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.LEADER_PHONE),
      leader_cid: Yup.string()
        .trim()
        .test("no_ktp", validationErrorMsg.mixed.default, isCitizenIdValid)
        .required()
        .label(FieldLabel.info.LEADER_CID),
      leader_gender: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.LEADER_GENDER),
      secretary_name: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.SECRETARY_NAME),
      secretary_phone: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.SECRETARY_PHONE),
      secretary_gender: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.SECRETARY_GENDER),
      treasurer_name: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.TREASURER_NAME),
      treasurer_phone: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.TREASURER_PHONE),
      treasurer_gender: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.TREASURER_GENDER),
      associate_name: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.ASSOCIATE_NAME),
      associate_phone: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.ASSOCIATE_PHONE),
      associate_status: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.ASSOCIATE_STATUS),
      associate_gender: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.ASSOCIATE_GENDER),
      // past_activities: Yup.string()
      //   .trim()
      //   .test("past_activities", validationErrorMsg.mixed.required, isRTEEmpty)
      //   .required()
      //   .label(FieldLabel.info.PAST_ACTIVITIES),
      membership_count: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.MEMBERSHIP_COUNT),
      andil_garapan: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.ANDIL_GARAPAN),
      total_area: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.TOTAL_AREA),
      all_membership_count: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.MEMBERSHIP_COUNT_ALL),
      all_andil_garapan: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.ANDIL_GARAPAN_ALL),
      all_total_area: Yup.string()
        .trim()
        .required()
        .label(FieldLabel.info.TOTAL_AREA_ALL),
      // partner: Yup.string().trim().label(FieldLabel.info.PARTNER),
      // general_overview: Yup.string()
      //   .trim()
      //   .required()
      //   .label(FieldLabel.info.GENERAL_OVERVIEW),
    }),
    mitra_usaha_kelompok: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().label("Mitra Usaha Kelompok"),
      })
    ),
    kegiatan_kelompok_dilaksanakan: Yup.array().of(
      Yup.object().shape({
        activity_category_id: Yup.string().required().label("Category"),
        description: Yup.string().required().label("Deskripsi"),
      })
    ),
    gambaran_umum_dibiayai: Yup.array().of(
      Yup.object().shape({
        finance_business_category_id: Yup.string().required().label("Category"),
        description: Yup.string().required().label("Deskripsi"),
      })
    ),
  });
  const methods = useForm({
    resolver: isView ? undefined : yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      ...infoInitVal,
      mitra_usaha_kelompok: [{ name: "" }],
      kegiatan_kelompok_dilaksanakan: [
        { activity_category_id: "", description: "" },
      ],
      gambaran_umum_dibiayai: [
        { finance_business_category_id: "", description: "" },
      ],
    },
  });

  useEffect(() => {
    const initialDocs = requiredDocs.map((doc) => {
      const matchingDocument = sourceData?.data.documents.find(
        (d) => d.type === doc.key
      );
      if (matchingDocument) {
        return {
          ...doc,
          file: { name: matchingDocument?.name },
          id: matchingDocument.id,
          doc_id: matchingDocument.doc_id,
          type: matchingDocument.type,
          name: matchingDocument.name,
          original: matchingDocument.link,
          required: true,
        };
      } else {
        return doc;
      }
    });

    // Handle documents that are not in initialDocs
    sourceData?.data.documents.forEach((doc) => {
      if (doc.type !== "other") {
        const isDocumentInInitialDocs = initialDocs.some(
          (d) => d.type === doc.type
        );

        if (!isDocumentInInitialDocs) {
          initialDocs.push({
            title: doc.name, // Assuming name should be the title
            desc: "",
            key: doc.type,
            file: { name: doc?.name },
            required: false, // Assuming non-required by default
            id: doc.id,
            doc_id: doc.doc_id,
            type: doc.type,
            name: doc.name,
            original: doc.link,
          });
        }
      } else {
        initialDocs.push({
          title: doc.name,
          desc: "Dokumen Pendukung lainnya",
          key: doc.type,
          file: { name: doc?.name },
          required: false,
          id: doc.id,
          doc_id: doc.doc_id,
          type: doc.type,
          name: doc.name,
          original: doc.link,
        });
      }
    });

    // Set the updated initialDocs state
    // setInitialDocs(initialDocs);
    // }
    // console.log("initialDocs", initialDocs);

    if (sourceData) {
      const activitiesArr = map(sourceData?.data?.activities, (item) => {
        return {
          id: item?.id || undefined,
          activity_category_id: item?.activity_category_id || "",
          description: item?.description || "",
        };
      });
      const partnersArr = map(sourceData?.data?.partners, (item) => {
        return {
          id: item?.id || undefined,
          name: item?.name || "",
        };
      });
      const financeArr = map(sourceData?.data?.finance_businesses, (item) => {
        return {
          id: item?.id || undefined,
          finance_business_category_id:
            item?.finance_business_category_id || "",
          description: item?.description || "",
        };
      });
      methods.reset({
        general: {
          group_name: sourceData?.data.name,
          decree: sourceData?.data.sk_number,
          establishment_date: sourceData?.data?.founding_date
            ? new Date(sourceData?.data?.founding_date)
            : null,
          area: sourceData?.data.social_area_function_id,
          area_access_agreement: sourceData?.data.agreement_access,
          area_access_agreement_year_expire:
            sourceData?.data.end_year_of_agreement_access,
          province: sourceData?.data.province_id,
          city: sourceData?.data.city_id,
          district: sourceData?.data.district_id,
          subdistrict: sourceData?.data.village_id,
          zip_code: sourceData?.data.zip_code_id,
          address: sourceData?.data.address,
        },
        info: {
          leader_name: sourceData?.data.leader_name,
          leader_cid: sourceData?.data.leader_identity,
          leader_phone: sourceData?.data.leader_phone,
          leader_gender: sourceData?.data.leader_gender,
          secretary_name: sourceData?.data.secretary_name,
          secretary_phone: sourceData?.data.secretary_phone,
          secretary_gender: sourceData?.data.secretary_gender,
          treasurer_name: sourceData?.data.treasurer_name,
          treasurer_phone: sourceData?.data.treasurer_phone,
          treasurer_gender: sourceData?.data.treasurer_gender,
          associate_name: sourceData?.data.companion_name,
          associate_phone: sourceData?.data.companion_phone,
          associate_status: sourceData?.data.companion_status_id,
          associate_gender: sourceData?.data.companion_gender,
          past_activities: sourceData?.data.activities,
          all_membership_count: sourceData?.data.total_member_not_apply,
          all_andil_garapan: sourceData?.data.total_member_not_apply_persil,
          all_total_area: sourceData?.data.total_member_not_apply_area,
          membership_count: sourceData?.data.total_member_apply,
          andil_garapan: sourceData?.data.total_member_apply_persil,
          total_area: sourceData?.data.total_member_apply_area,
          partner: sourceData?.data.partners,
          general_overview: sourceData?.data.general_description,
          end_date_confirmation: sourceData?.data?.end_date_confirmation
            ? new Date(sourceData?.data?.end_date_confirmation)
            : null,
          end_date_permission: sourceData?.data?.end_date_permission
            ? new Date(sourceData?.data?.end_date_permission)
            : null,
          end_date_legality: sourceData?.data?.end_date_legality
            ? new Date(sourceData?.data?.end_date_legality)
            : null,
          end_date_management: sourceData?.data?.end_date_management
            ? new Date(sourceData?.data?.end_date_management)
            : null,
        },
        agree: {
          created_from: sourceData?.data?.created_from,
        },
        docs: initialDocs,
        others: [],
        gambaran_umum_dibiayai:
          sourceData?.data?.finance_businesses.length > 0 && financeArr,
        mitra_usaha_kelompok:
          sourceData?.data?.partners.length > 0 && partnersArr,
        kegiatan_kelompok_dilaksanakan:
          sourceData?.data?.activities?.length > 0 && activitiesArr,
      });
    }
    setDocsInitVal((state) => ({
      ...state,
      docs: initialDocs,
      created_at: new Date().toISOString(),
      created_loc: sourceData?.data.create_in,
    }));
  }, [sourceData, methods]);

  // console.log(methods.watch());

  const onBack = () => {
    if (activeStep === 1) {
      navigate("/kelompok/non-perhutanan-sosial/formulir-pendaftaran");
    } else {
      // setActiveStep(activeStep - 1);
      navigate(0);
    }
  };

  const onSubmit = (payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));
    const payloadTemp = getPayload(payload);
    // console.log("submit payloads", payloadTemp);
    if (activeStep < TabSteps.length) {
      if (!isView) doSaveDraft(payloadTemp);
      setActiveStep(activeStep + 1);
    } else {
      if (!isView) setConfirmModal(true);
    }
  };

  const onSaveDraft = (payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));

    setConfirmModal(false);
    setDraftModal(true);
  };

  const queryClient = useQueryClient();

  const doSaveDraft = async (payloadTemp) => {
    let payload = getPayload(bodyToSend);

    if (
      payloadTemp &&
      payloadTemp.hasOwnProperty("type") &&
      payloadTemp.hasOwnProperty("nativeEvent")
    ) {
      payload = payload;
    } else {
      payload = payloadTemp;
    }

    // console.log("payload", payload);

    const formData = new FormData();
    // Create a JSON object
    const jsonPayload = {};
    // Function to append files with IDs to formData
    const appendFilesWithIds = async (idFile, fileKey, fileInput) => {
      // console.log("fileInput", fileKey, fileInput);

      const fileFormData = new FormData();
      fileFormData.append("file", fileInput);
      const response = await mutateAsync(fileFormData);
      // console.log("RESPONSE2", response);
      if (response.status === 200) {
        const fileId = response.data.data.id;
        formData.append(fileKey, fileId);
        const newDocument = {
          id: idFile ? idFile : "",
          doc_id: fileId,
          type: fileKey,
          name: fileInput.name,
        };
        // Ensure 'documents' array is initialized before pushing
        jsonPayload.documents = jsonPayload.documents || [];

        // Push the new document into the array
        jsonPayload.documents.push(newDocument);
      }
    };

    // Append each key-value pair to the FormData object
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (key === "documents" || key === "others") {
          // If the key is 'documents', search for specific file keys inside it
          const documents = payload[key];
          for (const document of documents) {
            const file = document.file;
            const docId = document.id;

            if (file instanceof File) {
              const docKey = document.key;
              // If the value associated with 'file' is a File object
              const typeKey = docKey ?? "other";
              // console.log("typeKey", docKey);
              const matchedDoc = docsInitVal.docs.find(
                (doc) => doc.type === typeKey && doc.id === docId
              );
              const idFile = matchedDoc?.id;
              await appendFilesWithIds(idFile, docKey, file);
            } else {
              // If the document payload is not a file, append object with existing sourceData
              const sourceData = docsInitVal.docs.find(
                (doc) => doc.id === docId
              );
              if (sourceData && sourceData?.file?.name) {
                // Filter and push object based on condition
                const filteredObject = {
                  id: sourceData?.id,
                  doc_id: sourceData?.doc_id,
                  type: sourceData?.type,
                  name: sourceData?.file.name,
                };
                jsonPayload.documents = jsonPayload.documents || [];
                jsonPayload.documents.push(filteredObject);
              }
            }
          }
        } else {
          if (Array.isArray(payload[key])) {
            // Handle array of objects
            jsonPayload[key] = payload[key].map((item) => {
              const newItem = {};
              for (const itemKey in item) {
                if (item.hasOwnProperty(itemKey)) {
                  newItem[itemKey] = item[itemKey];
                }
              }
              return newItem;
            });
          } else {
            // Handle non-array values
            jsonPayload[key] = payload[key];
          }
        }
      }
    }

    // Add additional fields if needed
    jsonPayload["submit_type"] = "draft";

    // Log the JSON object
    // console.log("JSON Payload", jsonPayload);

    // Call draftMutation.mutate with the JSON object
    draftMutation.mutate(jsonPayload, {
      onSuccess: () => {
        console.log("DO SAFE DRAFT HERE HERE");
        methods.setValue("gambaran_umum_dibiayai", undefined);
        methods.setValue("mitra_usaha_kelompok", undefined);
        methods.setValue("kegiatan_kelompok_dilaksanakan", undefined);
        queryClient.invalidateQueries([
          ["fdb.kelompok.regisform.source", token],
        ]);
      },
    });
  };

  const doSubmit = async (payloadTemp) => {
    let payload = getPayload(bodyToSend);
    // console.log(payloadTemp);

    if (
      payloadTemp &&
      payloadTemp.hasOwnProperty("type") &&
      payloadTemp.hasOwnProperty("nativeEvent")
    ) {
      payload = payload;
    } else {
      payload = payloadTemp;
    }

    // console.log("payload", payload);

    const formData = new FormData();
    const jsonPayload = {};
    // Function to append files with IDs to formData
    const appendFilesWithIds = async (idFile, fileKey, fileInput) => {
      // console.log("fileInput", fileKey, fileInput);

      const fileFormData = new FormData();
      fileFormData.append("file", fileInput);
      const response = await mutateAsync(fileFormData);
      // console.log("RESPONSE2", response);
      if (response.status === 200) {
        const fileId = response.data.data.id;
        formData.append(fileKey, fileId);
        const newDocument = {
          id: idFile ? idFile : "",
          doc_id: fileId,
          type: fileKey,
          name: fileInput.name,
        };
        // Ensure 'documents' array is initialized before pushing
        jsonPayload.documents = jsonPayload.documents || [];

        // Push the new document into the array
        jsonPayload.documents.push(newDocument);
      }
    };

    // Append each key-value pair to the FormData object
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (key === "documents" || key === "others") {
          // If the key is 'documents', search for specific file keys inside it
          const documents = payload[key];
          for (const document of documents) {
            const file = document.file;
            const docId = document.id;

            if (file instanceof File) {
              const docKey = document.key;
              // If the value associated with 'file' is a File object
              const typeKey = docKey ?? "other";
              // console.log("typeKey", docKey);
              const matchedDoc = docsInitVal.docs.find(
                (doc) => doc.type === typeKey && doc.id === docId
              );
              const idFile = matchedDoc?.id;
              await appendFilesWithIds(idFile, docKey, file);
            } else {
              // If the document payload is not a file, append object with existing sourceData
              const sourceData = docsInitVal.docs.find(
                (doc) => doc.id === docId
              );
              if (sourceData && sourceData?.file?.name) {
                // Filter and push object based on condition
                const filteredObject = {
                  id: sourceData?.id,
                  doc_id: sourceData?.doc_id,
                  type: sourceData?.type,
                  name: sourceData?.file.name,
                };
                jsonPayload.documents = jsonPayload.documents || [];
                jsonPayload.documents.push(filteredObject);
              }
            }
          }
        } else {
          if (Array.isArray(payload[key])) {
            // Handle array of objects
            jsonPayload[key] = payload[key].map((item) => {
              const newItem = {};
              for (const itemKey in item) {
                if (item.hasOwnProperty(itemKey)) {
                  newItem[itemKey] = item[itemKey];
                }
              }
              return newItem;
            });
          } else {
            // Handle non-array values
            jsonPayload[key] = payload[key];
          }
        }
      }
    }

    jsonPayload["submit_type"] = "submit";
    // Log the contents of formData
    // for (const [formDataKey, formDataValue] of formData.entries()) {
    //   console.log(`${formDataKey}: ${formDataValue}`);
    // }

    // console.log("formData", Object.fromEntries(formData));
    // console.log("jsonPayload", jsonPayload);
    submitMutation.mutate(jsonPayload);
  };

  useInterval(() => {
    const infoPayload = infoRef.current?.getFormValues() || {};
    const docsPayload = infoRef.current?.getFormValues() || {};
    setBodyToSend((state) => ({ ...state, ...infoPayload, ...docsPayload }));

    doSaveDraft();
  }, AUTOSAVE_DURATION);

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onClose={setDraftModal}
          onSubmit={doSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={doSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={
            (draftModal && draftMutation.isLoading) ||
            (confirmModal && submitMutation.isLoading)
          }
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text="Formulir Permohonan Kelompok"
          description="Buat permohonan baru."
        />

        <div className="border-b" />

        <div className="w-full flex justify-center">
          <div className="w-[800px] mb-10">
            <StepProgress stepProgressData={TabSteps} activeStep={activeStep} />
          </div>
        </div>

        <FormProvider {...methods}>
          <div>
            {activeStep === 1 && (
              <AkunKelompokNPSFormInfo
                ref={infoRef}
                onBack={onBack}
                onSubmit={onSubmit}
                onSaveDraft={onSaveDraft}
                initialValues={infoInitVal}
                mode={mode}
              />
            )}

            {activeStep === 2 && (
              <AkunKelompokNPSFormDocs
                ref={docsRef}
                onBack={onBack}
                onSubmit={onSubmit}
                onSaveDraft={onSaveDraft}
                initialValues={docsInitVal}
                mode={mode}
              />
            )}
          </div>
        </FormProvider>
      </div>
    </>
  );
};

export default AkunKelompokNPSFormMain;
