import React from "react";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import CardForm from "components/molecules/CardForm";
import { BottomFormAction, InputForm } from "components";
import AtomDatePicker from "components/atoms/Datepicker";

const BusinessLicenseForm = ({ onBack, onSubmit, onDraft, props }) => {
  const isKoperasi = props.type === "koperasi";
  const validationSchema =
    props === "koperasi"
      ? Yup.object().shape({
          nomor_nik: Yup.string()
            .required("Nomor NIK (Nomor Induk Koperasi) wajib diisi")
            .matches(
              /^[0-9]+$/,
              "Nomor NIK (Nomor Induk Koperasi) number must contain only digits"
            ),
          tanggal_terbit_nik: Yup.string().required(
            "Tanggal Terbit NIK wajib diisi"
          ),
          no_nib: Yup.string()
            .required("Nomor NIB wajib diisi")
            .matches(/^[0-9]+$/, "Nomor NIB number must contain only digits"),
          nib_issue_date: Yup.string().required(
            "Tanggal Terbit NIB wajib diisi"
          ),
        })
      : Yup.object().shape({
          no_nib: Yup.string()
            .required("Nomor NIB wajib diisi")
            .matches(/^[0-9]+$/, "Nomor NIB number must contain only digits"),
          nib_issue_date: Yup.string().required(
            "Tanggal Terbit NIB wajib diisi"
          ),
        });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit()} className="space-y-8">
        <CardForm label="Perijinan Usaha">
          <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
            {isKoperasi ? (
              <>
                <InputForm
                  controllerName={"nomor_nik"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={"Nomor Akta Pendirian Dan Pengesahannya"}
                  placeholder={"Nomor Akta Pendirian Dan Pengesahannya"}
                  required
                />
                <AtomDatePicker
                  controllerName={`tanggal_terbit_nik`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label="Tanggal Terbit"
                  placeholder="Tanggal Terbit"
                  required
                  datepickerOptions={{
                    maxDate: new Date().setDate(new Date().getDate() + 1),
                  }}
                  showErrorLabel
                />
                <InputForm
                  controllerName={"nomor_nik"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={"Nomor NIK (Nomor Induk Koperasi)"}
                  placeholder={"Nomor NIK (Nomor Induk Koperasi)"}
                  required
                />
                <AtomDatePicker
                  controllerName={`tanggal_terbit_nik`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label="Tanggal Terbit NIK"
                  placeholder="Tanggal Terbit NIK"
                  required
                  datepickerOptions={{
                    maxDate: new Date().setDate(new Date().getDate() + 1),
                  }}
                  showErrorLabel
                />
              </>
            ) : null}
            <InputForm
              controllerName={"no_nib"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Nomor NIB"}
              placeholder={"Nomor NIB"}
              required
            />
            <AtomDatePicker
              controllerName={"nib_issue_date"}
              label="Tanggal Terbit NIB"
              placeholder={"Tanggal Terbit NIB"}
              required
              showErrorLabel
              datepickerOptions={{
                maxDate: new Date().setDate(new Date().getDate() + 1),
              }}
            />
            <InputForm
              controllerName={"no_business_license"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Nomor Ijin Usaha (Jika Ada)"}
              placeholder={"Nomor Ijin Usaha (Jika Ada)"}
            />
            <AtomDatePicker
              controllerName={"business_license_date"}
              label="Tanggal Terbit Nomor Ijin Usaha"
              placeholder="Tanggal Terbit Nomor Ijin Usaha"
            />
          </div>
        </CardForm>
        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={onFormSubmit()}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
        />
        <div className="border-b" />
      </form>
    </FormProvider>
  );
};

export default BusinessLicenseForm;
