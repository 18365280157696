import React, { useState, Fragment, useEffect } from 'react';
import { Button, InputForm } from 'components';
import CardForm from 'components/molecules/CardForm';
import AtomSelect from 'components/atoms/Select';
import AtomDatePicker from 'components/atoms/Datepicker';
import RangeDatepicker from 'components/atoms/RangeDatepicker';
import ReactSelectCreatable from 'react-select/creatable';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import AccordionButton from 'components/atoms/AccordionButton';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { BiArrowBack } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa';
import _ from 'lodash';
import FileDetail from 'components/molecules/FileDetail';
import { useMutation } from '@tanstack/react-query';
import { fileUpload } from 'services/danaProgram/fileService';
import { postResultActivityReport } from 'services/danaProgram/callForProposalService';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const ResultActivity = ({ data = null, onNext, onBack }) => {
  const [safeguards, setSafeGuards] = useState([]);
  const [selectedSafeguard, setSelectedSafeguard] = useState(0);
  const [fileDoc, setFileDoc] = useState([]);
  const [accordion, setAccordion] = useState([false]);
  const defaultValues = {
    safeguard: [
      {
        principle_name: "",
        activity_list: [
          {
            activity: "",
            description: "",
            document: undefined
          }
        ]
      }
    ]
  };
  const navigate = useNavigate();
  const userId = localStorage.getItem(`userId`);
  const methods = useForm({
    defaultValues
  });  
  const { control } = methods;

  const changeFormatDateFromAPI = (date) => {
    // Split the date string into day, month, and year
    var parts = date.split("/");

    // Construct a new date string in the format yyyy/mm/dd
    var newDateString = parts[2] + "/" + parts[1] + "/" + parts[0];
    
    return newDateString;
  }

  const onSubmit = (data) => {
    if(
      data?.document?.every(file => file instanceof File)
    ) {
      postFileResultActivity.mutate(data);
    } else {
      postData.mutate(data);
    }
  }

  const postFileResultActivity = useMutation({
    mutationKey: ["post-file-result-activity"],
    mutationFn: async (data) => {
      const promise = await data.document.map(
        async (item) => {
          const formData = new FormData();
          formData.append("file", item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          }
        }
      );

      return Promise.all(promise);
    },
    onSuccess: async (res) => {
      postFileSafeguard.mutate({
        ...methods.getValues(),
        document: res
      });
      methods.setValue('document', res);
    }
  });

  const postFileSafeguard = useMutation({
    mutationKey: ["post-file-safeguard"],
    mutationFn: async (data) => {
      const promise = data.safeguards.map(
        async (item) => {
          const formData = new FormData();
          formData.append("file", item.document);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          }
        }
      );

      return Promise.all(promise);
    },
    onSuccess: async (res) => {
      postData.mutate({
        ...methods.getValues(),
        safeguards: methods.getValues('safeguards').map((safeguard, i) => ({
          ...safeguard,
          document: res[i]
        }))
      });
    }
  })

  const postData = useMutation({
    mutationKey: ["post-data-result-activity"],
    mutationFn: async (data) => {
      const res = await postResultActivityReport(data);
      return res;
    },
    onSuccess: (res) => {
      onNext();
    }
  })

  useEffect(() => {
    if(!_.isEmpty(data)) {
      methods.reset({
        id: data?._id,
        user_id: userId,
        proposal_id: data?.proposal_id,
        work_plan_id: data?.work_plan_id,
        proposal_name: typeof data?.proposal_name === "object" ? data?.proposal_name : { value: data?.proposal_name, label: data?.proposal_name },
        activity_name: typeof data?.activity_name === "object" ? data?.activity_name : { value: data?.activity_name, label: data?.activity_name },
        output: typeof data?.output === "object" ? data?.output : { value: data?.output, label: data?.output },
        activity_date: data?.activity_date instanceof Array ? 
          [
            new Date(data?.activity_date[0]),
            new Date(data?.activity_date[1]),
          ] :
          [
            new Date(changeFormatDateFromAPI(data?.activity_date.split(" - ")[0])),
            new Date(changeFormatDateFromAPI(data?.activity_date.split(" - ")[1]))
          ],
        activity_goal: typeof data?.activity_goal === "object" ? data?.activity_goal : { value: data?.activity_goal, label: data?.activity_goal },
        partner: typeof data?.partner === "object" ? data?.partner : { value: data?.partner, label: data?.partner },
        total_man: data?.total_man,
        total_woman: data?.total_woman,
        activity_result: data?.activity_result,
        document: data?.document,
        safeguard: data?.safeguard
      });
      
      setFileDoc(data?.document);
      setSafeGuards(data?.safeguard);
    }
  }, [data]);

  const ActivitySection = ({ index, safeguardIndex, accordion, setAccordion }) => {
    return (
      <Fragment>
        <AccordionButton
          label={`Kegiatan ${index + 1}`}
          isOpen={accordion?.[safeguardIndex]}
          onClick={() => setAccordion(safeguardIndex)}
        />
        <div
          className={`${
            accordion?.[safeguardIndex]
              ? "opacity-1 h-full visible"
              : "opacity-0 h-0 invisible max-h-0"
          }`}
          style={{ transition: "all .2s" }}
        >
          <div className="space-y-4">
            <InputForm
              controllerName={`safeguard.${safeguardIndex}.activity_list.${index}.activity`}
              label={"Kegiatan"}
              className="w-full text-sm"
            />
            <InputForm
              controllerName={`safeguard.${safeguardIndex}.activity_list.${index}.description`}
              label={"Deskripsi"}
              className="w-full text-sm"
            />
            <div className="space-y-2">
              <div className="text-[#1D2939] font-semibold text-sm">
                Dokumen Pendukung
              </div>
              <InputFormDropzone
                name={`safeguard.${safeguardIndex}.activity_list.${index}.document`}
                accept={{
                  "image/png": [],
                  "image/jpeg": [],
                  "application/pdf": [],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [],
                }}
                maxFiles={10}
                maxSize={10}
                multiple={true}
                informationText="PNG, JPG, PDF, Docx"
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  const { 
    fields: activityField,
    append: appendActivityField 
  } = useFieldArray({
    control,
    name: `safeguard.${selectedSafeguard}.activity_list`,
  });

  const accordionToggle = (key) => {
    let temp = [...accordion];
    temp[key] = !temp[key];

    setAccordion(temp);
  }

  return (
    <FormProvider {...methods}>
      <CardForm label="Pelaksanaan Kegiatan">
        <AtomSelect
          controllerName={`proposal_name`}
          label={"Nama Project"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          options={[]}
          disable={true}
        />
        <AtomSelect
          controllerName={`activity_name`}
          label={"Kegiatan Utama"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          options={[]}
          disable={true}
        />
        <AtomSelect
          controllerName={`output`}
          label={"Output"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          options={[]}
          disable={true}
        />
        <AtomDatePicker
          controllerName={"activity_date"}
          label="Tanggal Kegiatan"
          isRangeDatePicker
        />
        <div className="font-semibold text-[14px]">
          <label className="flex gap-1">Tujuan Kegiatan</label>
          <ReactSelectCreatable
            name="activity_goal"
            value={methods.watch("activity_goal")}
            isMulti
            className="text-sm"
          />
        </div>
        <div className="font-semibold text-[14px]">
          <label className="flex gap-1">Mitra/Pemangku yang dilibatkan</label>
          <ReactSelectCreatable
            name="partner"
            value={methods.watch("partner")}
            isMulti
            className="text-sm"
          />
        </div>
        <div className="space-y-4 font-semibold text-[16px]">
          <label>Keterlibatan</label>
          <div className="flex space-x-4 w-1/2">
            <div className="">
              <InputForm
                controllerName={`total_woman`}
                label="Perempuan"
              />
            </div>
            <div className="">
              <InputForm
                controllerName={`total_man`}
                label="Laki-laki"
              />
            </div>
          </div>
        </div>
      </CardForm>
      <CardForm label="Hasil Kegiatan">
        <InputForm
          controllerName={`activity_result`}
          label={"Hasil Kegiatan dan atau kemajuan"}
          textArea={true}
          rows={5}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
        />
        <div className="space-y-2">
          <div className="text-[#1D2939] font-semibold text-sm">
            Upload Dokumen Bukti Pelaksanaan kegiatan 
          </div>
          {fileDoc.length > 0 ? (
            fileDoc.map((item, key) => (
              <FileDetail
                key={'file-doc-' + key}
                file={item}
                onRemove={() => {
                  const filtered = fileDoc.filter(
                    (file) => file.fileId !== item.fileId
                  );

                  setFileDoc(filtered);
                }}
              />
            ))
          ) : (
            <InputFormDropzone
              name="document"
              accept={{
                "image/png": [],
                "image/jpeg": [],
                "application/pdf": [],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [],
              }}
              maxFiles={10}
              maxSize={10}
              multiple={true}
              informationText="PNG, JPG, PDF, Docx"
            />
          )}
        </div>
      </CardForm>
      <CardForm label={"Safeguard yang Diimplementasi"}>
        <div className="flex w-[1100px] min-h-[60px] mx-auto justify-center px-4 border border-gray-300 overflow-x-hidden">
          <div className="flex w-full overflow-scroll no-scrollbar [&>div]:flex-shrink-0">
            {safeguards.map((item) => (
              <div className={`min-w-[75px] bg-white p-4 border-b-2 border-primary-700 font-bold`}>
                {item?.principle_name}
              </div>
            ))}
          </div>
        </div>
        {activityField.map((el, key) => (
          <ActivitySection 
            key={"activity-section-" + key}
            control={control}
            index={key}
            safeguardIndex={selectedSafeguard}
            accordion={accordion}
            setAccordion={accordionToggle}
          />
        ))}
        <div className="flex justify-end mt-4 w-full">
          <Button
            className="bg-white border-[#D0D5DD]"
            onClick={() => {
              appendActivityField({ activity: "", description: "", document: undefined })
            }}
            label={
              <div className="flex items-center self-center">
                <FaPlus size={16} className="mr-2" />
                Kegiatan Utama
              </div>
            }
          />
        </div>
      </CardForm>
      <div className="flex justify-end space-x-4 sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
        <Button
          onClick={() => navigate(-1)}
          label={
            <div className="flex items-center gap-2">
              <BiArrowBack />
              <span>Kembali</span>
            </div>
          }
          size="md"
          className="bg-white"
        />
        <Button
          onClick={() => methods.handleSubmit(onSubmit)()}
          label="Selanjutnya"
          size="md"
          className="bg-primary-600 hover:bg-primary-600/90 text-white"
        />
      </div>
    </FormProvider>
  )
};

export default ResultActivity;