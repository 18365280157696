import axiosInstance from "app/interceptors";
import axios from "axios";

// Penyaluran Langsung

export const changeStatusChannelingDirect = (userId, params) => {
  return axiosInstance.put(`api/fdb/v1/channeling-direct/${userId}`, params);
};

export const registerChannelingDirect = (params) => {
  return axiosInstance.post(`api/fdb/v1/channeling-direct/register`, params);
};

export const submitChannelingDirect = (params) => {
  return axiosInstance.post(`api/fdb/v1/channeling-direct/submit`, params);
};

// Penyaluran Langsung - Profil Umum

export const getChannelingDirectProfile = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/profile/${userId}`,
    params
  );
};

export const updateChannelingDirectProfile = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/profile/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectProfile = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/profile/draft/${userId}`,
    params
  );
};

// Penyaluran Langsung - Kelembagaan

export const getChannelingDirectInstitutional = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/${userId}`,
    params
  );
};

// Bumdes

export const updateChannelingDirectInstitutionalBumdes = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/institutional/bumdes/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectInstitutionalBumdes = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/institutional/bumdes/draft/${userId}`,
    params
  );
};

// Advisor

export const getChannelingDirectInstitutionalAdvisor = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/advisor/${userId}`,
    params
  );
};

// Operational

export const getChannelingDirectInstitutionalOperational = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/operational/${userId}`,
    params
  );
};

// Supervisor

export const getChannelingDirectInstitutionalSupervisor = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/supervisor/${userId}`,
    params
  );
};

// ActiveAlly

export const getChannelingDirectInstitutionalActiveAlly = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/institutional/active-ally/${userId}`,
    params
  );
};

// InactiveAlly

export const getChannelingDirectInstitutionalInactiveAlly = (
  userId,
  params
) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/inactive-ally/${userId}`,
    params
  );
};

// CV

export const updateChannelingDirectInstitutionalCV = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/institutional/cv/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectInstitutionalCV = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/institutional/cv/draft/${userId}`,
    params
  );
};

// PT

export const updateChannelingDirectInstitutionalPT = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/institutional/pt/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectInstitutionalPT = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/institutional/pt/draft/${userId}`,
    params
  );
};

// Firma

export const updateChannelingDirectInstitutionalFirma = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/institutional/firma/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectInstitutionalFirma = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/institutional/firma/draft/${userId}`,
    params
  );
};

// Director

export const getChannelingDirectInstitutionalDirector = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/director/${userId}`,
    params
  );
};

export const getDraftChannelingDirectInstitutionalDirector = (
  userId,
  params
) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/director/draft/${userId}`,
    params
  );
};

// Management

export const getChannelingDirectInstitutionalManagement = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/management/${userId}`,
    params
  );
};

export const getDraftChannelingDirectInstitutionalManagement = (
  userId,
  params
) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/management/draft/${userId}`,
    params
  );
};

// OtherDocument

export const getChannelingDirectInstitutionalOtherDocument = (
  userId,
  params
) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/other-document/${userId}`,
    params
  );
};

export const getDraftChannelingDirectInstitutionalOtherDocument = (
  userId,
  params
) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/institutional/other-document/draft/${userId}`,
    params
  );
};

export const postChannelingDirectInstitutionalOtherDocument = (
  userId,
  params
) => {
  return axiosInstance.post(
    `api/fdb/v1/channeling-direct/institutional/other-document/${userId}`,
    params
  );
};

export const deleteChannelingDirectInstitutionalOtherDocument = (
  docId,
  params
) => {
  return axiosInstance.post(
    `api/fdb/v1/channeling-direct/institutional/other-document/${docId}`,
    params
  );
};

// Penyaluran Langsung - Keuangan

export const getChannelingDirectFinance = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/finance/${userId}`,
    params
  );
};

export const updateChannelingDirectFinance = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/finance/${userId}`,
    params
  );
};

export const getDraftChannelingDirectFinance = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/finance/draft/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectFinance = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/finance/draft/${userId}`,
    params
  );
};

// Penyaluran Langsung - Pengajuan

export const getChannelingDirectApplication = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/application/${userId}`,
    params
  );
};

export const createChannelingDirectApplication = (params) => {
  return axiosInstance.post(
    `api/fdb/v1/channeling-direct/application/create`,
    params
  );
};

export const submitChannelingDirectApplication = (params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/application/submit`,
    params
  );
};

export const putChannelingDirectApplicationById = (appId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/${appId}`,
    params
  );
};

export const getChannelingDirectApplicationGuarantee = (userId) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/application/guarantee/${userId}`
  );
};

export const updateChannelingDirectApplicationGuarantee = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/guarantee/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectApplicationGuarantee = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/guarantee/${userId}`,
    params
  );
};

export const getChannelingDirectApplicationGuaranteeDocument = (
  userId,
  params
) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/application/guarantee/${userId}`,
    params
  );
};

export const getDraftChannelingDirectApplicationGuaranteeDocument = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/guarantee/${userId}`,
    params
  );
};

export const createChannelingDirectApplicationGuaranteeDocument = (params) => {
  return axiosInstance.post(
    `api/fdb/v1/channeling-direct/application/guarantee`,
    params
  );
};

export const deleteChannelingDirectApplicationGuaranteeDocument = (docId) => {
  return axiosInstance.delete(
    `api/fdb/v1/channeling-direct/application/guarantee/${docId}`
  );
};

export const getChannelingDirectApplicationFinance = (userId) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/application/finance/${userId}`
  );
};

export const updateChannelingDirectApplicationFinanceBumdes = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/finance/bumdes/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectApplicationFinanceBumdes = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/finance/bumdes/draft/${userId}`,
    params
  );
};

export const updateChannelingDirectApplicationFinanceNonBumdes = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/finance/non-bumdes/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectApplicationFinanceNonBumdes = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/finance/non-bumdes/draft/${userId}`,
    params
  );
};

export const getChannelingDirectApplicationFinanceDocument = (
  userId,
  params
) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/application/finance/document/${userId}`,
    params
  );
};

export const getDraftChannelingDirectApplicationFinanceDocument = (
  userId,
  params
) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/application/finance/document/draft/${userId}`,
    params
  );
};

export const createChannelingDirectApplicationFinanceDocument = (params) => {
  return axiosInstance.post(
    `api/fdb/v1/channeling-direct/application/finance/document`,
    params
  );
};

export const deleteChannelingDirectApplicationFinanceDocument = (docId) => {
  return axiosInstance.delete(
    `api/fdb/v1/channeling-direct/application/finance/document/${docId}`
  );
};

// Penyaluran Langsung - IT System

export const getChannelingDirectApplicationITSystem = (userId) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/application/it-system/${userId}`
  );
};

export const updateChannelingDirectApplicationITSystem = (userId, params) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/it-system/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectApplicationITSystem = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/it-system/draft/${userId}`,
    params
  );
};

// Penyaluran Langsung - Debtor Access

export const getChannelingDirectApplicationDebtorAccess = (userId, params) => {
  return axiosInstance.get(
    `api/fdb/v1/channeling-direct/application/debtor-access/${userId}`,
    params
  );
};

export const updateChannelingDirectApplicationDebtorAccess = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/debtor-access/${userId}`,
    params
  );
};

export const updateDraftChannelingDirectApplicationDebtorAccess = (
  userId,
  params
) => {
  return axiosInstance.put(
    `api/fdb/v1/channeling-direct/application/debtor-access/draft/${userId}`,
    params
  );
};
