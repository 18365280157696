import axios from "axios";
import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  "Content-Type": "application/json",
};

const user = JSON.parse(
  sessionStorage.getItem(
    "oidc.user:https://identity.bpldh-dev.groot.id:web_app_enduser"
  )
);

const userId = localStorage.getItem("userId");

export const resourcesSubmit = (params) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/resource/submit`,
    params,
    {
      headers,
    }
  );
};
export const getResources = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/resource/findByUserId/${userId}`,
    params,
    { headers }
  );
};
