import React from "react";
import { InputForm } from "../../../index";

const InputKTP = React.forwardRef((props, ref) => {
  const { className, ...restProps } = props;
  
  return (
    <InputForm
      className={[`py-2 px-4 mt-2 border w-full rounded-md flex-1`, className].join(" ")}
      mask="################"
      {...restProps}
    />
  );
});

InputKTP.propTypes = {};

export default InputKTP;