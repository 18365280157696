import { useSelector } from 'react-redux'

export function useAuth() {
  const {userId} = useSelector(({ auth }) => auth);

  const storeUserId = (id) => {
    localStorage.setItem('auth_user_id', id)
  }

  return {
    id: userId,
    storeUserId
  }
}