import { config_fdb, config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

export const getMasterActivityCategory = () => {
  return axiosInstance.get(
    `${config_fdb.BASE_URL}/v1/master-data/activities-category`
  );
};
export const getActivityCategory = (UserId) => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/activity-categories`, {
    headers: {
      Authorization: `Bearer ${UserId}`,
    },
  });
};
