import React from "react";

import { AuthConsumer } from "../../providers/authProvider";
import { Spinner } from "components";

export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return (
        <div className="relative min-h-screen min-w-screen flex items-center justify-center">
          <Spinner />
        </div>
      );
    }}
  </AuthConsumer>
);
