import { AnimatePresence, motion } from "framer-motion";
import styles from "./index.module.css";

const ProgressModal = ({
  open,
  children,
  className = ""
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          <motion.div
            key="modal-confirmation"
            className="bg-white rounded-xl min-w-[400px] max-w-[400px]"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div className={`px-4 py-8 ${className}`}>
              {children || (
                <div className={'text-center'}>
                  <div className={'text-primary-500 text-center text-[0.45rem]'}>
                    <div className={[styles["lds-ring"], ''].join(' ')}>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  
                  <div className={"text-lg"}>
                    <span className={styles["anim-ellipsis"]}>Sedang melakukan proses</span>
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ProgressModal;
