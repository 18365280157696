import { yupResolver } from "@hookform/resolvers/yup";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormUpload,
} from "components";
import FileInfoField from "components/atoms/FileInfoField";
import CardForm from "components/molecules/CardForm";
import AsyncDropzoneFieldForm from "components/molecules/async-dropzone-field-form";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import {
  itSystemLembagaValidator,
  itSystemNonPeroranganValidator,
} from "./ValidationSchema/itSystem.scheme";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAgencyRequestById,
  patchAgencyRequestItSystem,
} from "services/fdb/lembagaPenyalur";
import { getFile } from "services/danaProgram/fileService";
import { humanizeFileSize } from "helpers";

const SystemITForm = ({ prefix, onSubmit, onBack }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const request_id = urlParams.get("request");
  const url = location.pathname.split("/");

  const is_view = url[4] === "view" ? true : false;

  const methods = useForm({
    resolver: yupResolver(
      url[2] === "non-perorangan"
        ? itSystemNonPeroranganValidator
        : itSystemLembagaValidator
    ),
    defaultValues: {
      oprationals: [
        {
          name: "",
          description: "",
          file: "",
        },
      ],
    },
  });

  const { control } = methods;

  const { data: dataIT } = useQuery({
    queryKey: ["request-profile-by-id"],
    queryFn: () => (request_id ? getAgencyRequestById(request_id) : null),
    select: (data) => data?.data?.data?.it_system,
  });

  const mutateAgencyRequsetItSystem = useMutation(
    ["PATCH AGENCY REQUEST IT SYSTEM"],
    ({ data, params }) => {
      return patchAgencyRequestItSystem(data, params);
    }
  );

  useEffect(() => {
    methods.reset({
      institution_availability_file: dataIT?.institution_availability_file,
      oprationals: dataIT?.agency_it_system_oprational?.length
        ? dataIT?.agency_it_system_oprational.map((dataOpertional) => {
          return {
            name: dataOpertional?.name,
            description: dataOpertional?.description,
            file: dataOpertional?.file,
          };
        })
        : [
          {
            name: "",
            description: "",
            file: "",
          },
        ],
    });
  }, [
    dataIT?.agency_it_system_oprational,
    dataIT?.institution_availability_file,
    methods,
  ]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "oprationals",
  });

  const onFormDraft = () => {
    const data = methods.getValues();

    mutateAgencyRequsetItSystem.mutate(
      {
        data,
        params: {
          request_id: request_id ?? null,
          is_draft: true,
          agency_type:
            url[2] === "non-perorangan" ? "Non Perorangan" : "Lembaga Penyalur",
        },
      },
      {
        onSuccess: () => navigate(`/permohonan-pembiayaan/${url[2]}/${url[3]}`),
      }
    );
  };
  const onFormSubmit = () => {
    if (is_view) {
      navigate(`/permohonan-pembiayaan/${url[2]}/${url[3]}`);
      return;
    }
    methods.handleSubmit((data) =>
      mutateAgencyRequsetItSystem.mutate(
        {
          data,
          params: {
            request_id: request_id ?? null,
            is_draft: false,
            agency_type:
              url[2] === "non-perorangan"
                ? "Non Perorangan"
                : "Lembaga Penyalur",
          },
        },
        {
          onSuccess: () =>
            navigate(`/permohonan-pembiayaan/${url[2]}/${url[3]}`),
        }
      )
    )();
  };

  const openDocsFile = async () => {
    window.open(fileIT?.url, "_blank");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const [fileIT, setFileIT] = useState();

  const handleGetFile = useCallback(async () => {
    const response = await getFile("e90ce7cf-c44e-4e92-907d-ad86877c19e5");

    setFileIT(response?.data?.data);
  }, []);

  useEffect(() => {
    handleGetFile();
  }, [handleGetFile]);

  return (
    <FormProvider {...methods}>
      <div className="space-y-10">
        <CardForm label="Ketersediaan Sistem IT Lembaga">
          <div className="space-y-6">
            <div className="space-y-1 mb-5">
              <label htmlFor="" className="font-semibold">
                Format Ketersediaan Sistem IT
              </label>
              <FileInfoField
                title={"Format Ketersediaan Sistem IT"}
                desc={humanizeFileSize(fileIT?.size)}
                showDownload
                showIcon
                onDownloadClick={() => openDocsFile()}
              />
            </div>
            <AsyncDropzoneFieldForm
              maxFiles={1}
              informationText="PNG, JPG, PDF, DOCX"
              name={`institution_availability_file`}
              label={"Upload Hasil Ketersediaan Sistem IT"}
              maxSize={10}
              isView={is_view}
            />
          </div>
        </CardForm>
        <CardForm label="Ketersediaan SOP Sistem IT">
          <div className="space-y-6">
            {fields.map((field, index) => {
              return (
                <>
                  <div
                    className="grid grid-cols-1 md:grid-cols-2 gap-4"
                    key={field.id}
                  >
                    <InputForm
                      placeholder="Nama SOP"
                      required
                      label={"Standard Operasional"}
                      controllerName={`oprationals.${index}.name`}
                      className={
                        "py-2 px-4 mt-2 border w-full rounded-md flex-1"
                      }
                      asText={is_view}
                    />
                    <InputForm
                      placeholder="Keterangan"
                      label={""}
                      controllerName={`oprationals.${index}.description`}
                      className={
                        "py-2 px-4 mt-6 border w-full rounded-md flex-1"
                      }
                      asText={is_view}
                    />
                  </div>

                  <AsyncDropzoneFieldForm
                    maxFiles={1}
                    informationText="PNG, JPG, PDF, DOCX"
                    name={`oprationals.${index}.file`}
                    maxSize={10}
                    isView={is_view}
                  />
                  {index > 0 && !is_view && (
                    <div className="flex justify-end pt-3">
                      <Button
                        label={
                          <>
                            <RiDeleteBin6Line size={20} />
                            Hapus
                          </>
                        }
                        theme="error"
                        size="sm"
                        type="button"
                        className="text-white h-10"
                        onClick={() => remove(field.id)}
                      />
                    </div>
                  )}
                </>
              );
            })}
            <div className="border-b" />
            <div className="flex flex-row-reverse my-5 mx-4 ">
              {!is_view && (
                <Button
                  className="p-4 border rounded-lg"
                  label={
                    <div className="flex items-center gap-2 font-[14px]">
                      <RiAddLine />
                      Tambah SOP
                    </div>
                  }
                  onClick={() => {
                    append({
                      name: "",
                      description: "",
                      file: "",
                    });
                  }}
                />
              )}
            </div>
          </div>
        </CardForm>
      </div>
      <BottomFormAction
        lastStep={true}
        backButtonProps={{
          type: "button",
        }}
        draftButtonProps={{
          type: "button",
        }}
        submitButtonProps={{
          type: "submit",
        }}
        submitActionButton={onFormSubmit}
        disableDrafButton={is_view ? true : false}
        drafButtonAction={onFormDraft}
        backButtonAction={handleBack}
      />
    </FormProvider>
  );
};

export default SystemITForm;
