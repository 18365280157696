import React, { Fragment, useState } from 'react';
import Stepper from '../components/Stepper';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import { HeaderLabel } from 'components';

import BasicInformation from './BasicInformation';
import NegativeFilter from './NegativeFilter';
import InitiatorCapacity from './InitiatorCapacity';
import RiskClassification from './RiskClassification';
import Screening from './Screening';
import MitigationPlan from './MitigationPlan';
import Commitment from './Commitment';
import Documents from './Documents';
// import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router';

const stepperItems = [
  {
    label: 'Informasi Dasar',
    id: 'basic_info',
    component: <BasicInformation />,
  },
  {
    label: 'Penyaringan Daftar Negative',
    id: 'negative_filter',
    component: <NegativeFilter />,
  },
  {
    label: 'Kapasitas Pemrakarsa',
    id: 'initiator_capicity',
    component: <InitiatorCapacity />,
  },
  {
    label: 'Klasifikasi Tingkat Resiko',
    id: 'risk_clarification',
    component: <RiskClassification />,
  },
  {
    label: 'Penapisan',
    id: 'screening',
    component: <Screening />,
  },
  {
    label: 'Komitmen Perlindungan Lingkungan dan Sosial',
    id: 'mitigation_plan',
    // component: <MitigationPlan />,
    component: <Commitment />,
  },
  {
    label: 'Dokumen Pendukung',
    id: 'document',
    component: <Documents />,
  },
];

const Safeguards = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [setPayload] = useState({});
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const onNext = (type, values) => {
    setCurrentStep((curr) => curr + 1);
    setPayload((curr) => ({
      ...curr,
      [type]: {
        ...curr[type],
        ...values,
      },
    }));
  };

  const onBack = () => {
    if (currentStep >= 1) setCurrentStep((curr) => curr - 1);
    else navigate(-1);
  };

  return (
    <Fragment>
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10 space-y-8">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: 'Call For Proposal',
                  path: `/proposal/call-for-proposal`,
                },
                {
                  label: 'Pengajuan Full Proposal',
                },
              ]}
            />
            <div className="flex mt-5 gap-4">
              <div className="flex-1">
                <HeaderLabel text="Safeguards" />
              </div>
            </div>
          </div>
          <hr />
          <div className="w-full mt-6 mb-6 flex justify-center items-center">
            <Stepper currentStep={currentStep} items={stepperItems} />
          </div>
          {React.cloneElement(stepperItems[currentStep].component, {
            type: stepperItems[currentStep].id,
            onNext,
            onBack,
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default Safeguards;
