import { yupResolver } from '@hookform/resolvers/yup';
import { BottomFormAction, InputForm, InputFormUpload } from 'components';
import AtomSelect from 'components/atoms/Select';
import CardForm from 'components/molecules/CardForm';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import {
  isCitizenIdValid,
  isFileSize,
  isFileTypeMatches,
  isPhoneValid,
  isTaxIdValid,
} from '../../../../helpers/validation';
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from '../../../../locale/yup_id';
import { find } from 'lodash';
import SelectCountry from 'components/organisms/inputs/SelectCountry';
import SelectProvince from 'components/organisms/inputs/SelectProvince';
import SelectCity from 'components/organisms/inputs/SelectCity';
import SelectDistrict from 'components/organisms/inputs/SelectDistrict';
import SelectSubdistrict from 'components/organisms/inputs/SelectServiceTypeNonPerhutananSosial';
import FileInfoField from 'components/atoms/FileInfoField';
import AtomDatePicker from 'components/atoms/Datepicker';
import { useLocation } from 'react-router';
import { DOC_UPLOAD_EXT, MAX_DOC_UPLOAD } from 'app/config';

const validationSchema = (typeLembaga) => Yup.object().shape({
  // General Inforamtion
  institution_name: Yup.string().trim().required().label('Nama Lembaga'),
  institution_type: Yup.string().required().label('Jenis Lembaga'),
  business_type: Yup.string()
    .trim()
    .required()
    .label('Jenis Usaha dan/atau Jenis Layanan'),
  email: Yup.string().trim().email().required().label('Email'),
  phone_number: Yup.string()
    .trim()
    .test(
      'phone_number',
      ({ label }) => validationErrorMsg.mixed.default.replace('${path}', label),
      isPhoneValid
    )
    .required()
    .label('No Telepon'),
  country: Yup.string().required().label('Negara'),
  province: Yup.string().required().label('Provinsi'),
  city: Yup.string().required().label('Kota/Kabupaten'),
  district: Yup.string().required().label('Kecamatan'),
  village: Yup.string().required().label('Kelurahan / Desa'),
  postal_code: Yup.string().required().label('Kode Pos'),
  address: Yup.string().trim().required().label('Alamat'),

  // Responsible person inforamtion 
  contact_person: Yup.object().shape({
    name: Yup.string().trim().required().label('Nama Narahubung'),
    position: Yup.string().trim().required().label('Jabatan'),
    email: Yup.string().trim().email().required().label('Email Narahubung'),
    phone_number: Yup.string()
      .trim()
      .required()
      .label('Telepon'),
    ktp_number: Yup.string()
      .trim()
      .required()
      .label('No KTP'),
    ktp: Yup.string().required().label('KTP'),
    npwp_number: Yup.string()
      .trim()
      .notRequired()
      .label('No NPWP'),
    npwp: Yup.string().notRequired().label('NPWP')
  }),

  // Background of Becoming a Distributor

  upload_letter_distribution_intitution: Yup.string().notRequired().label('Upload Surat Menjadi Lembaga Penyalur'),

  // organization structure
  total_members: Yup.string().required().label('Jumlah Anggota'),
  total_branches: Yup.string().required().label('Jumlah Cabang'),
  total_employees: Yup.string().required().label('Jumlah Pegawai'),
  upload_structure_org: Yup.string().required().label('Struktur organisasi'),

  // Legality of the institution
  institution_npwp_number: Yup.string()
    .label('Nomor NPWP'),

  intuition_npwp_doc: Yup.string().label('Dokumen NPWP'),
  deed_number: Yup.string().required().label('Nomor Akta Pendirian Dan Pengesahannya'),
  deed_date: Yup.string().required().label('Nomor Akta Pendirian Dan Pengesahannya'),
  deed_doc: Yup.string().required().label('Dokumen Akta Pendirian Dan pengesahannya Beserta Akta Perubahan'),
  nik_number: Yup.string()
    .when('typeLembaga', ([value], schema) => {
      return typeLembaga === 'koperasi' ? schema.required() : schema.notRequired()
    })
    .label('Nomor NIK (Nomor Induk Koperasi)'),
  nik_date: Yup.string()
    .when('typeLembaga', ([value], schema) => {
      return typeLembaga === 'koperasi' ? schema.required() : schema.notRequired()
    })
    .label('Tanggal Terbit NIK'),
  nik_docs: Yup.string()
    .when('value', ([value], schema) => {
      return typeLembaga === 'koperasi' ? schema.required() : schema.notRequired()
    })
    .label('Dokumen NIK'),
  nib_number: Yup.string().required().label('Nomor NIB/TDP/SKDP/Izin usaha'),
  nib_date: Yup.string().required().label('Tanggal Terbit NIB/TDP/SKDP/Izin usaha'),
  nib_docs: Yup.string().required().label('Dokumen NIB/TDP'),
  supporting_docs: Yup.string().label('Upload Dokumen pendukung lainnya Disatukan (SOP/ Ketetapan Kemenkumham dan dokumen lainnya)'),

  // Financial Information of the Last 3 Years
  finnancial_information: Yup.string().required().label('Informasi Keuangan 3 Tahun Terakhir'),

});

const GeneralInformationForm = ({ onBack, onSubmit, draftData, onDraft }) => {

  const location = useLocation()

  const options = useMemo(
    () => [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
    ],
    []
  );

  const countryOptions = useMemo(
    () => [{ value: 'Indonesia', label: 'Indonesia' }],
    []
  );

  // const [typeLembaga, setTypeLembaga] = useState()
  const typeLembaga = location.pathname.split('/')[2]


  const methods = useForm({
    resolver: yupResolver(validationSchema(typeLembaga)),
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    methods.reset({ ...draftData });
    return;
  }, [draftData, methods]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // useEffect(() => {
  //   const type = location.pathname.split('/')[2]

  //   setTypeLembaga(type)
  // }, [location])

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  const onFormDraft = () => {
    return () => onDraft(methods.getValues());
  };

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const provinceId = useWatch({
    control,
    name: 'province',
  })?.value;
  const cityId = useWatch({
    control,
    name: 'city',
  })?.value;
  const districtId = useWatch({
    control,
    name: 'kecamatan',
  })?.value;

  const orgStructureLabel = (type) => {
    let result;

    switch (type) {
      case 'label':
        if (typeLembaga === 'bank-ventura') result = 'Struktur Organisasi Beserta Daftar Direksi (Direksi/Komisaris/Pemegang Saham)'
        if (typeLembaga === 'koperasi') result = 'Struktur Organisasi Beserta Info Kepengurusan'
        if (typeLembaga === 'non-koperasi') result = 'Struktur Organisasi Beserta Info Kepengurusan'
        if (typeLembaga === 'badan-hukum') result = 'Struktur Organisasi, Daftar Direksi (Direksi/Komisaris/Pemegang Saham) & Info Kepengurusan '
        break;

      case 'description':
        if (typeLembaga === 'bank-ventura') result = 'Upload Struktur Organisasi Beserta Daftar Direksi (Direksi/Komisaris/Pemegang Saham)'
        if (typeLembaga === 'koperasi') result = 'Upload Struktur Organisasi Serta Info Kepengurusan Lembaga Dengan Isi Curicullum vitae pengurus Dan kartu tanda penduduk/surat izin mengemudi/paspor pengurus Disatukan'
        if (typeLembaga === 'non-koperasi') result = 'Upload Struktur Organisasi Serta Info Kepengurusan Lembaga Dengan Isi Curicullum vitae pengurus Dan kartu tanda penduduk/surat izin mengemudi/paspor pengurus Disatukan'
        if (typeLembaga === 'badan-hukum') result = 'Upload Struktur Organisasi, Daftar Direksi & Info Kepengurusan Lembaga (Curicullum vitae pengurus Dan kartu tanda penduduk/surat izin mengemudi/paspor pengurus) Disatukan'
        break;
      default:
        result = 'Struktur Organisasi Beserta Daftar Direksi (Direksi/Komisaris/Pemegang Saham)'
        break;
    }

    return result
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit()} className="space-y-8">
        <CardForm label="General Informasi">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputForm
              controllerName={'institution_name'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'Nama Lembaga'}
              placeholder={'Nama Lembaga'}
              required
            />
            <AtomSelect
              controllerName={'institution_type'}
              options={options}
              className="basic-single mt-2 flex-1"
              label="Jenis Lembaga"
              required={true}
              wrapperClass={"col-span-1"}
              isError={true}
            />
            <InputForm
              controllerName={'business_type'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'Jenis Usaha dan/atau Jenis Layanan'}
              placeholder={'Jenis usaha dan/atau jenis layanan'}
              required
            />
            <div>&nbsp;</div>
            <InputForm
              controllerName={'email'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'Email'}
              placeholder={'Email'}
              required
            />
            <InputForm
              controllerName={'phone_number'}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={'No Telepon'}
              placeholder={'No Telepon'}
              required
            />
            <SelectCountry
              controllerName={'country'}
              options={countryOptions}
              className="basic-single mt-2 flex-1"
              label="Negara"
              required={true}
            />
            <SelectProvince
              controllerName={'province'}
              className="basic-single mt-2 flex-1"
              label="Provinsi"
              required={true}
              selected={draftData?.province}
            />
            <SelectCity
              controllerName={'city'}
              className="basic-single mt-2 flex-1"
              label="Kota/Kabupaten"
              required={true}
              provinceId={provinceId}
            />
            <SelectDistrict
              controllerName={'district'}
              className="basic-single mt-2 flex-1"
              label="Kecamatan"
              required={true}
              cityId={cityId}
            />
            <SelectSubdistrict
              controllerName={'village'}
              className="basic-single mt-2 flex-1"
              label="Kelurahan/Desa"
              required={true}
              districtId={districtId}
            />
            <AtomSelect
              controllerName={'postal_code'}
              options={options}
              className="basic-single mt-2 flex-1"
              label="Kode Pos"
              required={true}
            />
            <InputForm
              controllerName={'address'}
              className={`py-2 px-4 mt-2 border w-full rounded-md`}
              label={'Alamat'}
              placeholder={'Alamat'}
              textArea
              required
            />
          </div>
          <div className="border-b" />
          <div className="space-y-6">
            <p className="text-lg text-primary-700 font-semibold">
              Informasi Penanggung Jawab {'(Narahubung)'}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputForm
                controllerName={'contact_person.name'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Nama Narahubung'}
                placeholder={'Nama Narahubung'}
                required
              />
              <InputForm
                controllerName={'contact_person.position'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Jabatan'}
                placeholder={'Jabatan'}
                required
              />
              <InputForm
                controllerName={'contact_person.email'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Email'}
                placeholder={'Email'}
                required
              />
              <InputForm
                controllerName={'contact_person.phone_number'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Telepon'}
                placeholder={'Telepon'}
                required
              />
              <InputForm
                controllerName={'contact_person.ktp_number'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'No KTP'}
                placeholder={'No KTP'}
                required
              />
              <InputFormUpload
                controllerName={`contact_person.ktp`}
                label={'KTP'}
                required
              />
              <InputForm
                controllerName={'contact_person.npwp_number'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'No NPWP'}
                placeholder={'No NPWP'}
              />
              <InputFormUpload
                controllerName={`contact_person.npwp`}
                label={'NPWP'}
              />
            </div>
          </div>
        </CardForm>
        <CardForm label={"Latar Belakang Menjadi Penyalur"}>
          <div className="gap-4 py-6">
            <div className="space-y-1 mb-5">
              <label htmlFor="" className="font-semibold">Format Surat Menjadi Lembaga Penyalur</label>
              <FileInfoField
                title={'Format Surat Menjadi Lembaga Penyalur'}
                desc={'2 MB'}
                showDownload
                showIcon
              // onDownloadClick={() => }
              />
            </div>

            <div className="space-y-1">
              <InputFormUpload
                required
                controllerName={`upload_letter_distribution_intitution`}
                label={'Upload Surat Menjadi Lembaga Penyalur'}
              />
            </div>
          </div>
        </CardForm>

        <CardForm label={orgStructureLabel('label')}>
          <div className="gap-4 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
              <div className="space-y-1">
                <InputForm
                  controllerName={'total_members'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'Jumlah Anggota'}
                  placeholder={'Jumlah Anggota'}
                  required
                />
                <p className='text-xs text-gray-400'>Jika Tidak Memiliki Anggota, Maka Diisi Angka 0</p>
              </div>
              <div className="space-y-1">
                <InputForm
                  controllerName={'total_branches'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'Jumlah Cabang'}
                  placeholder={'Jumlah Cabang'}
                  required
                />
                <p className='text-xs text-gray-400'>Jika Tidak Memiliki Cabang, Maka Diisi Angka 0</p>
              </div>
              <div className="space-y-1">
                <InputForm
                  controllerName={'total_employees'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'Jumlah Pegawai'}
                  placeholder={'Jumlah Pegawai'}
                  required
                />
                <p className='text-xs text-gray-400'>Jika Tidak Memiliki Pegawai, Maka Diisi Angka 0</p>
              </div>
            </div>

            <div className="space-y-1">
              <InputFormUpload
                required
                controllerName={`upload_structure_org`}
                label={orgStructureLabel('description')}
              />
            </div>
          </div>
        </CardForm>

        <CardForm label="Legalitas Lembaga">
          <div className="gap-4 py-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
              <div className="space-y-1">
                <InputForm
                  controllerName={'institution_npwp_number'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'No NPWP'}
                  placeholder={'No NPWP'}
                />
              </div>
              <div className="space-y-1">
                <InputFormUpload
                  controllerName={`  intuition_npwp_doc: Yup.string().notRequired().label('Upload Surat Menjadi Lembaga Penyalur'),
                  `}
                  label={'Dokumen NPWP'}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
              <div className="space-y-1">
                <InputForm
                  controllerName={'deed_number'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'Nomor Akta Pendirian Dan Pengesahannya'}
                  // placeholder={'No NPWP'}
                  required
                />
              </div>
              <div className="space-y-1">
                <AtomDatePicker
                  label='Tanggal Terbit Akta Pendirian Dan Pengesahannya'
                  // newDate={true}
                  required={true}
                  // customPicker={true}
                  controllerName={`deed_date`}
                  placeholder={'dd/mm/yyyy'}
                  customInputClassname={`py-2 px-4 border w-full rounded-md flex-1`}
                />
              </div>
            </div>

            <div className="space-y-1 mb-5">
              <InputFormUpload
                required
                controllerName={`deed_doc`}
                label={'Dokumen Akta Pendirian Dan pengesahannya Beserta Akta Perubahan'}
              />
            </div>

            {
              typeLembaga === 'koperasi' && (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
                    <div className="space-y-1">
                      <InputForm
                        controllerName={'nik_number'}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                        label={'Nomor NIK (Nomor Induk Koperasi)'}
                        // placeholder={'No NPWP'}
                        required
                      />
                    </div>
                    <div className="space-y-1">
                      <AtomDatePicker
                        label='Tanggal Terbit NIK'
                        // newDate={true}
                        required={true}
                        // customPicker={true}
                        controllerName={`nik_date`}
                        placeholder={'dd/mm/yyyy'}
                        customInputClassname={`py-2 px-4 border w-full rounded-md flex-1`}
                      />
                    </div>
                  </div>

                  <div className="space-y-1 mb-5">
                    <InputFormUpload
                      required
                      controllerName={`nik_docs`}
                      label={'Dokumen NIK'}
                    />
                  </div>
                </>
              )
            }

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
              <div className="space-y-1">
                <InputForm
                  controllerName={'nib_number'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={'Nomor NIB/TDP/SKDP/Izin usaha'}
                  required
                />
              </div>
              <div className="space-y-1">
                <AtomDatePicker
                  label='Tanggal Terbit NIB/TDP/SKDP/Izin usaha'
                  // newDate={true}
                  required={true}
                  // customPicker={true}
                  controllerName={`nib_date`}
                  placeholder={'dd/mm/yyyy'}
                  customInputClassname={`py-2 px-4 border w-full rounded-md flex-1`}
                />
              </div>
            </div>

            <div className="space-y-1 mb-5">
              <InputFormUpload
                required
                controllerName={`nib_docs`}
                label={'Dokumen NIB/TDP'}
              />
            </div>

            <div className="space-y-1 mb-5">
              <InputFormUpload
                controllerName={`supporting_docs`}
                label={'Upload Dokumen pendukung lainnya Disatukan (SOP/ Ketetapan Kemenkumham dan dokumen lainnya)'}
              />
            </div>
          </div>
        </CardForm>

        <CardForm label="Informasi Keuangan 3 Tahun Terakhir">
          <div className="gap-4 py-6">
            <div className="space-y-1 mb-5">
              <InputFormUpload
                required
                controllerName={`finnancial_information`}
                label={'Informasi Keuangan 3 Tahun Terakhir'}
              />
            </div>
          </div>
        </CardForm>

        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={onFormSubmit()}
          disableDrafButton={false}
          drafButtonAction={onFormDraft()}
          disableButtonSubmit={false}
          lastStep={true}
        />
      </form>
    </FormProvider>
  );
};

export default GeneralInformationForm;
