import { ConfirmationModal, HeaderLabel, StepProgress } from 'components';
import AlertBlankData from 'components/molecules/Alert';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, { useCallback, useMemo, useState } from 'react';
import { RiAlertLine } from 'react-icons/ri';
import ApplicationValue from './applicationValue';
import UsagePlan from './usagePlan';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';
import { useMutation } from '@tanstack/react-query';
import {
  updateChannelingDirectApplicationGuarantee,
  updateDraftChannelingDirectApplicationGuarantee,
} from 'services';
import dayjs from 'dayjs';
import { useAuth } from 'hooks/auth';

const ApplicationForm = () => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(1);
  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);
  const { id } = useAuth();

  const breadCrumbs = [
    {
      label: 'Daftar Permohonan',
      path: '/cv',
    },
    {
      label: 'Daftar Permohonan Baru',
      path: '/cv/supplier-application',
    },
    {
      label: 'Formulir Permohonan',
    },
  ];

  const stepProgress = useMemo(
    () => [
      {
        label: 'Nilai Permohonan',
        step: 1,
      },
      {
        label: 'Rencana Permohonan',
        step: 2,
      },
    ],
    []
  );

  const onBack = useCallback(() => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, navigate]);

  const onDraft = useCallback((payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));
    setDraftModal(true);
  }, []);

  const onSubmit = useCallback(
    (payload) => {
      setBodyToSend((state) => ({ ...state, ...payload }));
      if (activeStep < stepProgress.length) {
        setActiveStep(activeStep + 1);
      } else {
        setConfirmModal(true);
      }
    },
    [activeStep, stepProgress]
  );

  const params = useMemo(() => {
    if (!bodyToSend) return {};

    const startYear = dayjs(bodyToSend.first_year).format('YYYY');

    return {
      value: bodyToSend.app_value,
      cooperation_pattern: bodyToSend.cooperation_pattern,
      guarantee_type: bodyToSend.guarantee_type,
      guarantee_form: bodyToSend.guarantee_form,
      guarantee_value: bodyToSend.guarantee_value,
      forestry_business_type: bodyToSend.forestry_business_type,
      projected_distribution_benefit: bodyToSend.projected_distribution_benefit,
      financing_period_plan: bodyToSend.financing_period_plan,
      financing_period_plan_unit: bodyToSend.financing_period_plan_unit,
      distribution_plan: bodyToSend.distribution_plan,
      recipient_location: bodyToSend.recipient_location,
    };
  }, [bodyToSend]);

  const onError = useCallback((error) => {
    enqueueSnackbar({
      message: getErrorMessage(error),
      variant: 'error',
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar({
      message: 'Data berhasil disimpan',
      variant: 'error',
    });
    navigate('/cv/supplier-application');
  }, [navigate]);

  const { mutate: updateDebtor } = useMutation({
    mutationFn: () => {
      return updateChannelingDirectApplicationGuarantee(id, params);
    },
    onError,
    onSuccess,
  });

  const { mutate: updateDraftDebtor } = useMutation({
    mutationFn: () => {
      return updateDraftChannelingDirectApplicationGuarantee(id, params);
    },
    onError,
    onSuccess,
  });

  const onCreate = () => {
    updateDebtor();
  };

  const onCreateDraft = () => {
    updateDraftDebtor();
  };

  return (
    <div>
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onCreate}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        open={draftModal}
        onClose={setDraftModal}
        onSubmit={onCreateDraft}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan menyimpan data ini sebagai draft?
        </div>
        <div className="text-sm font-[400]">
          Setelah Anda menyimpan data sebagai draft anda masih bisa
          melanjutkannya.
        </div>
      </ConfirmationModal>

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-36 z-[99]">
        <BreadCrumbs routes={breadCrumbs} />

        {/* <div className="flex">
          <HeaderLabel
            text="Formulir Permohonan"
            description="Buat permohonan baru."
          />
        </div> */}

        <div className="border-b" />

        <div className="w-full flex justify-center">
          <div className="w-[800px] mb-10">
            <StepProgress
              stepProgressData={stepProgress}
              activeStep={activeStep}
            />
          </div>
        </div>

        <div className="w-full pt-4">
          <div className="bg-[#FFFCF5] border border-[#F5AE70] rounded-xl p-4">
            <div className="flex flex-row gap-2">
              <div className="w-[17px] h-[15px] mt-1">
                <RiAlertLine className="text-[#F5AE70]" />
              </div>
              <div className="flex-1">
                <span className="font-semibold text-[14px] text-[#8F480A]">
                  Silahkan isi semua data di bawah ini untuk bisa melanjutkan
                  pengisian ke tahap selanjutnya
                </span>
              </div>
            </div>
          </div>
        </div>

        {activeStep === 1 && (
          <ApplicationValue
            prefix={'app_value'}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        )}
        {activeStep === 2 && (
          <UsagePlan
            prefix={'usage_plan'}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
      </div>
    </div>
  );
};

export default ApplicationForm;
