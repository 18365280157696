import { useMutation, useQuery } from "@tanstack/react-query";
import Alert from "components/atoms/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { RiEyeLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import {
  getPengajuanAnggotaKelompok,
  updateServiceType,
} from "services/fdb/akunKelompok";
import { Button, ConfirmationModal, Table } from "../../../../../components";
import FormattedDate from "../../../../../components/atoms/FormattedDate";
import Tag from "../../../../../components/atoms/Tag";
import ProgressModal from "../../../../../components/molecules/Modal/ProgressModal";
import { formatCurrency, getErrorMessage } from "../../../../../helpers";
import ServiceTypeModal from "../../../components/NonPerhutananSosisal/ServiceTypeModal";

const Status = {
  DRAFT: "draft",
  WAIT_VERIF: "waiting_for_review",
  VERIFIED: "approved",
  REJECTED: "rejected",
};
const ServiceType = {
  TUNDA_TEBANG: "Tunda Tebang",
  HBBK: "Hasil Hutan Bukan Kayu",
  REFINANCING_KEHUTANAN: "Komoditas Non Kehutanan",
};

const AkunPeroranganNPSPembiayaanList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // const page = useWatch({ control: methods.control, name: "page" });
  // const pageSize = useWatch({ control: methods.control, name: "pageSize" });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: listData, isFetching: listLoading } = useQuery({
    queryKey: [
      "get-member-list",
      {
        params: {
          paginate: true,
          page: page,
          limit: limit,
        },
      },
    ],
    queryFn: async ({ queryKey }) => {
      const res = await getPengajuanAnggotaKelompok(queryKey[1]);

      return res.data.data;
    },
    retry: 1,
  });

  const updateMutation = useMutation({
    mutationFn: (params) => {
      updateServiceType(selectedRow?.id, {
        service_type: params?.service,
      });
    },
    onSuccess: (data, variables, context) => {
      const { service } = variables || {};

      enqueueSnackbar({
        message: "Perubahan layanan berhasil",
        variant: "success",
      });

      navigate(`${location.pathname}/edit/${selectedRow?.id}?type=${service}`, {
        state: {
          ...selectedRow,
        },
      });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  const emptyIcon = useCallback(() => undefined, []);

  const [confirmModal, setConfirmModal] = useState(false);
  const [serviceTypeModal, setServiceTypeModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleClickConfirmationModal = useCallback(
    (data) => {
      setConfirmModal((prev) => !prev);
      setSelectedRow(data);
    },
    [setConfirmModal]
  );

  const handleSubmitConfirmationModal = () => {
    if (selectedRow.service_type === "Hasil Hutan Bukan Kayu") {
      navigate(
        `${location.pathname}/edit/${
          selectedRow?.non_timber_forest_id ?? selectedRow?.id
        }?type=${selectedRow?.service_type}`,
        {
          state: selectedRow,
        }
      );
    }

    if (selectedRow.service_type === "Tunda Tebang") {
      navigate(
        `${location.pathname}/edit/${
          selectedRow?.deferred_logging_application_id ?? selectedRow?.id
        }?type=${selectedRow?.service_type}`,
        {
          state: selectedRow,
        }
      );
    }

    if (selectedRow.service_type === "Komoditas Non Kehutanan") {
      navigate(
        `${location.pathname}/edit/${
          selectedRow?.non_forest_commodity_id ?? selectedRow?.id
        }?type=${selectedRow?.service_type}`,
        {
          state: selectedRow,
        }
      );
    }
  };

  const handleViewDetail = (data) => {
    if (data.service_type === "Hasil Hutan Bukan Kayu") {
      navigate(
        `${location.pathname}/view/${
          data?.non_timber_forest_id ?? data?.id
        }?type=${data?.service_type}`,
        {
          state: data,
        }
      );
    }

    if (data.service_type === "Tunda Tebang") {
      navigate(
        `${location.pathname}/view/${
          data?.deferred_logging_application_id ?? data?.id
        }?type=${data?.service_type}`,
        {
          state: data,
        }
      );
    }

    if (data.service_type === "Komoditas Non Kehutanan") {
      navigate(
        `${location.pathname}/view/${
          data?.non_forest_commodity_id ?? data?.id
        }?type=${data?.service_type}`,
        {
          state: data,
        }
      );
    }
  };

  const handleSubmitServiceTypeModal = ({ value }) => {
    updateMutation.mutate({ service: value });
  };

  const paginator = useMemo(() => {
    return {
      page: page,
      limit: limit,
      total: listData?.meta?.total,
      previous_pages: listData?.meta?.previous_page_url,
      next_pages: listData?.meta?.next_page_url,
    };
  }, [listData, page, limit]);

  const tableColumns = useMemo(
    () => [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px] overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "registration_date",
        title: "Tanggal Didaftarkan Permohonan",
        dataIndex: "registration_date",
        columnClassName: "w-[274px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full ">
              <FormattedDate date={value} />
            </div>
          );
        },
      },
      {
        id: "request_date",
        title: "Tanggal Permohonan",
        dataIndex: "request_date",
        columnClassName: "w-[186px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full">
              <FormattedDate date={value} />
            </div>
          );
        },
      },
      {
        id: "service_type",
        title: "Jenis Layanan",
        dataIndex: "service_type",
        columnClassName: "w-[171px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full">
              {value === ServiceType.TUNDA_TEBANG && (
                <Tag icon={emptyIcon} theme={"info"} label={"Tunda Tebang"} />
              )}
              {value === ServiceType.HBBK && (
                <Tag icon={emptyIcon} theme={"accent"} label={"HHBK"} />
              )}
              {value === ServiceType.REFINANCING_KEHUTANAN && (
                <Tag
                  icon={emptyIcon}
                  theme={"error"}
                  label={"Komoditas Non Kehutanan"}
                />
              )}
            </div>
          );
        },
      },
      {
        id: "value",
        title: "Nilai Permohonan",
        dataIndex: "value",
        columnClassName: "w-[196px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full">
              {formatCurrency(value)}
            </div>
          );
        },
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "w-[170px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full ">
              {value === Status.DRAFT ? (
                <Tag
                  wrapperClass={
                    "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                  }
                  theme={"neutral"}
                >
                  Draft
                </Tag>
              ) : undefined}
              {value === Status.WAIT_VERIF ? (
                <Tag
                  wrapperClass={
                    "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                  }
                  theme={"warning"}
                >
                  Menunggu Verifikasi
                </Tag>
              ) : undefined}
              {value === Status.REJECTED ? (
                <Tag
                  wrapperClass={
                    "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                  }
                  theme={"error"}
                >
                  Ditolak
                </Tag>
              ) : undefined}
              {value === Status.VERIFIED ? (
                <Tag
                  wrapperClass={
                    "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                  }
                  theme={"success"}
                >
                  Disetujui
                </Tag>
              ) : undefined}
            </div>
          );
        },
      },
      {
        id: "no",
        title: "Aksi",
        dataIndex: "no",
        fixed: "right",
        columnClassName: "w-[160px] overflow-hidden text-center",
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full ">
              {data.status === Status.DRAFT ? (
                <Button
                  type={"button"}
                  className="bg-[#00BD52] hover:bg-primary-700 text-white py-2 px-3 rounded-lg font-semibold text-[12px]"
                  label={
                    <div className="flex items-center gap-2 font-[14px]">
                      Lanjutkan Permohonan
                    </div>
                  }
                  onClick={() => {
                    handleClickConfirmationModal(data);
                  }}
                />
              ) : (
                data.status === Status.WAIT_VERIF && (
                  <Button
                    type={"button"}
                    className="p-4 bg-[transparent] rounded-full flex justify-center"
                    label={<RiEyeLine />}
                    onClick={() => handleViewDetail(data)}
                  />
                )
              )}

              {data.status === Status.VERIFIED && (
                <Button
                  type={"button"}
                  className="p-4 bg-[transparent] rounded-full flex justify-center"
                  label={<RiEyeLine />}
                  onClick={() => handleViewDetail(data)}
                />
              )}
            </div>
          );
        },
      },
    ],
    [
      emptyIcon,
      paginator.limit,
      paginator.page,
      handleClickConfirmationModal,
      handleViewDetail,
    ]
  );

  const handleChangeLimit = (e) => {
    setLimit(e);
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-auto");

    return () => {
      layouContentEl?.classList?.remove("overflow-auto");
    };
  }, []);

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={() => {
            setConfirmModal((prev) => !prev);
          }}
          onCancel={() => {
            setConfirmModal((prev) => !prev);
            setServiceTypeModal((prev) => !prev);
          }}
          onSubmit={handleSubmitConfirmationModal}
          className="space-y-2 mb-4"
          cancelLabel={"Tidak, Ubah"}
          confirmLabel={"Iya, Lanjutkan"}
          onCloseOverlay
        >
          <div className="text-lg font-[600]">
            Apakah Anda yakin ingin melanjutkan layanan ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda menyimpan sebagai draft anda masih bisa melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ServiceTypeModal
          open={serviceTypeModal}
          onClose={() => setServiceTypeModal((prev) => !prev)}
          onSubmit={handleSubmitServiceTypeModal}
          className="space-y-2 mb-4"
        ></ServiceTypeModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={confirmModal && updateMutation.isLoading}
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-4 overflow-hidden">
        <div className="flex flex-col gap-y-6">
          {/* Header Section */}
          <div className="bg-[#FFFFFF] rounded-xl p-10">
            <div className="flex flex-col w-full">
              <BreadCrumbs
                routes={[
                  {
                    label: "Permohonan",
                    path: `/perorangan/non-perhutanan-sosial/permohonan-pembiayaan`,
                  },
                  {
                    label: "Daftar Permohonan",
                    path: location.pathname,
                  },
                ]}
              />
              <div className="mt-6 flex flex-row">
                <div className="flex-1">
                  <div className="flex flex-col">
                    <span className="font-semibold text-[24px] text-[#101828]">
                      Daftar Permohonan
                    </span>
                    <span className="font-normal text-[14px] text-[#667085]">
                      Daftar Permohonan - Non Perhutanan Sosial
                    </span>
                  </div>
                </div>
                <div className="flex-1 text-right">
                  {/* <Button
                    type="primary"
                    className="bg-[#E34F13] text-white py-3 px-4 rounded-lg"
                    label="Buat Permohonan Baru"
                  /> */}
                </div>
              </div>
            </div>
          </div>

          {listData?.results?.length ? (
            <Alert
              theme={"warning"}
              title={"Proses Permohonan Sedang Berjalan"}
              description={
                "Anda tidak bisa menambakan permohonan baru karena masih ada proses permohonan yang berjalan."
              }
            />
          ) : undefined}

          <Table
            columns={tableColumns}
            dataSource={listData?.data}
            layout="fixed"
            isLoading={listLoading}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeLimit}
            pagination={paginator}
          />

          {/* <div
            className={
              "rounded-lg border border-gray-200 bg-white p-3 space-y-4"
            }
          >
            <div className={"w-full overflow-y-hidden overflow-x-auto"}>
              <table className={"table-fixed w-full relative"}>
                <thead className="bg-primary-800 p-3 text-white text-sm">
                  <tr>
                    <th
                      className={
                        "w-[6rem] rounded-lg rounded-tr-none rounded-b-none py-2"
                      }
                    >
                      No
                    </th>
                    <th className={"w-[18rem] "}>Tgl Didaftarkan Permohonan</th>
                    <th className={"w-[18rem]"}>Tgl Permohonan</th>
                    <th className={"w-[12rem]"}>Jenis Layanan</th>
                    <th className={"w-[12rem]"}>Nilai Permohonan</th>
                    <th className={"w-[10rem]"}>Status</th>
                    <th
                      className={
                        "w-[12rem] sticky right-0 bg-inherit rounded-lg rounded-tl-none rounded-b-none"
                      }
                    >
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listLoading && (
                    <tr>
                      <td colSpan={6} className={"text-center"}>
                        <div className={"inline-block mx-auto p-4"}>
                          <Spinner theme={"primary"} className={"mx-auto"} />
                        </div>
                      </td>
                    </tr>
                  )}
                  {listData?.results?.map((entry, index) => (
                    <tr key={index}>
                      <td>{(page - 1) * pageSize + 1 + index}</td>
                      <td>
                        <FormattedDate date={entry?.created_at} />
                      </td>
                      <td>
                        <FormattedDate date={entry?.request_date} />
                      </td>
                      <td>
                        {entry?.service_type === ServiceType.TUNDA_TEBANG && (
                          <Tag
                            icon={emptyIcon}
                            theme={"info"}
                            label={"Tunda Tebang"}
                          />
                        )}
                        {entry?.service_type === ServiceType.HBBK && (
                          <Tag
                            icon={emptyIcon}
                            theme={"accent"}
                            label={"HHBK"}
                          />
                        )}
                        {entry?.service_type ===
                          ServiceType.REFINANCING_KEHUTANAN && (
                          <Tag
                            icon={emptyIcon}
                            theme={"error"}
                            label={"Komoditas Non Kehutanan"}
                          />
                        )}
                      </td>
                      <td>{entry?.value && formatCurrency(entry?.value)}</td>
                      <td className={"space-x-1 space-y-1"}>
                        {entry?.status === Status.DRAFT ? (
                          <Tag
                            wrapperClass={
                              "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                            }
                            theme={"neutral"}
                          >
                            Draft
                          </Tag>
                        ) : undefined}
                        {entry?.status === Status.WAIT_VERIF ? (
                          <Tag
                            wrapperClass={
                              "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                            }
                            theme={"warning"}
                          >
                            Menunggu Verifikasi
                          </Tag>
                        ) : undefined}
                        {entry?.status === Status.REJECTED ? (
                          <Tag
                            wrapperClass={
                              "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                            }
                            theme={"error"}
                          >
                            Ditolak
                          </Tag>
                        ) : undefined}
                        {entry?.status === Status.VERIFIED ? (
                          <Tag
                            wrapperClass={
                              "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                            }
                            theme={"success"}
                          >
                            Disetujui
                          </Tag>
                        ) : undefined}
                      </td>
                      <td className={`sticky right-0 bg-white`}>
                        {entry?.status === Status.DRAFT ? (
                          <Button
                            type={"button"}
                            className="bg-[#00BD52] text-white py-2 px-3 rounded-lg font-semibold text-[12px]"
                            label={
                              <div className="flex items-center gap-2 font-[14px]">
                                Lanjutkan Permohonan
                              </div>
                            }
                            onClick={onContinueClick(entry)}
                          />
                        ) : (
                          entry?.status === Status.WAIT_VERIF && (
                            <Button
                              type={"button"}
                              className="p-4 bg-[transparent] rounded-full flex justify-center"
                              label={<RiEyeLine />}
                              onClick={onContinueClick(entry)}
                            />
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <FormProvider {...methods}>
              <div
                className={"w-full flex flex-wrap items-center gap-4 text-sm"}
              >
                <TablePageSizeField />

                <div>
                  {(page - 1) * pageSize + 1}-{page * pageSize} data dari{" "}
                  {listData?.meta?.total} data.
                </div>

                <div className={"grow"} />

                <div>
                  <TablePagination
                    page={page}
                    totalPage={listData?.meta?.totalPage || 1}
                    onChange={(page) => methods.setValue("page", page)}
                  />
                </div>
              </div>
            </FormProvider>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AkunPeroranganNPSPembiayaanList;
