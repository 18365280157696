import React from "react";
import { removeToken } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import BackgroundHeader from "assets/backgrounds/Background.png";
import Logo from "assets/Logo.png";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(removeToken());
  };
  const location = useLocation();
  const navigate = useNavigate();
  const handleNavigate = () => {
    location.pathname === "/" ? navigate("/login") : navigate("/register");
  };

  return (
    <div className="w-full absolute">
      <img
        className={`w-full ${
          location.pathname === "/register" ? "h-[228px]" : "h-[376px] top-0"
        } overflow-x-hidden`}
        src={BackgroundHeader}
        alt=""
      />
      <img
        className="absolute top-6 tab:left-4 left-[112px] h-10"
        src={Logo}
        alt=""
      />
      <button
        onClick={handleNavigate}
        className="absolute  z-50 tab:right-4 top-6 right-[130px] bg-orange-600 py-[10px] px-[18px] text-white font-semibold rounded-lg"
      >
        {location.pathname === "/login" ? "Daftar" : "Login Akun"}
      </button>
    </div>
  );
};

export default Header;
