import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BottomFormAction, ConfirmationModal, HeaderLabel } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { AUTOSAVE_DURATION } from "../../../../../app/config";
import ProgressModal from "../../../../../components/molecules/Modal/ProgressModal";
import { getErrorMessage } from "../../../../../helpers";
import useInterval from "../../../../../helpers/useInterval";
import {
  AgreementSection,
  validationSchema as AgreeValSchema,
} from "../../../components/agreementSection";
import AddressSection, {
  validationSchema as AddressValSchema,
} from "../../../components/PerhutananSosial/FormTundaTebang/addressSection";
import DebiturSection, {
  validationSchema as DebiturValSchema,
} from "../../../components/PerhutananSosial/FormTundaTebang/debiturSection";
import FinanceSection, {
  validationSchema as FinanceValSchema,
} from "../../../components/PerhutananSosial/FormTundaTebang/financeSection";
import FormulirSection, {
  validationSchema as FormValSchema,
} from "../../../components/PerhutananSosial/FormTundaTebang/formulirSection";
import LoanSection, {
  validationSchema as LoanValSchema,
} from "../../../components/PerhutananSosial/FormTundaTebang/loanSection";
import PartnerSection, {
  validationSchema as PartnerValSchema,
} from "../../../components/PerhutananSosial/FormTundaTebang/partnerSection";
import ProjectSection, {
  validationSchema as ProjectValSchema,
} from "../../../components/PerhutananSosial/FormTundaTebang/projectSection";

const validationSchema = Yup.object().shape({
  form: FormValSchema,
  debitur: DebiturValSchema,
  partner: PartnerValSchema,
  address: AddressValSchema,
  finance_info: FinanceValSchema,
  project: ProjectValSchema,
  loan: LoanValSchema,
  agree: AgreeValSchema,
});

const AkunPeroranganPSPembiayaanForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { memberId: id } = params || {};

  // get mode from path
  const splitPath = location?.pathname?.split("/").filter((e) => !!e);
  const mode = id ? splitPath.at(-2) : splitPath?.pop();
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  const onBack = useCallback(() => {
    const target = location?.pathname
      ?.split("/")
      ?.filter((e) => !!e)
      ?.slice(0, -3)
      .join("/");
    navigate(`/${target}`);
  }, [location?.pathname]);

  useEffect(() => {
    const layoutContentEl = document.getElementById("_layout-content");

    layoutContentEl?.classList?.add("overflow-hidden");

    return () => {
      layoutContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  const routes = useMemo(() => [
    {
      label: "Formulir Pendaftaran",
      path: "/perorangan/perhutanan-sosial/pengajuan-daftar-permohonan",
    },
    {
      label: "Pendaftaran Perorangan",
      path: "/perorangan/perhutanan-sosial/pengajuan-daftar-permohonan",
    },
    {
      label: "Formulir Permohonan Layanan Tunda Tebang",
      path: location.pathname,
    },
  ]);

  // setup form
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  // api call
  const { data } = useQuery({
    queryKey: ["pengajuan-permohonan-non-perhutanan-sosial--detail", id],
    queryFn: async () => {
      // TODO: Integrate API here
      return new Array(3).fill({}).map((e, idx) => ({
        id: idx,
        name: "User" + idx,
        nik: "9843987498570394",
        birthday: new Date().toISOString(),
        email: "a@test.com",
        phone: "894379",
      }));
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!id,
  });

  const draftMutation = useMutation({
    mutationFn: (params) => {
      // TODO: integrate submit api
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // resolve(true)
          reject(true);
        }, 3000);
      });
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      onBack();
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setDraftModal(false);
    },
  });

  const submitMutation = useMutation({
    mutationFn: (params) => {
      // TODO: integrate submit api
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // resolve(true)
          reject(true);
        }, 3000);
      });
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      onBack();
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setConfirmModal(false);
    },
  });

  // methods
  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      setConfirmModal(data);
    });
  };

  const onSaveDraftClick = (e) => {
    e?.preventDefault();

    const formData = methods.getValues();

    setConfirmModal(false);
    setDraftModal(formData);
  };

  const doSaveDraft = () => {
    draftMutation.mutate(draftModal);
  };
  const doSubmit = () => {
    submitMutation.mutate(confirmModal);
  };

  useInterval(() => {
    // TODO: Gather all form data
    const values = methods.getValues();

    doSaveDraft();
  }, AUTOSAVE_DURATION);

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onClose={setDraftModal}
          onSubmit={doSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={doSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={
            (draftModal && draftMutation.isLoading) ||
            (confirmModal && submitMutation.isLoading)
          }
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text="Formulir Permohonan Perhutanan Sosial"
          description="Buat permohonan baru."
        />

        <div className="border-b" />

        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit()} className="space-y-8">
            <FormulirSection formPrefix={"form"} mode={mode} />

            <DebiturSection formPrefix={"debitur"} mode={mode} />

            <PartnerSection formPrefix={"partner"} mode={mode} />

            <AddressSection formPrefix={"address"} mode={mode} />

            <FinanceSection formPrefix={"finance_info"} mode={mode} />

            <ProjectSection formPrefix={"project"} mode={mode} />

            <LoanSection formPrefix={"loan"} mode={mode} />

            <AgreementSection formPrefix={"agree"} mode={mode} />

            <BottomFormAction
              lastStep={true}
              backButtonProps={{
                type: "button",
              }}
              draftButtonProps={{
                type: "button",
              }}
              submitButtonProps={{
                type: "submit",
              }}
              hideDraft={isView}
              hideSubmit={isView}
              backButtonAction={onBack}
              disableDrafButton={false}
              drafButtonAction={onSaveDraftClick}
              disableButtonSubmit={submitMutation.isLoading}
            />
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AkunPeroranganPSPembiayaanForm;
