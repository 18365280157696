import React from "react";
import { InputForm } from "../../../index";

const InputNumeric = React.forwardRef((props, ref) => {
  const { className, allowFraction, ...restProps } = props;
  
  return (
    <InputForm
      className={[`py-2 px-4 mt-2 border w-full rounded-md flex-1`, className].join(" ")}
      mask={allowFraction ? "0.0" : "0"}
      data-maska-tokens="0:\d:multiple"
      {...restProps}
    />
  );
});

InputNumeric.propTypes = {};

export default InputNumeric;