import AlertBlankData from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import _ from "lodash";
import React, { useState } from "react";
import { FiUpload } from "react-icons/fi";
import { RiComputerLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import Alert from "components/atoms/Alert";
import Button from "components/atoms/Button/Button";

import ProfileUmum from "../../../../assets/illustrations/Profil Umum.png";

const NonPeroranganDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [moduleItems] = useState([
    {
      icon: <RiComputerLine className="text-white" />,
      label: "Profil Umum",
      path: `${location.pathname}/profile-umum`,
      background: "bg-orangedark-600",
      backgroundOuter: "bg-orangedark-100",
      status: null,
    },
    // {
    //   icon: <RiBankLine className="text-white" />,
    //   label: 'Kelembagaan',
    //   path: `${location.pathname}/aplication-form`,
    //   background: 'bg-blue-800',
    //   backgroundOuter: 'bg-blue-100',
    //   status: null,
    // },
    // {
    //   icon: <RiCoinsLine className="text-white" />,
    //   label: 'Keuangan General',
    //   path: `${location.pathname}/finance`,
    //   background: 'bg-green-600',
    //   backgroundOuter: 'bg-green-100',
    //   status: null,
    // },
  ]);

  return (
    <div className="space-y-6">
      <div className="w-full p-10 bg-white rounded-xl border border-gray-200 backdrop-blur-[80px] space-y-6">
        <BreadCrumbs
          routes={[
            {
              label: "Daftar Pemohon",
            },
            {
              label: "Registrasi Prospecting Non Perorangan",
              path: "/non-perorangan/dashboard",
            },
          ]}
        />
        <div className="flex items-center justify-between">
          <div className="gap-x-2">
            <div className="text-gray-900 text-2xl font-semibold leading-loose">
              Registrasi Prospecting Non Perorangan
            </div>
            <div className="text-gray-600 leading-loose">
              Lengkapi Data Registrasi Prospecting Lembaga Penyalur.
            </div>
          </div>
          <div className="justify-start items-center gap-3 inline-flex">
            <Button
              color="orange"
              className="px-4 py-2.5capitalize leading-tight"
              disabled
              label={
                <div className="flex items-center space-x-2">
                  <FiUpload />
                  <p>Upload Semua Persyaratan</p>
                </div>
              }
              // onClick={() =>
              //   navigate('/financing-request/supplier-application')
              // }
            />
          </div>
        </div>
      </div>

      <Alert
        theme="warning"
        title={"Lengkapi Dokumen di bawah"}
        description={
          "Anda bisa upload Registrasi Prospecting Lembaga Penyalur jika semua dokumen kategori di bawah ini sudah dilengkapi!"
        }
      />

      <div className="flex flex-wrap gap-4">
        {_.map(
          moduleItems,
          ({ icon, label, path, background, backgroundOuter }, index) => (
            <div className="flex flex-col items-center w-[285px] gap-4 bg-white shadow-xl rounded-xl relative">
              <div className="p-4 absolute -top-10">
                <div className={`${backgroundOuter} p-3 rounded-full`}>
                  <div className={`${background} p-3 rounded-full`}>{icon}</div>
                </div>
              </div>
              <span className="mt-12 font-semibold">{_.upperFirst(label)}</span>
              <img src={ProfileUmum} alt="general-information-img" />

              <div className="p-4 w-full">
                <AlertBlankData
                  body={
                    "Lengkapi kesesuaian dokumen adminstrasi yang dipersyaratkan"
                  }
                />
              </div>
              <div className="p-4 w-full border-t">
                <button
                  onClick={() => navigate(path)}
                  className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                >
                  Lengkapi
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default NonPeroranganDashboard;
