import Alert from "components/atoms/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {
  RiComputerLine,
  RiEdit2Line,
  RiEditLine,
  RiFileCopy2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { ConfirmationModal } from "../../../../components";
import Tag from "../../../../components/atoms/Tag";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "features/auth/profileSlice";
import { getProfileAccount } from "services/fdb/profile";
import Button from "components/atoms/Button/Button";
import { getPengajuanAnggotaMemberDetail } from "services/fdb/akunKelompok";
import GeneralInformationImg from "../../../../assets/general_information.png";

// TODO: change this to actual status.
const ProgressStatus = {
  INITIAL: 0,
  DRAFT: 1,
  SUBMITTED: 2,
  REJECTED: 3,
  APPROVED: 4,
  WAIT_VERIF: 5,
};

const AkunPeroranganRegisFormDashboard = (props) => {
  const { type } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const qparams = new URLSearchParams(location?.search);
  const status = parseFloat(qparams.get("status")) || ProgressStatus.INITIAL;

  const [canUpload, setCanUpload] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [moduleItems] = useState([
    {
      icon: <RiFileCopy2Line className="text-[#E62E05]" />,
      label: "Formulir",
      path: `/perorangan/${type}/formulir-pendaftaran`,
      background: "bg-[#FFE6D5]",
      backgroundOuter: "bg-[#FFF4ED]",
      status,
    },
  ]);

  const { data } = useQuery(["GET PROFILE"], getProfileAccount);

  useEffect(() => {
    // TODO: Replace this logic with actual logic to enable the upload button
    setCanUpload(
      moduleItems?.filter((e) =>
        [ProgressStatus.SUBMITTED, ProgressStatus.REJECTED].includes(e.status)
      )?.length === moduleItems?.length
    );
  }, [moduleItems]);

  const onUploadClick = () => {
    setConfirmModal(true);
  };

  const onSubmit = () => {
    // TODO: Integrate to API
    console.log("Pengajuan Daftar Kelompok berhasil di simpan!");
  };

  const handleClickButton = () => {
    if (
      data.data.data.is_draft === false ||
      data.data.data.is_draft === null ||
      data.data.data.is_approved === false
    ) {
      if (type === "perhutanan-sosial") {
        return;
      }
      navigate(
        `/perorangan/${type}/formulir-pendaftaran/edit/${data.data.data.id}`,
        {
          state: {
            member: {
              ...data.data.data,
              group_name: data.data.data.group_profile.name,
            },
          },
        }
      );
    } else {
      navigate(
        `/perorangan/${type}/formulir-pendaftaran/edit/${data.data.data.id}`,
        {
          state: {
            member: {
              ...data.data.data,
              group_name: data?.data?.data?.group_profile?.name,
            },
          },
        }
      );
    }
  };

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      <div className="w-full space-y-4">
        <div className="flex flex-col gap-y-6">
          {/* Header Section */}
          <div className="bg-[#FFFFFF] rounded-xl p-10">
            <div className="flex flex-col w-full">
              <BreadCrumbs
                routes={[
                  {
                    label: "Formulir Pendaftaran",
                    path: `/perorangan/${type}/formulir-pendaftaran`,
                  },
                  {
                    label: "Pendaftaran Perorangan",
                    path: `/perorangan/${type}/formulir-pendaftaran`,
                  },
                ]}
              />
              <div className="mt-6 flex flex-row">
                <div className="flex-1">
                  <div className="flex flex-col">
                    <span className="font-semibold text-[24px] text-[#101828]">
                      Formulir Pendaftaran Perorangan
                    </span>
                    <span className="font-normal text-[14px] text-[#667085]"></span>
                  </div>
                </div>
                <div className="flex-1 text-right">
                  <button
                    className={[
                      "py-3 px-4 rounded-lg text-sm",
                      canUpload
                        ? "bg-[#E34F13] text-white"
                        : "bg-[#D0D5DD]  text-[#667085]",
                    ].join(" ")}
                    disabled={!canUpload}
                    onClick={onUploadClick}
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <RiUploadCloud2Line />
                      <span className="font-semibold">
                        Upload Semua Persyaratan
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {data?.data?.data?.group_profile?.is_draft === true && (
            <Alert
              theme="warning"
              title="Upload Formulir"
              description="Anda bisa upload Formulir Pendaftaran Perorangan jika semua dokumen kategori dibawah ini sudah dilengkapi!"
            />
          )}

          {data?.data?.data?.group_profile?.is_draft === false && (
            <Alert
              theme="warning"
              title="Sedang Proses Pengkajian"
              description="Formulir Pendaftaran Perorangan berhasil diupload dan dalam proses pengkajian, harap menunggu dan selalu mengecek status yang ada ditiap kategori penilaian"
            />
          )}

          <div className="pt-[42px] px-6 pb-6 bg-white rounded-xl border border-gray-200 w-72 relative">
            <div className="absolute p-3 bg-orange-25 rounded-full -top-8 left-1/2 -translate-x-1/2">
              <div className="h-14 w-14 bg-orange-100 text-white rounded-full flex items-center justify-center">
                <RiComputerLine size={28} />
              </div>
            </div>
            <div className="flex flex-col items-center mt-2 gap-2">
              <h5 className="font-medium text-[#101828]">Formulir</h5>
              <div className="flex flex-col gap-6">
                <img
                  src={GeneralInformationImg}
                  alt="General Information Icon"
                />
                {data?.data?.data?.is_draft === true ||
                  (data?.data?.data?.is_draft === null && (
                    <Alert
                      theme="warning"
                      title="Lengkapi Dokumen"
                      description="Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan"
                    />
                  ))}

                {data?.data?.data?.is_draft === false && (
                  <Alert
                    theme="success"
                    title="Data Sudah Dilengkapi"
                    description="Andah sudah melengkapi seluruh data"
                  />
                )}

                {data?.data?.data?.is_draft === true && (
                  <Tag label="Data Belum Lengkap" theme="warning" />
                )}

                {data?.data?.data?.is_draft === false &&
                  data?.data?.data?.is_approved === false && (
                    <div>
                      <Tag label="Ditolak" theme="error" />
                    </div>
                  )}

                {data?.data?.data?.is_draft === false &&
                  data?.data?.data?.is_approved === true && (
                    <div>
                      <Tag label="Disetujui" theme="success" />
                    </div>
                  )}

                <hr />

                {data?.data?.data?.is_draft === null && (
                  <button
                    className="h-10 text-sm font-semibold text-white bg-primary-600 hover:bg-primary-700 rounded-lg"
                    onClick={handleClickButton}
                  >
                    Lengkapi
                  </button>
                )}
                {data?.data?.data?.is_draft !== null && (
                  <button
                    className="h-10 text-sm font-semibold text-[#344054] bg-white border-2 hover:bg-slate-50 rounded-lg flex items-center justify-center gap-2"
                    onClick={handleClickButton}
                  >
                    <RiEditLine size={20} /> Ubah
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* {[ProgressStatus.APPROVED].includes(status) ? (
            <Alert
              title={""}
              description={"Formulir Pendaftaran Perorangan Anda disetujui."}
            />
          ) : undefined}
          {[ProgressStatus.INITIAL, ProgressStatus.DRAFT].includes(status) ? (
            <Alert
              theme={"warning"}
              title={"Upload Formulir"}
              description={
                "Anda bisa upload Formulir Pendaftaran Perorangan jika semua dokumen kategori dibawah ini sudah dilengkapi!"
              }
            />
          ) : undefined}
          {[ProgressStatus.REJECTED].includes(status) ? (
            <Alert
              theme={"warning"}
              title={""}
              description={
                "Setelah proses mengubah data yang ditolak harap kembali mengupload persyaratan"
              }
            />
          ) : undefined}
          {[ProgressStatus.WAIT_VERIF].includes(status) ? (
            <Alert
              theme={"warning"}
              title={""}
              description={
                "Data anda disetujui, harap menunggu verifikiasi oleh Kadiv Penyaluran"
              }
            />
          ) : undefined} */}
          {/* <div className="flex flex-wrap gap-4">
            {_.map(
              moduleItems,
              (
                { icon, label, path, background, backgroundOuter, status },
                index
              ) => (
                <div
                  className="flex flex-col w-[285px] gap-4 bg-white shadow-xl rounded-xl"
                  key={index}
                >
                  <div className="p-4">
                    <div className="flex items-center gap-2">
                      <div className={`${backgroundOuter} p-2 rounded-full`}>
                        <div className={`${background} p-2 rounded-full`}>
                          {icon}
                        </div>
                      </div>
                      <span>{_.upperFirst(label)}</span>
                    </div>
                  </div>

                  <div className="px-4 space-y-4">
                    {[ProgressStatus.SUBMITTED].includes(status) ? (
                      <Alert
                        theme={"success"}
                        title={"Data Sudah Dilengkapi"}
                        description={"Anda sudah melengkapi seluruh data"}
                      />
                    ) : undefined}

                    {[ProgressStatus.INITIAL, ProgressStatus.DRAFT].includes(
                      status
                    ) ? (
                      <Alert
                        theme={"warning"}
                        title={"Lengkapi Dokumen"}
                        description={
                          "Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan"
                        }
                      />
                    ) : undefined}

                    {[ProgressStatus.DRAFT].includes(status) ? (
                      <Tag label={"Data Belum Lengkap"} theme={"warning"} />
                    ) : undefined}

                    {[ProgressStatus.REJECTED].includes(status) ? (
                      <Tag label={"Ditolak"} theme={"error"} />
                    ) : undefined}

                    {[ProgressStatus.APPROVED].includes(status) ? (
                      <Tag label={"Disetujui"} theme={"success"} />
                    ) : undefined}
                  </div>

                  <div className="border" />
                  <div className="p-4 mb-4">
                    <div className="text-right">
                      {data?.data?.data?.is_draft === null && (
                        <Button
                          label="Lengkapi"
                          size="sm"
                          className="bg-green-700 hover:bg-primary-600 text-white text-xs"
                          onClick={handleClickButton}
                        />
                      )}

                      {data?.data?.data?.is_draft === true && (
                        <Button
                          label="Ubah"
                          className="bg-green-700 hover:bg-primary-600 text-white py-2 px-4 rounded-lg font-semibold text-[12px]"
                          onClick={handleClickButton}
                        />
                      )}

                      {data?.data?.data?.is_draft === false && (
                        <Button
                          label="Lihat Dokumen"
                          className="bg-green-700 hover:bg-primary-600 text-white py-2 px-4 rounded-lg font-semibold text-[12px]"
                          onClick={handleClickButton}
                        />
                      )}

                      {status === ProgressStatus.APPROVED ? (
                        <Link
                          to={`${path}/view`}
                          className="bg-[#00BD52] text-white py-2 px-4 rounded-lg font-semibold text-[12px]"
                        >
                          Lihat Dokumen
                        </Link>
                      ) : undefined}

                      {status === ProgressStatus.SUBMITTED ? (
                        <Link
                          to={`${path}/edit`}
                          className="bg-[#00BD52] text-white py-2 px-4 rounded-lg font-semibold text-[12px]"
                        >
                          Ubah
                        </Link>
                      ) : undefined}

                      {[ProgressStatus.DRAFT, ProgressStatus.REJECTED].includes(
                        status
                      ) ? (
                        <Link
                          to={`${path}/edit`}
                          className="bg-white text-gray-700 border-[1px] border-gray-700 py-2 px-4 rounded-lg font-semibold text-[12px]"
                        >
                          <RiEdit2Line className="inline-block w-4 h-4 mr-2 align-middle " />
                          <span className={"align-middle "}>Ubah</span>
                        </Link>
                      ) : undefined}
                    </div>
                  </div>
                </div>
              )
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AkunPeroranganRegisFormDashboard;
