import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { RiMap2Line } from "react-icons/ri";
import { getErrorMessage } from "../../../../helpers";
import { Button } from "../../../index";
import Spinner from "../../Spinner";

function GeotagLocationButton({ label, className, onSuccess, onError }) {
  const [isLoading, setLoading] = useState(false);
  
  const onClick = () => {
    if (navigator) {
      setLoading(true);
      
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLoading(false);
          
          enqueueSnackbar({
            message: "Location retrieved",
            variant: "success"
          });
          
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess(position?.coords);
          }
        },
        (error) => {
          setLoading(false);

          enqueueSnackbar({
            message: getErrorMessage(error),
            variant: "error"
          });
          if (onError && typeof onError === "function") {
            onError(error);
          }
        });
    }
  };
  
  return (
    <Button
      type={'button'}
      className={["col-span-2 bg-white p-4 border border-[#D0D5DD] rounded-lg", className].join(" ")}
      label={(
        <div className={`flex items-center justify-center gap-2`}>
          { isLoading ? <Spinner theme={'primary'} /> : <RiMap2Line className={"w-5 h-5 shrink-0"} /> }
          {label}
        </div>
      )}
      onClick={onClick}
    />
  );
}

export default GeotagLocationButton;