import React from "react";

export const _mapMasterData = (data) => {
  const transformedData = data?.map((item) => ({
    label: item.nama,
    value: item.nama,
    description: item.deskripsi,
    id: item.id,
  }));
  return transformedData;
};
