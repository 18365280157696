import React, { useEffect, useState } from "react";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormRadio,
  InputFormUpload,
} from "components";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import CardForm from "components/molecules/CardForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiAddLine, RiQuestionLine } from "react-icons/ri";
import AtomDatePicker from "components/atoms/Datepicker";
import validationSchema from "./validationSchema";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/danaProgram/fileService";

const InformasiKeuanganPajak = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [controllerTarget, setControllerTarget] = useState(null);
  const currentYear = new Date().getFullYear();

  function subtractMonths(months) {
    let date = new Date();
    date.setMonth(date.getMonth() - months);
    return Intl.DateTimeFormat("en", { month: "long" }).format(date);
  }

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    register,
    unregister,
  } = methods;

  const watcherRadioPkpStatus = useWatch({
    control,
    name: "is_pkp",
  });

  const watcherPPhStatus = useWatch({
    control,
    name: "is_income_cut",
  });

  const watcherTaxActivity = useWatch({
    control,
    name: "is_tax_activity",
  });

  useEffect(() => {
    if (!watcherTaxActivity) {
      unregister([
        "firstSptYear",
        "firstSptDate",
        "firstSptNumber",
        "firstSptDesc",
        "firstSptFile",
        "secondSptYear",
        "secondSptDate",
        "secondSptNumber",
        "secondSptDesc",
        "secondSptFile",
      ]);
    }
  }, [watcherTaxActivity]);

  useEffect(() => {
    if (!watcherPPhStatus) {
      unregister([
        "pphNumberFirstMonth",
        "pphDescFirstMonth",
        "pphFileFirstMonth",
        "pphNumberSecondMonth",
        "pphDescSecondMonth",
        "pphFileSecondMonth",
        "pphNumberThirdMonth",
        "pphDescThirdMonth",
        "pphFileThirdMonth",
      ]);
    }
  }, [watcherPPhStatus]);

  function registerAndSetValue(field, monthOffset, yearOffset) {
    register(field);
    setValue(field, subtractMonths(monthOffset));
    setValue(field.replace("Month", "Year"), currentYear - yearOffset);
  }
  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);

      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: Math.round(parseInt(response.data.data.size) / 1000),
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: Math.round(parseInt(response.data.data.size) / 1000),
        };
        setValue(key, temp);
      }
    } catch (error) {
      throw error;
    }
  });

  useEffect(() => {
    if (draftData) {
      Object.keys(draftData).forEach((key) => {
        if (key === "tax") {
          Object.keys(draftData[key]).forEach((item) => {
            if (item === "expire_date" || item === "pkpExpiredDate") {
              setValue(item, draftData[key][item]);
            } else if (item.includes("url")) {
              getFileMutation.mutate({
                id: draftData[key][item],
                key: item,
              });
            } else if (item === "tax_payment") {
              let dat = draftData[key][item];
              for (let i = 0; i < dat.length; i++) {
                setValue(`${item}.${i}.is_loss`, dat[i].is_loss);
                setValue(
                  `${item}.${i}.no_payment_evidence`,
                  dat[i].no_payment_evidence
                );
                setValue(
                  `${item}.${i}.tax_payment_evidence_date`,
                  dat[i].tax_payment_evidence_date
                );
                setValue(`${item}.${i}.tax_type`, dat[i].tax_type);
                getFileMutation.mutate({
                  id: dat[i].url_file_payment_evidence,
                  key: `${item}.${i}.url_file_payment_evidence`,
                });
              }
            } else if (item === "spt_report") {
              for (let i = 0; i < draftData[key][item].length; i++) {
                if (i < 1) {
                  setValue("firstSptYear", draftData[key][item][i].year);
                  setValue(
                    "firstSptNumber",
                    draftData[key][item][i].no_spt_annual_report
                  );
                  setValue("firstSptDesc", draftData[key][item][i].description);
                  setValue("firstSptDate", draftData[key][item][i].report_date);
                  getFileMutation.mutate({
                    key: "firstSptFile",
                    id: draftData[key][item][i].url_file_spt_report,
                  });
                } else {
                  setValue("secondSptYear", draftData[key][item][i].year);
                  setValue(
                    "secondSptNumber",
                    draftData[key][item][i].no_spt_annual_report
                  );
                  setValue(
                    "secondSptDesc",
                    draftData[key][item][i].description
                  );
                  setValue(
                    "secondSptDate",
                    draftData[key][item][i].report_date
                  );
                  getFileMutation.mutate({
                    key: "secondSptFile",
                    id: draftData[key][item][i].url_file_spt_report,
                  });
                }
              }
            } else if (item === "npwp_company" || item === "url_file_skt") {
              getFileMutation.mutate({ id: draftData[key][item], key: item });
            } else if (item === "pph_report") {
              setValue(
                "pphNumberFirstMonth",
                draftData[key][item]?.[0]?.no_report
              );
              setValue(
                "pphDescFirstMonth",
                draftData[key][item]?.[0]?.no_description
              );

              getFileMutation.mutate({
                id: draftData[key][item]?.[0]?.url_file_report_evidence,
                key: "pphFileFirstMonth",
              });

              setValue(
                "pphNumberSecondMonth",
                draftData[key][item]?.[1]?.no_report
              );
              setValue(
                "pphDescSecondMonth",
                draftData[key][item]?.[1]?.no_description
              );

              getFileMutation.mutate({
                id: draftData[key][item]?.[1]?.url_file_report_evidence,
                key: "pphFileSecondMonth",
              });

              if (methods.getValues("is_income_cut") === true) {
                setValue(
                  "pphNumberThirdMonth",
                  draftData[key][item]?.[2]?.no_report
                );
                setValue(
                  "pphDescThirdMonth",
                  draftData[key][item]?.[2]?.no_description
                );
                getFileMutation.mutate({
                  id: draftData[key][item]?.[2]?.url_file_report_evidence,
                  key: "pphFileThirdMonth",
                });
              }
            } else if (item === "ppn_report") {
              setValue(
                "ppnNumberFirstMonth",
                draftData[key][item]?.[0]?.no_report
              );
              setValue(
                "ppnDescFirstMonth",
                draftData[key][item]?.[0]?.no_description
              );

              getFileMutation.mutate({
                id: draftData[key][item]?.[0]?.url_file_report_evidence,
                key: "ppnFileFirstMonth",
              });

              setValue(
                "ppnNumberSecondMonth",
                draftData[key][item]?.[1]?.no_report
              );
              setValue(
                "ppnDescSecondMonth",
                draftData[key][item]?.[1]?.no_description
              );

              getFileMutation.mutate({
                id: draftData[key][item]?.[1]?.url_file_report_evidence,
                key: "ppnFileSecondMonth",
              });

              setValue(
                "ppnNumberThirdMonth",
                draftData[key][item]?.[2]?.no_report
              );
              setValue(
                "ppnDescThirdMonth",
                draftData[key][item]?.[2]?.no_description
              );
              getFileMutation.mutate({
                id: draftData[key][item]?.[2]?.url_file_report_evidence,
                key: "ppnFileThirdMonth",
              });
            } else {
              if (!draftData.tax_payment) {
                let temp = [
                  {
                    year: "",
                    is_loss: "",
                    tax_type: "",
                    no_payment_evidence: "",
                    tax_payment_evidence_date: "",
                    url_file_payment_evidence: "",
                  },
                ];
                setValue("tax_payment", temp);
              }
              setValue(item, draftData[key][item]);
            }
          });
        } else if (key.includes("document")) {
          setValue(key, draftData[key]["url_file_other_document"]);
        }
      });
    } else {
      const defaultTaxValues = {
        npwp_personal: "",
        npwp_company: "",
        npwp_address: "",
        url_file_skt: "",
        no_certificate_domicile: "",
        expire_date: "",
        is_pkp: null,
        register_date_pkp: "",
        is_tax_activity: null,
        url_file_pkp: "",
        is_tax_collectors: null,
        is_income_cut: null,
        vat_report: [
          {
            month: "",
            year: "",
            no_report: "",
            no_description: "",
            url_file_report_evidence: "",
          },
        ],
      };

      Object.keys(defaultTaxValues).forEach((key) => {
        setValue(key, defaultTaxValues[key]);
      });
    }
    registerAndSetValue("pphMonthFirst", 1, 1);
    registerAndSetValue("pphMonthSecond", 2, 2);

    if (methods.getValues("is_income_cut") === true) {
      registerAndSetValue("pphMonthThird", 3, 3);
    }
  }, [draftData, setValue, methods]);

  useEffect(() => {
    if (controllerTarget) {
      // Check if controllerTarget is not null
      setValue(controllerTarget, (prevUrls) => {
        const prevUrlsArray = Array.isArray(prevUrls) ? prevUrls : [];
        const newUrls = [...new Set([...prevUrlsArray, ...uploadedUrls])];
        return newUrls;
      });
    }
  }, [uploadedUrls, controllerTarget]);

  const {
    fields: repaymentFields,
    append: appendRepayment,
    remove: removeRepayment,
  } = useFieldArray({
    control,
    name: "tax_payment",
  });

  const generatePPn = () => {
    register(`ppnFirstYear`);
    register(`ppnFirstMonth`);
    register(`ppnSecondYear`);
    register(`ppnSecondMonth`);
    register(`ppnThirdYear`);
    register(`ppnThirdMonth`);
    setValue(`ppnFirstYear`, currentYear);
    setValue(`ppnFirstMonth`, subtractMonths(1));
    setValue(`ppnSecondYear`, currentYear);
    setValue(`ppnSecondMonth`, subtractMonths(2));
    setValue(`ppnThirdYear`, currentYear);
    setValue(`ppnThirdMonth`, subtractMonths(3));
  };

  return (
    <>
      <FormProvider {...methods}>
        <CardForm label={"Pajak"} buttonCollapse={true}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"npwp_personal"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"NPWP"}
                  placeholder={"NPWP"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputFormUpload
                  uploadFile={mutation}
                  controllerName={`npwp_company`}
                  label={"NPWP Perusahaan"}
                  methods={methods}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`npwp_address`}
                  className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                  label={"Alamat NPWP"}
                  placeholder={"Alamat NPWP"}
                  textArea={true}
                  required={true}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  uploadFile={mutation}
                  controllerName={`url_file_skt`}
                  label={"Surat Keterangan Terdaftar (SKT)"}
                  methods={methods}
                />
              </div>
              <div className="flex-1 pt-2"></div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"no_certificate_domicile"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"Nomor Surat Keterangan Domisili"}
                  placeholder={"Nomor Surat Keterangan Domisili"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <AtomDatePicker
                  controllerName={"expire_date"}
                  label="Tanggal Kadaluarsa"
                  required={true}
                  newDate={true}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Apakah Lembaga/ institusi/ perusahaan Anda termasuk ke dalam pengusaha kena pajak (PKP)?"
                  }
                  controllerName={"is_pkp"}
                  values={[
                    {
                      label: "ya",
                      value: true,
                    },
                    {
                      label: "tidak",
                      value: false,
                    },
                  ]}
                />
              </div>
            </div>
            {watcherRadioPkpStatus === true && (
              <>
                {generatePPn()}
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      uploadFile={mutation}
                      controllerName={`url_file_pkp`}
                      label={
                        watcherRadioPkpStatus === true
                          ? "Dokumen pengukuhan PKP"
                          : "Dokumen Pengukuhan Non PKP"
                      }
                      methods={methods}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    {watcherRadioPkpStatus === true && (
                      <AtomDatePicker
                        controllerName={"register_date_pkp"}
                        label="Tanggal Kadaluarsa"
                        required={true}
                        newDate={true}
                        placeholder="Tanggal Kadaluarsa"
                      />
                    )}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg text-[#026AA2] border-[#7CD4FD]">
                    <RiQuestionLine />
                    Laporan PPN selama 3 bulan terakhir
                  </div>
                </div>
                <CardForm
                  label={`Bulan ${subtractMonths(1)} ${currentYear}`}
                  buttonCollapse={true}
                  childerForm={true}
                >
                  <>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputForm
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          controllerName={"ppnNumberFirstMonth"}
                          label={"Nomor Laporan"}
                          placeholder={"Nomor Laporan"}
                          required={true}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={`ppnDescFirstMonth`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Deskripsi"}
                          placeholder={"Deskripsi"}
                          textArea={true}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputFormUpload
                          uploadFile={mutation}
                          controllerName={`ppnFileFirstMonth`}
                          label={"Dokumen Bukti Laporan"}
                          methods={methods}
                        />
                      </div>
                      <div className="flex-1 pt-2"></div>
                    </div>
                  </>
                </CardForm>
                <CardForm
                  label={`Bulan ${subtractMonths(2)} ${currentYear}`}
                  buttonCollapse={true}
                  childerForm={true}
                >
                  <>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={"ppnNumberSecondMonth"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nomor Laporan"}
                          placeholder={"Nomor Laporan"}
                          required={true}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={`ppnDescSecondMonth`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Deskripsi"}
                          placeholder={"Deskripsi"}
                          textArea={true}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputFormUpload
                          uploadFile={mutation}
                          controllerName={`ppnFileSecondMonth`}
                          label={"Dokumen Bukti Laporan"}
                          methods={methods}
                        />
                      </div>
                      <div className="flex-1 pt-2"></div>
                    </div>
                  </>
                </CardForm>
                <CardForm
                  label={`Bulan ${subtractMonths(3)} ${currentYear}`}
                  buttonCollapse={true}
                  childerForm={true}
                >
                  <>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={"ppnNumberThirdMonth"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nomor Laporan"}
                          placeholder={"Nomor Laporan"}
                          required={true}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={`ppnDescThirdMonth`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Deskripsi"}
                          placeholder={"Deskripsi"}
                          textArea={true}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputFormUpload
                          uploadFile={mutation}
                          controllerName={`ppnFileThirdMonth`}
                          label={"Dokumen Bukti Laporan"}
                          methods={methods}
                        />
                      </div>
                      <div className="flex-1 pt-2"></div>
                    </div>
                  </>
                </CardForm>
              </>
            )}

            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Apakah Lembaga/ institusi/ perusahaan Anda melakukan aktivitas perpajakan yang mendapatkan fasilitas dibebaskan?"
                  }
                  controllerName={"is_tax_activity"}
                  values={[
                    {
                      label: "ya",
                      value: true,
                    },
                    {
                      label: "tidak",
                      value: false,
                    },
                  ]}
                />
              </div>
            </div>
            {watcherTaxActivity === true ? (
              <>
                <div className="flex">
                  <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg text-[#026AA2] border-[#7CD4FD]">
                    <RiQuestionLine />
                    Silahkan lengkapi data laporan SPT Tahunan untuk periode dua
                    tahun terakhir dari lembaga/ institusi/ perusahaan Anda
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      uploadFile={mutation}
                      controllerName={`url_file_tax_activity`}
                      label={"Dokumen Surat Keterangan Bebas Pajak (SKB)"}
                      methods={methods}
                    />
                  </div>
                  <div className="flex-1 pt-2"></div>
                </div>
                <CardForm
                  label={`Tahun ${currentYear - 1}`}
                  buttonCollapse={true}
                  childerForm={true}
                >
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <InputForm
                        className={`py-2 px-4 mt-2 border w-full rounded-md`}
                        controllerName={"firstSptYear"}
                        label="Tahun"
                        placeholder="Tahun"
                        required={true}
                      />
                    </div>
                    <div className="flex-1">
                      <InputForm
                        controllerName={"firstSptNumber"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md`}
                        label={"Nomor Tanda Terima pelaporan SPT Tahunan"}
                        placeholder={"Nomor Tanda Terima pelaporan SPT Tahunan"}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex-1 pt-2">
                      <AtomDatePicker
                        controllerName={"firstSptDate"}
                        label="Tanggal Laporan"
                        required={true}
                        newDate={true}
                      />
                    </div>
                    <div className="flex-1 pt-2">
                      <InputForm
                        controllerName={`firstSptDesc`}
                        className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                        label={"Deskripsi"}
                        placeholder={"Deskripsi"}
                        textArea={true}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex-1 pt-2">
                      <InputFormUpload
                        uploadFile={mutation}
                        controllerName={`firstSptFile`}
                        label={"Dokumen Laporan SPT"}
                        methods={methods}
                      />
                    </div>
                    <div className="flex-1 pt-2"></div>
                  </div>
                </CardForm>
                <CardForm
                  label={`Tahun ${currentYear - 2}`}
                  buttonCollapse={true}
                  childerForm={true}
                >
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <InputForm
                        className={`py-2 px-4 mt-2 border w-full rounded-md`}
                        controllerName={"secondSptYear"}
                        label="Tahun"
                        placeholder="Tahun"
                        required={true}
                        newDate={true}
                      />
                    </div>
                    <div className="flex-1">
                      <InputForm
                        controllerName={"secondSptNumber"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md`}
                        label={"Nomor Tanda Terima pelaporan SPT Tahunan"}
                        placeholder={"Nomor Tanda Terima pelaporan SPT Tahunan"}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex-1 pt-2">
                      <AtomDatePicker
                        controllerName={"secondSptDate"}
                        label="Tanggal Laporan"
                        required={true}
                        newDate={true}
                      />
                    </div>
                    <div className="flex-1 pt-2">
                      <InputForm
                        controllerName={`secondSptDesc`}
                        className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                        label={"Deskripsi"}
                        placeholder={"Deskripsi"}
                        textArea={true}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex-1 pt-2">
                      <InputFormUpload
                        uploadFile={mutation}
                        controllerName={`secondSptFile`}
                        label={"Dokumen Laporan SPT"}
                        methods={methods}
                      />
                    </div>
                    <div className="flex-1 pt-2"></div>
                  </div>
                </CardForm>
              </>
            ) : (
              ""
            )}
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Apakah lembaga/ institusi/ perusahaan Anda adalah pemungut pajak?"
                  }
                  controllerName={"is_tax_collectors"}
                  values={[
                    {
                      label: "ya",
                      value: true,
                    },
                    {
                      label: "tidak",
                      value: false,
                    },
                  ]}
                />
              </div>
            </div>
            {watch("is_tax_collectors") === true && (
              <div className="flex gap-4">
                <div className="flex-1 pt-2">
                  <InputFormUpload
                    uploadFile={mutation}
                    controllerName={`url_file_tax_collectors`}
                    label={"Dokumen Wajib Pungut"}
                    methods={methods}
                  />
                </div>
                <div className="flex-1 pt-2"></div>
              </div>
            )}
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Apakah lembaga/ institusi/ perusahaan Anda melakukan pemotongan PPH 21 pada penghasilan karyawan Anda?"
                  }
                  controllerName={"is_income_cut"}
                  values={[
                    {
                      label: "ya",
                      value: true,
                    },
                    {
                      label: "tidak",
                      value: false,
                    },
                  ]}
                />
              </div>
            </div>
            {watcherPPhStatus === true && (
              <>
                {" "}
                <div className="flex">
                  <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg text-[#026AA2] border-[#7CD4FD]">
                    <RiQuestionLine />
                    Laporan PPh 21 selama 3 bulan terakhir
                  </div>
                </div>
                <CardForm
                  label={`Bulan ${subtractMonths(1)} ${currentYear}`}
                  buttonCollapse={true}
                  childerForm={true}
                >
                  <>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={"pphNumberFirstMonth"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nomor Laporan"}
                          placeholder={"Nomor Laporan"}
                          required={true}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={`pphDescFirstMonth`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Deskripsi"}
                          placeholder={"Deskripsi"}
                          textArea={true}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputFormUpload
                          uploadFile={mutation}
                          controllerName={`pphFileFirstMonth`}
                          label={"Dokumen Bukti Laporan"}
                          methods={methods}
                        />
                      </div>
                      <div className="flex-1 pt-2"></div>
                    </div>
                  </>
                </CardForm>
                <CardForm
                  label={`Bulan ${subtractMonths(2)} ${currentYear}`}
                  buttonCollapse={true}
                  childerForm={true}
                >
                  <>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={"pphNumberSecondMonth"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nomor Laporan"}
                          placeholder={"Nomor Laporan"}
                          required={true}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={`pphDescSecondMonth`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Deskripsi"}
                          placeholder={"Deskripsi"}
                          textArea={true}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputFormUpload
                          uploadFile={mutation}
                          controllerName={`pphFileSecondMonth`}
                          label={"Dokumen Bukti Laporan"}
                          methods={methods}
                        />
                      </div>
                      <div className="flex-1 pt-2"></div>
                    </div>
                  </>
                </CardForm>
                <CardForm
                  label={`Bulan ${subtractMonths(3)} ${currentYear}`}
                  buttonCollapse={true}
                  childerForm={true}
                >
                  <>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={"pphNumberThirdMonth"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nomor Laporan"}
                          placeholder={"Nomor Laporan"}
                          required={true}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={`pphDescThirdMonth`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Deskripsi"}
                          placeholder={"Deskripsi"}
                          textArea={true}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputFormUpload
                          uploadFile={mutation}
                          controllerName={`pphFileThirdMonth`}
                          label={"Dokumen Bukti Laporan"}
                          methods={methods}
                        />
                      </div>
                      <div className="flex-1 pt-2"></div>
                    </div>
                  </>
                </CardForm>{" "}
              </>
            )}
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg text-[#026AA2] border-[#7CD4FD]">
                <RiQuestionLine />
                Silahkan Anda lengkapi data pelunasan Pajak untuk periode 2
                tahun terakhir yang telah lembaga/ instansi/ perusahaan Anda
                bayarkan
              </div>
            </div>
            <>
              {repaymentFields.map((data, index) => {
                register(`tax_payment.${index}.year`);
                setValue(
                  `tax_payment.${index}.year`,
                  currentYear - (index + 1)
                );
                return (
                  <>
                    <CardForm
                      label={`Tahun ${currentYear - (index + 1)}`}
                      buttonCollapse={true}
                      childerForm={true}
                      deleteOption={index !== 0 && true}
                      customDeleteFunction={() => removeRepayment(index)}
                    >
                      <div className="flex gap-4 items-end border-t pt-2">
                        <div className="w-full">
                          <div className="flex gap-4">
                            <div className="flex-1 pt-2">
                              <InputFormRadio
                                label={"Apakah nihil?"}
                                values={[
                                  {
                                    label: "ya",
                                    value: true,
                                  },
                                  {
                                    label: "tidak",
                                    value: false,
                                  },
                                ]}
                                controllerName={`tax_payment.${index}.is_loss`}
                              />
                            </div>
                          </div>
                          {watch(`tax_payment.${index}.is_loss`) === true && (
                            <div className="w-full">
                              <div className="flex gap-4">
                                <div className="flex-1 pt-2">
                                  <InputForm
                                    controllerName={`tax_payment.${index}.tax_type`}
                                    className={`py-2 px-4 mt-2 border w-full rounded-md`}
                                    label={"Jenis Pajak"}
                                    required={
                                      watch(`tax_payment.${index}.is_loss`) ===
                                      true
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                                <div className="flex-1 pt-2">
                                  <InputForm
                                    controllerName={`tax_payment.${index}.no_payment_evidence`}
                                    className={`py-2 px-4 mt-2 border w-full rounded-md`}
                                    label={"Nomor Bukti Pelunasan"}
                                    required={
                                      watch(`tax_payment.${index}.is_loss`) ===
                                      true
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4">
                                <div className="flex-1 pt-2">
                                  <AtomDatePicker
                                    controllerName={`tax_payment.${index}.tax_payment_evidence_date`}
                                    label={"Tanggal Bukti Pelunasan Pajak"}
                                    required={
                                      watch(`tax_payment.${index}.is_loss`) ===
                                      true
                                        ? true
                                        : false
                                    }
                                    newDate={true}
                                  />
                                </div>
                                <div className="flex-1 pt-2">
                                  <InputFormUpload
                                    uploadFile={mutation}
                                    controllerName={`tax_payment.${index}.url_file_payment_evidence`}
                                    label={"Dokumen Bukti Pelunasan"}
                                    methods={methods}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="border"></div>
                    </CardForm>
                  </>
                );
              })}
              <div className="flex flex-row-reverse my-5 mx-4 ">
                <Button
                  className="p-4 border rounded-lg"
                  label={
                    <div className="flex items-center gap-2 font-[14px]">
                      <RiAddLine />
                      Tambah Data Pelunasan Pajak
                    </div>
                  }
                  onClick={() => {
                    appendRepayment({
                      year: "",
                      is_loss: "",
                      tax_type: "",
                      no_payment_evidence: "",
                      tax_payment_evidence_date: "",
                      url_file_payment_evidence: "",
                    });
                  }}
                />
              </div>
            </>
          </>
        </CardForm>
        <CardForm label={"Lampirkan Dokumen Pendukung"} buttonCollapse={true}>
          <div className="flex gap-4">
            <div className="flex-1 pt-2">
              <InputFormUpload
                controllerName={`document_support`}
                label={"Dokumen Lainnya (Multiple)"}
                required={"true"}
                isMulti={true}
                isDaprog={true}
                setUploadedUrls={setUploadedUrls}
                setControllerTarget={setControllerTarget}
                uploadFile={mutation}
              />
            </div>
          </div>
        </CardForm>

        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={!isValid}
          submitActionButton={() => {
            console.log(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default InformasiKeuanganPajak;
