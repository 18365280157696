import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import {
  RiUploadCloud2Line,
  RiComputerLine,
  RiBankLine,
  RiCoinsLine,
  RiApps2Line,
  RiShieldCheckLine,
  RiEdit2Line,
} from "react-icons/ri";
import { useNavigate } from "react-router";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import AlertBlankData from "components/molecules/Alert";
import { AlertDataComplete } from "components/molecules/Alert";
import ProfileUmumGlobalState from "services/danaProgram/globalState";
import { ToastContext, SuccessToast } from "components/atoms/Toast";

import { createPortal } from "react-dom";

import { ConfirmationModal } from "components";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import {
  getHomepageStatus,
  submitAllData,
} from "services/danaProgram/homepage";
import { stringify } from "postcss";
import { useAuthContext } from "providers/authProvider";

const PenilaianLemtaraChild = ({ statusComplete, data, userId }) => {
  const navigate = useNavigate();

  const [modal, setModal] = useState({ type: "", isOpen: false });

  const {
    showToast,
    toastMessage,
    toastDescription,
    showToastMessage,
    hideToastMessage
  } = useContext(ToastContext);

  const submitMutation = useMutation({
    mutationFn: (params) => {
      return submitAllData(params).then(response => {
        console.log(response)
        if (response?.data?.code == 200) {
          setModal({ type: "", isOpen: false });
          showToastMessage(
            "Data berhasil dikirim!",
            "Data yang anda masukan sudah berhasil dikirim."
          )
        }
      })
    },
  });

  const onClickSubmit = () => {
    console.log(data);
    data.general_profile.status = "NEED_APPROVAL";
    data.organization_profile.status = "NEED_APPROVAL";
    data.financial_profile.status = "NEED_APPROVAL";
    data.resource.status = "NEED_APPROVAL";
    data.safe_guards.status = "NEED_APPROVAL";
    data.program_experience.status = "NEED_APPROVAL";
    Object.assign(data, { user_id: userId });

    submitMutation.mutate(data);
  };

  const [moduleItems, setModuleItems] = useState([
    {
      icon: <RiComputerLine className="text-white text-3xl " />,
      label: "profile umum",
      name: "general_profile",
      path: "/penilaian-lemtara/profile-umum",
      background: "bg-[#E62E05]",
      backgroundOuter: "bg-[#FFE6D5]",
      status: 1,
      fullStep: 3,
      currentStep: 1,
    },
    {
      icon: <RiBankLine className="text-white text-3xl " />,
      label: "profile organisasi",
      name: "organization_profile",
      path: "/penilaian-lemtara/profile-organisasi",
      background: "bg-[#1570EF]",
      backgroundOuter: "bg-[#D1E9FF]",
      status: 1,
      fullStep: 6,
      currentStep: 1,
    },
    {
      icon: <RiCoinsLine className="text-white text-3xl " />,
      label: "sumber daya",
      name: "resource",
      path: "/penilaian-lemtara/sumber-daya",
      background: "bg-[#099250]",
      backgroundOuter: "bg-[#D3F8DF]",
      status: 1,
      fullStep: 2,
      currentStep: 1,
    },
    {
      icon: <RiComputerLine className="text-white text-3xl " />,
      label: "profile keuangan",
      name: "financial_profile",
      path: "/penilaian-lemtara/profile-keuangan",
      background: "bg-[#CA8504]",
      backgroundOuter: "bg-[#FEF7C3]",
      status: 1,
      fullStep: 4,
      currentStep: 1,
    },
    {
      icon: <RiApps2Line className="text-white text-3xl " />,
      label: "pengalaman program",
      name: "program_experience",
      path: "/penilaian-lemtara/pengalaman-program",
      background: "bg-[#6938EF]",
      backgroundOuter: "bg-[#EBE9FE]",
      status: 1,
      fullStep: 1,
      currentStep: 1,
    },
    {
      icon: <RiShieldCheckLine className="text-white text-3xl " />,
      label: "safeguards",
      name: "safe_guards",
      path: "/penilaian-lemtara/safeguards",
      background: "bg-[#DD2590]",
      backgroundOuter: "bg-[#FCE7F6]",
      status: 1,
      fullStep: 1,
      currentStep: 1,
    },
  ]);

  return (
    <div className="w-full space-y-4">
      {showToast && (
        <SuccessToast
          message={toastMessage}
          message_description={toastDescription}
          onClose={hideToastMessage}
        />
      )}

      {modal.type == "confirm" && (
        createPortal(
          <ConfirmationModal
            open={modal.isOpen}
            onClose={() => setModal({ type: "", isOpen: false })}
            cancelLabel={"Batal"}
            confirmLabel={"Ya"}
            onSubmit={onClickSubmit}
          >
            <div>
              <div className="text-gray-900 font-semibold text-lg">Anda yakin akan mengirimkan formulir penilaian kapasitas ini?</div>
              <div className="text-gray-600 text-sm">
                Data Anda akan diverifikasi dan dinilai oleh Team Ahli kami. Silahkan cek email Anda secara berkala untuk mendapatkan informasi terkait status verifikasi.
              </div>
            </div>
          </ConfirmationModal>,
          document.body,
          'confirm-upload-file-requirement-proposal-modal'
        )
      )}

      <div className="flex flex-col gap-y-6">
        {/* Header Section */}
        <div className="bg-[#FFFFFF] rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Penilaian LEMTARA",
                  path: "/penilaian-lemtara",
                },
              ]}
            />
            <div className="flex flex-row">
              <div className="flex-1">
                <div className="flex flex-col">
                  <span className="font-semibold text-[24px] text-[#101828]">
                    Penilaian LEMTARA
                  </span>
                  <span className="font-normal text-[14px] text-[#667085]">
                    Formulir Penilaian kapasitas calon lemtara.
                  </span>
                </div>
              </div>
              <div className="flex-1 text-right">
                {statusComplete ? (
                  <button
                    className="bg-[#E34F13] py-2 px-4 rounded-lg text-white transition-colors hover:bg-[#e87242] active:bg-[#c43d0f]"
                    // onClick={() => onClickSubmit()}
                    onClick={() => setModal({ type: 'confirm', isOpen: true })}
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <RiUploadCloud2Line />
                      <span className="font-semibold font-[14px]">
                        Upload Semua Persyaratan
                      </span>
                    </div>
                  </button>
                ) : (
                  <button
                    disabled
                    className="bg-[#D0D5DD] py-2 px-4 rounded-lg text-[#667085]"
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <RiUploadCloud2Line />
                      <span className="font-semibold font-[14px]">
                        Upload Semua Persyaratan
                      </span>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {!statusComplete && (
          <AlertBlankData
            body={`Anda bisa upload Penilaian Kapasitas Calon Lemtara jika semua dokumen kategori dibawah ini sudah dilengkapi!`}
          />
        )}

        <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-12">
          {_.map(
            moduleItems,
            ({ icon, label, name, path, background, backgroundOuter }) => {
              const moduleLabel = name.replace(/\s+/g, "_");
              const isFilled = [moduleLabel]?.status !== "";
              return (
                <div
                  key={label}
                  className="bg-white shadow-xl rounded-xl flex flex-col relative h-full mt-7"
                >
                  <div
                    className={`${backgroundOuter} p-2 rounded-full w-16 h-16 rounded-full absolute top-[-30px] left-1/2 transform -translate-x-1/2`}
                  >
                    <div className={`${background} p-2 rounded-full `}>
                      {icon}
                    </div>
                  </div>
                  <div className="pb-4 pt-8 px-4 self-center">
                    <div className="flex items-center gap-2">
                      <span className={`font-medium pt-4 text-lg`}>
                        {_.upperFirst(label)}
                      </span>
                    </div>
                  </div>

                  <div className="px-4 h-max self-center mb-5">
                    <img
                      alt="illustration"
                      src={process.env.PUBLIC_URL + "/" + label + ".webp"}
                    />
                  </div>
                  <div className="px-4 h-max">
                    {data[name]?.status === "SUBMITTED" ? (
                      <AlertDataComplete />
                    ) : (
                      <AlertBlankData
                        body={`Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan.`}
                      />
                    )}
                  </div>

                  <div className="border-b-2 py-2 mt-auto" />
                  <div className="px-4 py-4 flex justify-end">
                    <div className="text-right w-full">
                      {data[name]?.status === "DRAFTED" ||
                        data[name]?.status === "SUBMITTED" ? (
                        <button
                          onClick={() => navigate(path)}
                          className="bg-base-white text-[#344054] border border-gray-300 py-2 px-4 justify-center rounded-lg font-semibold text-xs flex items-center gap-2 w-full"
                        >
                          <RiEdit2Line className="text-xs" />
                          <span>Ubah</span>
                        </button>
                      ) : (
                        <>
                          {data[name]?.status === "NEED_APPROVAL" ? (
                            <button
                              onClick={() => navigate(path)}
                              className="bg-[#00BD52] text-white py-2 px-4 rounded-lg font-semibold text-[12px] w-full"
                            >
                              Lihat Dokumen
                            </button>
                          ) : (
                            <button
                              onClick={() => navigate(path)}
                              className="bg-[#00BD52] text-white py-2 px-4 rounded-lg font-semibold text-[12px] w-full"
                            >
                              Lengkapi
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default PenilaianLemtaraChild;
