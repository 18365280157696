import { ConfirmationModal, HeaderLabel, StepProgress } from 'components';
import AlertBlankData from 'components/molecules/Alert';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
// import GeneralInformationForm from './generalInfoForm';
import { useDispatch } from 'react-redux';
import { useAuth } from 'hooks/auth';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getChannelingDirectProfile,
  updateChannelingDirectProfile,
  updateDraftChannelingDirectProfile,
} from 'services';
import GeneralInformationForm from './GeneralInformationForm';

const ProfileUmum = (props) => {
  const { type } = props;
  const navigate = useNavigate();
  const { id } = useAuth();
  const { data } = useQuery({
    queryKey: ['channeling-direct-profile', id],
    queryFn: async () => (await getChannelingDirectProfile(id)).data?.data,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const profileData = useMemo(() => {
    return {
      institution_name: data?.name ?? '',
      institution_type: data?.type_agency ?? '',
      business_type: data?.type_bussines ?? '',
      institution_npwp_number: data?.npwp ?? '',
      // institution_npwp: data?.npwp_file_url?.split('/').pop() ?? null,
      email: data?.email ?? '',
      phone_number: data?.phone_number ?? '',
      country: data?.country ?? '',
      province: data?.province ?? '',
      city: data?.city ?? '',
      district: data?.district ?? '',
      village: data?.village ?? '',
      postal_code: data?.postal_code ?? '',
      address: data?.address ?? '',
      contact_person: {
        name: data?.contact_person_name ?? '',
        position: data?.contact_person_position ?? '',
        email: data?.contact_person_email ?? '',
        phone_number: data?.contact_person_phone_number ?? '',
        ktp_number: data?.contact_person_ktp ?? '',
        ktp: data?.contact_person_ktp_file,
        npwp_number: data?.contact_person_npwp ?? '',
      },
      // tab2
      programs: data?.direct_financing_application_letter_file_url ?? '',
      become_agency: data?.background ?? '',
      experience: data?.experience ?? '',
    };
  }, [data]);

  useEffect(() => {
    if (profileData) {
      setBodyToSend((state) => ({ ...state, ...profileData }));
    }
  }, [profileData]);

  const [activeStep, setActiveStep] = useState(1);
  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  const routes = useMemo(
    () => [
      {
        label: 'Formulir Pendaftaran',
        path: `/`,
      },
      {
        label: 'Registrasi Prospecting Lembaga Penyalur',
        path: `/non-perorangan/${type}`,
      },
      {
        label: 'Registrasi Prospecting Lembaga Penyalur',
        path: `/non-perorangan/${type}/profile-umum`,
      },
    ],
    [type]
  );

  const onBack = useCallback(() => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, navigate]);

  const onDraft = useCallback((payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));
    setDraftModal(true);
  }, []);

  // const onSubmit = useCallback(
  //   (payload) => {
  //     console.log({ payload });

  //     setBodyToSend((state) => ({ ...state, ...payload }));
  //     if (activeStep < stepProgress.length) {
  //       setActiveStep(activeStep + 1);
  //     } 
  //     else {
  //       setConfirmModal(true);
  //     }
  //   },
  //   [activeStep]
  // );
  const onSubmit = useCallback(
    (payload) => {
      // setConfirmModal(true);
    },
    []
  );

  const params = useMemo(() => {
    if (!bodyToSend) return {};

    const body = new FormData();

    body.append('name', bodyToSend?.institution_name ?? '');
    body.append(
      'kbli_classification',
      bodyToSend?.kbli_classification ?? 'Klasifikasi KBLI'
    );
    body.append('category', bodyToSend?.institution_type?.label ?? '');
    body.append('type_bussines', bodyToSend?.business_type ?? '');
    body.append('npwp', bodyToSend?.institution_npwp_number ?? '');
    body.append('npwp_file', bodyToSend?.institution_npwp ?? '');
    body.append('email', bodyToSend?.email ?? '');
    body.append('phone_number', bodyToSend?.phone_number ?? '');
    body.append('country', bodyToSend?.country?.label ?? '');
    body.append('province', bodyToSend?.province?.label ?? '');
    body.append('city', bodyToSend?.city?.label ?? '');
    body.append('district', bodyToSend?.district?.label ?? '');
    body.append('village', bodyToSend?.village?.label ?? '');
    body.append('zip_code', bodyToSend?.postal_code?.value ?? '');
    body.append('address', bodyToSend?.address ?? '');
    body.append('contact_person_name', bodyToSend?.contact_person?.name ?? '');
    body.append(
      'contact_person_position',
      bodyToSend?.contact_person?.position ?? ''
    );
    body.append(
      'contact_person_email',
      bodyToSend?.contact_person?.email ?? ''
    );
    body.append(
      'contact_person_phone',
      bodyToSend?.contact_person?.phone_number ?? ''
    );
    body.append(
      'contact_person_ktp',
      bodyToSend?.contact_person?.ktp_number ?? ''
    );
    body.append(
      'contact_person_ktp_file',
      bodyToSend?.contact_person?.ktp ?? ''
    );
    body.append(
      'contact_person_npwp',
      bodyToSend?.contact_person?.npwp_number ?? ''
    );
    body.append(
      'contact_person_npwp_file',
      bodyToSend?.contact_person?.npwp ?? ''
    );

    // tab 2
    body.append(
      'direct_financing_application_letter_file',
      bodyToSend?.programs ?? null
    );
    body.append('background', bodyToSend?.become_agency ?? '');
    body.append('experience', bodyToSend?.experience ?? '');

    return body;
  }, [bodyToSend]);

  const onError = useCallback((error) => {
    enqueueSnackbar(getErrorMessage(error), {
      variant: 'error',
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar('Data Berhasil Disimpan!', {
      variant: 'success',
    });
    navigate(`/lembaga-penyalur/${type}`);
  }, [navigate, type]);

  const { mutate: update } = useMutation({
    mutationFn: () => {
      return updateChannelingDirectProfile(id, params);
    },
    onError,
    onSuccess,
  });

  const { mutate: updateDraft } = useMutation({
    mutationFn: () => {
      return updateDraftChannelingDirectProfile(id, params);
    },
    onError,
    onSuccess,
  });

  const onCreate = () => {
    update();
  };

  const onCreateDraft = () => {
    updateDraft();
  };

  return (
    <div>
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onCreate}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        open={draftModal}
        onClose={setDraftModal}
        onSubmit={onCreateDraft}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan menyimpan data ini sebagai draft?
        </div>
        <div className="text-sm font-[400]">
          Setelah Anda menyimpan data sebagai draft anda masih bisa
          melanjutkannya.
        </div>
      </ConfirmationModal>

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text="Registrasi Prospecting Lembaga Penyalur"
          description="Lengkapi persyaratan di bawah ini."
        />

        <div className="border-b" />

        <AlertBlankData
          title={'Lengkapi Data'}
          body={
            'Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisisan ke tahap selanjutnya.'
          }
        />

        <div>
          <GeneralInformationForm
            onBack={onBack}
            onSubmit={onSubmit}
            onDraft={onDraft}
            draftData={profileData}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileUmum;
