import * as yup from "yup";

export const financeBankValidator = yup.object({
  quarters: yup.array(
    yup.object({
      report: yup.string().required().label("Laporan Keuangan Kuartal"),
      report_file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  reports: yup.array(
    yup.object({
      name: yup.string().required().label("Nomor KAP Mengaudit"),
      opinion: yup.string().required().label("Opini Auditor"),
      file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  clasification: yup.string().required().label("Pilih Klasifikasi"),
  oprational_file: yup.string().required().label("Upload Dokumen SOP"),
  tax_mandatory: yup.string().required().label("Wajib Pungut"),
  tax_registration_date: yup.string().required().label("Tanggal Registrasi"),
  tax_skt_number: yup
    .string()
    .required()
    .label("Nomor Surat Keterangan Terdaftar (SKT)"),
  tax_skt_date: yup
    .string()
    .required()
    .label("Tanggal Surat Keterangan Terdaftar (SKT)"),
  tax_skt_file: yup
    .string()
    .required()
    .label("Surat Surat Keterangan Terdaftar (SKT)"),
  tax_evidance_payment_number: yup
    .string()
    .required()
    .label("Nomor Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_evidance_payment_date: yup
    .string()
    .required()
    .label("anggal Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_evidance_payment_file: yup
    .string()
    .required()
    .label("Bukti Pelunasan Pajak Tahun Terakhir"),
  // tax_skb_spt_file: yup
  //   .string()
  //   .required()
  //   .label(
  //     "Upload Dokumen Surat Keterangan Bebas Pajak (SKB) & SPT Dijadikan satu"
  //   ),
});

export const financeBumdesValidator = yup.object({
  quarters: yup.array(
    yup.object({
      report: yup.string().required().label("Laporan Keuangan Kuartal"),
      report_file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  reports: yup.array(
    yup.object({
      name: yup.string().required().label("Nomor KAP Mengaudit"),
      opinion: yup.string().required().label("Opini Auditor"),
      file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  clasification: yup.string().required().label("Pilih Klasifikasi"),
  oprational_file: yup.string().required().label("Upload Dokumen SOP"),
  is_investation: yup
    .boolean()
    .transform((val) => (typeof val === "boolean" ? true : false)),
  is_have_experience: yup
    .boolean()
    .transform((val) => (typeof val === "boolean" ? true : false)),
  tax_mandatory: yup.string().required().label("Wajib Pungut"),
  tax_registration_date: yup.string().required().label("Tanggal Registrasi"),
  tax_skt_number: yup
    .string()
    .required()
    .label("Nomor Surat Keterangan Terdaftar (SKT)"),
  tax_skt_date: yup
    .string()
    .required()
    .label("Tanggal Surat Keterangan Terdaftar (SKT)"),
  tax_skt_file: yup
    .string()
    .required()
    .label("Surat Surat Keterangan Terdaftar (SKT)"),
  tax_skb_spt_file: yup
    .string()
    .required()
    .label(
      "Upload Dokumen Surat Keterangan Bebas Pajak (SKB) & SPT Dijadikan satu"
    ),
});

export const financeKoperasiValidator = yup.object({
  quarters: yup.array(
    yup.object({
      report: yup.string().required().label("Laporan Keuangan Kuartal"),
      report_file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  reports: yup.array(
    yup.object({
      name: yup.string().required().label("Nomor KAP Mengaudit"),
      opinion: yup.string().required().label("Opini Auditor"),
      file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  clasification: yup.string().required().label("Pilih Klasifikasi"),
  oprational_file: yup.string().required().label("Upload Dokumen SOP"),
  is_investation: yup
    .boolean()
    .transform((val) => (typeof val === "boolean" ? true : false)),
  is_have_experience: yup
    .boolean()
    .transform((val) => (typeof val === "boolean" ? true : false)),
  tax_mandatory: yup.string().required().label("Wajib Pungut"),
  tax_registration_date: yup.string().required().label("Tanggal Registrasi"),
  tax_skt_number: yup
    .string()
    .required()
    .label("Nomor Surat Keterangan Terdaftar (SKT)"),
  tax_skt_date: yup
    .string()
    .required()
    .label("Tanggal Surat Keterangan Terdaftar (SKT)"),
  tax_skt_file: yup
    .string()
    .required()
    .label("Surat Surat Keterangan Terdaftar (SKT)"),
  tax_evidance_payment_number: yup
    .string()
    .required()
    .label("Nomor Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_evidance_payment_date: yup
    .string()
    .required()
    .label("anggal Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_evidance_payment_file: yup
    .string()
    .required()
    .label("Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_skb_spt_file: yup
    .string()
    .required()
    .label(
      "Upload Dokumen Surat Keterangan Bebas Pajak (SKB) & SPT Dijadikan satu"
    ),
});

export const financeNonKoperasiValidator = yup.object({
  quarters: yup.array(
    yup.object({
      report: yup.string().required().label("Laporan Keuangan Kuartal"),
      report_file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  reports: yup.array(
    yup.object({
      name: yup.string().required().label("Nomor KAP Mengaudit"),
      opinion: yup.string().required().label("Opini Auditor"),
      file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  clasification: yup.string().required().label("Pilih Klasifikasi"),
  oprational_file: yup.string().required().label("Upload Dokumen SOP"),
  is_investation: yup
    .boolean()
    .transform((val) => (typeof val === "boolean" ? true : false)),
  is_have_experience: yup
    .boolean()
    .transform((val) => (typeof val === "boolean" ? true : false)),
  tax_mandatory: yup.string().required().label("Wajib Pungut"),
  tax_registration_date: yup.string().required().label("Tanggal Registrasi"),
  tax_skt_number: yup
    .string()
    .required()
    .label("Nomor Surat Keterangan Terdaftar (SKT)"),
  tax_skt_date: yup
    .string()
    .required()
    .label("Tanggal Surat Keterangan Terdaftar (SKT)"),
  tax_skt_file: yup
    .string()
    .required()
    .label("Surat Surat Keterangan Terdaftar (SKT)"),
  tax_evidance_payment_number: yup
    .string()
    .required()
    .label("Nomor Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_evidance_payment_date: yup
    .string()
    .required()
    .label("anggal Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_evidance_payment_file: yup
    .string()
    .required()
    .label("Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_skb_spt_file: yup
    .string()
    .required()
    .label(
      "Upload Dokumen Surat Keterangan Bebas Pajak (SKB) & SPT Dijadikan satu"
    ),
});

export const financePTCVFIRMAValidator = yup.object({
  quarters: yup.array(
    yup.object({
      report: yup.string().required().label("Laporan Keuangan Kuartal"),
      report_file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  reports: yup.array(
    yup.object({
      name: yup.string().required().label("Nomor KAP Mengaudit"),
      opinion: yup.string().required().label("Opini Auditor"),
      file: yup.string().required().label("Upload Laporan keuangan"),
      year: yup.string().required().label(""),
    })
  ),
  clasification: yup.string().required().label("Pilih Klasifikasi"),
  oprational_file: yup.string().required().label("Upload Dokumen SOP"),
  is_investation: yup
    .boolean()
    .transform((val) => (typeof val === "boolean" ? true : false)),
  is_have_experience: yup
    .boolean()
    .transform((val) => (typeof val === "boolean" ? true : false)),
  tax_mandatory: yup.string().required().label("Wajib Pungut"),
  tax_registration_date: yup.string().required().label("Tanggal Registrasi"),
  tax_skt_number: yup
    .string()
    .required()
    .label("Nomor Surat Keterangan Terdaftar (SKT)"),
  tax_skt_date: yup
    .string()
    .required()
    .label("Tanggal Surat Keterangan Terdaftar (SKT)"),
  tax_skt_file: yup
    .string()
    .required()
    .label("Surat Surat Keterangan Terdaftar (SKT)"),
  tax_evidance_payment_number: yup
    .string()
    .required()
    .label("Nomor Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_evidance_payment_date: yup
    .string()
    .required()
    .label("anggal Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_evidance_payment_file: yup
    .string()
    .required()
    .label("Bukti Pelunasan Pajak Tahun Terakhir"),
  tax_skb_spt_file: yup
    .string()
    .required()
    .label(
      "Upload Dokumen Surat Keterangan Bebas Pajak (SKB) & SPT Dijadikan satu"
    ),
});
