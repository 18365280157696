import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { Button, Table } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";

import { isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useMemo, useState } from "react";

import { RiFile3Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getFile } from "services/danaProgram/fileService";
import {
  getAgreementInstitutionList,
  submitAgreementInstitution,
} from "services/fdb/agreement-institution";

const statusBadge = {
  "Menunggu Persetujuan": "bg-[#FFFAEB] text-[#B54708]",
  Approved: "bg-[#ECFDF3] text-[#027A48]",
  Reject: "bg-[#FEF3F2] text-[#B42318]",
};

export default function Agreement(props) {
  const { type, group } = props;
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    paginate: true,
    page: 1,
    limit: 10,
  });
  const [agreements, setAgreements] = useState([]);

  const handleChangePage = (e) => {
    setFilters((prev) => ({ ...prev, page: e }));
  };

  const handleChangeLimit = (e) => {
    setFilters((prev) => ({ ...prev, page: 1, limit: e }));
  };

  const queryClient = useQueryClient();
  const submitApproval = useMutation({
    mutationFn: submitAgreementInstitution,
  });
  const { isLoading } = useQuery(
    ["GET_AGREEMENT_INSTITUTION", filters],
    getAgreementInstitutionList,
    {
      select: (data) => data?.data?.data,
      onSuccess: async (res) => {
        if (res.data.length === 0) return;

        for (let agreement of res?.data) {
          if (
            agreement?.doc_agreement_draft_template === "" ||
            typeof agreement?.doc_agreement_draft_template === "undefined" ||
            agreement?.doc_agreement_draft_template === null
          ) {
            continue;
          }

          const response = await getFile(
            agreement?.doc_agreement_draft_template
          );

          agreement.file = response?.data?.data;
        }

        setAgreements(res);
      },
    }
  );

  const handleReject = useCallback(
    (row) => {
      submitApproval.mutate(
        {
          id: row?.id,
          payload: {
            status: "Reject",
            note: "-",
          },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              variant: "success",
              message: "Berhasil Submit Perjanjian",
            });
            queryClient.invalidateQueries([
              "GET_AGREEMENT_INSTITUTION",
              filters,
            ]);
          },
          onError: (res) => {
            enqueueSnackbar({
              variant: "error",
              message: "Gagal Submit Perjanjian",
            });
          },
        }
      );
    },
    [submitApproval, filters, queryClient]
  );

  const handleApprove = useCallback(
    (row) => {
      submitApproval.mutate(
        {
          id: row?.id,
          payload: {
            status: "Approved",
            note: "-",
          },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              variant: "success",
              message: "Berhasil Submit Perjanjian",
            });
            queryClient.invalidateQueries([
              "GET_AGREEMENT_INSTITUTION",
              filters,
            ]);
          },
          onError: (res) => {
            enqueueSnackbar({
              variant: "error",
              message: "Gagal Submit Perjanjian",
            });
          },
        }
      );
    },
    [submitApproval, filters, queryClient]
  );

  const tableColumns = useMemo(
    () => [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px] overflow-hidden",
        render: (value, data, index) => {
          const no =
            // parseInt(paginator?.page) * parseInt(paginator?.limit) -
            // parseInt(paginator?.limit) +
            index + 1;
          return no;
        },
      },
      {
        id: "files",
        title: "Dokumen Perjanjian",
        dataIndex: "files",
        columnClassName: "w-[280px] overflow-hidden",
        render: (value, data, index) => {
          return isEmpty(data?.doc_agreement_draft_template) ? (
            "-"
          ) : (
            <div className="flex gap-3 items-center">
              <div className="p-[10px] rounded-full bg-primary-100 text-primary-600">
                <RiFile3Line />
              </div>
              <a
                href={data?.file?.url}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer"
              >
                <p className="line-clamp-2 text-sm font-medium">
                  {data?.file?.name ?? "-"}
                </p>
              </a>
            </div>
          );
        },
      },
      {
        id: "agreement_status_user",
        title: "Status",
        dataIndex: "agreement_status_user",
        columnClassName: "w-[220px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div
              className={classNames(
                "text-xs font-medium w-max py-0.5 px-2 rounded-full",
                statusBadge[value]
              )}
            >
              {value}
            </div>
          );
        },
      },
      {
        id: "#",
        title: "Aksi",
        fixed: "right",
        columnClassName: "w-[296px] overflow-hidden text-center",
        render: (value, data, index) => {
          return (
            <div className="flex gap-1 justify-center items-center">
              {data?.agreement_status_user === "Menunggu Persetujuan" ? (
                <div className="flex gap-2">
                  <Button
                    type="button"
                    label="Terima"
                    size="sm"
                    className="bg-primary-600 hover:bg-primary-700 text-white"
                    onClick={() => {
                      // handleApprove(data);
                      handleApprove(data);
                    }}
                  />
                  <Button
                    type="button"
                    label="Tolak"
                    size="sm"
                    className="bg-red-600 hover:bg-red-700 text-white"
                    onClick={() => {
                      // handleReject(data);
                      handleReject(data);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
    ],
    [handleApprove, handleReject]
  );

  const paginator = useMemo(() => {
    return {
      page: filters.page,
      limit: filters.limit,
      total: agreements?.meta?.total,
      previous_pages: agreements?.meta?.previous_page_url,
      next_pages: agreements?.meta?.next_page_url,
    };
  }, [agreements?.meta, filters]);

  return (
    <div>
      <div className="space-y-6">
        <div className="w-full p-10 bg-white rounded-xl border border-gray-200 backdrop-blur-[80px] spcae-y-6">
          <BreadCrumbs
            routes={[
              {
                label: "Daftar Penawaran",
                path: `/perjanjian/${group}/${type}`,
              },
            ]}
          />
          <div className="flex flex-row justify-between">
            <div className="flex-1">
              <h1 className="text-2xl font-bold">Perjanjian</h1>
            </div>
          </div>
        </div>

        <Table
          columns={tableColumns}
          dataSource={agreements?.data ?? []}
          isLoading={isLoading}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeLimit}
          pagination={paginator}
        />

        {/* <table
          className="w-full bg-white border border-gray-200"
          style={{ boxShadow: '0px 20px 40px 0px rgba(51, 40, 123, 0.04)' }}
        >
          <thead className="bg-primary-800 text-white">
            <tr>
              <th className="px-6 py-3">No</th>
              <th className="px-6 py-3">Dokumen Perjanjian</th>
              <th className="px-6 py-3">Status</th>
              <th className='px-6 py-3'>Aksi</th>
            </tr>
          </thead>
          <tbody>
            {
              dataDummy.map((data, index) => (
                <tr key={data.id}>
                  <td colSpan={1} className="text-center py-3 px-6">{index + 1}</td>
                  <td colSpan={1} className="flex justify-center text-center py-3 px-6 gap-2">
                    <RiFileFill size={20} />
                    {data.document_name}
                    <RiDownloadFill size={20} />
                  </td>
                  <td colSpan={1} className="py-3 px-6">{data.status}</td>
                  <td colSpan={1} className="flex justify-center items-center py-3 px-6 cursor-pointer"
                  // onClick={() => handleView(data.id)}
                  >
                    <BsEyeFill size={20} />
                    {data.status === 'Menunggu Keputusan' && (
                      <div className='flex flex-row gap-2 ml-2'>
                        <Button
                          label='Terima'
                          theme='success'
                          size='sm'
                          type='button'
                          className='text-white'
                        />
                        <Button
                          label='Tolak'
                          theme='error'
                          size='sm'
                          type='button'
                          className='text-white'
                          onClick={() => reject(data.id)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))
            }
          </tbody>
          <tfoot className="border-t ">
            <tr>
              <td colSpan={6} className="px-6 py-3">
                <FormProvider {...methods}>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4 text-sm">
                      <TablePageSizeField />
                      <div>1-5 data dari 1.523 data.</div>
                    </div>
                    <TablePagination
                      page={1}
                      totalPage={10}
                    // onChange={(page) => methods.setValue("page", page)}
                    />
                  </div>
                </FormProvider>
              </td>
            </tr>
          </tfoot>
        </table> */}
      </div>
    </div>
  );
}
