import {
  UPDATE_FORM_CFP,
  STORE_CATEGORY_CFP,
  STORE_ID_CFP,
  STORE_ID_SAFEGUARD,
  RESET_STATE_CFP,
} from "../actions";

const initialState = {
  data: {
    form: "",
    category: null,
    id: null,
    safeguard_id: null,
  },
};

export const cfpReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FORM_CFP:
      return {
        ...state,
        data: {
          ...state.data,
          form: action.payload,
        },
      };
    case STORE_CATEGORY_CFP:
      return {
        ...state,
        data: {
          ...state.data,
          category: action.payload,
        },
      };
    case STORE_ID_CFP:
      return {
        ...state,
        data: {
          ...state.data,
          id: action.payload,
        },
      };
    case STORE_ID_SAFEGUARD:
      return {
        ...state,
        data: {
          ...state.data,
          safeguard_id: action.payload,
        },
      };
    case RESET_STATE_CFP:
      return initialState;
    default:
      return state;
  }
};
