import { IDENTITY_CONFIG, METADATA_OIDC } from "contants/Auth";
import { UserManager, Log, WebStorageStateStore } from "oidc-client";
import { StorageAuthStateStore } from "../app/storageAuthStateStore";
import { Cookies } from "react-cookie";
import { logoutUser, storeUser } from "redux/authSlice";
import { setRequestHeader } from "utils/auth";
import store from "app/store";

const cookies = new Cookies();

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new StorageAuthStateStore(),
      stateStore: new WebStorageStateStore(),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    // Logger
    // Log.logger = console;
    // Log.level = Log.DEBUG;

    this.UserManager.events.addUserLoaded((user) => {
      if (user?.profile) {
        store.dispatch(storeUser(user?.profile));
      }
      setRequestHeader(user?.access_token);
      localStorage.setItem("token", user?.access_token);
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        window.location.replace(localStorage.getItem("redirectUri"));
      }
    });

    this.UserManager.events.addUserUnloaded(() => {
      console.log("user unloaded");
    });

    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.logout();
    });
  }

  signinRedirectCallback = () => {
    const auth = store.getState().auth.user;
    console.log(auth);
    if (auth === null || auth === undefined) {
      this.UserManager.signinRedirectCallback(null);
    }
  };

  signinRedirect = () => {
    // store.dispatch(userSignedOut());
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  isAuthenticated = () => {
    const user = store.getState().auth.user;
    // const idsrv = cookies.get("idsrv");
    // const idsrvSession = cookies.get("idsrv.session");
    // if (store) {
    //   return !!store?.getState()?.auth?.user;
    // }

    return !!user;
  };

  getUser = () => {
    const user = store.getState().oidc.oidc_user;

    return user;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  logout = () => {
    // this.UserManager.signoutRedirect({
    //     id_token_hint: localStorage.getItem("id_token")
    // });

    this.UserManager.clearStaleState();
    this.UserManager.removeUser();
    store.dispatch(logoutUser());

    this.UserManager.signoutRedirect();
  };

  signoutRedirectCallback = () => {
    this.UserManager.removeUser();
    this.UserManager.clearStaleState();
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/");
      store.dispatch(logoutUser());
    });
  };
}
