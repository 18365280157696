/* eslint-disable react-hooks/exhaustive-deps */
import InputNumeric from "components/organisms/inputs/InputNumeric";
import { find } from "lodash";
import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { getFile } from "services/danaProgram/fileService";
import * as Yup from "yup";
import { DOC_UPLOAD_EXT, MAX_DOC_UPLOAD } from "../../../../../app/config";
import Alert from "../../../../../components/atoms/Alert";
import FileInfoField from "../../../../../components/atoms/FileInfoField";
import CardForm from "../../../../../components/molecules/CardForm";
import InputFormUpload from "../../../../../components/molecules/InputFormUpload";
import { getErrorMessage, humanizeFileSize } from "../../../../../helpers";
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from "../../../../../locale/yup_id";
import { useMutation } from "@tanstack/react-query";
import { postFileServices } from "services/fdb/akunKelompok";
import { enqueueSnackbar } from "notistack";
import { Button, InputForm } from "components";
import {
  RiAddLine,
  RiCloseLine,
  RiDeleteBin6Line,
  RiFile2Line,
  RiFile3Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import {
  deleteCollateralsComodities,
  deleteCollateralsDeffered,
  deleteCollateralsHHBK,
} from "services/fdb/permohonan-pembiayaan";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import InputCurrencyForm from "components/V2/shared/input-currency-form";

export const FieldLabel = {
  UPLOAD: "Upload Data Jaminan",
  COLLATERAL_TYPE: "Bentuk Jaminan",
  COLLATERAL_FEE: "Nilai Jaminan",
};

export const validationSchema = Yup.object().shape({
  file: Yup.mixed()
    .test("file", validationErrorMsg.mixed.required, (value, context) => {
      let allowedTypes = DOC_UPLOAD_EXT;
      const maxFileSize = MAX_DOC_UPLOAD;

      // if has value, validate the file
      if (value) {
        const data = find(allowedTypes, (at) => value.type.includes(at));
        if (!data) {
          return context.createError({
            message: validationCustomErrorMsg.isFiletype(
              allowedTypes.join(", ")
            )({ label: FieldLabel.UPLOAD }),
          });
        }
        if (value.size > maxFileSize) {
          return context.createError({
            message: validationCustomErrorMsg.isFileSize(
              humanizeFileSize(maxFileSize)
            )({
              label: FieldLabel.UPLOAD,
            }),
          });
        }

        return true;
      }

      // otherwise, return false to render the field required
      return false;
    })
    .required()
    .label(FieldLabel.UPLOAD),
  // colleterals: Yup.array().of(Yup.object().shape({
  //   collateral_type: Yup.string().trim().required().label(FieldLabel.COLLATERAL_TYPE),
  //   collateral_fee: Yup.string().trim().required().label(FieldLabel.COLLATERAL_FEE),
  //   collateral_file: Yup.string().trim().required().label(FieldLabel.UPLOAD)

  // })),
});

function CollateralSection({
  formPrefix,
  isUploading,
  uploadProgress,
  uploadResult,
  mode,
  ...rest
}) {
  const prefix = formPrefix ? `${formPrefix}.` : "";

  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();
  const fileInput = useWatch({
    control: methods.control,
    name: `${prefix}file`,
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "colleterals",
  });

  const fileTitle = fileInput?.name;
  const fileSize = humanizeFileSize(fileInput?.size);
  const [urlFile, setUrlFile] = useState();

  const [tallyFile, setTallyFile] = useState();

  const tallyFileField = methods.watch("tally_sheet_file");
  // const colleterals = methods.watch("colleterals");

  // const collateralFileField = methods.watch("colleterals.0.collateral_file");

  const [searchParams, setSearchParams] = useSearchParams();

  const openDocs = (name) => {
    getDocsMutation.mutate({ payload: methods.getValues(name), type: "" });
  };

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "tally_sheet_file") {
        setTallyFile(res);
      }

      if (type === "tally_file") {
        setUrlFile(res.url);
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      if (type === "tally_sheet_file") {
        methods.setValue("tally_sheet_file", res.id);
        methods.setValue("tally_sheet_url", res.url);
        setTallyFile(res);
      }
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      // setDraftModal(false);
    },
  });

  const deleteColleteralsMutation = useMutation(
    ["DELETE COLLATERALS DEBITUR"],
    ({ id }) => {
      if (searchParams.get("type") === "Hasil Hutan Bukan Kayu")
        return deleteCollateralsHHBK(id);
      if (searchParams.get("type") === "Tunda Tebang")
        return deleteCollateralsDeffered(id);
      if (searchParams.get("type") === "Komoditas Non Kehutanan")
        return deleteCollateralsComodities(id);
    }
  );

  const handleUpload = useCallback(
    (field, files, index) => {
      postFile.mutate(
        { payload: files[0] },
        {
          onSuccess: (res) => {
            methods.setValue(
              `colleterals.${index}.collateral_file`,
              res.res.id
            );
            field.onChange(res.res);
          },
          onError: (err) => {},
        }
      );
    },
    [postFile]
  );

  const handleMountingColletrals = useCallback(async () => {
    const colleterals = methods.watch("colleterals");

    if (!colleterals) return;
    if (colleterals.some((el) => typeof el.id === "undefined")) return;
    for (let i = 0; i < colleterals.length; i++) {
      if (
        colleterals[i].collateral_file === "" ||
        typeof colleterals[i].collateral_file === "undefined" ||
        colleterals[i].collateral_file === null
      ) {
        continue;
      }

      const res = await getFile(colleterals[i].collateral_file);

      methods.setValue(`colleterals[${i}].selected_file`, res.data.data);
    }
  }, [methods]);

  const handleDeleteCollaterals = useCallback((collateral, index) => {
    if (collateral.id) {
      deleteColleteralsMutation.mutate(
        { id: collateral.id },
        {
          onSuccess: (res) => {
            remove(index);
          },
        }
      );
    }
    remove(index);
  }, []);

  const handleTallyFile = useCallback(() => {
    if (searchParams.get("type") === "Komoditas Non Kehutanan")
      return "d734a918-f61b-47d5-b113-24b2655e8f53";

    if (searchParams.get("type") === "Hasil Hutan Bukan Kayu")
      return "8ce6c09c-edf3-4a07-9cf9-0b02b011b707";

    return "1e469e7f-def7-41bf-8a80-6c0e756d8bd4";
  }, []);

  useEffect(() => {
    handleMountingColletrals();
  }, [handleMountingColletrals]);

  // 0bb9c922-83c6-4345-8662-ae5fd3bb485f

  useEffect(() => {
    console.log(searchParams);
    getDocsMutation.mutate({
      payload: handleTallyFile(),
      type: "tally_file",
    });
  }, [handleTallyFile]);

  useEffect(() => {
    if (tallyFileField instanceof File) {
      postFile.mutate({
        payload: tallyFileField,
        type: "tally_sheet_file",
      });
    } else if (typeof tallyFileField === "string" && tallyFileField !== "") {
      getDocsMutation.mutate({
        payload: tallyFileField,
        type: "tally_sheet_file",
      });
    }
  }, [tallyFileField]);

  return (
    <CardForm label="Data Jaminan" {...rest}>
      <div className="space-y-4">
        <Alert
          theme={"warning"}
          title={"Download & Upload Data Jaminan"}
          description={"Download data Jaminan dibawah ini & Upload."}
        />

        <div className={"space-y-1"}>
          <label className={"font-semibold"}>Data Jaminan</label>
          <FileInfoField
            title={"data_jaminan.xlsx"}
            desc={"2 MB"}
            showDownload
            showIcon
            onDownloadClick={() => (window.location.href = urlFile)}
          />
        </div>

        {tallyFileField ? (
          <>
            <label className="font-semibold text-sm my-1.5 block">
              {FieldLabel.CID_IMG}
            </label>
            <FileInfoField
              title={tallyFile ? tallyFile.name : ""}
              desc={tallyFile ? `${tallyFile.size} KB` : "Tidak ada file"}
              showIcon
              showDownload={true}
              showClose={!isView}
              onCloseClick={() => {
                methods.setValue(`tally_sheet_file`, "");
              }}
              buttonLabel="Lihat Berkas"
              onDownloadClick={() => {
                openDocs(`tally_sheet_file`);
              }}
            />
          </>
        ) : (
          <InputFormUpload
            controllerName={`tally_sheet_file`}
            label={FieldLabel.CID_IMG}
            required
            // readOnly={isView}
          />
        )}

        {isUploading?.[`${prefix}file`] && (
          <FileInfoField
            title={fileTitle}
            desc={fileSize}
            showIcon
            progress={uploadProgress?.[`${prefix}file`]}
            isUpload
            uploadResult={uploadResult?.[`${prefix}file`]}
            theme={methods.getFieldState(`${prefix}file`)?.error ? "error" : ""}
          />
        )}

        <div className={"space-y-1"}>
          <label className="font-semibold text-primary-600">
            Jaminan Tambahan
          </label>

          {fields.map((field, index) => {
            return (
              <>
                <div
                  key={field.id}
                  className={[
                    "col-span-1 md:grid md:grid-cols-2 gap-4 md:space-y-0 py-3",
                  ]}
                >
                  <div className="flex flex-col gap-2">
                    <label className="text-sm font-semibold rounded-md flex items-start gap-1">
                      {FieldLabel.COLLATERAL_TYPE}
                      <span className="font-semibold text-red-600">*</span>
                    </label>
                    {isView ? (
                      <p>
                        {methods.getValues(
                          `colleterals[${index}].collateral_type`
                        )}
                      </p>
                    ) : (
                      <InputForm
                        controllerName={`colleterals.${index}.collateral_type`}
                        className="w-full"
                      />
                      // <input
                      //   {...methods.register(
                      //     `colleterals[${index}].collateral_type`
                      //   )}
                      //   className="h-10 border border-gray-200 rounded-md px-3 outline-none"
                      // />
                    )}
                  </div>

                  <div className="flex flex-col gap-2">
                    <InputCurrencyForm
                      name={`colleterals[${index}].collateral_fee`}
                      prefix="Rp "
                      label={FieldLabel.COLLATERAL_FEE}
                      required
                      asText={isView}
                    />
                    {/* {isView ? (
                      <p>
                        {methods.getValues(
                          `colleterals[${index}].collateral_fee`
                        )}
                      </p>
                    ) : (
                      <InputForm
                        controllerName={`colleterals.${index}.collateral_fee`}
                        className="w-full"
                      />
                      // <input
                      //   {...methods.register(
                      //     `colleterals[${index}].collateral_fee`
                      //   )}
                      //   className="h-10 border border-gray-200 rounded-md px-3 outline-none"
                      //   onKeyPress={(event) => {
                      //     if (!/[0-9]/.test(event.key)) {
                      //       event.preventDefault();
                      //     }
                      //   }}
                      // />
                    )} */}
                  </div>
                </div>

                <div className="col-span-2">
                  <Controller
                    control={methods.control}
                    name={`colleterals[${index}].selected_file`}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <div className="rounded-lg border border-gray-200 shadow-sm shadow-neutral-gray4/10 bg-white">
                            {typeof field.value === "object" ? (
                              <div className="h-[120px] rounded-lg border-gray-200 shadow-sm shadow-neutral-gray4/10 bg-white">
                                <div className="flex flex-col justify-center h-full p-4 gap-4">
                                  <div className="flex justify-between">
                                    <div className="flex gap-4 items-center">
                                      <div className="p-2 rounded-full bg-primary-100 text-primary-600">
                                        <RiFile2Line />
                                      </div>
                                      <p className="text-sm font-medium">
                                        {field.value.name}
                                      </p>
                                    </div>
                                    {!isView && (
                                      <RiCloseLine
                                        className="cursor-pointer"
                                        onClick={() => field.onChange("")}
                                      />
                                    )}
                                  </div>

                                  <div>
                                    <a
                                      href={field.value.url}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="text-sm px-4 py-2 bg-primary-50 hover:bg-primary-100 text-primary-700 rounded-md"
                                    >
                                      Lihat Berkas
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <label
                                htmlFor="collateralfile"
                                className="h-[120px] w-full cursor-pointer flex flex-col gap-1 justify-center items-center"
                              >
                                <input
                                  type="file"
                                  name=""
                                  id="collateralfile"
                                  className="hidden"
                                  onChange={(e) => {
                                    handleUpload(field, e.target.files, index);
                                  }}
                                />
                                <RiUploadCloud2Line size={18} />
                                <div className="flex flex-col items-center">
                                  <p className="text-sm font-medium text-primary-700">
                                    Upload File
                                  </p>
                                  <p className="text-xs font-light">
                                    PNG, JPG, PDF, Docx. Up To 10MB
                                  </p>
                                </div>
                              </label>
                            )}
                          </div>
                          {fieldState.invalid && (
                            <p className="text-xs text-error-600 py-2">
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                </div>

                {index > 0 && (
                  <div className="flex justify-end pt-3">
                    <Button
                      label={
                        <>
                          <RiDeleteBin6Line size={20} />
                          Hapus
                        </>
                      }
                      theme="error"
                      size="sm"
                      type="button"
                      className="text-white h-10"
                      onClick={() =>
                        handleDeleteCollaterals(
                          methods.getValues(`colleterals[${index}]`),
                          index
                        )
                      }
                    />
                  </div>
                )}
              </>
            );
          })}
        </div>
        <hr className="my-5" />
        {!isView && (
          <div className="flex justify-end">
            <Button
              label={
                <>
                  <RiAddLine />
                  <p>Tambah Jaminan</p>a
                </>
              }
              className="bg-white border-2 border-gray-200 text-sm hover:bg-gray-25 hover:text-black hover:border-gray-200"
              outline
              type="button"
              onClick={() =>
                append({
                  id: undefined,
                  collateral_type: undefined,
                  collateral_fee: undefined,
                  collateral_file: undefined,
                })
              }
            />
          </div>
        )}
      </div>
    </CardForm>
  );
}

const InputFileDropzoneField = ({
  accept = {
    "image/*": [".jpg", ".png"],
    "application/*": [".pdf", ".doc", ".docx"],
  },
  maxFiles = 0,
  maxSize = 10,
  acceptPlaceholder = "PNG, JPG, PDF, Docx",
  onValueChange,
  ...rest
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    // onValueChange(acceptedFiles);

    // if (rest.multiple) {
    //   setSelectedFiles((prev) => [...prev, ...acceptedFiles]);
    // }

    // setSelectedFiles(acceptedFiles);
    handleUpload(acceptedFiles);
  }, []);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize: 1048576 * maxSize,
    ...rest,
  });

  const postFile = useMutation({
    mutationFn: async (payload) => {
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return res.data.data;
    },
  });

  const handleUpload = useCallback(
    (files) => {
      postFile.mutate(files[0], {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: "Data Berhasil Disimpan!",
            variant: "success",
          });
          if (rest.multiple) {
            setSelectedFiles((prev) => [...prev, data]);

            onValueChange(selectedFiles);

            return;
          }

          setSelectedFiles([data]);
          onValueChange(selectedFiles);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: getErrorMessage(error),
            variant: "error",
          });
        },
        onSettled: () => {},
      });
    },
    [onValueChange]
  );

  const handleDelete = (file) => {
    const filteredFiles = selectedFiles.filter((el) => el.name !== file.name);
    setSelectedFiles(filteredFiles);
  };

  return (
    <div className="flex flex-col gap-3">
      {maxFiles !== 0 && maxFiles > selectedFiles.length ? (
        <div
          {...getRootProps()}
          className={classNames(
            "h-32 w-full bg-white border border-gray-200 rounded-md flex items-center justify-center relative overflow-hidden cursor-pointer"
          )}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center gap-3">
            <div className="relative h-10 w-10 rounded-full flex items-center justify-center">
              <div className="h-full w-full bg-slate-50 rounded-full absolute animate-pulse"></div>
              <div className="bg-slate-100 rounded-full p-[8px] relative text-slate-700">
                <RiUploadCloud2Line />
              </div>
            </div>
            <div className="flex flex-col items-center text-xs">
              <p>
                <b className="text-primary-600">Klik untuk upload</b> atau seret
                dan lepas disini
              </p>
              <p>
                {acceptPlaceholder} up to {maxSize}MB
              </p>
            </div>
          </div>
          {isDragActive && (
            <div className="w-full h-full absolute top-0 bg-primary-50/50"></div>
          )}
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={classNames(
            "h-32 w-full bg-white border border-gray-200 rounded-md flex items-center justify-center relative overflow-hidden cursor-pointer"
          )}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center gap-3">
            <div className="relative h-10 w-10 rounded-full flex items-center justify-center">
              <div className="h-full w-full bg-slate-50 rounded-full absolute animate-pulse"></div>
              <div className="bg-slate-100 rounded-full p-[8px] relative text-slate-700">
                <RiUploadCloud2Line />
              </div>
            </div>
            <div className="flex flex-col items-center text-xs">
              <p>
                <b className="text-primary-600">Klik untuk upload</b> atau seret
                dan lepas disini
              </p>
              <p>
                {acceptPlaceholder} up to {maxSize}MB
              </p>
            </div>
          </div>
          {isDragActive && (
            <div className="w-full h-full absolute top-0 bg-primary-50/50"></div>
          )}
        </div>
      )}

      <div className="flex flex-col gap-2">
        {selectedFiles.map((file) => (
          <div className="w-full bg-white border border-gray-200 rounded-md flex flex-col items-start gap-4 p-4">
            <div className="flex items-start gap-2 text-xs w-full">
              <div className="flex flex-grow items-center gap-3">
                <div className="p-2 bg-primary-100 rounded-full text-primary-700 text-base">
                  <RiFile3Line />
                </div>
                <p>{file.name}</p>
              </div>
              <RiCloseLine onClick={() => handleDelete(file)} />
            </div>
            <a
              type="button"
              className="text-sm px-4 py-2 bg-primary-50 hover:bg-primary-100 text-primary-700 font-medium rounded-md"
              href={`${file.url}`}
              target="_blank"
              rel="noreferrer"
            >
              Lihat Berkas
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

CollateralSection.propTypes = {};

export default CollateralSection;
