import React from "react";
import { InputForm } from "../../../index";

const InputPhone = React.forwardRef((props, ref) => {
  const { className, ...restProps } = props;
  
  return (
    <InputForm
      className={[`py-2 px-4 mt-2 border w-full rounded-md flex-1`, className].join(" ")}
      mask="000000000000000"
      data-maska-tokens="0:[\d\-\+]:multiple"
      {...restProps}
    />
  );
});

InputPhone.propTypes = {};

export default InputPhone;