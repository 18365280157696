import React, { Fragment } from "react";

const StepProgress = ({
  stepProgressData = [],
  className = "",
  activeStep = 1,
  showIfSingleStep = false,
  ...props
}) => {
  return (
    <div className="w-full flex flex-col justify-center min-h-[100px]">
      <div className="w-full flex flex-row items-center px-10">
        {stepProgressData.length > 1 ||
        (showIfSingleStep && stepProgressData?.length) ? (
          stepProgressData.map((data, index) => {
            return (
              <Fragment key={index}>
                <div
                  className={[
                    "w-fit flex flex-row items-center justify-center",
                    stepProgressData?.length === 1 ? "mx-auto" : "",
                  ].join(" ")}
                >
                  <div
                    className={`w-[50px] h-[50px] ${
                      data.step === activeStep || data.step < activeStep
                        ? "bg-[#EEFFF4]"
                        : "bg-[#F9FAFB]"
                    }  border-4 border-[#F9FAFB] rounded-full flex flex-col items-center justify-center relative`}
                  >
                    <div
                      className={`w-[20px] h-[20px] ${
                        data.step === activeStep || data.step < activeStep
                          ? "bg-[#00BD52]"
                          : "bg-[#EAECF0]"
                      }  rounded-full`}
                    ></div>
                    <span
                      className={`${
                        stepProgressData.length < 4
                          ? "whitespace-nowrap"
                          : data.label.length > 25
                          ? "wrap -bottom-14 w-48"
                          : data.label.length > 18
                          ? "wrap -bottom-14"
                          : "whitespace-nowrap"
                      } text-center absolute -bottom-10 ${
                        data.step === activeStep || data.step < activeStep
                          ? "text-[#00BD52]"
                          : ""
                      }`}
                    >
                      {data.label}
                    </span>
                  </div>
                </div>
                {index < stepProgressData.length - 1 && (
                  <div
                    className={`w-full border-b-2 ${
                      data.step < activeStep
                        ? "border-b-[#00BD52]"
                        : "border-b-[#EAECF0]"
                    }`}
                  ></div>
                )}
              </Fragment>
            );
          })
        ) : (
          <>Tidak Ada Data Step progress</>
        )}
      </div>
    </div>
  );
};

export default StepProgress;
