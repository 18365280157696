import { useMutation, useQuery } from "@tanstack/react-query";
import { ConfirmationModal, HeaderLabel } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { enqueueSnackbar } from "notistack";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import ProgressModal from "../../../../../components/molecules/Modal/ProgressModal";
import { getErrorMessage } from "../../../../../helpers";
import FormNPSPendaftaranAnggota from "../../../components/NonPerhutananSosisal/FormPendaftaran";
import { ServiceType } from "../../../components/NonPerhutananSosisal/ServiceTypeModal";

const AkunPeroranganNPSPendaftaranForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const userId = useSelector((state) => state.profile.data.userId);

  const formRef = useRef();

  // get mode from path
  const splitPath = location?.pathname?.split("/").filter((e) => !!e);
  const mode = splitPath.at(userId ? splitPath.indexOf(userId) : -1);
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const { memberId: id } = params || {};

  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  useEffect(() => {
    const layoutContentEl = document.getElementById("_layout-content");

    layoutContentEl?.classList?.add("overflow-hidden");

    return () => {
      layoutContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  const onBack = useCallback(() => {
    const target = location?.pathname
      ?.split("/")
      ?.filter((e) => !!e)
      ?.slice(0, -1)
      .join("/");
    navigate(`/${target}`);
  }, []);

  const routes = useMemo(
    () => [
      {
        label: "Permohonan",
        path: "/perorangan/non-perhutanan-sosial/permohonan-pembiayaan",
      },
      {
        label: "Daftar Permohonan",
        path: "/perorangan/non-perhutanan-sosial/permohonan-pembiayaan",
      },
      {
        label: `Formulir Registrasi Anggota Kelompok`,
        path: location.pathname,
      },
    ],
    []
  );

  // setup form

  // api call
  const { data } = useQuery({
    queryKey: ["pengajuan-permohonan-non-perhutanan-sosial--detail", id],
    queryFn: async () => {
      // TODO: Integrate API here
      return new Array(3).fill({}).map((e, idx) => ({
        id: idx,
        name: "User" + idx,
        nik: "9843987498570394",
        birthday: new Date().toISOString(),
        email: "a@test.com",
        phone: "894379",
      }));
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!id,
  });

  const draftMutation = useMutation({
    mutationFn: (params) => {
      // TODO: integrate submit api
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // resolve(true)
          reject(true);
        }, 3000);
      });
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      onBack();
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setDraftModal(false);
    },
  });

  const submitMutation = useMutation({
    mutationFn: (params) => {
      // TODO: integrate submit api
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // resolve(true)
          reject(true);
        }, 3000);
      });
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      onBack();
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setConfirmModal(false);
    },
  });

  // methods
  const onFormSubmit = ({ lastStep, data }) => {
    if (lastStep) {
      setConfirmModal(data);
    }
  };

  const onSaveDraftClick = (e) => {
    e?.preventDefault();

    const formData = formRef.current?.getFormValues();

    setConfirmModal(false);
    setDraftModal(formData);
  };

  const doSaveDraft = () => {
    draftMutation.mutate(draftModal);
  };

  const doSubmit = () => {
    submitMutation.mutate(confirmModal);
  };

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onClose={setDraftModal}
          onSubmit={doSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={doSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={draftMutation.isLoading || submitMutation.isLoading}
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text={`Formulir Registrasi Anggota Kelompok`}
          description="Buat permohonan baru."
        />

        <div className="border-b" />

        <FormNPSPendaftaranAnggota
          ref={formRef}
          mode={mode}
          userId={userId}
          onBack={onBack}
          onDraft={onSaveDraftClick}
          onSubmit={onFormSubmit}
        />
      </div>
    </>
  );
};

export default AkunPeroranganNPSPendaftaranForm;
