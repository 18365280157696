import PT from 'pages/FDB/PT';
import SupplierApplication from 'pages/FDB/PT/SupplierApplication';
import ApplicationForm from 'pages/FDB/PT/SupplierApplication/ApplicationForm';
import ITSystemForm from 'pages/FDB/PT/SupplierApplication/ITSystemForm';
import DebiturAksesForm from 'pages/FDB/PT/SupplierApplication/DebiturAksesForm';
import NeracaKeuanganForm from 'pages/FDB/PT/SupplierApplication/NeracaKeuanganForm';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: 'private',
    path: '/pt',
    element: PT,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/pt/supplier-application',
    element: SupplierApplication,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/pt/supplier-application/aplication-form',
    element: ApplicationForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/pt/supplier-application/it-system',
    element: ITSystemForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/pt/supplier-application/debtor-access',
    element: DebiturAksesForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/pt/supplier-application/finance/:role',
    element: NeracaKeuanganForm,
    exact: true,
    sidebar: false,
  },
];
