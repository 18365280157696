import { AnimatePresence, motion } from "framer-motion";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Button, InputForm, InputFormUpload } from "../../../../../components";
import AccordionButton from "../../../../../components/atoms/AccordionButton";
import Radio from "../../../../../components/atoms/Radio";
import AtomSelect from "../../../../../components/atoms/Select";
import CardForm from "../../../../../components/molecules/CardForm";
import RichEditorForm from "../../../../../components/molecules/RichEditorForm";
import InputAge from "../../../../../components/organisms/inputs/InputAge";
import InputCurrency from "../../../../../components/organisms/inputs/InputCurrency";
import InputNumeric from "../../../../../components/organisms/inputs/InputNumeric";
import { isRTEEmpty } from "../../../../../helpers/validation";
import { validationErrorMsg } from "../../../../../locale/yup_id";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import InputCurrencyForm from "components/V2/shared/input-currency-form";
import FileInfoField from "components/atoms/FileInfoField";
import { useMutation } from "@tanstack/react-query";
import { getFile } from "services/danaProgram/fileService";
import { postFileServices } from "services/fdb/akunKelompok";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";

export const FieldLabel = {
  PROJECT_COMMODITY: "Komoditas HHBK",
  PROJECT_TYPE: "Jenis Kegiatan Pengelolaan",
  PROJECT_AGE: "Lama Usaha",
  SOURCE: "Sumber Bahan Baku Hhbk Dan Lokasi On Farm/Off Farm",
  CAPACITY: " Kapasitas Usaha/Produksi ",
  PRICE: "Harga Jual Terakhir Per KG/Liter",
  GOAL: "Tujuan Pemasaran",
  PARTNER: "Mitra Usaha (Jika Ada)",
  LOAN_GOAL: "Tujuan Pengajuan Pinjaman",
  LOAN_GOAL_ESSAY: "Jelaskan Tujuan Pengajuan Pinjaman Anda",
  EST_GROSS_EARN: "Perkiraan omzet/harga jual/pendapatan kotor",
  EST_COST: "Perkiraan Biaya Produksi / HPP / Modal Yang Telah Dikeluarkan",
  EST_NET_EARN: "Perkiraan pendapatan bersih",
  CYCLE: "Siklus Usaha Produksi",
};

export const validationSchema = Yup.object().shape({
  project_type: Yup.string().trim().required().label(FieldLabel.PROJECT_TYPE),
  commodity: Yup.string().trim().required().label(FieldLabel.PROJECT_COMMODITY),
  age_length: Yup.string().trim().required().label(FieldLabel.PROJECT_AGE),
  age_type: Yup.string().trim().required().label(FieldLabel.PROJECT_AGE),
  productivity: Yup.string().trim().required().label(FieldLabel.PRODUCTIVITY),
  price: Yup.string().trim().required().label(FieldLabel.PRICE),
  area: Yup.string().trim().required().label(FieldLabel.AREA),
  gross: Yup.string().trim().required().label(FieldLabel.GROSS),
  capital: Yup.string().trim().required().label(FieldLabel.CAPITAL),
  net: Yup.string().trim().required().label(FieldLabel.NET),
  goal: Yup.string().trim().required().label(FieldLabel.GOAL),
  // partner: Yup.string().trim().required().label(FieldLabel.PARTNER),
  type: Yup.string().trim().required().label(FieldLabel.TYPE),
  cycle_length: Yup.string().trim().required().label(FieldLabel.CYCLE),
  cycle_type: Yup.string().trim().required().label(FieldLabel.CYCLE),
  commodity_qty: Yup.string().trim().required().label(FieldLabel.COMMODITY_QTY),
  loan_goal: Yup.string().trim().required().label(FieldLabel.LOAN_GOAL),
  loan_goal_essay: Yup.string()
    .trim()
    .test("essay", validationErrorMsg.mixed.required, isRTEEmpty)
    .required()
    .label(FieldLabel.LOAN_GOAL_ESSAY),
});

function ProjectSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const [accordionState, setAccordionState] = useState({
    curr_condition: true,
    prospect: true,
  });

  const [incomeStatementFile, setIncomeStatementFile] = useState();

  const methods = useFormContext();

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  const incomeStatementFileField = methods.watch("income_statement_file");

  const openDocs = (name) => {
    getDocsMutation.mutate({ payload: methods.getValues(name), type: "" });
  };

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "income_statement_file") {
        setIncomeStatementFile(res);
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      // TODO: integrate submit api
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      if (type === "income_statement_file") {
        methods.setValue("income_statement_file", res.id);
        setIncomeStatementFile(res);
      }
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      // setDraftModal(false);
    },
  });

  useEffect(() => {
    if (incomeStatementFileField instanceof File) {
      postFile.mutate({
        payload: incomeStatementFileField,
        type: "income_statement_file",
      });
    } else if (
      typeof incomeStatementFileField === "string" &&
      incomeStatementFileField !== ""
    ) {
      getDocsMutation.mutate({
        payload: incomeStatementFileField,
        type: "income_statement_file",
      });
    }
  }, [incomeStatementFileField]);

  return (
    <CardForm label="Data Usaha yang Akan Dibiayai" {...rest}>
      <AnimatePresence>
        <AccordionButton
          key={"accordion-currcond-btn"}
          label={"Kondisi Usaha "}
          isOpen={accordionState?.curr_condition}
          onClick={toggleAccordion("curr_condition")}
        />

        {accordionState?.curr_condition && (
          <motion.div
            key={"accordion-currcond-content"}
            className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            {/* <AtomSelect
              controllerName={`hhbk_commodities`}
              className="basic-single mt-2 flex-1"
              wrapperClass={"col-span-1"}
              returnObject={false}
              label={FieldLabel.PROJECT_COMMODITY}
              placeholder={FieldLabel.PROJECT_COMMODITY}
              options={[{ value: "1", label: "Komoditas HHBK" }]}
              asText={isView}
            /> */}
            <InputForm
              label={FieldLabel.PROJECT_COMMODITY}
              placeholder={FieldLabel.PROJECT_COMMODITY}
              controllerName={`hhbk_commodities`}
              required
              asText={isView}
              className="w-full"
              wrapperClass="gap-1"
            />

            {/* <AtomSelect
              controllerName={`${prefix}manage_activity_type`}
              className="basic-single mt-2 flex-1"
              wrapperClass={"col-span-1"}
              returnObject={false}
              label={FieldLabel.PROJECT_TYPE}
              placeholder={FieldLabel.PROJECT_TYPE}
              options={[{ value: "Lorem", label: "Ipsum" }]}
              asText={isView}
            /> */}
            <InputForm
              label={FieldLabel.PROJECT_TYPE}
              placeholder={FieldLabel.PROJECT_TYPE}
              controllerName={`${prefix}manage_activity_type`}
              required
              asText={isView}
              className="w-full"
              wrapperClass="gap-1"
            />

            <div
              className={[
                "col-span-2 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
              ]}
            >
              <InputForm
                label={FieldLabel.PROJECT_AGE}
                controllerName="bussiness_duration"
                required
                asText={isView}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />

              <AtomSelect
                controllerName={`${prefix}bussiness_duration_type`}
                className="basic-single mt-2 flex-1"
                wrapperClass={"col-span-1"}
                returnObject={false}
                label={<span>&nbsp;</span>}
                options={[
                  { value: "Bulan", label: "Bulan" },
                  { value: "Tahun", label: "Tahun" },
                ]}
                asText={isView}
              />
            </div>

            <div className="col-span-2">
              {/* <AtomSelect
                controllerName={`${prefix}material`}
                className="basic-single mt-2 flex-1"
                wrapperClass={"col-span-1"}
                returnObject={false}
                label={FieldLabel.SOURCE}
                placeholder={FieldLabel.SOURCE}
                options={[{ value: "Lorem", label: "Ipsum" }]}
                asText={isView}
              /> */}
              <InputForm
                label={FieldLabel.SOURCE}
                placeholder={FieldLabel.SOURCE}
                controllerName={`${prefix}material`}
                required
                asText={isView}
                className="w-full"
                wrapperClass="gap-1"
              />
            </div>

            <InputForm
              controllerName={`${prefix}capacity_productivity`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              wrapperClass={"col-span-3 xl:col-span-1"}
              label={FieldLabel.CAPACITY}
              placeholder={FieldLabel.CAPACITY}
              required
              asText={isView}
            />

            <InputCurrencyForm
              name={`${prefix}last_price`}
              wrapperClass={"col-span-1"}
              label={FieldLabel.PRICE}
              placeholder={FieldLabel.PRICE}
              required
              prefix="Rp "
              asText={isView}
            />

            <div className="col-span-2">
              <InputForm
                controllerName={`${prefix}market_target`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.GOAL}
                placeholder={FieldLabel.GOAL}
                required
                asText={isView}
              />
            </div>

            <PartnerSection formPrefix={formPrefix} isView={isView} />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <AccordionButton
          key={"accordion-prospect-btn"}
          label={"Prospek Usaha"}
          isOpen={accordionState?.prospect}
          onClick={toggleAccordion("prospect")}
        />

        {accordionState?.prospect && (
          <motion.div
            key={"accordion-prospect-content"}
            className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div className={"col-span-2"}>
              {isView ? (
                <InputForm
                  controllerName={`${prefix}purpose_loan_application`}
                  label={FieldLabel.LOAN_GOAL}
                  inputWrapperClass={"flex gap-2"}
                  wrapperClass={"col-span-1"}
                  asText={isView}
                />
              ) : (
                <>
                  <label className={"block font-semibold text-sm mb-2"}>
                    {FieldLabel.LOAN_GOAL}
                  </label>

                  <Radio
                    controllerName={`${prefix}purpose_loan_application`}
                    inputWrapperClass={"flex gap-2"}
                    wrapperClass={"col-span-1"}
                    value={"Untuk Modal Kerja"}
                    label={<span>Untuk Modal Kerja</span>}
                  />
                  <Radio
                    controllerName={`${prefix}purpose_loan_application`}
                    inputWrapperClass={"flex gap-2"}
                    wrapperClass={"col-span-1"}
                    value={"Untuk Investasi"}
                    label={<span>Untuk Investasi</span>}
                  />
                </>
              )}
            </div>

            <div className={"col-span-2"}>
              <RichEditorForm
                key={`loan_goal_essay--${mode}`}
                name={`${prefix}purpose_loan_application_desc`}
                label={FieldLabel.LOAN_GOAL_ESSAY}
                placeholder={isView ? "" : FieldLabel.LOAN_GOAL_ESSAY}
                className={isView ? "" : "quill-4"}
                required
                readOnly={isView}
                hideToolbar={isView}
                theme={isView ? "" : "snow"}
              />
            </div>

            <div className="col-span-2 md:grid md:grid-cols-3 gap-4 space-y-4 md:space-y-0">
              <InputCurrencyForm
                name={`${prefix}revenue`}
                // wrapperClass={"col-span-3 md:col-span-1"}
                label={FieldLabel.EST_GROSS_EARN}
                required
                prefix="Rp "
                placeholder="Rp"
                asText={isView}
              />

              <InputCurrencyForm
                name={`${prefix}cost_price`}
                wrapperClass={"col-span-3 md:col-span-1"}
                label={FieldLabel.EST_COST}
                required
                prefix="Rp "
                placeholder="Rp"
                asText={isView}
              />

              <InputCurrencyForm
                name={`${prefix}net_income`}
                wrapperClass={"col-span-3 md:col-span-1"}
                label={FieldLabel.EST_NET_EARN}
                prefix="Rp "
                placeholder="Rp"
                required
                asText={isView}
              />
            </div>

            <div
              className={[
                "col-span-2 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
              ]}
            >
              <InputNumeric
                controllerName={`${prefix}bussiness_cycle`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.CYCLE}
                required
                asText={isView}
              />

              <AtomSelect
                controllerName={`${prefix}bussiness_cycle_unit`}
                className="basic-single mt-2 flex-1"
                wrapperClass={"col-span-1"}
                returnObject={false}
                label={<span>&nbsp;</span>}
                options={[
                  { value: "Minggu", label: "Minggu" },
                  { value: "Bulan", label: "Bulan" },
                  { value: "Tahun", label: "Tahun" },
                ]}
                asText={isView}
              />
            </div>
            <div className={"col-span-2"}>
              {incomeStatementFileField || isView ? (
                <>
                  <label className="font-semibold text-sm my-1.5 block">
                    {FieldLabel.UPLOAD}
                  </label>
                  <FileInfoField
                    title={incomeStatementFile ? incomeStatementFile.name : ""}
                    desc={
                      incomeStatementFile
                        ? `${incomeStatementFile.size} KB`
                        : "Tidak ada file"
                    }
                    showIcon
                    showDownload={true}
                    showClose={!isView}
                    onCloseClick={() => {
                      methods.setValue(`income_statement_file`, "");
                    }}
                    buttonLabel="Lihat Berkas"
                    onDownloadClick={() => {
                      openDocs(`income_statement_file`);
                    }}
                  />
                </>
              ) : (
                <InputFormUpload
                  controllerName={`income_statement_file`}
                  label={FieldLabel.UPLOAD}
                  required
                  readOnly={isView}
                />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </CardForm>
  );
}

const PartnerSection = forwardRef(({ formPrefix, isView, ...props }, ref) => {
  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `bussiness_partner`,
  });

  // const watchFieldArray = useWatch({
  //   control: methods.control,
  //   name: `${prefix}bussiness_partner`,
  // });

  // const [isEdit, isAdd, isView] = useMemo(
  //   () => ["edit", "add", "view"].map((e) => e === mode),
  //   [mode]
  // );

  return (
    <>
      {fields?.map((field, key) => (
        <div className="flex items-center space-x-4 col-span-2">
          <InputForm
            key={field.id}
            controllerName={`bussiness_partner.${key}.value`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            wrapperClass={"col-span-2"}
            label={FieldLabel.PARTNER}
            placeholder={FieldLabel.PARTNER}
            asText={isView}
          />
          {key > 0 && (
            <div className="space-y-2">
              <label>&nbsp;</label>
              <Button
                type="button"
                onClick={() => remove(key)}
                className="p-3 rounded-lg bg-error-600 flex-none"
                label={<BiTrash className="text-white" />}
              />
            </div>
          )}
        </div>
      ))}
      {!isView && (
        <div className="flex justify-end col-span-2 border-t pt-4">
          <Button
            type="button"
            onClick={() => append({ value: "" })}
            className="px-2 py-3 border rounded-lg bg-white w-fit h-fit"
            label={
              <div className="flex items-center gap-2 text-sm">
                <BiPlus />
                Mitra Usaha
              </div>
            }
          />
        </div>
      )}
    </>
  );
});

ProjectSection.propTypes = {};

export default ProjectSection;
