import React, { Fragment, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { BottomFormAction, InputForm, x } from 'components';
import AlertBlankData from 'components/molecules/Alert';
import CardForm from 'components/molecules/CardForm';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AtomSelect from 'components/atoms/Select';
import {
  getActivity,
  getListMasterActivity,
  getProject,
  getProjectPermohonanPembayaran,
  getProjectOutputPermohonanPembayaran,
  getProjectOutput,
} from 'services/danaProgram/disbursement';

const validationSchema = yup.object({
  project_name: yup.object().required('Proyek wajib diisi'),
  project_output: yup.object().required('Proyek output wajib diisi'),
  project_activity: yup.object().required('Proyek activity wajib diisi'),
  background: yup.string().required('Proyek background wajib diisi'),
  objective: yup.string().required('Maksud dan tujuan wajib diisi'),
  target_result: yup.string().required('Hasil Kegiatan wajib diisi'),
  total_man: yup.string().required('Jumlah laki laki wajib diisi'),
  total_girl: yup.string().required('Jumlah Perempuan wajib diisi'),
  description: yup.string().required('deskripsi wajib diisi'),
});

const TorForm = ({ onNext, onBack, type, payload }) => {
  const defaultValues = {
    project_name: payload?.tor?.project_name,
    project_output: payload?.tor?.project_output,
    project_activity: payload?.tor?.project_activity,
    background: payload?.tor?.background,
    objective: payload?.tor?.objective,
    target_result: payload?.tor?.target_result,
    total_man: payload?.tor?.total_man,
    total_girl: payload?.tor?.total_girl,
    description: payload?.tor?.description,
  };

  const [optionProjects, setOptionProjects] = useState([]);
  const [projectSelected, setProjectSelected] = useState(null);
  const [optionProjectOutputs, setOptionProjectOutputs] = useState([]);
  const [optionActivity, setOptionActivity] = useState([]);
  const { id } = useSelector((state) => state?.callForProposalData?.data);
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitForm = (values) => {
    let payload = {
      ...values,
    };

    onNext(type, payload);
  };

  const fetchProjectProposal = async () => {
    const response = await getProject({});
    const data = await response?.data?.data;

    const listProject = data.map((el, i) => ({
      value: el?._id,
      label: el?.proposal_title,
      program_name: el?.program?.name,
    }));

    setOptionProjects(() => [...listProject]);
  };

  const fetchProjectOutput = async (id) => {
    const response = await getProjectOutput(id);
    const data = await response?.data?.data;

    const listProject = data.output.map((el, i) => ({
      value: el,
      label: el,
    }));

    setOptionProjectOutputs(() => [...listProject]);
  };

  const fetchActivity = async (id) => {
    const response = await getListMasterActivity(id);
    const data = await response?.data?.data;
    const listProject = data.map((el, i) => ({
      value: el?.id,
      label: el?.kegiatanUtama,
    }));

    setOptionActivity(() => [...listProject]);
  };

  useEffect(() => {
    fetchProjectProposal();
  }, []);

  useEffect(() => {
    if (projectSelected) {
      fetchProjectOutput({ id: projectSelected?.value });
      fetchActivity({ id: projectSelected?.value });
    }
  }, [projectSelected]);

  return (
    <Fragment>
      <div className="space-y-6">
        <div className="w-full">
          <AlertBlankData
            title={`Lengkapi Data`}
            body={`Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
          />
        </div>
        <FormProvider {...methods}>
          <CardForm label="Informasi Proyek">
            <div className="flex flex-col gap-4">
              <AtomSelect
                controllerName={`project_name`}
                className={`mt-2 w-full rounded-md`}
                options={optionProjects}
                label={'Nama Proyek'}
                placeholder={'Pilih salah satu'}
                required={true}
                customOnChange={(e) => {
                  setProjectSelected(e);
                }}
              />
              <AtomSelect
                controllerName={`project_output`}
                className={`mt-2 w-full rounded-md`}
                options={optionProjectOutputs}
                label={'Output Proyek'}
                placeholder={'Pilih salah satu'}
                required={true}
              />
              <AtomSelect
                controllerName={`project_activity`}
                className={`mt-2 w-full rounded-md`}
                options={optionActivity}
                label={'Kegiatan Utama yang diusulkan (berdaarkan AWP)*'}
                placeholder={'Pilih salah satu'}
                required={true}
              />
            </div>
          </CardForm>

          <CardForm label="TOR Kegiatan">
            <div className="flex flex-col gap-4">
              <InputForm
                controllerName={`background`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={'Latar Belakang'}
                required={true}
                textArea={true}
              />
              <InputForm
                controllerName={`objective`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={'Maksud Dan Tujuan'}
                required={true}
                textArea={true}
              />
              <InputForm
                controllerName={`target_result`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={'Target Hasil Kegiatan'}
                required={true}
                textArea={true}
              />
            </div>
            <label className="text-[#1D2939] font-semibold text-sm">
              Peserta
            </label>
            <div className="flex gap-6">
              <InputForm
                controllerName={`total_man`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={'Jumlah laki laki'}
                required={true}
              />
              <InputForm
                controllerName={`total_girl`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={'Jumlah perempuan'}
                required={true}
              />
              <InputForm
                controllerName={`description`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={'Deskripsi'}
                required={true}
                textArea={true}
              />
            </div>
          </CardForm>

          <CardForm label="Dokumen Pendukung">
            <div className="space-y-4">
              <label className="text-[#1D2939] font-semibold text-sm">
                Upload dokumen pendukung
              </label>
              <InputFormDropzone
                name="additional_document"
                informationText="PDF, JPG, PNG, Docx, XLSX, PPD"
                accept={{
                  'application/pdf': [],
                  'image/jpeg': [],
                  'image/png': [],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    [],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    [],
                  'application/vnd.cups-ppd': [],
                }}
                maxFiles={30}
                maxSize={100}
                multiple
              />
            </div>
          </CardForm>
          <BottomFormAction
            lastStep={false}
            backButtonAction={() => onBack()}
            disableDrafButton={false}
            draftLabel={'Kembali Ke Permohonan'}
            drafButtonAction={() => {
              navigate(`/disbursement/application`);
            }}
            isLoading={false}
            submitActionButton={() => {
              methods.handleSubmit(submitForm)();
            }}
          />
        </FormProvider>
      </div>
    </Fragment>
  );
};

export default TorForm;
