import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { getCountryDDL } from '../../../../services/master';
import AtomSelect from '../../../atoms/Select';

const SelectCountry = React.forwardRef((props, ref) => {
  const { className, provinceId, ...restProps } = props;

  const [options, setOptions] = useState([]);

  const { data } = useQuery({
    queryKey: ['country-ddl', provinceId],
    queryFn: async () => {
      // TODO: Adjust logic once actual api exist
      const res = await getCountryDDL(provinceId);

      return res?.data?.data || [];
    },
    retry: 1,
  });

  useEffect(() => {
    setOptions(
      data?.map((entry) => {
        return {
          value: entry?.id,
          label: entry?.name,
        };
      })
    );
  }, [data]);

  return (
    <AtomSelect
      options={options}
      className={['basic-single mt-2 flex-1', className].join(' ')}
      {...restProps}
    />
  );
});

export default SelectCountry;
