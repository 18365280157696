import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUpload,
} from "components";
import AccordionButton from "components/atoms/AccordionButton";
import FileInfoField from "components/atoms/FileInfoField";
import AtomSelect from "components/atoms/Select";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { AnimatePresence, motion } from "framer-motion";
import { humanizeFileSize } from "helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getFile } from "services/danaProgram/fileService";
import { getDisbursementDebtorDetail } from "services/fdb/disbursement";
import { getBankService } from "services/masterData/bank";
import * as yup from "yup";

const formScheme = yup.object({
  bank_account: yup.object({
    bank: yup.object({
      value: yup.string().required().label("Bank"),
      label: yup.string().required().label("Bank"),
    }),
    bank_owner: yup.string().required().label("Nama Pemilik Rekening"),
    bank_number: yup.string().required().label("No Rekening Tujuan"),
  }),
  self_assesment: yup.object({
    is_move_address: yup.bool().required().label("Field"),
    is_healty: yup.bool().required().label("Field"),
    is_attend: yup.bool().required().label("Field"),
    is_can_work: yup.bool().required().label("Field"),
    identity_photo: yup
      .string()
      .required()
      .label(
        "Bukti foto dengan memegang ktp di depan rumah (menggunakan time stamp)"
      ),
    is_tree_ready: yup.bool().required().label("Field"),
    is_status_change: yup.bool().required().label("Field"),
    is_tree_good: yup.bool().required().label("Field"),
    tree_photo: yup
      .string()
      .required()
      .label(
        "Bukti foto dengan memegang ktp di depan rumah (menggunakan time stamp)"
      ),
    is_plan_work: yup.bool().required().label("Field"),
    is_business_change: yup.bool().required().label("Field"),
    is_business_active: yup.bool().required().label("Field"),
    business_photo: yup
      .string()
      .required()
      .label(
        "Bukti foto dengan memegang ktp di depan rumah (menggunakan time stamp)"
      ),
  }),
});

const FormPencairan = () => {
  const routes = useMemo(
    () => [
      {
        label: "Permohonan Pencairan",
        path: "",
      },
      {
        label: "Detail",
        path: "",
      },
    ],
    []
  );

  const navigate = useNavigate();

  const params = useParams();
  const [searchParams] = useSearchParams();

  const isView = searchParams.get("type") === "detail";

  const methods = useForm({ resolver: yupResolver(formScheme) });

  // ===========================

  const { data } = useQuery(
    ["GET_DISBURSEMENT_DEBITUR_DETAIL", params?.id],
    getDisbursementDebtorDetail,
    { select: (data) => data?.data?.data }
  );

  // ===========================

  useEffect(() => {
    if (data) {
      methods.reset({
        bank_account: {
          bank: {
            value: data?.bank?.bank_id,
            label: data?.bank?.bank_name,
          },
          bank_owner: data?.bank?.bank_owner,
          bank_number: data?.bank?.bank_number,
        },
      });
    }
  }, [data, methods]);

  return (
    <section className="space-y-5">
      <FormProvider {...methods}>
        <div className="bg-[#FFFFFF] rounded-xl p-10 w-full">
          <div className="flex flex-col w-full">
            <BreadCrumbs routes={routes} />
            <div className="mt-6 flex flex-row">
              <div className="flex flex-col">
                <span className="font-semibold text-[24px] text-[#101828]">
                  Detail
                </span>
              </div>
            </div>
          </div>
        </div>

        <PermohonanPencairan data={data?.disbursement_application} />
        <TujuanPemindahbukuan isView={isView} />
        <SelfAssesmentKinerjaDebitur isView={isView} />

        <BottomFormAction
          lastStep={true}
          backButtonProps={{
            type: "button",
          }}
          draftButtonProps={{
            type: "button",
          }}
          submitButtonProps={{
            type: "submit",
          }}
          draftLabel="Batal"
          disableDrafButton={false}
          hideDraft={isView}
          hideSubmit={isView}
          backButtonAction={() => navigate(-1)}
          // submitActionButton={handleSubmitClick}
          // disableDrafButton={false}
          // drafButtonAction={handleDraftClick}
          // disableButtonSubmit={mutatePermohonan.isLoading}
        />
      </FormProvider>
    </section>
  );
};

const PermohonanPencairan = ({ data }) => {
  const [agreementFile, setAgreementFile] = useState();

  const getAggreementFile = useCallback(async () => {
    if (!data) return;

    const response = await getFile(data?.agreement_file);

    setAgreementFile(response.data.data);
  }, [data]);

  useEffect(() => {
    getAggreementFile();
  }, [getAggreementFile]);

  return (
    <CardForm label="Permohonan Pencairan">
      <div className="grid grid-cols-4">
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-semibold">No Perjanjian</h4>
          <p>{data?.agreement_number}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-semibold">Tahap Pencairan</h4>
          <p>{data?.phase}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-semibold">Nilai Permohonan</h4>
          <p>
            {data?.application_fee
              ? `Rp ${Math.floor(data?.application_fee).toLocaleString()}`
              : "-"}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-semibold">NAP</h4>
          <p>{data?.nap}</p>
        </div>
      </div>

      {/* TODO:  */}

      <div className="flex flex-col gap-2">
        <h4 className="text-sm font-semibold">Dokument Perjanjian</h4>
        <FileInfoField
          title="Dokumen Perjanjian"
          desc={`${humanizeFileSize(agreementFile?.size)}`}
          showIcon
          showDownload={true}
          onCloseClick={() => {}}
          buttonLabel="Unduh Dokumen"
          onDownloadClick={() => {
            window.open(agreementFile?.url, "_blank");
          }}
        />
      </div>
    </CardForm>
  );
};

const TujuanPemindahbukuan = ({ isView }) => {
  const { data } = useQuery(["GET_BANK_LIST"], getBankService, {
    select: (data) => data?.data?.data,
  });

  return (
    <CardForm label="Tujuan Pemindahbukuan">
      <div className="grid grid-cols-3 gap-5">
        <AtomSelect
          controllerName="bank_account.bank"
          label="Bank"
          required
          options={data?.map((bank) => ({
            value: bank?.id,
            label: bank?.nama,
          }))}
          wrapperClass="gap-1"
          className=""
          disable={isView}
        />

        <InputForm
          controllerName="bank_account.bank_owner"
          label="Nama Pemilik Rekening"
          required
          wrapperClass="gap-1"
          className="w-full"
          disabled={isView}
        />

        <InputForm
          controllerName="bank_account.bank_number"
          label="No Rekening Tujuan"
          required
          wrapperClass="gap-1"
          className="w-full"
          disabled={isView}
        />
      </div>
    </CardForm>
  );
};

const SelfAssesmentKinerjaDebitur = ({ isView }) => {
  const [accordionState, setAccordionState] = useState({
    step1: true,
    step2: true,
    step3: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  return (
    <CardForm label="Self Assesment Kinerja Debitur">
      <div className="space-y-6">
        <AnimatePresence>
          <AccordionButton
            key="accordion-step1-btn"
            label="Keberadaan Penrima FDB"
            isOpen={accordionState?.step1}
            onClick={toggleAccordion("step1")}
          />

          {accordionState?.step1 && (
            <motion.div
              key="accordion-step1-content"
              className="space-y-6"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <InputFormRadio
                label="Saya tidak berpindah alamat"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              <InputFormRadio
                label="Saya dalam kondisi sehat"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              <InputFormRadio
                label="Saya selalu hadir dalam pertemuan kelompok"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              <InputFormRadio
                label="Saya bisa bekerja di lahan"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              {!isView && (
                <InputFormUpload
                  controllerName=""
                  label="Bukti foto dengan memegang ktp di depan rumah (menggunakan time stamp)"
                />
              )}
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          <AccordionButton
            key="accordion-step2-btn"
            label="Kondisi Tanaman"
            isOpen={accordionState?.step2}
            onClick={toggleAccordion("step2")}
          />

          {accordionState?.step2 && (
            <motion.div
              key="accordion-step2-content"
              className="space-y-6"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <InputFormRadio
                label="Pohon yang dijaminkan masih tersedia/belum ditebang"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              <InputFormRadio
                label="Status lahan tidak berubah"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              <InputFormRadio
                label="Kondisi pohon masih baik"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              {!isView && (
                <InputFormUpload
                  controllerName=""
                  label="Foto tanaman tunda tebang dengan nomor pohon terlihat (menggunakan timestamp)"
                />
              )}
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          <AccordionButton
            key="accordion-step3-btn"
            label="Kondisi Kondisi Usaha"
            isOpen={accordionState?.step3}
            onClick={toggleAccordion("step3")}
          />

          {accordionState?.step3 && (
            <motion.div
              key="accordion-step3-content"
              className="space-y-6"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <InputFormRadio
                label="Apakah realisasi penggunaan dana sesuai Rencana Kerja?"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              <InputFormRadio
                label="Apakah usaha berubah?"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              <InputFormRadio
                label="Apakah usaha masih berjalan"
                labelClass="w-full"
                controllerName=""
                usingGrid
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between gap-4 flex-wrap"
                values={[
                  {
                    label: "Iya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              {!isView && (
                <InputFormUpload
                  controllerName=""
                  label="Fotot kondisi usaha (menggunakan time stamp)"
                />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </CardForm>
  );
};

export default FormPencairan;
