import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { BiArrowBack } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';

import { Button, InputForm } from 'components';
import AccordionButton from 'components/atoms/AccordionButton';
import AtomDatePicker from 'components/atoms/Datepicker';
import CardForm from 'components/molecules/CardForm';
import FileDetail from 'components/molecules/FileDetail';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import { fileUpload } from 'services/danaProgram/fileService';
import { postDetailActivityReport } from 'services/danaProgram/callForProposalService';
import AtomSelect from 'components/atoms/Select';
import { getListMasterActivity } from 'services/danaProgram/disbursement';

const ActivityCost = ({ onBack, defaultValues, payload }) => {
  const navigate = useNavigate();
  const [optionActivity, setOptionActivity] = useState([]);
  const [accordion, setAccordion] = useState([false]);
  const [fileDoc, setFileDoc] = useState([]);

  const methods = useForm({
    defaultValues,
  });

  const { control, getValues } = methods;

  const { fields: detailActivityField, append: appendDetailActivityField } =
    useFieldArray({
      control,
      name: 'detail_activity',
    });

  const accordionToggle = (key) => {
    let temp = [...accordion];
    temp[key] = !temp[key];

    setAccordion(temp);
  };

  const onSubmit = (payload) => {
    postFile.mutate(payload?.document);
  };

  const fetchActivity = async (id) => {
    const response = await getListMasterActivity(id);
    const data = await response?.data?.data;
    const listProject = data.map((el, i) => ({
      value: el?.id,
      label: el?.kegiatanUtama,
    }));

    setOptionActivity(() => [...listProject]);
  };

  const postFile = useMutation({
    mutationKey: ['post-file-safeguard-activity-report-step-2'],
    mutationFn: async (data) => {
      let additionalDocs = data?.map(async (item, index) => {
        if (!item?.fileId) {
          const formData = new FormData();
          formData.append('file', item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          };
        } else {
          return item;
        }
      });
      const additionalResult = await Promise.all(additionalDocs);
      return additionalResult;
    },
    onSuccess: async (res) => {
      const detailActivity = getValues('detail_activity');

      let params = {
        id: payload?.id,
        document: res,
        detail_activity: detailActivity.map((activity) => ({
          ...activity,
          name: activity.name.label,
        })),
      };

      postStep2.mutate(params);
    },
  });

  const postStep2 = useMutation({
    mutationKey: ['post-step-2-activity-report'],
    mutationFn: async (payload) => {
      const res = await postDetailActivityReport(payload);
      return res.data.data;
    },
    onSuccess: (res) => {
      enqueueSnackbar('Data berhasil disimpan', { variant: 'success' });
      navigate('/report/activity');
    },
  });

  useEffect(() => {
    fetchActivity();
  }, []);

  const ActivitySection = ({ activityKey, accordion, setAccordion }) => {
    return (
      <Fragment>
        <AccordionButton
          label={`Detail Kegiatan ${activityKey + 1}`}
          isOpen={accordion?.[activityKey]}
          onClick={() => setAccordion(activityKey)}
        />
        <div
          className={`${
            accordion?.[activityKey]
              ? 'opacity-1 h-full visible'
              : 'opacity-0 h-0 invisible max-h-0'
          }`}
          style={{ transition: 'all .2s' }}
        >
          <div className="space-y-4">
            <AtomSelect
              controllerName={`detail_activity.${activityKey}.name`}
              label={'Detail Kegiatan'}
              className={`py-2 px-4 mt-2 border w-full rounded-md`}
              options={optionActivity}
            />
            <AtomDatePicker
              label={'Tanggal Pelaksanaan'}
              controllerName={`detail_activity.${activityKey}.implementation_date`}
            />
            <InputForm
              controllerName={`detail_activity.${activityKey}.realized_value`}
              label={'Nilai Realisasi Biaya (Rp)'}
              className="w-full text-sm"
            />
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <FormProvider {...methods}>
      <CardForm label="Tahapan Pelaksanaan & biaya kegiatan">
        {detailActivityField.map((field, key) => (
          <ActivitySection
            key={'activity-section-' + key}
            activityKey={key}
            accordion={accordion}
            setAccordion={accordionToggle}
          />
        ))}
        <div className="flex justify-end mt-4 w-full">
          <Button
            className="bg-white border-[#D0D5DD]"
            onClick={() => {
              appendDetailActivityField({
                name: '',
                implementation_date: '',
                realized: '',
              });
            }}
            label={
              <div className="flex items-center self-center">
                <FaPlus size={16} className="mr-2" />
                Detail Kegiatan
              </div>
            }
          />
        </div>
      </CardForm>
      <CardForm label="Dokumen Pendukung">
        <div className="space-y-2">
          <div className="text-[#1D2939] font-semibold text-sm">
            Upload Dokumen Bukti Penggunaan Biaya
          </div>
          {fileDoc.length > 0 ? (
            fileDoc.map((item, key) => (
              <FileDetail
                key={'file-doc-' + key}
                file={item}
                onRemove={() => {
                  const filtered = fileDoc.filter(
                    (file) => file.fileId !== item.fileId
                  );

                  setFileDoc(filtered);
                }}
              />
            ))
          ) : (
            <InputFormDropzone
              name="document"
              accept={{
                'image/png': [],
                'image/jpeg': [],
                'application/pdf': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  [],
              }}
              maxFiles={10}
              maxSize={10}
              multiple={true}
              informationText="PNG, JPG, PDF, Docx"
            />
          )}
        </div>
      </CardForm>

      <div className="flex justify-end space-x-4 sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
        <Button
          onClick={onBack}
          label={
            <div className="flex items-center gap-2">
              <BiArrowBack />
              <span>Kembali</span>
            </div>
          }
          size="md"
          className="bg-white"
        />
        <Button
          onClick={methods.handleSubmit(onSubmit)}
          label="Submit"
          size="md"
          className="bg-primary-600 hover:bg-primary-600/90 text-white"
        />
      </div>
    </FormProvider>
  );
};

export default ActivityCost;
