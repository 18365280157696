import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Button, HeaderNoAuth, InputForm } from "components";
import { motion } from "framer-motion";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AtomSelect from "components/atoms/Select";
import { useDispatch } from "react-redux";
import { signIn } from "features/auth/authSlice";
import { useMutation } from "@tanstack/react-query";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import {
  isMatchField,
  isPhoneValid,
  isPasswordValid,
} from "helpers/validation";
import { registerChannelingAgency } from "services/fdb/lembagaPenyalur";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";
import SelectFDBCountry from "components/organisms/inputs/SelectFDBCountry";
import InputMaskForm from "components/V2/shared/input-mask-form";

const ProspectingLembagaRegister = ({ children, sidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = useMemo(() => {
    return yup.object({
      name: yup
        .string()
        .trim()
        .required(`nama lemtara wajib diisi`)
        .label("Nama Lembaga")
        .matches(
          /^[a-zA-Z\s]*$/,
          "nama hanya boleh menggunakan huruf dan spasi"
        )
        .min(2, `nama lemtera minmal 2 karakter`)
        .max(50, `nama lemtera maksimal 50 karakter`),
      distributor_institution: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("jenis lembaga wajib diisi"),
      certificate_number: yup
        .string()
        .required(`nomor akta wajib diisi`)
        .min(2, `nomor akta minmal 2 karakter`)
        .max(50, `nomor akta maksimal 50 karakter`),
      npwp: yup
        .string()
        .required(`nomor npwp wajib diisi`)
        .label("NPWP")
        .min(20, `No. NPWP minmal 15 digit`),
      // country: yup
      //   .mixed()
      //   .nonNullable()
      //   .label("Jenis Lembaga")
      //   .required("negara wajib diisi"),
      province_id: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("provinsi wajib diisi"),
      city_id: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("kota wajib diisi"),
      village_id: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("village wajib diisi"),
      district_id: yup
        .mixed()
        .nonNullable()
        .label("Jenis Lembaga")
        .required("kecamatan district wajib diisi"),
      zip_code_id: yup.mixed().required("kode pos wajib diisi"),
      address: yup.string().required(`alamat wajib diisi`),
      // institution_type: yup.string().label('')
      contact_person_name: yup
        .string()
        .required(`nama narahubung wajib diisi`)
        .matches(
          /^[a-zA-Z\s]*$/,
          "nama hanya boleh menggunakan huruf dan spasi"
        )
        .min(2, `nama minmal 2 karakter`)
        .max(50, `nama maksimal 50 karakter`),
      contact_person_position: yup
        .string()
        .required(`jabatan wajib diisi`)
        .min(2, `jabatan minmal 2 karakter`)
        .max(50, `jabatan maksimal 50 karakter`),
      contact_person_phone: yup
        .string()
        .trim()
        .test(
          "contact_person.contact_person_phone",
          ({ label }) =>
            validationErrorMsg.mixed.default.replace("${path}", label),
          isPhoneValid
        )
        .required("No. Telepon wajib diisi")
        .label("Telepon"),
      email: yup
        .string()
        .trim()
        .email()
        .required()
        .label("Email Narahubung")
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Email Narahubung bukan email yang valid"
        ),
      password: yup
        .string()
        .test("password", validationErrorMsg.mixed.required, isPasswordValid)
        .required()
        .label("Kata Sandi"),
      confirmPassword: yup
        .string()
        .when(["password"], ([password]) => {
          if (password) {
            return yup
              .string()
              .test(
                "confpass",
                validationCustomErrorMsg.matchesField("Kata Sandi"),
                isMatchField("password")
              )
              .required()
              .label("Konfirmasi Kata Sandi");
          }

          return yup.string().required().label("Konfirmasi Kata Sandi");
        })
        .required()
        .label("Konfirmasi Kata Sandi"),
      // lembagaFocusIssue: yup
      //   .array()
      //   .required(({ label }) => `${label} wajib diisi`)
      //   .min(1, ({ label }) => `${label} wajib diisi`)
      //   .label('Fokus isu atau Intervansi Lembaga'),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const countryId = useWatch({
    control,
    name: "country",
  });
  const provinceId = useWatch({
    control,
    name: "province_id",
  });
  const cityId = useWatch({
    control,
    name: "city_id",
  });
  const districtId = useWatch({
    control: control,
    name: "district_id",
  });
  const villageId = useWatch({
    control: control,
    name: "village_id",
  });

  // List Distibution Agency 

  const distributionAgency = [
    {
      value: "1",
      label: "Bank"
    },
    {
      value: "2",
      label: "LKNB Koperasi"
    },
    {
      value: "3",
      label: "LKNB Non Koperasi",
    },
    {
      value: "4",
      label: "Badan Hukum Lainnya"
    },
  ]

  const onError = useCallback((error) => {
    enqueueSnackbar({
      message: getErrorMessage(error),
      variant: "error",
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar({
      message: "Data berhasil disimpan",
      variant: "success",
    });
    navigate("/");
  }, [navigate]);

  const mutation = useMutation(
    ['REGISTER_CHANNELING_AGENCY'],
    (params) => registerChannelingAgency(params),
    {
      onError,
      onSuccess
    }
  )

  const onSubmit = () => {

    const payload = methods.getValues()

    const mapPayload = {
      payload: {
        ...payload,
        distributor_institution: payload.distributor_institution.value,
      },
      query: 'Lembaga Penyalur'
    }

    mutation.mutate(mapPayload)
  };

  return (
    <div className="w-full bg-gray-25">
      <div className="flex flex-col h-screen">
        <HeaderNoAuth />
        <div className="md:px-28 flex flex-row flex-1 py-20">
          <div className="flex-1 ">
            <motion.main
              className="flex-1 paragraph pl-10 mb-4"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
              <FormProvider {...methods}>
                <div className="w-full space-y-4">
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col">
                      <label className="font-semibold text-[36px]">
                        Daftar Akun Lembaga
                      </label>
                      <label className="text-[#475467]">
                        Silahkan lengkapi data-data di bawah ini untuk Anda bisa
                        mendapatkan Akun.
                      </label>
                    </div>

                    <div className="flex flex-col gap-5 py-1">
                      <div className="py-1">
                        <label className="font-semibold text-[#01A24A]">
                          General Informasi
                        </label>
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"name"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nama Lembaga"}
                          placeholder={"Nama Lembaga"}
                          required={true}
                        />
                        <AtomSelect
                          controllerName={"distributor_institution"}
                          options={distributionAgency}
                          className="basic-single mt-2"
                          label="Pilih Lembaga Penyalur"
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"certificate_number"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={
                            "Nomor Akta Pendirian/SK Kemenkumham/Surat keterangan lainnya yang setara"
                          }
                          placeholder={
                            "Nomor Akta Pendirian/SK Kemenkumham/Surat keterangan lainnya yang setara"
                          }
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <InputMaskForm
                          label="NPWP"
                          name={`npwp`}
                          placeholder="NPWP"
                          mask="##.###.###.#-###.###"
                          replacement={{ "#": /\d/ }}
                          required
                        />
                      </div>
                      <div className="flex gap-4">
                        <AtomSelect
                          controllerName={"country"}
                          options={[{
                            label: 'Indonesia',
                            value: 'indonesia'
                          }]}
                          className="basic-single mt-2"
                          label="Negara"
                          placeholder={"Negara"}
                          required={true}
                          defaultValue={{
                            label: 'Indonesia',
                            value: 'indonesia'
                          }}
                        />
                        <SelectFDBProvince
                          controllerName={"province_id"}
                          className="basic-single mt-2"
                          label="Provinsi"
                          required={true}
                          returnObject={false}
                        // countryId={countryId}
                        />
                      </div>
                      <div className="flex gap-4">
                        <SelectFDBCity
                          controllerName={"city_id"}
                          className="basic-single mt-2"
                          label="Kota/Kabupaten"
                          required={true}
                          returnObject={false}
                          provinceId={provinceId}
                        />
                        <SelectFDBDistrict
                          controllerName={"district_id"}
                          className="basic-single mt-2"
                          label="Kecamatan"
                          required={true}
                          returnObject={false}
                          cityId={cityId}
                        />
                      </div>
                      <div className="flex gap-4">
                        <SelectFDBVillage
                          controllerName={"village_id"}
                          className="basic-single mt-2"
                          label="Kelurahan"
                          required={true}
                          returnObject={false}
                          districtId={districtId}
                        />
                        <SelectFDBZipCode
                          controllerName={"zip_code_id"}
                          className="basic-single mt-2"
                          label="Kode Pos"
                          required={true}
                          returnObject={false}
                          villageId={villageId}
                        />
                      </div>
                      <div className="flex">
                        <InputForm
                          controllerName={`address`}
                          className={`px-4 py-2 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Alamat Lengkap"}
                          placeholder={"alamat"}
                          textArea={true}
                          required
                        />
                      </div>
                    </div>

                    <div className="border-b" />

                    <div className="flex flex-col gap-5 py-1">
                      <div className="py-1">
                        <label className="font-semibold text-[#01A24A]">
                          Informasi Narahubung
                        </label>
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"contact_person_name"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nama Narahubung"}
                          placeholder={"Nama Narahubung"}
                          required={true}
                        />
                        <InputForm
                          controllerName={"contact_person_position"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Jabatan"}
                          placeholder={"Jabatan"}
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"contact_person_phone"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Telepon"}
                          placeholder={"Telepon"}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="border-b" />

                    <div className="flex flex-col gap-5 py-1">
                      <div className="py-1">
                        <label className="font-semibold text-[#01A24A]">
                          Informasi Akun Lembaga Penyalur
                        </label>
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"email"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Email"}
                          placeholder={"Email"}
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"password"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Kata Sandi"}
                          placeholder={"Kata Sandi"}
                          required={true}
                          type="password"
                        />
                        <InputForm
                          controllerName={"confirmPassword"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Konfirmasi Kata Sandi"}
                          placeholder={"Konfirmasi Kata Sandi"}
                          required={true}
                          type="password"
                        />
                      </div>
                    </div>

                    <div className="flex justify-end gap-4 cursor-pointer">
                      <button
                        className="border rounded-lg p-4 cursor-pointer"
                        onClick={() => navigate("/")}
                      >
                        Batal
                      </button>
                      <Button
                        className="bg-[#00BD52] text-white  rounded-lg p-4"
                        label={
                          <div className="flex items-center gap-2">
                            Daftar Akun
                          </div>
                        }
                        disabled={false}
                        onClick={() => methods.handleSubmit(onSubmit)()}
                      />
                    </div>
                  </div>
                </div>
              </FormProvider>
            </motion.main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProspectingLembagaRegister;
