import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RiAddLine, RiCheckLine } from "react-icons/ri";
import InputForm from "../InputForm";

const InputFormMultiple = ({
  controllerName,
  className,
  label,
  required = false,
  textArea = false,
  usingGrid = false,
  gridClassName = "",
  values = [],
  directionColumn = false,
  hideInputValue,
  showErrorLabel = true,
  ...props
}) => {
  const { control } = useFormContext();
  const [showInputForm, setShowInputForm] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    props.onChange && props.onChange(inputValue, event.target.value);
  };

  const toggleInputForm = () => {
    setShowInputForm(!showInputForm);
  };

  const handleAddOption = () => {
    if (inputValue.trim() !== "") {
      props.onChange && props.onChange(inputValue, inputValue);
      setInputValue("");
      setShowInputForm(false);
    }
  };

  const onClickContainer = (value, data) => (onChange) => {
    const selectedValues = value.slice();
    const index = selectedValues.indexOf(data.value);
    if (index > -1) {
      selectedValues.splice(index, 1);
    } else {
      selectedValues.push(data.value);
    }
    onChange(selectedValues);
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      defaultValue={[]}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <div className={`form-control w-full ${className}`}>
          <label className="label font-semibold font-[14px]">
            <span className={`label-text`}>{label}</span>
            {required && <span className="text-[#F04438]">*</span>}
          </label>
          {usingGrid ? (
            <div className={gridClassName || `grid grid-cols-3 gap-4 mt-2`}>
              {values.map((data, index) => (
                <div
                  key={data.value}
                  className={`${
                    directionColumn ? "mt-2" : ""
                  } flex items-center flex-1 ${
                    invalid ? "border-[#F04438]" : ""
                  } ${
                    value.includes(data.value)
                      ? "border-[#01A24A] bg-[#EEFFF4]"
                      : "bg-white"
                  }  border py-2 px-4 rounded-lg gap-4 hover:cursor-pointer`}
                  onClick={(e) => onClickContainer(value, data)(onChange)}
                >
                  <input
                    type="checkbox"
                    name={controllerName}
                    {...props}
                    onChange={(e) => {
                      const selectedValues = value.slice();
                      if (e.target.checked) {
                        selectedValues.push(data.value);
                      } else {
                        const index = selectedValues.indexOf(data.value);
                        if (index > -1) {
                          selectedValues.splice(index, 1);
                        }
                      }
                      onChange(selectedValues);
                    }}
                    checked={value.includes(data.value)}
                  />
                  {data.label}
                </div>
              ))}
              {/* Render the input form when "Rencana Baru" button is clicked */}
              {showInputForm && (
                <InputForm
                  controllerName={`${controllerName}-text`}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              )}
              {!hideInputValue && (
                <div className="mt-2 flex items-center flex-1 bg-white border border-[#EEFFF4] py-2 px-4 rounded-lg gap-4">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="w-full border-none outline-none focus:ring-0"
                  />
                  <button
                    type="button"
                    onClick={toggleInputForm}
                    className="text-[#01A24A] hover:text-green-500"
                  >
                    <RiAddLine />
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`${
                directionColumn ? "flex-column" : "flex"
              } flex-wrap gap-4 mt-2`}
            >
              {values.map((data, index) => (
                <div
                  key={data.value}
                  className={`${
                    directionColumn ? "mt-2" : ""
                  } flex items-center flex-1 ${
                    value.includes(data.value)
                      ? "border-[#01A24A] bg-[#EEFFF4]"
                      : "bg-white border border-grey"
                  } py-2 px-4 rounded-lg gap-4`}
                  style={{
                    position: "relative",
                  }}
                >
                  <input
                    type="checkbox"
                    name={controllerName}
                    {...props}
                    onChange={(e) => {
                      const selectedValues = Array.isArray(value)
                        ? [...value]
                        : [];
                      if (e.target.checked) {
                        selectedValues.push(data.value);
                      } else {
                        const index = selectedValues.indexOf(data.value);
                        if (index > -1) {
                          selectedValues.splice(index, 1);
                        }
                      }
                      onChange(selectedValues);
                    }}
                    checked={value.includes(data.value)}
                    style={{
                      position: "absolute",
                      opacity: 0,
                    }}
                  />
                  <div
                    className="radio-custom"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "12px",
                      height: "12px",
                      borderRadius: "4px",
                      backgroundColor: value.includes(data.value)
                        ? "#01A24A"
                        : "#FFFFFF",
                      border: value.includes(data.value)
                        ? "1px solid #01A24A"
                        : "1px solid grey",
                    }}
                  >
                    {value.includes(data.value) && (
                      <RiCheckLine size={10} color="#FFFFFF" />
                    )}
                  </div>
                  {data.label}
                </div>
              ))}
            </div>
          )}
          {invalid && showErrorLabel && (
            <label className="label">
              <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                {error?.message}
              </div>
            </label>
          )}
        </div>
      )}
    />
  );
};

export default InputFormMultiple;
