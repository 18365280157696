import React, {
  Fragment,
  useRef,
  useId,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  FormProvider,
  useForm,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useNavigate } from 'react-router';
import { RiAddLine } from 'react-icons/ri';
import _ from 'lodash';
import moment from 'moment';

import { Spinner, BottomFormAction, InputForm, Button } from 'components';
import CardForm from 'components/molecules/CardForm';
import AtomDatePicker from 'components/atoms/Datepicker';
import AtomSelect from 'components/atoms/Select';
import {
  getDetailSafeguards,
  postSafeguardsStep6CatBC,
} from 'services/danaProgram/callForProposalService';
import { PERIOD_OPTIONS } from 'contants/Safeguards';

const CommitmentArray = forwardRef(({ onNext, type, pIndex }, ref) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `isp.${pIndex}.commitment`,
  });

  const onSubmit = (data) => {
    const payload = {
      id: data.isp[0].id,
      is_agree: data.isp[0].is_agree,
      commitment_conclusion: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      commitment_document: [
        {
          fileId: '7c283d3c-e2b4-4a62-b94f-70851d446c72',
          fileName: '70270c24-d194-46e1-92b3-69dde0cce27a.jpg',
          fileSize: 76606,
          mimeType: 'image/jpeg',
          path: 'uploads/2024/7/70270c24-d194-46e1-92b3-69dde0cce27a.jpg',
        },
      ],
      commitment: data.isp.map((isp) => ({
        desc: isp.desc,
        principle_id: isp.principle_id,
        isp_id: isp.isp_id,
        principle: isp.principle,
        commitment: isp.commitment.map((commitment) => ({
          commitment: commitment.commitment,
          activities: commitment.activities.map((activity) => ({
            activity: activity.activity,
            implementation: [
              {
                date: moment(activity.date[0]).format('YYYY-MM-DD'),
                pic: activity.pic,
                report_type: activity.report_type,
                period: activity.period,
              },
            ],
          })),
        })),
      })),
    };

    submitStep6.mutate(payload);
  };

  const submitStep6 = useMutation({
    mutationKey: ['post-step-6-category-bc'],
    mutationFn: async (payload) => {
      const res = await postSafeguardsStep6CatBC(payload);
      return res;
    },
    onSuccess: (res) => {
      onNext(type);
    },
  });

  useImperativeHandle(ref, () => {
    return {
      onSubmit,
    };
  });

  return (
    <div>
      {fields.map((commitment, index) => (
        <CardForm
          key={commitment.id}
          label={`Komitmen ${index + 1}`}
          childerForm
          sideLabel=""
          buttonCollapse
        >
          <div>
            <div className="text-[#1D2939] font-semibold text-sm flex flex-row justify-between items-center">
              Komitmen
              {fields.length > 0 && index > 0 && (
                <Button
                  onClick={() => {
                    remove(index);
                  }}
                  className="rounded-lg border border-gray-300 shadow px-2 bg-error-600 hover:!text-black"
                  label={
                    <div className="flex gap-2 items-center text-white text-xs">
                      Hapus Komitmen
                    </div>
                  }
                />
              )}
            </div>
            <InputForm
              controllerName={`isp.${pIndex}.commitment.${index}.commitment`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              textArea={true}
              rows={5}
            />
          </div>

          <hr />

          <ActivityArray
            ispIndex={pIndex}
            commitmentIndex={index}
            control={control}
          />
        </CardForm>
      ))}

      <div className="text-center">
        <Button
          onClick={() => {
            append({
              commitment: '',
              activities: [
                {
                  activity: '',
                  date: '',
                  report_type: '',
                  period: '',
                  pic: '',
                },
              ],
            });
          }}
          className="rounded-lg border border-green-500 bg-white shadow py-2.5 px-4"
          label={
            <div className="flex gap-2 items-center text-gray-700 text-sm">
              <RiAddLine size={20} />
              Tambah komitmen
            </div>
          }
        />
      </div>
    </div>
  );
});

const ActivityArray = ({ ispIndex, commitmentIndex, control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `isp.${ispIndex}.commitment.${commitmentIndex}.activities`,
  });

  return (
    <>
      {fields.map((activity, index) => (
        <div
          key={activity.id}
          className="flex flex-col gap-2 border rounded-lg p-4"
        >
          <div>
            <div className="text-[#1D2939] font-semibold text-sm flex flex-row justify-between items-center">
              Kegiatan
              {fields.length > 0 && index > 0 && (
                <Button
                  onClick={() => {
                    remove(index);
                  }}
                  className="rounded-lg border border-gray-300 shadow px-2 bg-error-600 hover:!text-black"
                  label={
                    <div className="flex gap-2 items-center text-white text-xs">
                      Hapus Kegiatan
                    </div>
                  }
                />
              )}
            </div>
            <InputForm
              controllerName={`isp.${ispIndex}.commitment.${commitmentIndex}.activities.${index}.activity`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              textArea={true}
              rows={5}
            />
          </div>

          <div className="flex flex-row gap-4 items-center">
            <div className="flex-1">
              <AtomDatePicker
                controllerName={`isp.${ispIndex}.commitment.${commitmentIndex}.activities.${index}.date`}
                label="Tata Waktu Pelaksanaan"
              />
            </div>

            <div className="flex-1 space-y-2">
              <div className="flex gap-1 font-semibold text-[14px]">
                <span className={`label-text`}>Jenis Dokumen Laporan</span>
              </div>
              <InputForm
                controllerName={`isp.${ispIndex}.commitment.${commitmentIndex}.activities.${index}.report_type`}
                className="w-full"
              />
            </div>
          </div>

          <div className="flex flex-row gap-4 items-center">
            <div className="flex-1">
              <AtomSelect
                controllerName={`isp.${ispIndex}.commitment.${commitmentIndex}.activities.${index}.period`}
                options={PERIOD_OPTIONS}
                className="basic-single mt-2"
                label="Period"
                returnObject={false}
              />
            </div>

            <div className="flex-1 space-y-2">
              <div className="flex gap-1 font-semibold text-[14px]">
                <span className={`label-text`}>
                  Pihak/Entitas yang Bertanggungjawab
                </span>
              </div>
              <InputForm
                controllerName={`isp.${ispIndex}.commitment.${commitmentIndex}.activities.${index}.pic`}
                className="w-full"
              />
            </div>
          </div>
        </div>
      ))}

      <div className="text-right">
        <Button
          onClick={() => {
            append({
              activity: '',
              date: '',
              report_type: '',
              period: '',
              pic: '',
            });
          }}
          className="rounded-lg border border-green-500 bg-white shadow py-2.5 px-4"
          label={
            <div className="flex gap-2 items-center text-gray-700 text-sm">
              <RiAddLine size={20} />
              Tambah Kegiatan
            </div>
          }
        />

        <hr className="mt-4" />
      </div>
    </>
  );
};

const InputCheckBox = ({
  item,
  disabled,
  value = false,
  title,
  onChangeCheckBox,
}) => {
  const [checked, setChecked] = useState(value);
  const checkboxId = useId();

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <label
      for={checkboxId}
      className="flex gap-3 p-6 border border-primary-700 bg-primary-maincolor-50 rounded-lg cursor-pointer items-start"
    >
      <div className="flex gap-3 items-center mt-1">
        <input
          id={checkboxId}
          onChange={(e) => {
            if (onChangeCheckBox) {
              setChecked(e.target.checked);
              onChangeCheckBox(e);
            } else onChange(e);
          }}
          value={item?.value}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          className="relative cursor-pointer peer shrink-0 appearance-none bg-primary-maincolor-50 text-primary-maincolor-600 border border-primary-maincolor-600 rounded-md w-5 h-5 focus:ring-transparent focus:ring-0 checked:border-2"
        />
        <label
          for={checkboxId}
          className="absolute cursor-pointer ml-0.5 w-4 h-4 focus:outline-none hidden peer-checked:block text-primary-maincolor-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </label>
      </div>
      <div className="text-gray-700 font-medium items-center">
        {item?.value ?? title}
      </div>
    </label>
  );
};

const Commitment = ({ onNext, onBack, type }) => {
  const navigate = useNavigate();
  const { id } = useSelector((state) => state?.callForProposalData?.data);
  const categoryRef = useRef(null);

  const methods = useForm({
    defaultValues: {
      id: '',
      isp: [
        {
          id: '',
          commitment: [],
          commitment_conclusion: [],
          commitment_document: [],
          is_agree: false,
        },
      ],
    },
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'isp',
  });

  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    queryKey: ['get-detail-safeguard'],
    queryFn: async () => {
      const res = await getDetailSafeguards({ proposal_id: id });
      return res?.data?.data;
    },
    enabled: !!id,
  });

  useEffect(() => {
    if (!_.isEmpty(dataDetail)) {
      const { screening, _id, commitment } = dataDetail;

      let populatedPrinciple;
      let tempCommitment;

      populatedPrinciple = screening
        ?.filter((el) => el?.answer === 'yes')
        .map((parent) => {
          return parent?.principle?.map((child) => ({
            ...{
              id: _id,
              desc: child?.deskripsi,
              principle_id: child?.id,
              isp_id: child?.idIsp,
              principle: child?.nama,
              is_agree: false,
              commitment: [
                {
                  commitment: '',
                  activities: [
                    {
                      activity: '',
                      date: '',
                      report_type: '',
                      period: '',
                      pic: '',
                    },
                  ],
                },
              ],
            },
          }));
        })
        .flatMap((e) => [...e]);

      if (commitment) {
        tempCommitment = commitment.map((commit) => ({
          id: _id,
          desc: commit.desc,
          principle_id: commit.principle_id,
          isp_id: commit.isp_id,
          principle: commit.principle,
          is_agree: false,
          commitment: commit.commitment.map((data) => ({
            commitment: data.commitment,
            activities: data.activities.map((activity) => ({
              activity: activity.activity,
              date: new Date(activity.implementation[0].date),
              report_type: activity.implementation[0].report_type,
              period: activity.implementation[0].period,
              pic: activity.implementation[0].pic,
            })),
          })),
        }));
      }

      methods.reset({
        isp: commitment ? tempCommitment : populatedPrinciple,
      });
    }
  }, [dataDetail, methods]);

  return (
    <div className="gap-4 flex flex-col">
      {isFetchingDetail ? (
        <div className="w-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <CardForm
            label="Komitmen Perlindungan Lingkungan dan Sosial"
            sideLabel=""
          >
            <FormProvider {...methods}>
              {fields &&
                fields.map((isp, index) => (
                  <CardForm
                    label={`${fields[index].principle} - ${fields[index].desc}`}
                    childerForm
                    sideLabel=""
                    buttonCollapse
                  >
                    <CommitmentArray
                      ref={categoryRef}
                      type={type}
                      onNext={onNext}
                      pIndex={index}
                    />
                  </CardForm>
                ))}

              <InputCheckBox
                title="Dengan ini kami menyatakan bahwa kami siap melaksanakan dan memenuhi semua komitmen perlindungan lingkungan dan sosial sesuai dengan prinsip-prinsip perlindungan lingkungan sosial yang terpicu atas kegiatan yang akan kami laksanakan."
                name={'is_agree'}
                onChangeCheckBox={(e) =>
                  methods.setValue(`isp.0.is_agree`, e?.target?.checked)
                }
              />

              <BottomFormAction
                lastStep={false}
                backButtonAction={() => onBack()}
                disableDrafButton={false}
                hideDraft={false}
                draftLabel={'Kembali Ke Proposal'}
                drafButtonAction={() => {
                  navigate(`/proposal/call-for-proposal/${id}`);
                }}
                submitActionButton={() => {
                  methods.handleSubmit(categoryRef?.current?.onSubmit)();
                }}
              />
            </FormProvider>
          </CardForm>
        </Fragment>
      )}
    </div>
  );
};

export default Commitment;
