import * as Yup from "yup";
import { FieldLabel } from "./infoForm";
import { validationErrorMsg } from "locale/yup_id";
import { isCitizenIdValid } from "helpers/validation";

export const validationSchemaFormRegisterPS = Yup.object().shape({
  group_name: Yup.string().trim().required().label(FieldLabel.GROUP_NAME),
  kups_name: Yup.string().trim().required().label(FieldLabel.KUPS_NAME),
  sk_number: Yup.string().trim().required().label(FieldLabel.SK_NUMBER),
  kups_number: Yup.string().trim().required().label(FieldLabel.KUPS_NUMBER),
  founding_date: Yup.string().required().label(FieldLabel.FOUNDING_DATE),
  social_area_function_id: Yup.string()
    .required()
    .label(FieldLabel.SOCIAL_AREA_FUNCTION_ID),
  province_id: Yup.mixed().required().label(FieldLabel.PROVINCE),
  city_id: Yup.mixed().required().label(FieldLabel.CITY),
  district_id: Yup.mixed().required().label(FieldLabel.DISTRICT),
  village_id: Yup.mixed().required().label(FieldLabel.VILLAGE),
  zip_code_id: Yup.mixed().required().label(FieldLabel.ZIPCODE),
  address: Yup.string().trim().required().label(FieldLabel.ADDRESS),
  leader_name: Yup.string().trim().required().label(FieldLabel.LEADER_NAME),
  leader_phone: Yup.string().trim().required().label(FieldLabel.LEADER_PHONE),
  leader_identity: Yup.string()
    .trim()
    .test(
      "leader_identity",
      ({ label }) => validationErrorMsg.mixed.default.replace("${path}", label),
      isCitizenIdValid
    )
    .required()
    .label(FieldLabel.LEADER_CID),
  secretary_name: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.SECRETARY_NAME),
  secretary_phone: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.SECRETARY_PHONE),
  treasurer_name: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.TREASURER_NAME),
  treasurer_phone: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.TREASURER_PHONE),
  associate_name: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.COMPANION_NAME),
  associate_phone: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.COMPANION_PHONE),
  end_date: Yup.string().trim().required().label(FieldLabel.END_DATE),
  associate_affiliation: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.COMPANION_AFFILIATE),
  membership_count: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.MEMBERSHIP_COUNT),
  andil_garapan: Yup.string().trim().required().label(FieldLabel.ANDIL_GARAPAN),
  total_area: Yup.string().trim().required().label(FieldLabel.TOTAL_AREA),
  all_membership_count: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.MEMBERSHIP_COUNT_ALL),
  partners: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().label("Mitra Usaha Kelompok"),
    })
  ),
  activities: Yup.array().of(
    Yup.object().shape({
      category_id: Yup.string().required().label("Category"),
      description: Yup.string().required().label("Deskripsi"),
    })
  ),
  finance_businesses: Yup.array().of(
    Yup.object().shape({
      category_id: Yup.string().required().label("Category"),
      description: Yup.string().required().label("Deskripsi"),
    })
  ),
});
