import _, { toString } from "lodash";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { InputForm } from "../../../../../components";
import GeotagLocationButton from "../../../../../components/atoms/Button/GeotagLocationButton";
import AtomDatePicker from "../../../../../components/atoms/Datepicker";
import CardForm from "../../../../../components/molecules/CardForm";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";

export const FieldLabel = {
  PROVINCE: "Provinsi",
  CITY: "Kota/Kabupaten",
  DISTRICT: "Kecamatan",
  SUBDISTRICT: "Kelurahan/Desa",
  RT: "RT",
  RW: "RW",
  ADDRESS: "Alamat",
  GEOTAG: "Geotag Location",
  LAT: "Latitude",
  LNG: "Longitude",
  LIVE_SINCE: "Berdomisili Di Alamat Diatas Sejak Tahun",
  ZIPCODE: "Kode Pos",
};

export const validationSchema = Yup.object().shape({
  cid: Yup.object().shape({
    province_id: Yup.mixed().required().label(FieldLabel.PROVINCE),
    city_id: Yup.mixed().required().label(FieldLabel.CITY),
    district_id: Yup.mixed().required().label(FieldLabel.DISTRICT),
    village_id: Yup.mixed().required().label(FieldLabel.SUBDISTRICT),
    zip_code_id: Yup.mixed().required().label(FieldLabel.ZIPCODE),
    rt: Yup.string().required().label(FieldLabel.RT),
    rw: Yup.string().required().label(FieldLabel.RW),
    address: Yup.string().trim().required().label(FieldLabel.ADDRESS),
  }),
  living: Yup.object().shape({
    same_address: Yup.string().notRequired(),
    domicile_province_id: Yup.mixed().required().label(FieldLabel.PROVINCE),
    domicile_city_id: Yup.mixed().required().label(FieldLabel.CITY),
    domicile_district_id: Yup.mixed().required().label(FieldLabel.DISTRICT),
    domicile_village_id: Yup.mixed().required().label(FieldLabel.SUBDISTRICT),
    domicile_zip_code_id: Yup.mixed().required().label(FieldLabel.ZIPCODE),
    domicile_rt: Yup.string().required().label(FieldLabel.RT),
    domicile_rw: Yup.string().required().label(FieldLabel.RW),
    domicile_address: Yup.string().trim().required().label(FieldLabel.ADDRESS),
    latitude: Yup.string().trim().required().label(FieldLabel.LAT),
    longitude: Yup.string().trim().required().label(FieldLabel.LNG),
    domicile_since_year: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.LIVE_SINCE),
  }),
});

function AddressSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();

  const cidProvinceId = useWatch({
    control: methods.control,
    name: `province_id`,
  });
  const cidCityId = useWatch({
    control: methods.control,
    name: `city_id`,
  });
  const cidDistrictId = useWatch({
    control: methods.control,
    name: `district_id`,
  });
  const cidVillageId = useWatch({
    control: methods.control,
    name: `village_id`,
  });
  const livingProvinceId = useWatch({
    control: methods.control,
    name: `domicile_province_id`,
  });
  const livingCityId = useWatch({
    control: methods.control,
    name: `domicile_city_id`,
  });
  const livingDistrictId = useWatch({
    control: methods.control,
    name: `domicile_district_id`,
  });
  const livingVillageId = useWatch({
    control: methods.control,
    name: `domicile_village_id`,
  });

  const onGeotagClick = () => (value) => {
    methods.setValue(`latitude`, toString(value?.latitude));
    methods.setValue(`longitude`, toString(value?.longitude));
  };

  const onSameAddressChange = (e) => {
    const isChecked = e?.target?.checked;
    const { setValue, getValues } = methods || {};

    if (isChecked) {
      const values = getValues();

      console.log(values, "VALUES");

      setValue(`domicile_province_id`, values?.province_id);
      setValue(`domicile_city_id`, values?.city_id);
      setValue(`domicile_district_id`, values?.district_id);
      setValue(`domicile_village_id`, values?.village_id);
      setValue(`domicile_zip_code_id`, values?.zip_code_id);
      setValue(`domicile_address`, values?.address);
      setValue(`domicile_rt`, values?.rt);
      setValue(`domicile_rw`, values?.rw);
    }
  };

  return (
    <CardForm label="Alamat" {...rest}>
      <div className={"font-lg font-semibold text-[#01A24A]"}>Alamat KTP</div>

      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        <SelectFDBProvince
          controllerName={`province_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.PROVINCE}
          placeholder={FieldLabel.PROVINCE}
          required={true}
          returnObject={false}
          asText={isView}
        />
        <SelectFDBCity
          controllerName={`city_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.CITY}
          placeholder={FieldLabel.CITY}
          required={true}
          provinceId={cidProvinceId}
          returnObject={false}
          asText={isView}
        />
        <SelectFDBDistrict
          controllerName={`district_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.DISTRICT}
          placeholder={FieldLabel.DISTRICT}
          required={true}
          cityId={cidCityId}
          returnObject={false}
          asText={isView}
        />
        <SelectFDBVillage
          controllerName={`village_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.SUBDISTRICT}
          placeholder={FieldLabel.SUBDISTRICT}
          required={true}
          districtId={cidDistrictId}
          returnObject={false}
          asText={isView}
        />
        <SelectFDBZipCode
          controllerName={`zip_code_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.ZIPCODE}
          placeholder={FieldLabel.ZIPCODE}
          required={true}
          villageId={cidVillageId}
          returnObject={false}
          asText={isView}
        />
        <InputForm
          controllerName={`rt`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RT}
          placeholder={FieldLabel.RT}
          required
          asText={isView}
        />
        <InputForm
          controllerName={`rw`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RW}
          placeholder={FieldLabel.RW}
          required
          asText={isView}
        />

        <InputForm
          controllerName={`address`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.ADDRESS}
          placeholder={FieldLabel.ADDRESS}
          rows={4}
          textArea
          required
          asText={isView}
        />
      </div>

      <div className="border-b" />

      <div className={"flex flex-wrap items-center gap-4"}>
        <div className={"font-lg font-semibold text-[#01A24A]"}>
          Alamat Domisili
        </div>

        {!isView ? (
          <label
            className={"bg-[#EEFFF4] p-3 flex gap-3 items-start rounded-lg"}
          >
            <input
              className={"h-[1em] w-[1rem] my-[0.2em]"}
              type={"checkbox"}
              onChange={onSameAddressChange}
            />
            <span>Alamat Sama Dengan KTP</span>
          </label>
        ) : undefined}
      </div>

      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        <SelectFDBProvince
          controllerName={`domicile_province_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.PROVINCE}
          placeholder={FieldLabel.PROVINCE}
          required={true}
          returnObject={false}
          asText={isView}
        />
        <SelectFDBCity
          controllerName={`domicile_city_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.CITY}
          placeholder={FieldLabel.CITY}
          required={true}
          provinceId={livingProvinceId}
          returnObject={false}
          asText={isView}
        />
        <SelectFDBDistrict
          controllerName={`domicile_district_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.DISTRICT}
          placeholder={FieldLabel.DISTRICT}
          required={true}
          cityId={livingCityId}
          returnObject={false}
          asText={isView}
        />
        <SelectFDBVillage
          controllerName={`domicile_village_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.SUBDISTRICT}
          placeholder={FieldLabel.SUBDISTRICT}
          required={true}
          districtId={livingDistrictId}
          returnObject={false}
          asText={isView}
        />
        <SelectFDBZipCode
          controllerName={`domicile_zip_code_id`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.ZIPCODE}
          placeholder={FieldLabel.ZIPCODE}
          required={true}
          villageId={livingVillageId}
          returnObject={false}
          asText={isView}
        />
        <InputForm
          controllerName={`domicile_rt`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RT}
          placeholder={FieldLabel.RT}
          required
          asText={isView}
        />
        <InputForm
          controllerName={`domicile_rw`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RW}
          placeholder={FieldLabel.RW}
          required
          asText={isView}
        />

        <InputForm
          controllerName={`domicile_address`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.ADDRESS}
          placeholder={FieldLabel.ADDRESS}
          rows={4}
          textArea
          required
          asText={isView}
        />

        {!isView ? (
          <GeotagLocationButton
            label={FieldLabel.GEOTAG}
            className={"w-full"}
            onSuccess={onGeotagClick()}
          />
        ) : undefined}

        <InputForm
          controllerName={`latitude`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.LAT}
          placeholder={FieldLabel.LAT}
          required
          disabled
          readOnly
          asText={isView}
        />

        <InputForm
          controllerName={`longitude`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.LNG}
          placeholder={FieldLabel.LNG}
          required
          disabled
          readOnly
          asText={isView}
        />

        <AtomDatePicker
          controllerName={`domicile_since_year`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.LIVE_SINCE}
          placeholder={FieldLabel.LIVE_SINCE}
          datepickerOptions={{
            dateFormat: "yyyy",
            maxDate: new Date(),
            showYearPicker: true,
          }}
          required
          showErrorLabel
          asText={isView}
        />
      </div>
    </CardForm>
  );
}

AddressSection.propTypes = {};

export default AddressSection;
