import axios from "axios";
import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  "Content-Type": "application/json",
};

export const getListAWP = (params) => {
  return axiosInstance.get(`${config_daprog.BASE_URL}/v1/cms/awp/list`, {
    params,
    headers,
  });
};
