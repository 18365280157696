import FinancingRequest from "pages/FDB/FinancingRequest";
import ApplicationForm from "pages/FDB/FinancingRequest/SupplierApplication/ApplicationForm";

const FinancingRequestComp = (props) => () => <FinancingRequest {...props} />;
const FinancingRequestFormComp = (props) => () =>
  <ApplicationForm {...props} />;

const PageTypeLembaga = {
  bank_ventura: {
    group: "lembaga-penyalur",
    type: "bank-ventura",
  },
  koperasi: {
    group: "lembaga-penyalur",
    type: "koperasi",
  },
  non_koperasi: {
    group: "lembaga-penyalur",
    type: "non-koperasi",
  },
  badan_hukum: {
    group: "lembaga-penyalur",
    type: "badan-hukum",
  },
  bumdes: {
    group: "non-perorangan",
    type: "bumdes",
  },
  cv: {
    group: "non-perorangan",
    type: "cv",
  },
  pt: {
    group: "non-perorangan",
    type: "pt",
  },
  firma: {
    group: "non-perorangan",
    type: "firma",
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/bank-ventura",
    element: FinancingRequestComp(PageTypeLembaga.bank_ventura),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/koperasi",
    element: FinancingRequestComp(PageTypeLembaga.koperasi),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/non-koperasi",
    element: FinancingRequestComp(PageTypeLembaga.non_koperasi),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/badan-hukum",
    element: FinancingRequestComp(PageTypeLembaga.badan_hukum),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/bumdes",
    element: FinancingRequestComp(PageTypeLembaga.bumdes),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.bumdes,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/cv",
    element: FinancingRequestComp(PageTypeLembaga.cv),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.cv,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/pt",
    element: FinancingRequestComp(PageTypeLembaga.pt),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.pt,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/firma",
    element: FinancingRequestComp(PageTypeLembaga.firma),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.firma,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/bank-ventura/buat-permohonan",
    element: FinancingRequestFormComp(PageTypeLembaga.bank_ventura),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/koperasi/buat-permohonan",
    element: FinancingRequestFormComp(PageTypeLembaga.koperasi),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/non-koperasi/buat-permohonan",
    element: FinancingRequestFormComp(PageTypeLembaga.non_koperasi),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/badan-hukum/buat-permohonan",
    element: FinancingRequestFormComp(PageTypeLembaga.badan_hukum),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/cv/buat-permohonan",
    element: FinancingRequestFormComp(PageTypeLembaga.cv),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.cv,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/pt/buat-permohonan",
    element: FinancingRequestFormComp(PageTypeLembaga.pt),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.pt,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/firma/buat-permohonan",
    element: FinancingRequestFormComp(PageTypeLembaga.firma),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.firma,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/bumdes/buat-permohonan",
    element: FinancingRequestFormComp(PageTypeLembaga.bumdes),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.bumdes,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/bank-ventura/view",
    element: FinancingRequestFormComp(PageTypeLembaga.bank_ventura),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/koperasi/view",
    element: FinancingRequestFormComp(PageTypeLembaga.koperasi),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/non-koperasi/view",
    element: FinancingRequestFormComp(PageTypeLembaga.non_koperasi),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/lembaga-penyalur/badan-hukum/view",
    element: FinancingRequestFormComp(PageTypeLembaga.badan_hukum),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/cv/view",
    element: FinancingRequestFormComp(PageTypeLembaga.cv),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.cv,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/pt/view",
    element: FinancingRequestFormComp(PageTypeLembaga.pt),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.pt,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/firma/view",
    element: FinancingRequestFormComp(PageTypeLembaga.firma),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.firma,
  },
  {
    routeType: "private",
    path: "/permohonan-pembiayaan/non-perorangan/bumdes/view",
    element: FinancingRequestFormComp(PageTypeLembaga.bumdes),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.bumdes,
  },
  // {
  //   routeType: "private",
  //   path: "/financing-request",
  //   element: FinancingRequestComp({ type: PageTypeLembaga.bank_ventura }),
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/bank-ventura",
  //   element: FinancingRequestComp({ type: PageTypeLembaga.bank_ventura }),
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/koperasi",
  //   element: FinancingRequestComp({ type: PageTypeLembaga.koperasi }),
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/non-koperasi",
  //   element: FinancingRequestComp({ type: PageTypeLembaga.non_koperasi }),
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/badan-hukum",
  //   element: FinancingRequestComp({ type: PageTypeLembaga.badan_hukum }),
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/create",
  //   element: FinancingRequestFormComp({ type: PageTypeLembaga.bank_ventura }),
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/koperasi/create",
  //   element: FinancingRequestFormComp({ type: PageTypeLembaga.koperasi }),
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/non-koperasi/create",
  //   element: FinancingRequestFormComp({ type: PageTypeLembaga.non_koperasi }),
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/badan-hukum/create",
  //   element: FinancingRequestFormComp({ type: PageTypeLembaga.badan_hukum }),
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application",
  //   element: SupplierApplication,
  //   exact: true,
  //   sidebar: true,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application/aplication-form",
  //   element: ApplicationForm,
  //   exact: true,
  //   sidebar: false,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application/it-system",
  //   element: ITSystemForm,
  //   exact: true,
  //   sidebar: false,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application/debtor-access",
  //   element: DebiturAksesForm,
  //   exact: true,
  //   sidebar: false,
  // },
  // {
  //   routeType: "private",
  //   path: "/financing-request/supplier-application/finance/:role",
  //   element: NeracaKeuanganForm,
  //   exact: true,
  //   sidebar: false,
  // },
];
