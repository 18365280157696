import { signInFailed, signInSuccess } from "features/auth/authSlice";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
// import _ from "lodash";
import { motion } from "framer-motion";
import iefLogo from "assets/iefLogo.png";
import ImageLogin from "assets/ImageLogin.png";
import ImageLogin2 from "assets/ImageLogin2.png";

import { Button, InputValidation, Spinner } from "components";
import InputPassword from "components/molecules/InputPassword";
import { useMutation } from "@tanstack/react-query";
import { login } from "services";
import { useNavigate } from "react-router";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (params) => {
      const payload = new FormData();
      payload.append("email", params.email);
      payload.append("password", params.password);
      return (await login(payload)).data;
    },
    onSuccess(res) {
      // dispatch(signInSuccess(res));
      navigate("/");
    },
    onError(err) {
      // dispatch(signInFailed(err));
      setError("email", err?.response?.data?.message);
    },
  });

  // const { isLoading } = useSelector(({ auth }) => auth);
  const [showPass, setShowPass] = useState(false);
  const onSubmit = (data) => {
    // _.isEmpty(errors) && dispatch(signIn("fakeToken"));
    console.log(data);
    // data && dispatch(signIn(data));
    mutate(data);
  };

  return (
    <motion.div
      className="card absolute top-0 w-full h-full flex bg-base-100 justify-between"
      exit={{ y: 50, opacity: 0 }}
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.25 }}
    >
      <div className="flex w-screen">
        <img
          className="absolute border-white border z-0 left-0 w-[720px]"
          src={ImageLogin}
          alt=""
        />
        <img
          className="w-[720px] absolute z-10 right-0"
          src={ImageLogin2}
          alt=""
        />
      </div>

      <div className="flex absolute top-[261px] mobile:right-0 mobile:left-0 right-[12.5vw] z-20 items-center flex-col gap-4">
        <img className="h-20 w-max " src={iefLogo} alt="" />
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 ">
            <InputValidation
              {...register("email", {
                required: { value: true, message: "Email wajib diisi" },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Format email tidak valid",
                },
              })}
              label="Email"
              type="text"
              placeholder="john@gmail.com"
              isError={{
                value: errors.email,
                message: errors.email?.message,
              }}
            />
            <InputPassword
              {...register("password", {
                required: { value: true, message: "Password wajib diisi" },
                minLength: {
                  value: 8,
                  message: "Password minimal 8 karakter",
                },
              })}
              label="Password"
              setShowPass={setShowPass}
              showPass={showPass}
              type={showPass ? "text" : "password"}
              placeholder="password"
              isError={{
                value: errors.password,
                message: errors.password?.message,
              }}
            />
            <div className="text-right font-semibold">
              <button>Forgot Password</button>
            </div>
          </div>
          <div className="card-actions justify-end">
            <Button
              label={isLoading ? <Spinner /> : "Login"}
              disabled={isLoading}
              theme="primary"
              type="submit"
              className="bg-green-600 text-white flex flex-row justify-center items-center p-2 gap-2 w-80 h-12 rounded-md font-bold"
            />
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default Login;
