import { InputForm } from "components";
import CardForm from "components/molecules/CardForm";
import { FieldLabel } from "../validation-schema";
import { useLocation } from "react-router";
import AtomSelect from "components/atoms/Select";
import InputCurrencyForm from "components/V2/shared/input-currency-form";

export const InformasiKeuangan = () => {
  const location = useLocation();
  const isView = location.pathname.includes("view");

  return (
    <CardForm label="Informasi Keuangan">
      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        <div className="col-span-2">
          {/* <InputForm
            controllerName={`income_info.0.income`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.EST_INCOME}
            required
            asText={isView}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          /> */}
          <InputCurrencyForm
            name="income_info.0.income"
            label={FieldLabel.EST_INCOME}
            required
            asText={isView}
          />
        </div>

        {/* <div
          className={[
            "col-span-2 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
          ]}
        >
          <InputForm
            controllerName={`income_info.0.income_cycle`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.INCOME_CYCLE}
            required
            asText={isView}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />

          <AtomSelect
            controllerName={`income_info.0.income_cycle_unit`}
            className="basic-single mt-2 flex-1"
            wrapperClass={"col-span-1"}
            returnObject={false}
            label={<span>&nbsp;</span>}
            options={[
              { value: "Bulan", label: "Bulan" },
              { value: "Tahun", label: "Tahun" },
            ]}
            asText={isView}
          />
        </div> */}

        <div className="col-span-2 space-y-4 flex flex-col md:flex-row md:space-x-4 md:space-y-0">
          {/* <InputForm
            controllerName={`routine_outcome_per_month`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.REG_EXPENSE}
            required
            asText={isView}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          /> */}
          <div className="w-full">
            <InputCurrencyForm
              name="routine_outcome_per_month"
              label={FieldLabel.REG_EXPENSE}
              required
              asText={isView}
            />
          </div>

          <div className="w-full">
            <InputCurrencyForm
              name="biggest_outcome"
              label={FieldLabel.BIGGEST_EXPENSE}
              required
              asText={isView}
            />
          </div>
          {/* <InputForm
            controllerName={`biggest_outcome`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.BIGGEST_EXPENSE}
            required
            asText={isView}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          /> */}

          <InputForm
            controllerName={`use_largest_outcome`}
            className={`py-2 px-4 mt-2 border w-full rounded-md col-span-1`}
            label={FieldLabel.LARGEST_SPENDING}
            required
            asText={isView}
          />
        </div>
      </div>
    </CardForm>
  );
};
