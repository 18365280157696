import * as yup from "yup";

export const groupRegisterSchemeNPS1 = yup.object({
  submit_type: yup.string(),
  // TODO: LABEL
  // agreement_access: yup
  //   .string()
  //   .required()
  //   .label("Dasar Perizinan/Perjanjian Akses Kawasan"),
  // end_year_of_agreement_access: yup
  //   .string()
  //   .required("Tahun Berakhirnya Izin Akses/Perjanjian"),
  founding_date: yup.string().required().label("Tanggal dan Tahun Berdiri"),
  // social_area_function_id: yup
  //   .string()
  //   .required()
  //   .label("Fungsi Kawasan & Skema PS "),
  province_id: yup.string().required().label("Provinsi"),
  city_id: yup.string().required().label("Kota/Kabupaten"),
  district_id: yup.string().required().label("Kecamatan"),
  village_id: yup.string().required().label("Kelurahan/Desa"),
  zip_code_id: yup.string().required().label("Kode Pos"),
  address: yup.string().required().label("Alamat"),
  leader_name: yup.string().required().label("Nama Ketua Tani Hutan"),
  leader_phone: yup.string().required().label("Nomer Telepon Ketua Tani Hutan"),
  leader_identity: yup.string().required().label("NIK KTP Ketua Tani Hutan"),
  leader_gender: yup.string().required().label("Jenis Kelamin Ketua"),
  secretary_name: yup.string().required().label("Nama Sekretaris Tani Hutan"),
  secretary_phone: yup
    .string()
    .required()
    .label("Nomer Telepon Sekretaris Tani Hutan"),
  secretary_gender: yup.string().required().label("Jenis Kelamin Sekretaris"),
  treasurer_name: yup.string().required().label("Nama Bendahara Tani Hutan"),
  treasurer_phone: yup
    .string()
    .required()
    .label("Nomer Telepon Bendahara Tani Hutan"),
  treasurer_gender: yup.string().required().label("Jenis Kelamin Bendahara"),
  companion_name: yup.string().required().label("Nama Pendamping PS"),
  companion_status_id: yup.string().required().label("Status Pendamping"),
  companion_phone: yup.string().required().label("Nomor Telepon Pendamping PS"),
  companion_gender: yup.string().required().label("Jenis Kelamin Pendamping"),
  end_date_management: yup
    .string()
    .required()
    .label("Batas Akhir Kepengurusan"),
  end_date_permission: yup
    .string()
    .required()
    .label("Batas Waktu Kepengurusan Kelompok"),
  end_date_confirmation: yup
    .string()
    .required()
    .label("Batas Waktu Pengukuhan Kelompok"),
  end_date_legality: yup
    .string()
    .required()
    .label("Batas waktu legalitas akses kelompok/anggota terhadap lahan"),
  total_member_not_apply: yup
    .string()
    .required()
    .label("Jumlah anggotaKelompok (Termasuk yang tidak mengajukan pinjaman)"),
  total_member_not_apply_persil: yup.string().required().label("Jumlah Persil"),
  total_member_not_apply_area: yup
    .string()
    .required()
    .label("Total Luas Lahan"),
  total_member_apply: yup
    .string()
    .required()
    .label("Jumlah anggota yang mengajukan permohonan"),
  total_member_apply_persil: yup.string().required().label("Jumlah Persil"),
  total_member_apply_area: yup.string().required().label("Total Luas Lahan"),
  activities: yup.array(
    yup.object({
      id: yup.string().notRequired(),
      activity_category_id: yup.string().required().label("Kategori"),
      description: yup.string().required().label("Deskripsi"),
    })
  ),
  finance_businesses: yup.array(
    yup.object({
      id: yup.string().notRequired(),
      finance_business_category_id: yup.string().required().label("Kategori"),
      description: yup.string().required().label("Deskripsi"),
    })
  ),
  partners: yup.array(
    yup.object({ id: yup.string().notRequired(), name: yup.string() })
  ),
});

export const groupRegisterSchemeNPS2 = yup.object({
  documents: yup.array(
    yup.object({
      id: yup.string().notRequired(),
      doc_id: yup.string().required().label("Dokumen"),
      name: yup.string(),
      type: yup.string(),
    })
  ),
  agree: yup
    .bool()
    .required("Anda harus menyetujui pernyataan ini.")
    .oneOf([true], "Anda harus menyetujui pernyataan ini.")
    .default(false),
  created_from: yup.string().required().label("Dibuat Pada (Tempat)"),
});
