import BreadCrumbs from "components/molecules/Breadcrumbs";
import React from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import Button from "../../../components/atoms/Button";
import { useQuery } from "@tanstack/react-query";
import { getSelfAssesment } from "services/fdb/permohonan-pembiayaan";

const AkunPeroranganSelfAssessment = (props) => {
  const { type } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { data } = useQuery(["GET_SELF_ASSESMENT_FLAGGING"], getSelfAssesment, {
    select: (data) => data?.data?.data,
  });

  return (
    <>
      <div className="w-full space-y-4">
        <div className="flex flex-col gap-y-6">
          {/* Header Section */}
          <div className="bg-[#FFFFFF] rounded-xl p-10">
            <div className="flex flex-col w-full">
              <BreadCrumbs
                routes={[
                  {
                    label: "Self Assessment",
                    path: `/perorangan/self-assessment`,
                  },
                ]}
              />
            </div>
          </div>

          <section className="bg-[#FFFFFF] rounded-xl p-10 text-center p-10">
            <h1 className={"text-3xl font-medium mb-2"}>Self Assessment</h1>

            <p>
              Lorem ipsum dolor sit amet consectetur. Malesuada ultrices enim
              feugiat adipiscing cursus enim.
            </p>

            <div className={"mt-10"}>
              {data && (
                <Button
                  className={
                    "bg-primary-maincolor-600 text-white py-3 px-5 rounded-lg"
                  }
                  type={"button"}
                  label={"Mulai Assessment"}
                  onClick={() => navigate("/perorangan/self-assessment/start")}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AkunPeroranganSelfAssessment;
