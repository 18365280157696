import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getReportProgress = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/progress-report/list`,
    { params },
    { headers }
  )
}

export const getReportDetail = (params) => {
  console.log("params: ", params)
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/progress-report?id=${params}`,
    { headers }
  )
}

export const getListProject = () => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/list/deal`,
    { headers }
  )
}

export const getDetailNotes = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/notes/detail/${params?.note_id}`,
    { headers }
  )
}

export const getAwpDetail = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/cms/awp`,
    { params },
    { headers }
  )
}

export const storeReportProgress = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/progress-report/store`,
    payload
  );
};