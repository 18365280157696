/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { InputForm, InputFormUpload } from "../../../../../components";
import AtomDatePicker from "../../../../../components/atoms/Datepicker";
import FileInfoField from "../../../../../components/atoms/FileInfoField";
import CardForm from "../../../../../components/molecules/CardForm";
import InputKTP from "../../../../../components/organisms/inputs/InputKTP";
import { getErrorMessage } from "../../../../../helpers";
import { isCitizenIdValid } from "../../../../../helpers/validation";
import { validationErrorMsg } from "../../../../../locale/yup_id";
import SelectReligion from "components/organisms/inputs/SelectReligion";
import { enqueueSnackbar } from "notistack";
import { postFileServices } from "services/fdb/akunKelompok";
import { useMutation } from "@tanstack/react-query";
import { getFile } from "services/danaProgram/fileService";
import InputMaskForm from "components/V2/shared/input-mask-form";

export const FieldLabel = {
  CID: "No. KTP",
  CID_IMG: "KTP",
  FAMILY_REG_FILE: "Kartu Keluarga",
  FULL_NAME: "Nama Lengkap Sesuai KTP",
  BIRTHPLACE: "Tempat Lahir",
  BIRTHDAY: "Tanggal Lahir",
  FAMILY_REG_NO: "No Kartu Keluarga",
  FAMILY_REG_IMG: "Kartu Keluarga",
  MAIN_JOB: "Pekerjaan Utama",
  SUPPORT_DOC: "Dokumen Pendukung Pekerjaan",
  RELIGION: "Agama",
};

export const validationSchema = Yup.object().shape({
  ktp: Yup.string()
    .trim()
    .test("ktp", validationErrorMsg.mixed.default, isCitizenIdValid)
    .required()
    .label(FieldLabel.CID),
  ktp_file: Yup.string().required().label(FieldLabel.CID_IMG),
  // ktp_file: Yup.mixed()
  //   .test("ktp_file", validationErrorMsg.mixed.required, (value, context) => {
  //     const allowedTypes = MAX_DOC_UPLOAD;

  //     // if has value, validate the file
  //     if (value) {
  //       // validate file type by extension
  //       const v1 = isFileTypeMatches({ ext: allowedTypes })(value, context);
  //       if (!v1) {
  //         return context.createError({
  //           message: validationCustomErrorMsg.isFiletype(
  //             allowedTypes.join(", ")
  //           )({ label: FieldLabel.CID_IMG }),
  //         });
  //       }

  //       // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
  //       const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
  //       if (!v2) {
  //         return context.createError({
  //           message: validationCustomErrorMsg.isFileSize(
  //             humanizeFileSize(MAX_DOC_UPLOAD)
  //           )({
  //             label: FieldLabel.CID_IMG,
  //           }),
  //         });
  //       }

  //       return true;
  //     }

  //     // otherwise, return false to render the field required
  //     return false;
  //   })
  //   .notRequired()
  //   .label(FieldLabel.CID_IMG),
  name: Yup.string().trim().required().label(FieldLabel.FULL_NAME),
  place_of_birth: Yup.string().trim().required().label(FieldLabel.BIRTHPLACE),
  date_of_birth: Yup.string().trim().required().label(FieldLabel.BIRTHDAY),
  npwp: Yup.string()
    .trim()
    .notRequired()
    .test("lengthIfFilled", "NPWP harus tepat 15 karakter", (val) => {
      if (typeof val !== "undefined" && val !== "") {
        return val.length >= 20;
      }

      return true;
    })
    .label("NPWP"),
  kk: Yup.string().trim().required().length(16).label(FieldLabel.FAMILY_REG_NO),
  religion: Yup.string().trim().required().label(FieldLabel.RELIGION),
  kk_file: Yup.string().required().label(FieldLabel.FAMILY_REG_FILE),
  // kk_file: Yup.mixed()
  //   .test("kk_file", validationErrorMsg.mixed.required, (value, context) => {
  //     const allowedTypes = MAX_DOC_UPLOAD;

  //     // if has value, validate the file
  //     if (value) {
  //       // validate file type by extension
  //       const v1 = isFileTypeMatches({ ext: allowedTypes })(value, context);
  //       if (!v1) {
  //         return context.createError({
  //           message: validationCustomErrorMsg.isFiletype(
  //             allowedTypes.join(", ")
  //           )({ label: FieldLabel.CID_IMG }),
  //         });
  //       }

  //       // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
  //       const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
  //       if (!v2) {
  //         return context.createError({
  //           message: validationCustomErrorMsg.isFileSize(
  //             humanizeFileSize(MAX_DOC_UPLOAD)
  //           )({
  //             label: FieldLabel.CID_IMG,
  //           }),
  //         });
  //       }

  //       return true;
  //     }

  //     // otherwise, return false to render the field required
  //     return false;
  //   })
  //   .notRequired()
  //   .label(FieldLabel.FAMILY_REG_IMG),
  job: Yup.string().trim().required().label(FieldLabel.MAIN_JOB),
  income_file: Yup.string().required().label(FieldLabel.SUPPORT_DOC),
  // income_file: Yup.mixed()
  //   .test(
  //     "income_file",
  //     validationErrorMsg.mixed.required,
  //     (value, context) => {
  //       const allowedTypes = MAX_DOC_UPLOAD;

  //       // if has value, validate the file
  //       if (value) {
  //         // validate file type by extension
  //         const v1 = isFileTypeMatches({ ext: allowedTypes })(value, context);
  //         if (!v1) {
  //           return context.createError({
  //             message: validationCustomErrorMsg.isFiletype(
  //               allowedTypes.join(", ")
  //             )({ label: FieldLabel.SUPPORT_DOC }),
  //           });
  //         }

  //         // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
  //         const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
  //         if (!v2) {
  //           return context.createError({
  //             message: validationCustomErrorMsg.isFileSize(
  //               humanizeFileSize(MAX_DOC_UPLOAD)
  //             )({
  //               label: FieldLabel.SUPPORT_DOC,
  //             }),
  //           });
  //         }

  //         return true;
  //       }

  //       // otherwise, return false to render the field required
  //       return false;
  //     }
  //   )
  //   .notRequired()
  //   .label(FieldLabel.SUPPORT_DOC),
});

function DebiturSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [ktpImg, setKtpImg] = useState();
  const [familyImg, setFamilyImg] = useState();
  const [incomeImg, setIncomeImg] = useState();
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();

  const ktpFile = methods.watch("debitur.ktp_file");
  const familyCardFile = methods.watch("debitur.kk_file");
  const incomeFile = methods.watch("debitur.income_file");

  const openDocs = (name) => {
    getDocsMutation.mutate({ payload: methods.getValues(name), type: "" });
  };

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "ktp") {
        setKtpImg(res);
      } else if (type === "kk") {
        setFamilyImg(res);
      } else if (type === "income") {
        setIncomeImg(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      // TODO: integrate submit api
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      if (type === "ktp") {
        methods.setValue(`${prefix}.ktp_file`, res.id);
        setKtpImg(res);
      } else if (type === "kk") {
        methods.setValue(`${prefix}.kk_file`, res.id);
        setFamilyImg(res);
      } else if (type === "income") {
        methods.setValue(`${prefix}.income_file`, res.id);
        setIncomeImg(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      // setDraftModal(false);
    },
  });

  useEffect(() => {
    if (ktpFile instanceof File) {
      postFile.mutate({ payload: ktpFile, type: "ktp" });
    } else if (typeof ktpFile === "string" && ktpFile !== "") {
      getDocsMutation.mutate({ payload: ktpFile, type: "ktp" });
    }
  }, [ktpFile]);

  useEffect(() => {
    if (familyCardFile instanceof File) {
      postFile.mutate({ payload: familyCardFile, type: "kk" });
    } else if (typeof familyCardFile === "string" && familyCardFile !== "") {
      getDocsMutation.mutate({ payload: familyCardFile, type: "kk" });
    }
  }, [familyCardFile]);

  useEffect(() => {
    if (incomeFile instanceof File) {
      postFile.mutate({ payload: incomeFile, type: "income" });
    } else if (typeof incomeFile === "string" && incomeFile !== "") {
      getDocsMutation.mutate({ payload: incomeFile, type: "income" });
    }
  }, [incomeFile]);

  return (
    <CardForm label="Data Calon Debitur" {...rest}>
      <div className="lg:grid lg:grid-cols-2 gap-4 space-y-4 lg:space-y-0">
        <InputKTP
          controllerName={`${prefix}ktp`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.CID}
          placeholder={FieldLabel.CID}
          required
          readOnly={isView}
          asText={isView}
        />

        <div className={"col-span-1 row-span-2"}>
          {ktpFile || isView ? (
            <>
              <label className="font-semibold text-sm my-1.5 block">
                {FieldLabel.CID_IMG}
              </label>
              <FileInfoField
                title={ktpImg ? ktpImg.name : ""}
                desc={ktpImg ? `${ktpImg.size} KB` : "Tidak ada file"}
                showIcon
                showDownload={true}
                showClose={true}
                onCloseClick={() => {
                  methods.setValue(`${prefix}ktp_file`, "");
                }}
                buttonLabel="Lihat Berkas"
                onDownloadClick={() => {
                  openDocs(`${prefix}ktp_file`);
                }}
              />
            </>
          ) : (
            <InputFormUpload
              controllerName={`${prefix}ktp_file`}
              label={FieldLabel.CID_IMG}
              required
              readOnly={isView}
            />
          )}
        </div>

        <InputForm
          controllerName={`${prefix}name`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.FULL_NAME}
          placeholder={FieldLabel.FULL_NAME}
          required
          readOnly={isView}
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}phone`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-1"}
          label={"No Telepon"}
          placeholder={"No Telepon"}
          required
          readOnly={isView}
          asText={isView}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />

        {/* <InputForm
          controllerName={`${prefix}npwp`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-1"}
          label={"NPWP"}
          placeholder={"NPWP"}
          required
          readOnly={isView}
          asText={isView}
          maxlength="15"
        /> */}
        <InputMaskForm
          label="NPWP"
          name={`${prefix}npwp`}
          placeholder="NPWP"
          mask="##.###.###.#-###.###"
          replacement={{ "#": /\d/ }}
          required
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}place_of_birth`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.BIRTHPLACE}
          placeholder={FieldLabel.BIRTHPLACE}
          required
          readOnly={isView}
          asText={isView}
        />

        <AtomDatePicker
          controllerName={`${prefix}date_of_birth`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.BIRTHDAY}
          placeholder={FieldLabel.BIRTHDAY}
          datepickerOptions={{
            maxDate: new Date(),
            showMonthDropdown: true,
            showYearDropdown: true,
            scrollableYearDropdown: true,
            dropdownMode: "select",
          }}
          required
          showErrorLabel
          asText={isView}
        />
        <SelectReligion
          controllerName={`${prefix}religion`}
          wrapperClass={"col-span-1 gap-2"}
          label={FieldLabel.RELIGION}
          placeholder={FieldLabel.RELIGION}
          required={true}
          returnObject={false}
          asText={isView}
        />
        <InputForm
          controllerName={`${prefix}kk`}
          className={`py-2 px-4 mt-2 border w-full rounded-md col-span-1`}
          label={FieldLabel.FAMILY_REG_NO}
          placeholder={FieldLabel.FAMILY_REG_NO}
          required
          readOnly={isView}
          asText={isView}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          maxlength="16"
        />

        <div className={"col-span-1 row-span-2"}>
          {familyCardFile || isView ? (
            <>
              <label className="font-semibold text-sm my-1.5 block">
                {FieldLabel.FAMILY_REG_IMG}
              </label>
              <FileInfoField
                title={familyImg ? familyImg.name : ""}
                desc={familyImg ? `${familyImg.size} KB` : "Tidak ada file"}
                showIcon
                showDownload={true}
                showClose={!isView}
                onCloseClick={() => {
                  methods.setValue(`${prefix}kk_file`, "");
                }}
                buttonLabel="Lihat Berkas"
                onDownloadClick={() => {
                  openDocs(`${prefix}kk_file`);
                }}
              />
            </>
          ) : (
            <InputFormUpload
              controllerName={`${prefix}kk_file`}
              label={FieldLabel.FAMILY_REG_IMG}
              required
            />
          )}
        </div>

        <InputForm
          controllerName={`${prefix}job`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.MAIN_JOB}
          placeholder={FieldLabel.MAIN_JOB}
          required
          readOnly={isView}
          asText={isView}
        />

        <div className="col-span-2">
          {incomeFile ? (
            <FileInfoField
              title={incomeImg ? incomeImg.name : ""}
              desc={incomeImg ? `${incomeImg.size} KB` : "Tidak ada file"}
              showIcon
              showDownload={true}
              showClose={!isView}
              onCloseClick={() => {
                methods.setValue(`${prefix}income_file`, "");
              }}
              buttonLabel="Lihat Berkas"
              onDownloadClick={() => {
                openDocs(`${prefix}income_file`);
              }}
            />
          ) : (
            <InputFormUpload
              controllerName={`${prefix}income_file`}
              label={FieldLabel.SUPPORT_DOC}
              required
            />
          )}
        </div>
      </div>
    </CardForm>
  );
}

DebiturSection.propTypes = {};

export default DebiturSection;
