import React, { cloneElement, useMemo, useState } from 'react';
import { StepProgress } from 'components';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import ResultActivity from './ResultActivity';
import DetailActivity from './DetailActivity';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { getDetailActivityReport } from 'services/danaProgram/callForProposalService';
import Stepper from '../components/Stepper';

const stepperItems = [
  {
    label: "Hasil Pelaksanaan Kegiatan",
    component: <ResultActivity />,
  },
  {
    label: "Realisasi Biaya", 
    component: <DetailActivity />,
  },
];

const DetailRencanaKegiatan = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { id } = useParams();
  const items = [
    {
      label: "Funding Proposal",
    },
    { label: "Laporan Kegiatan" },
    { label: "Update" }
  ];

  const { data } = useQuery({
    queryKey: ["get-detail-activity-report"],
    queryFn: async () => {
      const res = await getDetailActivityReport({ proposal_id: id });

      return res?.data?.data || []
    }
  });

  const onNext = () => {
    setActiveStep(prev => prev + 1)
  }

  const onBack = () => {
    setActiveStep(prev => prev - 1)
  }

  return (
    <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
      {/* Header Section */}
      <div className="flex flex-col gap-4">
        <BreadCrumbs routes={items} />
        <hr />
        <div className="w-full mt-6 mb-6 flex justify-center items-center">
          <Stepper currentStep={activeStep} items={stepperItems} />
        </div>
        {cloneElement(stepperItems[activeStep].component, {
          data,
          onNext,
          onBack
        })}
      </div>
    </div>
  );
}

export default DetailRencanaKegiatan;