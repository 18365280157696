import { InputForm } from "components";
import Checkbox from "components/atoms/Checkbox";
import { useLocation } from "react-router";
import { FieldLabel } from "../validation-schema";

export const Agreement = () => {
  const location = useLocation();
  const isView = location.pathname.includes("view");

  return (
    <>
      {!isView ? (
        <Checkbox
          controllerName={`agree`}
          label={() => (
            <div>
              <p className={"font-medium"}>Saya Setuju</p>
              <p>
                Demikian permohonan, segala isi dan pernyataan yang terdapat
                pada formulir ini kami buat dengan sebenar-benarnya tanpa ada
                paksaan dari suatu apapun.
              </p>
            </div>
          )}
          inputWrapperClass={
            "bg-[#EEFFF4] p-6 flex gap-4 items-start rounded-lg"
          }
        />
      ) : undefined}

      <div
        className={
          "bg-[#F9FCF6] p-6 grid grid-cols-1 md:grid-cols-2 gap-4 rounded-lg border"
        }
      >
        <div className={"col-span-1"}>
          <InputForm
            controllerName={`created_at_display`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.CREATED_AT}
            placeholder={""}
            disabled
            asText={isView}
          />
          <InputForm
            controllerName={`created_at`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 hidden`}
            type={"hidden"}
          />
        </div>
        <div className={"col-span-1"}>
          <InputForm
            controllerName={`created_at_place`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.CREATED_LOC}
            placeholder={""}
            asText={isView}
          />
        </div>
      </div>
    </>
  );
};
