import React from "react";
import { RiAlertLine } from "react-icons/ri";

const WarningAlert = ({ text = "" }) => {
  return (
    <div className="bg-[#FFFCF5] border border-[#F5AE70] rounded-xl p-4">
      <div className="flex flex-row gap-2 items-center">
        <RiAlertLine className="text-[#8F480A]" size={20} />
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="font-semibold text-[14px] text-[#8F480A]">
              {text}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningAlert;
