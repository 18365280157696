import ProspectingLembagaRegister from 'pages/FDB/ProspectingLembaga/Register';
import ProspectingLembagaDashboard from 'pages/FDB/ProspectingLembaga/Dashboard';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: 'public',
    path: '/register-channeling-agencies',
    element: ProspectingLembagaRegister,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/lembaga-penyalur/dashboard',
    element: ProspectingLembagaDashboard,
    exact: true,
    sidebar: true,
  },
];
