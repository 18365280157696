import { BottomFormAction, InputFormRadio, InputFormUpload } from 'components';
import React from 'react';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import * as Yup from 'yup';
import CardForm from 'components/molecules/CardForm';
import { FormInput } from './components';
import Button from 'components/atoms/Button/Button';
import { RiAddLine } from 'react-icons/ri';

const FORM_LABEL = {
  JENIS_KLASIFIKASI: 'Jenis Klasifikasi',
  SOP: 'SOP',
  SOP_NAME: 'Nama SOP',
  SOP_DOCUMENT: 'Dokumen SOP',
  FINANCING_EXPERIENCE:
    'memilki pengalaman pembiayaan dalam bidang Usaha kehutanan dan Investasi lingkungan',
  REPORTING_EXPERIENCE:
    'Memiliki pengalaman dalam menyusun laporan keuangan secara periodik sesuai mekanisme yang berlaku',
};

const KlasifikasiFinansial = ({ onSubmit, onBack, onDraft }) => {
  const validationSchema = Yup.object().shape({
    jenis_klasifikasi: Yup.string()
      .required()
      .label(FORM_LABEL.JENIS_KLASIFIKASI),
    sop: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required().label(FORM_LABEL.SOP_NAME),
          document: Yup.mixed().required().label(FORM_LABEL.SOP_DOCUMENT),
        })
      )
      .min(1)
      .label(FORM_LABEL.SOP),
    financing_experience: Yup.string()
      .required('Wajib Diisi')
      .label(FORM_LABEL.FINANCING_EXPERIENCE),
    reporting_experience: Yup.string()
      .required('Wajib Diisi')
      .label(FORM_LABEL.REPORTING_EXPERIENCE),
  });

  const methods = useForm(
    {
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
      defaultValues: {
        jenis_klasifikasi: '',
        sop: [
          {
            name: '',
            document: null,
          },
        ],
        financing_experience: '',
        reporting_experience: '',
      },
    },
    [validationSchema]
  );

  const onFormSubmit = () => {
    return methods.handleSubmit(
      (data) => {
        onSubmit(data);
      },
      (errors, e) => console.log(errors, e)
    );
  };

  return (
    <div>
      <div className="">
        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit()} className="space-y-8">
            <CardForm label="Klasifikasi Finansial">
              <InputFormRadio
                label={FORM_LABEL.JENIS_KLASIFIKASI}
                controllerName={'jenis_klasifikasi'}
                usingGrid
                gridClassName="grid grid-cols-1 gap-4 mt-2"
                values={[
                  {
                    label:
                      'Usaha Kecil (Rencana Penyaluran FDB < Rp. 5 Miliar)',
                    value: 'kecil',
                  },
                  {
                    label:
                      'Usaha Menengah (Rencana Penyaluran FDB  Rp. 5 - 20 Miliar)',
                    value: 'menengah',
                  },
                  {
                    label:
                      'Usaha Besar (Rencana Penyaluran FDB  > Rp. 20 Miliar)',
                    value: 'besar',
                  },
                ]}
              />
            </CardForm>

            <PengelolaanKeuangan />
          </form>
        </FormProvider>
      </div>

      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onFormSubmit()}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        disableButtonSubmit={false}
      />
    </div>
  );
};

const PengelolaanKeuangan = () => {
  const { control } = useFormContext();
  const { fields, append } = useFieldArray({
    control,
    name: 'sop',
  });

  return (
    <CardForm label="SOP Pengelolaan Keuangan">
      {fields.map((field, index) => (
        <div key={field.id}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <FormInput
              controllerName={`sop.${index}.name`}
              label={FORM_LABEL.SOP_NAME}
              placeholder={FORM_LABEL.SOP_NAME}
              required
            />
            <InputFormUpload
              controllerName={`sop.${index}.document`}
              label={FORM_LABEL.SOP_DOCUMENT}
              required
            />
          </div>
        </div>
      ))}

      <div className="flex justify-end">
        <Button
          type="button"
          label={
            <>
              <RiAddLine className="text-lg" />
              Tambah SOP
            </>
          }
          onClick={() =>
            append({
              name: '',
              document: null,
            })
          }
        />
      </div>

      <div className="mt-5 space-y-4">
        <InputFormRadio
          label={FORM_LABEL.FINANCING_EXPERIENCE}
          controllerName={'financing_experience'}
          usingGrid
          gridClassName="w-auto flex gap-4 mt-2"
          optionClass={'border-none bg-transparent px-0 flex-none'}
          values={[
            {
              label: 'Ya, Pernah',
              value: 'pernah',
            },
            {
              label: 'Belum pernah',
              value: 'belum_pernah',
            },
          ]}
        />

        <InputFormRadio
          label={FORM_LABEL.REPORTING_EXPERIENCE}
          controllerName={'reporting_experience'}
          usingGrid
          gridClassName="w-auto flex gap-4 mt-2"
          optionClass={'border-none bg-transparent px-0 flex-none'}
          values={[
            {
              label: 'Ya, Pernah',
              value: 'pernah',
            },
            {
              label: 'Belum pernah',
              value: 'belum_pernah',
            },
          ]}
        />
      </div>
    </CardForm>
  );
};

export default KlasifikasiFinansial;
