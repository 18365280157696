import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  // yearRange: Yup.string().required("Pilih Range Tahun harus diisi"),
  financial_report: Yup.array().of(
    Yup.object().shape({
      year: Yup.string().trim(),
      total_assets: Yup.string().trim(),
      total_debt: Yup.string().trim(),
      current_asset: Yup.object().shape({
        total: Yup.string().trim(),
        cash: Yup.string().trim().required("Kas dan setara kas wajib diisi"),
        equipment: Yup.string().trim().required("Perlengkapan wajib diisi"),
        supply: Yup.string().trim().required("Persediaan wajib diisi"),
        debts: Yup.string().trim().required("Piutang wajib diisi"),
        advance_payment_program: Yup.string().trim(),
        other_asset: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().trim(),
            value: Yup.string().trim(),
          })
        ),
      }),
      fixed_asset: Yup.object().shape({
        total: Yup.string().trim(),
        equipment: Yup.string()
          .trim()
          .required("Peralatan dan mesin wajib diisi"),
        wip: Yup.string().trim().required("Pekerjaan dalam proses wajib diisi"),
        land_and_buildings: Yup.string()
          .trim()
          .required("Tanah dan bangunan wajib diisi"),
        other_asset: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().trim(),
            value: Yup.string().trim(),
          })
        ),
      }),
      other_asset: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().trim(),
          value: Yup.string().trim(),
        })
      ),
      short_term_debt: Yup.object().shape({
        total: Yup.string().trim(),
        cash: Yup.string().trim().required("Utang dagang wajib diisi"),
        equipment: Yup.string().trim().required("Utang pajak wajib diisi"),
        other_debt: Yup.string().trim().required("Utang lainnya wajib diisi"),
      }),
      long_term_debt: Yup.object().shape({
        total: Yup.string().trim(),
        debt: Yup.string().trim().required("Utang jangka panjang wajib diisi"),
      }),
      total_net_worth: Yup.string().trim(),
      total_net_income: Yup.string().trim(),
      total_income: Yup.string().trim().required("wajib diisi"),
      total_incurred: Yup.string().trim().required("wajib diisi"),
      program_costs: Yup.string().trim(),
      non_program_costs: Yup.string().trim(),
      // total_program_costs: Yup.string().trim(),
      // total_non_program_costs: Yup.string().trim(),
      url_file_report_financial: Yup.mixed(),
      is_kap: Yup.mixed().required("field wajib diisi"),
      kap: Yup.string().when("is_kap", {
        is: true,
        then: () => Yup.string().required("KAP wajib diisi"),
        otherwise: () => Yup.string(),
      }),
      kap_value: Yup.mixed().when("is_kap", {
        is: true,
        then: () => Yup.mixed().required("Hasil penilaian KAP wajib diisi"),
        otherwise: () => Yup.mixed(),
      }),
      kap_description: Yup.string().when("is_kap", {
        is: true,
        then: () =>
          Yup.string().required(
            "Deskripsi hasil audit / opini auditor wajib diisi"
          ),
        otherwise: () => Yup.string(),
      }),
    })
  ),
});

export default validationSchema;
