import { Controller, useFormContext } from 'react-hook-form';

const { RiLeafLine } = require('react-icons/ri');

const RadioGroup = ({ options, required = false, label, controllerName }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={controllerName}
      control={control}
      defaultValue={''}
      render={({
        field: { onChange, value: selected },
        fieldState: { invalid, error },
      }) => (
        <div>
          <label className="flex gap-1 label font-semibold text-[14px] w-full mb-2">
            <span className={`label-text`}>{label}</span>
            {required && <span className="text-[#F04438]">*</span>}
          </label>
          <div className="grid grid-cols-4 gap-6 mt-6">
            {options.map(({ color, value, label, description }) => (
              <button
                key={value}
                className={[
                  'border-[3px] bg-gray-50 rounded-[16px] p-4 relative transition duration-300 focus:outline-green-600',
                  selected === value ? 'border-green-600' : '',
                ].join(' ')}
                onClick={() => onChange(value)}
              >
                <div className="absolute -top-6 inset-x-0 w-full flex justify-center">
                  <div
                    className={`w-12 h-12 ${color.outer} rounded-full flex items-center justify-center`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full ${color.inner} ${color.text} flex items-center justify-center`}
                    >
                      <RiLeafLine className="w-6 h-6" />
                    </div>
                  </div>
                </div>
                <div className="my-4">
                  <h3 className="font-semibold text-center text-gray-900">
                    {label}
                  </h3>
                  <p className="text-center text-gray-600 text-sm mt-1">
                    {description}
                  </p>
                </div>
              </button>
            ))}
          </div>
          {invalid && (
            <label className="label block mt-2">
              <div className="text-xs text-error-600 label-text-alt text-error text-[#F04438]">
                {error?.message}
              </div>
            </label>
          )}
        </div>
      )}
    />
  );
};

export default RadioGroup;
