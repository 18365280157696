import React, { useState } from "react";
import AtomSelect from "../../../atoms/Select";

const SelectReligion = React.forwardRef((props, ref) => {
  const { className, districtId, ...restProps } = props;

  const [options, setOptions] = useState([
    {
      value: "Islam",
      label: "Islam",
    },
    {
      value: "Kristen",
      label: "Kristen",
    },
    {
      value: "Budha",
      label: "Budha",
    },
    {
      value: "Hindu",
      label: "Hindu",
    },
    {
      value: "Konghuchu",
      label: "Konghuchu",
    },
  ]);

  return (
    <AtomSelect
      options={options}
      className={["basic-single flex-1", className].join(" ")}
      {...restProps}
    />
  );
});

export default SelectReligion;
