import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { Button, InputForm, Table } from "components";
import AtomSelect from "components/atoms/Select";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { AnimatePresence, motion } from "framer-motion";
import { enqueueSnackbar } from "notistack";

import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import {
  RiCloseLine,
  RiEyeLine,
  RiFile3Line,
  RiSearchLine,
} from "react-icons/ri";
import { useNavigate } from "react-router";
import {
  getDisbursementDebtorList,
  updateDisbursementDebtor,
} from "services/fdb/disbursement";
import { getBankService } from "services/masterData/bank";
import * as yup from "yup";

const badgeStatus = {
  "Isi Self Assesment": "text-[#175CD3] bg-[#EFF8FF]",
  "Menunggu Diajukan Ketua": "text-[#B54708] bg-[#FFFAEB]",
  "Lengkapi No Rekening": "text-[#B54708] bg-[#FFFAEB]",
  "Sedang Direview": "text-[#B54708] bg-[#FFFAEB]",
  "Sedang Proses Pencairan": "text-[#B54708] bg-[#FFFAEB]",
  Tersalurkan: "text-[#1C7D44] bg-[#ECFDF3]",
};

const Pencairan = () => {
  const routes = useMemo(
    () => [
      {
        label: "Permohonan Pencairan",
        path: "",
      },
    ],
    []
  );

  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState();
  const [isOpenBankModal, setIsOpenBankModal] = useState(false);
  const [filters, setFilters] = useState({
    paginate: true,
    page: 1,
    limit: 10,
  });

  const handleChangeLimit = (e) => {
    setFilters((prev) => ({ ...prev, limit: e, page: 1 }));
  };

  const handleChangePage = (e) => {
    setFilters((prev) => ({ ...prev, page: e }));
  };

  // ======================================

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["GET_DISBURSEMENT_DEBITUR_LIST", filters],
    getDisbursementDebtorList,
    {
      select: (data) => data?.data?.data,
    }
  );

  const mutateDisbursment = useMutation({
    mutationFn: updateDisbursementDebtor,
    onSuccess: (res) => {
      enqueueSnackbar({
        variant: "success",
        message: "No Rekening berhasil diupdate",
      });

      queryClient.invalidateQueries(["GET_DISBURSEMENT_DEBITUR_LIST", filters]);
      setIsOpenBankModal((prev) => !prev);
    },
    onError: (err) => {
      enqueueSnackbar({
        variant: "error",
        message: "No Rekening gagal diupdate",
      });
    },
  });

  // ==========================

  const paginator = useMemo(() => {
    return {
      page: filters.page,
      limit: filters.limit,
      total: data?.meta?.total,
      previous_pages: data?.meta?.previous_page_url,
      next_pages: data?.meta?.next_page_url,
    };
  }, [data, filters]);

  const tableColumns = useMemo(
    () => [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px] overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return no;
        },
      },
      {
        id: "aggreement_number",
        title: "No Perjanjian",
        dataIndex: "aggreement_number",
        columnClassName: "w-[163px] overflow-hidden",
      },
      {
        id: "phase",
        title: "Tahapan Pencairan",
        dataIndex: "phase",
        columnClassName: "w-[200px] overflow-hidden",
      },
      {
        id: "application_fee",
        title: "Nilai Permohonan Pencairan",
        dataIndex: "application_fee",
        columnClassName: "w-[200px] overflow-hidden",
        render: (value, data, index) => {
          return data?.application_fee
            ? `Rp. ${Math.floor(data?.application_fee).toLocaleString()}`
            : "-";
        },
      },
      {
        id: "status_string",
        title: "Status",
        dataIndex: "status_string",
        columnClassName: "w-[200px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div
              className={classNames(
                "py-0.5 px-2 text-xs font-medium rounded-full w-max",
                badgeStatus[value]
              )}
            >
              {value}
            </div>
          );
        },
      },
      {
        id: "file",
        title: "Dokumen Penyaluran",
        dataIndex: "file",
        columnClassName: "w-[200px] overflow-hidden",
        render: (value, data, index) => {
          return value ? <RiFile3Line size={20} /> : "-";
        },
      },
      {
        id: "#",
        title: "Aksi",
        fixed: "right",
        columnClassName: "w-[296px] overflow-hidden text-center",
        render: (value, data, index) => {
          return (
            <div className="flex justify-center">
              {data.status_string === "Isi Self Assesment" && (
                <Button
                  label="Mulai Assesment"
                  className="w-max text-white bg-primary-600 hover:bg-primary-700 text-xs"
                  onClick={() => {
                    navigate(`${data?.no_agreement}`);
                  }}
                />
              )}
              {data.status_string === "Lengkapi No Rekening" && (
                <Button
                  label="Lengkapi"
                  className="w-max text-white bg-primary-600 hover:bg-primary-700 text-xs"
                  onClick={() => {
                    setSelectedRow(data);
                    setIsOpenBankModal((prev) => !prev);
                  }}
                />
              )}
              {data.status_string !== "Isi Self Assesment" &&
                data.status_string !== "Lengkapi No Rekening" && (
                  <RiEyeLine
                    size={20}
                    onClick={() => navigate(`${data?.id}?type=detail`)}
                  />
                )}
            </div>
          );
        },
      },
    ],
    [navigate, paginator]
  );

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-auto");

    return () => {
      layouContentEl?.classList?.remove("overflow-auto");
    };
  }, []);

  return (
    <>
      {createPortal(
        <BankModal
          open={isOpenBankModal}
          onClose={(e) => {
            setIsOpenBankModal((prev) => !prev);
          }}
          selectedRow={selectedRow}
          mutateDisbursment={mutateDisbursment}
        />,
        document.body
      )}

      <section className="w-full flex flex-col space-y-5 overflow-auto">
        <div className="bg-[#FFFFFF] rounded-xl p-10 w-full">
          <div className="flex flex-col w-full">
            <BreadCrumbs routes={routes} />
            <div className="mt-6 flex flex-row">
              <div className="flex flex-col">
                <span className="font-semibold text-[24px] text-[#101828]">
                  Daftar Permohonan Pencairan
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 p-3 bg-white rounded-xl">
          <div className="relative w-full">
            <RiSearchLine
              className="absolute text-gray-300 top-3 left-4"
              size={24}
            />
            <input
              type="text"
              className="pl-[50px] pr-4 py-3 rounded-md outline-none border border-gray-300 w-full"
              placeholder="Cari"
            />
          </div>

          <Table
            columns={tableColumns}
            dataSource={data?.data ?? []}
            isLoading={isLoading}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeLimit}
            pagination={paginator}
          />
        </div>
      </section>
    </>
  );
};

const bankScheme = yup.object({
  bank: yup.object({
    value: yup.string().required().label("Bank"),
    label: yup.string().required().label("Bank"),
  }),
  bank_owner: yup.string().required().label("Nama Pemilik Rekening"),
  bank_number: yup.string().required().label("No Rekening Tujuan"),
});

const BankModal = ({ open, onClose, selectedRow, mutateDisbursment }) => {
  const methods = useForm({
    resolver: yupResolver(bankScheme),
  });

  const { data } = useQuery(["GET_BANK_LIST"], getBankService, {
    select: (data) => data?.data?.data,
  });

  const onSubmit = (data) => {
    const payload = {
      bank_account: {
        ...data,
        bank_id: data?.bank.value,
        bank_name: data?.bank.label,
      },
    };

    delete payload?.bank_account?.bank;

    mutateDisbursment.mutate({ id: selectedRow?.id, data: payload });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-[9999]">
          <motion.div
            key="modal-confirmation"
            className="bg-white rounded-lg w-[782px] overflow-hidden"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div className="max-h-[90vh] overflow-auto">
              <div className="flex flex-row px-6 py-2 gap-4 items-center justify-center sticky top-0 bg-white z-50">
                <div className="flex-1 overflow-hidden text-lg font-semibold">
                  Tujuan Pemindahbukuan
                </div>
                <div className="shrink-0">
                  <button
                    className="p-3 rounded-full text-gray-500"
                    onClick={() => {
                      onClose(false);
                      methods.reset();
                    }}
                  >
                    <RiCloseLine className={"w-6 h-6"} />
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col px-6 py-5 gap-5">
              <FormProvider {...methods}>
                <div>
                  <AtomSelect
                    label="Bank"
                    // wrapperClass="gap-1"
                    required
                    options={data?.map((bank) => ({
                      value: bank?.id,
                      label: bank?.nama,
                    }))}
                    controllerName="bank"
                    className=""
                  />
                  {methods?.formState?.errors && (
                    <div className="text-xs text-error-600 label-text-alt text-error text-[#F04438]">
                      {methods?.formState?.errors?.bank?.label?.message}
                    </div>
                  )}
                </div>

                <InputForm
                  label="Nama Pemilik Rekening"
                  required
                  controllerName="bank_owner"
                  wrapperClass="gap-1"
                  className="w-full"
                />

                <InputForm
                  label="No Rekening Tujuan"
                  required
                  controllerName="bank_number"
                  wrapperClass="gap-1"
                  className="w-full"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <div className="flex gap-3 w-full">
                  <Button
                    label="Batal"
                    outline
                    className="flex-grow"
                    onClick={() => {
                      methods.reset();
                      onClose();
                    }}
                  />
                  <Button
                    label="Submit"
                    outline
                    className="flex flex-grow bg-primary-600 hover:bg-primary-700 text-white"
                    onClick={methods.handleSubmit(onSubmit)}
                  />
                </div>
              </FormProvider>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Pencairan;
