import React from "react";

const {
  RiCheckLine,
  RiCloseLine,
  RiInformationLine,
  RiErrorWarningLine,
} = require("react-icons/ri");
const { useMemo } = require("react");
const Tag = ({ label, icon, theme = "default", wrapperClass, children }) => {
  const themeClass = useMemo(() => {
    let container = "bg-[#FCFCFD] border border-[#D0D5DD]";
    let title = "";
    let icon = "";

    switch (theme) {
      case "success": {
        container = "bg-[#ECFDF3] text-[#027A48]";
        break;
      }
      case "warning": {
        container = "bg-[#FFFAEB] text-[#B54708]";
        icon = "text-[#F79009]";
        break;
      }
      case "error": {
        container = "bg-[#FFF1F3] text-[#C01048]";
        break;
      }
      case "info": {
        container = "bg-[#F0F9FF] text-[#026AA2]";
        break;
      }
      case "neutral": {
        container = "bg-[#F2F4F7] text-[#344054]";
        break;
      }
      case "accent": {
        container = "bg-[#F4F3FF] text-[#5925DC]";
        break;
      }
      default:
        break;
    }

    return {
      container: [container, wrapperClass || ""].join(" "),
      title,
      icon: "w-[1.2em] h-[1.2em] " + icon,
    };
  }, [theme]);

  const renderIcon = () => {
    if (typeof icon === "function") {
      return icon({ className: themeClass.icon });
    }

    let themeIcon = "";

    switch (theme) {
      case "success": {
        themeIcon = <RiCheckLine className={themeClass.icon} />;
        break;
      }
      case "warning": {
        themeIcon = <RiErrorWarningLine className={themeClass.icon} />;
        break;
      }
      case "error": {
        themeIcon = <RiCloseLine className={themeClass.icon} />;
        break;
      }
      default:
        themeIcon = <RiInformationLine />;
        break;
    }

    return themeIcon;
  };

  return (
    <div
      className={[
        "inline-block text-sm p-2 rounded-full font-medium",
        themeClass.container,
      ].join(" ")}
    >
      {children || (
        <div className={"flex items-center gap-1"}>
          {renderIcon()}
          <div className={"flex-1"}>{label}</div>
        </div>
      )}
    </div>
  );
};

export default Tag;
