import * as yup from "yup";

export const bankScheme = yup.object({
  agency: yup.object({
    name: yup.string().required().label("Nama Lembaga"),
    distributor_institution: yup.string().required().label("Jenis Lembaga"),
    npwp: yup.string().required().label("NPWP"),
    province_id: yup.string().required().label("Provinsi"),
    city_id: yup.string().required().label("Kota / Kabupaten"),
    district_id: yup.string().required().label("Kecamatan"),
    village_id: yup.string().required().label("Kelurahan / Desa"),
    zip_code_id: yup.string().required().label("Kode Pos"),
    address: yup.string().required().label("Alamat"),
    email: yup.string().required().label("Email"),
  }),
  profile: yup.object({
    business_type: yup
      .string()
      .required()
      .label("Jenis Usaha dan/atau Jenis Layanan"),
    contact_person_name: yup.string().required().label("Nama Narahubung"),
    contact_person_position: yup.string().required().label("Jabatan"),
    contact_person_email: yup.string().required().label("Email Narahubung"),
    contact_person_phone: yup.string().required().label("Telepon"),
    contact_person_identity_number: yup.string().required().label("No KTP"),
    contact_person_identity_number_file: yup.string().required().label("KTP"),
    contact_person_npwp: yup.string().required().label("No NPWP"),
    contact_person_npwp_file: yup.string().required().label("NPWP File"),
    background_file: yup
      .string()
      .required()
      .label("Upload Surat Menjadi Lembaga Penyalur"),
    total_member: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Anggota"),
    total_branch: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Cabang"),
    total_employee: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Pegawai"),
    organization_file: yup.string().required().label("Struktur organisasi"),
    institution_npwp: yup.string().required().label("No NPWP"),
    institution_npwp_file: yup.string().required().label("Dokumen File"),
    institution_legality_certificate: yup
      .string()
      .required()
      .label("Nomor Akta Pendirian Dan Pengesahannya"),
    institution_legality_date: yup
      .string()
      .required()
      .label("Tanggal Akta Pendirian Dan Pengesahannya"),
    institution_legality_file: yup
      .string()
      .required()
      .label("Dokumen Akta Pendirian Dan Pengesahannya"),
    institution_nib: yup
      .string()
      // .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Nomor NIB/TDP/SKDP/Izin usaha"),
    institution_nib_date: yup
      .string()
      .required()
      .label("Tanggal Terbit NIB/TDP/SKDP/Izin usaha"),
    institution_nib_file: yup.string().required().label("Dokumen NIB/TDP"),
    institution_other_file: yup
      .string()
      .required()
      .label(
        "Upload Dokumen pendukung lainnya Disatukan (SOP/ Ketetapan Kemenkumham dan dokumen lainnya)"
      ),
    finance_file: yup
      .string()
      .required()
      .label("Informasi Keuangan 3 Tahun Terakhir"),
  }),
});

export const koperasiScheme = yup.object({
  agency: yup.object({
    name: yup.string().required().label("Nama Lembaga"),
    distributor_institution: yup.string().required().label("Jenis Lembaga"),
    npwp: yup.string().required().label("NPWP"),
    province_id: yup.string().required().label("Provinsi"),
    city_id: yup.string().required().label("Kota / Kabupaten"),
    district_id: yup.string().required().label("Kecamatan"),
    village_id: yup.string().required().label("Kelurahan / Desa"),
    zip_code_id: yup.string().required().label("Kode Pos"),
    address: yup.string().required().label("Alamat"),
    email: yup.string().required().label("Email"),
  }),
  profile: yup.object({
    business_type: yup
      .string()
      .required()
      .label("Jenis Usaha dan/atau Jenis Layanan"),
    contact_person_name: yup.string().required().label("Nama Narahubung"),
    contact_person_position: yup.string().required().label("Jabatan"),
    contact_person_email: yup.string().required().label("Email Narahubung"),
    contact_person_phone: yup.string().required().label("Telepon"),
    contact_person_identity_number: yup.string().required().label("No KTP"),
    contact_person_identity_number_file: yup.string().required().label("KTP"),
    contact_person_npwp: yup.string().required().label("No NPWP"),
    contact_person_npwp_file: yup.string().required().label("NPWP File"),
    background_file: yup
      .string()
      .required()
      .label("Upload Surat Menjadi Lembaga Penyalur"),
    total_member: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Anggota"),
    total_branch: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Cabang"),
    total_employee: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Pegawai"),
    organization_file: yup.string().required().label("Struktur organisasi"),
    institution_npwp: yup.string().required().label("No NPWP"),
    institution_npwp_file: yup.string().required().label("Dokumen File"),
    institution_legality_certificate: yup
      .string()
      .required()
      .label("Nomor Akta Pendirian Dan Pengesahannya"),
    institution_legality_date: yup
      .string()
      .required()
      .label("Tanggal Akta Pendirian Dan Pengesahannya"),
    institution_legality_file: yup
      .string()
      .required()
      .label("Dokumen Akta Pendirian Dan Pengesahannya"),
    institution_nib: yup
      .string()
      // .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Nomor NIB/TDP/SKDP/Izin usaha"),
    institution_nib_date: yup
      .string()
      .required()
      .label("Tanggal Terbit NIB/TDP/SKDP/Izin usaha"),
    institution_nib_file: yup.string().required().label("Dokumen NIB/TDP"),
    institution_other_file: yup
      .string()
      .required()
      .label(
        "Upload Dokumen pendukung lainnya Disatukan (SOP/ Ketetapan Kemenkumham dan dokumen lainnya)"
      ),
    institution_nik: yup
      .string()
      // .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Nomor NIK"),
    institution_nik_date: yup.string().required().label("Tanggal Terbit NIK"),
    institution_nik_file: yup.string().required().label("Dokumen NIK"),
    finance_file: yup
      .string()
      .required()
      .label("Informasi Keuangan 3 Tahun Terakhir"),
  }),
});

export const nonKoperasiScheme = yup.object({
  agency: yup.object({
    name: yup.string().required().label("Nama Lembaga"),
    distributor_institution: yup.string().required().label("Jenis Lembaga"),
    npwp: yup.string().required().label("NPWP"),
    province_id: yup.string().required().label("Provinsi"),
    city_id: yup.string().required().label("Kota / Kabupaten"),
    district_id: yup.string().required().label("Kecamatan"),
    village_id: yup.string().required().label("Kelurahan / Desa"),
    zip_code_id: yup.string().required().label("Kode Pos"),
    address: yup.string().required().label("Alamat"),
    email: yup.string().required().label("Email"),
  }),
  profile: yup.object({
    business_type: yup
      .string()
      .required()
      .label("Jenis Usaha dan/atau Jenis Layanan"),
    contact_person_name: yup.string().required().label("Nama Narahubung"),
    contact_person_position: yup.string().required().label("Jabatan"),
    contact_person_email: yup.string().required().label("Email Narahubung"),
    contact_person_phone: yup.string().required().label("Telepon"),
    contact_person_identity_number: yup.string().required().label("No KTP"),
    contact_person_identity_number_file: yup.string().required().label("KTP"),
    contact_person_npwp: yup.string().required().label("No NPWP"),
    contact_person_npwp_file: yup.string().required().label("NPWP File"),
    background_file: yup
      .string()
      .required()
      .label("Upload Surat Menjadi Lembaga Penyalur"),
    total_member: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Anggota"),
    total_branch: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Cabang"),
    total_employee: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Pegawai"),
    organization_file: yup.string().required().label("Struktur organisasi"),
    institution_npwp: yup.string().required().label("No NPWP"),
    institution_npwp_file: yup.string().required().label("Dokumen File"),
    institution_legality_certificate: yup
      .string()
      .required()
      .label("Nomor Akta Pendirian Dan Pengesahannya"),
    institution_legality_date: yup
      .string()
      .required()
      .label("Tanggal Akta Pendirian Dan Pengesahannya"),
    institution_legality_file: yup
      .string()
      .required()
      .label("Dokumen Akta Pendirian Dan Pengesahannya"),
    institution_nib: yup
      .string()
      // .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Nomor NIB/TDP/SKDP/Izin usaha"),
    institution_nib_date: yup
      .string()
      .required()
      .label("Tanggal Terbit NIB/TDP/SKDP/Izin usaha"),
    institution_nib_file: yup.string().required().label("Dokumen NIB/TDP"),
    institution_other_file: yup
      .string()
      .required()
      .label(
        "Upload Dokumen pendukung lainnya Disatukan (SOP/ Ketetapan Kemenkumham dan dokumen lainnya)"
      ),
    finance_file: yup
      .string()
      .required()
      .label("Informasi Keuangan 3 Tahun Terakhir"),
  }),
});

export const lainyaScheme = yup.object({
  agency: yup.object({
    name: yup.string().required().label("Nama Lembaga"),
    distributor_institution: yup.string().required().label("Jenis Lembaga"),
    npwp: yup.string().required().label("NPWP"),
    province_id: yup.string().required().label("Provinsi"),
    city_id: yup.string().required().label("Kota / Kabupaten"),
    district_id: yup.string().required().label("Kecamatan"),
    village_id: yup.string().required().label("Kelurahan / Desa"),
    zip_code_id: yup.string().required().label("Kode Pos"),
    address: yup.string().required().label("Alamat"),
    email: yup.string().required().label("Email"),
  }),
  profile: yup.object({
    business_type: yup
      .string()
      .required()
      .label("Jenis Usaha dan/atau Jenis Layanan"),
    contact_person_name: yup.string().required().label("Nama Narahubung"),
    contact_person_position: yup.string().required().label("Jabatan"),
    contact_person_email: yup.string().required().label("Email Narahubung"),
    contact_person_phone: yup.string().required().label("Telepon"),
    contact_person_identity_number: yup.string().required().label("No KTP"),
    contact_person_identity_number_file: yup.string().required().label("KTP"),
    contact_person_npwp: yup.string().required().label("No NPWP"),
    contact_person_npwp_file: yup.string().required().label("NPWP File"),
    background_file: yup
      .string()
      .required()
      .label("Upload Surat Menjadi Lembaga Penyalur"),
    total_member: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Anggota"),
    total_branch: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Cabang"),
    total_employee: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Pegawai"),
    organization_file: yup.string().required().label("Struktur organisasi"),
    institution_npwp: yup.string().required().label("No NPWP"),
    institution_npwp_file: yup.string().required().label("Dokumen File"),
    institution_legality_certificate: yup
      .string()
      .required()
      .label("Nomor Akta Pendirian Dan Pengesahannya"),
    institution_legality_date: yup
      .string()
      .required()
      .label("Tanggal Akta Pendirian Dan Pengesahannya"),
    institution_legality_file: yup
      .string()
      .required()
      .label("Dokumen Akta Pendirian Dan Pengesahannya"),
    institution_nib: yup
      .string()
      // .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Nomor NIB/TDP/SKDP/Izin usaha"),
    institution_nib_date: yup
      .string()
      .required()
      .label("Tanggal Terbit NIB/TDP/SKDP/Izin usaha"),
    institution_nib_file: yup.string().required().label("Dokumen NIB/TDP"),
    institution_other_file: yup
      .string()
      .required()
      .label(
        "Upload Dokumen pendukung lainnya Disatukan (SOP/ Ketetapan Kemenkumham dan dokumen lainnya)"
      ),
    finance_file: yup
      .string()
      .required()
      .label("Informasi Keuangan 3 Tahun Terakhir"),
  }),
});

export const bumdesScheme = yup.object({
  agency: yup.object({
    name: yup.string().required().label("Nama Lembaga"),
    distributor_institution: yup.string().required().label("Jenis Lembaga"),
    npwp: yup.string().required().label("NPWP"),
    province_id: yup.string().required().label("Provinsi"),
    city_id: yup.string().required().label("Kota / Kabupaten"),
    district_id: yup.string().required().label("Kecamatan"),
    village_id: yup.string().required().label("Kelurahan / Desa"),
    zip_code_id: yup.string().required().label("Kode Pos"),
    address: yup.string().required().label("Alamat"),
    email: yup.string().required().label("Email"),
  }),
  profile: yup.object({
    business_type: yup
      .string()
      .required()
      .label("Jenis Usaha dan/atau Jenis Layanan"),
    contact_person_name: yup.string().required().label("Nama Narahubung"),
    contact_person_position: yup.string().required().label("Jabatan"),
    contact_person_email: yup.string().required().label("Email Narahubung"),
    contact_person_phone: yup.string().required().label("Telepon"),
    contact_person_identity_number: yup.string().required().label("No KTP"),
    contact_person_identity_number_file: yup.string().required().label("KTP"),
    contact_person_npwp: yup.string().required().label("No NPWP"),
    contact_person_npwp_file: yup.string().required().label("NPWP File"),
    background_file: yup
      .string()
      .required()
      .label("Upload Surat Menjadi Lembaga Penyalur"),
    organization_type: yup.string().required().label("Jenis badan usaha"),
    total_member: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Anggota"),
    total_branch: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Cabang"),
    total_employee: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Pegawai"),
    organization_file: yup.string().required().label("Struktur organisasi"),
    finance_file: yup
      .string()
      .required()
      .label("Informasi Keuangan 3 Tahun Terakhir"),
    documents: yup.array(
      yup.object({
        file: yup.string().required().label("Dokumen Legalitas"),
      })
    ),
  }),
});

export const ptScheme = yup.object({
  agency: yup.object({
    name: yup.string().required().label("Nama Lembaga"),
    distributor_institution: yup.string().required().label("Jenis Lembaga"),
    npwp: yup.string().required().label("NPWP"),
    npwp_file: yup.string().required().label("Dokumen NPWP"),
    province_id: yup.string().required().label("Provinsi"),
    city_id: yup.string().required().label("Kota / Kabupaten"),
    district_id: yup.string().required().label("Kecamatan"),
    village_id: yup.string().required().label("Kelurahan / Desa"),
    zip_code_id: yup.string().required().label("Kode Pos"),
    address: yup.string().required().label("Alamat"),
    email: yup.string().required().label("Email"),
  }),
  profile: yup.object({
    // business_type: yup
    //   .string()
    //   .required()
    //   .label("Jenis Usaha dan/atau Jenis Layanan"),
    contact_person_name: yup.string().required().label("Nama Narahubung"),
    contact_person_position: yup.string().required().label("Jabatan"),
    contact_person_email: yup.string().required().label("Email Narahubung"),
    contact_person_phone: yup.string().required().label("Telepon"),
    contact_person_identity_number: yup.string().required().label("No KTP"),
    contact_person_identity_number_file: yup.string().required().label("KTP"),
    contact_person_npwp: yup.string().required().label("No NPWP"),
    contact_person_npwp_file: yup.string().required().label("NPWP File"),
    background_file: yup
      .string()
      .required()
      .label("Upload Surat Menjadi Lembaga Penyalur"),
    // organization_type: yup.string().required().label("Jenis badan usaha"),
    total_member: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Anggota"),
    total_branch: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Cabang"),
    total_employee: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Pegawai"),
    organization_file: yup.string().required().label("Struktur organisasi"),
    kbli_clasification: yup.string().required().label("Klasifikasi KBLI"),
    kbli_business_type: yup
      .string()
      .required()
      .label(
        "Jenis usaha (Sesuai KLASIFIKASI BAKU LAPANGAN USAHA INDONESIA/KBLI)"
      ),
    finance_file: yup
      .string()
      .required()
      .label("Informasi Keuangan 3 Tahun Terakhir"),
    documents: yup.array(
      yup.object({
        file: yup.string().required().label("Dokumen Legalitas"),
      })
    ),
  }),
});

export const cvScheme = yup.object({
  agency: yup.object({
    name: yup.string().required().label("Nama Lembaga"),
    distributor_institution: yup.string().required().label("Jenis Lembaga"),
    npwp: yup.string().required().label("NPWP"),
    npwp_file: yup.string().required().label("Dokumen NPWP"),
    province_id: yup.string().required().label("Provinsi"),
    city_id: yup.string().required().label("Kota / Kabupaten"),
    district_id: yup.string().required().label("Kecamatan"),
    village_id: yup.string().required().label("Kelurahan / Desa"),
    zip_code_id: yup.string().required().label("Kode Pos"),
    address: yup.string().required().label("Alamat"),
    email: yup.string().required().label("Email"),
  }),
  profile: yup.object({
    business_type: yup
      .string()
      .required()
      .label("Jenis Usaha dan/atau Jenis Layanan"),
    contact_person_name: yup.string().required().label("Nama Narahubung"),
    contact_person_position: yup.string().required().label("Jabatan"),
    contact_person_email: yup.string().required().label("Email Narahubung"),
    contact_person_phone: yup.string().required().label("Telepon"),
    contact_person_identity_number: yup.string().required().label("No KTP"),
    contact_person_identity_number_file: yup.string().required().label("KTP"),
    contact_person_npwp: yup.string().required().label("No NPWP"),
    contact_person_npwp_file: yup.string().required().label("NPWP File"),
    background_file: yup
      .string()
      .required()
      .label("Upload Surat Menjadi Lembaga Penyalur"),
    // organization_type: yup.string().required().label("Jenis badan usaha"),
    total_member: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Anggota"),
    total_branch: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Cabang"),
    total_employee: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Pegawai"),
    organization_file: yup.string().required().label("Struktur organisasi"),
    finance_file: yup
      .string()
      .required()
      .label("Informasi Keuangan 3 Tahun Terakhir"),
    documents: yup.array(
      yup.object({
        file: yup.string().required().label("Dokumen Legalitas"),
      })
    ),
  }),
});

export const firmaScheme = yup.object({
  agency: yup.object({
    name: yup.string().required().label("Nama Lembaga"),
    distributor_institution: yup.string().required().label("Jenis Lembaga"),
    npwp: yup.string().required().label("NPWP"),
    npwp_file: yup.string().required().label("Dokumen NPWP"),
    province_id: yup.string().required().label("Provinsi"),
    city_id: yup.string().required().label("Kota / Kabupaten"),
    district_id: yup.string().required().label("Kecamatan"),
    village_id: yup.string().required().label("Kelurahan / Desa"),
    zip_code_id: yup.string().required().label("Kode Pos"),
    address: yup.string().required().label("Alamat"),
    email: yup.string().required().label("Email"),
  }),
  profile: yup.object({
    business_type: yup
      .string()
      .required()
      .label("Jenis Usaha dan/atau Jenis Layanan"),
    contact_person_name: yup.string().required().label("Nama Narahubung"),
    contact_person_position: yup.string().required().label("Jabatan"),
    contact_person_email: yup.string().required().label("Email Narahubung"),
    contact_person_phone: yup.string().required().label("Telepon"),
    contact_person_identity_number: yup.string().required().label("No KTP"),
    contact_person_identity_number_file: yup.string().required().label("KTP"),
    contact_person_npwp: yup.string().required().label("No NPWP"),
    contact_person_npwp_file: yup.string().required().label("NPWP File"),
    background_file: yup
      .string()
      .required()
      .label("Upload Surat Menjadi Lembaga Penyalur"),
    organization_type: yup.string().required().label("Jenis badan usaha"),
    total_member: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Anggota"),
    total_branch: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Cabang"),
    total_employee: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .required()
      .label("Jumlah Pegawai"),
    organization_file: yup.string().required().label("Struktur organisasi"),
    finance_file: yup
      .string()
      .required()
      .label("Informasi Keuangan 3 Tahun Terakhir"),
    documents: yup.array(
      yup.object({
        file: yup.string().required().label("Dokumen Legalitas"),
      })
    ),
  }),
});
