import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BottomFormAction, Button, InputForm, Spinner } from "components";
import AsyncDropzoneFieldForm from "components/molecules/async-dropzone-field-form";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import InputCurrencyForm from "components/V2/shared/input-currency-form";
import { map } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { RiAddLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import {
  getDisbursementDetail,
  postDisbursement,
} from "services/fdb/insitution_disbursement";
import * as yup from "yup";

const validationSchema = yup.object({
  debtor_contract: yup.string().required().label("Akad Debitur"),
  collaterals: yup.array(
    yup.object({
      type: yup.string().required().label("Jenis Jaminan"),
      value: yup.string().required().label("Nilai Jaminan"),
      file: yup.string().required().label("Dokumen"),
    })
  ),
});

export default function DisbursementRequestForm(props) {
  const { group, type } = props;
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      collaterals: [{ type: "", value: undefined, file: undefined }],
    },
  });

  const navigate = useNavigate();
  const params = useParams();

  const queryClient = useQueryClient();
  const mutatePostDisbursement = useMutation({ mutationFn: postDisbursement });
  const { data, isLoading } = useQuery(
    ["GET_DISBURSEMENT_DETAIL", params?.id],
    getDisbursementDetail,
    {
      enabled: !!params.id,
      select: (data) => data?.data?.data,
    }
  );

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: "collaterals",
  });

  const onSubmit = (data) => {
    mutatePostDisbursement.mutate(
      { id: params?.id, data: data },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil submit pencairan",
          });
          queryClient.invalidateQueries();
          navigate(-1);
        },
        onError: (res) => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal submit pencairan",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      methods.reset({
        debtor_contract: data?.debtor_contract,
        collaterals: data?.collaterals,
      });
    }
  }, [data, methods]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-36 z-[99]">
        <BreadCrumbs
          routes={[
            {
              label: "Permohonan Pencairan",
              href: `/permohonan-pencairan/${group}/${type}`,
            },
            {
              label: "Buat Permohonan",
              href: `/permohonan-pencairan/${group}/${type}/buat-permohonan`,
            },
          ]}
        />
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Buat Permohonan Pencairan</h1>
        </div>

        <FormProvider {...methods}>
          <div className="space-y-10">
            <CardForm label="Permohonan">
              <div className="space-y-6">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                  <div className="space-y-1">
                    <InputCurrencyForm
                      name=""
                      label="Jumlah Pencairan"
                      placeholder="Jumlah Pencairan"
                      required
                      defaultValue={data?.application?.value}
                      prefix="Rp "
                      disabled
                    />
                  </div>
                  <div className="space-y-1">
                    <InputForm
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label="Tahap Pencairan"
                      controllerName=""
                      disabled
                      defaultValue={`Tahap ${data?.application?.phase}`}
                      required
                    />
                  </div>
                  <div className="space-y-1">
                    <InputCurrencyForm
                      name=""
                      label="Nilai Tahap Pencairan Sekarang"
                      placeholder="Nilai Tahap Pencairan Sekarang"
                      required
                      defaultValue={data?.application?.value_this_phase}
                      prefix="Rp "
                      disabled
                    />
                  </div>
                  <div className="space-y-1">
                    <InputCurrencyForm
                      name=""
                      label="Sisa Plafon"
                      placeholder="Sisa Plafon"
                      required
                      defaultValue={data?.application?.ceiling_remaining}
                      prefix="Rp "
                      disabled
                    />
                  </div>
                </div>
              </div>
            </CardForm>

            <CardForm label="Akad Debitur">
              <div className="space-y-6">
                <AsyncDropzoneFieldForm
                  informationText="PNG, JPG, PDF, DOCX"
                  name="debtor_contract"
                  maxSize={10}
                  maxFiles={1}
                />
              </div>
            </CardForm>

            <CardForm label="Jaminan Tambahan (Jika Kurang)">
              <div className="space-y-6">
                {map(fields, (field, index) => (
                  <div key={field.id} className="space-y-6">
                    {index > 0 && <div className="border-t" />}
                    {index > 0 && (
                      <div className="flex items-center justify-end mx-4">
                        <Button
                          type="button"
                          onClick={() => remove(index)}
                          className="p-3 rounded-lg bg-error-500 flex-none h-12 w-12 hover:bg-error-600 text-lg"
                          label={<BiTrash className="text-white" />}
                        />
                      </div>
                    )}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
                      <InputForm
                        className={`py-2 px-4 mt-2 border w-full rounded-md`}
                        label="Jenis Jaminan"
                        controllerName={`collaterals.${index}.type`}
                        required
                      />
                      <InputCurrencyForm
                        name={`collaterals.${index}.value`}
                        label="Nilai Jaminan"
                        required
                        prefix="Rp "
                      />
                    </div>

                    <div className="space-y-10">
                      <AsyncDropzoneFieldForm
                        maxFiles={1}
                        informationText="PNG, JPG, PDF, DOCX"
                        name={`collaterals.${index}.file`}
                        maxSize={10}
                      />
                    </div>
                  </div>
                ))}
                <div className="border-t" />
                <div className="flex flex-row-reverse my-5 mx-4">
                  <Button
                    className="p-4 border rounded-lg bg-white"
                    onClick={() =>
                      append({
                        id: null,
                        type: "",
                        value: undefined,
                        file: undefined,
                      })
                    }
                    label={
                      <div className="flex items-center gap-2 font-[14px]">
                        <RiAddLine />
                        Tambah Jaminan
                      </div>
                    }
                  />
                </div>
              </div>
            </CardForm>
          </div>
          <BottomFormAction
            lastStep={true}
            hideDraft
            backButtonProps={{
              type: "button",
            }}
            draftButtonProps={{
              type: "button",
            }}
            submitButtonProps={{
              type: "submit",
            }}
            submitActionButton={methods.handleSubmit(onSubmit)}
            // submitActionButton={onFormSubmit}
            // backButtonAction={onBack}
          />
        </FormProvider>
      </div>
    </div>
  );
}
