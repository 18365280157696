import axiosInstance from "app/interceptors";
import axios from "axios";

export const getCountryDDL = (params) => {
  return axiosInstance.get(`api/master/v1/Region/negara`, {
    params,
  });
};

export const getProvinceDDL = (params) => {
  // TODO: Replace this with actual API
  return axiosInstance.get(`https://api.goapi.id/v1/regional/provinsi`, {
    baseURL: "",
    params: {
      api_key: "BnRMZAesSCYpcR0cGku737PRC94jEV",
      ...params,
    },
  });
};

export const getCityDDL = (provinceId) => {
  // TODO: Replace this with actual API
  return axiosInstance.get(`https://api.goapi.id/v1/regional/kota`, {
    baseURL: "",
    params: {
      api_key: "BnRMZAesSCYpcR0cGku737PRC94jEV",
      provinsi_id: provinceId || undefined,
    },
  });
};

export const getDistrictDDL = (cityId) => {
  // TODO: Replace this with actual API
  return axiosInstance.get(`https://api.goapi.id/v1/regional/kecamatan`, {
    baseURL: "",
    params: {
      api_key: "BnRMZAesSCYpcR0cGku737PRC94jEV",
      kota_id: cityId,
    },
  });
};

export const getSubdistrictDDL = (districtId) => {
  // TODO: Replace this with actual API
  return axiosInstance.get(`https://api.goapi.id/v1/regional/kelurahan`, {
    baseURL: "",
    params: {
      api_key: "BnRMZAesSCYpcR0cGku737PRC94jEV",
      kecamatan_id: districtId,
    },
  });
};

export const getSkemaPembiayaanDDL = () => {
  return axiosInstance.get("/api/master/v1/SkemaPembiayaan/list");
};

export const getJenisUsahaDDL = () => {
  return axiosInstance.get("/api/master/v1/JenisUsaha/list");
};

export const getFungsiKawasanSosialDDL = () => {
  return axiosInstance.get("/api/master/v1/FungsiKawasanSosial/list");
};

export const getStatusPendampingDDL = () => {
  return axiosInstance.get("/api/master/v1/StatusPendamping/list");
};

export const getKomoditasUsahaDDL = () => {
  return axiosInstance.get("/api/master/v1/KomoditasUsaha/list");
};

export const getSumberBahanProduksiDDL = () => {
  return axiosInstance.get("/api/master/v1/SumberBahanProduksi/list");
};

export const getSelfAssessmentCategories = () => {
  return axiosInstance.get(`/api/master/v1/SelfAssesment/list`);
};
export const getSelfAssessmentCategoryItems = (categoryId) => {
  return axiosInstance.get(`/api/master/v1/SelfAssesment/${categoryId}`);
};
