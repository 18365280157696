// import { InputForm } from "components";
// import AtomSelect from "components/atoms/Select";
// import CardForm from "components/molecules/CardForm";

// const FieldLabel = {
//   APPLICATION_FEE: "Nominal Permohonan",
//   APPLICATION_FEE_SPELL: "Terbilang",
//   YEAR_DURATION: "Jangka Waktu (Tahun)",
//   FINANCE_SCHEME: "Skema Pembiayaan",
// };

// const ApplicationDataSection = ({ mode }) => {
//   const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
//     (e) => e === mode
//   );
//   return (
//     <CardForm label="Data Permohonan">
//       <div className="grid grid-cols-2 gap-y-5 gap-x-6">
//         <InputForm
//           controllerName="application_fee"
//           className="py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case"
//           wrapperClass="col-span-2 lg:col-span-1"
//           label={FieldLabel.APPLICATION_FEE}
//           disabled
//           readOnly
//           asText={isView}
//           required
//         />

//         <InputForm
//           controllerName="application_fee_spel"
//           className="py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case"
//           wrapperClass="col-span-2 lg:col-span-1"
//           label={FieldLabel.APPLICATION_FEE_SPELL}
//           placeholder={FieldLabel.APPLICATION_FEE_SPELL}
//           disabled
//           readOnly
//           asText={isView}
//           required
//         />

//         <InputForm
//           controllerName="year_duration"
//           className="py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case"
//           wrapperClass="col-span-2 lg:col-span-1"
//           label={FieldLabel.YEAR_DURATION}
//           placeholder={FieldLabel.YEAR_DURATION}
//           mask={"####"}
//           asText={isView}
//           required
//         />

//         <AtomSelect
//           controllerName="finance_scheme"
//           className="basic-single mt-2 flex-1"
//           wrapperClass="col-span-1"
//           label={FieldLabel.FINANCE_SCHEME}
//           placeholder={FieldLabel.FINANCE_SCHEME}
//           returnObject={false}
//           options={[
//             { value: "Revolving", label: "Revolving" },
//             { value: "Non Revolving", label: "Non Revolving" },
//           ]}
//           required
//           asText={isView}
//         />
//       </div>
//     </CardForm>
//   );
// };

// export default ApplicationDataSection;

import angkaTerbilang from "@develoka/angka-terbilang-js";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { InputForm } from "../../../../../components";
import AtomSelect from "../../../../../components/atoms/Select";
import CardForm from "../../../../../components/molecules/CardForm";
import RichEditorForm from "../../../../../components/molecules/RichEditorForm";
import InputCurrency from "../../../../../components/organisms/inputs/InputCurrency";
import useTimeout from "../../../../../helpers/useTimeout";
import { isRTEEmpty } from "../../../../../helpers/validation";
import { validationErrorMsg } from "../../../../../locale/yup_id";
import { convertCurrencyToFloat } from "helpers";
import { toString } from "lodash";
import InputCurrencyForm from "components/V2/shared/input-currency-form";

export const FieldLabel = {
  APPLICATION_FEE: "Nominal Permohonan",
  AMOUNT_WORD: "Terbilang",
  YEAR_DURATION: "Jangka Waktu (Tahun)",
  FINANCE_SCHEME: "Skema Pembiayaan",
  GUARANTEE_PLAN: "Rencana Jaminan",
};

export const validationSchema = Yup.object().shape({
  application_fee: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.APPLICATION_FEE),
  amount_word: Yup.string().trim().required().label(FieldLabel.AMOUNT_WORD),
  year_duration: Yup.string().trim().required().label(FieldLabel.YEAR_DURATION),
  finance_scheme: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.FINANCE_SCHEME),
  guarantee_plan: Yup.string()
    .trim()
    .test("guarantee_plan", validationErrorMsg.mixed.required, isRTEEmpty)
    .required()
    .label(FieldLabel.GUARANTEE_PLAN),
});

function RequestSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();
  const application_fee = useWatch({
    control: methods.control,
    name: `${prefix}application_fee`,
  });

  // effects
  // update depending fields on row changes
  useTimeout(
    () => {
      const numToWords = (number) => {
        let normalizedNum = "";

        try {
          normalizedNum = convertCurrencyToFloat(number);
          normalizedNum = isNaN(normalizedNum) ? undefined : normalizedNum;
        } catch (err) {
          //stubbed
        }

        return normalizedNum ? angkaTerbilang(number) + " rupiah" : "";
      };

      const loanAmt = toString(application_fee);

      methods.setValue(`${prefix}amount_word`, numToWords(loanAmt));
    },
    400,
    [application_fee]
  );

  return (
    <CardForm label="Data Permohonan" {...rest}>
      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        {/* <InputForm
          controllerName={`${prefix}application_fee`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case`}
          wrapperClass={"col-span-2 lg:col-span-1"}
          label={FieldLabel.APPLICATION_FEE}
          disabled
          readOnly
          asText={isView}
        /> */}
        <InputCurrencyForm
          name={`${prefix}application_fee`}
          label={FieldLabel.APPLICATION_FEE}
          prefix="Rp "
          required
          asText={isView}
          disabled
        />

        <InputForm
          controllerName={`${prefix}application_fee_spel`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case`}
          wrapperClass={"col-span-2 lg:col-span-1"}
          label={FieldLabel.AMOUNT_WORD}
          placeholder={FieldLabel.AMOUNT_WORD}
          disabled
          readOnly
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}year_duration`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case`}
          wrapperClass={"col-span-2 lg:col-span-1"}
          label={FieldLabel.YEAR_DURATION}
          placeholder={FieldLabel.YEAR_DURATION}
          asText={isView}
        />

        <AtomSelect
          controllerName={`${prefix}finance_scheme`}
          className="basic-single mt-2 flex-1"
          wrapperClass={"col-span-1"}
          label={FieldLabel.FINANCE_SCHEME}
          placeholder={FieldLabel.FINANCE_SCHEME}
          returnObject={false}
          options={[
            { value: "Revolving", label: "Revolving" },
            { value: "Non Revolving", label: "Non Revolving" },
          ]}
          required
          asText={isView}
        />

        {/* <div className={"col-span-2"}>
          <RichEditorForm
            key={`guarantee_plan--${mode}`}
            name={`${prefix}guarantee_plan`}
            label={FieldLabel.GUARANTEE_PLAN}
            placeholder={isView ? "" : FieldLabel.GUARANTEE_PLAN}
            className={isView ? "" : "quill-4"}
            required
            readOnly={isView}
            hideToolbar={isView}
            theme={isView ? "" : "snow"}
          />
        </div> */}
      </div>
    </CardForm>
  );
}

RequestSection.propTypes = {};

export default RequestSection;
