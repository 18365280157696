import React, { useMemo } from "react";
import AtomSelect from "../Select";

function TablePageSizeField({
  wrapperClass='flex flex-wrap items-center gap-4',
  sizes = [5, 10, 25, 50, 100] }
) {
  const pageOpts = useMemo(() => {
    let normalizeSize = [5, 10, 25, 50, 100];
    if (Array.isArray(sizes) && sizes.length) {
      normalizeSize = sizes;
    }
    
    return normalizeSize?.map((e) => {
      return { value: e, label: e };
    });
  }, [sizes]);
  
  return (
    <div className={wrapperClass}>
      <span>Tampilkan Data</span>
      <AtomSelect
        controllerName={"pageSize"}
        wrapperClass={"!w-auto min-w-[100px]"}
        className="basic-single"
        options={pageOpts}
        returnObject={false}
      />
    </div>
  );
}

export default TablePageSizeField;