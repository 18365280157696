import _, { toString } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { InputForm } from "../../../../../components";
import GeotagLocationButton from "../../../../../components/atoms/Button/GeotagLocationButton";
import AtomDatePicker from "../../../../../components/atoms/Datepicker";
import CardForm from "../../../../../components/molecules/CardForm";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";

export const FieldLabel = {
  PROVINCE: "Provinsi",
  CITY: "Kota/Kabupaten",
  DISTRICT: "Kecamatan",
  SUBDISTRICT: "Kelurahan/Desa",
  ZIP_CODE: "Kode Pos",
  RT: "RT",
  RW: "RW",
  ADDRESS: "Alamat",
  GEOTAG: "Geotag Location",
  LAT: "Latitude",
  LNG: "Longitude",
  LIVE_SINCE: "Berdomisili Di Alamat Diatas Sejak Tahun",
};

export const validationSchema = Yup.object().shape({
  cid: Yup.object().shape({
    ktp_province_id: Yup.mixed().required().label(FieldLabel.PROVINCE),
    ktp_regency_id: Yup.mixed().required().label(FieldLabel.CITY),
    ktp_district_id: Yup.mixed().required().label(FieldLabel.DISTRICT),
    ktp_village_id: Yup.mixed().required().label(FieldLabel.SUBDISTRICT),
    ktp_rt: Yup.string().required().label(FieldLabel.RT),
    ktp_rw: Yup.string().required().label(FieldLabel.RW),
    ktp_address: Yup.string().trim().required().label(FieldLabel.ADDRESS),
    ktp_zip_code_id: Yup.string().required().label(FieldLabel.ZIP_CODE),
  }),
  living: Yup.object().shape({
    same_address: Yup.string().notRequired(),
    domicile_province_id: Yup.mixed().required().label(FieldLabel.PROVINCE),
    domicile_regency_id: Yup.mixed().required().label(FieldLabel.CITY),
    domicile_district_id: Yup.mixed().required().label(FieldLabel.DISTRICT),
    domicile_village_id: Yup.mixed().required().label(FieldLabel.SUBDISTRICT),
    domicile_rt: Yup.string().required().label(FieldLabel.RT),
    domicile_rw: Yup.string().required().label(FieldLabel.RW),
    domicile_address: Yup.string().trim().required().label(FieldLabel.ADDRESS),
    domicile_latitude: Yup.string().trim().required().label(FieldLabel.LAT),
    domicile_longitude: Yup.string().trim().required().label(FieldLabel.LNG),
    domicile_since_year: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.LIVE_SINCE),
    domicile_zip_code_id: Yup.string().required().label(FieldLabel.ZIP_CODE),
  }),
});

function AddressSection({ formPrefix, mode, data, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const [checked, setChecked] = useState();

  const methods = useFormContext();

  const cidProvinceId = useWatch({
    control: methods.control,
    name: `${prefix}cid.ktp_province_id`,
  });
  const cidCityId = useWatch({
    control: methods.control,
    name: `${prefix}cid.ktp_regency_id`,
  });
  const cidDistrictId = useWatch({
    control: methods.control,
    name: `${prefix}cid.ktp_district_id`,
  });
  const cidVillageId = useWatch({
    control: methods.control,
    name: `${prefix}cid.ktp_village_id`,
  });
  const zipField = useWatch({
    control: methods.control,
    name: `${prefix}cid.ktp_zip_code_id`,
  });
  const rtField = useWatch({
    control: methods.control,
    name: `${prefix}cid.ktp_rt`,
  });
  const rwField = useWatch({
    control: methods.control,
    name: `${prefix}cid.ktp_rw`,
  });
  const addressField = useWatch({
    control: methods.control,
    name: `${prefix}cid.ktp_address`,
  });

  const livingProvinceId = useWatch({
    control: methods.control,
    name: `${prefix}living.domicile_province_id`,
  });
  const livingCityId = useWatch({
    control: methods.control,
    name: `${prefix}living.domicile_regency_id`,
  });
  const livingDistrictId = useWatch({
    control: methods.control,
    name: `${prefix}living.domicile_district_id`,
  });
  const livingVillageId = useWatch({
    control: methods.control,
    name: `${prefix}living.domicile_village_id`,
  });

  const onGeotagClick = (key) => (value) => {
    const fieldPrefix = key ? (key?.endsWith(".") ? key : key + ".") : "";

    methods.setValue(
      `${fieldPrefix}domicile_latitude`,
      toString(value?.latitude)
    );
    methods.setValue(
      `${fieldPrefix}domicile_longitude`,
      toString(value?.longitude)
    );
  };

  const onSameAddressChange = (e) => {
    setChecked(e.target.value);
    const isChecked = e?.target?.checked;
    const { setValue, getValues } = methods || {};

    if (isChecked) {
      const values = getValues();
      const source = _.get(values, `${prefix}cid`);
      console.log("source", source?.ktp_province_id);
      setValue(`${prefix}living.domicile_province_id`, source?.ktp_province_id);
      setValue(`${prefix}living.domicile_regency_id`, source?.ktp_regency_id);
      setValue(`${prefix}living.domicile_district_id`, source?.ktp_district_id);
      setValue(`${prefix}living.domicile_village_id`, source?.ktp_village_id);
      setValue(`${prefix}living.domicile_zip_code_id`, source?.ktp_zip_code_id);
      setValue(`${prefix}living.domicile_address`, source?.ktp_address);
      setValue(`${prefix}living.domicile_rt`, source?.ktp_rt);
      setValue(`${prefix}living.domicile_rw`, source?.ktp_rw);

      methods.clearErrors(`${prefix}living`);
    }
  };

  useEffect(() => {
    if (checked) {
      methods.setValue(`${prefix}living.domicile_province_id`, cidProvinceId);
    }
  }, [cidProvinceId, methods, prefix, checked]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`${prefix}living.domicile_regency_id`, cidCityId);
    }
  }, [checked, cidCityId, methods, prefix]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`${prefix}living.domicile_district_id`, cidDistrictId);
    }
  }, [checked, cidDistrictId, methods, prefix]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`${prefix}living.domicile_village_id`, cidVillageId);
    }
  }, [checked, cidVillageId, methods, prefix]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`${prefix}living.domicile_zip_code_id`, zipField);
    }
  }, [checked, zipField, methods, prefix]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`${prefix}living.domicile_rt`, rtField);
    }
  }, [checked, methods, rtField, prefix]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`${prefix}living.domicile_rw`, rwField);
    }
  }, [checked, methods, rwField, prefix]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`${prefix}living.domicile_address`, addressField);
    }
  }, [checked, methods, addressField, prefix]);

  return (
    <CardForm label="Alamat" {...rest}>
      <div className={"font-lg font-semibold text-[#01A24A]"}>Alamat KTP</div>

      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.PROVINCE}
            </label>
            <p>{data?.province?.name}</p>
          </div>
        ) : (
          <SelectFDBProvince
            controllerName={`${prefix}cid.ktp_province_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.PROVINCE}
            placeholder={FieldLabel.PROVINCE}
            required={true}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">{FieldLabel.CITY}</label>
            <p>{data?.city?.name}</p>
          </div>
        ) : (
          <SelectFDBCity
            controllerName={`${prefix}cid.ktp_regency_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.CITY}
            placeholder={FieldLabel.CITY}
            required={true}
            provinceId={cidProvinceId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.DISTRICT}
            </label>
            <p>{data?.district?.name}</p>
          </div>
        ) : (
          <SelectFDBDistrict
            controllerName={`${prefix}cid.ktp_district_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.DISTRICT}
            placeholder={FieldLabel.DISTRICT}
            required={true}
            cityId={cidCityId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.SUBDISTRICT}
            </label>
            <p>{data?.district?.name}</p>
          </div>
        ) : (
          <SelectFDBVillage
            controllerName={`${prefix}cid.ktp_village_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.SUBDISTRICT}
            placeholder={FieldLabel.SUBDISTRICT}
            required={true}
            districtId={cidDistrictId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.ZIP_CODE}
            </label>
            <p>{data?.zip_code?.zip_code}</p>
          </div>
        ) : (
          <SelectFDBZipCode
            controllerName={`${prefix}cid.ktp_zip_code_id`}
            label={FieldLabel.ZIP_CODE}
            placeholder={FieldLabel.ZIP_CODE}
            required
            returnObject={false}
            villageId={cidVillageId}
            asText={isView}
          />
        )}

        <InputForm
          controllerName={`${prefix}cid.ktp_rt`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RT}
          placeholder={FieldLabel.RT}
          required
          asText={isView}
        />
        <InputForm
          controllerName={`${prefix}cid.ktp_rw`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RW}
          placeholder={FieldLabel.RW}
          required
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}cid.ktp_address`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.ADDRESS}
          placeholder={FieldLabel.ADDRESS}
          rows={4}
          textArea
          required
          asText={isView}
        />
      </div>

      <div className="border-b" />

      <div className={"flex flex-wrap items-center gap-4"}>
        <div className={"font-lg font-semibold text-[#01A24A]"}>
          Alamat Domisili
        </div>

        {!isView ? (
          <label
            className={"bg-[#EEFFF4] p-3 flex gap-3 items-start rounded-lg"}
          >
            <input
              className={"h-[1em] w-[1rem] my-[0.2em]"}
              type={"checkbox"}
              onChange={onSameAddressChange}
            />
            <span>Alamat Sama Dengan KTP</span>
          </label>
        ) : undefined}
      </div>

      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.PROVINCE}
            </label>
            <p>{data?.domicile_province?.name}</p>
          </div>
        ) : (
          <SelectFDBProvince
            controllerName={`${prefix}living.domicile_province_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.PROVINCE}
            placeholder={FieldLabel.PROVINCE}
            required={true}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">{FieldLabel.CITY}</label>
            <p>{data?.domicile_city?.name}</p>
          </div>
        ) : (
          <SelectFDBCity
            controllerName={`${prefix}living.domicile_regency_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.CITY}
            placeholder={FieldLabel.CITY}
            required={true}
            provinceId={livingProvinceId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.DISTRICT}
            </label>
            <p>{data?.domicile_district?.name}</p>
          </div>
        ) : (
          <SelectFDBDistrict
            controllerName={`${prefix}living.domicile_district_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.DISTRICT}
            placeholder={FieldLabel.DISTRICT}
            required={true}
            cityId={livingCityId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.SUBDISTRICT}
            </label>
            <p>{data?.domicile_village?.name}</p>
          </div>
        ) : (
          <SelectFDBVillage
            controllerName={`${prefix}living.domicile_village_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.SUBDISTRICT}
            placeholder={FieldLabel.SUBDISTRICT}
            required={true}
            districtId={livingDistrictId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.ZIP_CODE}
            </label>
            <p>{data?.domicile_zip_code?.zip_code}</p>
          </div>
        ) : (
          <SelectFDBZipCode
            controllerName={`${prefix}living.domicile_zip_code_id`}
            label={FieldLabel.ZIP_CODE}
            placeholder={FieldLabel.ZIP_CODE}
            required
            returnObject={false}
            villageId={livingVillageId}
            asText={isView}
          />
        )}

        <InputForm
          controllerName={`${prefix}living.domicile_rt`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RT}
          placeholder={FieldLabel.RT}
          required
          asText={isView}
        />
        <InputForm
          controllerName={`${prefix}living.domicile_rw`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RW}
          placeholder={FieldLabel.RW}
          required
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}living.domicile_address`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.ADDRESS}
          placeholder={FieldLabel.ADDRESS}
          rows={4}
          textArea
          required
          asText={isView}
        />

        {!isView ? (
          <GeotagLocationButton
            label={FieldLabel.GEOTAG}
            className={"w-full"}
            onSuccess={onGeotagClick(`${prefix}living`)}
          />
        ) : undefined}

        <InputForm
          controllerName={`${prefix}living.domicile_latitude`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.LAT}
          placeholder={FieldLabel.LAT}
          required
          disabled
          readOnly
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}living.domicile_longitude`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.LNG}
          placeholder={FieldLabel.LNG}
          required
          disabled
          readOnly
          asText={isView}
        />

        <AtomDatePicker
          controllerName={`${prefix}living.domicile_since_year`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.LIVE_SINCE}
          placeholder={FieldLabel.LIVE_SINCE}
          datepickerOptions={{
            dateFormat: "yyyy",
            maxDate: new Date(),
            showYearPicker: true,
          }}
          required
          showErrorLabel
          asText={isView}
        />
      </div>
    </CardForm>
  );
}

AddressSection.propTypes = {};

export default AddressSection;
