import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getOfferList = async ({ queryKey }) => {
  return await axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/agency-offer`, {
    params: queryKey[1],
  });
};

export const getOfferDetail = async ({ queryKey }) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/agency-offer/${queryKey[1]}`
  );
};

export const postOffer = async ({ id, data, params }) => {
  return await axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/agency-offer/${id}`,
    data,
    { params }
  );
};
