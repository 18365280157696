import MenuRegister from "components/molecules/MenuRegister";
import Header from "components/organisms/HeaderPortal";
import React from "react";

const Portal = () => {
  const menuList = [
    {
      title: "Daftar Sebagai Lemtara",
      description:
        "An all-in-one customer service platform that helps you balance everything your customers need to be happy.",
      link: "/register-dana-program",
      color: "#E0F2FE",
      borderIconColor: "#F0F9FF",
      iconColor: "#0086C9",
    },
    {
      title: "Daftar Sebagai Penerima Dana Hibah",
      description:
        "An all-in-one customer service platform that helps you balance everything your customers need to be happy.",
      link: "",
      color: "#EBE9FE",
      borderIconColor: "#F4F3FF",
      iconColor: "#6938EF",
    },
    {
      title: "Daftar Akun Kelompok",
      description:
        "An all-in-one customer service platform that helps you balance everything your customers need to be happy.",
      link: "/register-account-group",
      color: "#E3FBCC",
      borderIconColor: "#F3FEE7",
      iconColor: "#4CA30D",
    },
    {
      title: "Daftar Sebagai Debitur Perorangan",
      description:
        "An all-in-one customer service platform that helps you balance everything your customers need to be happy.",
      link: "",
      color: "#E0F2FE",
      borderIconColor: "#F0F9FF",
      iconColor: "#DD2590",
    },
  ];
  return (
    <div className="w-screen h-full">
      <Header />
      <div className="z-30 absolute px-[112px] mobile:px-4 py-[120px]">
        <h1 className="absolute h-11 tab:text-[26px] font-semibold text-[36px] tracking-tighter  text-white font-poppins">
          Selamat Datang di Portal BDPLH
        </h1>
        <div className="z-30 box-border mt-20 flex flex-col items-start p-10 w-full h-max bg-white border border-gray-200 shadow-md rounded-xl">
          <h1 className="z-30 font-semibold text-[24px] text-gray-900 pb-8">
            Pendaftaran
          </h1>
          <div className="grid grid-cols-2 gap-8 mobile:grid-cols-1 ">
            {menuList.map((menu, index) => (
              <MenuRegister
                key={index}
                title={menu.title}
                description={menu.description}
                color={menu.color}
                link={menu.link}
                borderIconColor={menu.borderIconColor}
                iconColor={menu.iconColor}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Portal;
