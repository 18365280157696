import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { MAX_DOC_UPLOAD } from "../../../../../app/config";
import { InputForm, InputFormUpload } from "../../../../../components";
import AtomDatePicker from "../../../../../components/atoms/Datepicker";
import FileInfoField from "../../../../../components/atoms/FileInfoField";
import CardForm from "../../../../../components/molecules/CardForm";
import InputKTP from "../../../../../components/organisms/inputs/InputKTP";
import { getErrorMessage, humanizeFileSize } from "../../../../../helpers";
import {
  isCitizenIdValid,
  isFileSize,
  isFileTypeMatches,
} from "../../../../../helpers/validation";
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from "../../../../../locale/yup_id";
import SelectGender from "components/organisms/inputs/SelectGender";
import SelectReligion from "components/organisms/inputs/SelectReligion";
import { useMutation } from "@tanstack/react-query";
import { postFileServices } from "services/fdb/akunKelompok";
import { enqueueSnackbar } from "notistack";
import { getFile } from "services/danaProgram/fileService";

export const FieldLabel = {
  CID: "Nomor NIK",
  CID_IMG: "Upload KTP",
  FULL_NAME: "Nama Lengkap Sesuai KTP",
  BIRTHDAY: "Tanggal Lahir Pasangan",
  BIRTHPLACE: "Tempat Lahir Pasangan",
  GENDER: "Jenis Kelamin Pasangan",
  RELIGION: "Agama Pasangan",
  PHONE: "Nomor Telepon Pasangan",
  JOB: "Pekerjaan Pasangan",
};
export const validationSchema = Yup.object().shape({
  couple_ktp: Yup.string()
    .trim()
    .test("couple_ktp", validationErrorMsg.mixed.default, isCitizenIdValid)
    .notRequired()
    .label(FieldLabel.CID),
  // couple_ktp_file: Yup.mixed()
  //   .test(
  //     "couple_ktp_file",
  //     validationErrorMsg.mixed.required,
  //     (value, context) => {
  //       const allowedTypes = MAX_DOC_UPLOAD;

  //       // if has value, validate the file
  //       if (value) {
  //         // validate file type by extension
  //         const v1 = isFileTypeMatches({ ext: allowedTypes })(value, context);
  //         if (!v1) {
  //           return context.createError({
  //             message: validationCustomErrorMsg.isFiletype(
  //               allowedTypes.join(", ")
  //             )({ label: FieldLabel.CID_IMG }),
  //           });
  //         }

  //         // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
  //         const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
  //         if (!v2) {
  //           return context.createError({
  //             message: validationCustomErrorMsg.isFileSize(
  //               humanizeFileSize(MAX_DOC_UPLOAD)
  //             )({
  //               label: FieldLabel.CID_IMG,
  //             }),
  //           });
  //         }

  //         return true;
  //       }

  //       // otherwise, return false to render the field required
  //       return true;
  //     }
  //   )
  //   .notRequired()
  //   .label(FieldLabel.CID_IMG),
  couple_name: Yup.string().trim().notRequired().label(FieldLabel.FULL_NAME),
  couple_place_of_birth: Yup.string()
    .trim()
    .notRequired()
    .label(FieldLabel.BIRTHPLACE),
  couple_date_of_birth: Yup.string()
    .trim()
    .notRequired()
    .label(FieldLabel.BIRTHDAY),
});

function PartnerSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [ktpImg, setKtpImg] = useState();
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();
  // const cidImgField = useWatch({
  //   control: methods.control,
  //   name: `${prefix}couple_ktp_file`,
  // });

  const cidImgField = methods.watch(`${prefix}couple_ktp_file`);

  const openDocs = (name) => {
    getDocsMutation.mutate({ payload: methods.getValues(name), type: "" });
  };

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "ktp") {
        setKtpImg(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      setKtpImg(res);
      methods.setValue(`${prefix}couple_ktp_file`, res.id);
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      // setDraftModal(false);
    },
  });

  useEffect(() => {
    if (cidImgField instanceof File) {
      postFile.mutate({ payload: cidImgField });
    } else if (typeof cidImgField === "string" && cidImgField !== "") {
      getDocsMutation.mutate({ payload: cidImgField, type: "ktp" });
    }
  }, [cidImgField]);

  return (
    <CardForm label="Data Pasangan" {...rest}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputKTP
          controllerName={`${prefix}couple_ktp`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.CID}
          placeholder={FieldLabel.CID}
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}couple_name`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.FULL_NAME}
          placeholder={FieldLabel.FULL_NAME}
          asText={isView}
        />

        <AtomDatePicker
          controllerName={`${prefix}couple_date_of_birth`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          datepickerOptions={{
            maxDate: new Date(),
            showMonthDropdown: true,
            showYearDropdown: true,
            scrollableYearDropdown: true,
            dropdownMode: "select",
          }}
          label={FieldLabel.BIRTHDAY}
          placeholder={FieldLabel.BIRTHDAY}
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}couple_place_of_birth`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.BIRTHPLACE}
          placeholder={FieldLabel.BIRTHPLACE}
          asText={isView}
        />
        <SelectGender
          controllerName={`${prefix}couple_gender`}
          label={FieldLabel.GENDER}
          wrapperClass={"col-span-1"}
          returnObject={false}
          menuPortalTarget={document.body}
          asText={isView}
        />
        <SelectReligion
          controllerName={`${prefix}couple_religion`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RELIGION}
          placeholder={FieldLabel.RELIGION}
          returnObject={false}
          asText={isView}
        />
        <InputForm
          controllerName={`${prefix}couple_phone_number`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.PHONE}
          placeholder={FieldLabel.PHONE}
          asText={isView}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
        <InputForm
          controllerName={`${prefix}couple_job`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.JOB}
          placeholder={FieldLabel.JOB}
          asText={isView}
        />

        <div className={"col-span-2"}>
          {cidImgField || isView ? (
            <>
              <label className="font-semibold text-sm my-1.5 block">
                {FieldLabel.CID_IMG}
              </label>
              {ktpImg ? (
                <FileInfoField
                  title={ktpImg ? ktpImg.name : ""}
                  desc={ktpImg ? `${ktpImg.size} KB` : "Tidak ada file"}
                  showIcon
                  showDownload={true}
                  showClose={true}
                  onCloseClick={() => {
                    methods.setValue(`${prefix}couple_ktp_file`, "");
                  }}
                  buttonLabel="Lihat Berkas"
                  onDownloadClick={() => {
                    openDocs(`${prefix}couple_ktp_file`);
                  }}
                />
              ) : null}
            </>
          ) : (
            <InputFormUpload
              controllerName={`${prefix}couple_ktp_file`}
              label={FieldLabel.CID_IMG}
            />
          )}
        </div>
      </div>
    </CardForm>
  );
}

PartnerSection.propTypes = {};

export default PartnerSection;
