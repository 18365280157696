/* eslint-disable import/no-anonymous-default-export */
import { lazy } from "react";
import AkunPeroranganRegisFormDashboard from "../pages/FDB/Perorangan/FormPendaftaran";
import AkunPeroranganNPSRegisForm from "../pages/FDB/Perorangan/FormPendaftaran/NonPerhutananSosial";
import AkunPeroranganPSRegisForm from "../pages/FDB/Perorangan/FormPendaftaran/PerhutananSosial";
import AkunPeroranganNPSPengajuanDaftarPermohonanPage from "../pages/FDB/Perorangan/PermohonanPembiayaan/NonPerhutananSosial";
import AkunPeroranganNPSPengajuanPermohonanForm from "../pages/FDB/Perorangan/PermohonanPembiayaan/NonPerhutananSosial/form";
import AkunPeroranganPSPengajuanDaftarPermohonanPage from "../pages/FDB/Perorangan/PermohonanPembiayaan/PerhutananSosial";
import AkunPeroranganSelfAssessment from "../pages/FDB/SelfAssessment";
import AkunPeroranganSelfAssessmentForm from "../pages/FDB/SelfAssessment/form";
import Penawaran from "pages/FDB/Perorangan/Penawaran";
import AkunKelompokPSPengajuanDaftarMemberForm from "pages/FDB/Kelompok/PengajuanDaftarPermohonan/PerhutananSosial/memberForm";
import Pencairan from "pages/FDB/Perorangan/pencairan";
import FormPencairan from "pages/FDB/Perorangan/pencairan/Form";

const generateRegisDashboardComp = (props) => () =>
  <AkunPeroranganRegisFormDashboard {...props} />;
const generatePSRegisFormComp = (props) => () =>
  <AkunPeroranganPSRegisForm {...props} />;
const generateNPSRegisFormComp = (props) => () =>
  <AkunPeroranganNPSRegisForm {...props} />;
const generatePSPengajuanPermohonanFormComp = (props) => () =>
  <AkunKelompokPSPengajuanDaftarMemberForm {...props} />;
const generateNPSPengajuanPermohonanFormComp = (props) => () =>
  <AkunPeroranganNPSPengajuanPermohonanForm {...props} />;

const PageType = {
  PS: "perhutanan-sosial",
  NPS: "non-perhutanan-sosial",
};
const FormMode = {
  EDIT: "edit",
  VIEW: "view",
  ADD: "add",
};

const InfoAkun = lazy(() => import("../pages/FDB/Perorangan/InfoAkun"));

const Perjanjian = lazy(() => import("../pages/FDB/Perorangan/Perjanjian"));

const generateInfoAkun = (props) => () => <InfoAkun {...props} />;

const generatePerjanjian = (props) => () => <Perjanjian {...props} />;

const generatePenawaran = (props) => () => <Penawaran {...props} />;

const generatePencairan = (props) => () => <Pencairan {...props} />;
const generateFormPencairan = (props) => () => <FormPencairan {...props} />;

export default [
  // FORMULIR PENDAFTARAN
  // =======================================================================

  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/info-akun",
    element: generateInfoAkun({ mode: PageType.PS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/info-akun",
    element: generateInfoAkun({ mode: PageType.NPS }),
    exact: true,
    sidebar: true,
  },

  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/formulir-pendaftaran",
    element: generateRegisDashboardComp({ type: PageType.PS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/formulir-pendaftaran",
    element: generateRegisDashboardComp({ type: PageType.NPS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/formulir-pendaftaran/edit",
    element: generatePSRegisFormComp({ mode: FormMode.EDIT }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/formulir-pendaftaran/edit/:memberId",
    element: generateNPSRegisFormComp({ mode: FormMode.EDIT }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/formulir-pendaftaran/view",
    element: generatePSRegisFormComp({ mode: FormMode.VIEW }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/formulir-pendaftaran/view/:memberId",
    element: generateNPSRegisFormComp({ mode: FormMode.VIEW }),
    exact: true,
    sidebar: false,
  },

  // PENGAJUAN DAFTAR PERMOHONAN
  // =======================================================================
  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/permohonan-pembiayaan",
    element: AkunPeroranganPSPengajuanDaftarPermohonanPage,
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/permohonan-pembiayaan",
    element: AkunPeroranganNPSPengajuanDaftarPermohonanPage,
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/permohonan-pembiayaan/edit/:id",
    element: generatePSPengajuanPermohonanFormComp({ mode: FormMode.EDIT }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/permohonan-pembiayaan/edit/:id",
    element: generateNPSPengajuanPermohonanFormComp({ mode: FormMode.EDIT }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/permohonan-pembiayaan/view/:id",
    element: generatePSPengajuanPermohonanFormComp({ mode: FormMode.VIEW }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/permohonan-pembiayaan/view/:id",
    element: generateNPSPengajuanPermohonanFormComp({ mode: FormMode.VIEW }),
    exact: true,
    sidebar: false,
  },

  // SELF-ASSESSMENT
  {
    routeType: "private",
    path: "/perorangan/self-assessment",
    element: AkunPeroranganSelfAssessment,
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/perorangan/self-assessment/start",
    element: AkunPeroranganSelfAssessmentForm,
    exact: true,
    sidebar: false,
  },

  // ? PERJANJIAN
  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/perjanjian",
    element: generatePerjanjian({ type: PageType.PS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/perjanjian",
    element: generatePerjanjian({ type: PageType.NPS }),
    exact: true,
    sidebar: true,
  },

  // ? PENAWARAN
  // ! PS
  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/penawaran",
    element: generatePenawaran({ type: PageType.PS }),
    exact: true,
    sidebar: true,
  },

  // ! NPS
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/penawaran",
    element: generatePenawaran({ type: PageType.NPS }),
    exact: true,
    sidebar: true,
  },

  // ? Pencairan
  // ! PS
  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/permohonan-pencairan",
    element: generatePencairan({ type: PageType.PS }),
    exact: true,
    sidebar: true,
  },

  // ! NPS
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/permohonan-pencairan",
    element: generatePencairan({ type: PageType.NPS }),
    exact: true,
    sidebar: true,
  },

  {
    routeType: "private",
    path: "/perorangan/perhutanan-sosial/permohonan-pencairan/:id",
    element: generateFormPencairan({ type: PageType.PS }),
    exact: true,
  },

  // ! NPS
  {
    routeType: "private",
    path: "/perorangan/non-perhutanan-sosial/permohonan-pencairan/:id",
    element: generateFormPencairan({ type: PageType.NPS }),
    exact: true,
  },
];
