import RegistrationForm from "pages/FDB/RegistrationForm"
import GeneralProfile from "pages/FDB/RegistrationForm/GeneralProfile"

const RegistrationFormComp = (props) => () => <RegistrationForm {...props} />
const GeneralProfileComp = (props) => () => <GeneralProfile {...props} />

const PageTypeLembaga = {
  bank_ventura: {
    group: "lembaga-penyalur",
    type: "bank-ventura"
  },
  koperasi: {
    group: "lembaga-penyalur",
    type: "koperasi"
  },
  non_koperasi: {
    group: "lembaga-penyalur",
    type: "non-koperasi"
  },
  badan_hukum: {
    group: "lembaga-penyalur",
    type: "badan-hukum"
  },
  bumdes: {
    group: "non-perorangan",
    type: "bumdes"
  },
  cv: {
    group: "non-perorangan",
    type: "cv"
  },
  pt: {
    group: "non-perorangan",
    type: "pt"
  },
  firma: {
    group: "non-perorangan",
    type: "firma"
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/formulir-pendaftaran/lembaga-penyalur/bank-ventura",
    element: RegistrationFormComp(PageTypeLembaga.bank_ventura),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/lembaga-penyalur/koperasi",
    element: RegistrationFormComp(PageTypeLembaga.koperasi),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/lembaga-penyalur/non-koperasi",
    element: RegistrationFormComp(PageTypeLembaga.non_koperasi),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/lembaga-penyalur/badan-hukum",
    element: RegistrationFormComp(PageTypeLembaga.badan_hukum),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/non-perorangan/bumdes",
    element: RegistrationFormComp(PageTypeLembaga.bumdes),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.bumdes,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/non-perorangan/cv",
    element: RegistrationFormComp(PageTypeLembaga.cv),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.cv,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/non-perorangan/pt",
    element: RegistrationFormComp(PageTypeLembaga.pt),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.pt,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/non-perorangan/firma",
    element: RegistrationFormComp(PageTypeLembaga.firma),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.firma,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/lembaga-penyalur/bank-ventura/profile-umum/",
    element: GeneralProfileComp(PageTypeLembaga.bank_ventura),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/lembaga-penyalur/koperasi/profile-umum/",
    element: GeneralProfileComp(PageTypeLembaga.koperasi),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/lembaga-penyalur/non-koperasi/profile-umum/",
    element: GeneralProfileComp(PageTypeLembaga.non_koperasi),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/lembaga-penyalur/badan-hukum/profile-umum/",
    element: GeneralProfileComp(PageTypeLembaga.badan_hukum),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/non-perorangan/bumdes/profile-umum/",
    element: GeneralProfileComp(PageTypeLembaga.bumdes),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.bumdes,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/non-perorangan/cv/profile-umum/",
    element: GeneralProfileComp(PageTypeLembaga.cv),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.cv,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/non-perorangan/pt/profile-umum/",
    element: GeneralProfileComp(PageTypeLembaga.pt),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.pt,
  },
  {
    routeType: "private",
    path: "/formulir-pendaftaran/non-perorangan/firma/profile-umum/",
    element: GeneralProfileComp(PageTypeLembaga.firma),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.firma,
  },
]