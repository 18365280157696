import React from "react";
import Icon from "../../../assets/icons";
import background from "../../../assets/backgrounds/Background.png";
import { Link } from "react-router-dom";

const HeaderNoAuth = ({ bg = true }) => {
  return (
    <nav
      className="w-screen h-auto"
      style={
        bg
          ? {
              zIndex: 99,
              background: `url(${background}) no-repeat`,
              backgroundSize: "100%",
            }
          : { zIndex: 99 }
      }
    >
      <div className="w-full h-[228px] min-h-[228px]">
        <div className="flex items-center justify-between w-full px-4 md:px-28 py-10">
          <div className="flex-1">
            <img className=" w-28 h-12" src={Icon.logo} />
          </div>
          <div className="flex flex-1 justify-end">
            <div className="dropdown dropdown-end">
              <div className="flex flex-end space-x-2 gap-20">
                <Link
                  to={
                    "https://identity.bpldh-dev.groot.id/account/LoginEndUser?type=Dana%20Program"
                  }
                >
                  <button className=" bg-[#E34F13] rounded-lg py-2 px-4 font-semibold text-white">
                    Login Akun
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block md:px-36 pb-10">
          <img className="w-40 h-20" src={Icon.logo} />
        </div>
      </div>
    </nav>
  );
};

export default HeaderNoAuth;
