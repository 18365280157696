import accountGroupSlice from "features/accountGroup/accountGroupSlice";
import profileSlice from "../features/auth/profileSlice";
import {
  profileUmumReducer,
  organizationProfileReducer,
  resourcesReducer,
  completedStatusReducer,
  financialProfileReducer,
  regionReducer,
  masterDataReducer,
  userReducer,
  authReducer,
  oidcReducer,
  cfpReducer,
} from "./reducers";
import authSlice from "redux/authSlice";
import oidcSlice from "redux/oidcSlice";

const rootReducer = {
  auth: authSlice,
  oidc: oidcSlice,
  profile: profileSlice,
  accountGroup: accountGroupSlice,
  draftDataGeneralProfile: profileUmumReducer,
  organizationProfileDraftData: organizationProfileReducer,
  resourcesDraftData: resourcesReducer,
  financialDraftData: financialProfileReducer,
  completedStatus: completedStatusReducer,
  regionDraftData: regionReducer,
  draftMasterData: masterDataReducer,
  userInformationData: userReducer,
  callForProposalData: cfpReducer,
};

export default rootReducer;
