import { validationCustomErrorMsg, validationErrorMsg } from "../locale/yup_id";

/**
 * Validate if tested value matches compared value
 *
 * @param targetPath Target field path to compare against. This must be given and will be used to
 * retrieve the compare value from Yup context
 * @param targetValue (optional) Target field value to compare against. If this value is defined explicitly, then the
 * validation will use this value to compare. if not defined, then validation will use the `targetPath` as fallback to
 * retrieve the compare value.
 *
 * @returns {(function(*, *): boolean)|*} returns Yup's TestFunction
 */
export const isMatchField = (targetPath, targetValue) => (value, context) => {
  let target = targetValue;

  if (target === undefined) {
    target = context?.parent?.[targetPath];
  }

  if (value) {
    return value === target;
  }

  // always return true when `value` is empty to allow other validation to be run.
  // This is important to match the behavior or Yup existing validators, where empty will only block validation when
  // used with specific schema (mixed, number) or with explicit validators (.required())
  return true;
};

export const isFileSize = (sizeInKB) => (value) => {
  if (value) {
    let result = true;

    const files = value?.length ? value : [value];
    for (const element of files) {
      result = result && element?.size <= sizeInKB;
    }
    return result;
  }

  return true;
};

export const isFileTypeMatches =
  ({ mime, ext }) =>
  (value) => {
    if (value) {
      try {
        const byMime = Boolean(
          typeof mime === "string" || (Array.isArray(mime) && mime.length)
        );
        const byExt = Boolean(
          typeof ext === "string" || (Array.isArray(ext) && ext.length)
        );

        let allowed = byExt ? ext : mime;
        allowed = typeof allowed === "string" ? [allowed] : allowed;

        const files = value?.length ? value : [value];
        let result = true;
        for (const element of files) {
          if (byMime && allowed.length) {
            result = result && allowed?.includes(element?.type);
          }

          if (byExt && allowed.length) {
            const fileExt = element?.name?.split(".")?.pop();
            result = result && allowed?.includes(fileExt);
          }
        }

        return result;
      } catch (err) {
        console.error("Validation isFileTypeMatches", { err });
        return false;
      }
    }

    return true;
  };

export const isCitizenIdValid = (value, context) => {
  if (value) {
    // replace all non number text
    const numberOnly = value?.replaceAll(/[^\d]/gi, "");

    if (value.length !== numberOnly.length) {
      return context.createError({
        message: validationErrorMsg.mixed.default,
      });
    }

    if (numberOnly.length !== 16) {
      return context.createError({
        message: validationErrorMsg.string.length,
        params: {
          length: 16,
        },
      });
    }
  }

  return true;
};

export const isTaxIdValid = (value, context) => {
  const regex = /^\d{2}\.\d{3}\.\d{3}\.\d\-\d{3}\.\d{3}$/i;

  if (value) {
    // replace all non number text
    const formatMatches = regex.test(value?.trim());

    if (!formatMatches) {
      return context.createError({
        message: validationErrorMsg.string.matches,
        params: {
          regex: "000000000000000",
        },
      });
    }
  }

  return true;
};

export const isPhoneValid = (value, context) => {
  if (value) {
    // Remove all non-digit characters
    const numberOnly = value.replace(/[^\d]/g, "");

    // Check if the phone number starts with "0" or "+62"
    if (!/^0|^(\+62)/.test(value)) {
      return context.createError({
        message: "telepon harus diawali dengan '0' atau '+62'",
      });
    }

    if (numberOnly.length < 12 || numberOnly.length > 13) {
      return context.createError({
        message: "telepon harus terdiri dari 12 dan 13 digit",
      });
    }
  }

  return true;
};

export const isPasswordValid = (value, context) => {
  const specialCharRegex = /[\W_]/g;
  const uppercaseRegex = /[A-Z]/g;
  const lowercaseRegex = /[a-z]/g;
  const numberRegex = /[\d]/g;

  if (value) {
    if (value.length < 8) {
      return context.createError({
        message: validationErrorMsg.string.min,
        params: {
          min: 8,
        },
      });
    }

    if (
      !specialCharRegex.test(value) ||
      !uppercaseRegex.test(value) ||
      !lowercaseRegex.test(value) ||
      !numberRegex.test(value)
    ) {
      return context.createError({
        message: validationErrorMsg.string.matches,
        params: {
          regex: "memiliki huruf besar, huruf kecil, angka dan simbol.",
        },
      });
    }
  }

  return true;
};

export const isRTEEmpty = (value) => {
  const haveImgTag = String(value).match(/(<img(([^>])+)>)/gi) || [];
  const textOnlyValue = String(value)
    .replace(/(<([^>]+)>)/gi, "")
    .replace("\\s+", " ")
    .trim();

  return haveImgTag?.length > 0 || textOnlyValue?.length > 0;
};

export const isNumberValid = (value, context) => {
  if (value) {
    const intValue = parseInt(value, 10);

    if (isNaN(intValue)) {
      return context.createError({
        message: validationErrorMsg.number.integer,
      });
    }
  }

  return true;
};
