import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { InputForm } from "../../../components";
import Checkbox from "../../../components/atoms/Checkbox";

export const FieldLabel = {
  CREATED_DATE: "Dibuat Pada Tanggal",
  CREATED_LOC: "Dibuat Pada (Tempat)",
};

const FieldPlaceholder = {
  CREATED_AT: "dd/mmm/yyyy",
  CREATED_LOC: "",
};

export const validationSchema = Yup.object().shape({
  agree: Yup.bool()
    .required("Anda harus menyetujui pernyataan ini.")
    .oneOf([true], "Anda harus menyetujui pernyataan ini.")
    .default(false),
  created_at: Yup.string().label(FieldLabel.CREATED_DATE),
  created_from: Yup.string().required("Tempat dibuat harus diisi"),
});

const AgreementSection = ({ formPrefix, message, mode }) => {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const formContext = useFormContext();

  useEffect(() => {
    const values = formContext.getValues();
    const source = _.get(values, prefix);

    formContext.setValue(
      `created_at_display`,
      dayjs(source?.created_at || new Date()).format("DD MMMM YYYY")
    );
  }, [formContext]);

  return (
    <>
      {!isView ? (
        <Checkbox
          controllerName={`agree.agree`}
          label={() => (
            <div>
              <p className={"font-medium"}>Saya Setuju</p>
              <p>
                {message ||
                  "Demikian permohonan, segala isi dan pernyataan yang terdapat pada formulir ini kami buat dengan sebenar-benarnya tanpa ada paksaan dari suatu apapun."}
              </p>
            </div>
          )}
          inputWrapperClass={
            "bg-[#EEFFF4] p-6 flex gap-4 items-start rounded-lg"
          }
        />
      ) : undefined}

      <div
        className={
          "bg-[#F9FCF6] p-6 grid grid-cols-1 md:grid-cols-2 gap-4 rounded-lg border"
        }
      >
        <div className={"col-span-1"}>
          <InputForm
            controllerName={`created_at_display`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.CREATED_DATE}
            placeholder={FieldPlaceholder.CREATED_DATE}
            disabled
            asText={isView}
          />
          <InputForm
            controllerName={`created_at`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 hidden`}
            type={"hidden"}
          />
        </div>
        <div className={"col-span-1"}>
          <InputForm
            controllerName={`agree.created_from`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.CREATED_LOC}
            placeholder={FieldPlaceholder.CREATED_LOC}
            asText={isView}
          />
        </div>
      </div>
    </>
  );
};

export { AgreementSection };
