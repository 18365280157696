import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

export const getRegisterForm = (userId) => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/perhutanan-sosial`,
    {
      headers: { Authorization: `Bearer ${userId}` },
    }
  );
};

export const putRegisterForm = (userId, payload) => {
  return axiosInstance.put(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/perhutanan-sosial`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${userId}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteActivity = (userId, itemId) => {
  return axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/delete-activity/${itemId}`,
    {
      headers: {
        Authorization: `Bearer ${userId}`,
      },
    }
  );
};

export const deletePartner = (userId, itemId) => {
  return axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/delete-partner/${itemId}`,
    {
      headers: {
        Authorization: `Bearer ${userId}`,
      },
    }
  );
};

export const deleteFinanceBusiness = (userId, itemId) => {
  return axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/delete-finance-business/${itemId}`,
    {
      headers: {
        Authorization: `Bearer ${userId}`,
      },
    }
  );
};

export const getFormulirPendaftaranNPS = () => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/perhutanan-sosial`
  );
};

export const getFormulirPendaftaran = () => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/perhutanan-sosial`
  );
};

export const submitFormulirPendaftaran = (data) => {
  return axiosInstance.put(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/perhutanan-sosial`,
    data
  );
};

export const submitFormulirPendaftaranNPS = (data) => {
  return axiosInstance.put(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/non-perhutanan-sosial`,
    data
  );
};

export const deleteActivityFormulirPendaftaran = (id) => {
  return axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/delete-activity/${id}`
  );
};

export const deletePartnerFormulirPendaftaran = (id) => {
  return axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/delete-partner/${id}`
  );
};

export const deleteFinanceBusinessFormulirPendaftaran = (id) => {
  return axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/delete-finance-business/${id}`
  );
};

export const deleteDocumentFormulirPendaftaran = (id) => {
  return axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/delete-document/${id}`
  );
};
