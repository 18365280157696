import * as yup from 'yup'

export const itSystemLembagaValidator = yup.object({
  institution_availability_file: yup.string().required().label(),
  oprationals: yup.array(
    yup.object({
      name: yup.string().required().label(),
      description: yup.string().required(),
      file: yup.string().required().label()
    })
  )
})

export const itSystemNonPeroranganValidator = yup.object({
  institution_availability_file: yup.string().required().label(),
})