import { HeaderLabel } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import FormNPSPendaftaranAnggota from "../../../components/NonPerhutananSosisal/FormPendaftaran";

const AkunKelompokNPSPengajuanDaftarMemberForm = () => {
  // const navigate = useNavigate();
  const location = useLocation();

  const userId = 1;

  const formRef = useRef();

  // get mode from path
  const splitPath = location?.pathname?.split("/").filter((e) => !!e);
  const mode = splitPath.at((userId ? splitPath.indexOf(userId) : 0) - 1);

  useEffect(() => {
    const layoutContentEl = document.getElementById("_layout-content");

    layoutContentEl?.classList?.add("overflow-hidden");

    return () => {
      layoutContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  const routes = useMemo(
    () => [
      {
        label: "Permohonan",
        path: "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan",
      },
      {
        label: "Daftar Permohonan",
        path: "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan",
      },
      {
        label: `Formulir Registrasi Anggota Kelompok`,
        path: location.pathname,
      },
    ],
    [location.pathname]
  );

  return (
    <>
      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text={`Formulir Registrasi Anggota Kelompok`}
          description="Buat permohonan baru."
        />

        <div className="border-b" />

        <FormNPSPendaftaranAnggota
          ref={formRef}
          mode={mode}
          // userId={userId}
          // onBack={onBack}
          // initialValues={infoInitVal}
          // onDraft={onSaveDraftClick}
          // onSubmit={onFormSubmit}
        />
      </div>
    </>
  );
};

export default AkunKelompokNPSPengajuanDaftarMemberForm;
