import axios from "axios";
import { config_master } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  accept: "application/json",
  "Content-Type": "multipart/form-data",
};

const secondHeaders = {
  accept: "application/json",
  "Content-Type": "application/json",
};

export const registerBeneficiaries = (params) => {
  return axiosInstance.post(
    `${process.env.REACT_APP_BASE_URL}/api/dana-program/v1/register/beneficiaries`,
    params,
    {
      headers
    }
  )
}