import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Button, HeaderNoAuth, Spinner } from "components";
import React, { useCallback, useMemo } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { getErrorMessage } from "../../helpers";
import {
  isCitizenIdValid,
  isMatchField,
  isPasswordValid,
  isPhoneValid,
} from "../../helpers/validation";
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from "../../locale/yup_id";
import AccountGroupInformation from "./accountGroupInformation";
import ContactPersonForm from "./contactPersonForm";
import GeneralInformationForm from "./generalInformationForm";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { registerAccGroup } from "services/fdb/akunKelompok";

const validationSchema = Yup.object().shape({
  general_information: Yup.object().shape({
    group_name: Yup.string()
      .trim()
      .required()
      .min(2)
      .max(50)
      .label("Nama Kelompok"),
    group_type: Yup.mixed().required().nonNullable().label("Jenis Kelompok"),
    assignment: Yup.string()
      .trim()
      .required()
      .min(2)
      .label(
        "Dasar persetujuan/penetapan/perjanjian akses kawasan/SK Pembentukan atau berita acara pembentukan"
      ),
    province: Yup.mixed().required().nonNullable().label("Provinsi"),
    city: Yup.mixed().required().nonNullable().label("Kota/Kabupaten"),
    district: Yup.mixed().required().nonNullable().label("Kecamatan"),
    village: Yup.mixed().required().nonNullable().label("Kelurahan"),
    zip_code: Yup.mixed().required().nonNullable().label("Kode Pos"),
    address: Yup.string()
      .required()
      .nonNullable()
      .max(250)
      .label("Alamat Lengkap"),
  }),
  contact_person: Yup.object().shape({
    name: Yup.string().trim().required().label("Nama Narahubung"),
    position: Yup.string().trim().required().label("Jabatan"),
    ktp: Yup.string()
      .trim()
      .test(
        "no_ktp",
        ({ label }) =>
          validationErrorMsg.mixed.default.replace("${path}", label),
        isCitizenIdValid
      )
      .required()
      .label("NIK Narahubung"),
    phone_number: Yup.string()
      .trim()
      .test(
        "contact_person.phone_number",
        ({ label }) =>
          validationErrorMsg.mixed.default.replace("${path}", label),
        isPhoneValid
      )
      .required()
      .label("Telepon"),
    email: Yup.string().trim().email().required().label("Email Narahubung"),
  }),
  account_group_information: Yup.object().shape({
    email: Yup.string().trim().email().required().label("Email"),
    password: Yup.string()
      .test("password", validationErrorMsg.mixed.required, isPasswordValid)
      .required()
      .label("Kata Sandi"),
    confirm_password: Yup.string()
      .when(["password"], ([password]) => {
        if (password) {
          return Yup.string()
            .test(
              "confpass",
              validationCustomErrorMsg.matchesField("Kata Sandi"),
              isMatchField("password")
            )
            .required()
            .label("Konfirmasi Kata Sandi");
        }

        return Yup.string().required().label("Konfirmasi Kata Sandi");
      })
      .required()
      .label("Konfirmasi Kata Sandi"),
  }),
});

const RegisterAccountGroup = () => {
  const navigate = useNavigate();
  const defaultValues = useMemo(() => {
    return {
      general_information: {
        group_name: "",
        group_type: null,
        assignment: "",
        province: null,
        city: null,
        district: null,
        village: null,
        address: "",
      },
      contact_person: {
        name: "",
        position: "",
        ktp: "",
        phone_number: "",
        email: "",
      },
      account_group_information: {
        email: "",
        password: "",
        confirm_password: "",
      },
    };
  }, []);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const provinceId = useWatch({
    control: methods.control,
    name: "general_information.province",
  });
  const cityId = useWatch({
    control: methods.control,
    name: "general_information.city",
  });
  const districtId = useWatch({
    control: methods.control,
    name: "general_information.district",
  });
  const villageId = useWatch({
    control: methods.control,
    name: "general_information.village",
  });
  const onError = useCallback((error) => {
    enqueueSnackbar({
      message: getErrorMessage(error),
      variant: "error",
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar({
      message: "Data berhasil disimpan",
      variant: "success",
    });
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/account/LoginEndUser?type=Fasilitas%20Dana%20Bergulir%20(FDB)`;
  }, []);

  const mutation = useMutation({
    mutationFn: (params) => {
      return registerAccGroup(params);
    },
    onSuccess,
    onError,
  });

  const onSubmit = (payload) => {
    const form = new FormData();
    form.append("name", payload?.general_information?.group_name ?? "");
    form.append(
      "group_type",
      payload?.general_information?.group_type?.value ?? ""
    );
    form.append("sk", payload?.general_information?.assignment ?? "");
    form.append("province_id", payload?.general_information?.province ?? "");
    form.append("city_id", payload?.general_information?.city ?? "");
    form.append("district_id", payload?.general_information?.district ?? "");
    form.append("village_id", payload?.general_information?.village ?? "");
    form.append("zip_code_id", payload?.general_information?.zip_code ?? "");
    form.append("address", payload?.general_information?.address ?? "");
    form.append("contact_person_name", payload?.contact_person?.name ?? "");
    form.append(
      "contact_person_position",
      payload?.contact_person?.position ?? ""
    );
    form.append("contact_person_identity", payload?.contact_person?.ktp ?? "");
    form.append(
      "contact_person_phone",
      payload?.contact_person?.phone_number ?? ""
    );
    form.append("contact_person_email", payload?.contact_person?.email ?? "");
    form.append("email", payload?.account_group_information?.email ?? "");
    form.append("password", payload?.account_group_information?.password ?? "");
    form.append(
      "password_confirmation",
      payload?.account_group_information?.confirm_password ?? ""
    );
    mutation.mutate(form);
  };

  return (
    <div className="w-full bg-[#FCFCFD] overflow-hidden">
      <HeaderNoAuth />
      <div className="px-10 md:px-36 py-20 flex flex-col">
        <div className="flex flex-col space-y-3 mb-4">
          <label className="font-semibold text-4xl">Daftar Akun Kelompok</label>
          <label className="text-gray-600">
            Silahkan lengkapi data-data di bawah ini untuk Anda bisa mendapatkan
            Akun.
          </label>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col divide-y">
              <GeneralInformationForm
                provinceId={provinceId}
                cityId={cityId}
                districtId={districtId}
                villageId={villageId}
              />
              <ContactPersonForm />
              <AccountGroupInformation />
            </div>
            <div className="border-t flex items-center justify-end space-x-4 pt-6">
              <Button
                className="text-gray-700 border border-gray-300 bg-white hover:bg-gray-300 rounded-lg"
                label={"Batal"}
                type={"reset"}
                onClick={() => navigate("/")}
              />
              <Button
                className="text-white border border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 rounded-lg"
                label={
                  <div className="flex items-center gap-2">
                    <p>Daftar Akun</p>
                    {mutation.isLoading && (
                      <Spinner className="text-white text-lg" />
                    )}
                  </div>
                }
                type={"submit"}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default RegisterAccountGroup;
