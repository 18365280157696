import { yupResolver } from "@hookform/resolvers/yup";
import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { BottomFormAction, ConfirmationModal } from "../../../../../components";
import StepProgress from "../../../../../components/molecules/StepProgress";
import CollateralSection, {
  validationSchema as CollateralValSchema,
} from "../FormPembiayaan/collateralSection";
import DocsSection, { requiredDocs } from "./docsSection";
import ProjectSection, {
  validationSchema as ProjectValSchema,
} from "./projectSection";
import RequestSection, {
  validationSchema as RequestValSchema,
} from "./requestSection";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { useLocation, useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getDetailsApplicationFinancingComodities,
  sendApplicationFinancingCommodities,
} from "services/fdb/permohonan-pembiayaan";
import { createPortal } from "react-dom";
import { AgreementSection } from "../FormPembiayaan/agreementSection";

const TabItems = {
  INFO: 1,
  LEGAL_DOCS: 2,
};

const TabSteps = [
  {
    label: "Data Usaha & Jaminan",
    step: TabItems.INFO,
  },
  {
    label: "Dokumen Penguasaan Lahan",
    step: TabItems.LEGAL_DOCS,
  },
];

const schemaStep1 = Yup.object().shape({
  // * Request Section

  application_fee: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .notRequired()
    .label("Nominal Permohonan"),
  application_fee_spel: Yup.string()
    .nullable()
    .notRequired()
    .label("Terbilang"),
  year_duration: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .notRequired()
    .label("Jangka Waktu (Tahun)"),
  finance_scheme: Yup.string().required().label("Skema Pembiayaan"),

  // * Project Section

  bussiness_type: Yup.string().required().label("Jenis Usaha Non Kehutanan"),
  bussiness_commodities: Yup.string().required().label("Komoditas Usaha"),
  bussiness_duration: Yup.number().required().label("Lama Usaha"),
  bussiness_duration_type: Yup.string().notRequired().label("Lama Usaha"),
  material: Yup.string()
    .notRequired()
    .label("Produktivitas/Hasil Panen Terakhir"),
  capacity_productivity: Yup.string()
    .required()
    .label("Kapasitas Usaha/Produksi"),
  last_price: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required()
    .label("Harga Jual Terakhir Per KG/Liter"),
  market_target: Yup.string().required().label("Tujuan Pemasaran"),
  bussiness_partner: Yup.array()
    .of(
      Yup.object({
        value: Yup.string(),
      })
    )
    .nullable()
    .label("Mitra Usaha (Jika Ada)"),
  purpose_loan_application: Yup.string()
    .required()
    .label("Tujuan Pengajuan Pinjaman"),
  purpose_loan_application_desc: Yup.string()
    .required()
    .label("Jelaskan Tujuan Pengajuan Pinjaman Anda"),
  revenue: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required()
    .label("Perkiraan omzet/harga jual/pendapatan kotor"),
  cost_price: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required()
    .label("Perkiraan Biaya Produksi / HPP / Modal Yang Telah Dikeluarkan"),
  net_income: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required()
    .label("Perkiraan pendapatan bersih"),
  bussiness_cycle: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required()
    .label("Siklus Usaha Produksi"),
  bussiness_cycle_unit: Yup.string().required().label("Siklus Usaha Produksi"),
  income_statement_file: Yup.string().required().label("Laporan Laba Rugi"),

  // * Collateral Section
  tally_sheet_file: Yup.string().required().label("Upload Data Jaminan"),
  tally_sheet_url: Yup.string().label(),
  created_at_place: Yup.string().nullable().label(),
  colleterals: Yup.array().of(
    Yup.object({
      id: Yup.string().nullable(),
      collateral_type: Yup.string()
        .required()
        .nullable()
        .label("Bentuk Jaminan"),
      collateral_fee: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .required()
        .label("Kuantitas komoditas yang sudah ada"),
      collateral_file: Yup.string().nullable().required().label("File Jaminan"),
    })
  ),
});

const schemaStep2 = Yup.object().shape({
  document: Yup.array().of(
    Yup.object({
      id: Yup.string().nullable(),
      name: Yup.string().nullable(),
      desc: Yup.string().nullable(),
      file: Yup.string().required().nullable(),
    })
  ),
  created_at_place: Yup.string().required().label("Dibuat Pada (Tempat)"),
  agree: Yup.bool()
    .required("Anda harus menyetujui pernyataan ini.")
    .oneOf([true], "Anda harus menyetujui pernyataan ini."),
});

function FormKomoditasNonKehutanan({ mode }) {
  const docsRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const [activeStep, setActiveStep] = useState(1);
  const [draftModal, setDraftModal] = useState();
  const [confirmModal, setConfirmModal] = useState(false);

  const methods = useForm({
    resolver:
      activeStep === 1 ? yupResolver(schemaStep1) : yupResolver(schemaStep2),
    defaultValues: {
      application_fee: state?.value,
      application_fee_spel: `${angkaTerbilangJs(state?.value)} rupiah`,
      colleterals: state?.non_forest_commodity_id === null && [
        {
          id: undefined,
          collateral_type: "",
          collateral_fee: undefined,
          collateral_file: "",
        },
      ],
      bussiness_partner: state?.non_forest_commodity_id === null && [
        { value: "" },
      ],
      document: requiredDocs.map((data) => ({
        id: null,
        name: data.title,
        desc: data.desc,
        key: data.key,
        file: null,
        selected_file: undefined,
      })),
    },
  });

  useQuery(
    [
      "GET DETAIL APPLICATION FOR FINANCING COMMODITIES",
      state.non_forest_commodity_id,
    ],
    ({ queryKey }) => getDetailsApplicationFinancingComodities(queryKey[1]),
    {
      enabled: !!state.non_forest_commodity_id,
      onSuccess: (res) => {
        const { data } = res.data;

        const colleterals =
          data.colleterals.length > 0
            ? data?.colleterals.map((colleteral) => {
                return {
                  id: colleteral.id,
                  colleteral_id: colleteral.id,
                  collateral_type: colleteral.collateral_type,
                  collateral_fee: colleteral.collateral_fee,
                  collateral_file: colleteral.collateral_file,
                };
              })
            : [
                {
                  id: undefined,
                  collateral_type: undefined,
                  collateral_fee: undefined,
                  collateral_file: undefined,
                },
              ];

        const documents =
          res.data.data?.document.length > 0
            ? res.data.data.document
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                .map((doc) => ({
                  id: doc.id,
                  file: doc.file,
                  desc: doc.desc,
                  name: doc.name,
                }))
            : requiredDocs.map((data) => ({
                id: null,
                name: data.title,
                desc: data.desc,
                key: data.key,
                file: null,
              }));

        methods.reset({
          ...data,
          application_fee: data.application_fee ?? state.value,
          application_fee_spel: `${angkaTerbilangJs(
            data.application_fee ?? state.value
          )} rupiah`,
          colleterals: colleterals,
          document: documents,
          bussiness_partner:
            data?.bussiness_partner.length > 0
              ? data?.bussiness_partner?.map((e) => ({ value: e }))
              : [{ value: "" }],
        });
      },
    }
  );

  const mutateApplicationFinancingComodities = useMutation(
    ["PATCH APPLICATION FOR FINANCING COMMODITIES"],
    ({ data, params }) => {
      return sendApplicationFinancingCommodities(data, params);
    }
  );

  const onBackClick = () => {
    navigate(-1);
  };

  const onFormSubmit = (data) => {
    if (activeStep === 1) {
      setActiveStep(2);

      return;
    }

    setConfirmModal(true);
  };

  const handleDraftButton = () => {
    setDraftModal(true);
  };

  const handleSubmit = () => {
    const payload = methods.getValues();

    const mapPayload = {
      ...payload,
      bussiness_partner:
        payload?.bussiness_partner.length > 0
          ? payload?.bussiness_partner.map((data) => data.value)
          : [],
    };

    submitPayoad(mapPayload, false);
  };

  const handleSaveDraft = () => {
    const payload = methods.getValues();
    let { document } = payload;

    let docsTemp = [];
    document.map((doc) => {
      if (doc.file) docsTemp.push(doc);
    });

    const mapPayload = {
      ...payload,
      application_fee: state?.value,
      document: docsTemp,
      colleterals: payload?.colleterals.filter(
        (e) =>
          e.collateral_fee !== "" &&
          e.collateral_type !== "" &&
          e.collateral_file !== ""
      ),
      bussiness_partner:
        payload.bussiness_partner.length > 0
          ? payload?.bussiness_partner.map((data) => data.value)
          : [],
    };

    submitPayoad(mapPayload, true);
  };

  const submitPayoad = (mapPayload, is_draft) => {
    mutateApplicationFinancingComodities.mutateAsync(
      {
        data: mapPayload,
        params: {
          is_draft,
          non_forest_commodity_id: state.non_forest_commodity_id,
          // non_timber_forest_id: state.non_timber_forest_id,
          applicant_member_id: state.applicant_member_id,
          request_id: state.id,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            message: "Data Berhasil Disimpan!",
            variant: "success",
          });

          navigate(`/${location.pathname.split("/")[1]}/self-assessment/start`);
        },
        onError: (err) => {
          enqueueSnackbar({
            message: getErrorMessage(err),
            variant: "error",
          });

          window.scrollTo({ behavior: "smooth", top: 0 });
        },
      }
    );
  };

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onCancel={setDraftModal}
          onSubmit={handleSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onCancel={setConfirmModal}
          onSubmit={handleSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onFormSubmit)}
          className="space-y-8"
        >
          <div className="w-full flex justify-center">
            <div className="w-[800px] mb-10">
              <StepProgress
                stepProgressData={TabSteps}
                activeStep={activeStep}
              />
            </div>
          </div>

          <div className={"space-y-8"}>
            {activeStep === 1 && (
              <>
                <RequestSection mode={mode} />
                <ProjectSection mode={mode} />
                <CollateralSection mode={mode} isUploading uploadProgress />
              </>
            )}

            {activeStep === 2 && (
              <DocsSection mode={mode} formPrefix={"docs"} ref={docsRef} />
            )}
          </div>

          {activeStep === 2 && (
            <AgreementSection
              mode={mode}
              formPrefix={"agree"}
              message={
                "Segala isi dan pernyataan yang terdapat pada formulir ini kami buat dengan sebenar-benarnya tanpa ada paksaan dari suatu apapun dan bertanggung jawab apabila data disebutkan diatas tidak benar adanya"
              }
            />
          )}

          <BottomFormAction
            lastStep={activeStep === TabItems.LEGAL_DOCS}
            backButtonProps={{
              type: "button",
            }}
            draftButtonProps={{
              type: "button",
            }}
            submitButtonProps={{
              type: "submit",
            }}
            backButtonAction={onBackClick}
            hideDraft={isView}
            hideSubmit={activeStep === TabItems.LEGAL_DOCS && isView}
            disableDrafButton={false}
            drafButtonAction={handleDraftButton}
            disableButtonSubmit={false}
          />
        </form>
      </FormProvider>
    </>
  );
}

FormKomoditasNonKehutanan.propTypes = {};

export default FormKomoditasNonKehutanan;
