import React, { Fragment, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

import BreadCrumbs from 'components/molecules/Breadcrumbs';
import Stepper from 'pages/Report/Components/Stepper';

import ActivityCost from './ActivityCost';
import ResultActivity from './ResultActivity';

const links = [
  {
    label: 'Laporan',
  },
  {
    label: 'Laporan Kegiatan',
  },
  {
    label: 'Buat Baru',
  },
];

const stepperItems = [
  {
    label: 'Hasil Pelaksanaan Kegiatan',
  },
  {
    label: 'Realisasi Biaya',
  },
];

const ReportActivityForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [payload, setPayload] = useState({});
  const [activityResultValues, setActivityResultValues] = useState({});
  const [costRealizationValues, setCostRealizationValues] = useState({});

  const onBack = useCallback(
    (data) => {
      if (currentStep > 0) {
        setCostRealizationValues((curr) => ({
          ...curr,
          ...data,
        }));
        setCurrentStep(0);
      }
    },
    [currentStep]
  );

  const onSubmit = useCallback(
    (data) => {
      if (currentStep < 1) {
        setPayload((curr) => ({
          ...curr,
          ...data,
        }));
        setActivityResultValues((curr) => ({
          ...curr,
          ...data,
        }));
        setCurrentStep(1);
      } else {
        if (!isEmpty(payload)) {
          const dataToSend = {
            ...payload,
            ...data,
          };

          console.log('data to send ', dataToSend);
        }
      }
    },
    [currentStep, payload]
  );

  const activityResultDefaultValues = useMemo(
    () => ({
      main_activity: null,
      status: 1,
      activity_goals: [],
      partners: [],
      involvement: {
        male: null,
        female: null,
      },
      safeguards: [],
      activity_result: {
        activity_result: '',
        attachment: [],
      },
      ...activityResultValues,
      start_date: activityResultValues?.start_date
        ? moment(activityResultValues?.start_date).toDate()
        : null,
      end_date: activityResultValues?.end_date
        ? moment(activityResultValues?.end_date).toDate()
        : null,
    }),
    [activityResultValues]
  );

  const costRealizationDefaultValues = useMemo(
    () => ({
      detail_activity: [
        {
          name: '',
          implementation_date: '',
          realized: 0,
        },
      ],
      document: [],
      ...costRealizationValues,
    }),
    [costRealizationValues]
  );

  return (
    <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
      <div className="flex flex-col gap-4">
        <BreadCrumbs routes={links} />
        <hr />
        <div className="w-full mt-6 mb-6 flex justify-center items-center">
          <Stepper currentStep={currentStep} items={stepperItems} />
        </div>
        {currentStep < 1 ? (
          <ResultActivity
            defaultValues={activityResultDefaultValues}
            onNext={onSubmit}
          />
        ) : (
          <ActivityCost
            onBack={onBack}
            onNext={onSubmit}
            defaultValues={costRealizationDefaultValues}
            payload={payload}
          />
        )}
      </div>
    </div>
  );
};

export default ReportActivityForm;
