/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { MAX_DOC_UPLOAD } from "../../../../../app/config";
import { InputForm, InputFormUpload } from "../../../../../components";
import AtomDatePicker from "../../../../../components/atoms/Datepicker";
import FileInfoField from "../../../../../components/atoms/FileInfoField";
import CardForm from "../../../../../components/molecules/CardForm";
import { getErrorMessage, humanizeFileSize } from "../../../../../helpers";
import {
  isCitizenIdValid,
  isFileSize,
  isFileTypeMatches,
} from "../../../../../helpers/validation";
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from "../../../../../locale/yup_id";
import SelectGender from "components/organisms/inputs/SelectGender";
import { useMutation } from "@tanstack/react-query";
import { postFileServices } from "services/fdb/akunKelompok";
import { enqueueSnackbar } from "notistack";
import { getFile } from "services/danaProgram/fileService";

export const FieldLabel = {
  CID: "NIK",
  CID_IMG: "KTP",
  FULL_NAME: "Nama Lengkap Sesuai KTP",
  BIRTHPLACE: "Tempat Lahir",
  BIRTHDAY: "Tanggal Lahir",
  FAMILY_REG_NO: "No Kartu Keluarga",
  FAMILY_REG_IMG: "Kartu Keluarga",
  MAIN_JOB: "Pekerjaan Utama",
  OTHER_JOB: "Pekerjaan/Usaha Lainnya",
  GENDER: "Jenis Kelamin Debitur",
  NPWP: "Nomor NPWP Debitur",
  PHONE: "Nomor Telepon Debitur",
};

export const validationSchema = Yup.object().shape({
  identity_number: Yup.string()
    .trim()
    .test("cid", validationErrorMsg.mixed.default, isCitizenIdValid)
    .notRequired()
    .label(FieldLabel.CID),
  identity_file: Yup.mixed()
    .test("cid_img", validationErrorMsg.mixed.required, (value, context) => {
      const allowedTypes = MAX_DOC_UPLOAD;

      // if has value, validate the file
      if (value) {
        // validate file type by extension
        const v1 = isFileTypeMatches({ ext: allowedTypes })(value, context);
        if (!v1) {
          return context.createError({
            message: validationCustomErrorMsg.isFiletype(
              allowedTypes.join(", ")
            )({ label: FieldLabel.CID_IMG }),
          });
        }

        // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
        const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
        if (!v2) {
          return context.createError({
            message: validationCustomErrorMsg.isFileSize(
              humanizeFileSize(MAX_DOC_UPLOAD)
            )({
              label: FieldLabel.CID_IMG,
            }),
          });
        }

        return true;
      }

      // otherwise, return false to render the field required
      return false;
    })
    .notRequired()
    .label(FieldLabel.CID_IMG),
  name: Yup.string().trim().required().label(FieldLabel.FULL_NAME),
  gender: Yup.string().trim().required().label(FieldLabel.GENDER),
  phone: Yup.string().trim().required().label(FieldLabel.PHONE),
  npwp: Yup.string().trim().required().label(FieldLabel.NPWP),
  place_of_birth: Yup.string().trim().required().label(FieldLabel.BIRTHPLACE),
  date_of_birth: Yup.string().trim().required().label(FieldLabel.BIRTHDAY),
  family_card_number: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.FAMILY_REG_NO),
  family_card_file: Yup.mixed()
    .test(
      "family_reg_img",
      validationErrorMsg.mixed.required,
      (value, context) => {
        const allowedTypes = MAX_DOC_UPLOAD;

        // if has value, validate the file
        if (value) {
          // validate file type by extension
          const v1 = isFileTypeMatches({ ext: allowedTypes })(value, context);
          if (!v1) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype(
                allowedTypes.join(", ")
              )({ label: FieldLabel.CID_IMG }),
            });
          }

          // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
          const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
          if (!v2) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize(
                humanizeFileSize(MAX_DOC_UPLOAD)
              )({
                label: FieldLabel.CID_IMG,
              }),
            });
          }

          return true;
        }

        // otherwise, return false to render the field required
        return false;
      }
    )
    .notRequired()
    .label(FieldLabel.FAMILY_REG_IMG),
  main_job: Yup.string().trim().required().label(FieldLabel.MAIN_JOB),
  other_job: Yup.string().trim().required().label(FieldLabel.OTHER_JOB),
});

function DebiturSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";

  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const [cidFile, setCidFile] = useState();
  const [familyRegFile, setFamilyRegFile] = useState();

  const methods = useFormContext();

  const cidField = methods.watch("identity_file");
  const familyRegField = methods.watch("family_card_file");

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "identity_file") {
        cidFile(res);
      } else if (type === "family_card_file") {
        setFamilyRegFile(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      if (type === "identity_file") {
        methods.setValue(`identity_file`, res.id);
        setCidFile(res);
      } else if (type === "family_card_file") {
        methods.setValue(`family_card_file`, res.id);
        setFamilyRegFile(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {},
  });

  useEffect(() => {
    if (cidField instanceof File) {
      postFile.mutate({ payload: cidField, type: "identity_file" });
    } else if (typeof cidField === "string" && cidField !== "") {
      getDocsMutation.mutate({ payload: cidField, type: "identity_file" });
    }
  }, [cidField]);

  useEffect(() => {
    if (familyRegField instanceof File) {
      postFile.mutate({ payload: familyRegField, type: "family_card_file" });
    } else if (typeof familyRegField === "string" && familyRegField !== "") {
      getDocsMutation.mutate({
        payload: familyRegField,
        type: "family_card_file",
      });
    }
  }, [familyRegField]);

  return (
    <CardForm label="Data Calon Debitur" {...rest}>
      <div className="lg:grid lg:grid-cols-2 gap-4 space-y-4 lg:space-y-0">
        <InputForm
          controllerName={`identity_number`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          label={FieldLabel.CID}
          placeholder={FieldLabel.CID}
          required
          readOnly={isView}
          asText={isView}
        />

        <div className={"col-span-1"}>
          {cidField || isView ? (
            <div>
              <label className="font-semibold text-sm my-1.5 block">
                {FieldLabel.CID_IMG}
              </label>
              <FileInfoField
                title={cidFile ? cidFile.name : ""}
                desc={cidFile ? `${cidFile.size} KB` : "Tidak ada file"}
                showIcon
                showDownload={true}
                showClose={true}
                onCloseClick={() => {
                  methods.setValue("identity_file", "");
                }}
                buttonLabel="Lihat Berkas"
                onDownloadClick={() => {
                  window.open(methods.getValues("identity_file"), "_blank");
                }}
              />
            </div>
          ) : (
            <InputFormUpload
              controllerName={`identity_file`}
              label={FieldLabel.CID_IMG}
              required
              readOnly={isView}
            />
          )}
        </div>

        <InputForm
          controllerName={`name`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={FieldLabel.FULL_NAME}
          placeholder={FieldLabel.FULL_NAME}
          required
          readOnly={isView}
          asText={isView}
        />

        <SelectGender
          controllerName={`gender`}
          label={FieldLabel.GENDER}
          wrapperClass={"col-span-1"}
          returnObject={false}
          menuPortalTarget={document.body}
          asText={isView}
        />
        <InputForm
          controllerName={`phone`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={FieldLabel.PHONE}
          placeholder={FieldLabel.PHONE}
          required
          readOnly={isView}
          asText={isView}
        />
        <InputForm
          controllerName={`npwp`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={FieldLabel.NPWP}
          placeholder={FieldLabel.NPWP}
          required
          readOnly={isView}
          asText={isView}
        />

        <InputForm
          controllerName={`place_of_birth`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.BIRTHPLACE}
          placeholder={FieldLabel.BIRTHPLACE}
          required
          readOnly={isView}
          asText={isView}
        />

        <AtomDatePicker
          controllerName={`date_of_birth`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.BIRTHDAY}
          placeholder={FieldLabel.BIRTHDAY}
          datepickerOptions={{ maxDate: new Date() }}
          required
          showErrorLabel
          asText={isView}
        />

        <InputForm
          controllerName={`family_card_number`}
          className={`py-2 px-4 mt-2 border w-full rounded-md col-span-1`}
          label={FieldLabel.FAMILY_REG_NO}
          placeholder={FieldLabel.FAMILY_REG_NO}
          required
          readOnly={isView}
          asText={isView}
        />

        <div className={"col-span-1"}>
          {familyRegField || isView ? (
            <>
              <label className="font-semibold text-sm my-1.5 block">
                {FieldLabel.FAMILY_REG_IMG}
              </label>
              <FileInfoField
                title={familyRegFile ? familyRegFile.name : ""}
                desc={
                  familyRegFile ? `${familyRegFile.size} KB` : "Tidak ada file"
                }
                showIcon
                showDownload={true}
                showClose={true}
                onCloseClick={() => {
                  methods.setValue("family_card_file", "");
                }}
                buttonLabel="Lihat Berkas"
                onDownloadClick={() => {
                  window.open(methods.getValues("family_card_file"), "_blank");
                }}
              />
            </>
          ) : (
            <InputFormUpload
              controllerName={`family_card_file`}
              label={FieldLabel.FAMILY_REG_IMG}
              required
            />
          )}
        </div>

        <InputForm
          controllerName={`main_job`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.MAIN_JOB}
          placeholder={FieldLabel.MAIN_JOB}
          required
          readOnly={isView}
          asText={isView}
        />

        <InputForm
          controllerName={`other_job`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.OTHER_JOB}
          placeholder={FieldLabel.OTHER_JOB}
          required
          readOnly={isView}
          asText={isView}
        />
      </div>
    </CardForm>
  );
}

DebiturSection.propTypes = {};

export default DebiturSection;
