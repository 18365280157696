import React from "react";
import { formatCurrency } from "../../../../helpers";
import { InputForm } from "../../../index";

const InputCurrency = React.forwardRef((props, ref) => {
  const { className, ...restProps } = props;

  return (
    <InputForm
      ref={ref}
      placeholder={"Rp"}
      className={[
        `py-2 px-4 mt-2 border w-full rounded-md flex-1`,
        className,
      ].join(" ")}
      mask="0,99"
      data-maska-tokens="0:\d:multiple|9:\d:optional"
      maskaInputOptions={{
        preProcess: (val) => val.replace(/[Rp\s?\.]/g, ""),
        postProcess: (val) => {
          if (!val) return "";

          const numericString = val.replace(/[\,]/g, ".");
          const sub =
            3 -
            (numericString.includes(".")
              ? numericString.length - numericString.indexOf(".")
              : 0);

          return formatCurrency(numericString).slice(0, sub ? -sub : undefined);
        },
      }}
      {...restProps}
    />
  );
});

InputCurrency.propTypes = {};

export default InputCurrency;
