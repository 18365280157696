import { ConfirmationModal, HeaderLabel, StepProgress } from 'components';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import LoanApplication from './loanApplication';
import SdmIT from './sdmIT';
import ApplicationProvider from './applicationProvider';
import ApplicationSupport from './applicationSupport';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getChannelingDirectApplicationITSystem,
  updateChannelingDirectApplicationITSystem,
  updateDraftChannelingDirectApplicationITSystem,
} from 'services';
import { useAuth } from 'hooks/auth';

const ITSystemForm = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const { id } = useAuth();

  const [activeStep, setActiveStep] = useState(1);
  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  const { data: itSystemData } = useQuery(
    ['supplier-application-it-system'],
    async () => (await getChannelingDirectApplicationITSystem(id)).data
  );

  useEffect(() => {
    if (!itSystemData) return;

    const loan = itSystemData.data.find((item) => item.question_number === 1);
    const sdm = itSystemData.data.find((item) => item.question_number === 2);
    const app_provider = itSystemData.data.find(
      (item) => item.question_number === 3
    );
    const app_support = itSystemData.data.find(
      (item) => item.question_number === 4
    );

    setBodyToSend({
      loan: {
        using_app: loan?.answer === 'Ya',
        details: loan?.answer_detail[0],
      },
      sdm: {
        using_app: sdm?.answer === 'Ya',
        details: sdm?.answer_detail[0],
      },
      app_provider: {
        has_app_provider: app_provider?.answer === 'Ya',
        provider: app_provider?.answer_detail,
      },
      app_support: {
        has_app_support: app_support?.answer === 'Ya',
        support_app: app_support?.answer_detail,
      },
    });

    return;
  }, [itSystemData]);

  const breadCrumbs = [
    {
      label: 'Daftar Permohonan',
      path: '/bumdes',
    },
    {
      label: 'Daftar Permohonan Baru',
      path: '/bumdes/supplier-application',
    },
    {
      label: 'Sistem IT',
    },
  ];

  const stepProgress = useMemo(
    () => [
      {
        label: 'Software/Aplikasi',
        step: 1,
      },
      {
        label: 'SDM Yang Menangani IT',
        step: 2,
      },
      {
        label: 'Penyedia Software/Aplikasi',
        step: 3,
      },
      {
        label: 'Pendukung Software/Aplikasi',
        step: 4,
      },
    ],
    []
  );

  const onBack = useCallback(() => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, navigate]);

  const onDraft = useCallback((payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));
    setDraftModal(true);
  }, []);

  const onSubmit = useCallback(
    (payload) => {
      setBodyToSend((state) => ({ ...state, ...payload }));
      if (activeStep < stepProgress.length) {
        setActiveStep(activeStep + 1);
      } else {
        setConfirmModal(true);
      }
    },
    [activeStep, stepProgress]
  );

  const params = useMemo(() => {
    if (!bodyToSend) return {};

    const body = new FormData();
    body.append('questionDetail[0][question_number]', 1);
    body.append(
      'questionDetail[0][answer]',
      bodyToSend.loan?.using_app ? 'Ya' : 'Tidak'
    );
    body.append(
      'questionDetail[0][answer_detail][0]',
      bodyToSend.loan?.details
    );

    body.append('questionDetail[1][question_number]', 2);
    body.append(
      'questionDetail[1][answer]',
      bodyToSend.sdm?.using_app ? 'Ya' : 'Tidak'
    );
    body.append('questionDetail[1][answer_detail][0]', bodyToSend.sdm?.details);

    body.append('questionDetail[2][question_number]', 3);
    body.append(
      'questionDetail[2][answer]',
      bodyToSend.app_provider?.has_app_provider ? 'Ya' : 'Tidak'
    );
    bodyToSend.app_provider?.provider?.forEach((item, index) => {
      body.append(`questionDetail[2][answer_detail][${index}]`, item);
    });

    body.append('questionDetail[3][question_number]', 4);
    body.append(
      'questionDetail[3][answer]',
      bodyToSend.app_support?.has_app_support ? 'Ya' : 'Tidak'
    );
    bodyToSend.app_support?.support_app?.forEach((item, index) => {
      body.append(`questionDetail[3][answer_detail][${index}]`, item);
    });
    return body;
  }, [bodyToSend]);

  const onError = useCallback((error) => {
    enqueueSnackbar(getErrorMessage(error), {
      variant: 'error',
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar('Data Berhasil Disimpan!', {
      variant: 'success',
    });
    navigate('/bumdes/supplier-application');
  }, [navigate]);

  const { mutate: updateDebtor } = useMutation({
    mutationFn: () => {
      return updateChannelingDirectApplicationITSystem(id, params);
    },
    onError,
    onSuccess,
  });

  const { mutate: updateDraftDebtor } = useMutation({
    mutationFn: () => {
      return updateDraftChannelingDirectApplicationITSystem(id, params);
    },
    onError,
    onSuccess,
  });

  const onCreate = () => {
    updateDebtor();
  };

  const onCreateDraft = () => {
    updateDraftDebtor();
  };

  return (
    <div>
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onCreate}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        open={draftModal}
        onClose={setDraftModal}
        onSubmit={onCreateDraft}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan menyimpan data ini sebagai draft?
        </div>
        <div className="text-sm font-[400]">
          Setelah Anda menyimpan data sebagai draft anda masih bisa
          melanjutkannya.
        </div>
      </ConfirmationModal>

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-36 z-[99]">
        <BreadCrumbs routes={breadCrumbs} />

        <div className="flex">
          <HeaderLabel text="Sistem IT" />
        </div>

        <div className="border-b" />

        <div className="w-full flex justify-center">
          <div className="w-full mb-10">
            <StepProgress
              stepProgressData={stepProgress}
              activeStep={activeStep}
            />
          </div>
        </div>

        {/* <pre>{JSON.stringify(bodyToSend, null, 2)}</pre> */}

        {activeStep === 1 && (
          <LoanApplication
            ref={formRef}
            prefix={'loan'}
            data={bodyToSend?.loan}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
        {activeStep === 2 && (
          <SdmIT
            ref={formRef}
            prefix={'sdm'}
            data={bodyToSend?.sdm}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
        {activeStep === 3 && (
          <ApplicationProvider
            ref={formRef}
            prefix={'app_provider'}
            data={bodyToSend?.app_provider}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
        {activeStep === 4 && (
          <ApplicationSupport
            ref={formRef}
            prefix={'app_support'}
            data={bodyToSend?.app_support}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
      </div>
    </div>
  );
};

export default ITSystemForm;
