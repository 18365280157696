import { Button, InputForm } from "components";
import CardForm from "components/molecules/CardForm";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useLocation } from "react-router";
import { FieldLabel } from "../validation-schema";
import {
  RiCloseLine,
  RiDeleteBin6Line,
  RiFile2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { BiPlus } from "react-icons/bi";
import { useMutation } from "@tanstack/react-query";
import { postFileServices } from "services/fdb/akunKelompok";
import { useCallback, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getFile } from "services/danaProgram/fileService";
import InputCurrencyForm from "components/V2/shared/input-currency-form";

export const Jaminan = () => {
  const location = useLocation();
  const isView = location.pathname.includes("view");

  const methods = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: "business_colleterals",
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
  });

  const handleUpload = useCallback(
    (field, files, index) => {
      postFile.mutate(
        { payload: files[0] },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              message: "Dokumen Berhasil Disimpan!",
              variant: "success",
            });

            field.onChange(res.res);
          },
          onError: (err) => {
            enqueueSnackbar({
              message: "Dokumen Gagal Disimpan!",
              variant: "failed",
            });
          },
        }
      );
    },
    [postFile]
  );

  const handleMountingColletrals = useCallback(async () => {
    const colleterals = methods.watch("business_colleterals");

    if (!colleterals) return;
    if (colleterals.some((el) => typeof el.id === "undefined")) return;
    for (let i = 0; i < colleterals.length; i++) {
      if (
        colleterals[i].collateral_file === "" ||
        typeof colleterals[i].collateral_file === "undefined" ||
        colleterals[i].collateral_file === null
      ) {
        continue;
      }

      const res = await getFile(colleterals[i].collateral_file);

      methods.setValue(
        `business_colleterals[${i}].selected_file`,
        res.data.data
      );
    }
  }, [methods]);

  useEffect(() => {
    handleMountingColletrals();
  }, [handleMountingColletrals]);

  return (
    <CardForm label="Jaminan">
      <div className={"space-y-1"}>
        {fields.map((field, index) => {
          return (
            <>
              <div
                key={field.id}
                className={[
                  "col-span-1 md:grid md:grid-cols-2 gap-4 md:space-y-0 py-3",
                ]}
              >
                <div className="flex flex-col gap-2">
                  <label className="text-sm font-semibold rounded-md flex items-start gap-1">
                    {FieldLabel.COLLATERAL_NAME}
                    <span className="font-semibold text-red-600">*</span>
                  </label>
                  {isView ? (
                    <p>
                      {methods.getValues(
                        `business_colleterals[${index}].collateral_type`
                      )}
                    </p>
                  ) : (
                    <input
                      {...methods.register(
                        `business_colleterals[${index}].collateral_type`
                      )}
                      className="h-10 border border-gray-200 rounded-md px-3 outline-none"
                    />
                  )}
                </div>

                <div className="flex flex-col gap-2">
                  <InputCurrencyForm
                    name={`business_colleterals[${index}].collateral_fee`}
                    label={FieldLabel.COLLATERAL_VALUE}
                    required
                    asText={isView}
                  />
                  {/* {isView ? (
                    <p>
                      {methods.getValues(
                        `business_colleterals[${index}].collateral_fee`
                      )}
                    </p>
                  ) : (
                    <input
                      {...methods.register(
                        `business_colleterals[${index}].collateral_fee`
                      )}
                      className="h-10 border border-gray-200 rounded-md px-3 outline-none"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  )} */}
                </div>
              </div>

              <div className="col-span-2">
                <Controller
                  control={methods.control}
                  name={`business_colleterals[${index}].selected_file`}
                  render={({ field, fieldState }) => {
                    return (
                      <>
                        <div className="rounded-lg border border-gray-200 shadow-sm shadow-neutral-gray4/10 bg-white">
                          {typeof field.value === "object" ? (
                            <div className="h-[120px] rounded-lg border-gray-200 shadow-sm shadow-neutral-gray4/10 bg-white">
                              <div className="flex flex-col justify-center h-full p-4 gap-4">
                                <div className="flex justify-between">
                                  <div className="flex gap-4 items-center">
                                    <div className="p-2 rounded-full bg-primary-100 text-primary-600">
                                      <RiFile2Line />
                                    </div>
                                    <p className="text-sm font-medium">
                                      {field.value.name}
                                    </p>
                                  </div>
                                  {!isView ? (
                                    <RiCloseLine
                                      className="cursor-pointer"
                                      onClick={() => field.onChange("")}
                                    />
                                  ) : null}
                                </div>

                                <div>
                                  <a
                                    href={field.value.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-sm px-4 py-2 bg-primary-50 hover:bg-primary-100 text-primary-700 rounded-md"
                                  >
                                    Lihat Berkas
                                  </a>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <label
                              htmlFor="collateralfile"
                              className="h-[120px] w-full cursor-pointer flex flex-col gap-1 justify-center items-center"
                            >
                              <input
                                type="file"
                                name=""
                                id="collateralfile"
                                className="hidden"
                                onChange={(e) => {
                                  handleUpload(field, e.target.files, index);
                                }}
                              />
                              <RiUploadCloud2Line size={18} />
                              <div className="flex flex-col items-center">
                                <p className="text-sm font-medium text-primary-700">
                                  Upload File
                                </p>
                                <p className="text-xs font-light">
                                  PNG, JPG, PDF, Docx. Up To 10MB
                                </p>
                              </div>
                            </label>
                          )}
                        </div>
                        {fieldState.invalid && (
                          <p className="text-xs text-error-600 py-2">
                            {fieldState.error.message}
                          </p>
                        )}
                      </>
                    );
                  }}
                />
              </div>

              {index > 0 && (
                <div className="flex justify-end pt-3">
                  <Button
                    label={
                      <>
                        <RiDeleteBin6Line size={20} />
                        Hapus
                      </>
                    }
                    theme="error"
                    size="sm"
                    type="button"
                    className="text-white h-10"
                    // onClick={() =>
                    //   handleDeleteCollaterals(
                    //     methods.getValues(`business_colleterals[${index}]`),
                    //     index
                    //   )
                    // }
                  />
                </div>
              )}
            </>
          );
        })}
      </div>
      {!isView && (
        <div className="flex items-center justify-end">
          <Button
            type="button"
            onClick={() =>
              append({
                id: undefined,
                collateral_name: "",
                collateral_fee: 0,
                collateral_file: "",
              })
            }
            className="px-2 py-3 border rounded-lg bg-white w-fit h-fit"
            label={
              <div className="flex items-center gap-2 text-sm">
                <BiPlus />
                Tambah Jaminan
              </div>
            }
          />
        </div>
      )}
    </CardForm>
  );
};

// {fields.map((item, key) => (
//   <>
//     <div key={key} className="grid grid-cols-2 gap-3">
//       <InputForm
//         controllerName={`business_colleterals.${key}.type`}
//         className={`py-2 px-4 mt-2 border w-full rounded-md`}
//         wrapperClass={"col-span-1"}
//         label={FieldLabel.COLLATERAL_NAME}
//         placeholder={FieldLabel.COLLATERAL_NAME}
//         asText={isView}
//       />
//       <InputForm
//         controllerName={`business_colleterals.${key}.collateral_fee`}
//         className={`py-2 px-4 mt-2 border w-full rounded-md`}
//         wrapperClass={"col-span-1"}
//         label={FieldLabel.COLLATERAL_VALUE}
//         placeholder={FieldLabel.COLLATERAL_VALUE}
//         asText={isView}
//       />
//       <div className="col-span-2">
//         <Controller
//           control={methods.control}
//           name={`business_colleterals[${key}].collateral_file`}
//           render={({ field }) => {
//             return (
//               <div className="h-[120px] rounded-lg border border-gray-200 shadow-sm shadow-neutral-gray4/10 bg-white">
//                 {typeof field.value === "object" ? (
//                   <div className="h-[120px] rounded-lg border border-gray-200 shadow-sm shadow-neutral-gray4/10 bg-white">
//                     <div className="flex flex-col justify-center h-full p-4 gap-4">
//                       <div className="flex gap-4 items-center">
//                         <div className="p-2 rounded-full bg-primary-100 text-primary-600">
//                           <RiFile2Line />
//                         </div>
//                         <p className="text-sm font-medium">
//                           {field.value.name}
//                         </p>
//                       </div>
//                       {/* <Button
//                       label="Lihat berkas"
//                       className="w-max bg-gray-50 px-3 py-2 rounded-md text-sm font-medium"
//                     /> */}
//                     </div>
//                   </div>
//                 ) : (
//                   <label
//                     htmlFor="collateralfile"
//                     className="h-[120px] w-full cursor-pointer flex flex-col gap-1 justify-center items-center"
//                   >
//                     <input
//                       type="file"
//                       name=""
//                       id="collateralfile"
//                       className="hidden"
//                       onChange={(e) =>
//                         handleUpload(field, e.target.files)
//                       }
//                     />
//                     <RiUploadCloud2Line size={18} />
//                     <div className="flex flex-col items-center">
//                       <p className="text-sm font-medium text-primary-700">
//                         Upload File
//                       </p>
//                       <p className="text-xs font-light">
//                         PNG, JPG, PDF, Docx. Up To 100MB
//                       </p>
//                     </div>
//                   </label>
//                 )}
//               </div>
//             );
//           }}
//         />
//       </div>
//     </div>
//     <hr />
//   </>
// ))}
// <div className="flex items-center justify-end">
//   <Button
//     type="button"
//     onClick={() =>
//       append({
//         collateral_name: "",
//         collateral_fee: 0,
//         collateral_file: "",
//       })
//     }
//     className="px-2 py-3 border rounded-lg bg-white w-fit h-fit"
//     label={
//       <div className="flex items-center gap-2 text-sm">
//         <BiPlus />
//         Tambah Jaminan
//       </div>
//     }
//   />
// </div>
