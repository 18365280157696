import axios from "axios";
import { config_daprog } from "app/config";

export const registerAccountGroup = (params) => {
  return axios.post(
    `${config_daprog.BASE_URL}/v1/group-profile/register`,
    params
  );
};

export const registerAccountLemtara = (params) => {
  return axios.post(`${config_daprog.BASE_URL}/v1/register`, params);
};
