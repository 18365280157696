import { Button, InputForm } from "components";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";

const FixedAssetOtherAsset = ({
  nestIndex = 0,
  sumFixedAssetTotal = () => {},
  convertMoney = () => {},
  setValue = () => {},
  ...props
}) => {
  const { watch, getValue, getValues, control } = useFormContext();
  const {
    fields: financialReportFixedAssetOtherAssetFields,
    append: appendFinancialReportFixedAssetOtherAsset,
    remove: removeFinancialReportFixedAssetOtherAsset,
  } = useFieldArray({
    control,
    name: `financial_report.${nestIndex}.fixed_asset.other_asset`,
  });
  return (
    <>
      {financialReportFixedAssetOtherAssetFields.length > 0
        ? financialReportFixedAssetOtherAssetFields.map((data, index) => (
            <div className="flex gap-4 items-end  pt-2" key={data.id}>
              <div className="flex-1 pt-2">
                <InputForm
                  // controllerName={`otherFixedAsset.${index}.name`}
                  controllerName={`financial_report.${nestIndex}.fixed_asset.other_asset.${index}.name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"Nama Aktiva Lainnya"}
                  placeholder={"Aktiva"}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  // controllerName={`otherFixedAsset.${index}.value`}
                  controllerName={`financial_report.${nestIndex}.fixed_asset.other_asset.${index}.value`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"Nilai"}
                  placeholder={"0"}
                  // defaultValue={
                  //   otherFixedAssetCustom[index]?.value
                  // }
                  key={data.id}
                  showPrefix={true}
                  prefix={"Rp. "}
                  onChangeInput={(e) => {
                    setValue(
                      `financial_report.${nestIndex}.fixed_asset.other_asset.${index}.value`,
                      convertMoney(e.target.value)
                    );
                    sumFixedAssetTotal(nestIndex);
                  }}
                />
              </div>

              <button
                className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                onClick={() => removeFinancialReportFixedAssetOtherAsset(index)}
              >
                <RiDeleteBin6Line />
              </button>
            </div>
          ))
        : ""}
      <div className="border-b" />
      <div className="flex flex-row-reverse my-5 mx-4 ">
        <Button
          className="p-4 border rounded-lg"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah Aktiva Tetap Lain
            </div>
          }
          onClick={() => {
            // const prevArr = [...getValues("otherFixedAsset")];
            // prevArr.push({
            //   name: "",
            //   value: "",
            // });
            // setOtherFixedAssetCustom(prevArr);
            appendFinancialReportFixedAssetOtherAsset({
              name: "",
              value: "",
            });
          }}
        />
      </div>
    </>
  );
};

export default FixedAssetOtherAsset;
