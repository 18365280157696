import { useMutation, useQuery } from "@tanstack/react-query";
import { ConfirmationModal, HeaderLabel } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate, useParams } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  registerServiceForm,
  saveDraftService,
} from "services/fdb/akunKelompok";
import ProgressModal from "../../../../../components/molecules/Modal/ProgressModal";
import { getErrorMessage } from "../../../../../helpers";
import FormHHBK from "../../../components/NonPerhutananSosisal/FormHHBK";
import FormKomoditasNonKehutanan from "../../../components/NonPerhutananSosisal/FormKomoditasNonKehutanan";
import FormTundaTebang from "../../../components/NonPerhutananSosisal/FormTundaTebang";
import { ServiceType } from "../../../components/NonPerhutananSosisal/ServiceTypeModal";

const AkunPeroranganNPSPembiayaanForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const formType =
    new URLSearchParams(location?.search)?.get("type") ||
    ServiceType.TUNDA_TEBANG;
  const serviceType = [
    ServiceType.TUNDA_TEBANG,
    ServiceType.KNK,
    ServiceType.HBBK,
  ].map((e) => e?.toString() === formType);
  const [isTundaTebang, isKNK, isHBBK] = serviceType;
  const { id, type } = params || {};
  const formTitle = [
    "Tunda Tebang",
    "Komoditas Non Kehutanan",
    "Hasil Hutan Bukan Kayu",
  ]
    .filter((e, idx) => !!serviceType[idx])
    ?.at(0);

  // get mode from path
  const splitPath = location?.pathname?.split("/").filter((e) => !!e);
  const mode = splitPath.at((id ? splitPath.indexOf(id) : 0) - 1);
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  const { state } = useLocation();
  const { application_fee, request_id, applicant_member_id } = state;

  useEffect(() => {
    const layoutContentEl = document.getElementById("_layout-content");

    layoutContentEl?.classList?.add("overflow-hidden");

    return () => {
      layoutContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  const onBack = useCallback(() => {
    const target = location?.pathname
      ?.split("/")
      ?.filter((e) => !!e)
      ?.slice(0, -2)
      .join("/");
    navigate(`/${target}`);
  });

  const routes = useMemo(
    () => [
      {
        label: "Permohonan",
        path: "/perorangan/non-perhutanan-sosial/permohonan-pembiayaan",
      },
      {
        label: "Daftar Permohonan",
        path: "/perorangan/non-perhutanan-sosial/permohonan-pembiayaan",
      },
      {
        label: `Formulir Permohonan Layanan ${formTitle}`,
        path: location.pathname,
      },
    ],
    []
  );

  // api call
  const draftMutation = useMutation({
    mutationFn: (params) => {
      if (request_id !== id) {
        saveDraftService(true, applicant_member_id, request_id, id, params);
      } else {
        registerServiceForm(true, applicant_member_id, request_id);
      }
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      onBack();
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setDraftModal(false);
    },
  });

  const submitMutation = useMutation({
    mutationFn: (params) => {
      saveDraftService(false, applicant_member_id, request_id, id, bodyToSend);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      onBack();
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setConfirmModal(false);
    },
  });

  // methods
  const onFormSubmit = (lastStep, data) => {
    if (lastStep) {
      setConfirmModal(data);
    }
  };

  const onSaveDraftClick = (e) => {
    e?.preventDefault();

    setConfirmModal(false);
    setDraftModal(true);
  };

  const doSaveDraft = () => {
    draftMutation.mutate(bodyToSend);
  };

  const doSubmit = () => {
    submitMutation.mutate(bodyToSend);
  };

  const [searchParams] = useSearchParams();

  console.log(searchParams.get("type"), "SEARCH PARAMS");

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onClose={setDraftModal}
          onSubmit={doSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={doSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={
            (draftModal && draftMutation.isLoading) ||
            (confirmModal && submitMutation.isLoading)
          }
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text={`Formulir Permohonan Layanan ${formTitle}`}
          description="Buat permohonan baru."
        />

        <div className="border-b" />

        {isTundaTebang && (
          <FormTundaTebang
            onBack={onBack}
            onSubmit={onFormSubmit}
            onDraft={onSaveDraftClick}
            setBodyToSend={setBodyToSend}
            mode={mode}
          />
        )}

        {isKNK && (
          <FormKomoditasNonKehutanan
            onBack={onBack}
            onSubmit={onFormSubmit}
            onDraft={onSaveDraftClick}
            mode={mode}
          />
        )}

        {isHBBK && (
          <FormHHBK
            onBack={onBack}
            onSubmit={onFormSubmit}
            onDraft={onSaveDraftClick}
            mode={mode}
          />
        )}
      </div>
    </>
  );
};

export default AkunPeroranganNPSPembiayaanForm;
