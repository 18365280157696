import { useState } from "react";
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiDeleteBin6Line,
} from "react-icons/ri";

const CardForm = ({
  children,
  label = "",
  buttonCollapse = false,
  childerForm = false,
  deleteOption = false,
  customDeleteFunction = false,
  sideLabel = "",
  className = ""
}) => {
  const [formCollapse, setFormCollapse] = useState(true);

  const collapse = (data) => {
    setFormCollapse(data);
  };

  return (
    <>
      <div
        className={`flex rounded-lg ${
          childerForm
            ? ""
            : `bg-[#F9FCF6] border border-t-[#00BD52] border-t-8 ${className}`
        } `}
      >
        <div className={`flex flex-col w-full `}>
          <div
            className={`flex ${childerForm ? "bg-[#E1F1D6] rounded-lg" : ""}`}
          >
            <div className="flex-1 p-4 place-self-center">
              <span
                className={
                  childerForm ? "text-[#01A24A] font-bold" : "font-[700]"
                }
              >
                {label}
              </span>
              {sideLabel && (
                <span
                  className={
                    childerForm
                      ? "text-[#01A24A] ml-6 bg-[#ECFDF3] py-1 px-2 rounded-xl"
                      : "font-[700]"
                  }
                >
                  {sideLabel}
                </span>
              )}
            </div>

            {buttonCollapse ? (
              <div
                className={`flex-1 ${
                  deleteOption ? "flex" : ""
                } text-right p-2 place-self-center`}
              >
                {deleteOption ? (
                  <div className="flex-1 text-right p-1 mr-2">
                    <button
                      className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                      onClick={customDeleteFunction}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <button onClick={() => collapse(!formCollapse)}>
                  <span className="text-[#01A24A] flex items-center">
                    {formCollapse ? "Tutup" : "Buka"}
                    {formCollapse ? (
                      <RiArrowDropUpLine />
                    ) : (
                      <RiArrowDropDownLine />
                    )}
                  </span>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          {childerForm ? "" : <div className="border"></div>}
          <div className="p-4 flex flex-col gap-4">
            {buttonCollapse ? (formCollapse ? children : "") : children}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardForm;
