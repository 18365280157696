import { useEffect, useMemo, useState } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import {
  Button,
  CustomSelect,
  Input,
  InputForm,
  Spinner,
  Table,
} from "components";
import { RiCloseLine, RiEyeLine, RiFile3Line } from "react-icons/ri";
import dayjs from "dayjs";
import classNames from "classnames";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getDetailOffer,
  getListOffer,
  submitNegotiatonOffer,
} from "services/fdb/offer";
import { getFile } from "services/danaProgram/fileService";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import { negosiasiScheme } from "./validation-schema";
import { useLocation } from "react-router";
import AtomSelect from "components/atoms/Select";
import InputCurrencyForm from "components/V2/shared/input-currency-form";

const statusBadge = {
  "Menunggu Keputusan": "bg-[#FFFAEB] text-[#B54708]",
  "Disetujui Dengan Catatan": "bg-[#F0F9FF] text-[#026AA2]",
  Disetujui: "bg-[#ECFDF3] text-[#027A48]",
  Ditolak: "bg-[#FEF3F2] text-[#B42318]",
};

const Penawaran = (props) => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    paginate: true,
  });
  const [offers, setOffers] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedMode, setSelectedMode] = useState();

  // const paginator = useMemo(() => {
  //   return {
  //     page: page,
  //     limit: limit,
  //     total: listData?.meta?.total,
  //     previous_pages: listData?.meta?.previous_page_url,
  //     next_pages: listData?.meta?.next_page_url,
  //   };
  // }, [listData, page, limit]);

  const routes = useMemo(
    () => [
      {
        label: "Penawaran",
        path: `/perorangan/${props?.type}/penawaran`,
      },
    ],
    [props]
  );

  const queryClient = useQueryClient();
  const { isLoading } = useQuery(
    ["GET_LIST_PENAWARAN", filters],
    getListOffer,
    {
      select: (data) => data?.data?.data,
      onSuccess: async (data) => {
        for (let offer of data?.data) {
          const res = await getFile(offer.document);
          offer.files = res.data.data;
        }
        setOffers(data);
      },
    }
  );
  const submitOffer = useMutation(
    ["SUBMIT_OFFER"],
    (data) => submitNegotiatonOffer(selectedId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries();
        enqueueSnackbar({
          variant: "success",
          message: "Negosiasi berhasil di submit",
        });
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Negosiasi gagal di submit",
        });
      },
    }
  );

  const paginator = useMemo(() => {
    return {
      page: filters.page,
      limit: filters.limit,
      total: offers?.meta?.total,
      previous_pages: offers?.meta?.previous_page_url,
      next_pages: offers?.meta?.next_page_url,
    };
  }, [offers, filters]);

  const renderStatus = (value) => {
    switch (value) {
      case "NOT_YET_OFFER":
        return "Menunggu Keputusan";
      case "AGREE_WITH_NOTES":
        return "Disetujui Dengan Catatan";
      case "APPROVE":
        return "Disetujui";
      case "REJECT":
        return "Ditolak";
      default:
        return "-";
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px] overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return no;
        },
      },
      {
        id: "files",
        title: "Dokumen Penawaran",
        dataIndex: "files",
        columnClassName: "w-[280px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div className="flex gap-3 items-center">
              <div className="p-[10px] rounded-full bg-primary-100 text-primary-600">
                <RiFile3Line />
              </div>
              <a
                href={data?.files?.url}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer"
              >
                <p className="line-clamp-2 text-sm font-medium">
                  {data?.files?.name ?? "-"}
                </p>
              </a>
            </div>
          );
        },
      },
      {
        id: "#",
        title: "Tgl Penawaran Berakhir",
        dataIndex: "date_offer_ends",
        columnClassName: "w-[220px] overflow-hidden",
        render: (value, data, index) => {
          return dayjs(value).format("DD MMMM YYYY");
        },
      },
      {
        id: "#",
        title: "Status",
        dataIndex: "status",
        columnClassName: "w-[212px] overflow-hidden",
        render: (value, data, index) => {
          return (
            <div
              className={classNames(
                "text-xs font-medium w-max py-0.5 px-2 rounded-full",
                statusBadge[renderStatus(value)]
              )}
            >
              {renderStatus(value)}
            </div>
          );
        },
      },
      {
        id: "note",
        title: "Catatan",
        dataIndex: "note",
        columnClassName: "w-[190px] overflow-hidden",
        render: (value, data, index) => {
          return <p className="line-clamp-1 text-sm">{value ?? "-"}</p>;
        },
      },
      {
        id: "#",
        title: "Aksi",
        fixed: "right",
        columnClassName: "w-[296px] overflow-hidden text-center",
        render: (value, data, index) => {
          return (
            <div className="flex gap-1 justify-center items-center">
              {renderStatus(data?.status) === "Menunggu Keputusan" ? (
                <>
                  {/* <RiEyeLine
                    size={24}
                    className="cursor-pointer"
                    onClick={() => {
                      setIsOpenModal(true);
                      setSelectedMode("detail");
                      setSelectedId(data?.applicant_id);
                    }}
                  />
                  <div className="w-0.5 border-r-2 h-6 mx-1"></div> */}
                  <Button
                    type="button"
                    label="Negosiasi"
                    outline
                    size="sm"
                    onClick={() => {
                      setSelectedId(data?.applicant_id);
                      setIsOpenModal(true);
                      setSelectedMode("form");
                    }}
                  />
                  <Button
                    type="button"
                    label="Terima"
                    className="bg-primary-600 hover:bg-primary-700 text-white text-sm"
                    size="sm"
                    onClick={() => {
                      setSelectedId(data?.applicant_id);
                      submitOffer.mutate({
                        data: null,
                        params: { approval: "approve" },
                      });
                    }}
                  />
                  <Button
                    type="button"
                    label="Tolak"
                    className="bg-error-600 hover:bg-error-700 text-white text-sm"
                    size="sm"
                    onClick={() => {
                      setSelectedId(data?.applicant_id);
                      submitOffer.mutate({
                        data: null,
                        params: { approval: "reject" },
                      });
                    }}
                  />
                </>
              ) : (
                <RiEyeLine
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    setIsOpenModal(true);
                    setSelectedMode("detail");
                    setSelectedId(data?.applicant_id);
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [paginator.limit, paginator.page, submitOffer, setSelectedId]
  );

  const handleChangeLimit = (e) => {
    setFilters((prev) => ({ ...prev, limit: e, page: 1 }));
  };

  const handleChangePage = (e) => {
    setFilters((prev) => ({ ...prev, page: e }));
  };

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-auto");

    return () => {
      layouContentEl?.classList?.remove("overflow-auto");
    };
  }, []);

  return (
    <>
      {createPortal(
        <NegosiasiModal
          open={isOpenModal}
          onClose={(e) => {
            setSelectedId(undefined);
            setIsOpenModal(e);
          }}
          id={selectedId}
          mode={selectedMode}
        />,
        document.body
      )}
      <section className="w-full flex flex-col space-y-5 overflow-auto">
        <div className="bg-[#FFFFFF] rounded-xl p-10 w-full">
          <div className="flex flex-col w-full">
            <BreadCrumbs routes={routes} />
            <div className="mt-6 flex flex-row">
              <div className="flex flex-col">
                <span className="font-semibold text-[24px] text-[#101828]">
                  Penawaran
                </span>
              </div>
            </div>
          </div>
        </div>

        <Table
          columns={tableColumns}
          dataSource={offers?.data}
          isLoading={isLoading}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeLimit}
          pagination={paginator}
        />
      </section>
    </>
  );
};

const NegosiasiModal = ({ open, onClose, id, mode = "form" }) => {
  const location = useLocation();

  const isPS = !location.pathname.includes("non");

  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(
    ["GET_DETAIL_PENAWARAN", id],
    getDetailOffer,
    {
      enabled: !!id,
      select: (data) => data?.data?.data,
      onSuccess: (res) => {
        methods.reset(res);
      },
    }
  );
  const submitMutation = useMutation(["SUBMIT_NEGOTIATION,MUTATION"], (data) =>
    submitNegotiatonOffer(id, data)
  );

  const methods = useForm({
    resolver: yupResolver(negosiasiScheme(isPS, data)),
    mode: "onChange",
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: "termins",
  });

  const changeApplicationFeeField = useWatch({
    control: methods.control,
    name: "change_application_fee",
  });
  const changeLoanPeriod = useWatch({
    control: methods.control,
    name: "change_loan_period",
  });
  const changeReturnField = useWatch({
    control: methods.control,
    name: "change_return",
  });
  const numberTerminsField = useWatch({
    control: methods.control,
    name: "number_termins",
  });

  const onSubmit = (data) => {
    submitMutation.mutate(
      { payload: data, params: { approval: "negotiation" } },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries();
          onClose(false);
          enqueueSnackbar({
            variant: "success",
            message: "Negosiasi berhasil di submit",
          });
          methods.reset();
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Negosiasi gagal di submit",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      methods.reset(data);
    }
  }, [data, methods]);

  useEffect(() => {
    const data = Array.from(Array(numberTerminsField).keys())?.map((e) => ({
      phase: e + 1,
      value: "",
    }));

    methods.setValue("termins", data);
  }, [numberTerminsField, methods]);

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-[9999]">
          <motion.div
            key="modal-confirmation"
            className="bg-white rounded-lg w-[782px] overflow-hidden"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div className="max-h-[90vh] overflow-auto">
              <div className="flex flex-row px-6 py-2 gap-4 items-center justify-center sticky top-0 bg-white z-50">
                <div className="flex-1 overflow-hidden text-lg font-semibold">
                  Negosiasi
                </div>
                <div className="shrink-0">
                  <button
                    className="p-3 rounded-full text-gray-500"
                    onClick={() => {
                      onClose(false);
                      methods.reset();
                    }}
                  >
                    <RiCloseLine className={"w-6 h-6"} />
                  </button>
                </div>
              </div>

              <div className="flex flex-col p-6 gap-5">
                <FormProvider {...methods}>
                  <form className="space-y-5">
                    {/* <InputForm
                      controllerName="recommendation_application"
                      label="Nilai Permohonan Ditawarkan"
                      className="w-full"
                      wrapperClass="gap-1"
                      required
                      disabled
                    /> */}
                    <InputCurrencyForm
                      name="recommendation_application"
                      label="Nilai Permohonan Ditawarkan"
                      disabled
                      prefix="Rp "
                    />
                    {mode === "form" && (
                      <>
                        <Controller
                          control={methods.control}
                          name="change_application_fee"
                          render={({ field, fieldState }) => (
                            <div className="flex flex-col gap-2">
                              <label
                                htmlFor="change_application_value"
                                className="text-sm font-semibold flex gap-1"
                              >
                                Apakah Nilai Permohonan Ingin Dirubah?
                                <span className="text-error-600">*</span>
                              </label>
                              <div className="flex gap-10">
                                <label
                                  htmlFor="change_application_value_ya"
                                  className="text-sm font-semibold gap-2 flex items-center"
                                >
                                  <input
                                    id="change_application_value_ya"
                                    type="radio"
                                    disabled={
                                      isPS &&
                                      data?.recommendation_application ===
                                        500000
                                    }
                                    value={true}
                                    name="change_application_fee"
                                    onChange={(e) => {
                                      field.onChange(true);
                                    }}
                                  />
                                  Iya
                                </label>
                                <label
                                  htmlFor="change_application_value_tidak"
                                  className="text-sm font-semibold gap-2 flex items-center"
                                >
                                  <input
                                    id="change_application_value_tidak"
                                    type="radio"
                                    value={false}
                                    name="change_application_fee"
                                    onChange={(e) => {
                                      field.onChange(false);
                                    }}
                                  />
                                  Tidak
                                </label>
                              </div>
                              {fieldState.invalid && (
                                <span className="text-xs text-error-600">
                                  {fieldState.error.message}
                                </span>
                              )}
                            </div>
                          )}
                        />
                        {changeApplicationFeeField === true && (
                          <div>
                            {isPS ? (
                              <AtomSelect
                                label="Nilai Pinjaman Yang dinegosiasikan"
                                controllerName={`application_fee`}
                                className="basic-single mt-2 flex-1"
                                wrapperClass={"col-span-1"}
                                returnObject={false}
                                options={[{ value: "500000", label: 500000 }]}
                                required
                              />
                            ) : (
                              <InputCurrencyForm
                                name="application_fee"
                                label="Nilai Pinjaman Yang dinegosiasikan"
                                required
                                prefix="Rp "
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}

                    <hr />

                    <InputForm
                      controllerName="recommendation_loan_period"
                      label="Jangka Waktu Pinjaman Ditawarkan"
                      className="w-full"
                      wrapperClass="gap-1"
                      defaultValue={data?.recommendation_loan_period}
                      required
                      disabled
                    />
                    {mode === "form" && (
                      <>
                        <Controller
                          control={methods.control}
                          name="change_loan_period"
                          render={({ field, fieldState }) => (
                            <div className="flex flex-col gap-2">
                              <label
                                htmlFor="change_application_value"
                                className="text-sm font-semibold flex gap-1"
                              >
                                Apakah jangka Waktu Pinjaman Ingin Dirubah?
                                <span className="text-error-600">*</span>
                              </label>
                              <div className="flex gap-10">
                                <label
                                  htmlFor="change_loan_period_ya"
                                  className="text-sm font-semibold gap-2 flex items-center"
                                >
                                  <input
                                    id="change_loan_period_ya"
                                    type="radio"
                                    value={true}
                                    disabled={
                                      isPS &&
                                      data?.recommendation_loan_period ===
                                        "24 Minggu"
                                    }
                                    name="change_loan_period"
                                    onChange={(e) => {
                                      field.onChange(true);
                                    }}
                                  />
                                  Iya
                                </label>
                                <label
                                  htmlFor="change_loan_period_tidak"
                                  className="text-sm font-semibold gap-2 flex items-center"
                                >
                                  <input
                                    id="change_loan_period_tidak"
                                    type="radio"
                                    value={false}
                                    name="change_loan_period"
                                    onChange={(e) => {
                                      field.onChange(false);
                                    }}
                                  />
                                  Tidak
                                </label>
                              </div>
                              {fieldState.invalid && (
                                <span className="text-xs text-error-600">
                                  {fieldState.error.message}
                                </span>
                              )}
                            </div>
                          )}
                        />
                        {changeLoanPeriod === true && (
                          <>
                            {isPS && (
                              <div className="flex flex-col gap-2">
                                <div className="flex gap-5 items-stretch w-full">
                                  <Controller
                                    name="loan_period_value"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                      <div className="flex flex-col gap-2 w-full ">
                                        <label
                                          htmlFor="change_application_value"
                                          className="text-sm font-semibold flex gap-1"
                                        >
                                          Jangka Waktu Pinjaman Diajukan
                                          <span className="text-error-600">
                                            *
                                          </span>
                                        </label>
                                        <CustomSelect
                                          label="Jangka Waktu Pinjaman Diajukan"
                                          clearable
                                          optionsData={[
                                            { value: 24, label: 24 },
                                            // { value: 2, label: 2 },
                                            // { value: 3, label: 3 },
                                            // { value: 4, label: 4 },
                                            // { value: 5, label: 5 },
                                          ]}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                        />
                                        {fieldState.invalid && (
                                          <span className="text-xs text-error-600">
                                            {fieldState.error.message}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  />
                                  <Controller
                                    name="loan_period_type"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                      <div className="flex flex-col gap-2 w-full mt-5">
                                        <label
                                          htmlFor="change_application_value"
                                          className="text-sm font-semibold flex gap-1"
                                        ></label>
                                        <CustomSelect
                                          label="Jangka Waktu Pinjaman Diajukan"
                                          optionsData={[
                                            {
                                              value: "Minggu",
                                              label: "Minggu",
                                            },
                                            // { value: "Bulan", label: "Bulan" },
                                            // { value: "Tahun", label: "Tahun" },
                                          ]}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                        />
                                        {fieldState.invalid && (
                                          <span className="text-xs text-error-600">
                                            {fieldState.error.message}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                                <p className="text-xs text-gray-400">
                                  Pastikan Jangka Waktu Diajukan Dibawah Nilai
                                  Permohonan Ditawarkan
                                </p>
                              </div>
                            )}

                            {!isPS && (
                              <div className="flex flex-col gap-2">
                                <div className="flex gap-5 items-stretch w-full">
                                  <Controller
                                    name="loan_period_value"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                      <div className="flex flex-col gap-2 w-full ">
                                        <label
                                          htmlFor="change_application_value"
                                          className="text-sm font-semibold flex gap-1"
                                        >
                                          Jangka Waktu Pinjaman Diajukan
                                          <span className="text-error-600">
                                            *
                                          </span>
                                        </label>
                                        <Input
                                          className="rounded-md"
                                          placeholder=""
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          {...field}
                                        />
                                        {fieldState.invalid && (
                                          <span className="text-xs text-error-600">
                                            {fieldState.error.message}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  />
                                  <Controller
                                    name="loan_period_type"
                                    control={methods.control}
                                    render={({ field, fieldState }) => (
                                      <div className="flex flex-col gap-2 w-full mt-5">
                                        <label
                                          htmlFor="change_application_value"
                                          className="text-sm font-semibold flex gap-1"
                                        ></label>
                                        <CustomSelect
                                          label="Jangka Waktu Pinjaman Diajukan"
                                          optionsData={[
                                            // { value: "Minggu", label: "Minggu" },
                                            // { value: "Bulan", label: "Bulan" },
                                            { value: "Tahun", label: "Tahun" },
                                          ]}
                                          placeholder="Pilih Jangka Waktu"
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                        />
                                        {fieldState.invalid && (
                                          <span className="text-xs text-error-600">
                                            {fieldState.error.message}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                                <p className="text-xs text-gray-400">
                                  Pastikan Jangka Waktu Diajukan Dibawah Nilai
                                  Permohonan Ditawarkan
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <hr />

                    {!isPS ? (
                      <>
                        <InputForm
                          controllerName="recommendation_disbursement_phase"
                          label="Tahapan Pencairan Yang Ditawarkan"
                          className="w-full"
                          wrapperClass="gap-1"
                          required
                          disabled
                        />
                        {data?.recommendation_disbursement_value?.map(
                          (e, idx) => (
                            // <InputForm
                            //   controllerName={`recommendation_disbursement_value.${idx}.value`}
                            //   label={`Nilai Pencairan Tahap ${
                            //     idx + 1
                            //   } Yang Ditawarkan`}
                            //   className="w-full"
                            //   wrapperClass="gap-1"
                            //   required
                            //   disabled
                            // />
                            <InputCurrencyForm
                              name={`recommendation_disbursement_value.${idx}.value`}
                              label={`Nilai Pencairan Tahap ${
                                idx + 1
                              } Yang Ditawarkan`}
                              required
                              disabled
                              prefix="Rp "
                            />
                          )
                        )}
                        {mode === "form" && (
                          <>
                            <Controller
                              control={methods.control}
                              name="change_return"
                              render={({ field, fieldState }) => (
                                <div className="flex flex-col gap-2">
                                  <label
                                    htmlFor="change_return"
                                    className="text-sm font-semibold flex gap-1"
                                  >
                                    Apakah Tahap Pencairan & Nilai Pencairan
                                    Ingin Dirubah?
                                    <span className="text-error-600">*</span>
                                  </label>
                                  <div className="flex gap-10">
                                    <label
                                      htmlFor="change_return_ya"
                                      className="text-sm font-semibold gap-2 flex items-center"
                                    >
                                      <input
                                        id="change_return_ya"
                                        type="radio"
                                        value={true}
                                        name="change_return"
                                        onChange={(e) => {
                                          field.onChange(true);
                                        }}
                                      />
                                      Iya
                                    </label>
                                    <label
                                      htmlFor="change_return_tidak"
                                      className="text-sm font-semibold gap-2 flex items-center"
                                    >
                                      <input
                                        id="change_return_tidak"
                                        type="radio"
                                        value={false}
                                        name="change_return"
                                        onChange={(e) => {
                                          field.onChange(false);
                                        }}
                                      />
                                      Tidak
                                    </label>
                                  </div>
                                  {fieldState.invalid && (
                                    <span className="text-xs text-error-600">
                                      {fieldState.error.message}
                                    </span>
                                  )}
                                </div>
                              )}
                            />

                            {changeReturnField === true && (
                              <div className="flex flex-col gap-5">
                                <div className="flex flex-col gap-2">
                                  <Controller
                                    control={methods.control}
                                    name="number_termins"
                                    render={({ field, fieldState }) => (
                                      <div className="flex flex-col gap-2">
                                        <label
                                          htmlFor="change_application_value"
                                          className="text-sm font-semibold flex gap-1"
                                        >
                                          Tahapan Pencairan Diajukan
                                          <span className="text-error-600">
                                            *
                                          </span>
                                        </label>
                                        <CustomSelect
                                          placeholder="Tahap Pencairan"
                                          optionsData={data?.recommendation_disbursement_value.map(
                                            (_, idx) => ({
                                              value: idx + 1,
                                              label: idx + 1,
                                            })
                                          )}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                        />
                                        {fieldState.invalid && (
                                          <span className="text-xs text-error-600">
                                            {fieldState.error.message}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  />

                                  <p className="text-xs text-gray-400">
                                    Pastikan Tahapan Pencairan Diajukan Dibawah
                                    Tahapan Pencairan Ditawarkan
                                  </p>
                                </div>

                                {typeof numberTerminsField !== "undefined" &&
                                  fields.map((field, idx) => (
                                    // <InputForm
                                    //   key={field.id}
                                    //   controllerName={`termins.${idx}.value`}
                                    //   label={`Nilai Pencairan Tahap ${
                                    //     idx + 1
                                    //   } Yang Diajukan`}
                                    //   wrapperClass="gap-0.5"
                                    //   className="w-full"
                                    //   required
                                    // />
                                    <InputCurrencyForm
                                      key={field.id}
                                      name={`termins.${idx}.value`}
                                      label={`Nilai Pencairan Tahap ${
                                        idx + 1
                                      } Yang Diajukan`}
                                      required
                                      prefix="Rp "
                                    />
                                  ))}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : null}

                    <hr />

                    {mode === "form" && (
                      <InputForm
                        controllerName="notes"
                        label="Jelaskan Mengapa Anda Ingin Mengubah Yang Di Tawarkan"
                        className={`py-2 px-4 mt-2 border w-full rounded-md`}
                        wrapperClass="gap-1"
                        required
                        textArea
                      />
                    )}
                  </form>
                </FormProvider>
              </div>
              {mode === "form" && (
                <div className="flex pt-8 px-6 pb-6 gap-3">
                  <Button
                    label="Batal"
                    className="flex flex-grow"
                    outline
                    onClick={() => {
                      onClose(false);
                    }}
                  />
                  <Button
                    label="Terima"
                    className="flex flex-grow bg-primary-600 hover:bg-primary-700 text-white"
                    onClick={methods.handleSubmit(onSubmit)}
                  />
                </div>
              )}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Penawaran;
