import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { RiCloseLine, RiLeafLine } from "react-icons/ri";
import StaticRippleBg from "../../../../../components/atoms/StaticRippleBg";
import style from "./index.module.css";

export const ServiceType = {
  TUNDA_TEBANG: "Tunda Tebang",
  HBBK: "Hasil Hutan Bukan Kayu",
  KNK: "Komoditas Non Kehutanan",
};

const ServiceTypeOpts = [
  {
    title: "Tunda Tebang",
    desc: "Lorem ipsum dolor sit amet",
    icon: <RiLeafLine className={"w-6 h-6"} />,
    theme: "bluelight",
    value: ServiceType.TUNDA_TEBANG,
  },
  {
    title: "Hasil Hutan Bukan Kayu",
    desc: "Lorem ipsum dolor sit amet",
    icon: <RiLeafLine className={"w-6 h-6"} />,
    theme: "purple",
    value: ServiceType.HBBK,
  },
  {
    title: "Komoditas Non Kehutanan",
    desc: "Lorem ipsum dolor sit amet",
    icon: <RiLeafLine className={"w-6 h-6"} />,
    theme: "rose",
    value: ServiceType.KNK,
  },
];

function ServiceTypeModal({
  open,
  onClose,
  onSubmit,
  className = "",
  children,
}) {
  const onServiceClick = (value) => () => {
    onSubmit(value);
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-[9999]">
          <motion.div
            key="modal-confirmation"
            className="bg-white rounded-xl w-10/12 max-w-[800px] overflow-hidden"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div className={"max-h-[90vh] overflow-auto"}>
              <div
                className={
                  "flex flex-row px-6 py-2 gap-4 items-center justify-center"
                }
              >
                <div className={"flex-1 overflow-hidden text-xl font-semibold"}>
                  Pilih Layanan
                </div>
                <div className={"shrink-0"}>
                  <button
                    className={"p-3 rounded-full text-gray-500"}
                    onClick={() => onClose(false)}
                  >
                    <RiCloseLine className={"w-6 h-6"} />
                  </button>
                </div>
              </div>

              <div className="flex flex-col p-6">
                <div className={`empty:hidden ${className}`}>{children}</div>

                <div
                  className={
                    "flex flex-row flex-wrap justify-center item-start -mx-3"
                  }
                >
                  {ServiceTypeOpts.map((service, idx) => (
                    <div
                      className={`w-full sm:basis-1/3 grow-1 text-center px-2 py-2`}
                      key={idx}
                    >
                      <button
                        className={[
                          "w-full h-full flex flex-col items-center px-2 pt-2 rounded-2xl",
                          style.option,
                        ].join(" ")}
                        type={"button"}
                        onClick={onServiceClick(service)}
                      >
                        {!!service.icon && (
                          <StaticRippleBg
                            className={"mb-5 shrink-0 z-10 relative"}
                            theme={service.theme}
                          >
                            {service.icon}
                          </StaticRippleBg>
                        )}

                        <div
                          className={[
                            "w-full flex-1 space-y-2 -mx-2 px-2 py-5 rounded-[inherit]",
                            !!service.icon ? "-mt-12 pt-10" : "",
                            style.optionContent,
                          ].join(" ")}
                        >
                          {!!service.title && (
                            <div className={"text-lg font-semibold"}>
                              {service.title}
                            </div>
                          )}

                          {!!service.desc && <div>{service.desc}</div>}
                        </div>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

ServiceTypeModal.propTypes = {};

export default ServiceTypeModal;
