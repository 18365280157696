import { Button, Input } from 'components';
import CustomSelect from 'components/atoms/CustomSelect';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import Table from 'components/molecules/Table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { BiSearch } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { Await, useNavigate } from 'react-router';
import moment from 'moment';
import useNoteConcept from './hooks/useNoteConcept';
import 'custom.css';
import AtomRangeDatepicker from 'components/atoms/RangeDatepicker';

const KonsepNotesList = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const [statusFilter, setStatusFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(null);
  const [rowPage, setRowPage] = useState(10);
  const [orderBy, setOrderBy] = useState('');
  const [orderMethod, setOrderMethod] = useState('');

  const [start, end] = methods.watch('dateSubmission') || [null, null];

  const items = [
    {
      label: 'Konsep Notes',
      path: '/proposal/konsep-notes',
    },
  ];

  const params = {
    name: nameFilter,
    status: statusFilter,
    start_date: startDate,
    end_date: endDate,
    page: page,
    limit: rowPage || 5,
    order_by: orderBy,
    order_method: orderMethod,
  };

  const {
    conceptNoteData,
    paginationAwsData,
    isLoadingConceptNoteData,
    refetch,
  } = useNoteConcept({ params });

  const statusOptions = useMemo(() => {
    return [
      {
        value: null,
        label: 'Semua',
      },
      {
        value: 'Draft',
        label: 'Draft',
      },
      {
        value: 'Menunggu Persetujuan',
        label: 'Menunggu Persetujuan',
      },
      {
        value: 'Pelu Set Komitmen',
        label: 'Pelu Set Komitmen',
      },
      {
        value: 'Disetujui',
        label: 'Disetujui',
      },
      {
        value: 'Ditolak',
        label: 'Ditolak',
      },
    ];
  });

  const onSearch = debounce(
    useCallback(
      (event) => {
        setNameFilter(event?.target?.value || '');
      },
      [setNameFilter]
    ),
    1000
  );

  const onChangeStatus = useCallback((obj) => {
    setStatusFilter(obj.value || undefined);
  }, []);

  useEffect(() => {
    refetch();
  }, [
    nameFilter,
    statusFilter,
    endDate,
    startDate,
    page,
    rowPage,
    orderBy,
    orderMethod,
  ]);

  useEffect(() => {
    let newEndDate = end ? moment(new Date(end)).format('YYYY-MM-DD') : null;
    let newStartDate = start
      ? moment(new Date(start)).subtract(1, 'days').format('YYYY-MM-DD')
      : null;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [start, end]);

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
      total: 5,
      previous_pages: true,
      next_pages: true,
    };
  });

  // const dataSource = useMemo(() => {
  // return [
  //     {
  //       proposal_title: "Proposal A",
  //       project_name: "Project A",
  //       submission_date: "02/04/2023",
  //       location: "Jawa Barat",
  //       status: "menunggu persetujuan",
  //     },
  //     {
  //       proposal_title: "Proposal B",
  //       project_name: "Project B",
  //       submission_date: "02/04/2023",
  //       location: "Jawa Barat",
  //       status: "menunggu persetujuan",
  //     },
  //     {
  //       proposal_title: "Proposal C",
  //       project_name: "Project C",
  //       submission_date: "02/04/2023",
  //       location: "Jawa Barat",
  //       status: "disetujui",
  //     },
  //     {
  //       proposal_title: "Proposal D",
  //       project_name: "Project D",
  //       submission_date: "02/04/2023",
  //       location: "Jawa Barat",
  //       status: "disetujui",
  //     },
  //     {
  //       proposal_title: "Proposal E",
  //       project_name: "Project E",
  //       submission_date: "02/04/2023",
  //       location: "Jawa Barat",
  //       status: "ditolak",
  //     },
  //     {
  //       proposal_title: "Proposal D",
  //       project_name: "Project D",
  //       submission_date: "02/04/2023",
  //       location: "Jawa Barat",
  //       status: "disetujui",
  //     },
  //     {
  //       proposal_title: "Proposal E",
  //       project_name: "Project E",
  //       submission_date: "02/04/2023",
  //       location: "Jawa Barat",
  //       status: "ditolak",
  //     },
  //     {
  //       proposal_title: "Proposal D",
  //       project_name: "Project D",
  //       submission_date: "02/04/2023",
  //       location: "Jawa Barat",
  //       status: "disetujui",
  //     },
  //     {
  //       proposal_title: "Proposal E",
  //       project_name: "Project E",
  //       submission_date: "02/04/2023",
  //       location: "Jawa Barat",
  //       status: "ditolak",
  //     },
  //   ];

  // }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: 'overflow-hidden',
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'proposalTitle',
        title: 'Judul Proposal',
        dataIndex: 'proposal_title',
        className: '',
        columnClassName:
          'w-[250px] text-ellipsis overflow-hidden whitespace-break-spaces',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[250px]">
            {value}
          </div>
        ),
      },
      {
        id: 'projectName',
        title: 'Nama Proyek',
        dataIndex: 'project_name',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'submissionDate',
        title: 'Tanggal Pengajuan',
        dataIndex: 'submission_date',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value ? moment(value).format('DD/MM/YYYY') : '-'}
          </div>
        ),
      },
      {
        id: 'location',
        title: 'Lokasi',
        dataIndex: 'location',
        className: '',
        columnClassName: 'w-[150px] text-ellipsis overflow-hidden',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
      {
        id: 'status',
        title: 'Status',
        dataIndex: 'status',
        columnClassName: 'w-[200px] text-ellipsis overflow-hidden',
        sortable: true,
        sorter: (method, column) => {
          setOrderBy(column);
          setOrderMethod(method);
        },
        fixed: 'right',
        render: (value) => (
          <div className="text-ellipsis w-full max-w-[200px]">
            {value.toLowerCase() === 'menunggu persetujuan' && (
              <div className="w-fit py-1 px-2 bg-[#FFFAEB] rounded-[25px] flex gap-[1px] items-center">
                <div className="text-[#B54708] font-medium text-xs">
                  Menunggu Persetujuan
                </div>
              </div>
            )}
            {value.toLowerCase() === 'disetujui' ||
              (value.toLowerCase() === 'approved' && (
                <div className="w-fit py-1 px-2 bg-[#ECFDF3] rounded-[25px] flex gap-[1px] p-1 items-center">
                  <div className="text-[#00BD52] font-medium text-xs">
                    Disetujui
                  </div>
                </div>
              ))}
            {value.toLowerCase() === 'ditolak' && (
              <div className="w-fit py-1 px-2 bg-[#FEF3F2] rounded-[25px] flex gap-[1px] p-1 items-center">
                <div className="text-[#B42318] font-medium text-xs">
                  Ditolak
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        id: 'actions',
        title: 'Aksi',
        dataIndex: 'status',
        className: '',
        sortable: true,
        columnClassName: 'w-[100px] text-center text-ellipsis overflow-hidden',
        fixed: 'right',
        render: (value, data, index) => {
          return (
            <div className="flex flex-col justify-center items-center">
              <BsEye
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() => navigate(`/proposal/konsep-notes/${data?._id}`)}
              />
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <div className="w-full flex flex-col mx-auto gap-6">
      {/* Header Section */}
      <div className="flex w-[84%] flex-col bg-white gap-5 p-10 rounded-xl">
        <BreadCrumbs routes={items} />

        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-2xl text-gray-900">
            Daftar Permohonan Konsep Notes
          </h1>
          <Button
            type="button"
            onClick={() => navigate('/proposal/konsep-notes/form')}
            size="md"
            className="bg-[#E34F13] text-white"
            label={
              <>
                <FiPlus size={20} />
                <span>Tambah Konsep Notes</span>
              </>
            }
          />
        </div>
      </div>

      {/* Content Section */}
      <div className="card w-[84%] bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="flex flex-col gap-4 card-body p-3">
          {/* Filter */}
          <div className="flex gap-5 justify-around">
            {/* Search */}
            <div className="flex flex-1 items-center rounded-md border-gray-200 px-4 space-x-3 border-solid border-[1px]">
              <BiSearch className="text-gray-400" size={20} />
              <input
                className="bg-white text-gray-400 w-full text-sm p-2 outline-none border-0 ring-0 focus:outline-none focus:border-0 focus:ring-0"
                placeholder={'Cari'}
                onChange={onSearch}
              />
            </div>

            {/* Date */}
            <div className="flex-1 customDateKonsepNote">
              <FormProvider {...methods}>
                <AtomRangeDatepicker
                  className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                  controllerName="dateSubmission"
                  label={null}
                  placeholder="Tanggal Pengajuan"
                  asText={false}
                  format={'dd/MM/yyyy'}
                  isRangeDatePicker={true}
                />
              </FormProvider>
            </div>

            {/* Select */}
            <CustomSelect
              optionsData={statusOptions}
              placeholder="Status"
              className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center"
              onChange={onChangeStatus}
            />
          </div>

          {/* Table */}
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={conceptNoteData}
            isLoading={false}
            onChangePage={(page) => setPage(page)}
            onChangeRowsPerPage={(row) => setRowPage(row)}
            pagination={paginationAwsData}
          />
        </div>
      </div>
    </div>
  );
};

export default KonsepNotesList;
