import { BottomFormAction } from 'components';
import { AlertWarning } from 'components/molecules/Alert';
import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import CardForm from 'components/molecules/CardForm';
import AtomDatePicker from 'components/atoms/Datepicker';
import Disclosure from '../../../../../components/atoms/Disclosure';
import { FormHeader, TotalField, CurrencyField } from './components';
import { currencyToNumber, numberToCurrency } from 'helpers';

const InputCurrencyMemo = React.memo(CurrencyField);
const TotalFieldMemo = React.memo(TotalField);

const NeracaKeuanganLembaga = ({ draftData, onSubmit, onBack, onDraft }) => {
  const validationSchema = Yup.object().shape({
    items: Yup.array()
      .of(
        Yup.object().shape({
          kas: Yup.string().required().label('Kas'),
          bank: Yup.string().required().label('Bank'),
          puitang: Yup.string().required().label('Piutang'),
          persediaan_barang: Yup.string().required().label('Persediaan Barang'),
          biaya_dibayar_di_muka: Yup.string()
            .required()
            .label('Biaya Dibayar Dimuka'),
          peralatan_mesin: Yup.string().required().label('Peralatan Mesin'),
          inventaris: Yup.string().required().label('Inventaris'),
          gedung: Yup.string().required().label('Gedung'),
          aktiva_lainnya: Yup.string().required().label('Aktiva Lainnya'),
          utang_dagang: Yup.string().required().label('Utang Dagang'),
          utang_pajak: Yup.string().required().label('Utang Pajak'),
          utang_lainnya: Yup.string().required().label('Utang Lainnya'),
          utang: Yup.string().required().label('Utang'),
          modal_disetor: Yup.string().required().label('Modal Disetor/Inti'),
          tambahan_modal_disetor: Yup.string()
            .required()
            .label('Tambahan Modal Disetor'),
          penghasilan_lainnya: Yup.string()
            .required()
            .label('Penghasilan Lainnya'),
          cadangan: Yup.string().required().label('Cadangan'),
          laba_rugi: Yup.string().required().label('Laba Rugi'),
        })
      )
      .min(1),
  });

  const currentDate = useMemo(() => new Date(), []); // Using useMemo to calculate once
  const twoYearsAgo = useMemo(
    () =>
      new Date(
        currentDate.getFullYear() - 4,
        currentDate.getMonth(),
        currentDate.getDate()
      ),
    [currentDate] // Depend on currentDate
  );

  const methods = useForm(
    {
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
      defaultValues: {
        // range_tahun: [twoYearsAgo, currentDate],
        first_year: twoYearsAgo,
        end_year: currentDate,
        items: Array.from(
          { length: draftData?.items?.length || 4 },
          (_, index) => {
            const isFirstElement = index === 0;
            const draftItem = draftData?.items?.[index] || {};

            const item = {
              kas: draftItem.kas || '',
              bank: draftItem.bank || '',
              puitang: draftItem.puitang || '',
              persediaan_barang: draftItem.persediaan_barang || '',
              biaya_dibayar_di_muka: draftItem.biaya_dibayar_di_muka || '',
              jumlah_a: isFirstElement ? undefined : draftItem.jumlah_a || '',
              peralatan_mesin: draftItem.peralatan_mesin || '',
              inventaris: draftItem.inventaris || '',
              gedung: draftItem.gedung || '',
              jumlah_b: isFirstElement ? undefined : draftItem.jumlah_b || '',
              aktiva_lainnya: draftItem.aktiva_lainnya || '',
              jumlah_c: draftItem.jumlah_c || '',
              total_aktiva: draftItem.total_aktiva || '',
              utang_dagang: draftItem.utang_dagang || '',
              utang_pajak: draftItem.utang_pajak || '',
              utang_lainnya: draftItem.utang_lainnya || '',
              jumlah_d: draftItem.jumlah_d || '',
              utang: draftItem.utang || '',
              jumlah_e: draftItem.jumlah_e || '',
              jumlah_de: draftItem.jumlah_de || '',
              modal_disetor: draftItem.modal_disetor || '',
              tambahan_modal_disetor: draftItem.tambahan_modal_disetor || '',
              penghasilan_lainnya: draftItem.penghasilan_lainnya || '',
              cadangan: draftItem.cadangan || '',
              laba_rugi: draftItem.laba_rugi || '',
            };

            return item;
          }
        ),
      },
    },
    [validationSchema, currentDate, twoYearsAgo]
  );

  // const onCreate = () => {
  //   // let params = bodyToSend;
  //   navigate("/lembaga-penyalur");
  // };

  const {
    setValue,
    getValues,
    watch,
    formState: { errors },
    control,
  } = methods || {};

  const first_year = watch('first_year', twoYearsAgo);
  const end_year = watch('end_year', currentDate);

  const values = getValues();

  useEffect(() => {
    const startDate = first_year;
    const endDate = end_year;

    if (startDate && endDate) {
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const yearLength = endYear - startYear;

      if (yearLength !== values.items.length) {
        setValue(
          'items',
          Array.from({ length: yearLength }, (_, index) => {
            const draftItem = values.items?.[index] || {};
            const isFirstElement = index === 0;

            const item = {
              kas: draftItem.kas || '',
              bank: draftItem.bank || '',
              puitang: draftItem.puitang || '',
              persediaan_barang: draftItem.persediaan_barang || '',
              biaya_dibayar_di_muka: draftItem.biaya_dibayar_di_muka || '',
              jumlah_a: isFirstElement ? undefined : draftItem.jumlah_a || '',
              peralatan_mesin: draftItem.peralatan_mesin || '',
              inventaris: draftItem.inventaris || '',
              gedung: draftItem.gedung || '',
              jumlah_b: isFirstElement ? undefined : draftItem.jumlah_b || '',
              aktiva_lainnya: draftItem.aktiva_lainnya || '',
              jumlah_c: draftItem.jumlah_c || '',
              total_aktiva: draftItem.total_aktiva || '',
              utang_dagang: draftItem.utang_dagang || '',
              utang_pajak: draftItem.utang_pajak || '',
              utang_lainnya: draftItem.utang_lainnya || '',
              jumlah_d: draftItem.jumlah_d || '',
              utang: draftItem.utang || '',
              jumlah_e: draftItem.jumlah_e || '',
              jumlah_de: draftItem.jumlah_de || '',
              modal_disetor: draftItem.modal_disetor || '',
              tambahan_modal_disetor: draftItem.tambahan_modal_disetor || '',
              penghasilan_lainnya: draftItem.penghasilan_lainnya || '',
              cadangan: draftItem.cadangan || '',
              laba_rugi: draftItem.laba_rugi || '',
            };

            return item;
          })
        );
      }
    }
  }, [first_year, end_year, values, setValue]);

  const startFrom = first_year.getFullYear();

  const onFormSubmit = () => {
    return methods.handleSubmit(
      (data) => {
        onSubmit(data);
      },
      (errors, e) => {
        console.log('ERROR', errors, e);
      }
    );
  };

  const { fields: items } = useFieldArray({
    control,
    name: 'items',
  });

  return (
    <div>
      <div className="">
        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit()} className="space-y-8">
            <CardForm label="Pilih Range Tahun">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <AtomDatePicker
                  controllerName={'first_year'}
                  label="Tahun"
                  placeholder="2021"
                  required={true}
                  datepickerOptions={{
                    dateFormat: 'yyyy',
                    maxDate: end_year,
                    showYearPicker: true,
                    minDate: first_year,
                  }}
                  showErrorLabel
                />
                <AtomDatePicker
                  controllerName={'end_year'}
                  label="Tahun"
                  placeholder="2023"
                  required={true}
                  datepickerOptions={{
                    dateFormat: 'yyyy',
                    maxDate: end_year,
                    showYearPicker: true,
                    minDate: first_year,
                  }}
                  showErrorLabel
                />
              </div>
            </CardForm>

            <div className="space-y-4">
              {items.length > 0
                ? items.map((data, index) => (
                    <React.Fragment key={data.id}>
                      <Disclosure
                        title={`Neraca Keuangan Lembaga - Tahun ${
                          index === 0 ? startFrom : startFrom + index
                        }`}
                        contentClass={'space-y-4'}
                        defaultValue={index === 0 || !!errors?.items?.[index]}
                      >
                        <Disclosure
                          title="Aktiva/Aset"
                          defaultValue={index === 0}
                        >
                          <FormHeader className={'mb-4'}>
                            A. Aktiva Lancar
                          </FormHeader>
                          <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                            <InputCurrencyMemo
                              controllerName={`items.${index}.kas`}
                              label={'Kas'}
                              placeholder={'Kas'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.bank`}
                              label={'Bank'}
                              placeholder={'Bank'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.puitang`}
                              label={'Piutang'}
                              placeholder={'Piutang'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.persediaan_barang`}
                              label={'Persediaan Barang'}
                              placeholder={'Persediaan Barang'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.biaya_dibayar_di_muka`}
                              label={'Biaya Dibayar Dimuka'}
                              placeholder={'Biaya Dibayar Dimuka'}
                              required
                            />
                          </div>
                          <TotalFieldMemo
                            index={index}
                            label={'Jumlah A'}
                            fieldName={'jumlah_a'}
                            wrapperClass="mt-0"
                            calc={calcTotalA}
                          />

                          <FormHeader className="mt-6 mb-4">
                            B. Aktiva Tetap
                          </FormHeader>
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <InputCurrencyMemo
                              controllerName={`items.${index}.peralatan_mesin`}
                              label={'Peralatan Mesin'}
                              placeholder={'Peralatan Mesin'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.inventaris`}
                              label={'Inventaris'}
                              placeholder={'Inventaris'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.gedung`}
                              label={'Gedung'}
                              placeholder={'Gedung'}
                              required
                            />
                          </div>
                          <AlertWarning className="my-4">
                            sudah di kurangin penysutusan (bersih)
                          </AlertWarning>
                          <TotalFieldMemo
                            index={index}
                            label={'Jumlah B'}
                            fieldName={'jumlah_b'}
                            calc={calcTotalB}
                          />
                          <FormHeader className={'mb-4 mt-6'}>
                            C. Aktiva Lainnya
                          </FormHeader>
                          <InputCurrencyMemo
                            controllerName={`items.${index}.aktiva_lainnya`}
                            label={'Aktiva Lainnya'}
                            placeholder={'Aktiva Lainnya'}
                          />
                          <TotalFieldMemo
                            index={index}
                            label={'Jumlah C'}
                            fieldName={'jumlah_c'}
                            calc={(data) => {
                              return numberToCurrency(
                                currencyToNumber(data.aktiva_lainnya)
                              );
                            }}
                          />
                          <TotalFieldMemo
                            index={index}
                            label={'Total Aktiva A+B+C'}
                            fieldName={'jumlah_aktiva'}
                            calc={calcTotalAktiva}
                          />
                        </Disclosure>

                        <Disclosure
                          title="PASIVA/KEWAJIBAN"
                          defaultValue={index === 0}
                        >
                          <FormHeader className={'mb-3'}>
                            D. Utang Jangka Pendek (kurang dari 12 bulan)
                          </FormHeader>
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <InputCurrencyMemo
                              controllerName={`items.${index}.utang_dagang`}
                              label={'Utang Dagang'}
                              placeholder={'Utang Dagang'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.utang_pajak`}
                              label={'Utang Pajak'}
                              placeholder={'Utang Pajak'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.utang_lainnya`}
                              label={'Utang Lainnya'}
                              placeholder={'Utang Lainnya'}
                              required
                            />
                          </div>
                          <TotalFieldMemo
                            index={index}
                            label={'Jumlah (D)'}
                            fieldName={'jumlah_d'}
                            calc={calcTotalD}
                          />
                          <FormHeader className={'mb-3 mt-6'}>
                            E. Utang Jangka Panjang (Lebih dari 12 bulan)
                          </FormHeader>
                          <InputCurrencyMemo
                            controllerName={`items.${index}.utang`}
                            label={'Utang'}
                            placeholder={'Utang'}
                            required
                          />
                          <TotalFieldMemo
                            index={index}
                            label={'Jumlah (E)'}
                            fieldName={'jumlah_e'}
                            calc={calcTotalE}
                          />
                          <TotalFieldMemo
                            index={index}
                            label={'Jumlah (D-E)'}
                            fieldName={'jumlah_de'}
                            calc={calcTotalDE}
                          />
                        </Disclosure>

                        <Disclosure
                          title="Ekuitas/Modal"
                          defaultValue={index === 0}
                        >
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <InputCurrencyMemo
                              controllerName={`items.${index}.modal_disetor`}
                              label={'Modal Disetor/Inti'}
                              placeholder={'Modal Disetor/Inti'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.tambahan_modal_disetor`}
                              label={'Tambahan Modal Disetor'}
                              placeholder={'Tambahan Modal Disetor'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.penghasilan_lainnya`}
                              label={'Penghasilan Lainnya'}
                              placeholder={'Penghasilan Lainnya'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.cadangan`}
                              label={'Cadangan'}
                              placeholder={'Cadangan'}
                              required
                            />
                            <InputCurrencyMemo
                              controllerName={`items.${index}.laba_rugi`}
                              label={'Laba Rugi'}
                              placeholder={'Laba Rugi'}
                              wrapperClass="col-span-2"
                              required
                            />
                          </div>
                        </Disclosure>
                      </Disclosure>
                    </React.Fragment>
                  ))
                : null}
            </div>
          </form>
        </FormProvider>
      </div>

      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onFormSubmit()}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        disableButtonSubmit={false}
      />
    </div>
  );
};

const calcTotalA = (data) => {
  return (
    currencyToNumber(data.kas) +
    currencyToNumber(data.bank) +
    currencyToNumber(data.puitang) +
    currencyToNumber(data.persediaan_barang) +
    currencyToNumber(data.biaya_dibayar_di_muka)
  );
};

const calcTotalB = (data) => {
  return (
    currencyToNumber(data.peralatan_mesin) +
    currencyToNumber(data.inventaris) +
    currencyToNumber(data.gedung)
  );
};

const calcTotalAktiva = (data) => {
  return (
    calcTotalA(data) + calcTotalB(data) + currencyToNumber(data.aktiva_lainnya)
  );
};

const calcTotalD = (data) => {
  return (
    currencyToNumber(data.utang_dagang) +
    currencyToNumber(data.utang_pajak) +
    currencyToNumber(data.utang_lainnya)
  );
};

const calcTotalE = (data) => {
  return currencyToNumber(data.utang);
};

const calcTotalDE = (data) => {
  return calcTotalD(data) - calcTotalE(data);
};

export default NeracaKeuanganLembaga;
