import React, { Fragment, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  BottomFormAction,
  Button,
  ConfirmationModal,
  InputForm,
  x,
} from 'components';
import AlertBlankData from 'components/molecules/Alert';
import CardForm from 'components/molecules/CardForm';
import { RiAddLine, RiDeleteBinLine } from 'react-icons/ri';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import { useDispatch, useSelector } from 'react-redux';
import FileDetail from 'components/molecules/FileDetail';
import { useNavigate } from 'react-router';
import AtomSelect from 'components/atoms/Select';
import {
  getActivity,
  getBudgetAccountList,
  getDetailMasterActivity,
  getListMasterActivity,
  post,
} from 'services/danaProgram/disbursement';
import { useMutation } from '@tanstack/react-query';
import { fileUpload } from 'services/danaProgram/fileService';
import { enqueueSnackbar } from 'notistack';
import InputCurrency from 'components/organisms/inputs/InputCurrency';
import { currencyToNumber, formatCurrency } from 'helpers';

const validationSchema = yup.object({});

const defaultValues = {
  disbursement_rab: [
    {
      name: '',
    },
  ],
};

const RabForm = ({ onNext, onBack, type, payload }) => {
  const [optionActivity, setOptionActivity] = useState([]);
  const [optionMataAnggaran, setOptionMataAnggaran] = useState([]);
  const [initDocs, setInitDocs] = useState([]);
  const [formSubmit, setFormSubmit] = useState(null);
  const [totalPengajuanDana, setTotalPengajuanDana] = useState(0);
  const [showConfirmModal, setConfirmModal] = useState({
    open: false,
    type: 'draft',
    cancelLabel: 'Tidak',
    confirmLabel: 'Iya, simpan draft',
    title: '',
    description: '',
  });
  const { id } = useSelector((state) => state?.callForProposalData?.data);
  const navigate = useNavigate();

  const submitPermohonan = useMutation({
    mutationKey: ['post-permohonan-pembayaran'],
    mutationFn: async (payload) => {
      const res = await post(payload);
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar('Data Berhasil Disimpan', { variant: 'success' });
      navigate(`/disbursement/application`);
    },
  });

  const postFile = useMutation({
    mutationKey: ['post-file-document-permohonan'],
    mutationFn: async (payload) => {
      const promise = payload?.map(async (item) => {
        const formData = new FormData();
        formData.append('file', item);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });
      return Promise.all(promise || []);
    },
    onSuccess: async (data) => {
      submitPermohonan.mutate({
        ...formSubmit,
        document: data?.length > 0 ? [...data, ...(initDocs || [])] : initDocs,
      });
    },
  });

  const submitForm = (values) => {
    const sum = values?.disbursement_rab.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        Number(currentValue.subtotal.replace(/[Rp.,]/g, '').trim())
      );
    }, 0);

    const reqBody = {
      proposal_id: payload?.tor?.project_name?.value,
      project_name: payload?.tor?.project_name?.program_name,
      output_name: payload?.tor?.project_output?.value,
      cfp_general_information_id: '65a0b93f9ecc360a9a79fce7',
      main_activity_id: payload?.tor?.project_activity?.value,
      main_activity_name: payload?.tor?.project_activity?.label,
      background: payload?.tor?.background,
      purpose: payload?.tor?.objective,
      activity_outcome: payload?.tor?.target_result,
      member_of_male: payload?.tor?.total_girl,
      member_of_female: payload?.tor?.total_man,
      member_description: payload?.tor?.description,
      total_requested_funds: sum,
      tor_id: '65a0b93f9ecc360a9a79fce7',
    };

    if (
      payload?.tor?.additional_document &&
      payload?.tor?.additional_document.length > 0
    ) {
      setInitDocs(payload?.tor?.additional_document);
      postFile.mutate(payload?.tor?.additional_document);
    }

    if (values?.disbursement_rab && values?.disbursement_rab.length > 0) {
      reqBody['requirement_item'] = [];
      values?.disbursement_rab.map((item, key) => {
        reqBody['requirement_item'].push({
          name: item.name,
          qty: item?.quantity,
          volume: item?.volume,
          activity_id: item?.detail_activity?.value,
          activity_name: item?.detail_activity?.label,
          budget_line_item_id: item?.budget_item?.value,
          budget_name: item?.budget_item?.label,
          subcost: Number(item?.unit_cost.replace(/[Rp.,]/g, '').trim()),
          subtotal: Number(item?.subtotal.replace(/[Rp.,]/g, '').trim()),
          currency: 'IDR',
        });
      });
    }
    setFormSubmit(reqBody);
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { control } = methods;

  const {
    fields: rabFields,
    append: appendRab,
    remove: removeRab,
  } = useFieldArray({
    control,
    name: `disbursement_rab`,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchActivity = async () => {
    const response = await getListMasterActivity();
    const data = await response?.data?.data;

    const listProject = data.map((el, i) => ({
      value: el?.id,
      label: el?.kegiatanUtama,
    }));

    setOptionActivity(() => [...listProject]);
  };

  const fetchBudgetAccount = async () => {
    const response = await getBudgetAccountList({});
    const data = await response?.data?.data;

    const listBudget = data.map((el, i) => ({
      value: el?.id,
      label: el?.nama,
    }));

    setOptionMataAnggaran(() => [...listBudget]);
  };

  useEffect(() => {
    fetchActivity();
    fetchBudgetAccount();
  }, []);

  return (
    <div>
      <Fragment>
        <div className="space-y-6">
          <div className="w-full">
            <AlertBlankData
              title={`Lengkapi Data`}
              body={`Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
            />
          </div>
          <FormProvider {...methods}>
            <ConfirmationModal
              useGraphic
              open={showConfirmModal.open}
              onCancel={() => {
                setConfirmModal((curr) => ({
                  ...curr,
                  open: false,
                }));
              }}
              cancelLabel={showConfirmModal.cancelLabel}
              confirmLabel={showConfirmModal.confirmLabel}
              onSubmit={() => methods.handleSubmit(submitForm)()}
            >
              <div className="space-y-1">
                <div className="text-lg text-gray-900 font-semibold">
                  {showConfirmModal.title}
                </div>
                <div className="text-sm text-gray-600">
                  {showConfirmModal.description}
                </div>
              </div>
            </ConfirmationModal>
            <CardForm label="Rencana Anggaran Biaya" buttonCollapse={true}>
              {rabFields.map((field, key) => (
                <CardForm
                  label=""
                  childerForm={true}
                  buttonCollapse={true}
                  key={key}
                >
                  <InputForm
                    controllerName={`disbursement_rab.${key}.name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md`}
                    label={'Item Kebutuhan'}
                    required={true}
                  />
                  <div className="flex gap-6">
                    <InputForm
                      controllerName={`disbursement_rab.${key}.quantity`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label={'Qty'}
                      required={true}
                      onChangeInput={(e) => {
                        let qty = methods.getValues(
                          `disbursement_rab.${key}.quantity`
                        );
                        let unitCost = methods.getValues(
                          `disbursement_rab.${key}.unit_cost`
                        );
                        methods.setValue(
                          `disbursement_rab.${key}.subtotal`,
                          formatCurrency(qty * currencyToNumber(unitCost))
                        );
                        setTotalPengajuanDana(
                          totalPengajuanDana + qty * currencyToNumber(unitCost)
                        );
                      }}
                    />
                    <InputForm
                      controllerName={`disbursement_rab.${key}.volume`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label={'Volume / Frekuensi'}
                      required={true}
                    />
                    <InputCurrency
                      controllerName={`disbursement_rab.${key}.unit_cost`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label={'Satuan Biaya (Rp)'}
                      required={true}
                      onChangeInput={(e) => {
                        let qty = methods.getValues(
                          `disbursement_rab.${key}.quantity`
                        );
                        let unitCost = methods.getValues(
                          `disbursement_rab.${key}.unit_cost`
                        );
                        methods.setValue(
                          `disbursement_rab.${key}.subtotal`,
                          formatCurrency(qty * currencyToNumber(unitCost))
                        );
                        setTotalPengajuanDana(
                          totalPengajuanDana + qty * currencyToNumber(unitCost)
                        );
                      }}
                    />
                    <InputCurrency
                      controllerName={`disbursement_rab.${key}.subtotal`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label={'Sub Total (Rp)'}
                      required={true}
                      disabled
                    />
                  </div>
                  <AtomSelect
                    controllerName={`disbursement_rab.${key}.detail_activity`}
                    className={`mt-2 w-full rounded-md`}
                    options={optionActivity}
                    label={'Detail Kegiatan'}
                    placeholder={'Pilih salah satu'}
                    required={true}
                  />
                  <AtomSelect
                    controllerName={`disbursement_rab.${key}.budget_item`}
                    className={`mt-2 w-full rounded-md`}
                    options={optionMataAnggaran}
                    label={'Mata Anggaran'}
                    placeholder={'Pilih salah satu'}
                    required={true}
                  />
                </CardForm>
              ))}
              <div className="w-full items-end">
                <Button
                  type="button"
                  className="rounded-lg bg-secondary-orange-600 border border-secondary-orange-600 text-white px-4 py-2.5 w-fit float-right"
                  label={
                    <div className="flex items-center gap-2">
                      <RiAddLine />
                      <span className="font-semibold text-sm">
                        Tambah Item Kebutuhan
                      </span>
                    </div>
                  }
                  onClick={() => appendRab({})}
                />
              </div>
              <hr />
              <label className="text-[#1D2939] font-semibold text-sm">
                Total Pengajuan Dana
              </label>
              <label className="text-[#1D2939] font-semibold text-sm">
                {formatCurrency(totalPengajuanDana)}
              </label>
            </CardForm>
            <BottomFormAction
              lastStep={true}
              backButtonAction={() => onBack()}
              disableDrafButton={false}
              draftLabel={'Kembali Ke Permohonan'}
              drafButtonAction={() => {
                navigate(`/disbursement/application`);
              }}
              isLoading={false}
              submitActionButton={() => {
                setConfirmModal((curr) => ({
                  ...curr,
                  open: true,
                  type: 'submit',
                  cancelLabel: 'Tidak, ubah data',
                  confirmLabel: 'Ya, kirimkan',
                  title: 'Anda yakin akan mengirimkan semua data ini?',
                  description:
                    'Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku. ',
                }));
              }}
            />
          </FormProvider>
        </div>
      </Fragment>
    </div>
  );
};

export default RabForm;
