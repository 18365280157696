import React, { Fragment, useEffect, useId, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";

import {
  BottomFormAction,
  Button,
  ConfirmationModal,
  HeaderLabel,
  InputForm,
  Table,
} from "components";
import AlertBlankData from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import AtomSelect from "components/atoms/Select";
import { RiAddLine, RiDeleteBinLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import WarningAlert from "../components/WarningAlert";
import AccordionButton from "components/atoms/AccordionButton";
import { FaTrashAlt } from "react-icons/fa";
import AtomDatePicker from "components/atoms/Datepicker";
import TableForm from "../components/TableForm";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { FormModal } from "components/molecules/Modal";
import { createPortal } from "react-dom";
import {
  getDetailWorkPlan,
  getGeneralInformation,
  getListActivityCategory,
  postWorkPlan,
} from "services/danaProgram/callForProposalService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import _ from "lodash";

const validationSchema = yup.object({
  activities: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Nama Kegiatan wajib diisi"),
      category: yup
        .string()
        .test(
          "required",
          "Kategori Kegiatan wajib diisi",
          (value) => value?.value !== null
        ),
      execution_time: yup
        .array()
        .test(
          "required",
          "Rencana Waktu Pelaksanaan wajib diisi",
          (value) => value !== null
        )
        .test(
          "required_start_end_date",
          "Rencana Waktu Pelaksanaan wajib diisi",
          (value) =>
            value && Array.isArray(value) && !!value?.[0] && !!value?.[1]
        )
        .nullable(),
      description: yup.string().required("Deskripsi Kegiatan wajib diisi"),
      pic: yup.string().required("Penanggung Jawab wajib diisi"),
      verifications: yup.array().of(
        yup.object().shape({
          verification: yup
            .string()
            .required("Means of Verification wajib diisi"),
        })
      ),
      items: yup.array().of(
        yup.object().shape({
          name: yup.string().required("Nama Item wajib diisi"),
          qty: yup.string().required("QTY wajib diisi"),
          volume: yup.string().required("Volume wajib diisi"),
          unit: yup.string().required("Satuan/Unit wajib diisi"),
          price: yup.string().required("Harga Satuan wajib diisi"),
        })
      ),
      indicators: yup.array().of(
        yup.object().shape({
          parameter: yup.array().of(
            yup.object().shape({
              baseline: yup.string().required("Baseline wajib diisi"),
              target: yup.string().required("Target wajib diisi"),
            })
          ),
        })
      ),
    })
  ),
});

const defaultValues = {
  activities: [
    {
      name: "",
      category: null,
      description: "",
      pic: "",
      execution_time: null,
      verifications: [
        {
          verification: "",
        },
      ],
      indicators: [],
      items: [
        {
          name: "",
          qty: "",
          volume: "",
          unit: "",
          price: "",
          total: "",
        },
      ],
    },
  ],
};

const NestedVerifications = ({ nestedIndex, control }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const {
    fields: verificationFields,
    append: appendVerification,
    remove: removeVerification,
  } = useFieldArray({
    control,
    name: `activities[${nestedIndex}].verifications`,
  });

  return (
    <div>
      <div className="space-y-5">
        {verificationFields.map((field, key) => {
          return (
            <div className="w-full" key={"verification-field-" + field.id}>
              <div className="w-full">
                <div className="flex gap-1 font-semibold text-[14px] mb-2">
                  <span className={`label-text`}>Means of Verification</span>
                  <span className="text-[#F04438]">*</span>
                </div>
                <div className="flex w-full gap-6">
                  <InputForm
                    controllerName={`activities[${nestedIndex}].verifications.${key}.verification`}
                    className={`border w-full rounded-md flex-1`}
                    showErrorLabel={false}
                  />
                  {verificationFields.length > 1 && (
                    <Button
                      type="button"
                      className="rounded-lg bg-error-600 text-white shadow py-2 px-3.5 items-center font-semibold h-10"
                      label={
                        <RiDeleteBinLine size={20} className="text-white" />
                      }
                      onClick={() => {
                        removeVerification(key);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
                {
                  errors?.activities?.[nestedIndex]?.verifications?.[key]
                    ?.verification?.message
                }
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-end mt-5">
        <Button
          className="rounded-lg border border-gray-300 shadow py-2.5 px-4"
          label={
            <div className="flex gap-2 items-center text-gray-700 text-sm">
              <RiAddLine size={20} />
              Means of Verification
            </div>
          }
          onClick={() => {
            appendVerification({
              verification: "",
            });
          }}
        />
      </div>
    </div>
  );
};

const ParameterIndicator = ({
  control,
  param,
  activityIndex,
  indicatorIndex,
  parameterIndex,
}) => {
  const { register } = useFormContext();
  const checkboxId = useId();

  return (
    <>
      <div className="flex gap-3 bg-primary-maincolor-50 p-6 rounded-lg items-center cursor-pointer">
        <input
          {...register(
            `activities.${activityIndex}.indicators.${indicatorIndex}.parameter.${parameterIndex}.is_checked`
          )}
          id={checkboxId}
          type="checkbox"
          className="relative peer shrink-0 appearance-none bg-primary-maincolor-50 text-primary-maincolor-600 border border-primary-maincolor-600 rounded-md w-5 h-5 focus:ring-transparent focus:ring-0 checked:border-2 cursor-pointer"
        />
        <div className="text-gray-700 font-medium">
          Parameter {parameterIndex + 1} - {param?.nama}
        </div>
        <label
          htmlFor={checkboxId}
          className="absolute ml-0.5 w-4 h-4 hidden peer-checked:block text-primary-maincolor-600 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </label>
      </div>
      <Card>
        <div className="flex gap-6">
          <InputForm
            controllerName={`activities.${activityIndex}.indicators.${indicatorIndex}.parameter.${parameterIndex}.baseline`}
            className={`border flex-1 rounded-md w-full`}
            label="Baseline"
            placeholder="Baseline"
          />
          <InputForm
            controllerName={`activities.${activityIndex}.indicators.${indicatorIndex}.parameter.${parameterIndex}.target`}
            className={`border flex-1 rounded-md w-full`}
            label="Target Kontribusi"
            placeholder="Target Kontribusi"
          />
          <InputForm
            controllerName={`activities.${activityIndex}.indicators.${indicatorIndex}.parameter.${parameterIndex}.satuan`}
            className={`border flex-1 rounded-md w-full`}
            label="Satuan/Unit"
            placeholder="Satuan/Unit"
            disabled
          />
        </div>
      </Card>
    </>
  );
};

const CustomAccordionIndicators = ({ control, nestedIndex, index, item }) => {
  const [accordionIndicator, setAccordionIndicator] = useState(false);

  return (
    <Fragment>
      <AccordionButton
        label={`Indikator ${index + 1} -  ${item?.indicator}`}
        isOpen={accordionIndicator}
        onClick={() => setAccordionIndicator((curr) => !curr)}
      />
      <div
        className={`${
          accordionIndicator
            ? "opacity-1 h-full visible"
            : "opacity-0 h-0 invisible max-h-0"
        }`}
        style={{ transition: "all .2s" }}
      >
        <div className="space-y-6 h-full">
          <div className="text-lg font-semibold text-primary-maincolor-700">
            Parameter
          </div>
          {item?.parameter?.map((param, i) => (
            <ParameterIndicator
              key={"parameter-indicator-" + i}
              control={control}
              param={param}
              activityIndex={nestedIndex}
              indicatorIndex={index}
              parameterIndex={i}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

const IndicatorModal = ({
  modal,
  onClose,
  data,
  tableIndicatorColumns,
  onSubmit,
}) => {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const temp = data.map((item) => item);
      setDataSource(temp);
    }
  }, [data]);

  return createPortal(
    <FormModal
      title={modal.title}
      open={modal.open}
      onClose={onClose}
      onSubmit={onSubmit}
      positiveConfirm="Pilih"
      negativeConfirm="Batal"
    >
      <Table
        bordered
        stripped
        layout="fixed"
        className="mb-4"
        columns={tableIndicatorColumns}
        dataSource={dataSource}
        withPagination={false}
      />
    </FormModal>,
    document.body,
    "list-indicator-modal"
  );
};

const NestedIndicators = ({
  nestedIndex,
  control,
  onAppend,
  dataIndicator,
  modal,
  setModal,
}) => {
  const [collectIndicator, setCollectIndicator] = useState([]);
  const {
    formState: { errors },
  } = useFormContext();
  const {
    fields: indicatorFields,
    append: appendIndicator,
    remove: removeIndicator,
  } = useFieldArray({
    control,
    name: `activities[${nestedIndex}].indicators`,
  });

  const tableIndicatorColumns = useMemo(() => {
    return [
      {
        id: "checkbox",
        title: (value, data, index) => {
          const checkboxId = _.uniqueId("prefix-checkbox-");
          return (
            <div className="flex gap-3 items-center mt-1">
              <input
                id={checkboxId}
                type="checkbox"
                className="relative peer shrink-0 appearance-none bg-primary-maincolor-50 text-primary-maincolor-600 border border-primary-maincolor-600 rounded-md w-5 h-5 focus:ring-transparent focus:ring-0 checked:border-2"
                onChange={(e) => {
                  if (e.target.checked) setCollectIndicator(dataIndicator);
                  else setCollectIndicator([]);
                }}
              />
              <label
                for={checkboxId}
                className="absolute ml-0.5 w-4 h-4 hidden peer-checked:block text-primary-maincolor-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </label>
            </div>
          );
        },
        dataIndex: "checkbox",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const checkboxId = _.uniqueId("prefix-");
          const findIdx = collectIndicator?.findIndex(
            (el) => el?.indicator === data?.indicator
          );
          return (
            <div className="flex gap-3 items-center mt-1">
              <input
                id={checkboxId}
                type="checkbox"
                className="relative peer shrink-0 appearance-none bg-primary-maincolor-50 text-primary-maincolor-600 border border-primary-maincolor-600 rounded-md w-5 h-5 focus:ring-transparent focus:ring-0 checked:border-2"
                checked={
                  data?.indicator === collectIndicator?.[findIdx]?.indicator
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setCollectIndicator((prev) => [...prev, data]);
                  } else {
                    setCollectIndicator(
                      collectIndicator.filter(
                        (el) => el.indicator !== data?.indicator
                      )
                    );
                  }
                }}
              />
              <label
                for={checkboxId}
                className="absolute ml-0.5 w-4 h-4 hidden peer-checked:block text-primary-maincolor-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </label>
            </div>
          );
        },
      },
      {
        id: "indicator",
        title: "Indikator",
        dataIndex: "indicator",
        className: "overflow-hidden",
        sortable: true,
        render: (value) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full">{value}</div>
          );
        },
      },
    ];
  }, [collectIndicator, dataIndicator]);

  const onSubmitIndicator = async () => {
    appendIndicator(collectIndicator);

    await setModal((prev) => ({
      ...prev,
      type: "",
      open: false,
    }));
  };

  const onClose = () => {
    setModal((prev) => ({ ...prev, title: "", open: false, type: "" }));
    setCollectIndicator([]);
  };

  return (
    <>
      {modal?.type == "list-indicator" && (
        <IndicatorModal
          modal={modal}
          onClose={onClose}
          data={dataIndicator}
          tableIndicatorColumns={tableIndicatorColumns}
          onSubmit={onSubmitIndicator}
        />
      )}
      <Card>
        <div className="space-y-5">
          {indicatorFields.map((field, index) => (
            <CustomAccordionIndicators
              key={"custom-accordion-indicator-" + field.id}
              control={control}
              nestedIndex={nestedIndex}
              index={index}
              item={field}
            />
          ))}
        </div>
        <div className="flex items-center justify-end mt-5">
          <Button
            className="rounded-lg border border-gray-300 shadow py-2.5 px-4"
            label={
              <div className="flex gap-2 items-center text-gray-700 text-sm">
                <RiAddLine size={20} />
                Pilih Indikator
              </div>
            }
            onClick={onAppend}
          />
        </div>
      </Card>
    </>
  );
};

const Workplan = () => {
  const navigate = useNavigate();
  const [showConfirmModal, setConfirmModal] = useState({
    open: false,
    type: "draft",
    cancelLabel: "Tidak",
    confirmLabel: "Iya, simpan draft",
    title: "",
    description: "",
  });
  const [payload, setPayload] = useState(null);
  const [accordionActivity, setAccordionActivity] = useState([
    {
      open: false,
      index: 0,
      name: "Kegiatan 1",
    },
  ]);
  const [modal, setModal] = useState({ open: false, type: "", title: "" });
  const { id } = useSelector((state) => state?.callForProposalData?.data);
  const [categoryOption, setCategoryOption] = useState([]);
  // const { id } = useParams()

  const fetchDetailNoteConcept = async () => {
    const response = await getGeneralInformation({ id });

    if (response?.data?.code === 200) {
      return response?.data?.data?.note_concept?.awp?.investment?.step_3;
    } else return [];
  };

  const fetchDetailWorkPlan = async () => {
    const response = await getDetailWorkPlan(id);

    if (response?.data?.meta?.code === 200) return response?.data?.data;
    return;
  };

  const fetchListActivityCategory = async () => {
    const response = await getListActivityCategory();

    if (response?.code === 200) return response?.data;
    return;
  }

  const { data: dataIndicator = [] } = useQuery(
    ["application-get-detail-note-concept"],
    fetchDetailNoteConcept,
    {
      enabled: !_.isEmpty(id),
    }
  );

  const { data: dataWorkPlan = [] } = useQuery(
    ["application-get-detail-work-plan"],
    fetchDetailWorkPlan,
    {
      enabled: !_.isEmpty(id),
    }
  );

  const { data: dataActivityCategory } = useQuery({
    queryKey: ["application-get-list-activity-category"],
    queryFn: async () => {
      const response = await getListActivityCategory();

      return response?.data?.data;
    }
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { control } = methods;
  const dispatch = useDispatch();

  const {
    fields: activityFields,
    append: appendActivity,
    remove: removeActivity,
  } = useFieldArray({
    control,
    name: "activities",
  });

  const saveDraft = (values) => {
    let payload = {
      proposal_id: id,
      status: "DRAFT",
      work_plan: values.activities.map((activity) => ({
        activity_name: activity.name,
        activity_category: activity.category,
        activity_description: activity.description,
        pic: activity.pic,
        implementation_time_plan: `${dayjs(activity.execution_time[0]).format(
          "DD/MM/YYYY"
        )} - ${dayjs(activity.execution_time[1]).format("DD/MM/YYYY")}`,
        means_of_verification: activity.verifications.map(
          (ver) => ver.verification
        ),
        indicator: activity.indicators.map((indicator) => ({
          ...indicator,
          parameter: indicator.parameter.filter(
            (param) => param.is_checked === true
          ),
        })),
        list_of_items: activity.items.map((item) => ({
          item_name: item.name,
          quantity: parseInt(item.qty),
          volume: parseInt(item.volume),
          unit: item.unit,
          item_price: parseInt(item?.price?.replace(/\D/g, "")),
        })),
      })),
    };
    setPayload(payload);
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: "draft",
      cancelLabel: "Tidak",
      confirmLabel: "Iya, simpan draft",
      title: "Anda yakin akan menyimpan ini sebagai draft?",
      description:
        "Setelah anda menyimpan sebagai draft anda masih bisa melanjutkannya",
    }));
  };

  const submitForm = (values) => {
    let payload = {
      proposal_id: id,
      status: "SUBMIT",
      work_plan: values.activities.map((activity) => ({
        activity_name: activity.name,
        activity_category: activity.category,
        activity_description: activity.description,
        pic: activity.pic,
        implementation_time_plan: `${dayjs(activity.execution_time[0]).format(
          "DD/MM/YYYY"
        )} - ${dayjs(activity.execution_time[1]).format("DD/MM/YYYY")}`,
        means_of_verification: activity.verifications.map(
          (ver) => ver.verification
        ),
        indicator: activity.indicators.map((indicator) => ({
          ...indicator,
          parameter: indicator.parameter.filter(
            (param) => param.is_checked === true
          ),
        })),
        list_of_items: activity.items.map((item) => ({
          item_name: item.name,
          quantity: parseInt(item.qty),
          volume: parseInt(item.volume),
          unit: item.unit,
          item_price: parseInt(item.price.replace(/\D/g, "")),
        })),
      })),
    };
    setPayload(payload);
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: "submit",
      cancelLabel: "Tidak, ubah data",
      confirmLabel: "Ya, kirimkan",
      title: "Anda yakin akan mengirimkan semua data ini?",
      description:
        "Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku. ",
    }));
  };

  const onSubmit = () => {
    submitMutation.mutate(payload);
  };

  const submitMutation = useMutation({
    mutationKey: ["application-post-workplan"],
    mutationFn: async (payload) => {
      const res = await postWorkPlan(payload);
      return res;
    },
    onSuccess: (res) => {
      setConfirmModal((curr) => ({
        ...curr,
        open: false,
      }));
      enqueueSnackbar({
        message: "Data berhasil disimpan",
        variant: "success",
      });
      navigate(-1);
    },
  });

  const toggleAccordion = (index) => {
    const temp = [...accordionActivity];
    temp[index].open = !temp[index].open;
    setAccordionActivity(temp);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes(".") ? oriValue.split(".").join("") : oriValue;
    let modifValue = "";
    let count = 1;
    if (oriValue !== "" && oriValue.length > 3) {
      const splitValue = oriValue.split("");
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  useEffect(() => {
    if (!_.isEmpty(dataWorkPlan)) {
      methods.reset({
        activities: [
          {
            name: dataWorkPlan?.activity_name,
            category: dataWorkPlan?.activity_category,
            description: dataWorkPlan?.activity_description,
            pic: dataWorkPlan?.pic,
            execution_time: [
              new Date(dataWorkPlan?.time_plan_start),
              new Date(dataWorkPlan?.time_plan_end),
            ],
            verifications: dataWorkPlan?.means_of_verification?.map((ver) => ({
              verification: ver,
            })),
            items: dataWorkPlan?.list_of_items?.map((el) => ({
              name: el?.item_name,
              qty: String(el?.quantity),
              volume: String(el?.volume),
              unit: el?.unit,
              price: convertMoney(String(el?.item_price)),
              total: el?.total,
            })),
            indicators: dataWorkPlan.indicator,
          },
        ],
      });
    }
  }, [dataWorkPlan]);

  useEffect(() => {
    if(dataActivityCategory) {
      const temp = dataActivityCategory?.map(el => ({
        value: el?.name,
        label: el?.name
      }));

      setCategoryOption(temp);
    }
  }, [dataActivityCategory]);

  return (
    <Fragment>
      <ConfirmationModal
        useGraphic
        open={showConfirmModal.open}
        onCancel={() => {
          setConfirmModal((curr) => ({
            ...curr,
            open: false,
          }));
          setPayload(null);
        }}
        cancelLabel={showConfirmModal.cancelLabel}
        confirmLabel={showConfirmModal.confirmLabel}
        onSubmit={onSubmit}
        isLoading={submitMutation.isLoading}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {showConfirmModal.title}
          </div>
          <div className="text-sm text-gray-600">
            {showConfirmModal.description}
          </div>
        </div>
      </ConfirmationModal>
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10 space-y-8">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Call For Proposal",
                  path: `/proposal/call-for-proposal`,
                },
                {
                  label: "Pengajuan Full Proposal",
                },
              ]}
            />
            <div className="flex mt-5 gap-4">
              <div className="flex-1">
                <HeaderLabel text="Rencana Kerja" />
              </div>
            </div>
          </div>
          <div className="w-full">
            <AlertBlankData
              title={`Lengkapi Data`}
              body={`Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
            />
          </div>
          <FormProvider {...methods}>
            <CardForm label="Rencana Kerja">
              <div className="space-y-5">
                {activityFields.map((field, index) => (
                  <Fragment key={"activity-" + field.id}>
                    <div className="space-y-4">
                      <AccordionButton
                        key={"accordion-button-" + field.id}
                        label={accordionActivity?.[index]?.name}
                        isOpen={accordionActivity?.[index]?.open}
                        onClick={() => toggleAccordion(index)}
                      />

                      <div
                        className={`${
                          accordionActivity?.[index]?.open
                            ? "opacity-1 h-full visible"
                            : "opacity-0 h-0 invisible max-h-0"
                        }`}
                        style={{ transition: "all .2s" }}
                      >
                        <div className="space-y-5 h-full">
                          <InputForm
                            controllerName={`activities.${index}.name`}
                            className={`border flex-1 rounded-md w-full`}
                            label="Nama Kegiatan"
                            placeholder="Nama Kegiatan"
                            required
                          />
                          <AtomSelect
                            controllerName={`activities.${index}.category`}
                            className="basic-single flex-1"
                            wrapperClass={"col-span-1"}
                            label="Kategori Kegiatan"
                            placeholder="Kategori Kegiatan"
                            required
                            returnObject={false}
                            options={categoryOption}
                          />
                          <InputForm
                            label="Deskripsi Kegiatan"
                            controllerName={`activities.${index}.description`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                            placeholder="Deskripsi Kegiatan"
                            textArea={true}
                            rows={5}
                            required
                          />
                          <div className="flex gap-6">
                            <div className="w-1/2">
                              <AtomDatePicker
                                isRangeDatePicker
                                className="!mt-0"
                                controllerName={`activities.${index}.execution_time`}
                                label="Rencana Waktu Pelaksanaan"
                                placeholder="Rencana Waktu Pelaksanaan"
                                required
                                datepickerOptions={{
                                  dateFormat: "dd/MM/yyyy",
                                  minDate: new Date(),
                                }}
                                showErrorLabel
                              />
                            </div>
                            <div className="w-1/2">
                              <InputForm
                                controllerName={`activities.${index}.pic`}
                                className={`border flex-1 rounded-md w-full`}
                                label="Penanggung Jawab"
                                placeholder="Penanggung Jawab"
                                required
                              />
                            </div>
                          </div>
                          <NestedVerifications
                            control={control}
                            nestedIndex={index}
                          />
                        </div>
                      </div>
                    </div>
                    <WarningAlert text="Pilih Indikator yang sesuai dengan  kegiatan ini" />
                    <NestedIndicators
                      control={control}
                      nestedIndex={index}
                      dataIndicator={dataIndicator}
                      modal={modal}
                      setModal={setModal}
                      onAppend={() => {
                        setModal((prev) => ({
                          ...prev,
                          open: true,
                          type: "list-indicator",
                          title: "Pilih Indikator",
                        }));
                      }}
                    />
                    <hr />
                    <WarningAlert text="Tentukan Item Kebutuhan yang Diperlukan untuk Melaksanakan Kegiatan di Atas" />
                    <CardForm
                      label="Daftar Item Kebutuhan"
                      className="!bg-white"
                    >
                      <div className="">
                        <TableForm control={control} nestedIndex={index} />
                      </div>
                    </CardForm>
                    <div className="flex items-center justify-center gap-6">
                      {activityFields.length > 1 && (
                        <Button
                          className="rounded-lg border border-error-300 py-2.5 px-4"
                          onClick={() => {
                            setAccordionActivity((curr) =>
                              curr.filter((x) => x.index !== index)
                            );
                            removeActivity(index);
                          }}
                          label={
                            <div className="flex gap-2 items-center text-sm text-error-700">
                              <FaTrashAlt
                                size={20}
                                className="text-error-700"
                              />
                              Kegiatan
                            </div>
                          }
                        />
                      )}
                      {((index > 0 && index === activityFields.length - 1) ||
                        (index === 0 && activityFields.length === 1)) && (
                        <Button
                          className="rounded-lg border border-gray-300 shadow py-2.5 px-4"
                          label={
                            <div className="flex gap-2 items-center text-gray-700 text-sm">
                              <RiAddLine size={20} />
                              Kegiatan
                            </div>
                          }
                          onClick={() => {
                            setAccordionActivity((curr) => [
                              ...curr,
                              {
                                open: false,
                                name: `Kegiatan ${curr.length + 1}`,
                                index: curr.length,
                              },
                            ]);
                            appendActivity({
                              name: "",
                              category: null,
                              description: "",
                              pic: "",
                              execution_time: null,
                              verifications: [
                                {
                                  verification: "",
                                },
                              ],
                              items: [
                                {
                                  name: "",
                                  qty: "",
                                  volume: "",
                                  unit: "",
                                  price: "",
                                  total: "",
                                },
                              ],
                            });
                          }}
                        />
                      )}
                    </div>
                    <hr />
                  </Fragment>
                ))}
              </div>
            </CardForm>
            <BottomFormAction
              lastStep
              backButtonAction={() => navigate(-1)}
              disableDrafButton={false}
              drafButtonAction={() => {
                const values = methods.getValues();
                saveDraft(values);
              }}
              // disableButtonSubmit={isValid ? false : true}
              submitActionButton={() => {
                methods.handleSubmit(submitForm)();
              }}
            />
          </FormProvider>
        </div>
      </div>
    </Fragment>
  );
};

export default Workplan;
