import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CardForm from 'components/molecules/CardForm';
import { BottomFormAction, InputFormRadio, InputForm } from 'components';

const SdmIT = ({ prefix, onSubmit, onBack, onDraft, data }) => {
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      [prefix]: yup.object({
        using_app: yup.mixed().required(),
        details: yup.string(),
      }),
    });
  }, [prefix]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      [prefix]: {
        using_app: null,
        details: [],
      },
    },
  });

  useEffect(() => {
    methods.reset({ [prefix]: data });
    return;
  }, [methods, prefix, data]);

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-10">
        <CardForm label={'SDM Yang Menangani IT'}>
          <div className="space-y-6 pt-2 pb-4">
            <InputFormRadio
              label={
                'Apakah Anda telah menggunakan software/aplikasi dalam melaksanakan kegiatan Operasional sehari - hari?'
              }
              controllerName={`${prefix}.using_app`}
              required
              showErrorLabel={false}
              gridClassName="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2"
              values={[
                {
                  label: 'Ya',
                  value: true,
                },
                {
                  label: 'Tidak',
                  value: false,
                },
              ]}
            />
            <InputForm
              textArea
              label="SDM yang mengelola/menangani sistem IT"
              placeholder="Jelaskan SDM yang Mengelola/Menangani Sistem IT"
              controllerName={`${prefix}.details`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            />
          </div>
        </CardForm>
      </div>
      <BottomFormAction
        // lastStep={true}
        backButtonProps={{
          type: 'button',
        }}
        submitButtonProps={{
          type: 'submit',
        }}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        submitActionButton={onFormSubmit()}
        backButtonAction={onBack}
      />
    </FormProvider>
  );
};

export default SdmIT;
