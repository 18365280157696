const BADGE_COLORS = {
  default: 'bg-gray-100 text-gray-700',
  warning: 'bg-warning-100 text-warning-700',
  error: 'bg-error-100 text-error-700',
  success: 'bg-success-100 text-success-700',
  bluelight: 'bg-bluelight-100 text-bluelight-700',
};

const BADGE_SIZES = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
};

const Badge = ({ color = 'default', size = 'xs', className, children }) => (
  <span
    className={[
      'px-4 py-[2px] rounded-full text-xs font-medium inline-flex gap-2 items-center justify-center whitespace-nowrap',
      BADGE_COLORS[color],
      BADGE_SIZES[size],
      className,
    ].join(' ')}
  >
    {children}
  </span>
);

export default Badge;
