import { Button } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";
import classNames from "classnames";

const FormModal = ({ 
  title = "", 
  open, 
  onClose, 
  onSubmit, 
  children,
  positiveConfirm = "Submit",
  negativeConfirm = "Cancel",
  size = "w-1/2",
  withHeader = true,
  titleClassName=""
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          <motion.div
            key="modal-confirmation"
            className={`bg-white rounded-xl ${size}`}
            initial={{ scale: 0 }}
            animate={{ scale: 1, animation: 10 }}
            exit={{ scale: 0 }}
          >
            <div className="flex flex-col py-4 space-y-4">
              {withHeader && (
                <div className="flex-1 flex items-center justify-between px-8">
                  <p
                    className={classNames([
                      "text-gray-900",
                      "mb-0",
                      "text-[18px]",
                      "font-semibold"
                    ])}
                  > 
                    
                    {title}
                  </p>
                  <CgClose
                    className="text-xl cursor-pointer"
                    onClick={onClose}
                  />
                </div>
              )}
              <div className="flex-1 border-y max-h-[400px] px-8 py-4">{children}</div>
              <div className="flex-1 flex items-center space-x-4 px-8">
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1"
                  onClick={() => onClose(false)}
                  label={negativeConfirm}
                 />
                <Button
                  type="button"
                  className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
                  onClick={onSubmit}
                  label={positiveConfirm}
                />
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default FormModal;