import TablePagination from 'components/atoms/Pagination';
import TablePageSizeField from 'components/atoms/TablePageSizeField';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiPlus } from 'react-icons/fi';
import { RiEyeLine } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import Alert from 'components/atoms/Alert';
import Badge from 'components/atoms/Badge';
import Button from 'components/atoms/Button/Button';

const items = [
  {
    id: 1,
    date: '2023/01/10',
    type: 'Permohonan Baru',
    value: 'Rp30,000,000',
    state: 'Draft',
  },
  {
    id: 2,
    date: '2023/01/10',
    type: 'Resolving',
    value: 'Rp50,000,000',
    state: 'Menunggu Revisi',
  },
  {
    id: 3,
    date: '2023/01/10',
    type: 'Resolving',
    value: 'Rp20,000,000',
    state: 'Ditolak',
  },
  {
    id: 1,
    date: '2023/01/10',
    type: 'Permohonan Baru',
    value: 'Rp40,000,000',
    state: 'Disetujui',
  },
];

const TYPE_BADGE_COLORS = {
  'Permohonan Baru': 'warning',
  Resolving: 'bluelight',
};

const STATE_BADGE_COLORS = {
  Draft: 'default',
  'Menunggu Revisi': 'warning',
  Ditolak: 'error',
  Disetujui: 'success',
};

const NonPeroranganRequests = () => {
  const navigate = useNavigate();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      pageSize: 5,
      page: 1,
    },
  });

  return (
    <div className="space-y-6">
      <div className="w-full p-10 bg-white rounded-xl border border-gray-200 backdrop-blur-[80px] space-y-6">
        <BreadCrumbs
          routes={[
            {
              label: 'Daftar Permohonan',
              path: '/financing-request',
            },
          ]}
        />
        <div className="flex items-center justify-between">
          <div>
            <div className="text-gray-900 text-2xl font-semibold leading-normal">
              Daftar Permohonan
            </div>
            <p className="capitalize text-gray-600">
              daftar permohonan Non Perorangan
            </p>
          </div>
          <div className="justify-start items-center gap-3 inline-flex">
            <Button
              color="orange"
              className="px-4 py-2.5 capitalize leading-tight"
              label={
                <div className="flex items-center space-x-2">
                  <FiPlus />
                  <p>Buat Permohonan Baru</p>
                </div>
              }
            />
          </div>
        </div>
      </div>

      <Alert
        title="Proses Permohonan Sedang Berjalan"
        description="Anda tidak bisa menambakan permohonan baru karena masih ada proses permohonan yang berjalan."
        theme="warning"
      />

      <div className="overflow-x-auto relative max-w-full shadow-sm rounded-lg">
        <table className="w-full bg-white rounded-lg">
          <thead className="bg-primary-800 text-white">
            <tr>
              <th className="px-6 text-sm font-semibold py-3 rounded-tl-lg sticky left-0 bg-primary-800 text-center">
                No
              </th>
              <th className="px-6 text-sm font-semibold py-3 text-left">
                Tanggal Permohonan
              </th>
              <th className="px-6 text-sm font-semibold py-3 text-left">
                Jenis Permohonan
              </th>
              <th className="px-6 text-sm font-semibold py-3 text-left">
                Nilai Permohonan
              </th>
              <th className="px-6 text-sm font-semibold py-3 text-left">
                Status
              </th>
              <th className="px-6 text-sm font-semibold py-3 rounded-tr-lg text-center sticky right-0 bg-primary-800">
                Aksi
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {items.length > 0 &&
              items.map((item, index) => (
                <tr>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 sticky left-0 bg-white text-center">
                    {index + 1}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                    {item.date}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                    <Badge color={TYPE_BADGE_COLORS[item.type]}>
                      {item.type}
                    </Badge>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                    {item.value}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                    <Badge color={STATE_BADGE_COLORS[item.state]}>
                      {item.state}
                    </Badge>
                  </td>
                  <td
                    className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 text-center sticky right-0 bg-white"
                    style={{
                      boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    {item.state === 'Draft' && (
                      <Button
                        color="success"
                        shadow="none"
                        size="sm"
                        label="Lanjutkan Draft"
                      />
                    )}
                    {item.state !== 'Draft' && (
                      <Button
                        shadow="none"
                        size="sm"
                        icon
                        label={<RiEyeLine className="text-xl" />}
                      />
                    )}
                  </td>
                </tr>
              ))}
            {items.length < 1 && (
              <tr>
                <td
                  colSpan={6}
                  className="px-6 text-sm py-6 text-center text-gray-600"
                >
                  Data Kosong.
                </td>
              </tr>
            )}
          </tbody>
          <tfoot className="border-t">
            <tr className="rounded-b-lg">
              <td colSpan={6} className="px-6 py-3 rounded-b-lg">
                <FormProvider {...methods}>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4 text-sm">
                      <TablePageSizeField />
                      <div>1-5 data dari 1.523 data.</div>
                    </div>
                    <TablePagination
                      page={1}
                      totalPage={10}
                      // onChange={(page) => methods.setValue("page", page)}
                    />
                  </div>
                </FormProvider>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default NonPeroranganRequests;
