import { useMutation } from '@tanstack/react-query';
import TablePagination from 'components/atoms/Pagination';
import TablePageSizeField from 'components/atoms/TablePageSizeField';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { createChannelingDirectApplication } from 'services';
import Button from 'components/atoms/Button/Button';
import { useAuth } from 'hooks/auth';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';

const FinancingRequest = () => {
  const navigate = useNavigate();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      pageSize: 5,
      page: 1,
    },
  });

  const { id } = useAuth();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      const payload = new FormData();
      payload.append('user_id', id);
      return createChannelingDirectApplication(payload);
    },
    onSuccess() {
      navigate('/bumdes/supplier-application');
    },
    onError(error) {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: 'error',
      });
    },
  });

  const createApplication = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <div className="space-y-6">
      <div className="w-full p-10 bg-white rounded-xl border border-gray-200 backdrop-blur-[80px] space-y-6">
        <BreadCrumbs
          routes={[
            {
              label: 'Daftar Permohonan',
              path: '/bumdes',
            },
          ]}
        />
        <div className="flex items-center justify-between">
          <div className="text-gray-900 text-2xl font-semibold leading-loose">
            Daftar Permohonan
          </div>
          <div className="justify-start items-center gap-3 inline-flex">
            <Button
              color="error"
              loading={isLoading}
              label={
                <div className="flex items-center space-x-2">
                  {!isLoading && <FiPlus />}
                  <p>Buat Permohonan Baru</p>
                </div>
              }
              onClick={createApplication}
            />
          </div>
        </div>
      </div>
      <table
        className="w-full bg-white border border-gray-200"
        style={{ boxShadow: '0px 20px 40px 0px rgba(51, 40, 123, 0.04)' }}
      >
        <thead className="bg-primary-800 text-white">
          <tr>
            <td className="px-6 py-3">No</td>
            <td className="px-6 py-3">Tanggal Permohonan</td>
            <td className="px-6 py-3">Jenis Permohonan</td>
            <td className="px-6 py-3">Nilai Permohonan</td>
            <td className="px-6 py-3">Status</td>
            <td className="px-6 py-3">Aksi</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={6} className="px-6 py-6"></td>
          </tr>
        </tbody>
        <tfoot className="border-t ">
          <tr>
            <td colSpan={6} className="px-6 py-3">
              <FormProvider {...methods}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4 text-sm">
                    <TablePageSizeField />
                    <div>1-5 data dari 1.523 data.</div>
                  </div>
                  <TablePagination
                    page={1}
                    totalPage={10}
                    // onChange={(page) => methods.setValue("page", page)}
                  />
                </div>
              </FormProvider>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default FinancingRequest;
