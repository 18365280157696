import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../helpers";
import axios from "axios";
import { config_fdb } from "app/config";

// util func
const doGetProfile = async (params) => {
  const formData = new FormData();
  formData.append("email", params);

  try {
    const res = await axios.post(
      `${config_fdb.BASE_URL}/v1/login/email`,
      formData
    );

    return res.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// thunks
export const getProfileFromLogin = createAsyncThunk(
  "profileLogin",
  async (token, thunkAPI) => {
    const response = await doGetProfile();

    return response.data;
  }
);

const initialState = {
  // TODO: Replace `data` object with actual user profile data
  data: {
    _id: "",
    reference_id: "",
    email: "",
    role: "",
    created_at: "",
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearData: (state, action) => {
      state.data = action.payload;
    },
    profileRequest: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    profileSuccess: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.errorMessage = null;
      state.data = action?.payload;
    },
    profileFailed: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = getErrorMessage(action?.payload);
    },
    clearProfile: (state, action) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileFromLogin.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = null;
      })
      .addCase(getProfileFromLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.errorMessage = null;
        state.data = action?.payload;
      })
      .addCase(getProfileFromLogin.rejected, (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = true;
        state.errorMessage = getErrorMessage(action?.payload);
      });
  },
});

// this is for dispatch
export const { profileRequest, profileFailed, profileSuccess, clearProfile } =
  profileSlice.actions;

export const getProfile = (params, shouldRedirect = false) => {
  return async (dispatch, getState) => {
    dispatch(profileRequest());

    try {
      const data = await doGetProfile(params);

      dispatch(profileSuccess(data));
      if (shouldRedirect) {
        if (window.location.href.indexOf("signin-oidc") !== -1) {
          window.location.replace(localStorage.getItem("redirectUri"));
        }
      }
    } catch (error) {
      dispatch(profileFailed(error.response));
    }
  };
};

// this is for configureStore
export default profileSlice.reducer;
