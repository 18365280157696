import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Button, InputForm, InputFormUpload, Table } from "components";
import Checkbox from "components/atoms/Checkbox";
import CardForm from "components/molecules/CardForm";
import { AnimatePresence, motion } from "framer-motion";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  RiCloseLine,
  RiDeleteBin5Line,
  RiDownloadCloud2Line,
  RiFile2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { getFile } from "services/danaProgram/fileService";
import { postFileServices } from "services/fdb/akunKelompok";
import { deleteDocumentFormulirPendaftaran } from "services/fdb/perhutananSosial/registerForm";
import * as yup from "yup";

const Document = () => {
  const [isUploadOtherDoc, setIsUploadOtherDoc] = useState(false);

  const methods = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: "documents",
  });

  const mutateFileService = useMutation(["File Service"], postFileServices);
  const mutateDeleteDocument = useMutation(
    ["DELETE_DOCUMENT"],
    deleteDocumentFormulirPendaftaran,
    {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Dokumen Legalitas berhasil dihapus",
        });
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Dokumen Legalitas gagal dihapus",
        });
      },
    }
  );

  const handleMountFile = useCallback(async () => {
    const docs = methods.getValues("documents");

    for (let i = 0; i < docs.length; i++) {
      if (
        !docs[i].doc_id ||
        typeof docs[i].selected_file === "object" ||
        docs[i].selected_file === "" ||
        docs[i].selected_file === "undefined"
      ) {
        continue;
      }

      const res = await getFile(docs[i].doc_id);

      methods.setValue(`documents[${i}].selected_file`, res.data.data);
    }
  }, [methods]);

  const handleUpload = useCallback(
    (field, files, index) => {
      const formData = new FormData();

      formData.append("file", files[0]);

      mutateFileService.mutate(formData, {
        onSuccess: (res) => {
          enqueueSnackbar({
            message: "Dokumen Berhasil Disimpan!",
            variant: "success",
          });
          field.onChange(res.data.data);
          methods.setValue(`documents.${index}.doc_id`, res.data.data.id);
        },
        onError: (err) => {
          enqueueSnackbar({
            message: "Dokumen Gagal Disimpan!",
            variant: "failed",
          });
        },
      });
    },
    [mutateFileService, methods]
  );

  const handleDelete = useCallback(
    (field, doc, index) => {
      if (doc.id) {
        mutateDeleteDocument.mutate(doc.id);
      }
      remove(index);
      field.onChange("");
    },
    [mutateDeleteDocument, remove]
  );

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "no",
        dataIndex: "#",
        columnClassName: "w-[50px]",
        render: (_, __, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        id: "document",
        title: "document",
        dataIndex: "document",
        columnClassName: "",
        render: (_, data, index) => {
          return (
            <div className="flex flex-col">
              <p className="text-sm font-bold">
                {data.name} <span className="text-sm text-red-600">*</span>
              </p>
            </div>
          );
        },
      },
      {
        id: "document",
        title: "Deskripsi",
        dataIndex: "document",
        columnClassName: "",
        render: (_, data, index) => {
          return (
            <div className="flex gap-2 items-center text-blue-500 cursor-pointer">
              <RiDownloadCloud2Line size={20} />
              <p className="text-sm font-semibold">Unduh Dokumen Rujukan</p>
            </div>
          );
        },
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        columnClassName: "w-min",
        fixed: "right",
        render: (_, data, index) => {
          return (
            <div className="flex flex-col">
              <Controller
                control={methods.control}
                name={`documents[${index}].selected_file`}
                render={({ field }) => {
                  const doc = methods.watch(`documents[${index}]`);
                  return (
                    <div className="flex flex-col gap-2">
                      {(typeof doc.selected_file === "undefined" ||
                        typeof doc.selected_file !== "object") && (
                        <label
                          htmlFor={`upload_files${index}`}
                          className="flex gap-2 items-center text-primary-700"
                          type="button"
                        >
                          <input
                            type="file"
                            name=""
                            id={`upload_files${index}`}
                            className="hidden"
                            onChange={(e) => {
                              handleUpload(field, e.target.files, index);
                            }}
                          />
                          <RiUploadCloud2Line size={20} />
                          <h5 className="text-sm font-semibold">Upload</h5>
                        </label>
                      )}

                      {typeof doc.selected_file === "object" && (
                        <div className="flex flex-col gap-3">
                          <div className="flex gap-2 items-center">
                            <div className="p-2 bg-primary-100 rounded-full text-primary-700">
                              <RiFile2Line size={18} />
                            </div>
                            <p className="whitespace-nowrap text-sm font-medium">
                              {doc.selected_file.name}
                            </p>
                          </div>

                          <div className="flex gap-4">
                            {index > 0 && (
                              <button
                                className="flex gap-1 items-center text-red-700 font-semibold text-sm"
                                onClick={() => handleDelete(field, doc, index)}
                                type="button"
                              >
                                <RiDeleteBin5Line size={20} />
                                <p>Delete</p>
                              </button>
                            )}
                            <label
                              htmlFor="upload_file"
                              className="flex gap-2 items-center text-primary-700"
                              type="button"
                            >
                              <input
                                type="file"
                                name=""
                                id="upload_file"
                                className="hidden"
                                onChange={(e) => {
                                  handleUpload(field, e.target.files, index);
                                }}
                              />
                              <RiUploadCloud2Line size={20} />
                              <h5 className="text-sm font-semibold">Upload</h5>
                            </label>
                          </div>
                        </div>
                      )}

                      <p>Tipe File : PDF ; Max Size : 5mb</p>
                    </div>
                  );
                }}
              />
            </div>
          );
        },
      },
    ];
  }, [methods, handleDelete, handleUpload]);

  const handleAddOtherDoc = (payload) => {
    const formData = new FormData();

    formData.append("file", payload.selected_file);

    mutateFileService.mutate(formData, {
      onSuccess: (res) => {
        enqueueSnackbar({
          message: "Dokumen Berhasil Disimpan!",
          variant: "success",
        });

        const otherDoc = {
          id: null,
          name: payload.name,
          key: "other",
          selected_file: res.data.data,
          type: "other",
        };

        append(otherDoc);
        setIsUploadOtherDoc(false);

        // field.onChange(res.data.data);
      },
      onError: (err) => {
        enqueueSnackbar({
          message: "Dokumen Gagal Disimpan!",
          variant: "error",
        });
      },
    });
  };

  useEffect(() => {
    handleMountFile();
  }, [handleMountFile]);

  return (
    <>
      {createPortal(
        <UploadOtherDocumentModal
          open={isUploadOtherDoc}
          onClose={() => setIsUploadOtherDoc(false)}
          onSubmitForm={handleAddOtherDoc}
        />,
        document.body
      )}
      <div className="space-y-6">
        <CardForm label="Legalitas">
          <div className="flex justify-between">
            <h5 className="text-lg font-semibold text-primary-700">
              Daftar Dokumen
            </h5>
            <Button
              className="bg-primary-600 text-white"
              label={
                <div className="flex gap-2 items-center">
                  <RiUploadCloud2Line size={20} />
                  <h5>Upload Dokumen Lainnya</h5>
                </div>
              }
              type="button"
              onClick={() => setIsUploadOtherDoc(true)}
            />
          </div>
          <Table
            columns={tableColumns}
            dataSource={fields}
            withPagination={false}
          />
        </CardForm>

        {/* {!isView ? ( */}
        <Checkbox
          controllerName="agree"
          label={() => (
            <div>
              <p className="font-medium">Saya Setuju</p>
              <p>
                {/* {message ??
                "Demikian permohonan, segala isi dan pernyataan yang terdapat pada formulir ini kami buat dengan sebenar-benarnya tanpa ada paksaan dari suatu apapun."} */}
                Segala isi dan pernyataan yang terdapat pada formulir ini kami
                buat dengan sebenar-benarnya tanpa ada paksaan dari suatu apapun
                dan bertanggung jawab apabila data disebutkan diatas tidak benar
                adanya
              </p>
            </div>
          )}
          inputWrapperClass="bg-[#EEFFF4] p-6 flex gap-4 items-start rounded-lg"
        />
        {/* ) : undefined} */}

        <div className="bg-[#F9FCF6] p-6 grid grid-cols-1 md:grid-cols-2 gap-4 rounded-lg border">
          <div className="col-span-1">
            <InputForm
              controllerName="created_at_display"
              className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
              label="Dibuat Pada Tanggal"
              placeholder=""
              disabled
              // asText={isView}
            />
            <InputForm
              controllerName="created_at"
              className="py-2 px-4 mt-2 border w-full rounded-md flex-1 hidden"
              type="hidden"
            />
          </div>
          <div className="col-span-1">
            <InputForm
              controllerName="created_from"
              className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
              label="Dibuat Pada ( tempat)"
              placeholder=""
              // asText={isView}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const otherSchemaValidation = yup.object({
  name: yup.string().required("Nama dokumen lainnya harus diisi"),
  selected_file: yup.string().required("Dokumen harus diisi"),
  desc: yup.string().required("Deskripsi harus diisi"),
});

const UploadOtherDocumentModal = (props) => {
  const methods = useForm({
    resolver: yupResolver(otherSchemaValidation),
    mode: "onChange",
  });

  const handleClose = () => {
    methods.reset();

    props.onClose();
  };

  const onSubmit = () => {
    const payload = methods.getValues();

    props.onSubmitForm(payload);

    methods.reset();
  };

  return (
    <AnimatePresence mode="wait">
      {props.open && (
        <div className="h-screen w-full bg-black/70 z-50 fixed inset-0 overflow-y-hidden flex justify-center items-center">
          <motion.div
            key="modal-confirmation"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="flex flex-col gap-5 px-8 py-6 bg-white rounded-xl min-w-[400px] shadow-md"
          >
            {/* HEADER */}
            <div className="flex items-center">
              <h5 className="flex flex-grow text-lg font-semibold">
                Upload Dokumen Lainnya
              </h5>
              <RiCloseLine
                size={24}
                className="text-gray-500 cursor-pointer"
                onClick={handleClose}
              />
            </div>

            {/* CONTENT */}
            <FormProvider {...methods}>
              <div className="flex flex-col space-y-5">
                <InputForm
                  label="Nama Dokumen Lainnya"
                  controllerName="name"
                  className="w-full"
                  required
                />

                <InputFormUpload
                  controllerName="selected_file"
                  label="Dokumen Lainnya"
                />

                <div className="flex flex-col gap-1">
                  <InputForm
                    label="Deskripsi"
                    controllerName="desc"
                    className="w-full h-[100px]"
                    required
                  />
                </div>
              </div>

              <div className="flex gap-3">
                <Button
                  label="Batal"
                  className="flex-grow"
                  outline
                  onClick={handleClose}
                  type="button"
                />
                <Button
                  label="Submit"
                  className="flex-grow bg-primary-600 hover:bg-primary-700 text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </FormProvider>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Document;
