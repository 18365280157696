import React, { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import * as yup from 'yup';
import CardForm from 'components/molecules/CardForm';
import { BottomFormAction, Spinner } from 'components';
import {
  RiDeleteBinLine,
  RiDownloadCloud2Line,
  RiUploadCloud2Line,
} from 'react-icons/ri';
import { map } from 'lodash';
import { FormInput } from './components';
import UploadDocModal from 'components/molecules/Modal/UploadDocModal';
import { createPortal } from 'react-dom';
import { useMutation } from '@tanstack/react-query';
import { createChannelingDirectApplicationFinanceDocument } from 'services';
import { useAuth } from 'hooks/auth';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';
import Button from 'components/atoms/Button/Button';

const DokumenLegalitas = ({ onSubmit, onBack, onDraft, draftData }) => {
  const [uploadModal, setUploadModal] = useState(false);

  const validationSchema = yup.object().shape({
    no_izin_kap: yup
      .string()
      .required()
      .test('number_only', (val) => {
        if (val) {
          return /^\d+$/.test(val);
        }
        return true;
      })
      .max(50)
      .label('No Izin KAP'),
    nama_kap: yup.string().optional().max(150).label('Nama KAP Yang Mengaudit'),
    supporting_documents: yup.array().of(
      yup.object().shape({
        title: yup.string().required().label('Nama Dokumen'),
        desc: yup.string().required().label('Deskripsi Dokumen'),
        uploadedFile: yup.mixed().required().label('File Dokumen'),
      })
    ),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      no_izin_kap: draftData?.no_izin_kap || '',
      nama_kap: draftData?.nama_kap || '',
      supporting_documents: draftData?.supporting_documents || [],
    },
  });

  const values = methods.getValues();

  const { append, remove } = useFieldArray({
    control: methods.control,
    name: `supporting_documents`,
  });

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  const doUpload = (data) => {
    append(data);
    setUploadModal(false);
  };

  return (
    <FormProvider {...methods}>
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      <div className="space-y-10">
        <CardForm label={'Dokumen Pendukung'}>
          <div className="space-y-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <FormInput
                controllerName={'no_izin_kap'}
                label={'No izin KAP'}
                placeholder={'Contoh: 123456789'}
                required
              />
              <FormInput
                controllerName={'nama_kap'}
                label={'Nama KAP Yang Mengaudit (Jika Ada)'}
                required
              />
            </div>
          </div>
        </CardForm>
        <div>
          <div className="flex justify-between items-center gap-4 mb-4">
            <h3 className="text-primary-700 font-semibold text-lg">
              Daftar Dokumen
            </h3>
            <Button
              type="button"
              color="primary-subgreen1"
              label={
                <>
                  <RiUploadCloud2Line className="text-white" />
                  <span>Upload Dokumen Lainnya</span>
                </>
              }
              onClick={() => setUploadModal(true)}
            />
          </div>
          <SupportingDocumentsTable removeDocument={remove} />
        </div>
      </div>
      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onFormSubmit()}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        disableButtonSubmit={false}
      />
      {createPortal(
        <UploadDocModal
          open={uploadModal}
          onClose={setUploadModal}
          setOpenModal={setUploadModal}
          onSubmit={doUpload}
          className="space-y-2 mb-4"
          allowedFileByExt={['pdf']}
          maxSize={15 * 1024 * 1024}
        />,
        document.body
      )}
    </FormProvider>
  );
};

const SupportingDocumentsTable = ({ removeDocument }) => {
  const { control } = useFormContext();
  const fields = useWatch({
    control,
    name: 'supporting_documents',
  });

  const { id } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => {
      return createChannelingDirectApplicationFinanceDocument(data);
    },
    onSuccess() {
      enqueueSnackbar('Dokumen berhasil diupload', {
        variant: 'success',
      });
    },
    onError(error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    },
  });

  const uploadDocument = useCallback(
    (field, index) => {
      setSelectedIndex(index);
      const data = new FormData();
      data.append('user_id', id);
      data.append('name', field.title);
      data.append('description', field.desc);
      data.append('file', field.file);
      mutate(data);
    },
    [mutate, id, setSelectedIndex]
  );

  const removeDoc = useCallback(
    (index) => {
      removeDocument(index);
    },
    [removeDocument]
  );

  return (
    <table
      className="w-full bg-white border border-gray-200 rounded-lg"
      style={{ boxShadow: '0px 20px 40px 0px rgba(51, 40, 123, 0.04)' }}
    >
      <thead className="bg-primary-800 text-white rounded-t-lg">
        <tr className="rounded-t-lg">
          <td className="px-6 py-3 font-semibold rounded-tl-lg">No</td>
          <td className="px-6 py-3 font-semibold">Nama Dokumen</td>
          <td className="px-6 py-3 font-semibold">Deskripsi</td>
          <td className="px-6 py-3 font-semibold rounded-tr-lg text-center">
            Aksi
          </td>
        </tr>
      </thead>
      <tbody className="divide-y">
        {fields.length < 1 && (
          <tr className="rounded-b-lg">
            <td colSpan={4} className="px-6 rounded-b-lg py-3 text-center">
              <div className="text-gray-500 text-sm">Belum ada dokumen</div>
            </td>
          </tr>
        )}
        {map(fields, (field, key) => (
          <>
            <tr key={field.id} className="">
              <td className="px-6 py-3">{key + 1}</td>
              <td className="px-6 py-3 text-gray-600 text-sm">{field.title}</td>
              <td className="px-6 py-3 text-gray-600 text-sm">
                <div className="space-y-1">
                  <div className="text-sm text-gray-600">{field.desc}</div>
                  <Button
                    type="button"
                    variant="link"
                    color="blue"
                    label={
                      <>
                        <RiDownloadCloud2Line className="text-current" />
                        <span>Unduh Dokumen Rujukan</span>
                      </>
                    }
                  />
                </div>
              </td>
              <td colSpan={1} className="px-6 py-3 text-center">
                <div className="flex gap-4 items-center justify-center">
                  <Button
                    onClick={() => uploadDocument(field, key)}
                    type="button"
                    variant="link"
                    color="primary"
                    label={
                      <>
                        {isLoading && selectedIndex === key ? (
                          <Spinner />
                        ) : (
                          <RiUploadCloud2Line className="text-current text-sm" />
                        )}
                        <span>
                          {isLoading && selectedIndex === key
                            ? 'Uploading...'
                            : 'Upload'}
                        </span>
                      </>
                    }
                  />
                  <Button
                    color="error"
                    variant="link"
                    label={<RiDeleteBinLine class="text-sm" />}
                    onClick={() => removeDoc(key)}
                  />
                </div>
              </td>
            </tr>
          </>
        ))}
      </tbody>
    </table>
  );
};

export default DokumenLegalitas;
