import Offer from "pages/FDB/Offer"
import OfferDetail from "pages/FDB/Offer/Detail"

const OfferComp = (props) => () => <Offer {...props} />
const OfferDetailCom = (props) => () => <OfferDetail {...props} />


const PageTypeLembaga = {
  bank_ventura: {
    group: "lembaga-penyalur",
    type: "bank-ventura"
  },
  koperasi: {
    group: "lembaga-penyalur",
    type: "koperasi"
  },
  non_koperasi: {
    group: "lembaga-penyalur",
    type: "non-koperasi"
  },
  badan_hukum: {
    group: "lembaga-penyalur",
    type: "badan-hukum"
  },
  bumdes: {
    group: "non-perorangan",
    type: "bumdes"
  },
  cv: {
    group: "non-perorangan",
    type: "cv"
  },
  pt: {
    group: "non-perorangan",
    type: "pt"
  },
  firma: {
    group: "non-perorangan",
    type: "firma"
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/penawaran/lembaga-penyalur/bank-ventura",
    element: OfferComp(PageTypeLembaga.bank_ventura),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/penawaran/lembaga-penyalur/koperasi",
    element: OfferComp(PageTypeLembaga.koperasi),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/penawaran/lembaga-penyalur/non-koperasi",
    element: OfferComp(PageTypeLembaga.non_koperasi),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/penawaran/lembaga-penyalur/badan-hukum",
    element: OfferComp(PageTypeLembaga.badan_hukum),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/penawaran/non-perorangan/bumdes",
    element: OfferComp(PageTypeLembaga.bumdes),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.bumdes,
  },
  {
    routeType: "private",
    path: "/penawaran/non-perorangan/cv",
    element: OfferComp(PageTypeLembaga.cv),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.cv,
  },
  {
    routeType: "private",
    path: "/penawaran/non-perorangan/pt",
    element: OfferComp(PageTypeLembaga.pt),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.pt,
  },
  {
    routeType: "private",
    path: "/penawaran/non-perorangan/firma",
    element: OfferComp(PageTypeLembaga.firma),
    exact: true,
    sidebar: true,
    sidebarProps: PageTypeLembaga.firma,
  },
  {
    routeType: "private",
    path: "/penawaran/lembaga-penyalur/bank-ventura/detail/:id",
    element: OfferDetailCom(PageTypeLembaga.bank_ventura),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/penawaran/lembaga-penyalur/koperasi/detail/:id",
    element: OfferDetailCom(PageTypeLembaga.koperasi),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/penawaran/lembaga-penyalur/non-koperasi/detail/:id",
    element: OfferDetailCom(PageTypeLembaga.non_koperasi),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/penawaran/lembaga-penyalur/badan-hukum/detail/:id",
    element: OfferDetailCom(PageTypeLembaga.badan_hukum),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/penawaran/non-perorangan/bumdes/detail/:id",
    element: OfferDetailCom(PageTypeLembaga.bumdes),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.bumdes,
  },
  {
    routeType: "private",
    path: "/penawaran/non-perorangan/cv/detail/:id",
    element: OfferDetailCom(PageTypeLembaga.cv),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.cv,
  },
  {
    routeType: "private",
    path: "/penawaran/non-perorangan/pt/detail/:id",
    element: OfferDetailCom(PageTypeLembaga.pt),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.pt,
  },
  {
    routeType: "private",
    path: "/penawaran/non-perorangan/firma/detail/:id",
    element: OfferDetailCom(PageTypeLembaga.firma),
    exact: true,
    sidebar: false,
    sidebarProps: PageTypeLembaga.firma,
  },
]

