import { general_config } from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

export const registerChannelingAgency = async (params) => {
  const { payload, query } = params
  return await axiosInstance.patch(
    `${general_config.BASE_URL}/fdb-v2/v1/agency/register?agency_type=${query}`,
    payload
  );
};

export const getAgencyProfile = async (params) => {
  return await axiosInstance.get(`${general_config.BASE_URL}/fdb-v2/v1/agency/profile`, params);
};

export const patchAgencyProfile = async (data, query, params) => {
  return await axiosInstance.put(`${general_config.BASE_URL}/fdb-v2/v1/agency/profile/${query.id}?distribution=${query.distribution}&is_draft=${query.is_draft}`, data, { params });
}

export const getAgencyRequest = async (params) => {
  return await axiosInstance.get(`${general_config.BASE_URL}/fdb-v2/v1/agency-request`, {
    params
  })
}

export const getAgencyRequestById = async (id, params) => {
  return await axiosInstance.get(`${general_config.BASE_URL}/fdb-v2/v1/agency-request/${id}`, {
    params
  })
}

export const patchAgencyRequestApplication = async (data, params) => {
  return await axiosInstance.patch(`${general_config.BASE_URL}/fdb-v2/v1/agency-request/application`, data, {
    params
  })
}

export const patchAgencyRequestFinance = async (data, params) => {
  console.log('params', params)
  return await axiosInstance.patch(`${general_config.BASE_URL}/fdb-v2/v1/agency-request/finance`, data, {
    params
  })
}

export const patchAgencyRequestDebtor = async (data, params) => {
  console.log('params', params)
  return await axiosInstance.patch(`${general_config.BASE_URL}/fdb-v2/v1/agency-request/debtor-access`, data, {
    params
  })
}

export const patchAgencyRequestItSystem = async (data, params) => {
  console.log('params', params)
  return await axiosInstance.patch(`${general_config.BASE_URL}/fdb-v2/v1/agency-request/it-system`, data, {
    params
  })
}
