import * as yup from "yup";

export const negosiasiScheme = (isPS, data) =>
  yup.object({
    change_application_fee: yup
      .bool()
      .required()
      .label("Perubahan nilai permohonan"),
    application_fee: yup
      .number()
      .transform((value) => {
        return Number.isNaN(value) ? null : value;
      })
      .when("change_application_fee", ([value], schema) => {
        if (value) {
          return schema
            .required()
            .max(Number(data?.recommendation_application))
            .nullable();
        }
        return schema;
      })
      .label("Nilai Permohonan Diajukan"),
    change_loan_period: yup
      .bool()
      .required()
      .label("Perubahan jangka waktu pinjaman"),
    loan_period_value: yup
      .number()
      .transform((value) => {
        return Number.isNaN(value) ? null : value;
      })
      .when("change_loan_period", ([value], schema) => {
        if (value) {
          const maxYear = data?.recommendation_loan_period?.split(" ");

          return schema.max(Number(maxYear[0])).required();
        }
        return schema;
      })
      .label("Jangka waktu pinjaman diajukan"),
    loan_period_type: yup
      .string()
      .when("change_loan_period", ([value], schema) => {
        if (value) {
          return schema.required();
        }
        return schema;
      })
      .label("Jangka waktu pinjaman diajukan"),
    change_return: yup
      .bool()
      .required()
      .label("Perubahan tahap & nilai pencairan")
      .when("test", (_, schema) => {
        if (isPS) {
          return schema.notRequired();
        }

        return schema;
      }),
    number_termins: yup
      .number()
      .when("change_return", ([value], schema) => {
        if (value) {
          return schema.required();
        }
      })
      .label("Tahap Pencairan"),
    termins: yup.array(
      yup.object({
        phase: yup.number().notRequired(),
        value: yup
          .number()
          .transform((value) => {
            return Number.isNaN(value) ? null : value;
          })
          .when("change_return", ([value], schema) => {
            if (isPS) return schema;

            if (value) {
              return schema.when("number_termins", ([value2], schema2) => {
                if (value2) {
                  return schema2.required().nullable();
                }

                return schema2;
              });
            }

            return schema;
          })
          .label("Nilai Pencairan"),
      })
    ),
    notes: yup.string().required().label("Catatan"),
  });
