import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import AtomSelect from "../../../atoms/Select";
import { getCountryFDBService } from "services/fdb/region";

let HAS_MORE = false;

const SelectFDBCountry = React.forwardRef((props, ref) => {
  const { className, provinceId, ...restProps } = props;

  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);

  const { data } = useQuery({
    queryKey: ["country-fdb", page],
    queryFn: async () => {
      const res = await getCountryFDBService({
        order_by: "name",
        order_dir: "asc",
        page,
        limit: 10,
      });

      if (res?.data?.data?.meta?.next_page_url) HAS_MORE = true;
      else HAS_MORE = false;
      return res?.data?.data?.data || [];
    },
    retry: false,
    // enabled: !!provinceId,
  });

  const fetchMore = () => {
    if (HAS_MORE) {
      setPage((curr) => curr + 1);
    }
  }

  useEffect(() => {
    // setOptions([])
    data?.map((entry) => {
      setOptions((state) => [
        ...state,
        {
          value: entry?.id,
          label: entry?.name,
        },
      ]);
    })
  }, [data])

  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      onMenuScrollToBottom={fetchMore}
      {...restProps}
    />
  );
});

export default SelectFDBCountry;
