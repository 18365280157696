import React, { useState } from "react";
import AtomSelect from "../../../atoms/Select";

const SelectSubdistrict = React.forwardRef((props, ref) => {
  const { className, districtId, ...restProps } = props;

  const [options, setOptions] = useState([
    {
      value: "Tunda Tebang",
      label: "Tunda Tebang",
    },
    {
      value: "Hasil Hutan Bukan Kayu",
      label: "Hasil Hutan Bukan Kayu",
    },
    {
      value: "Komoditas Non Kehutanan",
      label: "Komoditas Non Kehutanan",
    },
  ]);

  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      {...restProps}
    />
  );
});

export default SelectSubdistrict;
