// import { useQuery } from "@tanstack/react-query";
import { InputForm } from "components";
import AtomDatePicker from "components/atoms/Datepicker";
// import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";
import { useFormContext, useWatch } from "react-hook-form";
// import { getMasterSocialArea } from "services/fdb/socialArea";
import InformationGroup from "./information-group";

const InformationNPS = () => {
  return (
    <div className="flex flex-col gap-10">
      <FormSection />
      <InformationGroup />
    </div>
  );
};

const FormSection = () => {
  const methods = useFormContext();

  const provinceId = useWatch({
    control: methods.control,
    name: "province_id",
  });
  const cityId = useWatch({
    control: methods.control,
    name: "city_id",
  });
  const districtId = useWatch({
    control: methods.control,
    name: "district_id",
  });
  const villageId = useWatch({
    control: methods.control,
    name: "village_id",
  });

  return (
    <CardForm>
      <div className="flex flex-col gap-5">
        <div className="grid grid-cols-2 gap-y-5 gap-x-6">
          <InputForm
            controllerName="name"
            label="Nama Kelompok"
            wrapperClass="gap-0.5"
            className="w-full"
            disabled
          />
          <InputForm
            controllerName="sk_number"
            label="SK Pembentukan atau berita acara pembentukan"
            wrapperClass="gap-0.5"
            className="w-full"
            disabled
          />
          <div className="col-span-2">
            <AtomDatePicker
              controllerName="founding_date"
              label="Tanggal dan Tahun Berdiri"
              datepickerOptions={{
                maxDate: new Date(),
                showMonthDropdown: true,
                showYearDropdown: true,
                scrollableYearDropdown: true,
                dropdownMode: "select",
              }}
              showErrorLabel
            />
          </div>

          {/* <AreaSchemeFunctionSelect /> */}

          {/* <InputForm
            controllerName="agreement_access"
            label="Dasar Perizinan/Perjanjian Akses Kawasan"
            wrapperClass="gap-0.5"
            className="w-full"
          /> */}

          {/* <InputForm
            controllerName="end_year_of_agreement_access"
            label="Tahun Berakhirnya Izin Akses/Perjanjian"
            wrapperClass="gap-0.5"
            className="w-full"
          /> */}
          {/* <AtomDatePicker
            controllerName="end_year_of_agreement_access"
            label="Tahun Berakhirnya Izin Akses/Perjanjian"
            datepickerOptions={{
              maxDate: new Date(),
              showMonthDropdown: true,
              showYearDropdown: true,
              scrollableYearDropdown: true,
              dropdownMode: "select",
            }}
            showErrorLabel
          /> */}

          <SelectFDBProvince
            controllerName="province_id"
            label="Provinsi"
            placeholder=""
            returnObject={false}
            menuPosition="fixed"
            // asText={isView}
          />
          <SelectFDBCity
            controllerName="city_id"
            label="Kota/Kabupaten"
            placeholder=""
            returnObject={false}
            menuPosition="fixed"
            provinceId={provinceId}
            // asText={isView}
          />
        </div>

        <div className="grid grid-cols-3 gap-6">
          <SelectFDBDistrict
            controllerName="district_id"
            label="Kecamatan"
            placeholder=""
            returnObject={false}
            cityId={cityId}
            // asText={isView}
          />
          <SelectFDBVillage
            controllerName="village_id"
            label="Kelurahan/Desa"
            placeholder=""
            districtId={districtId}
            returnObject={false}
            // asText={isView}
          />
          <SelectFDBZipCode
            controllerName="zip_code_id"
            label="Kode Pos"
            placeholder=""
            returnObject={false}
            villageId={villageId}
            // asText={isView}
          />
        </div>

        <InputForm
          controllerName="address"
          className="py-2 px-4 mt-2 border w-full rounded-md"
          label="Alamat"
          placeholder=""
          textArea
          // asText={isView}
        />
      </div>
    </CardForm>
  );
};

// const AreaSchemeFunctionSelect = () => {
//   const { data } = useQuery(["GET_AREA_SCHEMA_FUNCTION"], getMasterSocialArea, {
//     select: (data) => data?.data?.data?.data,
//   });

//   return (
//     <AtomSelect
//       controllerName="social_area_function_id"
//       label="Kawasan"
//       className="mt-2"
//       placeholder=""
//       options={data?.map((e) => ({
//         value: e.id,
//         label: e.name,
//       }))}
//       menuPosition="fixed"
//       returnObject={false}
//       // asText={isView}
//     />
//   );
// };

export default InformationNPS;
