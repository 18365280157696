import axios from "axios";
import config, { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const createGeneralProfile = (params) => {
  return axios.post(`${config.BASE_URL}/fdb/register-account-group`, params);
};

export const getProfileAccount = () => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/profile`);
};

export const updateProfileAccount = (params) => {
  return axiosInstance.put(`${config_fdb_v2.BASE_URL}/v1/profile`, params);
};
