import { AnimatePresence, motion } from "framer-motion";
import React, { forwardRef, useMemo, useState } from "react";
import * as Yup from "yup";
import { Button, InputForm } from "../../../../../components";
import AccordionButton from "../../../../../components/atoms/AccordionButton";
import AtomSelect from "../../../../../components/atoms/Select";
import CardForm from "../../../../../components/molecules/CardForm";
import InputAge from "../../../../../components/organisms/inputs/InputAge";
import InputCurrency from "../../../../../components/organisms/inputs/InputCurrency";
import SelectJenisUsaha from "../../../../../components/organisms/inputs/SelectJenisUsaha";
import SelectKomoditasUsaha from "../../../../../components/organisms/inputs/SelectKomoditasUsaha";
import SelectSumberBahanProduksi from "../../../../../components/organisms/inputs/SelectSumberBahanProduksi";
import InputNumeric from "components/organisms/inputs/InputNumeric";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";

export const FieldLabel = {
  PROJECT_TYPE: "Jenis Usaha PS",
  PROJECT_COMMODITY: "Komoditas Usaha PS",
  PROJECT_AGE: "Lama Usaha",
  SOURCE: "Sumber Bahan Produksi/Jasa",
  CAPACITY: " Kapasitas Usaha/Produksi ",
  VALUE: "Nilai Ekonomi usaha (Rp produksi/jasa)",
  GOAL: "Tujuan Pemasaran",
  PARTNER: "Mitra Usaha (Jika Ada)",
  PLAN: "Rencana penggunaan modal pinjaman",
  EST_GROSS_EARN: "Perkiraan omzet/harga jual/pendapatan kotor",
  EST_COST: "Perkiraan Biaya Produksi / HPP / Modal Yang Telah Dikeluarkan",
  EST_NET_EARN: "Perkiraan pendapatan bersih",
  CYCLE: "Siklus Usaha Produksi",
};

export const validationSchema = Yup.object().shape({
  // TODO: ADD VALIDATION PARTNER FIELD ARRAY
  current: Yup.object().shape({
    business_type: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.PROJECT_TYPE),
    business_commodity: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.PROJECT_COMMODITY),
    business_since_type: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.PROJECT_AGE),
    business_since: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.PROJECT_AGE),
    business_material: Yup.string().trim().required().label(FieldLabel.SOURCE),
    business_capacity: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.CAPACITY),
    business_economy_values: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.VALUE),
    business_target: Yup.string().trim().required().label(FieldLabel.GOAL),
    // partner: Yup.string().trim().required().label(FieldLabel.PARTNER),
  }),
  prospect: Yup.object().shape({
    business_panning_application: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.PLAN),
    business_revenue: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.EST_GROSS_EARN),
    business_cost_price: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.EST_COST),
    business_net_income: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.EST_NET_EARN),
    business_cycle_unit: Yup.string().trim().required().label(FieldLabel.CYCLE),
    business_cycle: Yup.string().trim().required().label(FieldLabel.CYCLE),
  }),
});

function ProjectSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: "business_partner",
    keyName: "value",
  });

  const [accordionState, setAccordionState] = useState({
    curr_condition: true,
    prospect: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  return (
    <CardForm label="Data Usaha yang Akan Dibiayai" {...rest}>
      <AnimatePresence>
        <AccordionButton
          key={"accordion-currcond-btn"}
          label={"Kondisi Usaha Saat Ini"}
          isOpen={accordionState?.curr_condition}
          onClick={toggleAccordion("curr_condition")}
        />

        {accordionState?.curr_condition && (
          <motion.div
            key={"accordion-currcond-content"}
            className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div className="col-span-2">
              <SelectJenisUsaha
                controllerName={`business_type`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.PROJECT_TYPE}
                returnObject={false}
                asText={isView}
              />
            </div>

            <div className="col-span-2">
              <SelectKomoditasUsaha
                controllerName={`business_commodity`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.PROJECT_COMMODITY}
                returnObject={false}
                asText={isView}
              />
            </div>

            <div
              className={[
                "col-span-2 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
              ]}
            >
              <InputForm
                controllerName={`business_since`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.PROJECT_AGE}
                placeholder={FieldLabel.PROJECT_AGE}
                required
                asText={isView}
              />

              <AtomSelect
                controllerName={`business_since_type`}
                className="basic-single mt-2 flex-1"
                wrapperClass={"col-span-1"}
                returnObject={false}
                label={<span>&nbsp;</span>}
                placeholder={FieldLabel.PROJECT_AGE}
                options={[{ value: "bulan", label: "Bulan" }]}
                asText={isView}
              />
            </div>

            <div className="col-span-2">
              <SelectSumberBahanProduksi
                controllerName={`business_material`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.SOURCE}
                returnObject={false}
                asText={isView}
              />
            </div>

            <InputForm
              controllerName={`business_capacity`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              wrapperClass={"col-span-1"}
              label={FieldLabel.CAPACITY}
              placeholder={FieldLabel.CAPACITY}
              asText={isView}
            />

            <InputForm
              controllerName={`business_economy_values`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              wrapperClass={"col-span-1"}
              label={FieldLabel.VALUE}
              asText={isView}
            />

            <div className="col-span-2">
              <InputForm
                controllerName={`business_target`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.GOAL}
                placeholder={FieldLabel.GOAL}
                asText={isView}
              />
            </div>

            <div className="flex flex-col col-span-2 gap-2">
              <label className="text-sm font-semibold">Partner Bisnis</label>
              {fields.map((field, idx) => (
                <div className="flex gap-4 items-center">
                  <InputForm
                    controllerName={`business_partner.${idx}.value]`}
                    className="w-full rounded-md"
                  />
                  {idx > 0 && (
                    <Button
                      type="button"
                      onClick={() => remove(idx)}
                      size="md"
                      className="rounded-lg bg-error-600 flex-none"
                      label={<BiTrash className="text-white" />}
                    />
                  )}
                </div>
              ))}
            </div>

            <div className="col-span-2 flex justify-end">
              <Button
                label="Tambah Partner"
                className="self-end w-max"
                type="button"
                onClick={() => append("")}
              />
            </div>

            <div className="col-span-2">
              <InputForm
                controllerName={`business_target`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                wrapperClass={"col-span-1"}
                label={FieldLabel.GOAL}
                placeholder={FieldLabel.GOAL}
                asText={isView}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <AccordionButton
          key={"accordion-prospect-btn"}
          label={"Prospek Usaha"}
          isOpen={accordionState?.prospect}
          onClick={toggleAccordion("prospect")}
        />

        {accordionState?.prospect && (
          <motion.div
            key={"accordion-prospect-content"}
            className="md:grid md:grid-cols-3 gap-4 space-y-4 md:space-y-0"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <InputForm
              controllerName={`business_panning_application`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              wrapperClass={"col-span-3"}
              label={FieldLabel.PLAN}
              placeholder={FieldLabel.PLAN}
              required
              asText={isView}
            />

            <InputNumeric
              controllerName={`business_revenue`}
              className={`py-2 px-4 mt-2 border w-full rounded-md `}
              label={FieldLabel.EST_GROSS_EARN}
              required
              asText={isView}
            />

            <InputNumeric
              controllerName={`business_cost_price`}
              className={`py-2 px-4 mt-2 border w-full rounded-md `}
              label={FieldLabel.EST_COST}
              required
              asText={isView}
            />

            <InputNumeric
              controllerName={`business_net_income`}
              className={`py-2 px-4 mt-2 border w-full rounded-md `}
              label={FieldLabel.EST_NET_EARN}
              required
              asText={isView}
            />

            <div
              className={[
                "col-span-3 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
              ]}
            >
              <InputForm
                controllerName={`business_cycle`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.CYCLE}
                placeholder={FieldLabel.CYCLE}
                required
                asText={isView}
              />

              <AtomSelect
                controllerName={`business_cycle_unit`}
                className="basic-single mt-2 flex-1"
                wrapperClass={"col-span-1"}
                returnObject={false}
                label={<span>&nbsp;</span>}
                placeholder={FieldLabel.CYCLE}
                options={[
                  { value: "minggu", label: "Mingguan" },
                  { value: "bulan", label: "Bulanan" },
                ]}
                asText={isView}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </CardForm>
  );
}

// const PartnerSection = forwardRef(({ formPrefix, mode, ...props }, ref) => {
//   const prefix = formPrefix ? `${formPrefix}.` : "";

//   const { fields, append, remove } = useFieldArray({
//     name: `business_partner`,
//   });

//   const watchFieldArray = useWatch({
//     name: `business_partner`,
//   });

//   const [isEdit, isAdd, isView] = useMemo(
//     () => ["edit", "add", "view"].map((e) => e === mode),
//     []
//   );

//   const controlledFields = fields.map((field, index) => {
//     return {
//       ...field,
//       ...watchFieldArray[index],
//     };
//   });

//   return (
//     <>
//       {controlledFields.map((field, key) => (
//         <div className="flex items-center space-x-4 col-span-2">
//           <InputForm
//             key={field.id}
//             controllerName={`business_partner.${key}.value`}
//             className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
//             wrapperClass={"col-span-2"}
//             label={FieldLabel.PARTNER}
//             placeholder={FieldLabel.PARTNER}
//             asText={false}
//           />
//           {key > 0 && (
//             <div className="space-y-2">
//               <label>&nbsp;</label>
//               <Button
//                 type="button"
//                 onClick={() => remove(key)}
//                 className="p-3 rounded-lg bg-error-600 flex-none"
//                 label={<BiTrash className="text-white" />}
//               />
//             </div>
//           )}
//         </div>
//       ))}
//       <div className="flex justify-end col-span-2 border-t pt-4">
//         <Button
//           type="button"
//           onClick={() => append({ value: "" })}
//           className="px-2 py-3 border rounded-lg bg-white w-fit h-fit"
//           label={
//             <div className="flex items-center gap-2 text-sm">
//               <BiPlus />
//               Mitra Usaha
//             </div>
//           }
//         />
//       </div>
//     </>
//   );
// });

ProjectSection.propTypes = {};

export default ProjectSection;
