import { Button } from 'components';
import Alert from 'components/atoms/Alert';
import AlertBlankData from 'components/molecules/Alert';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import _, { map, upperFirst } from 'lodash';
import React, { useState } from 'react';
import { FiAlertCircle, FiUpload } from 'react-icons/fi';
import { RiBankLine, RiCoinsLine, RiComputerLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router';

import ProfileUmum from '../../../../assets/illustrations/Profil Umum.png'

const ProspectingLembagaDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [moduleItems] = useState([
    {
      icon: <RiComputerLine className="text-white" />,
      label: 'Profil Umum',
      path: `${location.pathname}/profile-umum`,
      background: 'bg-orangedark-600',
      backgroundOuter: 'bg-orangedark-100',
      status: null,
    },
    // {
    //   icon: <RiBankLine className="text-white" />,
    //   label: 'Kelembagaan',
    //   path: `${location.pathname}/aplication-form`,
    //   background: 'bg-blue-800',
    //   backgroundOuter: 'bg-blue-100',
    //   status: null,
    // },
    // {
    //   icon: <RiCoinsLine className="text-white" />,
    //   label: 'Keuangan General',
    //   path: `${location.pathname}/finance`,
    //   background: 'bg-green-600',
    //   backgroundOuter: 'bg-green-100',
    //   status: null,
    // },
  ]);

  return (
    <div className="space-y-6">
      <div className="w-full p-10 bg-white rounded-xl border border-gray-200 backdrop-blur-[80px] space-y-6">
        <BreadCrumbs
          routes={[
            {
              label: 'Formulir Pendaftaran'
            },
            {
              label: 'Registrasi Prospecting Lembaga Penyalur',
              path: '/prospecting-lembaga',
            },
          ]}
        />
        <div className="flex items-center justify-between">
          <div className='gap-x-2'>
            <div className="text-gray-900 text-2xl font-semibold leading-loose">
              Registrasi Prospecting Lembaga Penyalur
            </div>
            <div className="text-gray-600 leading-loose">
              Lengkapi Data Registrasi Prospecting Lembaga Penyalur.
            </div>
          </div>
          <div className="justify-start items-center gap-3 inline-flex">
            <Button
              className="px-4 py-2.5 bg-orange-600 rounded-lg shadow border border-orange-700 flex justify-center items-center space-x-2 text-white text-sm font-semibold capitalize leading-tight
                disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500 disabled:shadow-none disabled:cursor-not-allowed
              "
              disabled
              label={
                <div className="flex items-center space-x-2">
                  <FiUpload />
                  <p>Upload Semua Persyaratan</p>
                </div>
              }
              onClick={() =>
                navigate('/financing-request/supplier-application')
              }
            />
          </div>
        </div>
      </div>
      {/* <AlertBlankData />
       */}
      <Alert
        theme='warning'
        title={'Lengkapi Dokumen di bawah'}
        description={'Anda bisa upload Registrasi Prospecting Lembaga Penyalur jika semua dokumen kategori di bawah ini sudah dilengkapi!'}
      />
      <div className="flex flex-wrap gap-4">
        {_.map(moduleItems, (
          { icon, label, path, background, backgroundOuter }, index
        ) => (
          <div className="flex flex-col items-center w-[285px] gap-4 bg-white shadow-xl rounded-xl relative">
            <div className="p-4 absolute -top-10 w-[56px]">
              <div className={`${backgroundOuter} p-3 rounded-full`} >
                <div className={`${background} p-3 rounded-full`}>
                  {icon}
                </div>
              </div>
            </div>
            <span className="mt-12 font-semibold">
              {_.upperFirst(label)}
            </span>
            <img
              src={ProfileUmum}
              alt="general-information-img"
            />

            <div className="p-4 w-full">
              <AlertBlankData body={'Lengkapi kesesuaian dokumen adminstrasi yang dipersyaratkan'} />
            </div>
            <div className="p-4 w-full border-t">
              <button
                onClick={() => navigate(path)}
                className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
              >
                Lengkapi
              </button>
            </div>
          </div>

        ))}

      </div>
    </div >
  );
};

export default ProspectingLembagaDashboard;
