import dayjs from "dayjs";
import React from "react";

function FormattedDate({ date, format = "DD MMMM YYYY", ...props }) {
  const formatDate = () => {
    if (date && format) {
      try {
        return dayjs(date).format(format);
      } catch (err) {
        // stubbed
      }
    }
    
    return "";
  };
  
  return (
    <span {...props}>{formatDate()}</span>
  );
}

export default FormattedDate;