import axios from "axios";
import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

const user = JSON.parse(
  sessionStorage.getItem(
    "oidc.user:https://identity.bpldh-dev.groot.id:web_app_enduser"
  )
);

export const submitGeneralProfile = (params) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/general-profile/submit`,
    params,
    {
      headers,
    }
  );
};

export const getGeneralProfile = (params) => {
  const id = localStorage.getItem(`userId`);
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/general-profile/findByUserId/${
      params ? params : id
    }`,
    params,
    { headers }
  );
};
