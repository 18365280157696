import { Button, InputForm, InputFormRadio, InputFormUpload } from "components";
import AtomSelect from "components/atoms/Select";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import CardForm from "components/molecules/CardForm";
import { useFormContext } from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";

const SafeGuard = ({
  safeguardFields = [],
  appendSafeguard = () => {},
  removeSafeguard = () => {},
  uploadFile = () => {},
  methods,
}) => {
  const { watch, getValue } = useFormContext();
  return (
    <CardForm label={"Safeguard"}>
      <QuestionBadge text="Apakah Lembaga Anda pernah mengimplementasikan safeguard?" />
      <div className={`flex gap-4 mt-[20px]`}>
        <div className={`w-full`}>
          <InputFormRadio
            label={``}
            controllerName={`is_safeguard`}
            values={[
              {
                label: "ya",
                value: true,
              },
              {
                label: "tidak",
                value: false,
              },
            ]}
          />
        </div>
      </div>
      {watch(`is_safeguard`) && (
        <>
          <QuestionBadge text="Bisa Anda jelaskan bagaimana pengalaman lembaga Anda dalam mengimplementasikan safeguard?" />
          {safeguardFields?.length &&
            safeguardFields?.map((data, index) => (
              <CardForm
                key={data.id}
                label={`Pengalaman Safeguard ${index + 1}`}
                buttonCollapse={true}
                childerForm={true}
              >
                <div className="flex gap-4 items-end border-t pt-2">
                  <div className="w-full">
                    <div className={`flex gap-4`}>
                      <div className={`flex-1 pt-2`}>
                        <InputForm
                          controllerName={`data_safeguard.${index}.desc`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Deskripsi Singkat Gambaran Safeguardnya"}
                          placeholder={"Deskripsi"}
                          textArea={true}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={`data_safeguard.${index}.name`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Nama Donor"}
                          placeholder={""}
                          textArea={true}
                          required={true}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <AtomSelect
                          controllerName={`data_safeguard.${index}.period`}
                          options={[
                            { value: "1 Kali", label: "1 kali" },
                            { value: "2 Kali", label: "2 Kali" },
                            { value: "3 Kali", label: "3 Kali" },
                            { value: "4 Kali", label: "4 Kali" },
                          ]}
                          className="basic-single mt-2 items-start"
                          label="Periode Pelaporan Safeguard Dalam 1 Tahun"
                          required={true}
                          returnObject={false}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <InputFormUpload
                          controllerName={`data_safeguard.${index}.report`}
                          label={"Laporan Safeguard Atau Bukti Pelaksanaan"}
                          uploadFile={uploadFile}
                          methods={methods}
                        />
                      </div>
                    </div>
                  </div>
                  {index !== 0 && (
                    <button
                      className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                      onClick={() => removeSafeguard(index)}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  )}
                </div>
              </CardForm>
            ))}
          <div className="border-b" />
          <div className="flex flex-row-reverse my-5 mx-4 ">
            <Button
              className="p-4 border rounded-lg"
              label={
                <div className="flex items-center gap-2 font-[14px]">
                  <RiAddLine />
                  Tambah Safeguard Lain
                </div>
              }
              onClick={() => {
                appendSafeguard({
                  desc: "",
                  name: "",
                  period: "",
                  report: "",
                });
              }}
            />
          </div>
        </>
      )}
    </CardForm>
  );
};

export default SafeGuard;
