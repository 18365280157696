import { InputForm } from "components";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import FileInfoField from "components/atoms/FileInfoField";
import AtomDatePicker from "components/atoms/Datepicker";
import {
  RiDeleteBin5Line,
  RiDownloadCloud2Line,
  RiFile2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";
import AsyncDropzoneFieldForm from "components/molecules/async-dropzone-field-form";
import InputMaskForm from "components/V2/shared/input-mask-form";
import { useMutation } from "@tanstack/react-query";
import { postFileServices } from "services/fdb/akunKelompok";
import { enqueueSnackbar } from "notistack";
import { deleteDocumentFormulirPendaftaran } from "services/fdb/perhutananSosial/registerForm";
import { getFile } from "services/danaProgram/fileService";
import { humanizeFileSize } from "helpers";

// public distributionInstitutionType = {
//   lembaga_penyalur: {
//     bank: {
//       code: '1',
//       string: 'Bank & Ventura',
//     },
//     koperasi: {
//       code: '2',
//       string: 'LKNB Koperasi',
//     },
//     non_koperasi: {
//       code: '3',
//       string: 'LKNB Non Koperasi',
//     },
//     lainnya: {
//       code: '4',
//       string: 'Badan Hukum Lainnya',
//     },
//   },
//   non_perorangan: {
//     bumn: {
//       code: '1',
//       string: 'BUMN',
//     },
//     bumd: {
//       code: '2',
//       string: 'BUMD',
//     },
//     bumdes: {
//       code: '3',
//       string: 'BUMDES',
//     },
//     swasta: {
//       code: '4',
//       string: 'Badan Usaha Swasta',
//     },
//   },
// }

const GeneralInformationForm = ({
  // onBack,
  // onSubmit,
  // draftData,
  // onDraft,
  props,
  profileData,
  isView
}) => {
  const { type: typeLembaga, group } = props;

  const [urlFile, setUrlFile] = useState("");
  const options = useMemo(
    () =>
      group === "non-perorangan"
        ? [
          {
            value: "1",
            label: "BUMN",
          },
          {
            value: "2",
            label: "BUMD",
          },
          {
            value: "3",
            label: "BUMDES",
          },
          {
            value: "4",
            label: "Badan Usaha Swasta",
          },
        ]
        : [
          {
            value: "1",
            label: "Bank & Ventura",
          },
          {
            value: "2",
            label: "LKNB Koperasi",
          },
          {
            value: "3",
            label: "LKNB Non Koperasi",
          },
          {
            value: "4",
            label: "Badan Hukum Lainnya",
          },
        ],
    [group]
  );

  const countryOptions = useMemo(
    () => [{ value: "Indonesia", label: "Indonesia" }],
    []
  );

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);

  const methods = useFormContext();
  const { control } = methods;

  const provinceId = useWatch({
    control,
    name: "agency.province_id",
  });
  const cityId = useWatch({
    control,
    name: "agency.city_id",
  });
  const districtId = useWatch({
    control,
    name: "agency.district_id",
  });
  const villageId = useWatch({
    control,
    name: "agency.village_id",
  });

  const orgStructureLabel = useCallback(
    (type) => {
      const baseLabel = "Struktur Organisasi";
      const uploadPrefix = "Upload ";

      let labelSuffix = "";
      let descriptionSuffix = "";

      switch (typeLembaga) {
        case "bank-ventura":
          labelSuffix =
            "Beserta Daftar Direksi (Direksi/Komisaris/Pemegang Saham)";
          descriptionSuffix = labelSuffix;
          break;
        case "koperasi":
        case "non-koperasi":
          labelSuffix = "Beserta Info Kepengurusan";
          descriptionSuffix =
            "Serta Info Kepengurusan Lembaga Dengan Isi Curicullum vitae pengurus Dan kartu tanda penduduk/surat izin mengemudi/paspor pengurus Disatukan";
          break;
        case "badan-hukum":
          labelSuffix =
            ", Daftar Direksi (Direksi/Komisaris/Pemegang Saham) & Info Kepengurusan";
          descriptionSuffix =
            "Daftar Direksi & Info Kepengurusan Lembaga (Curicullum vitae pengurus Dan kartu tanda penduduk/surat izin mengemudi/paspor pengurus) Disatukan";
          break;
        case "cv":
          labelSuffix = "";
          descriptionSuffix =
            "CV, Beserta Data Diri Sekutu Aktif & Pasif (KTP/SIM/Paspor,CV) Dijadikan Satu";
          break;
        default:
          if (group === "non-perorangan") {
            labelSuffix = "";
            descriptionSuffix =
              "Beserta Data Diri (KTP/SIM/Paspor,CV) Dijadikan Satu";
          } else {
            labelSuffix =
              "Beserta Daftar Direksi (Direksi/Komisaris/Pemegang Saham)";
            descriptionSuffix = labelSuffix;
          }
          break;
      }

      if (type === "label") {
        return `${baseLabel} ${labelSuffix}`;
      } else if (type === "description") {
        return `${uploadPrefix}${baseLabel} ${descriptionSuffix}`;
      } else {
        return `${baseLabel} ${labelSuffix}`;
      }
    },
    [typeLembaga, group]
  );

  const getInstitutionInfo = () => {
    switch (typeLembaga) {
      case "bumdes":
        return "Bumdes";
      case "cv":
        return "Penyalur";
      case "pt":
        return "Umum";
      default:
        return "Lembaga";
    }
  };

  const getLabel = useCallback(() => {
    switch (typeLembaga) {
      case "bumdes":
        return "Bumdes";
      case "cv":
        return "CV";
      // case 'pt': return 'Lembaga';
      case "firma":
        return "Firma";
      default:
        return "Lembaga";
    }
  }, [typeLembaga]);

  const { append, remove } = useFieldArray({
    control,
    name: `profile.documents`,
  });

  const openDocs = async () => {
    window.open(backgroundFile?.url, "_blank");
  };

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload }) => {
      const res = await getFile(payload);
      return { res: res.data.data };
    },
    onSuccess: ({ res }) => {
      setUrlFile(res.url);
    },
  });

  const [backgroundFile, setBackgroundFile] = useState();

  const handleGetBackgroundFile = useCallback(async () => {
    const response = await getFile("c2da834e-ca65-4342-8fb8-bb56e249e02f");

    setBackgroundFile(response.data.data);
  }, []);

  useEffect(() => {
    handleGetBackgroundFile();
  }, [handleGetBackgroundFile]);

  return (
    <FormProvider {...methods}>
      <CardForm label="General Informasi">
        {group === "non-perorangan" && (
          <p className="text-lg text-primary-700 font-semibold">
            Informasi {getInstitutionInfo()}
          </p>
        )}
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputForm
              controllerName="agency.name"
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={`Nama ${getLabel()}`}
              placeholder={"Nama Lembaga"}
              required
              asText={isView}
            // disabled={group === "non-perorangan" ? true : false}
            />
            <AtomSelect
              controllerName={"agency.distributor_institution"}
              options={options}
              className="basic-single mt-2 flex-1"
              label={`Jenis ${getLabel()}`}
              required={true}
              wrapperClass={"col-span-1"}
              isError={true}
              // disable={group === "non-perorangan" ? true : false}
              returnObject={false}
              asText={isView}
            />
            {/* <InputForm
              controllerName="agency.distributor_institution"
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={`Jenis ${getLabel()}`}
              // placeholder={"Nama Lembaga"}
              required
              // disabled={group === "non-perorangan" ? true : false}
            /> */}
          </div>
          {typeLembaga !== "pt" ? (
            <div className="space-y-4">
              <InputForm
                controllerName={"profile.business_type"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"Jenis Usaha dan/atau Jenis Layanan"}
                placeholder={"Jenis usaha dan/atau jenis layanan"}
                required
                asText={isView}
              />
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AtomSelect
                controllerName={"profile.kbli_clasification"}
                options={options}
                className="basic-single mt-2 flex-1"
                label={`Klasifikasi KBLI`}
                placeholder={"Lembaga Non Bank - Koperasi"}
                required={true}
                wrapperClass={"col-span-1"}
                isError={true}
                returnObject={false}
                asText={isView}
              />
              <AtomSelect
                controllerName={"profile.kbli_business_type"}
                options={options}
                className="basic-single mt-2 flex-1"
                label={`Jenis usaha (Sesuai KLASIFIKASI BAKU LAPANGAN USAHA INDONESIA/KBLI)`}
                placeholder={"Lembaga Non Bank - Koperasi"}
                required={true}
                wrapperClass={"col-span-1"}
                isError={true}
                asText={isView}
                returnObject={false}
              />
            </div>
          )}
          {group === "non-perorangan" && typeLembaga !== "bumdes" && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputForm
                controllerName={"agency.npwp"}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={`Nomor NPWP ${getLabel()}`}
                placeholder={"____-____-___"}
                asText={isView}
                required
              />
              <AsyncDropzoneFieldForm
                name={`agency.npwp_file`}
                label={`NPWP ${getLabel()}`}
                required
                maxFiles={1}
                maxSize={10}
                isView={isView}
              />
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputForm
              controllerName={"agency.email"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={`Email ${getLabel()}`}
              placeholder={"Email"}
              asText={isView}
              required
            // value={draftData?.email}
            />
            <InputForm
              controllerName={"agency.phone"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"No Telepon"}
              placeholder={"No Telepon"}
              required
              asText={isView}
              maxLength={14}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            // value={draftData?.phone_number}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <AtomSelect
              controllerName={"country"}
              options={countryOptions}
              className="basic-single mt-2"
              label="Negara"
              placeholder={"Negara"}
              required={true}
              asText={isView}
              defaultValue={countryOptions[0]}
            />
            <SelectFDBProvince
              controllerName={"agency.province_id"}
              className="basic-single mt-2 flex-1"
              label="Provinsi"
              required={true}
              asText={isView}
              returnObject={false}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <SelectFDBCity
              controllerName={"agency.city_id"}
              className="basic-single mt-2 flex-1"
              label="Kota/Kabupaten"
              required={true}
              provinceId={provinceId}
              asText={isView}
              returnObject={false}
            />
            <SelectFDBDistrict
              controllerName={"agency.district_id"}
              className="basic-single mt-2 flex-1"
              label="Kecamatan"
              required={true}
              asText={isView}
              cityId={cityId}
              returnObject={false}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <SelectFDBVillage
              controllerName={"agency.village_id"}
              className="basic-single mt-2 flex-1"
              label="Kelurahan/Desa"
              required={true}
              districtId={districtId}
              asText={isView}
              returnObject={false}
            />
            <SelectFDBZipCode
              controllerName={"agency.zip_code_id"}
              className="basic-single mt-2 flex-1"
              label="Kode Pos"
              required={true}
              // selected={draftData?.zip_code_id}
              returnObject={false}
              asText={isView}
              villageId={villageId}
            />
          </div>
          <InputForm
            controllerName={"agency.address"}
            className={`py-2 px-4 mt-2 border w-full rounded-md`}
            label={"Alamat"}
            placeholder={"Alamat"}
            textArea
            asText={isView}
            required
          // value={draftData?.address}
          />
        </div>
        <div className="border-b" />
        <div className="space-y-6">
          <p className="text-lg text-primary-700 font-semibold">
            Informasi Penanggung Jawab {"(Narahubung)"}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputForm
              controllerName={"profile.contact_person_name"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Nama Narahubung"}
              placeholder={"Nama Narahubung"}
              asText={isView}
              required
            />
            <InputForm
              controllerName={"profile.contact_person_position"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Jabatan"}
              placeholder={"Jabatan"}
              required
              asText={isView}
            />
            <InputForm
              controllerName={"profile.contact_person_email"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Email"}
              placeholder={"Email"}
              required
              asText={isView}
            />
            <InputForm
              controllerName={"profile.contact_person_phone"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Telepon"}
              placeholder={"Telepon"}
              required
              asText={isView}
              maxLength={15}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <div className="space-y-1">
              <InputForm
                controllerName={"profile.contact_person_identity_number"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"No KTP"}
                placeholder={"No KTP"}
                asText={isView}
                required
                maxLength={16}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className="space-y-1">
              <AsyncDropzoneFieldForm
                name={`profile.contact_person_identity_number_file`}
                required
                informationText={"Dokumen KTP"}
                maxSize={1}
                maxFiles={1}
                isView={isView}
              />
            </div>

            <div className="space-y-1">
              <InputMaskForm
                label="No NPWP"
                name={`profile.contact_person_npwp`}
                placeholder="No NPWP"
                mask="##.###.###.#-###.###"
                replacement={{ "#": /\d/ }}
                required
                asText={isView}
              />
            </div>
            <div className="space-y-1">
              <AsyncDropzoneFieldForm
                name={`profile.contact_person_npwp_file`}
                required
                informationText={"Dokumen NPWP"}
                maxSize={1}
                maxFiles={1}
                isView={isView}
              />
            </div>
          </div>
        </div>
      </CardForm>
      <CardForm label={"Latar Belakang Menjadi Penyalur"}>
        <div className="gap-4">
          <div className="space-y-1 mb-5">
            <label htmlFor="" className="font-semibold">
              Format Surat Menjadi Lembaga Penyalur
            </label>
            <FileInfoField
              title={"Format Surat Menjadi Lembaga Penyalur"}
              desc={humanizeFileSize(backgroundFile?.size)}
              showDownload
              showIcon
              onDownloadClick={() => openDocs()}
            />
          </div>

          <div className="space-y-1">
            <AsyncDropzoneFieldForm
              name={`profile.background_file`}
              label={"Upload Surat Menjadi Lembaga Penyalur"}
              required
              informationText={"Upload Surat Menjadi Lembaga Penyalur"}
              maxSize={1}
              maxFiles={1}
              isView={isView}
            />
          </div>
        </div>

        {profileData?.agency_profile?.status_string === "Revisi" &&
          profileData?.agency_profile?.agency_verificator &&
          profileData?.agency_profile?.agency_verificator
            ?.general_info_desc && (
            <div className="flex flex-col gap-2 mt-2">
              <h5 className="text-lg font-medium">
                Notes (General Informasi & Latar Belakang Menjadi Penyalur)
              </h5>
              <div className="p-3 bg-white border border-gray-200 rounded-md text-base">
                {
                  profileData?.agency_profile?.agency_verificator
                    ?.general_info_desc
                }
              </div>
            </div>
          )}
      </CardForm>

      <CardForm label={orgStructureLabel("label")}>
        <div className="gap-4">
          <div className="mb-5">
            {(typeLembaga === "bumdes" || typeLembaga === "firma") && (
              <InputForm
                controllerName={"profile.organization_type"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"Jenis badan usaha"}
                placeholder={"Jenis badan usaha"}
                required
                asText={isView}
              />
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
            <div className="space-y-1">
              <InputForm
                controllerName={"profile.total_member"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"Jumlah Anggota"}
                placeholder={"Jumlah Anggota"}
                required
                asText={isView}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <p className="text-xs text-gray-400">
                Jika Tidak Memiliki Anggota, Maka Diisi Angka 0
              </p>
            </div>
            <div className="space-y-1">
              <InputForm
                controllerName={"profile.total_branch"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"Jumlah Cabang"}
                placeholder={"Jumlah Cabang"}
                required
                asText={isView}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <p className="text-xs text-gray-400">
                Jika Tidak Memiliki Cabang, Maka Diisi Angka 0
              </p>
            </div>
            <div className="space-y-1">
              <InputForm
                controllerName={"profile.total_employee"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"Jumlah Pegawai"}
                placeholder={"Jumlah Pegawai"}
                required
                asText={isView}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <p className="text-xs text-gray-400">
                Jika Tidak Memiliki Pegawai, Maka Diisi Angka 0
              </p>
            </div>
          </div>

          <div className="space-y-1">
            <AsyncDropzoneFieldForm
              name={`profile.organization_file`}
              label={orgStructureLabel("description")}
              required
              informationText={orgStructureLabel("description")}
              maxSize={1}
              maxFiles={1}
              isView={isView}
            />
          </div>
        </div>

        {profileData?.agency_profile?.status_string === "Revisi" &&
          profileData?.agency_profile?.agency_verificator &&
          profileData?.agency_profile?.agency_verificator
            ?.organization_desc && (
            <div className="flex flex-col gap-2 mt-2">
              <h5 className="text-lg font-medium">
                Notes (Struktur Organisasi)
              </h5>
              <div className="p-3 bg-white border border-gray-200 rounded-md text-base">
                {
                  profileData?.agency_profile?.agency_verificator
                    ?.organization_desc
                }
              </div>
            </div>
          )}
      </CardForm>

      {group === "non-perorangan" ? (
        <CardForm label="Dokumen Legalitas">
          <div className="gap-4">
            <LegalityDocumentsSection removeDocument={remove} />
          </div>
          {profileData?.agency_profile?.status_string === "Revisi" &&
            profileData?.agency_profile?.agency_verificator &&
            profileData?.agency_profile?.agency_verificator
              ?.legality_document_desc && (
              <div className="flex flex-col gap-2 mt-2">
                <h5 className="text-lg font-medium">
                  Notes (Dokumen Legalitas)
                </h5>
                <div className="p-3 bg-white border border-gray-200 rounded-md text-base">
                  {
                    profileData?.agency_profile?.agency_verificator
                      ?.legality_document_desc
                  }
                </div>
              </div>
            )}
        </CardForm>
      ) : (
        <CardForm label="Legalitas Lembaga">
          <div className="gap-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
              <div className="space-y-1">
                <InputMaskForm
                  label="No NPWP"
                  name={`profile.institution_npwp`}
                  placeholder="No NPWP"
                  mask="##.###.###.#-###.###"
                  replacement={{ "#": /\d/ }}
                  required
                  asText={isView}
                />
              </div>
              <div className="space-y-1">
                <AsyncDropzoneFieldForm
                  name={`profile.institution_npwp_file`}
                  required
                  informationText={"Dokumen NPWP"}
                  maxSize={1}
                  maxFiles={1}
                  isView={isView}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
              <div className="space-y-1">
                <InputForm
                  controllerName={"profile.institution_legality_certificate"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={"Nomor Akta Pendirian Dan Pengesahannya"}
                  // placeholder={'No NPWP'}
                  required
                  asText={isView}
                />
              </div>
              <div className="space-y-1">
                <AtomDatePicker
                  asText={isView}
                  label="Tanggal Terbit Akta Pendirian Dan Pengesahannya"
                  required={true}
                  controllerName={`profile.institution_legality_date`}
                  placeholder={"dd/mm/yyyy"}
                  customInputClassname={`py-2 px-4 border w-full rounded-md flex-1`}
                  datepickerOptions={{
                    maxDate: new Date(),
                    showMonthDropdown: true,
                    showYearDropdown: true,
                    scrollableYearDropdown: true,
                    dropdownMode: "select",
                  }}
                />
              </div>
            </div>

            <div className="space-y-1 mb-5">
              <AsyncDropzoneFieldForm
                name={`profile.institution_legality_file`}
                label={
                  "Dokumen Akta Pendirian Dan pengesahannya Beserta Akta Perubahan"
                }
                required
                informationText={
                  "Dokumen Akta Pendirian Dan pengesahannya Beserta Akta Perubahan"
                }
                maxSize={1}
                maxFiles={1}
                isView={isView}
              />
            </div>

            {typeLembaga === "koperasi" && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
                  <div className="space-y-1">
                    <InputForm
                      controllerName={"profile.institution_nik"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Nomor NIK (Nomor Induk Koperasi)"}
                      // placeholder={'No NPWP'}
                      required
                      asText={isView}
                    />
                  </div>
                  <div className="space-y-1">
                    <AtomDatePicker
                      label="Tanggal Terbit NIK"
                      asText={isView}
                      // newDate={true}
                      required={true}
                      // customPicker={true}
                      controllerName={`profile.institution_nik_date`}
                      placeholder={"dd/mm/yyyy"}
                      customInputClassname={`py-2 px-4 border w-full rounded-md flex-1`}
                      datepickerOptions={{
                        maxDate: new Date(),
                        showMonthDropdown: true,
                        showYearDropdown: true,
                        scrollableYearDropdown: true,
                        dropdownMode: "select",
                      }}
                    />
                  </div>
                </div>

                <div className="space-y-1 mb-5">
                  <AsyncDropzoneFieldForm
                    required
                    name={`profile.institution_nik_file`}
                    label={"Dokumen NIK"}
                    informationText={"Dokumen NIK"}
                    maxSize={1}
                    maxFiles={1}
                    isView={isView}
                  />
                </div>
              </>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
              <div className="space-y-1">
                <InputForm
                  controllerName={"profile.institution_nib"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={"Nomor NIB/TDP/SKDP/Izin usaha"}
                  required
                  asText={isView}
                />
              </div>
              <div className="space-y-1">
                <AtomDatePicker
                  label="Tanggal Terbit NIB/TDP/SKDP/Izin usaha"
                  required={true}
                  controllerName={`profile.institution_nib_date`}
                  asText={isView}
                  placeholder={"dd/mm/yyyy"}
                  customInputClassname={`py-2 px-4 border w-full rounded-md flex-1`}
                  datepickerOptions={{
                    maxDate: new Date(),
                    showMonthDropdown: true,
                    showYearDropdown: true,
                    scrollableYearDropdown: true,
                    dropdownMode: "select",
                  }}
                />
              </div>
            </div>

            <div className="space-y-1 mb-5">
              <AsyncDropzoneFieldForm
                required
                name={`profile.institution_nib_file`}
                label={"Dokumen NIB/TDP"}
                informationText={"Dokumen NIB/TDP"}
                maxSize={1}
                maxFiles={1}
                isView={isView}
              />
            </div>

            <div className="space-y-1 mb-5">
              <AsyncDropzoneFieldForm
                required
                name={`profile.institution_other_file`}
                label={
                  "Upload Dokumen pendukung lainnya Disatukan (SOP/ Ketetapan Kemenkumham dan dokumen lainnya)"
                }
                informationText={
                  "Upload Dokumen pendukung lainnya Disatukan (SOP/ Ketetapan Kemenkumham dan dokumen lainnya)"
                }
                maxSize={1}
                maxFiles={1}
                isView={isView}
              />
            </div>
          </div>

          {profileData?.agency_profile?.status_string === "Revisi" &&
            profileData?.agency_profile?.agency_verificator &&
            profileData?.agency_profile?.agency_verificator
              ?.legality_document_desc && (
              <div className="flex flex-col gap-2 mt-2">
                <h5 className="text-lg font-medium">
                  Notes (Dokumen Legalitas)
                </h5>
                <div className="p-3 bg-white border border-gray-200 rounded-md text-base">
                  {
                    profileData?.agency_profile?.agency_verificator
                      ?.legality_document_desc
                  }
                </div>
              </div>
            )}
        </CardForm>
      )}

      <CardForm label="Informasi Keuangan 3 Tahun Terakhir">
        <div className="gap-4">
          <div className="space-y-1 mb-5">
            <AsyncDropzoneFieldForm
              required
              name={`profile.finance_file`}
              label={"Informasi Keuangan 3 Tahun Terakhir"}
              informationText={"Informasi Keuangan 3 Tahun Terakhir"}
              maxSize={1}
              maxFiles={1}
              isView={isView}
            />
          </div>
        </div>
        {profileData?.agency_profile?.status_string === "Revisi" &&
          profileData?.agency_profile?.agency_verificator &&
          profileData?.agency_profile?.agency_verificator
            ?.financing_document_desc && (
            <div className="flex flex-col gap-2 mt-2">
              <h5 className="text-lg font-medium">
                Notes (Informasi Keuangan 3 Tahun Terakhir)
              </h5>
              <div className="p-3 bg-white border border-gray-200 rounded-md text-base">
                {
                  profileData?.agency_profile?.agency_verificator
                    ?.financing_document_desc
                }
              </div>
            </div>
          )}
      </CardForm>
    </FormProvider>
  );
};

const LegalityDocumentsSection = ({ removeDocument }) => {
  const { control, watch, setValue } = useFormContext();
  const fields = useWatch({
    control,
    name: "profile.documents",
  });

  const mutateDeleteDocument = useMutation(
    ["DELETE_DOCUMENT"],
    deleteDocumentFormulirPendaftaran,
    {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Dokumen Legalitas berhasil dihapus",
        });
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Dokumen Legalitas gagal dihapus",
        });
      },
    }
  );

  const mutateFileService = useMutation(["File Service"], postFileServices);

  const uploadDocument = useCallback(
    (field, files, index) => {
      const formData = new FormData();

      formData.append("file", files[0]);

      mutateFileService.mutate(formData, {
        onSuccess: (res) => {
          enqueueSnackbar({
            message: "Dokumen Berhasil Disimpan!",
            variant: "success",
          });
          // field.onChange(res.data.data);
          setValue(`profile.documents.${index}.file`, res.data.data.id);
          setValue(`profile.documents.${index}.name`, res.data.data.name);
        },
        onError: (err) => {
          enqueueSnackbar({
            message: "Dokumen Gagal Disimpan!",
            variant: "failed",
          });
        },
      });
    },
    // [mutate, id, setSelectedIndex]
    [mutateFileService, setValue]
  );

  const removeDoc = useCallback(
    (field, doc, index) => {
      if (doc.id) {
        mutateDeleteDocument.mutate(doc.id);
      }
      removeDocument(index);
      field.onChange("");
    },
    [mutateDeleteDocument, removeDocument]
  );

  return (
    <table
      className="w-full bg-white border border-gray-200 rounded-lg"
      style={{ boxShadow: "0px 20px 40px 0px rgba(51, 40, 123, 0.04)" }}
    >
      <thead className="bg-primary-800 text-white rounded-t-lg">
        <tr className="rounded-t-lg">
          <td className="px-6 py-3 font-semibold rounded-tl-lg">No</td>
          <td className="px-6 py-3 font-semibold">Nama Dokumen</td>
          <td className="px-6 py-3 font-semibold">Deskripsi</td>
          <td className="px-6 py-3 font-semibold rounded-tr-lg text-center">
            Aksi
          </td>
        </tr>
      </thead>

      <tbody className="divide-y">
        {!fields?.length && (
          <tr className="rounded-b-lg">
            <td colSpan={4} className="px-6 rounded-b-lg py-3 text-center">
              <div className="text-gray-500 text-sm">Belum ada dokumen</div>
            </td>
          </tr>
        )}
        {fields?.map((field, index) => (
          <tr key={field.id}>
            <td className="px-6 py-3">{index + 1}</td>
            <td className="px-6 py-3 text-gray-600 text-sm">
              {field.description}
            </td>
            <td className="px-6 py-3 text-gray-600 text-sm">
              <div className="flex gap-2 items-center text-blue-500 cursor-pointer">
                <RiDownloadCloud2Line size={20} />
                <p className="text-sm font-semibold">Unduh Dokumen Rujukan</p>
              </div>
            </td>
            <td colSpan={1} className="px-6 py-3 text-center">
              <div className="flex gap-4 items-center justify-center">
                <Controller
                  control={control}
                  name={`profile.documents[${index}].description`}
                  render={({ field }) => {
                    const doc = watch(`profile.documents[${index}]`);
                    return (
                      <div className="flex flex-col gap-2 items-start">
                        {(doc.file === null || doc.file === undefined) && (
                          <label
                            htmlFor={`upload_files${index}`}
                            className="flex gap-2 items-center text-primary-700 cursor-pointer"
                            type="button"
                          >
                            <input
                              type="file"
                              name=""
                              id={`upload_files${index}`}
                              className="hidden"
                              onChange={(e) => {
                                uploadDocument(field, e.target.files, index);
                              }}
                            />
                            <RiUploadCloud2Line size={20} />
                            <h5 className="text-sm font-semibold">Upload</h5>
                          </label>
                        )}
                        {doc.file && (
                          <div className="flex flex-col gap-3">
                            <div className="flex gap-2 items-center">
                              <div className="p-2 bg-primary-100 rounded-full text-primary-700">
                                <RiFile2Line size={18} />
                              </div>
                              <p className="whitespace-nowrap text-sm font-medium">
                                {doc.name}
                              </p>
                            </div>

                            <div className="flex gap-4">
                              {/* {index > 2 && (
                                <button
                                  className="flex gap-1 items-center text-red-700 font-semibold text-sm"
                                  onClick={() => removeDoc(field, doc, index)}
                                  type="button"
                                >
                                  <RiDeleteBin5Line size={20} />
                                  <p>Delete</p>
                                </button>
                              )} */}
                              <label
                                htmlFor="upload_file"
                                className="flex gap-2 items-center text-primary-700 cursor-pointer"
                                type="button"
                              >
                                <input
                                  type="file"
                                  name=""
                                  id="upload_file"
                                  className="hidden"
                                  onChange={(e) => {
                                    uploadDocument(
                                      field,
                                      e.target.files,
                                      index
                                    );
                                  }}
                                />
                                <RiUploadCloud2Line size={20} />
                                <h5 className="text-sm font-semibold">
                                  Upload
                                </h5>
                              </label>
                            </div>
                          </div>
                        )}

                        <p>Tipe File : PDF ; Max Size : 5mb</p>
                      </div>
                    );
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default GeneralInformationForm;
