import React from "react";

const Card = ({ children }) => {
  return (
    <div className="flex rounded-lg border border-t-[#00BD52] border-t-8 bg-white">
      <div className="p-4 w-full">
        {children}
      </div>
    </div>
  );
};

export default Card;
