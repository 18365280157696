import angkaTerbilang from "@develoka/angka-terbilang-js";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { InputForm } from "../../../../../components";
import AtomSelect from "../../../../../components/atoms/Select";
import CardForm from "../../../../../components/molecules/CardForm";
import InputCurrency from "../../../../../components/organisms/inputs/InputCurrency";
import useTimeout from "../../../../../helpers/useTimeout";
import InputCurrencyForm from "components/V2/shared/input-currency-form";

export const FieldLabel = {
  AMOUNT: "Nominal Permohonan",
  AMOUNT_WORD: "Terbilang",
  DURATION: "Jangka Waktu (Tahun)",
  SCHEME: "Skema Pembiayaan",
};

export const validationSchema = Yup.object().shape({
  application_fee: Yup.string().trim().required().label(FieldLabel.AMOUNT),
  application_fee_spel: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.AMOUNT_WORD),
  year_duration: Yup.string().trim().required().label(FieldLabel.DURATION),
  finance_scheme: Yup.string().trim().required().label(FieldLabel.SCHEME),
});

function RequestSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();
  const amount = useWatch({
    control: methods.control,
    name: `${prefix}application_fee`,
  });

  // effects
  // update depending fields on row changes
  useTimeout(
    () => {
      const numToWords = (number) => {
        let normalizedNum = "";

        try {
          normalizedNum = parseFloat(number);
          normalizedNum = isNaN(normalizedNum) ? undefined : normalizedNum;
        } catch (err) {
          //stubbed
        }

        return normalizedNum ? angkaTerbilang(number) + " rupiah" : "";
      };

      const loanAmt = toString(amount);

      // methods.setValue(`${prefix}amount_word`, numToWords(loanAmt));
    },
    400,
    [amount]
  );

  return (
    <CardForm label="Data Permohonan" {...rest}>
      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        <InputCurrencyForm
          name={`${prefix}application_fee`}
          wrapperClass={"col-span-2 lg:col-span-1"}
          prefix="Rp "
          label={FieldLabel.AMOUNT}
          disabled
          readOnly
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}application_fee_spel`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case`}
          wrapperClass={"col-span-2 lg:col-span-1"}
          label={FieldLabel.AMOUNT_WORD}
          placeholder={FieldLabel.AMOUNT_WORD}
          disabled
          readOnly
          asText={isView}
        />

        <InputForm
          controllerName={`${prefix}year_duration`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case`}
          wrapperClass={"col-span-2 lg:col-span-1"}
          label={FieldLabel.DURATION}
          placeholder={FieldLabel.DURATION}
          asText={isView}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />

        <AtomSelect
          controllerName={`${prefix}finance_scheme`}
          className="basic-single mt-2 flex-1"
          wrapperClass={"col-span-1"}
          label={FieldLabel.SCHEME}
          placeholder={FieldLabel.SCHEME}
          returnObject={false}
          options={[
            { value: "Revolving", label: "Revolving" },
            { value: "Non Revolving", label: "Non Revolving" },
          ]}
          required
          asText={isView}
        />
      </div>
    </CardForm>
  );
}

RequestSection.propTypes = {};

export default RequestSection;
