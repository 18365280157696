import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getMasterBussinessType = () => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/business-types`);
};

export const getMasterOtherBussinessType = () => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/other-business-types`);
};
