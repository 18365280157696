import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { RiAddLine } from 'react-icons/ri';
import { Button, ConfirmationModal, InputForm } from 'components';
import AtomSelect from 'components/atoms/Select';
import { SuccessToast } from 'components/atoms/Toast';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import CardForm from 'components/molecules/CardForm';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import { config_daprog } from 'app/config';
import { fileUpload } from 'services/danaProgram/fileService';
import { useSelector } from 'react-redux';
import { getListAWP } from 'services/danaProgram/awpService';
import axiosInstance from 'app/interceptors';
import { REPORT_COMMITMENT_FORM_BREADCRUMBS } from 'contants';
import AtomDatePicker from 'components/atoms/Datepicker';

const CommitmentArray = forwardRef(({ onNext, pIndex }, ref) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'commitment',
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  useImperativeHandle(ref, () => {
    return {
      onSubmit,
    };
  });

  return (
    <div>
      {fields.map((commitment, index) => (
        <CardForm
          key={commitment.id}
          label={`Laporan Implementasi Komitmen ${index + 1}`}
          childerForm
          sideLabel=""
          buttonCollapse
        >
          <div>
            <div className="text-[#1D2939] font-semibold text-sm flex flex-row justify-between items-center">
              Komitmen
              {fields.length > 0 && index > 0 && (
                <Button
                  onClick={() => {
                    remove(index);
                  }}
                  className="rounded-lg border border-gray-300 shadow px-2 bg-error-600 hover:!text-black"
                  label={
                    <div className="flex gap-2 items-center text-white text-xs">
                      Hapus Komitmen
                    </div>
                  }
                />
              )}
            </div>

            <AtomSelect
              controllerName={`commitment.${index}.commitment`}
              className={`mt-2 w-full rounded-md`}
              options={[]}
              label={''}
              placeholder={'Pilih Komitmen'}
              required={true}
            />
          </div>

          <hr />

          <ActivityArray
            ispIndex={pIndex}
            commitmentIndex={index}
            control={control}
          />
        </CardForm>
      ))}

      <div className="text-center">
        <Button
          onClick={() => {
            append({
              commitment: '',
              activities: [
                {
                  activity: '',
                  date: '',
                  report_type: '',
                  period: '',
                  pic: '',
                },
              ],
            });
          }}
          className="rounded-lg border border-green-500 bg-white shadow py-2.5 px-4"
          label={
            <div className="flex gap-2 items-center text-gray-700 text-sm">
              <RiAddLine size={20} />
              Tambah komitmen
            </div>
          }
        />
      </div>
    </div>
  );
});

const ActivityArray = ({ commitmentIndex, control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `commitment.${commitmentIndex}.activities`,
  });

  return (
    <>
      {fields.map((activity, index) => (
        <div
          key={activity.id}
          className="flex flex-col gap-2 border rounded-lg p-4"
        >
          <div>
            <div className="text-[#1D2939] font-semibold text-sm flex flex-row justify-between items-center">
              Kegiatan
              {fields.length > 0 && index > 0 && (
                <Button
                  onClick={() => {
                    remove(index);
                  }}
                  className="rounded-lg border border-gray-300 shadow px-2 bg-error-600 hover:!text-black"
                  label={
                    <div className="flex gap-2 items-center text-white text-xs">
                      Hapus Kegiatan
                    </div>
                  }
                />
              )}
            </div>

            <AtomSelect
              controllerName={`commitment.${commitmentIndex}.activities.${index}.activity`}
              className={`mt-2 w-full rounded-md`}
              options={[]}
              label={''}
              placeholder={'Pilih Kegiatan'}
              required={true}
            />
          </div>

          <div className="flex-1">
            <AtomDatePicker
              controllerName={`commitment.${commitmentIndex}.activities.${index}.date`}
              label="Tanggal Pelaksanaan"
              isRangeDatePicker
            />
          </div>

          <div>
            <InputForm
              label={'Deskripsi Pelaksanaan Komitmen'}
              controllerName={`proposal_title`}
              className={`py-2 px-4 mt-2 border w-full rounded-md`}
              rows={5}
              required
              textArea
            />
          </div>

          <div className="flex flex-row gap-4 items-center">
            <div className="flex-1 space-y-2">
              <div className="flex gap-1 font-semibold text-[14px]">
                <span className={`label-text`}>Jenis Dokumen Laporan</span>
              </div>
              <InputForm
                controllerName={`.commitment.${commitmentIndex}.activities.${index}.report_type`}
                className="w-full"
              />
            </div>
          </div>

          <div className="space-y-4">
            <label className="text-[#1D2939] font-semibold text-sm">
              Upload Dokumen Hasil Pelaksanaan Komitmen
              <span className="ml-1 text-error-500 text-sm">*</span>
            </label>
            <InputFormDropzone
              name={`.commitment.${commitmentIndex}.activities.${index}.doc`}
              informationText="PDF, JPG, PNG, Docx, XLSX, PPD"
              accept={{
                'application/pdf': [],
                'image/jpeg': [],
                'image/png': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  [],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  [],
                'application/vnd.cups-ppd': [],
              }}
              maxFiles={30}
              maxSize={10}
              multiple={false}
            />
          </div>
        </div>
      ))}

      <div className="text-right">
        <Button
          onClick={() => {
            append({
              activity: '',
              date: '',
              report_type: '',
              period: '',
              pic: '',
            });
          }}
          className="rounded-lg border border-green-500 bg-white shadow py-2.5 px-4"
          label={
            <div className="flex gap-2 items-center text-gray-700 text-sm">
              <RiAddLine size={20} />
              Tambah Kegiatan
            </div>
          }
        />

        <hr className="mt-4" />
      </div>
    </>
  );
};

const KonsepNotesForm = () => {
  const categoryRef = useRef(null);

  const methods = useForm({
    defaultValues: {
      project_name: '',
      proposal_title: '',
      period: '',
      year: '',
      commitment: [
        {
          commitment: '',
          activities: [
            {
              activity: '',
              date: [],
              desc: '',
              doc_type: '',
              docs: [],
            },
          ],
        },
      ],
    },
  });

  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [optionAWP, setOptionAWP] = useState([]);
  const [limit, setLimit] = useState(10);
  const userId = useSelector((state) => state.auth.user.UserId);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const fetchProjectProposal = async () => {
    const response = await getListAWP({ page: page, limit: limit });
    const data = await response?.data?.data?.data;

    const listAWP = data.map((el, i) => ({
      value: el?._id,
      label: el?.proposal_number,
      investment_id: el?.investment_id,
    }));

    setLastPage(response?.data?.data?.lastPage);

    setOptionAWP((prevState) => [...prevState, ...listAWP]);
  };

  useEffect(() => {
    fetchProjectProposal();
  }, [page]);

  const submitForm = () => {
    setConfirmModal(true);
  };

  const fileUploadHelper = (listFile) => {
    let temp = [];

    return new Promise(async (resolve, reject) => {
      try {
        for (let file of listFile) {
          const formData = new FormData();
          formData.append('file', file);

          const res = await fileUpload(formData);

          const data = await res.data.data;

          const payload = {
            fileId: data.id,
            fileName: data.name,
            fileSize: data.size,
            mimeType: data.mimeType,
            path: data.url,
          };

          temp.push(payload);
        }

        resolve(temp);
      } catch (err) {
        reject(err);
      }
    });
  };

  const onCreate = async (data) => {
    let investment = data.awp_id;
    let paramMainActivity = data.main_activity.map((el) => el?.val);
    let paramActivityGoal = data.activity_goals.map((el) => el?.val);

    // destructuring and split param investment_id
    data.awp_id = investment.value;
    data.project_name = investment.label;

    data.main_activity = paramMainActivity;
    data.activity_goals = paramActivityGoal;

    let postFileRegionalRestriction,
      postFileRabDoc,
      postFileBeneficiariesDoc,
      postAdditionalDoc;

    // this field is optional so that's set up condition
    if (data.regional_restrictions) {
      postFileRegionalRestriction = await fileUploadHelper(
        data.regional_restrictions
      );
    }

    postFileRabDoc = await fileUploadHelper(data.rab_documents);

    postFileBeneficiariesDoc = await fileUploadHelper(
      data.file_beneficiaries_document
    );

    // this field is optional so that's set up condition
    if (data.additional_document) {
      postAdditionalDoc = await fileUploadHelper(data.additional_document);
    }

    let [
      regionalRestrictions,
      rabDocuments,
      beneficiariesDocuments,
      additionalDocuments,
    ] = await Promise.all([
      postFileRegionalRestriction,
      postFileRabDoc,
      postFileBeneficiariesDoc,
      postAdditionalDoc,
    ]);

    data.user_id = userId;
    data.regional_restrictions = regionalRestrictions;
    data.rab_documents = rabDocuments;
    data.file_beneficiaries_document = beneficiariesDocuments;
    data.additional_document = additionalDocuments;

    try {
      const response = await axiosInstance.post(
        `${config_daprog.BASE_URL}/v1/notes`,
        data
      );

      setSuccess(true);

      setConfirmModal(!confirmModal);

      navigate('/proposal/konsep-notes');
    } catch (err) {
      console.error(err);
    }
  };

  const reachBottom = () => {
    if (page < lastPage) {
      setPage(page + 1);
    }
  };

  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmModal}
        onCancel={setConfirmModal}
        onSubmit={() => onCreate(methods.getValues())}
        cancelLabel={'Tidak, Ubah Data'}
        confirmLabel={'Ya, Kirimkan'}
      >
        <div className="text-lg font-semibold">
          Anda yakin akan mengirimkan semua data ini?
        </div>
        <div className="text-sm">
          Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada
          formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan
          unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk
          diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku.
        </div>
      </ConfirmationModal>
    );
  };

  return (
    <>
      {/* Modal */}
      {renderModal()}

      {success && (
        <SuccessToast
          onClose={() => setSuccess(!success)}
          message={'Data Berhasil Dikirim!'}
          message_description="Data yang anda masukkan sudah berhasil dikirim"
        />
      )}

      <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
        {/* Header Section */}
        <div className="flex flex-col gap-4">
          <BreadCrumbs routes={REPORT_COMMITMENT_FORM_BREADCRUMBS} />
        </div>

        <FormProvider {...methods}>
          <CardForm label="Laporan Implementasi Komitmen Perlindungan Lingkungan & Sosial">
            <div className="flex flex-col gap-4">
              <AtomSelect
                controllerName={`project_name`}
                className={`mt-2 w-full rounded-md`}
                options={optionAWP}
                label={'Nama Proyek'}
                placeholder={'Pilih Nama Proyek'}
                required={true}
                onMenuScrollToBottom={reachBottom}
              />

              <InputForm
                label={'Judul Proposal'}
                controllerName={`proposal_title`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                rows={5}
                required
                textArea
              />

              <div className="flex flex-row gap-4">
                <AtomSelect
                  controllerName={`period`}
                  className={`mt-2 w-full rounded-md`}
                  options={[]}
                  label={'Periode yang Dilaporkan'}
                  placeholder={'Pilih Periode yang Dilaporkan'}
                  required={true}
                  onMenuScrollToBottom={reachBottom}
                />

                <AtomSelect
                  controllerName={`year`}
                  className={`mt-2 w-full rounded-md`}
                  options={[]}
                  label={'Tahun'}
                  placeholder={'Tahun'}
                  required={true}
                  onMenuScrollToBottom={reachBottom}
                />
              </div>

              <p className="font-bold text-lg text-green-500">
                Laporan Implementasi Komitmen
              </p>

              <CommitmentArray ref={categoryRef} onNext={submitForm} />
            </div>
          </CardForm>
        </FormProvider>

        <div className="flex justify-between sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
          <Button
            label={
              <div className="flex items-center gap-2">
                <BiArrowBack />
                <span>Kembali</span>
              </div>
            }
            size="md"
            className="bg-white"
            onClick={() => navigate('/proposal/konsep-notes')}
          />
          <Button
            label="Submit"
            size="md"
            onClick={() =>
              methods.handleSubmit(categoryRef?.current?.onSubmit)()
            }
            className="bg-primary-600 hover:bg-primary-600/90 text-white"
          />
        </div>
      </div>
    </>
  );
};

export default KonsepNotesForm;
