const {
  RiInformationLine,
  RiAlertLine,
  RiCheckboxCircleLine,
} = require('react-icons/ri');
const { useMemo } = require('react');
const Alert = ({ title, description, icon, theme = 'default' }) => {
  const themeClass = useMemo(() => {
    let container = 'bg-[#FCFCFD] border border-[#D0D5DD]';
    let title = 'text-[#1D2939]';
    let icon = 'text-[#1D2939]';

    switch (theme) {
      case 'success': {
        container = 'bg-[#F6FEF9] border border-[#82E3AA]';
        icon = 'text-[#1C7D44]';
        title = 'text-[#1C7D44]';
        break;
      }
      case 'warning': {
        container = 'bg-warning-25 border border-warning-300';
        icon = 'text-warning-700';
        title = 'text-warning-700';
        break;
      }
      default:
        break;
    }

    return {
      container,
      title,
      icon: 'w-[1.2em] h-[1.2em] ' + icon,
    };
  }, [theme]);

  const renderIcon = () => {
    if (typeof icon === 'function') {
      return icon({ className: themeClass.icon });
    }

    let themeIcon = '';

    switch (theme) {
      case 'success': {
        themeIcon = <RiCheckboxCircleLine className={themeClass.icon} />;
        break;
      }
      case 'warning': {
        themeIcon = <RiAlertLine className={themeClass.icon} />;
        break;
      }
      default:
        themeIcon = <RiInformationLine />;
        break;
    }

    return themeIcon;
  };

  return (
    <div className={['rounded-xl p-4', themeClass.container].join(' ')}>
      <div className="flex flex-row gap-4">
        <div className="w-[17px] h-[15px]">{renderIcon()}</div>
        <div className="flex-1">
          <div className="flex flex-col gap-0.5">
            <p
              className={['font-semibold text-[14px]', themeClass.title].join(
                ' '
              )}
            >
              {title}
            </p>
            <p className="font-normal text-[14px] text-gray-600">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
