import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const getReportActivity = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/activity-report/list`,
    { params },
    { headers }
  )
}