import { RiQuestionLine } from "react-icons/ri";

const QuestionBadge = ({ text = `` }) => {
  return (
    <div className="flex">
      <div className="w-full mt-2 flex gap-4 items-center p-4 bg-white border rounded-lg">
        <div className="flex w-fit">
          <RiQuestionLine className={`text-[27px]`} />
        </div>
        <div className="flex flex-1">{text}</div>
      </div>
    </div>
  );
};

export default QuestionBadge;
