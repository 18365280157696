import {
  ConfirmationModal, Table,
} from "components";
import { SuccessToast,  } from "components/atoms/Toast";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useForm,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CardForm from "components/molecules/CardForm";
import moment from "moment";
import { detail } from "services/danaProgram/disbursement";

const validationSchema = yup.object({
});

const DisbursementDetail = () => {
  const [detailDisbursement, setDetailDisbursement] = useState(null);
  
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
    mode: 'all'
  });

  const { id: idDisbursement } = useParams();

  const fetchDisbursementDetail = async (id) => {
    const response = await detail(id);
    const data = await response?.data?.data;

    setDetailDisbursement(data);
  };

  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [payload, setPayload] = useState({});
  const [success, setSuccess] = useState(false);
  const [page, setPage] = useState(null);
  const [rowPage, setRowPage] = useState(10);

  const submitForm = () => {
    setConfirmModal(true);
  }

  const items = [
    {
      label: "Daftar Permohonan",
      path: "/disbursement/application",
    },
    { label: "Detail Permohonan" },
  ];

  useEffect(() => {
    idDisbursement && fetchDisbursementDetail(idDisbursement);
  }, [idDisbursement]);

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
      total: 5,
      previous_pages: true,
      next_pages: true,
    };
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "submissionDate",
        title: "Tanggal",
        dataIndex: "submission_date",
        className: "",
        columnClassName:
          "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value ? moment(value).format("DD/MM/YYYY") : "-"}
          </div>
        ),
      },
      {
        id: "proposalTitle",
        title: "Aktivitas",
        dataIndex: "proposal_title",
        className: "",
        columnClassName:
          "w-[250px] text-ellipsis overflow-hidden whitespace-break-spaces",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[250px]">
            {value}
          </div>
        ),
      },
      {
        id: "projectName",
        title: "User",
        dataIndex: "project_name",
        className: "",
        columnClassName:
          "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value}
          </div>
        ),
      },
    ];
  }, []);

  return (
    <>

      <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
        {/* Header Section */}
        <div className="flex flex-col gap-4">
          <BreadCrumbs routes={items} />
        <div>
        <div className="space-y-6">
            <CardForm label="NO: 350/BA/2023/865">
              <CardForm label="General Informasi" buttonCollapse={true} childerForm={true}>
                <div className="flex">
                  <div className="flex flex-col w-full">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Tanggal Permohonan
                    </label>
                    <label className="text-[#1D2939] font-semibold text-sm">
                      02/04/2023
                    </label>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Tahap Pencairan yang diajukan
                    </label>
                    <label className="text-[#1D2939] font-semibold text-sm">
                      02/04/2023
                    </label>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Nomor Perjanjian
                    </label>
                    <label className="text-[#1D2939] font-semibold text-sm">
                      02/04/2023
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col w-full">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Nama Project
                    </label>
                    <label className="text-[#1D2939] font-semibold text-sm">
                      02/04/2023
                    </label>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Nilai Permohonan
                    </label>
                    <label className="text-[#1D2939] font-semibold text-sm">
                      02/04/2023
                    </label>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Tanggal Perjanjian
                    </label>
                    <label className="text-[#1D2939] font-semibold text-sm">
                      02/04/2023
                    </label>
                  </div>
                </div>
                  <div className="flex flex-col w-full">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Lampiran Dokumen
                    </label>
                    <div className="flex gap-4">
                      <label className="text-[#1D2939] font-semibold text-sm">
                        02/04/2023
                      </label>
                    </div>
                </div>
              </CardForm>
            </CardForm>
            <CardForm label="Riwayat">
              <Table
                bordered
                stripped
                layout="fixed"
                className="mb-4"
                columns={tableColumns}
                dataSource={[]}
                isLoading={false}
                onChangePage={(page) => setPage(page)}
                onChangeRowsPerPage={(row) => setRowPage(row)}
              />
            </CardForm>
        </div>
    </div>
        </div>
      </div>
    </>
  );
};

export default DisbursementDetail;
