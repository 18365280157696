import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import * as yup from 'yup';

import CardForm from 'components/molecules/CardForm';
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormUpload,
} from 'components';
import AtomSelect from 'components/atoms/Select';
import InputCurrency from 'components/organisms/inputs/InputCurrency';
import { RiAddLine, RiAlertLine, RiCheckLine } from 'react-icons/ri';
import { FiAlertCircle } from 'react-icons/fi';
import FileInfoField from 'components/atoms/FileInfoField';
import { validationCustomErrorMsg, validationErrorMsg } from 'locale/yup_id';
import { find, map } from 'lodash';
import { DOC_UPLOAD_EXT, MAX_DOC_UPLOAD } from 'app/config';
import { humanizeFileSize } from 'helpers';
import { BiTrash } from 'react-icons/bi';

const FieldLabel = {
  APP_VALUE: 'Nilai Permohonan',
  COOPERATION_PATTERN: 'Pola Kerjasama',
  FINANCING_TERM_PLAN: 'Rencana jangka waktu pembiayaan yang akan disalurkan',
  COLLATERAL_TYPE: 'Jenis Jaminan',
  GUARANTEE_FORM: 'Bentuk Jaminan',
  GUARANTEE_NAME: 'Nama Jaminan',
  COLLATERAL_VALUE: 'Nilai Jaminan',
  GUARANTEE_FILE: 'Upload Jaminan',
  FDB_POTENCY: 'Upload Daftar Potensi Penerima FDB',
};

const ApplicationValue = ({ prefix, onSubmit, onBack }) => {
  const options = useMemo(
    () => [
      { value: '1', label: 'Option 1' },
      { value: '2', label: 'Option 2' },
    ],
    []
  );
  const collateral_type_options = useMemo(
    () => [
      { value: 1, label: 'Aset Tetap' },
      { value: 2, label: 'Aset Tidak Tetap / Bergerak' },
    ],
    []
  );
  const guarantee_form_options = useMemo(
    () => [
      [
        { value: 'Rumah', label: 'Rumah' },
        { value: 'Tanah', label: 'Tanah' },
        { value: 'Ruko', label: 'Ruko' },
        { value: 'Lainnya', label: 'Lainnya' },
      ],
      [
        { value: 'Tagihan Lancar', label: 'Tagihan Lancar' },
        { value: 'Cash Collateral', label: 'Cash Collateral' },
        { value: 'Lainnya', label: 'Lainnya' },
      ],
    ],
    []
  );
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      [prefix]: yup.object({
        app_value: yup
          .string()
          .trim()
          .max(20)
          .required()
          .label(FieldLabel.APP_VALUE),
        cooperation_pattern: yup
          .mixed()
          .required()
          .label(FieldLabel.COOPERATION_PATTERN),
        financing_term_plan: yup
          .mixed()
          .required()
          .label(FieldLabel.FINANCING_TERM_PLAN),
        collaterals: yup.array().of(
          yup.object().shape({
            collateral_type: yup
              .mixed()
              .required()
              .label(FieldLabel.COLLATERAL_TYPE),
            guarantee_form: yup
              .mixed()
              .required()
              .label(FieldLabel.GUARANTEE_FORM),
            guarantee_name: yup.string().when('guarantee_form', {
              is: (guarantee_form) => {
                return guarantee_form?.value === 'Lainnya';
              },
              then: (schema) =>
                schema.required().label(FieldLabel.GUARANTEE_NAME),
            }),
            collateral_value: yup
              .string()
              .trim()
              .required()
              .label(FieldLabel.COLLATERAL_VALUE),
            guarantee_file: yup
              .array()
              .min(1)
              .label(FieldLabel.GUARANTEE_FILE)
              .of(
                yup.object().shape({
                  file: yup
                    .mixed()
                    .test(
                      'file',
                      validationErrorMsg.mixed.required,
                      (value, context) => {
                        let allowedTypes = DOC_UPLOAD_EXT;
                        const maxFileSize = MAX_DOC_UPLOAD;

                        // if has value, validate the file
                        // console.log("value", value);
                        if (value) {
                          const data = find(allowedTypes, (at) =>
                            value.type.includes(at)
                          );
                          if (!data) {
                            return context.createError({
                              message: validationCustomErrorMsg.isFiletype(
                                allowedTypes.join(', ')
                              )({ label: FieldLabel.GUARANTEE_FILE }),
                            });
                          }
                          if (value.size > maxFileSize) {
                            return context.createError({
                              message: validationCustomErrorMsg.isFileSize(
                                humanizeFileSize(maxFileSize)
                              )({
                                label: FieldLabel.GUARANTEE_FILE,
                              }),
                            });
                          }

                          return true;
                        }

                        // otherwise, return false to render the field required
                        return false;
                      }
                    )
                    .required()
                    .label(FieldLabel.GUARANTEE_FILE),
                })
              ),
          })
        ),
        // fdb_potency: yup
        //   .mixed()
        //   .test('file', validationErrorMsg.mixed.required, (value, context) => {
        //     let allowedTypes = DOC_UPLOAD_EXT;
        //     const maxFileSize = MAX_DOC_UPLOAD;

        //     // if has value, validate the file
        //     if (value) {
        //       const data = find(allowedTypes, (at) => value.type.includes(at));
        //       if (!data) {
        //         return context.createError({
        //           message: validationCustomErrorMsg.isFiletype(
        //             allowedTypes.join(', ')
        //           )({ label: FieldLabel.FDB_POTENCY }),
        //         });
        //       }
        //       if (value.size > maxFileSize) {
        //         return context.createError({
        //           message: validationCustomErrorMsg.isFileSize(
        //             humanizeFileSize(maxFileSize)
        //           )({
        //             label: FieldLabel.FDB_POTENCY,
        //           }),
        //         });
        //       }

        //       return true;
        //     }

        //     // otherwise, return false to render the field required
        //     return false;
        //   })
        //   .required()
        //   .label(FieldLabel.FDB_POTENCY),
      }),
    });
  }, [prefix]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      [prefix]: {
        app_value: '',
        cooperation_pattern: null,
        financing_term_plan: null,
        collaterals: [
          {
            collateral_type: null,
            guarantee_form: null,
            guarantee_name: '',
            collateral_value: '',
            guarantee_file: [],
          },
        ],
        // fdb_potency: null,
      },
    },
  });

  const {
    formState: { errors, isValid },
    control,
    getValues,
    setValue,
  } = methods;

  const values = getValues();

  const {
    fields: collateralFields,
    append: appendCollateral,
    remove: removeCollateral,
  } = useFieldArray({
    control: methods.control,
    name: `${prefix}.collaterals`,
  });

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-10">
        <CardForm label={'Nilai Permohonan'}>
          <div className="space-y-6">
            <InputCurrency
              controllerName={`${prefix}.app_value`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${
                errors.nilai_permohonan ? 'border-[#F04438]' : 'border-green'
              }`}
              label={FieldLabel.APP_VALUE}
              required={true}
            />
            <AtomSelect
              controllerName={`${prefix}.cooperation_pattern`}
              className={`mt-2 w-full rounded-md `}
              options={options}
              label={FieldLabel.COOPERATION_PATTERN}
              placeholder={' '}
              required={true}
            />
            <AtomSelect
              controllerName={`${prefix}.financing_term_plan`}
              className={`mt-2 w-full rounded-md `}
              options={options}
              label={FieldLabel.FINANCING_TERM_PLAN}
              placeholder={' '}
              required={true}
            />
          </div>
        </CardForm>
        <CardForm label={'Nilai Jaminan'}>
          <div className="space-y-6">
            {map(collateralFields, (field, index) => (
              <div key={field.id} className="space-y-6">
                {index > 0 && <div className="border-t" />}
                {index > 0 && (
                  <div className="flex items-center justify-end mx-4">
                    <Button
                      type="button"
                      onClick={() => removeCollateral(index)}
                      className="p-3 rounded-lg bg-error-600 flex-none"
                      label={<BiTrash className="text-white" />}
                    />
                  </div>
                )}
                <AtomSelect
                  controllerName={`${prefix}.collaterals.${index}.collateral_type`}
                  className={`mt-2 w-full rounded-md `}
                  options={collateral_type_options}
                  label={FieldLabel.COLLATERAL_TYPE}
                  placeholder={' '}
                  required={true}
                />
                <AtomSelect
                  controllerName={`${prefix}.collaterals.${index}.guarantee_form`}
                  className={`mt-2 w-full rounded-md `}
                  options={
                    guarantee_form_options[
                      methods.watch(
                        `${prefix}.collaterals.${index}.collateral_type`
                      )?.value - 1
                    ]
                  }
                  label={FieldLabel.GUARANTEE_FORM}
                  placeholder={' '}
                  isDisabled={
                    !methods.watch(
                      `${prefix}.collaterals.${index}.collateral_type`
                    )?.value
                  }
                  required={true}
                />
                {methods?.watch(`${prefix}.collaterals.${index}.guarantee_form`)
                  ?.value === 'Lainnya' && (
                  <InputForm
                    controllerName={`${prefix}.collaterals.${index}.guarantee_name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md ${
                      errors.nilai_permohonan
                        ? 'border-[#F04438]'
                        : 'border-green'
                    }`}
                    label={FieldLabel.GUARANTEE_NAME}
                    required={true}
                  />
                )}
                <InputCurrency
                  controllerName={`${prefix}.collaterals.${index}.collateral_value`}
                  wrapperClass={'col-span-1'}
                  label={FieldLabel.COLLATERAL_VALUE}
                  required
                />
                <div className="space-y-4">
                  <FileSection prefix={prefix} indexData={index} />
                  {/* <FileInfoField
                    title={"data_jaminan.xlsx"}
                    desc={"200 KB"}
                    showDownload
                    showIcon
                  />
                  <FileInfoField
                    title={"data_jaminan.xlsx"}
                    desc={"200 KB"}
                    isUpload
                    progress={10}
                    // showDownload
                    showIcon
                  /> */}
                </div>
              </div>
            ))}
            <div className="border-t" />
            <div className="flex flex-row-reverse my-5 mx-4">
              <Button
                className="p-4 border rounded-lg bg-white"
                onClick={() =>
                  appendCollateral({
                    collateral_type: null,
                    guarantee_form: null,
                    guarantee_name: '',
                    collateral_value: '',
                    guarantee_file: [],
                  })
                }
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine />
                    Tambah Jaminan
                  </div>
                }
              />
            </div>
            {/* <div className={"space-y-1"}>
              <label className={"font-semibold"}>
                Format Daftar Potensi Penerima FDB
              </label>
              <FileInfoField
                title={"Format Daftar Potensi Penerima FDB.xlsx"}
                desc={"2 MB"}
                showDownload
                showIcon
              />
            </div> */}
            {/* <InputFormUpload
              controllerName={`${prefix}.fdb_potency`}
              label={FieldLabel.FDB_POTENCY}
              required
            /> */}
          </div>
        </CardForm>
      </div>
      <BottomFormAction
        // lastStep={true}
        backButtonProps={{
          type: 'button',
        }}
        draftButtonProps={{
          type: 'button',
        }}
        submitButtonProps={{
          type: 'submit',
        }}
        submitActionButton={onFormSubmit()}
        backButtonAction={onBack}
      />
    </FormProvider>
  );
};

const FileSection = ({ prefix, indexData }) => {
  const { control } = useFormContext();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `${prefix}.collaterals.${indexData}.guarantee_file`,
  });

  const onModalSubmit = (data) => {
    console.log('UPLOAD', data);
    append({ file: data.file });
  };

  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [controllerTarget, setControllerTarget] = useState();

  return (
    <div className="space-y-4">
      <InputFormUpload
        isMulti
        controllerName={`${prefix}.collaterals.${indexData}.guarantee_file`}
        onModalSubmit={onModalSubmit}
        setUploadedUrls={setUploadedUrls}
        setControllerTarget={setControllerTarget}
        label={
          <>
            <label className="label font-semibold text-[14px]">
              <span className={`label-text`}>{FieldLabel.GUARANTEE_FILE}</span>
              <span className="text-[#F04438]">*</span>
            </label>
            <div className="w-full pt-2">
              <div className="bg-gray-25 border border-gray-300 rounded-xl p-4">
                <div className="flex flex-row gap-2">
                  <div className="w-[17px] h-[15px] mt-1">
                    <FiAlertCircle className="text-gray-800" />
                  </div>
                  <div className="flex-1">
                    <span className="font-semibold text-[14px] text-gray-800">
                      Bisa upload lebih dari satu
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
      {map(fields, (field, index) => (
        <FileInfoField
          key={field.id}
          title={field?.file?.name}
          desc={humanizeFileSize(field?.file?.size)}
          isUpload
          progress={100}
          // uploadResult={"success"}
          uploadResult={'downloaded'}
          theme="success"
          showIcon
          onDeleteClick={() => remove(index)}
        />
      ))}
    </div>
  );
};

export default ApplicationValue;
