const { RiAlertLine, RiCheckFill } = require("react-icons/ri");

const AlertBlankData = ({ routes, title, body }) => {
  return (
    <div className="bg-[#FFFCF5] border border-[#F5AE70] rounded-xl p-4">
      <div className="flex flex-row gap-2">
        <div className="w-[20px] h-[15px] mt-1">
          <RiAlertLine className="text-[#F5AE70] text-[20px]" />
        </div>
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="font-semibold text-[14px] text-[#8F480A]">
              {title ? title : "Lengkapi Dokumen"}
            </span>
            <span className="font-normal text-[14px] text-[#667085]">
              {body
                ? body
                : "Anda bisa upload semua persyaratan jika semua dokumen sudah dilengkapi!"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AlertDataComplete = ({ routes }) => {
  return (
    <div className="bg-[#ECFDF3] rounded-full p-2">
      <div className="flex flex-row gap-2 w-fit">
        <div className="w-[17px] h-[15px] mt-1">
          <RiCheckFill className="text-[#12B76A]" />
        </div>
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="font-medium text-[14px] text-[#027A48]">
              Data Sudah Dilengkapi
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AlertWarning = ({ children, className }) => {
  return (
    <div role="alert" className={`bg-[#FFFCF5] border border-[#F5AE70] rounded-xl p-4 flex flex-row items-center gap-4 ${className}`}>
      <div className="w-[20px] h-[15px] shrink-0 flex items-center">
        <RiAlertLine className="text-[#8F480A] text-[20px]" />
      </div>
      <div className="flex-grow font-semibold text-sm text-[#8F480A]">
        {children}
      </div>
    </div>
  );
};

export default AlertBlankData;
