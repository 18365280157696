import React, { useState } from "react";
import AtomSelect from "../../../atoms/Select";

const SelectPurpose = React.forwardRef((props, ref) => {
  const { className, districtId, ...restProps } = props;

  const [options, setOptions] = useState([
    {
      value: "Untuk Modal Usaha",
      label: "Modal Usaha",
    },
    {
      value: "Untuk Investasi",
      label: "Investasi",
    },
  ]);

  return (
    <AtomSelect
      options={options}
      className={["basic-single flex-1", className].join(" ")}
      {...restProps}
    />
  );
});

export default SelectPurpose;
