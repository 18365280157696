import { StepProgress } from "components";
import { AlertWarning } from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { useMemo, useState } from "react";
import ApplicationValue from "./applicationValue";

import FinanceForm from "./financeForm";
import DebturAccessForm from "./debturAcscessForm";
import SystemITForm from "./systemITForm";
import { useLocation, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAgencyProfile, getAgencyRequestById } from "services/fdb/lembagaPenyalur";

const ApplicationForm = (props) => {
  const { type, group } = props;
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const step = urlParams.get('step');
  const request_id = urlParams.get('request');

  const [activeStep, setActiveStep] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useQuery({
    queryKey: ['request-profile-by-id'],
    queryFn: () => request_id ? getAgencyRequestById(request_id) : null,
    select: data => data?.data?.data,
  })

  const breadCrumbs = useMemo(
    () => [
      {
        label: "Daftar Permohonan",
        path: `/permohonan-pembiayaan/${group}/${type}`,
      },
      {
        label: "Formulir Permohonan Pembiayaan",
      },
    ],
    [group, type]
  );

  const stepProgress = useMemo(
    () => [
      {
        label: "Permohonan & Penjaminan",
        step: 1,
      },
      {
        label: "Keuangan",
        step: 2,
      },
      {
        label: "Akses Debitur",
        step: 3,
      },
      {
        label: "Sistem IT",
        step: 4,
      },
    ],
    []
  );

  const warningLabel = () => {
    let label =
      "masukan data keuangan 2/3 taun terakhir & isi semua data di bawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya";

    if (step === '1')
      label =
        "Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya";

    return label;
  };

  return (
    <div>
      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-36 z-[99]">
        <BreadCrumbs routes={breadCrumbs} />
        <div className="border-b" />

        <div className="w-full flex justify-center">
          <div className="w-full mb-10">
            <StepProgress
              stepProgressData={stepProgress}
              activeStep={parseInt(step)}
            />
          </div>
        </div>

        <div className="w-full pt-4">
          <AlertWarning className={"w-full"}>{warningLabel()}</AlertWarning>
        </div>

        {step === "1" && <ApplicationValue dataApplication={data?.application} />}
        {step === "2" && <FinanceForm typeLembaga={type} />}
        {step === "3" && <DebturAccessForm />}
        {step === "4" && <SystemITForm />}
      </div>
    </div>
  );
};

export default ApplicationForm;
