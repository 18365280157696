import { Button, Spinner } from "components";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { CgClose } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";
import graphic from "../../../../assets/confirmation_graphic.svg";

const ConfirmationModal = ({
  open,
  onClose,
  onCancel = () => {},
  onSubmit,
  cancelLabel,
  confirmLabel,
  children,
  className = "",
  useGraphic = true,
  onCloseOverlay = false,
  isLoading = false,
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div
          className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-[9999999]"
          onClick={() => onCloseOverlay && onClose()}
        >
          <motion.div
            key="modal-confirmation"
            className="bg-white rounded-xl min-w-[400px] max-w-[400px] overflow-hidden"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div className={"max-h-[90vh] overflow-auto"}>
              <div className="flex flex-col p-6">
                <div className="flex-1 flex items-center justify-between">
                  {useGraphic ? (
                    <img src={graphic} alt="Confirmation Graphic" />
                  ) : (
                    <div className="bg-warning-50 rounded-full p-2">
                      <div className="bg-warning-200 rounded-full p-1">
                        <FiAlertCircle className="text-3xl text-warning-600" />
                      </div>
                    </div>
                  )}
                </div>
                <div className={`flex-1 py-4 ${className}`}>{children}</div>
                <div className="flex-1 flex items-center space-x-4">
                  <AnimatePresence mode="wait">
                    {!isLoading && (
                      <Button
                        type="button"
                        className="text-gray-700 border border-gray-300 bg-white hover:bg-gray-300 flex-1 py-2 rounded"
                        onClick={() => {
                          if (onClose) {
                            onClose(false);
                          }
                          onCancel();
                        }}
                        label={cancelLabel || "Cancel"}
                      />
                    )}
                    <Button
                      type="button"
                      className="text-white border  border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 py-2 rounded disabled:bg-white disabled:shadow-none"
                      onClick={onSubmit}
                      disabled={isLoading}
                      label={
                        isLoading ? (
                          <Spinner className={"p-0"} />
                        ) : (
                          confirmLabel || "Confirm"
                        )
                      }
                    />
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ConfirmationModal;
