import { useMutation, useQuery } from "@tanstack/react-query";
import {
  BottomFormAction,
  ConfirmationModal,
  HeaderLabel,
  Spinner,
} from "components";
import AlertBlankData from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { AUTOSAVE_DURATION } from "../../../../../app/config";
import ProgressModal from "../../../../../components/molecules/Modal/ProgressModal";
import { getErrorMessage } from "../../../../../helpers";
import useInterval from "../../../../../helpers/useInterval";
import {
  AgreementSection,
  validationSchema as AgreeValSchema,
} from "../../../components/agreementSection";
import AkunKelompokPSFormInfo from "./infoForm";
import {
  getRegisterForm,
  putRegisterForm,
} from "services/fdb/perhutananSosial/registerForm";
import { getActivityCategory } from "services/fdb/activityCategory";
import { getCompanionAffiliates } from "services/masterData/companionAffiliates";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemaFormRegisterPS } from "./validationFormPS";
import { getMasterSocialArea } from "services/fdb/socialArea";
import { getMasterBussinessCategory } from "services/fdb/bussinessCategory";
import { map } from "lodash";

const validationSchema = Yup.object().shape({
  validationSchemaFormRegisterPS,
  AgreeValSchema,
  partners: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().label("Mitra Usaha Kelompok"),
    })
  ),
  activities: Yup.array().of(
    Yup.object().shape({
      activity_category_id: Yup.string().required().label("Category"),
      description: Yup.string().required().label("Deskripsi"),
    })
  ),
  finance_businesses: Yup.array().of(
    Yup.object().shape({
      finance_business_category_id: Yup.string().required().label("Category"),
      description: Yup.string().required().label("Deskripsi"),
    })
  ),
});

const AkunKelompokPSFormMain = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userId = useSelector((state) => state?.oidc?.oidc_user?.access_token);

  // get mode from path
  const splitPath = location?.pathname?.split("/").filter((e) => !!e);
  const mode = splitPath?.pop();
  const [isEdit, isView] = ["edit", "view"].map((e) => e === mode);

  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);
  const [infoInitVal, setInfoInitVal] = useState({
    group_name: "",
    sk_number: "",
    kups_name: "",
    kups_number: "",
    founding_date: new Date(),
    social_area_function_id: "",
    province_id: "",
    city_id: "",
    district_id: "",
    village_id: "",
    address: "",
    leader_name: "",
    leader_identity: "",
    leader_phone: "",
    leader_gender: "",
    secretary_name: "",
    secretary_phone: "",
    secretary_gender: "",
    treasurer_name: "",
    treasurer_phone: "",
    treasurer_gender: "",
    companion_name: "",
    companion_affiliation: "",
    companion_phone: "",
    companion_gender: "",
    end_date_management: new Date(),
    total_member_not_apply: "",
    total_member_apply: "",
    total_member_apply_persil: "",
    total_member_apply_area: "",
    past_activities: "",
    partner: "",
    general_overview: "",
    created_from: "",
  });

  const routes = useMemo(() => [
    {
      label: "Formulir Pendaftaran",
      path: "/kelompok/perhutanan-sosial/formulir-pendaftaran",
    },
    {
      label: "Pendaftaran Kelompok",
      path: "/kelompok/perhutanan-sosial/formulir-pendaftaran",
    },
    {
      label: "Formulir Permohonan Layanan",
      path: location.pathname,
    },
  ]);

  const methods = useForm({
    // resolver: isView ? undefined : yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      ...infoInitVal,
      partners: [{ name: "" }],
      activities: [{ activity_category_id: "", description: "" }],
      finance_businesses: [
        { finance_business_category_id: "", description: "" },
      ],
    },
  });

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-hidden");

    return () => {
      layouContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  const { data: activityCategory } = useQuery({
    queryKey: ["master-category-fdb"],
    queryFn: async () => {
      const res = await getActivityCategory(userId);
      const activities = res?.data?.data?.data || [];
      const formattedData = activities.map((activity) => ({
        value: activity.id,
        label: activity.name,
      }));
      if (formattedData.length <= 0) {
        formattedData.push({
          value: null,
          label: null,
        });
      }
      return formattedData;
    },
    retry: 1,
  });

  const { data: companionAffiliates } = useQuery({
    queryKey: ["master-companion-affiliates-fdb"],
    queryFn: async () => {
      const res = await getCompanionAffiliates(userId);
      const activities = res?.data?.data?.data || [];
      const formattedData = activities.map((activity) => ({
        value: activity.id,
        label: activity.name,
      }));
      if (formattedData.length <= 0) {
        formattedData.push({
          value: null,
          label: null,
        });
      }
      return formattedData;
    },
    retry: 1,
  });

  const { data: socialArea } = useQuery({
    queryKey: ["master-social-area-fdb"],
    queryFn: async () => {
      const res = await getMasterSocialArea(userId);
      const activities = res?.data?.data?.data || [];
      const formattedData = activities.map((activity) => ({
        value: activity.id,
        label: activity.name,
      }));
      if (formattedData.length <= 0) {
        formattedData.push({
          value: null,
          label: null,
        });
      }
      return formattedData;
    },
    retry: 1,
  });

  const { data: bussinessCategory } = useQuery({
    queryKey: ["master-bussiness-category-fdb"],
    queryFn: async () => {
      const res = await getMasterBussinessCategory(userId);
      const activities = res?.data?.data?.data || [];
      const formattedData = activities.map((activity) => ({
        value: activity.id,
        label: activity.name,
      }));
      if (formattedData.length <= 0) {
        formattedData.push({
          value: null,
          label: null,
        });
      }
      return formattedData;
    },
    retry: 1,
  });

  const onBack = useCallback(() => {
    navigate("/kelompok/perhutanan-sosial/formulir-pendaftaran");
  });

  const onSubmit = useCallback((payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));
    console.log(payload);

    setConfirmModal(true);
  });

  const onSaveDraft = useCallback((payload) => {
    setBodyToSend((state) => methods.getValues());

    setConfirmModal(false);
    setDraftModal(true);
  });

  // const { data: sourceData } = useQuery({
  //   queryKey: ["fdb.kelompok.regisform.source", userId],
  //   queryFn: async () => {
  //     // TODO: Process response accordingly to return data when real api exist.
  //     const res = await getRegisPerhutananSosialDraft(userId);

  //     return {};
  //   },
  //   retry: 1,
  //   refetchOnWindowFocus: false, // disable refetch on window focus as to not override form during filling out form
  // });

  const { data: sourceData, isLoading } = useQuery({
    queryKey: ["fdb-kelompok-form-regis", userId],
    queryFn: async () => {
      // TODO: Replace this code with actual API integration
      if (userId) {
        const res = await getRegisterForm(userId);
        return res?.data?.data || {};
      }
      return {};
    },
  });

  useEffect(() => {
    // TODO: Adjust initial value setup accordingly when real data exist.
    if (sourceData) {
      const activitiesArr = map(sourceData?.activities, (item) => {
        return {
          id: item?.id || "",
          activity_category_id: item?.activity_category_id || "",
          description: item?.description || "",
        };
      });
      console.log(activitiesArr);
      const partnersArr = map(sourceData?.partners, (item) => {
        return {
          id: item?.id || "",
          name: item?.name || "",
        };
      });
      const financeArr = map(sourceData?.finance_businesses, (item) => {
        return {
          id: item?.id || "",
          finance_business_category_id:
            item?.finance_business_category_id || "",
          description: item?.description || "",
        };
      });
      methods.reset({
        group_name: sourceData?.name,
        sk_number: sourceData?.sk_number,
        kups_name: sourceData?.kups_name,
        kups_number: sourceData?.kups_number,
        founding_date: sourceData?.founding_date ? new Date(sourceData?.founding_date) : new Date(),
        social_area_function_id: sourceData?.social_area_function_id,
        province_id: sourceData?.province_id,
        city_id: sourceData?.city_id,
        district_id: sourceData?.district_id,
        village_id: sourceData?.village_id,
        zip_code_id: sourceData?.zip_code_id,
        address: sourceData?.address,
        leader_name: sourceData?.leader_name,
        leader_phone: sourceData?.leader_phone,
        leader_identity: sourceData?.leader_identity,
        leader_gender: sourceData?.leader_gender,
        secretary_name: sourceData?.secretary_name,
        secretary_phone: sourceData?.secretary_phone,
        secretary_gender: sourceData?.secretary_gender,
        treasurer_name: sourceData?.treasurer_name,
        treasurer_phone: sourceData?.treasurer_phone,
        treasurer_gender: sourceData?.treasurer_gender,
        companion_name: sourceData?.companion_name,
        companion_affiliate_id: sourceData?.companion_affiliate_id,
        companion_phone: sourceData?.companion_phone,
        companion_gender: sourceData?.companion_gender,
        end_date_management: sourceData?.end_date_management ? new Date(sourceData?.end_date_management) : new Date(),
        total_member_not_apply: sourceData?.total_member_not_apply,
        total_member_apply: sourceData?.total_member_apply,
        total_member_apply_persil: sourceData?.total_member_apply_persil,
        total_member_apply_area: sourceData?.total_member_apply_area,
        activities: sourceData?.activities?.length > 0 && activitiesArr,
        partners: sourceData?.partners?.length > 0 && partnersArr,
        finance_businesses:
          sourceData?.finance_businesses?.length > 0 && financeArr,
        created_from: sourceData?.created_from,
      });
    }
  }, [sourceData]);

  const draftMutation = useMutation({
    mutationFn: (payload) => {
      return putRegisterForm(userId, {
        ...payload,
        submit_type: "draft",
        end_date_management: dayjs(payload.end_date_management).format(
          "YYYY-MM-DD"
        ),
        founding_date: dayjs(payload.founding_date).format("YYYY-MM-DD"),
      });
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      setDraftModal(false);

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  const submitMutation = useMutation({
    mutationFn: (payload) => {
      return putRegisterForm(userId, {
        ...payload,
        submit_type: "submit",
        end_date_management: dayjs(payload.end_date_management).format(
          "YYYY-MM-DD"
        ),
        founding_date: dayjs(payload.founding_date).format("YYYY-MM-DD"),
      });
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      navigate("/kelompok/perhutanan-sosial/formulir-pendaftaran");
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  const doSaveDraft = () => {
    const payload = bodyToSend;

    draftMutation.mutate(payload);
  };

  const onCreate = () => {
    const payload = bodyToSend;

    submitMutation.mutate(payload);
  };

  // setup autosave
  useInterval(() => {
    const val = methods?.getValues() || {};
    setBodyToSend((state) => ({ ...state, ...val }));

    doSaveDraft();
  }, AUTOSAVE_DURATION);

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onClose={setDraftModal}
          onSubmit={doSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onCreate}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={
            (draftModal && draftMutation.isLoading) ||
            (confirmModal && submitMutation.isLoading)
          }
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text="Formulir Permohonan Kelompok"
          description="Buat permohonan baru."
        />

        <div className="border-b" />

        <AlertBlankData />

        <FormProvider {...methods}>
          {isLoading ? (
            <div className="w-full flex justify-center">
              <Spinner />
            </div>
          ) : (
            <form
              onSubmit={methods.handleSubmit((data) => {
                onSubmit(data);
              })}
              className="space-y-8"
            >
              <AkunKelompokPSFormInfo
                mode={mode}
                activityCategory={activityCategory}
                companionAffiliates={companionAffiliates}
                socialArea={socialArea}
                bussinessCategory={bussinessCategory}
              />

              <AgreementSection mode={mode} />

              <BottomFormAction
                lastStep={true}
                backButtonProps={{
                  type: "button",
                }}
                backButtonAction={onBack}
                submitButtonProps={{
                  type: "submit",
                }}
                disableDrafButton={false}
                hideSubmit={isView}
                hideDraft={isView}
                draftButtonProps={{
                  type: "button",
                }}
                drafButtonAction={onSaveDraft}
                disableButtonSubmit={false}
              />
            </form>
          )}
        </FormProvider>
      </div>
    </>
  );
};

export default AkunKelompokPSFormMain;
