import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { getReportActivity } from "services/report/activity";

const useReportActivity = ({ 
  page,
  limit,
  name,
  status,
  submited_date 
}) => {

  const objQuery = {
    page,
    limit
  }

  if (name)
    objQuery['name'] = name
  if (status)
    objQuery['status'] = status
  if (submited_date)
    objQuery['submited_date'] = submited_date

  const {
    data: reportActivityData,
    isLoading: isLoadingReportActivityData,
    isFetching: isFetchingReportActivityData,
    refetch: refetchReportActivityData,
  } = useQueryReportActivity(objQuery);

  return {
    reportActivityData: reportActivityData?.data?.data || [],
    isLoadingReportActivityData,
    isFetchingReportActivityData,
    refetchReportActivityData,
  };
};


export const useQueryReportActivity = (params) => {
  return useQuery({
    queryKey: ["list-report-activity", params],
    queryFn: () => getReportActivity(params),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export default useReportActivity