import LembagaPenyalur from "pages/FDB/LembagaPenyalur";
import LembagaPenyalurProfileUmum from "pages/FDB/LembagaPenyalur/ProfileUmum";
import Kelembagaan from "pages/FDB/LembagaPenyalur/Kelembagaan";
import KelembagaanBank from "pages/FDB/LembagaPenyalur/KelembagaanBank";
import Keuangan from "pages/FDB/LembagaPenyalur/Keuangan";

const LembagaPenyalurProfileUmumComp = (props) => () =>
  <LembagaPenyalurProfileUmum {...props} />;
const KeuanganComp = (props) => () => <Keuangan {...props} />;
const LembagaPenyalurComp = (props) => () => <LembagaPenyalur {...props} />;
const KelembagaanComp = (props) => () => <Kelembagaan {...props} />;

const PageTypeLembaga = {
  bank_ventura: "bank-ventura",
  koperasi: "koperasi",
  non_koperasi: "non-koperasi",
  badan_hukum : "badan-hukum"
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  // ==========================
  // kelembagaan
  {
    routeType: "private",
    path: "/lembaga-penyalur/bank-ventura",
    element: LembagaPenyalurComp({ type: PageTypeLembaga.bank_ventura }),
    exact: true,
    sidebar: true,
    sidebarProps : PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/koperasi",
    element: LembagaPenyalurComp({ type: PageTypeLembaga.koperasi }),
    exact: true,
    sidebar: true,
    sidebarProps : PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/non-koperasi",
    element: LembagaPenyalurComp({ type: PageTypeLembaga.non_koperasi }),
    exact: true,
    sidebar: true,
    sidebarProps : PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/badan-hukum",
    element: LembagaPenyalurComp({ type: PageTypeLembaga.badan_hukum }),
    exact: true,
    sidebar: true,
    sidebarProps : PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/bank-ventura/profile-umum",
    element: LembagaPenyalurProfileUmumComp({
      type: PageTypeLembaga.bank_ventura,
    }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/koperasi/profile-umum",
    element: LembagaPenyalurProfileUmumComp({ type: PageTypeLembaga.koperasi }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/non-koperasi/profile-umum",
    element: LembagaPenyalurProfileUmumComp({
      type: PageTypeLembaga.non_koperasi,
    }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/badan-hukum/profile-umum",
    element: LembagaPenyalurProfileUmumComp({
      type: PageTypeLembaga.badan_hukum,
    }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/bank-ventura/kelembagaan",
    element: KelembagaanBank,
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/koperasi/kelembagaan",
    element: KelembagaanComp({ type: PageTypeLembaga.koperasi }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/non-koperasi/kelembagaan",
    element: KelembagaanComp({ type: PageTypeLembaga.non_koperasi }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/badan-hukum/kelembagaan",
    element: KelembagaanComp({ type: PageTypeLembaga.badan_hukum }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.badan_hukum,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/bank-ventura/keuangan",
    element: KeuanganComp({ type: PageTypeLembaga.bank_ventura }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.bank_ventura,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/koperasi/keuangan",
    element: KeuanganComp({ type: PageTypeLembaga.koperasi }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.koperasi,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/non-koperasi/keuangan",
    element: KeuanganComp({ type: PageTypeLembaga.non_koperasi }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.non_koperasi,
  },
  {
    routeType: "private",
    path: "/lembaga-penyalur/badan-hukum/keuangan",
    element: KeuanganComp({ type: PageTypeLembaga.badan_hukum }),
    exact: true,
    sidebar: false,
    sidebarProps : PageTypeLembaga.badan_hukum,
  },
];
