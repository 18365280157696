import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiAddLine, RiDeleteBin2Line } from "react-icons/ri";
import * as Yup from "yup";
import { Button, InputForm, InputFormUpload } from "../../../../../components";
import AtomSelect from "../../../../../components/atoms/Select";
import CardForm from "../../../../../components/molecules/CardForm";
import { getErrorMessage } from "helpers";
import SelectOtherBuisness from "components/organisms/inputs/SelectOtherBuisness";
import { useMutation } from "@tanstack/react-query";
import { getFile } from "services/danaProgram/fileService";
import FileInfoField from "components/atoms/FileInfoField";
import {
  groupProfileMemberRemoveIncomeInfo,
  postFileServices,
} from "services/fdb/akunKelompok";
import { enqueueSnackbar } from "notistack";
import InputCurrencyForm from "components/V2/shared/input-currency-form";

export const FieldLabel = {
  WORK_TYPE: "Jenis Usaha / Pekerjaan Lainnya",
  EST_INCOME: "Perkiraan Pendapatan",
  INCOME_CYCLE: "Satuan Waktu Siklus Pendapatan",
  REG_EXPENSE: "Pengeluaran Rutin Per Bulan",
  BIGGEST_OUTCAME: "Penggunaan Terbesar",
  USE_LARGEST_OUTCAME: "Penggunaan Pengeluaran Terbesar",
  INCOME_SUPPORT_DOC:
    "Dokumen Pendukung pendapatan (slip gaji/laporan keuangan usaha)",
};

export const validationSchema = Yup.object().shape({
  income: Yup.array()
    .of(
      Yup.object().shape({
        other_business_type: Yup.string()
          .required()
          .label(FieldLabel.WORK_TYPE),
        other_business_income: Yup.string()
          .required()
          .label(FieldLabel.EST_INCOME),
        other_business_cycle: Yup.string()
          .required()
          .max(2)
          .label(FieldLabel.INCOME_CYCLE),
        other_business_cycle_unit: Yup.string()
          .required()
          .label(FieldLabel.INCOME_CYCLE),
      })
    )
    .required()
    .min(1)
    .label("Pendapatan selain dari usaha/pekerjaan lainnya"),
  expense: Yup.string().trim().required().label(FieldLabel.REG_EXPENSE),
  largest_expense: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.BIGGEST_OUTCAME),
  use_largest: Yup.string().required().label(FieldLabel.USE_LARGEST_OUTCAME),
  income_file: Yup.string().notRequired().label(FieldLabel.INCOME_SUPPORT_DOC),
  // income_file: Yup.mixed()
  //   .test(
  //     "income_file",
  //     validationErrorMsg.mixed.required,
  //     (value, context) => {
  //       const allowedTypes = MAX_DOC_UPLOAD;

  //       // if has value, validate the file
  //       if (value) {
  //         // validate file type by extension
  //         const v1 = isFileTypeMatches({ ext: allowedTypes })(value, context);
  //         if (!v1) {
  //           return context.createError({
  //             message: validationCustomErrorMsg.isFiletype(
  //               allowedTypes.join(", ")
  //             )({ label: FieldLabel.INCOME_SUPPORT_DOC }),
  //           });
  //         }

  //         // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
  //         const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
  //         if (!v2) {
  //           return context.createError({
  //             message: validationCustomErrorMsg.isFileSize(
  //               humanizeFileSize(MAX_DOC_UPLOAD)
  //             )({
  //               label: FieldLabel.INCOME_SUPPORT_DOC,
  //             }),
  //           });
  //         }

  //         return true;
  //       }

  //       // otherwise, return false to render the field required
  //       return true;
  //     }
  //   )
  //   .notRequired()
  //   .label(FieldLabel.INCOME_SUPPORT_DOC),
});

function FinanceSection({ formPrefix, mode, data, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [incomeImg, setIncomeImg] = useState();
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const counter = useRef(0);

  const methods = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `${prefix}income`,
  });
  // const incomeImgField = useWatch({
  //   control: methods.control,
  //   name: `${prefix}income_file`,
  // });
  const incomeImgField = methods.watch(`${prefix}income_file`);

  const openDocs = (name) => {
    getDocsMutation.mutate({ payload: methods.getValues(name), type: "" });
  };

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "income") {
        setIncomeImg(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      // TODO: integrate submit api
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      setIncomeImg(res);
      methods.setValue(`${prefix}income_file`, res.id);
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      // setDraftModal(false);
    },
  });

  useEffect(() => {
    if (incomeImgField instanceof File) {
      postFile.mutate({ payload: incomeImgField });
    } else if (typeof incomeImgField == "string" && incomeImgField !== "") {
      getDocsMutation.mutate({ payload: incomeImgField, type: "income" });
      return;
    }
  }, [incomeImgField]);

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      // ...watchFieldArray[index],
    };
  });

  const mutateDeleteIncome = useMutation(
    ["GROUP PROFILE MEMBER DELETE"],
    (data) => {
      groupProfileMemberRemoveIncomeInfo(data);
    }
  );

  const addRow = () => {
    counter.current += 1;

    append({});

    methods.setValue(`${prefix}income_len`, controlledFields.length);
  };

  const deleteRow = (index) => {
    const income = methods.getValues(`${prefix}income.${index}`);
    if (income.id) {
      mutateDeleteIncome.mutate(income.id, {
        onSuccess: (res) => console.log(res),
      });
    }
    remove(index);
    methods.setValue(`${prefix}income_len`, controlledFields.length);
  };

  return (
    <CardForm label="Informasi Keuangan" {...rest}>
      <section className={"space-y-5"}>
        <h1 className={"text-lg font-semibold capitalize"}>
          Pendapatan selain dari usaha/pekerjaan lainnya
        </h1>

        <div className={"space-y-4"}>
          {controlledFields?.map((entry, index) => (
            <div key={entry?.id} className={"flex w-full space-x-8"}>
              <div
                className={
                  "md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0 w-full"
                }
              >
                {isView ? (
                  <div className="flex flex-col gap-2">
                    <label className="text-sm font-semibold">
                      {FieldLabel.WORK_TYPE}
                    </label>
                    <p>{data?.income_info[index]?.other_business_type?.name}</p>
                  </div>
                ) : (
                  <SelectOtherBuisness
                    controllerName={`${prefix}income.${index}.other_business_type`}
                    wrapperClass={"flex-1 min-w-[0]"}
                    label={FieldLabel.WORK_TYPE}
                    returnObject={false}
                    required
                    asText={isView}
                  />
                )}

                {/* <InputForm
                  controllerName={`${prefix}income.${index}.other_business_income`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  wrapperClass={"flex-1 min-w-[0]"}
                  label={FieldLabel.EST_INCOME}
                  placeholder={FieldLabel.EST_INCOME}
                  required
                  asText={isView}
                /> */}
                <InputCurrencyForm
                  name={`${prefix}income.${index}.other_business_income`}
                  required
                  label={FieldLabel.EST_INCOME}
                  placeholder={FieldLabel.EST_INCOME}
                  prefix="Rp "
                  asText={isView}
                />

                <div className={["col-span-2 flex gap-5 items-stretch"]}>
                  <InputForm
                    controllerName={`${prefix}income.${index}.other_business_cycle`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md`}
                    wrapperClass={"col-span-1 w-full"}
                    label={FieldLabel.INCOME_CYCLE}
                    placeholder={FieldLabel.INCOME_CYCLE}
                    required
                    asText={isView}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />

                  <AtomSelect
                    controllerName={`${prefix}income.${index}.other_business_cycle_unit`}
                    className="basic-single mt-2 flex-1"
                    wrapperClass={"col-span-1 w-full mt-5"}
                    // label={"asd"}
                    returnObject={false}
                    options={[
                      { value: "Minggu", label: "Minggu" },
                      { value: "Bulan", label: "Bulan" },
                      { value: "Tahun", label: "Tahun" },
                    ]}
                    required
                    asText={isView}
                  />
                </div>
              </div>
              {!isView && index > 0 && (
                <div className={"shrink-0 self-end"}>
                  <Button
                    type="button"
                    className={"text-[#B42318] bg-[transparent]"}
                    label={
                      <div className={"flex items-center gap-2"}>
                        <RiDeleteBin2Line className={"w-4 h-4"} />
                        Hapus
                      </div>
                    }
                    onClick={() => deleteRow(index)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className={"text-right"}>
          {!isView && (
            <Button
              type={"button"}
              className="p-4 bg-[transparent] border rounded-lg"
              label={
                <div className="flex items-center gap-2 font-[14px]">
                  <RiAddLine /> Tambah Usaha Lain
                </div>
              }
              onClick={() => addRow()}
            />
          )}
        </div>
      </section>
      <div className={"text-ereror-600"}>
        {methods.getFieldState(`${prefix}income`)?.error?.message}
      </div>

      <section className={"space-y-5"}>
        <h1 className={"text-lg font-semibold capitalize"}>
          Pengeluaran Rutin Rumah Tangga
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* <InputForm
            controllerName={`${prefix}expense`}
            className={`py-2 px-4 mt-2 border w-full rounded-md`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.REG_EXPENSE}
            required
            asText={isView}
          /> */}
          <InputCurrencyForm
            name={`${prefix}expense`}
            required
            label={FieldLabel.REG_EXPENSE}
            placeholder="Rp"
            prefix="Rp "
            asText={isView}
          />

          {/* <InputForm
            controllerName={`${prefix}largest_expense`}
            className={`py-2 px-4 mt-2 border w-full rounded-md`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.BIGGEST_OUTCAME}
            required
            asText={isView}
          /> */}
          <InputCurrencyForm
            name={`${prefix}largest_expense`}
            required
            label={FieldLabel.BIGGEST_OUTCAME}
            placeholder="Rp"
            prefix="Rp "
            asText={isView}
          />

          <InputForm
            controllerName={`${prefix}use_largest`}
            className={`py-2 px-4 mt-2 border w-full rounded-md`}
            wrapperClass={"col-span-1 gap-[1px]"}
            label={FieldLabel.USE_LARGEST_OUTCAME}
            placeholder={FieldLabel.USE_LARGEST_OUTCAME}
            required
            asText={isView}
          />

          <div className="col-span-3">
            {incomeImgField ? (
              <FileInfoField
                title={incomeImg ? incomeImg.name : ""}
                desc={incomeImg ? `${incomeImg.size} KB` : "Tidak ada file"}
                showIcon
                showDownload={true}
                showClose={!isView}
                onCloseClick={() => {
                  methods.setValue(`${prefix}income_file`, "");
                }}
                buttonLabel="Lihat Berkas"
                onDownloadClick={() => {
                  openDocs(`${prefix}income_file`);
                }}
              />
            ) : (
              <InputFormUpload
                controllerName={`${prefix}income_file`}
                label={FieldLabel.INCOME_SUPPORT_DOC}
              />
            )}
          </div>
        </div>
      </section>
    </CardForm>
  );
}

FinanceSection.propTypes = {};

export default FinanceSection;
