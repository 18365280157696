import * as yup from "yup";

export const validationScheme = (data) =>
  yup.object({
    is_negotiation_financing: yup
      .bool()
      .transform((val) => (typeof val === "boolean" ? val : undefined))
      .required()
      .label("Opsi Ini"),
    fdb_value: yup
      .number()
      .notRequired()
      .transform((val) => (isNaN(val) ? undefined : val))
      .when("is_negotiation_financing", ([val], schema) => {
        if (val) {
          return schema
            .test(
              "maxValue",
              "Jumlah FDB tidak boleh lebih dari nilai yang ditawarkan",
              (val) => {
                return typeof val !== "undefined" && val < data?.fdb_value;
              }
            )
            .required();
        }
      })
      .label("Jumlah FDB"),
    term_value: yup
      .number()
      .notRequired()
      .transform((val) => (isNaN(val) ? undefined : val))
      .when("is_negotiation_financing", ([val], schema) => {
        if (val) {
          return schema.required();
        }
      })
      .label("Jangka Waktu"),
    term_type: yup
      .string()
      .notRequired()
      .when("is_negotiation_financing", ([val], schema) => {
        if (val) {
          return schema.required();
        }
      })
      .label("Satuan Waktu"),
    with_grace_period: yup
      .bool()
      .notRequired()
      .transform((val) => (typeof val === "boolean" ? val : undefined))
      .label(""),
    grace_period_term_value: yup
      .number()
      .notRequired()
      .transform((val) => (isNaN(val) ? undefined : val))
      .when("is_negotiation_financing", ([val], schema) => {
        if (val && data?.grace_period_term_value) {
          return schema.required();
        }
      })
      .label("Jangka Waktu Grace Period"),
    grace_period_term_type: yup
      .string()
      .notRequired()
      .when("is_negotiation_financing", ([val], schema) => {
        if (val && data?.grace_period_term_type) {
          return schema.required();
        }
      })
      .label("Satuan Grace"),
    can_be_extended: yup
      .bool()
      .notRequired()
      .transform((val) => (typeof val === "boolean" ? val : val))
      .label(""),
    agreed_by_both: yup
      .bool()
      .notRequired()
      .transform((val) => (typeof val === "boolean" ? val : val))
      .when("is_negotiation_financing", ([val], schema) => {
        if (val) {
          return schema.required();
        }
      })
      .label("Opsi ini"),
    // extend_value: yup
    //   .number()
    //   .notRequired()
    //   .transform((val) => (isNaN(val) ? undefined : val))
    //   .when("is_negotiation_financing", ([val], schema) => {
    //     if (val) {
    //       return schema.required();
    //     }
    //   })
    //   .label("Waktu Perpanjangan"),
    // extend_type: yup
    //   .string()
    //   .notRequired()
    //   .when("is_negotiation_financing", ([val], schema) => {
    //     if (val) {
    //       return schema.required();
    //     }
    //   })
    //   .label("Satuan Waktu Perpanjangan"),
    // distribution: yup.array(
    //   yup.object({
    //     phase: yup
    //       .number()
    //       .notRequired()
    //       .transform((val) => (isNaN(val) ? undefined : val))
    //       .label(""),
    //     value: yup
    //       .number()
    //       .notRequired()
    //       .transform((val) => (isNaN(val) ? undefined : val))
    //       .label(""),
    //   })
    // ),
    is_negotiation: yup
      .bool()
      .transform((val) => (typeof val === "boolean" ? val : undefined))
      .required()
      .label("Opsi ini"),
    disbursement_purpose: yup
      .string()
      .notRequired()
      .when("is_negotiation", ([val], schema) => {
        if (val) {
          return schema
            .test(
              "customRequired",
              "Penggunaan/ Tujuan FDB wajib diisi",
              (val) => val !== "<p><br></p>"
            )
            .required();
        }
      })
      .label("Penggunaan/ Tujuan FDB"),
    // collateral: yup
    //   .string()
    //   .notRequired()
    //   .when("", ([val], schema) => {
    //     if (val) {
    //       return schema.required();
    //     }
    //   })
    //   .label(""),
    agreement: yup
      .string()
      .notRequired()
      .when("is_negotiation", ([val], schema) => {
        if (val) {
          return schema
            .test(
              "customRequired",
              "Perjanjian Mitra Penyaluran wajib diisi",
              (val) => val !== "<p><br></p>"
            )
            .required();
        }
      })
      .label("Perjanjian Mitra Penyaluran"),
    disbursement: yup
      .string()
      .notRequired()
      .when("is_negotiation", ([val], schema) => {
        if (val) {
          return schema
            .test(
              "customRequired",
              "Pencairan Penyaluran wajib diisi",
              (val) => val !== "<p><br></p>"
            )
            .required();
        }
      })
      .label("Pencairan"),
    installment: yup
      .string()
      .notRequired()
      .when("is_negotiation", ([val], schema) => {
        if (val) {
          return schema
            .test(
              "customRequired",
              "Pembayaran Angsuran Penyaluran wajib diisi",
              (val) => val !== "<p><br></p>"
            )
            .required();
        }
      })
      .label("Pembayaran Angsuran"),
    mandatory: yup
      .string()
      .notRequired()
      .when("is_negotiation", ([val], schema) => {
        if (val) {
          return schema
            .test(
              "customRequired",
              "Hak Dan Tanggung Jawab LP wajib diisi",
              (val) => val !== "<p><br></p>"
            )
            .required();
        }
      })
      .label("Hak Dan Tanggung Jawab LP"),
  });
