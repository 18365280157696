import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { getListNoteConcept } from "services/danaProgram/noteConcept";
import { getErrorMessage } from "helpers";
import { useCallback, useEffect, useMemo, useState } from "react";

const useNoteConcept = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [listConcept, setListConcept] = useState([]);

  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const {
    data: conceptNoteData,
    isLoading: isLoadingConceptNoteData,
    isFetching: isFetchingConceptNoteData,
    refetch: refetchConceptNoteData,
  } = useQuery(
    ["list-db-concept-note", params],
    () => getListNoteConcept(queryParams),
    {
      onError: (error) => {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      },
      onSuccess: (response) => {
        let dataResp = [];
        response?.data?.data?.data.forEach((key) => {
          let arr = {};
          arr = {
            _id: key._id,
            proposal_title: key.proposal_title,
            project_name: key.project_name,
            submission_date: key.date_of_filling,
            location: key.location?.[0]?.province.label,
            status: key.status?.toLowerCase(),
          };
          dataResp.push(arr);
        });
        setListConcept(dataResp);
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  return {
    conceptNoteData: listConcept || [],
    paginationAwsData:
      {
        lastPage: conceptNoteData?.data?.data?.lastPage,
        page: conceptNoteData?.data?.data?.page,
        perPage: conceptNoteData?.data?.data?.perPage,
        total: conceptNoteData?.data?.data?.total,
      } || {},
    isLoadingConceptNoteData,
    isFetchingConceptNoteData,
    refetch: refetchConceptNoteData,
  };
};

export default useNoteConcept;
