import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Button, HeaderNoAuth, InputForm } from 'components';
import { motion } from 'framer-motion';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AtomSelect from 'components/atoms/Select';
import { useDispatch } from 'react-redux';
import { signIn } from 'features/auth/authSlice';
import { useMutation } from '@tanstack/react-query';
import { validationCustomErrorMsg, validationErrorMsg } from 'locale/yup_id';
import {
  isMatchField,
  isPhoneValid,
  isPasswordValid,
} from 'helpers/validation';
import RadioGroup from 'components/molecules/RadioGroup';

const lembagaPenyalurOptions = [
  {
    value: 'bank',
    label: 'Bank',
    color: {
      outer: 'bg-bluelight-50',
      inner: 'bg-bluelight-100',
      text: 'text-bluelight-600',
    },
    description: 'Lorem ipsum dolor sit amet',
  },
  {
    value: 'koperasi',
    label: 'Lembaga Jasa Keuangan Non Bank - Koperasi',
    color: {
      outer: 'bg-purple-50',
      inner: 'bg-purple-100',
      text: 'text-purple-600',
    },
    description: 'Lorem ipsum dolor sit amet',
  },
  {
    value: 'non_koperasi',
    label: 'Lembaga Jasa Keuangan Non Bank - Non Koperasi',
    color: {
      outer: 'bg-green-50',
      inner: 'bg-green-100',
      text: 'text-green-600',
    },
    description: 'Lorem ipsum dolor sit amet',
  },
  {
    value: 'lainnya',
    label: 'Badan Hukum Lainnya',
    color: {
      outer: 'bg-pink-50',
      inner: 'bg-pink-100',
      text: 'text-pink-600',
    },
    description: 'Lorem ipsum dolor sit amet',
  },
];

const RegisterAccount = ({ children, sidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = useMemo(() => {
    return yup.object({
      nama_lembaga: yup
        .string()
        .trim()
        .required(`nama lemtara wajib diisi`)
        .label('Nama LEMTARA')
        .matches(
          /^[a-zA-Z\s]*$/,
          'nama hanya boleh menggunakan huruf dan spasi'
        ),
      no_akta: yup.string().required(`nomor akta wajib diisi`),
      npwpNumber: yup
        .string()
        .required(`nomor npwp wajib diisi`)
        .label('NPWP')
        .matches(/^[0-9]*$/, 'No. NPWP hanya boleh menggunakan angka'),
      country: yup
        .mixed()
        .nonNullable()
        .label('Jenis Lembaga')
        .required('negara wajib diisi'),
      province: yup
        .mixed()
        .nonNullable()
        .label('Jenis Lembaga')
        .required('provinsi wajib diisi'),
      city: yup
        .mixed()
        .nonNullable()
        .label('Jenis Lembaga')
        .required('provinsi wajib diisi'),
      kelurahan: yup
        .mixed()
        .nonNullable()
        .label('Jenis Lembaga')
        .required('kelurahan wajib diisi'),
      kecamatan: yup
        .mixed()
        .nonNullable()
        .label('Jenis Lembaga')
        .required('kecamatan wajib diisi'),
      posCode: yup.mixed().required('kode pos wajib diisi'),
      address: yup.string().required(`alamat wajib diisi`),
      narahubungName: yup
        .string()
        .required(`nama narahubung wajib diisi`)
        .matches(
          /^[a-zA-Z\s]*$/,
          'nama hanya boleh menggunakan huruf dan spasi'
        ),
      position: yup.string().required(`jabatan wajib diisi`),
      phone: yup
        .string()
        .trim()
        .test(
          'contact_person.phone',
          ({ label }) =>
            validationErrorMsg.mixed.default.replace('${path}', label),
          isPhoneValid
        )
        .required('No. Telepon wajib diisi')
        .label('Telepon'),
      email: yup
        .string()
        .trim()
        .email()
        .required()
        .label('Email Narahubung')
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          'Email Narahubung bukan email yang valid'
        ),
      password: yup
        .string()
        .test('password', validationErrorMsg.mixed.required, isPasswordValid)
        .required()
        .label('Kata Sandi'),
      confirmPassword: yup
        .string()
        .when(['password'], ([password]) => {
          if (password) {
            return yup
              .string()
              .test(
                'confpass',
                validationCustomErrorMsg.matchesField('Kata Sandi'),
                isMatchField('password')
              )
              .required()
              .label('Konfirmasi Kata Sandi');
          }

          return yup.string().required().label('Konfirmasi Kata Sandi');
        })
        .required()
        .label('Konfirmasi Kata Sandi'),
      lembaga_penyalur: yup.string().required().label('Lembaga Penyalur'),

      // lembagaFocusIssue: yup
      //   .array()
      //   .required(({ label }) => `${label} wajib diisi`)
      //   .min(1, ({ label }) => `${label} wajib diisi`)
      //   .label('Fokus isu atau Intervansi Lembaga'),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  // const {
  // formState: { errors, isValid },
  // control,
  // } = methods;

  const mutation = useMutation({
    mutationFn: (params) => {
      return console.log(params);
      // return registerAccountLemtara(params);
    },
  });

  const onSubmit = (data) => {
    let params = {
      general_information: {
        name_corporate: data?.lemtaraName ?? '',
        type_corporate: data?.jenisLembaga?.value ?? '',
        no_npwp: parseInt(data?.npwpNumber) ?? null,
        inventaris_lembaga: ['Ruko'],
        no_akta: data?.no_akta ?? null,
        nation: data?.country?.label ?? '',
        province_id: parseInt(data?.country?.value) ?? '',
        city_id: parseInt(data?.city?.value) ?? '',
        district_id: parseInt(data?.kecamatan?.value) ?? '',
        village_id: parseInt(data?.kelurahan?.value) ?? '',
        zip_code: parseInt(data?.posCode?.label) ?? '',
        address: data?.country?.value ?? '',
      },
      pic_information: {
        name: data?.narahubungName ?? '',
        position: data?.position ?? '',
        phone: data?.phone ?? null,
      },
      account: {
        email: data?.email ?? '',
        password: data?.password ?? '',
        confirmation_password: data?.confirmPassword ?? '',
      },
    };
    data && dispatch(signIn('fakeToken'));
    mutation.mutate(params);
  };

  return (
    <div className="w-full bg-gray-25">
      <div className="flex flex-col h-screen">
        <HeaderNoAuth />
        <div className="md:px-28 flex flex-row flex-1 py-20">
          <div className="flex-1 ">
            <motion.main
              className="flex-1 paragraph pl-10 mb-4"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
              <FormProvider {...methods}>
                <div className="w-full space-y-4">
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col">
                      <label className="font-semibold text-[36px]">
                        Daftar Akun Lembaga
                      </label>
                      <label className="text-[#475467]">
                        Silahkan lengkapi data-data di bawah ini untuk Anda bisa
                        mendapatkan Akun.
                      </label>
                    </div>

                    <div className="flex flex-col gap-5 py-1">
                      <div className="py-1">
                        <label className="font-semibold text-[#01A24A]">
                          General Informasi
                        </label>
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={'nama_lembaga'}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={'Nama Lembaga'}
                          placeholder={'Nama Lembaga'}
                          required={true}
                        />
                      </div>
                      <RadioGroup
                        options={lembagaPenyalurOptions}
                        label={'Pilih Lembaga Penyalur'}
                        controllerName={'lembaga_penyalur'}
                        required
                      />
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={'no_akta'}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={
                            'Nomor Akta Pendirian/SK Kemenkumham/Surat keterangan lainnya yang setara'
                          }
                          placeholder={
                            'Nomor Akta Pendirian/SK Kemenkumham/Surat keterangan lainnya yang setara'
                          }
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={'npwpNumber'}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={'Nomor NPWP'}
                          placeholder={'Nomor NPWP'}
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <AtomSelect
                          controllerName={'country'}
                          options={[{ value: '1', label: 'Indonesia' }]}
                          className="basic-single mt-2"
                          label="Negara"
                          required={true}
                        />
                        <AtomSelect
                          controllerName={'province'}
                          options={[
                            { value: '1', label: 'DKI Jakarta' },
                            { value: '2', label: 'DIY Yogyakarta' },
                            { value: '3', label: 'Jawa Barat' },
                          ]}
                          className="basic-single mt-2"
                          label="Provinsi"
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <AtomSelect
                          controllerName={'city'}
                          options={[
                            { value: '1', label: 'Bandung' },
                            { value: '2', label: 'Solo' },
                            { value: '3', label: 'Jakarta' },
                          ]}
                          className="basic-single mt-2"
                          label="Kota/Kabupaten"
                          required={true}
                        />
                        <AtomSelect
                          controllerName={'kecamatan'}
                          options={[
                            { value: '1', label: 'Bandung' },
                            { value: '2', label: 'Solo' },
                            { value: '3', label: 'Jakarta' },
                          ]}
                          className="basic-single mt-2"
                          label="Kecamatan"
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <AtomSelect
                          controllerName={'kelurahan'}
                          options={[
                            { value: '1', label: 'Bandung' },
                            { value: '2', label: 'Solo' },
                            { value: '3', label: 'Jakarta' },
                          ]}
                          className="basic-single mt-2"
                          label="Keluarahan"
                          required={true}
                        />
                        <AtomSelect
                          controllerName={'posCode'}
                          options={[
                            { value: '1', label: '1333' },
                            { value: '2', label: '1444' },
                            { value: '3', label: '1555' },
                          ]}
                          className="basic-single mt-2"
                          label="Kodepos"
                          required={true}
                        />
                      </div>
                      <div className="flex">
                        <InputForm
                          controllerName={`address`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={'Alamat'}
                          placeholder={'alamat'}
                          textArea={true}
                        />
                      </div>
                    </div>

                    <div className="border-b" />

                    <div className="flex flex-col gap-5 py-1">
                      <div className="py-1">
                        <label className="font-semibold text-[#01A24A]">
                          Informasi Narahubung
                        </label>
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={'narahubungName'}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={'Nama Narahubung'}
                          placeholder={'Nama Narahubung'}
                          required={true}
                        />
                        <InputForm
                          controllerName={'position'}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={'Jabatan'}
                          placeholder={'Jabatan'}
                          required={true}
                        />
                        <InputForm
                          controllerName={'phone'}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={'Telepon'}
                          placeholder={'Telepon'}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="border-b" />

                    <div className="flex flex-col gap-5 py-1">
                      <div className="py-1">
                        <label className="font-semibold text-[#01A24A]">
                          Informasi Akun Lembaga Penyalur
                        </label>
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={'email'}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={'Email'}
                          placeholder={'Email'}
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={'password'}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={'Kata Sandi'}
                          placeholder={'Kata Sandi'}
                          required={true}
                          type="password"
                        />
                        <InputForm
                          controllerName={'confirmPassword'}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={'Konfirmasi Kata Sandi'}
                          placeholder={'Konfirmasi Kata Sandi'}
                          required={true}
                          type="password"
                        />
                      </div>
                    </div>

                    <div className="flex justify-end gap-4 cursor-pointer">
                      <button
                        className="border rounded-lg p-4 cursor-pointer"
                        onClick={() => navigate('/')}
                      >
                        Batal
                      </button>
                      <Button
                        className="bg-[#00BD52] text-white  rounded-lg p-4"
                        label={
                          <div className="flex items-center gap-2">
                            Daftar Akun
                          </div>
                        }
                        disabled={false}
                        onClick={() => methods.handleSubmit(onSubmit)()}
                      />
                    </div>
                  </div>
                </div>
              </FormProvider>
            </motion.main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterAccount;
