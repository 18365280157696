import { useMutation } from "@tanstack/react-query";
import { Button, InputForm } from "components";
import AccordionButton from "components/atoms/AccordionButton";
import AtomSelect from "components/atoms/Select";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import FileDetail from "components/molecules/FileDetail";
import InputFormDropzone from "components/molecules/InputFormDropzone";
import { enqueueSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { fileUpload } from "services/danaProgram/fileService";
import {
  getAwpDetail,
  getDetailNotes,
  storeReportProgress,
  getReportDetail,
} from "services/report/progress";
import useReportProgressForm from "../Hooks/useReportProgressForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ReportProgressForm = () => {
  const params = useParams();
  const { id } = params || {};
  const [fileDoc, setFileDoc] = useState([]);
  const [accordionOutput, setAccordionOutput] = useState();
  const [projectOption, setProjectOption] = useState([]);
  const items = [
    {
      label: "Funding Proposal",
      path: `/report/progress`,
    },
    { label: "Laporan Kemajuan", path: `/report/progress` },
    { label: id ? "Detail" : "Update" },
  ];
  const defaultValues = {
    proposal_id: "",
    project_name: null,
    output: [],
    document: [],
    user_id: localStorage.getItem(`userId`),
  };

  const [detailReport, setDetailReport] = useState();
  const fetchDetailReport = async (id) => {
    if (id) {
      const response = await getReportDetail(id);
      const data = await response?.data?.data;

      // setDetailReport(data);
      setFileDoc(data?.document);
      setValue("project_name", { label: data?.project_name });
      setValue("proposal_id", data?.proposal_id);
      setValue(`output`, data?.output);
      setAccordionOutput(
        Array.from({ length: data?.output?.length }, () => false)
      );
    }
  };

  useEffect(() => {
    id && fetchDetailReport(id);
  }, [id]);

  const navigate = useNavigate();
  const methods = useForm({
    defaultValues,
  });
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { isValid, errors },
  } = methods;

  const {
    projectListData,
    isLoadingProjectListData,
    isFetchingProjectListData,
    refetchProjectListData,
  } = useReportProgressForm();

  useEffect(() => {
    const mapArr = [];
    for (const p of projectListData) {
      const data = {
        value: p?.program?.id,
        label: p?.program?.name,
        proposal_id: p?._id,
      };
      const mapping = mapArr?.map((e) => e?.value);
      if (!mapping.includes(p?.program?.id)) {
        mapArr.push(data);
      }
    }
    setProjectOption(mapArr);
  }, [projectListData]);

  const getOutput = useMutation({
    mutationFn: async (note_id) => {
      const detailNotes = await getDetailNotes(note_id);
      if (detailNotes?.data?.data?.awp_id) {
        const providerActivity = await getAwpDetail({
          id: detailNotes?.data?.data?.awp_id,
        });
        let data = providerActivity?.data?.data?.output;
        let dataOutput = [];
        if (data.length > 0) {
          data = data?.map((e) => {
            return {
              activity: e?.activity,
              name: e?.name,
            };
          });
          for (const p in data) {
            const child = [];
            for (const o in data[p]?.activity) {
              child.push({
                name: data[p]?.activity?.[o]?.name,
                contribution: "",
              });
            }
            dataOutput.push({
              name: data[p]?.name,
              indicator_list: child,
            });
          }
          setValue(`output`, dataOutput);
          setAccordionOutput(
            Array.from({ length: dataOutput?.length }, () => false)
          );
        }
      }
    },
  });

  useEffect(() => {
    if (watch("project_name") && !id) {
      const value = watch("project_name");
      getOutput.mutate({
        note_id: value?.value,
      });
      setValue("proposal_id", value?.proposal_id);
    }
  }, [watch("project_name")]);

  const NestedOutput = ({
    data,
    control,
    outputKey,
    accordionOutput,
    setAccordionOutput,
  }) => {
    const { fields: indicatorFields } = useFieldArray({
      control,
      name: `output.${outputKey}.indicator_list`,
    });

    return (
      <Fragment>
        <AccordionButton
          label={`Output ${data?.name}`}
          isOpen={accordionOutput[outputKey]}
          onClick={() => setAccordionOutput(outputKey)}
        />
        <div
          className={`${
            accordionOutput[outputKey]
              ? "opacity-1 h-full visible"
              : "opacity-0 h-0 invisible max-h-0"
          }`}
        >
          <div className="space-y-4">
            {indicatorFields.map((indicator, key) => (
              <div key={"indicator-" + key} className="flex w-full space-x-4">
                <div className="w-[70%]">
                  <InputForm
                    label={`Indikator`}
                    controllerName={`output.${outputKey}.indicator_list.${key}.name`}
                    className="w-full"
                    disabled={true}
                  />
                </div>
                <div className="w-[30%]">
                  <InputForm
                    controllerName={`output.${outputKey}.indicator_list.${key}.contribution`}
                    label="Update Kontribusi Capaian"
                    className="w-full"
                    subfix="%"
                    disabled={id}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fragment>
    );
  };

  const { fields: outputFields } = useFieldArray({
    control,
    name: "output",
  });

  const setAccordion = (key) => {
    setAccordionOutput((prev) => {
      const temp = [...prev];
      temp[key] = !temp[key];
      return temp;
    });
  };

  const postFile = useMutation({
    mutationKey: ["post-file"],
    mutationFn: (data) => {
      const promise = data.document.map(async (doc) => {
        const formData = new FormData();
        formData.append("file", doc);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });

      return Promise.all(promise);
    },
    onSuccess: (res) => {
      let val = methods.getValues();
      let payload = {
        ...val,
        project_name: val?.project_name?.label,
        project_id: val?.project_name?.value,
        document: res,
      };

      updateProgressReport.mutate(payload);
    },
  });

  const updateProgressReport = useMutation({
    mutationKey: ["update-progress-report"],
    mutationFn: async (data) => {
      const res = await storeReportProgress(data);
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar({
        message: "Data berhasil diupdate",
        variant: "success",
      });
      navigate(-1);
    },
  });

  const onSubmit = (data) => {
    postFile.mutate(data);
    // console.log(getValues())
  };

  return (
    <>
      <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
        {/* Header Section */}
        <div className="flex flex-col gap-4">
          <BreadCrumbs routes={items} />
          <hr />

          <FormProvider {...methods}>
            <CardForm label={`${id ? "Detail" : "Update"} Laporan Kemajuan`}>
              <AtomSelect
                controllerName={`project_name`}
                label={"Nama Project"}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                options={projectOption}
                disable={id}
              />
              <span className="font-bold">Capaian Output</span>
              {outputFields?.map((data, i) => (
                <NestedOutput
                  key={"output-list-" + i}
                  outputKey={i}
                  data={data}
                  control={control}
                  accordionOutput={accordionOutput}
                  setAccordionOutput={setAccordion}
                />
              ))}
            </CardForm>
            <CardForm label="Bukti Pendukung">
              {fileDoc.length > 0 ? (
                fileDoc.map((item, key) => (
                  <FileDetail key={"file-doc-" + key} file={item} hideDelete />
                ))
              ) : (
                <InputFormDropzone
                  name={`document`}
                  accept={{
                    "image/png": [],
                    "image/jpeg": [],
                    "application/pdf": [],
                  }}
                  maxFiles={10}
                  maxSize={10}
                  multiple={true}
                  informationText="PNG, JPG, PDF"
                />
              )}
            </CardForm>
            <div className="flex justify-end space-x-4 sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
              <Button
                onClick={() => navigate(-1)}
                label={
                  <div className="flex items-center gap-2">
                    <BiArrowBack />
                    <span>Kembali</span>
                  </div>
                }
                size="md"
                className="bg-white"
              />
              {!id && (
                <Button
                  onClick={() => methods.handleSubmit(onSubmit)()}
                  label="Submit"
                  size="md"
                  className="bg-primary-600 hover:bg-primary-600/90 text-white"
                />
              )}
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default ReportProgressForm;
