import { yupResolver } from '@hookform/resolvers/yup';
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormUpload,
} from 'components';
import CardForm from 'components/molecules/CardForm';
import RichEditorForm from 'components/molecules/RichEditorForm';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { isRTEEmpty } from '../../../../../helpers/validation';
import { validationErrorMsg } from '../../../../../locale/yup_id';
import { AiOutlineFile } from 'react-icons/ai';
import { RiAddLine } from 'react-icons/ri';
import AtomSelect from 'components/atoms/Select';
import InputNumeric from 'components/organisms/inputs/InputNumeric';
import { map } from 'lodash';
import { BiTrash } from 'react-icons/bi';

const FieldLabel = {
  FORESTRY_BUSINESS_TYPE:
    'Jenis usaha kehutanan dan/atau investasi lingkungan yang akan dibiayai (penjelasan singkat)',
  FDB_RECIPIENT_LOCATION: 'Lokasi Calon Penerima FDB',
  FDB_DISTRIBUTION_PLAN: 'Rencana penyaluran FDB',
  DISTRIBUTION_FDB_BENEFITS:
    'Proyeksi manfaat penyaluran FDB, termasuk penyerapan tenaga kerja',
  PLAN: 'Rencana jangka waktu pembiayaan yang akan disalurkan',
};

const validationSchema = yup.object({
  forestry_business_type: yup
    .string()
    .trim()
    .test(
      'forestry_business_type',
      ({ label }) =>
        validationErrorMsg.mixed.required.replace('${path}', label),
      isRTEEmpty
    )
    .required()
    .label(FieldLabel.FORESTRY_BUSINESS_TYPE),
  fdb_recipient_location: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .trim()
        .required()
        .label(FieldLabel.FDB_RECIPIENT_LOCATION),
    })
  ),
  fdb_distribution_plan: yup
    .string()
    .trim()
    .test(
      'fdb_distribution_plan',
      ({ label }) =>
        validationErrorMsg.mixed.required.replace('${path}', label),
      isRTEEmpty
    )
    .required()
    .label(FieldLabel.FDB_DISTRIBUTION_PLAN),
  distribution_fdb_benefits: yup
    .string()
    .trim()
    .test(
      'distribution_fdb_benefits',
      ({ label }) =>
        validationErrorMsg.mixed.required.replace('${path}', label),
      isRTEEmpty
    )
    .required()
    .label(FieldLabel.DISTRIBUTION_FDB_BENEFITS),
  plan_length: yup.string().trim().required().label(FieldLabel.PLAN),
  plan_type: yup.string().trim().required().label(FieldLabel.PLAN),
});

const UsagePlan = ({ onBack, onSubmit, onDraft }) => {
  const options = useMemo(
    () => [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
    ],
    []
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      fdb_recipient_location: [{ value: '' }],
    },
  });

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'fdb_recipient_location',
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const onFormSubmit = (type) => {
    return methods.handleSubmit((data) => {
      if (type === 'draft') {
        onDraft(data);
      } else {
        onSubmit(data);
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <CardForm label="Rencana Penggunaan">
        <div className="space-y-6">
          <RichEditorForm
            name={`forestry_business_type`}
            label={FieldLabel.FORESTRY_BUSINESS_TYPE}
            required
          />
          <div className="space-y-4">
            {map(fields, (field, key) => (
              <div key={field.id} className="flex items-center space-x-4">
                <InputForm
                  controllerName={`fdb_recipient_location.${key}.value`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={FieldLabel.FDB_RECIPIENT_LOCATION}
                  required={true}
                />
                {key > 0 && (
                  <div className="space-y-2">
                    <label>&nbsp;</label>
                    <Button
                      type="button"
                      onClick={() => remove(key)}
                      className="p-3 rounded-lg bg-error-600 flex-none"
                      label={<BiTrash className="text-white" />}
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="border-t" />
            <div className="flex flex-row-reverse my-5 mx-4">
              <Button
                className="p-4 border rounded-lg bg-white"
                onClick={() => append({ value: '' })}
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine />
                    Lokasi Calon Penerima FDB
                  </div>
                }
              />
            </div>
          </div>
          <RichEditorForm
            name={`distribution_fdb_benefits`}
            label="Proyeksi manfaat penyaluran FDB, termasuk penyerapan tenaga kerja"
            required
          />
          <div
            className={[
              'col-span-2 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0',
            ]}
          >
            <InputNumeric
              controllerName={`plan_length`}
              wrapperClass={'col-span-1'}
              label={FieldLabel.PLAN}
              required
            />

            <AtomSelect
              controllerName={`plan_type`}
              className="basic-single mt-2 flex-1"
              wrapperClass={'col-span-1'}
              returnObject={false}
              label={<span>&nbsp;</span>}
              placeholder="Pilih Tahun"
              options={[{ value: 'bulan', label: 'Bulan' }]}
            />
          </div>
          <RichEditorForm
            name={`fdb_distribution_plan`}
            label={FieldLabel.FDB_DISTRIBUTION_PLAN}
            required
          />
        </div>
      </CardForm>
      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onFormSubmit()}
        disableDrafButton={false}
        drafButtonAction={onFormSubmit('draft')}
        disableButtonSubmit={false}
      />
    </FormProvider>
  );
};

export default UsagePlan;
