import { BottomFormAction, InputFormUpload } from 'components';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import CardForm from 'components/molecules/CardForm';
import { FormInput } from './components';
import AtomDatePicker from 'components/atoms/Datepicker';

const FORM_LABEL = {
  SKB: 'Surat Keterangan Bebas Pajak (SKB) Jika Ada',
  REGISTER_DATE: 'Tanggal Registrasi',
  SKB_DOCUMENT: 'Surat Keterangan Bebas Pajak (SKB)',
  SKT_DOCUMENT: 'Surat Keterangan Terdaftar (SKT)',
  OTHER_DOCUMENT: 'Dokumen Lainnya',
};

const Pajak = ({ draftData, onSubmit, onBack, onDraft }) => {
  const validationSchema = Yup.object().shape({
    skb: Yup.string().optional().label(FORM_LABEL.SKB),
    skb_register_date: Yup.string().required().label(FORM_LABEL.REGISTER_DATE),
    skb_document: Yup.mixed().optional().label(FORM_LABEL.SKB_DOCUMENT),
    skt_document: Yup.mixed().optional().label(FORM_LABEL.SKT_DOCUMENT),
    other_document: Yup.mixed().optional().label(FORM_LABEL.OTHER_DOCUMENT),
  });

  const methods = useForm(
    {
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
      defaultValues: {},
    },
    [validationSchema]
  );

  const { getValues } = methods || {};
  const values = getValues();

  const onFormSubmit = () => {
    return methods.handleSubmit(
      (data) => {
        onSubmit(data);
      },
      (errors, e) => console.log(errors, e)
    );
  };

  return (
    <div>
      <div className="">
        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit()} className="space-y-8">
            <CardForm label="Pajak">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormInput controllerName={`skb`} label={FORM_LABEL.SKB} />
                <AtomDatePicker
                  controllerName={'skb_register_date'}
                  label={FORM_LABEL.REGISTER_DATE}
                  required={true}
                  datepickerOptions={{
                    dateFormat: 'dd-MM-yyyy',
                  }}
                  showErrorLabel
                />

                <InputFormUpload
                  controllerName={`skb_document`}
                  label={FORM_LABEL.SKB_DOCUMENT}
                />
                <InputFormUpload
                  controllerName={`skt_document`}
                  label={FORM_LABEL.SKT_DOCUMENT}
                />
                <InputFormUpload
                  controllerName={`other_document`}
                  label={FORM_LABEL.OTHER_DOCUMENT}
                />
              </div>
            </CardForm>
          </form>
        </FormProvider>
      </div>

      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onFormSubmit()}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        disableButtonSubmit={false}
      />
    </div>
  );
};

export default Pajak;
