import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getListOffer = async ({ queryKey }) => {
  return await axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/offers`, {
    params: queryKey[1],
  });
};

export const getDetailOffer = async ({ queryKey }) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/offers/${queryKey[1]}`
  );
};

export const submitNegotiatonOffer = async (id, { payload, params }) => {
  return await axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/offers/${id}`,
    payload,
    { params: params }
  );
};
