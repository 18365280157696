import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getDistrictDDL } from "../../../../services/master";
import AtomSelect from "../../../atoms/Select";

const SelectDistrict = React.forwardRef((props, ref) => {
  const { className, cityId, ...restProps } = props;
  
  const [options, setOptions] = useState([]);
  
  const { data } = useQuery({
    queryKey: ["district-ddl", cityId],
    queryFn: async () => {
      // TODO: Replace this with actual API call
      const res = await getDistrictDDL(cityId);
      
      return res?.data?.data || [];
    },
    retry: 1
  });
  
  useEffect(() => {
    setOptions(data?.map((entry) => {
      return {
        value: entry?.id,
        label: entry?.name
      };
    }));
  }, [data]);
  
  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      {...restProps}
    />
  );
});

export default SelectDistrict;