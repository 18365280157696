import React, { useMemo, useEffect } from 'react';
import { BottomFormAction, Button, InputForm } from 'components';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import AtomSelect from 'components/atoms/Select';
import CardForm from 'components/molecules/CardForm';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiAddLine, RiDeleteBin6Line } from 'react-icons/ri';
import { validationErrorMsg } from 'locale/yup_id';
import { isCitizenIdValid } from 'helpers/validation';

const InformasiRekeningDanDaftarKepemilikan = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const validationSchema = useMemo(() => {
    return yup.object({
      account_name: yup
        .string()
        .max(20, 'Nama rekening hanya boleh diisi dengan maksimum 20 karakter')
        .required(`nama rekening wajib diisi`),
      account_number: yup
        .string()
        .matches(/^\d+$/, 'Nomor rekening harus diisi dengan angka')
        .required(`nomor rekening wajib diisi`),
      bank_name: yup
        .string()
        .max(20, 'Nama bank hanya boleh diisi dengan maksimum 20 karakter')
        .required(`nama bank wajib diisi`),
      branch_name: yup
        .string()
        .max(20, 'kantor cabang hanya boleh diisi dengan maksimum 20 karakter')
        .required(`kantor cabang wajib diisi`),
      account_type: yup.object({
        value: yup.string().required('jenis rekening wajib diisi'),
      }),
      list: yup.array().of(
        yup.object().shape({
          name: yup.string().required(`nama wajib diisi`),
          identity_number: yup
            .string()
            .trim()
            .test(
              'no_ktp',
              ({ label }) =>
                validationErrorMsg.mixed.default.replace('${path}', label),
              isCitizenIdValid
            )
            .required()
            .label('Nomor KTP'),
          nationality: yup.object({
            value: yup.string().required('kewarganegaraan wajib diisi'),
          }),
        })
      ),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { setValue } = methods;

  useEffect(() => {
    if (draftData && Object.keys(draftData).length > 0) {
      Object.keys(draftData).forEach((key) => {
        if (key === 'account') {
          Object.keys(draftData[key]).forEach((obj) => {
            setValue(obj, draftData[key][obj]);
          });
        } else {
          if (key === 'stock_ownership') {
            if (draftData[key].list.length > 0) {
              setValue('list', draftData[key].list);
            } else {
              if (shareholdingFields.length === 0) {
                appendShareholdings({
                  name: '',
                  identity_number: '',
                  nationality: '',
                  percentage: '',
                });
              }
            }
          }
        }
      });
    } else {
      if (shareholdingFields.length === 0) {
        appendShareholdings({
          name: '',
          identity_number: '',
          nationality: '',
          percentage: '',
        });
      }
    }
  }, [draftData, setValue, methods]);

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const {
    fields: shareholdingFields,
    append: appendShareholdings,
    remove: removeShareholdings,
  } = useFieldArray({
    control,
    name: 'list',
  });

  return (
    <>
      <FormProvider {...methods}>
        <CardForm label={'Informasi Rekening'}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={'account_name'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                  }`}
                  label={'Nama Rekening'}
                  placeholder={'Nama Rekening'}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={'account_number'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                  }`}
                  label={'Nomor Rekening'}
                  placeholder={'xxxx xxxx xxxx'}
                  required={true}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={'bank_name'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                  }`}
                  label={'Nama Bank'}
                  placeholder={'Nama Bank'}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={'branch_name'}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors.nama_lemtara ? 'border-[#F04438]' : 'border-green'
                  }`}
                  label={'Kantor Cabang'}
                  placeholder={'Kantor Cabang'}
                  required={true}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <AtomSelect
                  controllerName={'account_type'}
                  options={[
                    { value: 'Diamond', label: 'Diamond' },
                    { value: 'Gold', label: 'Gold' },
                    { value: 'Silver', label: 'Silver' },
                  ]}
                  className="basic-single mt-2"
                  label="Jenis Rekening"
                  required={true}
                />
              </div>
              <div className="flex-1"></div>
            </div>
          </>
        </CardForm>
        <CardForm
          label={
            'Daftar Kepemilikan saham (untuk pt) / anggota pengurus (untuk yayasan/perkumpulan)'
          }
        >
          <>
            {shareholdingFields.map((data, index) => (
              <div className="flex gap-4 items-end border-t pt-2" key={data.id}>
                <div className="w-full">
                  <div className="flex gap-4">
                    <div className="flex-1 pt-2">
                      <InputForm
                        controllerName={`list.${index}.name`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md ${
                          errors.nama_lemtara
                            ? 'border-[#F04438]'
                            : 'border-green'
                        }`}
                        label={'Nama'}
                        placeholder={'Nama'}
                        required={true}
                      />
                    </div>
                    <div className="flex-1 pt-2">
                      <InputForm
                        controllerName={`list.${index}.identity_number`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md ${
                          errors.nama_lemtara
                            ? 'border-[#F04438]'
                            : 'border-green'
                        }`}
                        label={'Nomor KTP'}
                        placeholder={'xxxx xxxx xxxx'}
                        required={true}
                      />
                    </div>
                    <div className="flex-1 pt-2">
                      <AtomSelect
                        controllerName={`list.${index}.nationality`}
                        options={[
                          { value: 'Dalam Negeri', label: 'dalam negeri' },
                          { value: 'Luar Negeri', label: 'luar negeri' },
                        ]}
                        className="basic-single mt-2"
                        label="Kewarganegaraan"
                        required={true}
                      />
                    </div>
                    <div className="flex-1 pt-2">
                      <InputForm
                        controllerName={`list.${index}.percentage`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md ${
                          errors?.percentage
                            ? 'border-[#F04438]'
                            : 'border-green'
                        }`}
                        label="Persentase (Opsional)"
                        placeholder={'Persentase (Opsional)'}
                        subfix="%"
                      />
                    </div>
                  </div>
                </div>
                {index !== 0 && (
                  <button
                    className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                    onClick={() => removeShareholdings(index)}
                  >
                    <RiDeleteBin6Line />
                  </button>
                )}
              </div>
            ))}
            <div className="border-b" />
            <div className="flex flex-row-reverse my-5 mx-4 ">
              <Button
                className="p-4 border rounded-lg"
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine />
                    Tambah Data Lain
                  </div>
                }
                onClick={() => {
                  appendShareholdings({
                    name: '',
                    identity_number: '',
                    nationality: '',
                    percentage: '',
                  });
                }}
              />
            </div>
          </>
        </CardForm>

        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            console.log(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default InformasiRekeningDanDaftarKepemilikan;
