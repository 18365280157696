import { Button, ConfirmationModal, Spinner } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { FaRegPaperPlane } from "react-icons/fa";
import { RiAddLine, RiPencilLine, RiDownloadLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { LuClipboardX } from "react-icons/lu";

import { useProposal } from "./hooks";
import TablePagination from "components/atoms/Pagination";
import Select from "./components/Select";
import SearchInput from "./components/SearchInput";
import CustomDatepicker from "./components/Datepicker";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import AtomRangeDatepicker from "components/atoms/RangeDatepicker";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { postSubmitReport } from "services/danaProgram/callForProposalService";
import { enqueueSnackbar } from "notistack";

const rowPerPageOptions = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

const STATUS_ARRAY = [
  "Konsep Note Disetujui",
  "DRAFT",
  "Kegiatan Selesai",
  "Perlu Set Komitmen",
  "Sedang Verifikasi",
  "Selesai",
  "Ditutup",
  "Ditolak",
];

const StatusBadge = ({ status }) => {
  let defaultDotClass = "bg-gray-500";
  let defaultWrapperClass = "bg-gray-50 text-gray-700";

  switch (status) {
    case "Konsep Note Disetujui":
      defaultDotClass = "bg-bluelight-500";
      defaultWrapperClass = "bg-bluelight-50 text-bluelight-700";
      break;
    case "Kegiatan Selesai":
      defaultDotClass = "bg-purple-500";
      defaultWrapperClass = "bg-purple-50 text-purple-700";
      break;
    case "Perlu Set Komitmen":
      defaultDotClass = "bg-pink-500";
      defaultWrapperClass = "bg-pink-50 text-pink-700";
      break;
    case "Menunggu Verifikasi":
      defaultDotClass = "bg-warning-500";
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
    case "Kontrak Deal":
      defaultDotClass = "bg-primary-700";
      defaultWrapperClass = "bg-primary-50 text-primary-700";
      break;
    case "Proyek Berakhir":
      defaultDotClass = "bg-purple-700";
      defaultWrapperClass = "bg-purple-50 text-purple-700";
      break;
    case "Selesai":
      defaultDotClass = "bg-primary-500";
      defaultWrapperClass = "bg-primary-50 text-primary-700";
      break;
    case "Ditutup":
    case "Ditolak":
      defaultDotClass = "bg-red-500";
      defaultWrapperClass = "bg-red-50 text-red-700";
      break;

    default:
      defaultDotClass = "bg-gray-500";
      defaultWrapperClass = "bg-gray-50 text-gray-700";
      break;
  }
  return (
    <div
      className={`rounded-2xl flex items-center border-transparent p-2 text-xs ${defaultWrapperClass}`}
    >
      <div
        className={`rounded-[50%] w-2 h-2 inline-block mr-1 ${defaultDotClass}`}
      />
      <div className="flex-1 text-center whitespace-nowrap">{status}</div>
    </div>
  );
};

const CallForProposal = () => {
  const navigate = useNavigate();
  const wrapperRef = useRef();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [dataSource, setDataSource] = useState({});
  const [statusFilter, setStatusFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowPerPage, setRowPerPage] = useState({ value: 10, label: "10" });
  const [modal, setModal] = useState({
    title: "",
    description: "",
    type: "",
    open: false,
    data: null,
    cancelLabel: "",
    confirmLabel: "",
  });
  const params = {
    page: page,
    limit: limit,
    name: keyword,
    status: statusFilter?.value,
    start_date: startDate && moment(startDate).format("YYYY-MM-DD"),
    end_date: endDate && moment(endDate).format("YYYY-MM-DD"),
  };

  const {
    refetch,
    proposalData,
    isLoadingProposalData,
    paginationProposalData,
  } = useProposal({ params });

  useEffect(() => {
    refetch();
  }, [page, limit, keyword, statusFilter, startDate, endDate]);

  useEffect(() => {
    refetch();
  }, []);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const changePage = (val) => {
    setPage(val);
    wrapperRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  const changeRowsPerPage = (page) => {
    setRowPerPage(page);
    setPage(1);
    setLimit(page.value);
    wrapperRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  const onSubmitReport = useMutation({
    mutationKey: ["submit-report-call-for-proposal"],
    mutationFn: async () => {
      const res = await postSubmitReport({ id: modal?.data });
      return res;
    },
    onSuccess: () => {
      setModal((curr) => ({
        ...curr,
        type: "",
        title: "",
        open: false,
        data: null,
        cancelLabel: "",
        confirmLabel: "",
      }));
      enqueueSnackbar("Laporan berhasil disubmit", { variant: "success" });
      refetch();
    },
  });

  const renderButton = (status, id) => {
    let element = (
      <div>
        <Button
          type="button"
          size="sm"
          className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
          onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
          label={
            <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
              <AiOutlineEye size={20} />
              <span>Lihat Detail</span>
            </div>
          }
        />
      </div>
    );
    switch (status) {
      case "Konsep Note Disetujui":
        element = (
          <>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 p-2 border border-primary-700 bg-primary-50 rounded-lg h-full"
                label={
                  <div className="flex items-center gap-2 text-primary-700 text-xs font-semibold">
                    <RiPencilLine size={20} />
                    <span>Ajukan Full Proposal</span>
                  </div>
                }
              />
            </div>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
                onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
                label={
                  <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                    <AiOutlineEye size={20} />
                    <span>Lihat Detail</span>
                  </div>
                }
              />
            </div>
          </>
        );
        break;
      case "Kegiatan Selesai":
        element = (
          <>
            <Button
              type="button"
              size="sm"
              className="m-2 p-2 border border-primary-700 bg-primary-50 rounded-lg h-full"
              label={
                <div className="flex items-center gap-2 text-primary-700 text-xs font-semibold">
                  <RiPencilLine size={20} />
                  <span>Input Laporan Akhir</span>
                </div>
              }
            />
            <Button
              type="button"
              size="sm"
              className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
              onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
              label={
                <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                  <AiOutlineEye size={20} />
                  <span>Lihat Detail</span>
                </div>
              }
            />
          </>
        );
        break;
      case "Perlu Set Komitmen":
        element = (
          <>
            <Button
              type="button"
              size="sm"
              className="m-2 p-2 border border-primary-700 bg-primary-50 rounded-lg h-full"
              label={
                <div className="flex items-center gap-2 text-primary-700 text-xs font-semibold">
                  <RiPencilLine size={20} />
                  <span>Set Komitmen Safeguard</span>
                </div>
              }
            />
            <Button
              type="button"
              size="sm"
              className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
              onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
              label={
                <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                  <AiOutlineEye size={20} />
                  <span>Lihat Detail</span>
                </div>
              }
            />
          </>
        );
        break;
      case "DRAFT":
        element = (
          <>
            <Button
              type="button"
              size="sm"
              className="m-2 p-2 border border-primary-700 bg-primary-50 rounded-lg h-full"
              onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
              label={
                <div className="flex items-center gap-2 text-primary-700 text-xs font-semibold">
                  <RiPencilLine size={20} />
                  <span>Lanjutkan Draft</span>
                </div>
              }
            />
          </>
        );
        break;
      case "Menunggu Verifikasi":
        element = (
          <>
            <Button
              type="button"
              size="sm"
              onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
              className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
              label={
                <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                  <AiOutlineEye size={20} />
                  <span>Lihat Detail</span>
                </div>
              }
            />
          </>
        );
        break;
      case "Selesai":
        element = (
          <>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 p-2 border border-primary-700 bg-primary-50 rounded-lg h-full"
                label={
                  <div className="flex items-center gap-2 text-primary-700 text-xs font-semibold">
                    <RiPencilLine size={20} />
                    <span>Input Laporan Kegiatan</span>
                  </div>
                }
              />
            </div>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
                label={
                  <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                    <RiDownloadLine size={20} />
                    <span>Download Laporan</span>
                  </div>
                }
              />
            </div>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 p-2 border border-secondary-orange-700 bg-secondary-orange-50 rounded-lg h-full"
                label={
                  <div className="flex items-center gap-2 text-secondary-orange-700 text-xs font-semibold">
                    <FaRegPaperPlane size={20} />
                    <span>Submit Pelaporan</span>
                  </div>
                }
              />
            </div>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
                onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
                label={
                  <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                    <AiOutlineEye size={20} />
                    <span>Lihat Detail</span>
                  </div>
                }
              />
            </div>
          </>
        );
        break;
      case "Ditutup":
      case "Ditolak":
        element = (
          <Button
            type="button"
            size="sm"
            className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
            onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
            label={
              <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                <AiOutlineEye size={20} />
                <span>Lihat Detail</span>
              </div>
            }
          />
        );
        break;
      case "Kontrak Deal":
        element = (
          <>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 p-2 border border-primary-700 bg-primary-50 rounded-lg h-full"
                onClick={() =>
                  navigate(`/proposal/call-for-proposal/rencana-kemajuan/${id}`)
                }
                label={
                  <div className="flex items-center gap-2 text-primary-700 text-xs font-semibold">
                    <RiPencilLine size={20} />
                    <span>Input Laporan Kemajuan</span>
                  </div>
                }
              />
            </div>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 p-2 border border-primary-700 bg-primary-50 rounded-lg h-full"
                onClick={() =>
                  navigate(`/proposal/call-for-proposal/rencana-kegiatan/${id}`)
                }
                label={
                  <div className="flex items-center gap-2 text-primary-700 text-xs font-semibold">
                    <RiPencilLine size={20} />
                    <span>Input Laporan Kegiatan</span>
                  </div>
                }
              />
            </div>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 p-2 border border-secondary-orange-700 bg-secondary-orange-50 rounded-lg h-full"
                onClick={() =>
                  setModal((curr) => ({
                    ...curr,
                    open: true,
                    type: "confirm",
                    title:
                      "Apakah anda yakin ingin mengirim Laporan Kemajuan dan Laporan Kegiatan Ini?",
                    cancelLabel: "Batal",
                    confirmLabel: "Submit",
                    data: id,
                  }))
                }
                label={
                  <div className="flex items-center gap-2 text-secondary-orange-700 text-xs font-semibold">
                    <FaRegPaperPlane size={20} />
                    <span>Submit Pelaporan</span>
                  </div>
                }
              />
            </div>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
                onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
                label={
                  <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                    <AiOutlineEye size={20} />
                    <span>Lihat Detail</span>
                  </div>
                }
              />
            </div>
            <div>
              <Button
                type="button"
                size="sm"
                className="m-2 py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
                onClick={() => console.log("first")}
                label={
                  <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                    <AiOutlineEye size={20} />
                    <span>Detail Kontrak</span>
                  </div>
                }
              />
            </div>
          </>
        );
        break;

      default:
        element = (
          <Button
            type="button"
            size="sm"
            className="py-2 px-3.5 border border-gray-300 bg-white rounded-lg h-full"
            onClick={() => navigate(`/proposal/call-for-proposal/${id}`)}
            label={
              <div className="flex items-center gap-2 text-gray-700 text-xs font-semibold">
                <AiOutlineEye size={20} />
                <span>Lihat Detail</span>
              </div>
            }
          />
        );
        break;
    }
    return element;
  };
  return (
    <Fragment>
      <ConfirmationModal
        useGraphic
        open={modal.open && modal.type === "confirm"}
        onCancel={() => {
          setModal((curr) => ({
            ...curr,
            type: "",
            title: "",
            description: "",
            open: false,
            data: null,
          }));
        }}
        cancelLabel={modal.cancelLabel}
        confirmLabel={modal.confirmLabel}
        onSubmit={() => onSubmitReport.mutate()}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {modal.title}
          </div>
          <div className="text-sm text-gray-600">{modal.description}</div>
        </div>
      </ConfirmationModal>
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Pengajuan Dana",
                  path: `/`,
                },
                {
                  label: "Call For Proposal",
                  path: `/`,
                },
              ]}
            />
            <div className="mt-6 flex flex-row">
              <div className="flex-1">
                <div className="flex flex-col">
                  <span
                    className="font-semibold text-[24px] text-gray-900"
                    ref={wrapperRef}
                  >
                    Daftar Proposal
                  </span>
                </div>
              </div>
              <div className="flex-1 text-right">
                <Button
                  type="button"
                  className="rounded-lg bg-secondary-orange-600 border border-secondary-orange-600 text-white px-4 py-2.5"
                  label={
                    <div className="flex items-center gap-2">
                      <RiAddLine />
                      <span className="font-semibold text-sm">
                        Tambah Proposal
                      </span>
                    </div>
                  }
                  onClick={() => navigate("/proposal/call-for-proposal/new")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4 p-3 rounded-xl bg-white border border-gray-200">
          <div className="flex gap-5">
            <div className="w-1/3">
              <SearchInput onChange={onSearch} />
            </div>
            <div className="w-1/3">
              <CustomDatepicker
                placeholder="Tanggal Pengajuan"
                // value={dateFilter}
                // onChange={(e) => console.log(e)}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                onClear={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
                selectsRange
              />
            </div>
            <div className="w-1/3">
              <Select
                placeholder="Status"
                value={statusFilter}
                optionsData={STATUS_ARRAY.map((x) => ({
                  value: x,
                  label: x,
                }))}
                onChange={setStatusFilter}
                isClearable={true}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          {isLoadingProposalData ? (
            <div className="min-h-[500px] flex items-center justify-center">
              <Spinner theme="primary" />
            </div>
          ) : proposalData.length > 0 ? (
            <div className="space-y-4">
              {proposalData.map((x, key) => (
                <div
                  key={key}
                  className="p-5 rounded-xl border border-gray-200 space-y-3"
                >
                  <div className="block overflow-x-scroll w-full">
                    <div className="flex content-start items-center justify-between gap-5">
                      <div className="text-gray-800 font-semibold text-sm line-clamp-3 w-2/4">
                        {x?.proposal_title}
                      </div>
                      <div className="text-gray-800 text-sm flex-1 min-w-max">
                        Tanggal Pengajuan:{" "}
                        <span className="font-semibold">
                          {x.date_submit
                            ? dayjs(x.date_submit).format("DD/MM/YYYY")
                            : "-"}
                        </span>
                      </div>
                      <div className="text-gray-800 text-sm line-clamp-3 w-2/4">
                        Program:{" "}
                        <span className="font-semibold">{x.program?.name}</span>
                      </div>
                      <div className="w-max">
                        <StatusBadge status={x.status} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="flex flex-row justify-end gap-3">
                    {renderButton(x.status, x._id)}
                  </div>
                </div>
              ))}
              <hr />
              <div className="flex items-center justify-end px-4 pb-4 pt-3">
                <div className="flex space-x-3 items-center">
                  <p className="mb-0 text-gray-600 text-sm">Show Data</p>
                  <Select
                    value={rowPerPage}
                    optionsData={rowPerPageOptions}
                    onChange={changeRowsPerPage}
                    menuPortalTarget={document.body}
                  />
                  <p className="mb-0 text-gray-600 text-sm">{`${(page - 1) * rowPerPage.value || 1
                    } - ${page <= 1 &&
                      paginationProposalData?.total <= rowPerPage.value
                      ? paginationProposalData?.total
                      : page * rowPerPage.value
                    } data from ${paginationProposalData?.total} data.`}</p>
                </div>
                <div className="flex flex-1 justify-end">
                  <TablePagination
                    page={page}
                    totalPage={paginationProposalData?.lastPage}
                    onChange={changePage}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default CallForProposal;
