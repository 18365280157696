import axios from "axios";
import config from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  accept: "application/json",
  "Content-Type": "multipart/form-data",
};

const secondHeaders = {
  accept: "application/json",
  "Content-Type": "application/json",
};

export const fileUpload = (params) => {
  return axiosInstance.post(
    `${process.env.REACT_APP_BASE_URL}/api/file-service/v1/files`,
    params,
    {
      headers,
    }
  );
};
export const getUrlFile = (params) => {
  return axiosInstance.post(
    `${process.env.REACT_APP_BASE_URL}/api/file-service/v1/files/generate-url`,
    params,
    { secondHeaders }
  );
};
export const getFile = (params) => {
  if (params !== undefined) {
    return axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/api/file-service/v1/files/` + params,
      params,
      { secondHeaders }
    );
  }
};
