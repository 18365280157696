import { BottomFormAction, ConfirmationModal, HeaderLabel } from "components";
import AlertBlankData from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { useCallback, useEffect, useMemo, useState } from "react";
import GeneralInformationForm from "./generalInformationForm";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAgencyProfile,
  patchAgencyProfile,
} from "services/fdb/lembagaPenyalur";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { enqueueSnackbar } from "notistack";
import {
  bankScheme,
  bumdesScheme,
  cvScheme,
  firmaScheme,
  koperasiScheme,
  lainyaScheme,
  nonKoperasiScheme,
  ptScheme,
} from "./validation_scheme";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import Alert from "components/atoms/Alert";

const GeneralProfile = (props) => {
  const { type, group } = props;
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);
  const navigate = useNavigate();

  const routes = useMemo(
    () => [
      {
        label: "Formulir Pendaftaran",
        path: `/formulir-pendaftaran/${group}/${type}`,
      },
      {
        label: `Registrasi Prospecting ${group === "non-perorangan" ? "Non Perorangan" : "Lembaga Penyalur"
          }`,
        path: `/formulir-pendaftaran/${group}/${type}/profile-umum`,
      },
    ],
    [group, type]
  );

  const renderScheme = (type) => {
    switch (type) {
      case "bank-ventura":
        return bankScheme;
      case "koperasi":
        return koperasiScheme;
      case "non-koperasi":
        return nonKoperasiScheme;
      case "badan-hukum":
        return lainyaScheme;
      case "bumdes":
        return bumdesScheme;
      case "cv":
        return cvScheme;
      case "pt":
        return ptScheme;
      case "firma":
        return firmaScheme;

      default:
    }
  };

  const mutateAgencyProfile = useMutation(
    ["PATCH_AGENCY_PROFILE"],
    ({ data, query }) => {
      return patchAgencyProfile(data, query);
    }
  );
  const { data } = useQuery({
    queryKey: ["agency-profile"],
    queryFn: getAgencyProfile,
    select: (data) => data?.data?.data,
    refetchOnMount: true,
  });
  const queryClient = useQueryClient();

  const methods = useForm({
    resolver: yupResolver(renderScheme(type)),
    mode: "onChange",
  });

  const default_legality_docs = useCallback(
    (data = []) => {
      const mapData = new Map(data?.map((item) => [item.description, item]));

      const docsMap = {
        bumdes: [
          "Berita Acara Musyawarah desa",
          "Peraturan Desa",
          "Anggaran Dasar",
          "Anggaran Rumah Tangga",
          "Program Kerja",
          "sertifikat pendaftaran badan hukum BUM Desa Dari Kemenkumham",
          "Dokumen NIB/Izin Usaha",
        ],
        cv: [
          "Dokumen Akta Pendirian, Akta Perubahan Beserta Pengesahannya",
          "Dokumen NIB/TDP/Izin Usaha/SKDP Yang Berlaku",
          "Dokumen Bukti pendaftaran ke pengadilan negeri",
          "Dokumen Standar Operasional (SOP)",
        ],
        default: [
          "Dokumen Akta Pendirian, Akta Perubahan Beserta Pengesahannya",
          "Dokumen NIB/TDP/Izin Usaha/SKDP Yang Berlaku",
          "Dokumen Standar Operasional (SOP)",
        ],
      };

      return (docsMap[type] || docsMap.default).map((description) => ({
        id: mapData.get(description)?.id,
        description,
        file: mapData.get(description)?.file,
        name: mapData.get(description)?.name,
      }));
    },
    [type]
  );

  const handleMountingData = useCallback(() => {
    if (!data) return;

    console.log(data.agency_profile);

    const mapData = {
      agency: {
        ...data,
        // distributor_institution: data?.distributor_institution_string,
      },
      profile: {
        ...data.agency_profile,
        institution_legality_date: data?.agency_profile
          ?.institution_legality_date
          ? new Date(data?.agency_profile?.institution_legality_date)
          : null,
        institution_npwp:
          data?.npwp ?? data?.agency_profile?.institution_npwp_file,
        institution_legality_certificate:
          data?.certificate_number ??
          data?.agency_profile?.institution_legality_certificate,
        institution_nib_date: data?.agency_profile?.institution_nib_date
          ? new Date(data?.agency_profile?.institution_nib_date)
          : null,
        institution_nik_date: data?.agency_profile?.institution_nik_date
          ? new Date(data?.agency_profile?.institution_nik_date)
          : null,
        documents: data.agency_profile.agency_legality_document.length
          ? default_legality_docs(
            data?.agency_profile?.agency_legality_document
          )
          : default_legality_docs(),
        // documents: data.agency_profile.agency_legality_document.length
        //   ? default_legality_docs()
        //   : default_legality_docs(),
      },
    };

    delete mapData.agency.agency_profile;
    delete mapData.agency.business_type;

    methods.reset(mapData);
  }, [data, methods, default_legality_docs]);

  const distribution = useMemo(() => {
    let result;

    switch (type) {
      case "bank-ventura":
        result = "BANK";
        break;
      case "koperasi":
        result = "KOPERASI";
        break;
      case "non-koperasi":
        result = "NON_KOPERASI";
        break;
      case "badan-hukum":
        result = "LAINNYA";
        break;
      case "bumdes":
        result = "BUMDES";
        break;
      case "cv":
        result = "CV";
        break;
      case "pt":
        result = "PT";
        break;
      case "firma":
        result = "FIRMA";
        break;
      default:
        result = "BANK";
        break;
    }

    return result;
  }, [type]);

  const handleClickSubmit = useCallback(() => {
    setConfirmModal((prev) => !prev);
  }, []);

  const handleSaveDraftClick = useCallback(() => {
    setDraftModal((prev) => !prev);
  }, []);

  const handleClickBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onCreate = useCallback(
    (payload) => {
      methods.handleSubmit((values) => {
        const mapPayload = {
          ...values,
          profile: {
            ...values?.profile,
            institution_legality_date: values?.profile.institution_legality_date
              ? moment(values?.profile.institution_legality_date).format(
                "YYYY-MM-DD"
              )
              : null,
            institution_nib_date: values?.profile.institution_nib_date
              ? moment(values?.profile.institution_nib_date).format(
                "YYYY-MM-DD"
              )
              : null,
            institution_nik_date: payload?.profile?.institution_nik_date
              ? moment(payload?.profile.institution_nik_date).format(
                "YYYY-MM-DD"
              )
              : null,
          },
        };

        mutateAgencyProfile.mutate(
          {
            data: mapPayload,
            query: {
              id: values?.profile?.id,
              distribution,
              is_draft: false,
            },
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar({
                variant: "success",
                message: "Berhasil Registrasi Prospecting Non Perorangan",
              });
              queryClient.invalidateQueries();
              setTimeout(() => {
                navigate(-1);
              }, 500);
            },
            onError: (err) => {
              enqueueSnackbar({
                variant: "error",
                message: "Gagal Registrasi Prospecting Non Perorangan",
              });
            },
          }
        );
      })();
    },
    [mutateAgencyProfile, distribution, queryClient, navigate, methods]
  );

  const onCreateDraft = useCallback(
    (_) => {
      const payload = methods.getValues();

      const mapPayload = {
        ...payload,
        profile: {
          ...payload?.profile,
          institution_legality_date: payload?.profile.institution_legality_date
            ? moment(payload?.profile.institution_legality_date).format(
              "YYYY-MM-DD"
            )
            : null,
          institution_nib_date: payload?.profile.institution_nib_date
            ? moment(payload?.profile.institution_nib_date).format("YYYY-MM-DD")
            : null,
          institution_nik_date: payload?.profile?.institution_nik_date
            ? moment(payload?.profile.institution_nik_date).format("YYYY-MM-DD")
            : null,
        },
      };

      mutateAgencyProfile.mutate(
        {
          data: mapPayload,
          query: {
            id: payload?.profile?.id,
            distribution,
            is_draft: true,
          },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              variant: "success",
              message: "Berhasil Registrasi Prospecting Non Perorangan",
            });
            queryClient.invalidateQueries();
            setTimeout(() => {
              navigate(-1);
            }, 500);
          },
          onError: (err) => {
            enqueueSnackbar({
              variant: "error",
              message: "Gagal Registrasi Prospecting Non Perorangan",
            });
          },
        }
      );
    },
    [mutateAgencyProfile, distribution, queryClient, navigate, methods]
  );

  const [searchParams] = useSearchParams();

  const isView = searchParams.get("status") === "view";

  useEffect(() => {
    handleMountingData();
  }, [handleMountingData]);

  return (
    <div>
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onCreate}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        open={draftModal}
        onClose={setDraftModal}
        onSubmit={onCreateDraft}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan menyimpan data ini sebagai draft?
        </div>
        <div className="text-sm font-[400]">
          Setelah Anda menyimpan data sebagai draft anda masih bisa
          melanjutkannya.
        </div>
      </ConfirmationModal>

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text={`Registrasi Prospecting ${group === "non-perorangan" ? "Non Perorangan" : "Lembaga Penyalur"
            }`}
          description="Lengkapi persyaratan di bawah ini."
        />

        <div className="border-b" />
        {data?.agency_profile?.status_string === "Revisi" && (
          <Alert
            theme={"warning"}
            title={"Revisi"}
            description={
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.agency_profile?.agency_verificator?.notes,
                }}
              ></p>
            }
          />
        )}
        {data?.agency_profile?.status_string !== "Terverifikasi" &&
          data?.agency_profile?.status_string !== "Revisi" && (
            <AlertBlankData
              title={"Lengkapi Data"}
              body={
                "Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisisan ke tahap selanjutnya."
              }
            />
          )}

        <FormProvider {...methods}>
          <GeneralInformationForm
            // onBack={onBack}
            // onSubmit={onSubmit}
            // onDraft={onDraft}
            profileData={data}
            props={props}
            isView={isView}
          />

          <BottomFormAction
            lastStep={true}
            backButtonProps={{
              type: "button",
            }}
            draftButtonProps={{
              type: "button",
            }}
            submitButtonProps={{
              type: "submit",
            }}
            backButtonAction={handleClickBack}
            hideDraft={isView}
            hideSubmit={isView}
            disableDrafButton={false}
            drafButtonAction={handleSaveDraftClick}
            disableButtonSubmit={false}
            submitActionButton={() => {
              console.log(methods.formState.errors);
              methods.handleSubmit(handleClickSubmit)();
            }}
          />
        </FormProvider>
        <div></div>
      </div>
    </div>
  );
};

export default GeneralProfile;
