import { yupResolver } from "@hookform/resolvers/yup";
import {
  BottomFormAction,
  InputForm,
  Button,
  ConfirmationModal,
} from "components";
import CardForm from "components/molecules/CardForm";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  FormProvider,
  useForm,
  useWatch,
  useFormContext,
  useFieldArray,
} from "react-hook-form";
import * as Yup from "yup";
import AtomSelect from "../../../../../components/atoms/Select";
import RichEditorForm from "../../../../../components/molecules/RichEditorForm";
import InputKTP from "../../../../../components/organisms/inputs/InputKTP";
import InputNumeric from "../../../../../components/organisms/inputs/InputNumeric";
import InputPhone from "../../../../../components/organisms/inputs/InputPhone";
import SelectStatusPendamping from "../../../../../components/organisms/inputs/SelectStatusPendamping";

import {
  isCitizenIdValid,
  isRTEEmpty,
} from "../../../../../helpers/validation";
import { validationErrorMsg } from "../../../../../locale/yup_id";
import { FieldLabel } from "./constants";
import { map } from "lodash";
import { BiTrash } from "react-icons/bi";
import { RiAddLine } from "react-icons/ri";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getActivityCategory,
  getMasterActivityCategory,
} from "services/fdb/activityCategory";
import { getMasterBussinessCategory } from "services/fdb/bussinessCategory";
import store from "app/store";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";
import { getMasterSocialArea } from "services/fdb/socialArea";
import AtomDatePicker from "components/atoms/Datepicker";
import { useSelector } from "react-redux";
import {
  deleteActivity,
  deleteFinanceBusiness,
  deletePartner,
} from "services/fdb/perhutananSosial/registerForm";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { createPortal } from "react-dom";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
export const validationSchema = Yup.object().shape({
  general: Yup.object().shape({
    group_name: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.general.GROUP_NAME),
    decree: Yup.string().trim().required().label(FieldLabel.general.DECREE),
    province: Yup.mixed().required().label(FieldLabel.general.PROVINCE),
    city: Yup.mixed().required().label(FieldLabel.general.CITY),
    district: Yup.mixed().required().label(FieldLabel.general.DISTRICT),
    subdistrict: Yup.mixed().required().label(FieldLabel.general.SUBDISTRICT),
    address: Yup.string().trim().required().label(FieldLabel.general.ADDRESS),
  }),
  info: Yup.object().shape({
    leader_name: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.LEADER_NAME),
    leader_phone: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.LEADER_PHONE),
    leader_cid: Yup.string()
      .trim()
      .test("no_ktp", validationErrorMsg.mixed.default, isCitizenIdValid)
      .required()
      .label(FieldLabel.info.LEADER_CID),
    leader_gender: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.LEADER_GENDER),
    secretary_name: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.SECRETARY_NAME),
    secretary_phone: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.SECRETARY_PHONE),
    secretary_gender: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.SECRETARY_GENDER),
    treasurer_name: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.TREASURER_NAME),
    treasurer_phone: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.TREASURER_PHONE),
    treasurer_gender: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.TREASURER_GENDER),
    associate_name: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.ASSOCIATE_NAME),
    associate_phone: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.ASSOCIATE_PHONE),
    associate_status: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.ASSOCIATE_STATUS),
    associate_gender: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.ASSOCIATE_GENDER),
    // past_activities: Yup.string()
    //   .trim()
    //   .test("past_activities", validationErrorMsg.mixed.required, isRTEEmpty)
    //   .required()
    //   .label(FieldLabel.info.PAST_ACTIVITIES),
    membership_count: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.MEMBERSHIP_COUNT),
    andil_garapan: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.ANDIL_GARAPAN),
    total_area: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.TOTAL_AREA),
    all_membership_count: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.MEMBERSHIP_COUNT_ALL),
    all_andil_garapan: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.ANDIL_GARAPAN_ALL),
    all_total_area: Yup.string()
      .trim()
      .required()
      .label(FieldLabel.info.TOTAL_AREA_ALL),
    // partner: Yup.string().trim().label(FieldLabel.info.PARTNER),
    // general_overview: Yup.string()
    //   .trim()
    //   .required()
    //   .label(FieldLabel.info.GENERAL_OVERVIEW),
    // ? PENGUKUHAN
    end_date_confirmation: Yup.string()
      .required()
      .label(FieldLabel.info.GROUP_INAUGURATION_DEADLINE),
    // ? KEPENGURUSAN
    end_date_permission: Yup.string()
      .required()
      .label(FieldLabel.info.GROUP_MANAGEMENT_DEADLINE),
    // ?
    end_date_legality: Yup.string()
      .required()
      .label(FieldLabel.info.LEGAL_TIME_LIMIT_FOR_GROUP_MEMBER_ACCESS_TO_LAND),
    // ? Batas Akhir Kepengurusan*
    end_date_management: Yup.string()
      .required()
      .label(FieldLabel.info.MANAGEMENT_DEADLINE),
  }),
  mitra_usaha_kelompok: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().label("Mitra Usaha Kelompok"),
    })
  ),
  kegiatan_kelompok_dilaksanakan: Yup.array().of(
    Yup.object().shape({
      activity_category_id: Yup.string().required().label("Category"),
      description: Yup.string().required().label("Deskripsi"),
    })
  ),
  gambaran_umum_dibiayai: Yup.array().of(
    Yup.object().shape({
      finance_business_category_id: Yup.string().required().label("Category"),
      description: Yup.string().required().label("Deskripsi"),
    })
  ),
});

const AkunKelompokNPSFormInfo = forwardRef(
  (
    { onBack, onSubmit, lastStep, onSaveDraft, initialValues, mode = "view" },
    ref
  ) => {
    const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
      (e) => e === mode
    );

    const methods = useFormContext();

    useEffect(() => {
      // methods.reset(initialValues);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, [initialValues, methods]);

    const provinceId = useWatch({
      control: methods.control,
      name: "general.province",
    });
    const cityId = useWatch({
      control: methods.control,
      name: "general.city",
    });
    const districtId = useWatch({
      control: methods.control,
      name: "general.district",
    });
    const villageId = useWatch({
      control: methods.control,
      name: "general.subdistrict",
    });

    useImperativeHandle(ref, () => ({
      getFormValues() {
        return methods.getValues();
      },
    }));

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const onFormSubmit = () => {
      return methods.handleSubmit((data) => {
        console.log("submmit");
        onSubmit(data);
      });
    };

    const onSaveDraftClick = (e) => {
      e?.preventDefault();

      const formData = methods.getValues();

      if (onSaveDraft) {
        onSaveDraft(formData);
      }
    };

    return (
      <FormProvider {...methods}>
        <form onSubmit={onFormSubmit()} className="space-y-8">
          <CardForm label="General Informasi">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputForm
                controllerName={"general.group_name"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.general.GROUP_NAME}
                placeholder={FieldLabel.general.GROUP_NAME}
                required
                asText={isView}
              />
              <InputForm
                controllerName={"general.decree"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.general.DECREE}
                placeholder={FieldLabel.general.DECREE}
                required
                asText={isView}
              />
              <AtomDatePicker
                controllerName={"general.establishment_date"}
                label={FieldLabel.general.ESTABLISHMENT_DATE}
                placeholder={FieldLabel.general.ESTABLISHMENT_DATE}
                datepickerOptions={{
                  maxDate: new Date(),
                  showMonthDropdown: true,
                  showYearDropdown: true,
                  scrollableYearDropdown: true,
                  dropdownMode: "select",
                }}
                required
                showErrorLabel
              />
              <FormSelectKawasan />
              <InputForm
                controllerName={"general.area_access_agreement"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.general.AREA_ACCESS_AGGREMENT}
                placeholder={FieldLabel.general.AREA_ACCESS_AGGREMENT}
                required
                asText={isView}
              />
              <InputForm
                controllerName={"general.area_access_agreement_year_expire"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.general.AREA_ACCESS_AGGREMENT_EXPIRE}
                placeholder={FieldLabel.general.AREA_ACCESS_AGGREMENT_EXPIRE}
                required
                asText={isView}
              />
              <SelectFDBProvince
                controllerName={"general.province"}
                label={FieldLabel.general.PROVINCE}
                placeholder={FieldLabel.general.PROVINCE}
                required
                returnObject={false}
                asText={isView}
              />
              <SelectFDBCity
                controllerName={"general.city"}
                label={FieldLabel.general.CITY}
                placeholder={FieldLabel.general.CITY}
                required
                returnObject={false}
                provinceId={provinceId}
                asText={isView}
              />
            </div>
            <div className="grid grid-cols-12 md:grid-cols-12 gap-4">
              <div className="col-span-4">
                <SelectFDBDistrict
                  controllerName={"general.district"}
                  label={FieldLabel.general.DISTRICT}
                  placeholder={FieldLabel.general.DISTRICT}
                  required
                  returnObject={false}
                  cityId={cityId}
                  asText={isView}
                />
              </div>
              <div className="col-span-4">
                <SelectFDBVillage
                  controllerName={"general.subdistrict"}
                  label={FieldLabel.general.SUBDISTRICT}
                  placeholder={FieldLabel.general.SUBDISTRICT}
                  required
                  returnObject={false}
                  districtId={districtId}
                  asText={isView}
                />
              </div>
              <div className="col-span-4">
                <SelectFDBZipCode
                  controllerName={"general.zip_code"}
                  label={FieldLabel.general.ZIP_CODE}
                  placeholder={FieldLabel.general.ZIP_CODE}
                  required
                  returnObject={false}
                  villageId={villageId}
                  asText={isView}
                />
              </div>
            </div>
            <InputForm
              controllerName={"general.address"}
              className={`py-2 px-4 mt-2 border w-full rounded-md`}
              label={FieldLabel.general.ADDRESS}
              placeholder={FieldLabel.general.ADDRESS}
              textArea
              required
              asText={isView}
            />
          </CardForm>

          <CardForm label="Informasi Kelompok">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              <div className={"col-span-3"}>
                <InputForm
                  controllerName={"info.leader_name"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={FieldLabel.info.LEADER_NAME}
                  placeholder={FieldLabel.info.LEADER_NAME}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <InputPhone
                  controllerName={"info.leader_phone"}
                  label={FieldLabel.info.LEADER_PHONE}
                  placeholder={FieldLabel.info.LEADER_PHONE}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <InputKTP
                  controllerName={"info.leader_cid"}
                  label={FieldLabel.info.LEADER_CID}
                  placeholder={FieldLabel.info.LEADER_CID}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <AtomSelect
                  controllerName={"info.leader_gender"}
                  options={[
                    { value: "m", label: "Laki-laki" },
                    { value: "f", label: "Perempuan" },
                  ]}
                  className="basic-single mt-2"
                  label={FieldLabel.info.LEADER_GENDER}
                  placeholder="Laki-laki / Perempuan"
                  required
                  returnObject={false}
                  asText={isView}
                />
              </div>
              <div className={"col-span-4"}>
                <InputForm
                  controllerName={"info.secretary_name"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={FieldLabel.info.SECRETARY_NAME}
                  placeholder={FieldLabel.info.SECRETARY_NAME}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-4"}>
                <InputPhone
                  controllerName={"info.secretary_phone"}
                  label={FieldLabel.info.SECRETARY_PHONE}
                  placeholder={FieldLabel.info.SECRETARY_PHONE}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-4"}>
                <AtomSelect
                  controllerName={"info.secretary_gender"}
                  options={[
                    { value: "m", label: "Laki-laki" },
                    { value: "f", label: "Perempuan" },
                  ]}
                  className="basic-single mt-2"
                  label={FieldLabel.info.SECRETARY_GENDER}
                  placeholder="Laki-laki / Perempuan"
                  required
                  returnObject={false}
                  asText={isView}
                />
              </div>
              <div className={"col-span-4"}>
                <InputForm
                  controllerName={"info.treasurer_name"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={FieldLabel.info.TREASURER_NAME}
                  placeholder={FieldLabel.info.TREASURER_NAME}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-4"}>
                <InputPhone
                  controllerName={"info.treasurer_phone"}
                  label={FieldLabel.info.TREASURER_PHONE}
                  placeholder={FieldLabel.info.TREASURER_PHONE}
                  required
                  mask="0"
                  data-maska-tokens="0:\d:multiple"
                  asText={isView}
                />
              </div>
              <div className={"col-span-4"}>
                <AtomSelect
                  controllerName={"info.treasurer_gender"}
                  options={[
                    { value: "m", label: "Laki-laki" },
                    { value: "f", label: "Perempuan" },
                  ]}
                  className="basic-single mt-2"
                  label={FieldLabel.info.TREASURER_GENDER}
                  placeholder="Laki-laki / Perempuan"
                  required
                  returnObject={false}
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <InputForm
                  controllerName={"info.associate_name"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={FieldLabel.info.ASSOCIATE_NAME}
                  placeholder={FieldLabel.info.ASSOCIATE_NAME}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <InputPhone
                  controllerName={"info.associate_phone"}
                  label={FieldLabel.info.ASSOCIATE_PHONE}
                  placeholder={FieldLabel.info.ASSOCIATE_PHONE}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <SelectStatusPendamping
                  controllerName={"info.associate_status"}
                  label={FieldLabel.info.ASSOCIATE_STATUS}
                  placeholder={FieldLabel.info.ASSOCIATE_STATUS}
                  required
                  returnObject={false}
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <AtomSelect
                  controllerName={"info.associate_gender"}
                  options={[
                    { value: "m", label: "Laki-laki" },
                    { value: "f", label: "Perempuan" },
                  ]}
                  className="basic-single mt-2"
                  label={FieldLabel.info.ASSOCIATE_GENDER}
                  placeholder="Laki-laki / Perempuan"
                  required
                  returnObject={false}
                  asText={isView}
                />
              </div>
              <div className={"col-span-6"}>
                <AtomDatePicker
                  controllerName={"info.end_date_confirmation"}
                  label={FieldLabel.info.GROUP_INAUGURATION_DEADLINE}
                  placeholder={FieldLabel.info.GROUP_INAUGURATION_DEADLINE}
                  datepickerOptions={{
                    showMonthDropdown: true,
                    showYearDropdown: true,
                    scrollableYearDropdown: true,
                    dropdownMode: "select",
                  }}
                  required
                  showErrorLabel
                />
              </div>
              <div className={"col-span-6"}>
                <AtomDatePicker
                  controllerName={"info.end_date_permission"}
                  label={FieldLabel.info.GROUP_MANAGEMENT_DEADLINE}
                  placeholder={FieldLabel.info.GROUP_MANAGEMENT_DEADLINE}
                  datepickerOptions={{
                    showMonthDropdown: true,
                    showYearDropdown: true,
                    scrollableYearDropdown: true,
                    dropdownMode: "select",
                  }}
                  required
                  showErrorLabel
                />
              </div>
              <div className={"col-span-6"}>
                <AtomDatePicker
                  controllerName={"info.end_date_legality"}
                  label={
                    FieldLabel.info
                      .LEGAL_TIME_LIMIT_FOR_GROUP_MEMBER_ACCESS_TO_LAND
                  }
                  placeholder={
                    FieldLabel.info
                      .LEGAL_TIME_LIMIT_FOR_GROUP_MEMBER_ACCESS_TO_LAND
                  }
                  datepickerOptions={{
                    showMonthDropdown: true,
                    showYearDropdown: true,
                    scrollableYearDropdown: true,
                    dropdownMode: "select",
                  }}
                  required
                  showErrorLabel
                />
              </div>
              <div className={"col-span-6"}>
                <AtomDatePicker
                  controllerName={"info.end_date_management"}
                  label={FieldLabel.info.MANAGEMENT_DEADLINE}
                  placeholder={FieldLabel.info.MANAGEMENT_DEADLINE}
                  datepickerOptions={{
                    showMonthDropdown: true,
                    showYearDropdown: true,
                    scrollableYearDropdown: true,
                    dropdownMode: "select",
                  }}
                  required
                  showErrorLabel
                />
              </div>
              <div className={"col-span-12"}>
                <p className="text-lg text-primary-700 font-semibold">
                  Kegiatan - Kegiatan Kelompok yang telah Dilaksanakan
                </p>
                <FormDataKegiatanDilaksanakan />
              </div>
              {/* <div className={"col-span-12"}>
                <RichEditorForm
                  key={`info.past_activities--${mode}`}
                  name={`info.past_activities`}
                  label={FieldLabel.info.PAST_ACTIVITIES}
                  placeholder={isView ? "" : FieldLabel.info.PAST_ACTIVITIES}
                  required
                  hideToolbar={isView}
                  theme={isView ? "" : "snow"}
                />
              </div> */}
              <div className={"col-span-6"}>
                <InputNumeric
                  controllerName={"info.all_membership_count"}
                  label={FieldLabel.info.MEMBERSHIP_COUNT_ALL}
                  placeholder={0}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <InputNumeric
                  controllerName={"info.all_andil_garapan"}
                  label={FieldLabel.info.ANDIL_GARAPAN_ALL}
                  placeholder={0}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <InputNumeric
                  controllerName={"info.all_total_area"}
                  label={FieldLabel.info.TOTAL_AREA_ALL}
                  placeholder={0}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-6"}>
                <InputNumeric
                  controllerName={"info.membership_count"}
                  label={FieldLabel.info.MEMBERSHIP_COUNT}
                  placeholder={0}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <InputNumeric
                  controllerName={"info.andil_garapan"}
                  label={FieldLabel.info.ANDIL_GARAPAN}
                  placeholder={0}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-3"}>
                <InputNumeric
                  controllerName={"info.total_area"}
                  label={FieldLabel.info.TOTAL_AREA}
                  placeholder={0}
                  required
                  asText={isView}
                />
              </div>
              <div className={"col-span-12"}>
                <FormDataMitraUsaha />
              </div>
              <div className={"col-span-12"}>
                <p className="text-lg text-primary-700 font-semibold">
                  Gambaran Umum Jenis - Jenis Usaha Anggota Kelompok yang
                  Dibiayai
                </p>
                <FormGambaranUmumDibiayai />
              </div>
              {/* <div className={"col-span-12"}>
                <InputForm
                  controllerName={"info.general_overview"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={FieldLabel.info.GENERAL_OVERVIEW}
                  placeholder={FieldLabel.info.GENERAL_OVERVIEW}
                  required
                  asText={isView}
                />
              </div> */}
            </div>
          </CardForm>

          <BottomFormAction
            lastStep={lastStep}
            backButtonProps={{
              type: "button",
            }}
            backButtonAction={onBack}
            submitButtonProps={{
              type: "submit",
            }}
            disableDrafButton={false}
            hideDraft={isView}
            drafButtonAction={onSaveDraftClick}
            draftButtonProps={{
              type: "button",
            }}
            disableButtonSubmit={false}
          />
        </form>
      </FormProvider>
    );
  }
);
const FormDataMitraUsaha = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "mitra_usaha_kelompok",
    keyName: "_id",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedKey, setSelectedKey] = useState("");

  const userId = useSelector((state) => state?.oidc?.oidc_user?.access_token);

  const onClickDelete = (id, key) => {
    if (id) {
      setConfirmModal(true);
      setSelectedId(id);
      setSelectedKey(key);
    } else {
      remove(key);
    }
  };

  const onSubmitDelete = () => {
    submitDelete.mutate();
  };

  const submitDelete = useMutation({
    mutationFn: (payload) => {
      return deletePartner(userId, selectedId);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Dihapus!",
        variant: "success",
      });

      setSelectedId("");
      setConfirmModal(false);
      remove(selectedKey);

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });
  return (
    <div>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmitDelete}
          className="space-y-2 mb-4"
          useGraphic={false}
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menghapus data mitra ini?
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {map(fields, (field, key) => (
        <div
          key={field._id}
          className="my-4 pb-8 border-b flex items-center space-x-4"
        >
          <InputForm
            controllerName={`mitra_usaha_kelompok.${key}.name`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.info.PARTNER}
            placeholder={FieldLabel.info.PARTNER}
          />
          {key > 0 && (
            <div>
              <Button
                type="button"
                onClick={() => {
                  onClickDelete(field.id, key);
                }}
                className="p-3 rounded-lg bg-error-600 flex-none"
                label={<BiTrash className="text-white" />}
              />
            </div>
          )}
        </div>
      ))}
      <div className="flex items-center justify-end pt-2">
        <Button
          type="button"
          className="p-4 border rounded-lg bg-white"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah Mitra Usaha Kelompok
            </div>
          }
          onClick={() => {
            append({
              name: "",
            });
          }}
        />
      </div>
    </div>
  );
};

const FormDataKegiatanDilaksanakan = () => {
  const { control, setValue } = useFormContext();
  const user = store.getState().profile.data.data;
  const [options, setOptions] = useState([]);
  const { master } = useQuery({
    queryKey: ["master-activity-category-fdb"],
    queryFn: async () => {
      const res = await getActivityCategory();

      const activities = res?.data?.data.data || [];
      setOptions(
        activities?.map((entry) => {
          return {
            value: entry?.id,
            label: entry?.name,
          };
        })
      );
      const formattedData = activities.map((activity) => ({
        activity_category_id: activity.id,
        description: activity.description,
      }));
      return formattedData;
    },
    retry: 1,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "kegiatan_kelompok_dilaksanakan",
    keyName: "_id",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedKey, setSelectedKey] = useState("");

  const userId = useSelector((state) => state?.oidc?.oidc_user?.access_token);

  const onClickDelete = (id, key) => {
    if (id) {
      setConfirmModal(true);
      setSelectedId(id);
      setSelectedKey(key);
    } else {
      remove(key);
    }
  };

  const onSubmitDelete = () => {
    submitDelete.mutate();
  };

  const submitDelete = useMutation({
    mutationFn: (payload) => {
      return deleteActivity(userId, selectedId);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Dihapus!",
        variant: "success",
      });

      setSelectedId("");
      setConfirmModal(false);
      remove(selectedKey);

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });
  return (
    <div>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmitDelete}
          useGraphic={false}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menghapus data aktivitas ini?
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {map(fields, (field, key) => (
        <div
          key={field._id}
          className="my-4 pb-8 border-b flex items-center space-x-4"
        >
          <AtomSelect
            controllerName={`kegiatan_kelompok_dilaksanakan.${key}.activity_category_id`}
            options={options}
            className="basic-single mt-2"
            label={"Kegiatan"}
            required
            returnObject={false}
          />
          <InputForm
            controllerName={`kegiatan_kelompok_dilaksanakan.${key}.description`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Deskripsi"}
            placeholder={"Deskripsi"}
            required
            textArea={true}
          />
          {key > 0 && (
            <div>
              <Button
                type="button"
                onClick={() => {
                  onClickDelete(field.id, key);
                }}
                className="p-3 rounded-lg bg-error-600 flex-none"
                label={<BiTrash className="text-white" />}
              />
            </div>
          )}
        </div>
      ))}
      <div className="flex items-center justify-end pt-2">
        <Button
          type="button"
          className="p-4 border rounded-lg bg-white"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah Kegiatan Kelompok
            </div>
          }
          onClick={() => {
            append({
              activity_category_id: "",
              description: "",
            });
          }}
        />
      </div>
    </div>
  );
};

const FormGambaranUmumDibiayai = () => {
  const { control } = useFormContext();
  const [options, setOptions] = useState([]);
  const user = store.getState().profile.data.data;
  const { master } = useQuery({
    queryKey: ["master-bussiness-category-fdb"],
    queryFn: async () => {
      const res = await getMasterBussinessCategory();

      const bussinesses = res?.data?.data.data || [];
      setOptions(
        bussinesses?.map((bussiness) => {
          return {
            value: bussiness?.id,
            label: bussiness?.name,
          };
        })
      );
      const formattedData = bussinesses.map((bussiness) => ({
        finance_business_category_id: bussiness.id,
        description: bussiness.description,
      }));
      return formattedData;
    },
    retry: 1,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "gambaran_umum_dibiayai",
    keyName: "_id",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const userId = useSelector((state) => state?.oidc?.oidc_user?.access_token);

  const onClickDelete = (id, key) => {
    if (id) {
      setConfirmModal(true);
      setSelectedId(id);
      setSelectedKey(key);
    } else {
      remove(key);
    }
  };

  const onSubmitDelete = () => {
    submitDelete.mutate();
  };

  const submitDelete = useMutation({
    mutationFn: (payload) => {
      return deleteFinanceBusiness(userId, selectedId);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Dihapus!",
        variant: "success",
      });

      setSelectedId("");
      setConfirmModal(false);
      remove(selectedKey);

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });
  return (
    <div>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmitDelete}
          useGraphic={false}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menghapus data finance ini?
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {map(fields, (field, key) => (
        <div
          key={field.id}
          className="my-4 pb-8 border-b flex items-center space-x-4"
        >
          <AtomSelect
            controllerName={`gambaran_umum_dibiayai.${key}.finance_business_category_id`}
            options={options}
            className="basic-single mt-2"
            label={"Kegiatans"}
            required
            returnObject={false}
          />
          <InputForm
            controllerName={`gambaran_umum_dibiayai.${key}.description`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Deskripsi"}
            placeholder={"Deskripsi"}
            required
            textArea={true}
          />
          {key > 0 && (
            <div>
              <Button
                type="button"
                onClick={() => onClickDelete(field.id, key)}
                className="p-3 rounded-lg bg-error-600 flex-none"
                label={<BiTrash className="text-white" />}
              />
            </div>
          )}
        </div>
      ))}
      <div className="flex items-center justify-end pt-2">
        <Button
          type="button"
          className="p-4 border rounded-lg bg-white"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah Gambaran Umum
            </div>
          }
          onClick={() => {
            append({
              finance_business_category_id: "",
              description: "",
            });
          }}
        />
      </div>
    </div>
  );
};

const FormSelectKawasan = () => {
  const [options, setOptions] = useState([]);
  const { master } = useQuery({
    queryKey: ["master-social-area-fdb"],
    queryFn: async () => {
      const res = await getMasterSocialArea();
      const area = res?.data?.data.data || [];
      setOptions(
        area?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        })
      );
    },
    retry: 1,
  });
  return (
    <AtomSelect
      controllerName={"general.area"}
      options={options}
      className="basic-single mt-2"
      label={FieldLabel.general.AREA}
      placeholder="Pilih Kawasan"
      required
      returnObject={false}
    />
  );
};

export default AkunKelompokNPSFormInfo;
