import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormRadio,
  InputFormMultiple,
  InputFormUpload,
} from "components";
import {
  FormProvider,
  useFieldArray,
  useForm,
  Controller,
} from "react-hook-form";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiAddLine, RiDeleteBin6Line, RiQuestionLine } from "react-icons/ri";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import { getPositionService } from "services/masterData/position";
import { useSelector } from "react-redux";
import {
  addNewPosition,
  addNewStrategicPlans,
  getStrategicPlans,
} from "services/danaProgram/masterDataService";
import { useForceUpdate } from "framer-motion";

const RencanaLembaga = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
  dataOnRedux,
}) => {
  const navigate = useNavigate();
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [dynamicOptionValues, setDynamicOptionValues] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [positions, setPositions] = useState();
  const validationSchema = useMemo(() => {
    return yup.object({
      plan_strategy_organization: yup
        .mixed()
        .required()
        .nonNullable()
        .label("rencana strategis wajib diisi"),
      plan_strategy_organization_time: yup
        .mixed()
        .required()
        .nonNullable()
        .label("waktu rencana strategis wajib diisi"),
      strategy_master: yup.array().of(
        yup.object().shape({
          position: yup.mixed().required().nonNullable().label("Jenis Kelamin"),
        })
      ),
      hirarki_position: yup
        .array()
        .required("hirarki wajib diisi")
        .min(1, "hirarki wajib diisi"),
      organizational_structure_document: yup
        .mixed()
        .required("file struktur organisasi wajib diisi"),
      organizational_structure_desc: yup
        .string()
        .required("deskripsi dokumen bukti wajib diisi"),
      organ_function_document: yup
        .mixed()
        .required("file fungsi orgran wajib diisi"),
      organ_function_desc: yup
        .string()
        .required("deskripsi dokumen bukti wajib diisi"),
    });
  });

  const [planStrategyList, setPlanStrategyList] = useState([]);

  const getStrategic = useMutation(async () => {
    try {
      const strategicResponse = await getStrategicPlans();
      setPlanStrategyList(
        strategicResponse?.data?.data?.map((item) => ({
          label: item.nama,
          value: item.nama,
          description: item.deskripsi,
          id: item.id,
        }))
      );
    } catch (error) {
      throw error;
    }
  });

  // masterData?.rencanaStrategis

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      strategy_master: draftData?.strategy_master?.map((data) => ({
        position: data?.position || undefined,
      })) || [{ position: undefined }],
    },
  });

  const [isFetching, setIsFetching] = useState(true);

  const getPosition = useMutation({
    mutationFn: async (callback) => {
      try {
        const positionResponse = await getPositionService();
        const transformedData = positionResponse?.data?.data?.map(
          (item, index) => ({
            label: item.nama,
            value: item.id,
          })
        );
        callback(transformedData);
        setIsFetching(false);
      } catch (error) {
        throw error;
      }
    },
  });

  useEffect(() => {
    getPosition.mutate(setPositions);
    getStrategic.mutate();
  }, []);

  const addNewPlan = useMutation({
    mutationFn: (params) => {
      return addNewStrategicPlans(params);
    },
  });

  const { register, setValue, watch } = methods;

  const handleShowInput = () => {
    setValue("option", "custom");
    setShowForm(true);
  };

  const handleHideInput = () => {
    setValue("option", "");
    setShowForm(false);
  };

  const handleAddOption = (data) => {
    const customOptionValue = data.customOption;

    const newOption = {
      label: customOptionValue,
      value: planStrategyList?.length + 1,
    };

    const temp = {
      nama: customOptionValue,
      deskripsi: customOptionValue,
    };

    addNewPlan.mutate(temp);

    setPlanStrategyList((prevOptions) => [...prevOptions, newOption]);
    setValue("option", "");
    setShowForm(false);
  };

  useEffect(() => {
    if (draftData) {
      Object.keys(draftData).forEach((key) => {
        if (key.includes("document")) {
          getFileMutation.mutate({
            id: draftData[key],
            key: key,
          });
        } else {
          setValue(key, draftData[key]);
        }
      });
      methods.trigger();
    }
  }, [draftData, setValue, methods]);

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const {
    fields: strategy_masterFields,
    append: appendStrategy_master,
    remove: removeStrategy_master,
  } = useFieldArray({
    control,
    name: "strategy_master",
  });
  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);

      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: Math.round(parseInt(response.data.data.size) / 1000),
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: Math.round(parseInt(response.data.data.size) / 1000),
        };
        setValue(key, temp);
      }
    } catch (error) {
      throw error;
    }
  });

  const handleOptionChange = (index, value) => {
    setDynamicOptionValues((prevValues) => {
      const updatedValues = [...prevValues];
      const valueIndex = updatedValues.findIndex(
        (item) => item.index === index
      );
      if (valueIndex !== -1) {
        updatedValues[valueIndex].value = value;
      }
      return updatedValues;
    });
  };

  const strategy = watch("strategy_master");

  useEffect(() => {
    methods.setValue(
      "hirarki_position",
      methods.watch("strategy_master").map((item) => item.position)
    );
  }, [strategy, methods.getFieldState("strategy_master")]);

  return (
    <>
      <FormProvider {...methods}>
        {!isFetching && (
          <>
            <CardForm label={"Rencana Kelembagaan"}>
              <>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormMultiple
                      label={
                        "Bagaimana cara Anda menyusun rencana strategis lembaga Anda?"
                      }
                      controllerName={"plan_strategy_organization"}
                      directionColumn={true}
                      multiple={true}
                      values={planStrategyList}
                      control={control}
                    />
                    {showForm && (
                      <div className="flex gap-4 items-end pt-2">
                        <div className="w-full">
                          <div className="flex gap-1">
                            <div className="flex-1 pt-2">
                              <label>
                                <InputForm
                                  controllerName={"customOption"}
                                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                    errors.nama_lemtara
                                      ? "border-[#F04438]"
                                      : "border-green"
                                  }`}
                                  placeholder={"Rencana lain"}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <button
                          className="w-11 h-11 p-3 bg-[#01A24A] text-white rounded-md"
                          onClick={() => handleAddOption(watch())}
                        >
                          <RiAddLine />
                        </button>
                        <button
                          className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                          onClick={handleHideInput}
                        >
                          <RiDeleteBin6Line />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex-1 pt-2">
                    *Jika tidak ada di daftar pilihan, silahkan buat baru
                    rencana strategis yg lembaga Anda lakukan
                  </div>
                  <div className="flex flex-row-reverse my-5 mx-4 ">
                    <Button
                      className="p-4 border rounded-lg bg-white"
                      label={
                        <div className="flex items-center gap-2 font-[14px]">
                          <RiAddLine />
                          Rencana Lain
                        </div>
                      }
                      onClick={handleShowInput}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`plan_desc`}
                      className={`px-4 mt-8 border w-full rounded-md h-32 items-start`}
                      label={"Jelaskan"}
                      textArea={true}
                      required={true}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg">
                    <RiQuestionLine />
                    Siapa yang memutuskan rencana strategis lembaga anda?
                  </div>
                </div>
                {strategy_masterFields?.length > 0
                  ? strategy_masterFields?.map((data, index) => (
                      <div className="flex gap-4 items-end pt-2" key={data.id}>
                        <div className="w-full">
                          <div className="flex gap-4">
                            <div className="flex-1 pt-2">
                              <AtomSelect
                                controllerName={`strategy_master.${index}.position`}
                                options={positions}
                                className="basic-single mt-2"
                                label="Jabatan"
                                required={true}
                                mutation={addNewPosition}
                                type={"dynamic"}
                              />
                            </div>
                          </div>
                        </div>
                        {index !== 0 && (
                          <button
                            className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                            onClick={() => {
                              removeStrategy_master(index);
                            }}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        )}
                      </div>
                    ))
                  : ""}
                <div className="flex gap-4 items-center">
                  <div className="flex-1 pt-2"></div>
                  <div className="flex flex-row-reverse my-5 mx-4 ">
                    <Button
                      className="p-4 border rounded-lg bg-white"
                      label={
                        <div className="flex items-center gap-2 font-[14px]">
                          <RiAddLine />
                          Jabatan Lain
                        </div>
                      }
                      onClick={() => {
                        appendStrategy_master({
                          position: "",
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <AtomSelect
                    controllerName={"hirarki_position"}
                    ref={register}
                    options={positions}
                    className="basic-single mt-2"
                    label="Sebutkan Dari hirarki jabatan yg paling rendah ke hirarki jabatan yg paling tinggi"
                    required={true}
                    isMulti
                    disable={true}
                  />
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormRadio
                      label={
                        "Berapa lama waktu yang lembaga Anda butuhkan untuk membuat rencana strategis?"
                      }
                      controllerName={"plan_strategy_organization_time"}
                      values={[
                        {
                          label: "1-2 Minggu",
                          value: "1-2 Minggu",
                        },
                        {
                          label: "1-2 Bulan",
                          value: "1-2 Bulan",
                        },
                        {
                          label: "3-4 Bulan",
                          value: "3-4 Bulan",
                        },
                        {
                          label: "Diatas 4 Bulan",
                          value: "Diatas 4 Bulan",
                        },
                      ]}
                    />
                    {dynamicOptions?.map((option) => (
                      <InputForm
                        key={option.value}
                        controllerName={`option-${option.value}`}
                        label={option.label}
                        value={
                          dynamicOptionValues.find(
                            (v) => v.index === option.value
                          )?.value
                        }
                        onChange={(e) =>
                          handleOptionChange(option.value, e.target.value)
                        }
                      />
                    ))}
                  </div>
                </div>
              </>
            </CardForm>
            <CardForm label={"Rencana Kelembagaan"}>
              <>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      uploadFile={mutation}
                      controllerName={`organizational_structure_document`}
                      label={
                        "Dokumen struktur organisasi Lembaga/ Instansi/ perusahaan"
                      }
                      methods={methods}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`organizational_structure_desc`}
                      className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                      label={"Uraian singkat terkait isi dokumen"}
                      placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                      textArea={true}
                      required={true}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      uploadFile={mutation}
                      controllerName={`organ_function_document`}
                      label={"Dokumen fungsi organ (job descriptions)"}
                      methods={methods}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`organ_function_desc`}
                      className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                      label={"Uraian singkat terkait isi dokumen"}
                      placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                      textArea={true}
                      required={true}
                    />
                  </div>
                </div>
              </>
            </CardForm>
          </>
        )}
        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            console.log(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default RencanaLembaga;
