import React, { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import * as yup from 'yup';
import CardForm from 'components/molecules/CardForm';
import { BottomFormAction, InputForm } from 'components';
import InputCurrency from 'components/organisms/inputs/InputCurrency';
import { RiAddLine, RiDeleteBinLine } from 'react-icons/ri';
import { map } from 'lodash';
import Button from 'components/atoms/Button/Button';

const Layanan = ({ prefix, onSubmit, onBack, onDraft, draftData }) => {
  const validationSchema = yup.object().shape({
    produk_layanan: yup
      .array()
      .of(
        yup.object().shape({
          jenis_layanan: yup.string().required().label('Jenis Produk'),
          nama_layanan: yup.string().required().label('Nama Produk'),
          pendapatan: yup.string().required().label('pendapatan'),
          keterangan: yup.string().required().label('Keterangan'),
        })
      )
      .min(1)
      .label('Produk Layanan'),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      produk_layanan: [],
    },
  });

  const {
    formState: { errors },
  } = methods;

  const { append, remove } = useFieldArray({
    control: methods.control,
    name: 'layanan',
  });

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  const addService = useCallback(
    (data) => {
      append(data);
    },
    [append]
  );

  return (
    <FormProvider {...methods}>
      <div className="space-y-10">
        <FormService onSubmit={addService} />
        <CardForm label={'Produk/Layanan Table'}>
          <ServiceTable
            onRemove={(key) => {
              remove(key);
            }}
          />
          {errors.produk_layanan?.message ? (
            <div className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
              {errors.produk_layanan.message}
            </div>
          ) : null}
        </CardForm>
      </div>
      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onFormSubmit()}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        disableButtonSubmit={false}
      />
    </FormProvider>
  );
};

const FormService = ({ onSubmit, draftData }) => {
  const validationSchema = yup.object().shape({
    jenis_layanan: yup.string().required().label('Jenis Layanan'),
    nama_layanan: yup.string().required().label('Nama Layanan'),
    pendapatan: yup.string().required().label('Pendapatan'),
    keterangan: yup.string().required().label('Keterangan'),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      jenis_layanan: draftData?.jenis_produk || '',
      nama_layanan: draftData?.nama_layanan || '',
      pendapatan: draftData?.pendapatan || '',
      keterangan: draftData?.keterangan || '',
      layanan: [],
    },
  });

  const { reset } = methods;

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
      reset();
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-10">
        <CardForm label={'Layanan'}>
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <InputForm
                controllerName={'jenis_layanan'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Jenis Layanan'}
                placeholder={'Pembiayaan/Simpanan Lainnya'}
                required
              />
              <InputForm
                controllerName={'nama_layanan'}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Nama Layanan'}
                placeholder={'Nama Layanan'}
                required
              />
              <InputCurrency
                controllerName={`pendapatan`}
                wrapperClass={'col-span-1'}
                label={'Pendapatan'}
                placeholder={'Pendapatan'}
                required
              />
              <InputForm
                controllerName={`keterangan`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={'Keterangan'}
                placeholder={'Keterangan'}
                required
              />
            </div>
          </div>
          <div className="border-b" />
          <div className="flex flex-row-reverse my-5 mx-4 ">
            <Button
              type="button"
              label={
                <div className="flex items-center gap-2 font-[14px]">
                  <RiAddLine />
                  Tambah Data Lain
                </div>
              }
              onClick={onFormSubmit()}
            />
          </div>
        </CardForm>
      </div>
    </FormProvider>
  );
};

const tableHeaders = [
  {
    key: 'no',
    label: 'No',
  },
  {
    key: 'jenis_layanan',
    label: 'Jenis Layanan',
  },
  {
    key: 'nama_layanan',
    label: 'Nama Layanan',
  },
  {
    key: 'pendapatan',
    label: 'Pendapatan',
  },
  {
    key: 'keterangan',
    label: 'Keterangan',
  },
  {
    key: 'action',
    label: 'Aksi',
  },
];

const ServiceTable = ({ onRemove }) => {
  const { control } = useFormContext();
  const fields = useWatch({
    control,
    name: 'layanan',
  });

  return (
    <table
      className="w-full bg-white border border-gray-200 rounded-lg"
      style={{ boxShadow: '0px 20px 40px 0px rgba(51, 40, 123, 0.04)' }}
    >
      <thead className="bg-primary-800 text-white rounded-t-lg">
        <tr className="rounded-t-lg">
          {tableHeaders.map((header, index) => (
            <td
              key={header.key}
              className={[
                'text-xs px-6 py-2 font-semibold',
                index === 0 ? 'rounded-tl-lg' : '',
                index === tableHeaders.length - 1
                  ? 'rounded-tr-lg text-center'
                  : '',
                header.className || '',
              ].join(' ')}
            >
              {header.label}
            </td>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y">
        {fields?.length < 1 && (
          <tr>
            <td
              colSpan={tableHeaders.length}
              className="px-6 rounded-lg py-3 text-center"
            >
              <div className="text-gray-500 text-sm">Belum ada data</div>
            </td>
          </tr>
        )}
        {map(fields, (field, key) => (
          <tr key={key + 1}>
            <td className="px-6 py-3 text-gray-600 text-sm">{key + 1}</td>
            <td className="px-6 py-3 text-gray-600 text-sm">
              {field.jenis_layanan}
            </td>
            <td className="px-6 py-3 text-gray-600 text-sm">
              {field.nama_layanan}
            </td>
            <td className="px-6 py-3 text-gray-600 text-sm">
              {field.pendapatan}
            </td>
            <td className="px-6 py-3 text-gray-600 text-sm">
              {field.keterangan}
            </td>
            <td colSpan={1} className="px-6 py-3 text-center">
              <div className="flex justify-center">
                <Button
                  type="button"
                  color="error"
                  size="sm"
                  label={<RiDeleteBinLine className="text-current text-xl" />}
                  onClick={() => onRemove(key)}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Layanan;
