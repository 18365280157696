import { BottomFormAction, ConfirmationModal, StepProgress } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Information from "../components/PerhutananSosial/information";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  groupRegisterSchemePS1,
  groupRegisterSchemePS2,
} from "./validation-scheme";
import Document from "../components/PerhutananSosial/document";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getFormulirPendaftaran,
  submitFormulirPendaftaran,
} from "services/fdb/perhutananSosial/registerForm";
import { createPortal } from "react-dom";
import ProgressModal from "components/molecules/Modal/ProgressModal";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import dayjs from "dayjs";

const tabs = [
  {
    label: "Informasi Kelompok",
    step: "information",
  },
  {
    label: "Dokumen Legalitas",
    step: "document",
  },
];

const FormulirPendaftaranPS = () => {
  const breadcumbs = useMemo(
    () => [
      {
        label: "Formulir Pendaftaran",
        path: "/kelompok/perhutanan-sosial/formulir-pendaftaran",
      },
      {
        label: "Pendaftaran Kelompok",
        path: "/kelompok/perhutanan-sosial/formulir-pendaftaran",
      },
      {
        label: "Formulir Permohonan Kelompok",
        // path: location.pathname,
      },
    ],
    []
  );

  // ================

  const navigate = useNavigate();

  const [step, setStep] = useState("information");
  const [draftModal, setDraftModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  // ==================

  const methods = useForm({
    resolver:
      step === "information"
        ? yupResolver(groupRegisterSchemePS1)
        : yupResolver(groupRegisterSchemePS2),
  });

  // ==============================

  const queryClient = useQueryClient();
  const mutateSubmitFormulir = useMutation(
    ["SUBMIT_FORMULIR_PENDAFTARAN"],
    submitFormulirPendaftaran
  );

  useQuery(["GET_DETAIL_PERHUTANAN_SOSIAL"], getFormulirPendaftaran, {
    select: (data) => data?.data?.data,
    onSuccess: (data) => {
      methods.reset({
        agree: false,
        created_at_display: dayjs(new Date()).format("YYYY-MM-YY"),
        name: data?.name,
        sk_number: data?.sk_number,
        submit_type: "",
        kups_name: data?.kups_name,
        kups_number: data?.kups_number,
        founding_date: data?.founding_date
          ? new Date(data?.founding_date)
          : null,
        social_area_function_id: data?.social_area_function_id,
        province_id: data?.province_id,
        city_id: data?.city_id,
        district_id: data?.district_id,
        village_id: data?.village_id,
        zip_code_id: data?.zip_code_id,
        address: data?.address,
        leader_name: data?.leader_name,
        leader_phone: data?.leader_phone,
        leader_identity: data?.leader_identity,
        leader_gender: data?.leader_gender,
        secretary_name: data?.secretary_name,
        secretary_phone: data?.secretary_phone,
        secretary_gender: data?.secretary_gender,
        treasurer_name: data?.treasurer_name,
        treasurer_phone: data?.treasurer_phone,
        treasurer_gender: data?.treasurer_gender,
        companion_name: data?.companion_name,
        companion_affiliate_id: data?.companion_affiliate_id,
        companion_phone: data?.companion_phone,
        companion_gender: data?.companion_gender,
        end_date_management: data?.end_date_management,
        end_date_permission: data?.end_date_permission,
        end_date_confirmation: data?.end_date_confirmation,
        end_date_legality: data?.end_date_legality,
        total_member_not_apply: data?.total_member_not_apply,
        total_member_apply: data?.total_member_apply,
        total_member_apply_persil: data?.total_member_apply_persil,
        total_member_apply_area: data?.total_member_apply_area,
        activities:
          data.activities.length > 0
            ? data?.activities.map((activity) => ({
                id: activity.id,
                activity_category_id: activity.activity_category_id,
                description: activity.description,
              }))
            : [
                {
                  id: null,
                  activity_category_id: "",
                  description: "",
                },
              ],
        partners:
          data?.partners.length > 0
            ? data?.partners.map((partner) => ({
                id: partner.id,
                name: partner.name,
              }))
            : [{ id: null, name: "" }],
        finance_businesses:
          data?.finance_businesses.length > 0
            ? data?.finance_businesses.map((finance) => ({
                id: finance.id,
                finance_business_category_id:
                  finance.finance_business_category_id,
                description: finance.description,
              }))
            : [
                {
                  id: null,
                  finance_business_category_id: "",
                  description: "",
                },
              ],
        documents:
          data?.documents.length > 0
            ? data?.documents.map((document) => ({
                id: document.id,
                name: document.name,
                doc_id: document.doc_id,
                type: document.type,
              }))
            : [
                {
                  id: null,
                  name: "Dokumen SK Pembentukan / Berita Acara",
                  doc_id: null,
                  selected_file: undefined,
                  type: "sk",
                },
              ],
        created_from: data?.created_from,
      });
    },
    onError: (err) => console.log(err),
  });

  const mapPayload = (submitType = "draft") => {
    const data = methods.getValues();

    const payload = {
      ...data,
      submit_type: submitType,
      founding_date: data?.founding_date
        ? dayjs(data?.founding_date).format("YYYY-MM-DD")
        : null,
      activities: data.activities.filter(
        (activity) => activity.activity_category_id !== ""
      ),
      partners: data.partners.filter((partner) => partner.name !== ""),
      finance_businesses: data.finance_businesses.filter(
        (finance) => finance.finance_business_category_id !== ""
      ),
      documents: data.documents.map((e) => ({
        ...e,
        doc_id: e?.selected_file?.id,
      })),
    };

    return payload;
  };

  // ? For floating action button
  const handleClickSubmit = (data) => {
    if (step === "information") {
      const payload = mapPayload();

      const data = {
        ...payload,
        kups_name: payload.kups_name === "" ? "-" : payload.kups_name,
        kups_number: payload.kups_number === "" ? "-" : payload.kups_number,
      };

      mutateSubmitFormulir.mutate(data, {
        onSuccess: () => {
          queryClient.invalidateQueries(["GET_DETAIL_PERHUTANAN_SOSIAL"]);
        },
      });

      setStep("document");
      return;
    }

    setConfirmModal(true);
  };

  const handleClickBack = () => {
    if (step === "information") {
      navigate(-1);
      return;
    }

    navigate(0);
  };

  const handleDraft = () => {
    const payload = mapPayload();

    const data = {
      ...payload,
      kups_name: payload.kups_name === "" ? "-" : payload.kups_name,
      kups_number: payload.kups_number === "" ? "-" : payload.kups_number,
    };

    mutateSubmitFormulir.mutate(data, {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Berhasil simpan draft",
        });
        queryClient.invalidateQueries();
        navigate(-1);
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Gagal simpan draft",
        });
      },
    });
  };

  const handleSubmit = () => {
    const payload = mapPayload("submit");

    mutateSubmitFormulir.mutate(payload, {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Berhasil submit formulir",
        });
        queryClient.invalidateQueries();
        navigate(
          "/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan/add",
          { replace: true }
        );
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Gagal submit formulir",
        });
      },
    });
  };

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={handleSubmit}
          onCancel={() => setDraftModal(false)}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onClose={setDraftModal}
          onSubmit={handleDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan simpan draft data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {/* {createPortal(
        <ProgressModal
          open={mutateSubmitFormulir.isLoading}
          className="space-y-2 mb-4"
        />,
        document.body
      )} */}

      <div className="space-y-5 bg-white p-10 rounded-3xl border">
        <BreadCrumbs routes={breadcumbs} />

        <div className="flex flex-col gap-1">
          <h2 className="text-3xl font-medium">Formulir Permohonan Kelompok</h2>
          <p className="text-gray-600">Buat permohonan baru.</p>
        </div>

        <hr />

        <div className="w-full flex justify-center">
          <div className="w-[800px] mb-10">
            <StepProgress stepProgressData={tabs} activeStep={step} />
          </div>
        </div>

        <FormProvider {...methods}>
          {step === "information" ? <Information /> : <Document />}
        </FormProvider>

        <BottomFormAction
          lastStep={step !== "information"}
          // hideSubmit={isView}
          submitButtonProps={{
            type: "submit",
          }}
          backButtonProps={{
            type: "button",
          }}
          backButtonAction={handleClickBack}
          disableDrafButton={false}
          // hideDraft={isView}
          draftButtonProps={{
            type: "button",
          }}
          submitActionButton={methods.handleSubmit(handleClickSubmit)}
          drafButtonAction={() => setDraftModal(true)}
          disableButtonSubmit={false}
        />
      </div>
    </>
  );
};

export default FormulirPendaftaranPS;
