import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  npwp_personal: Yup.string()
    .label("NPWP")
    .matches(/^[0-9]*$/, "No. NPWP hanya boleh diisi menggunakan angka")
    .max(16, "No. NPWP wajib terdiri dari 16 digit")
    .required("NPWP harus diisi"),
  npwp_company: Yup.mixed(),
  npwp_address: Yup.string().required("Alamat NPWP harus diisi"),
  url_file_skt: Yup.mixed(),
  no_certificate_domicile: Yup.string().required(
    "Nomor Surat Keterangan Domisili harus diisi"
  ),
  expire_date: Yup.date().required("Tanggal Kadaluarsa harus diisi"),
  is_pkp: Yup.boolean().required("PKP Status harus diisi"),
  is_tax_collectors: Yup.boolean().required(
    "Status Pemungut Pajak harus diisi"
  ),
  url_file_tax_collectors: Yup.mixed().when("is_tax_collectors", {
    is: true,
    then: () => Yup.mixed(),
    otherwise: () => Yup.mixed(),
  }),
  sktPKP: Yup.string().when("radioPkpStatus", {
    is: true,
    then: () => Yup.string().required("SKT PKP harus diisi"),
    otherwise: () => Yup.string(),
  }),
  pkpExpiredDate: Yup.date().when("radioPkpStatus", {
    is: true,
    then: () => Yup.date(),
    otherwise: () => Yup.date(),
  }),
  radioFreeFacility: Yup.boolean().when("radioPkpStatus", {
    is: true,
    then: () => Yup.boolean().required("Free Facility Status harus diisi"),
    otherwise: () => Yup.boolean(),
  }),
  reportNumberFirstMonth: Yup.string().when("radioPkpStatus", {
    is: true,
    then: () =>
      Yup.string().required("Nomor Laporan Bulan Pertama harus diisi"),
    otherwise: () => Yup.string(),
  }),
  otherDataDescFirstMonth: Yup.string().when("radioPkpStatus", {
    is: true,
    then: () => Yup.string().required("Deskripsi Bulan Pertama harus diisi"),
    otherwise: () => Yup.string(),
  }),
  reportNumberSecondMonth: Yup.string().when("radioPkpStatus", {
    is: true,
    then: () => Yup.string().required("Nomor Laporan Bulan Kedua harus diisi"),
    otherwise: () => Yup.string(),
  }),
  otherDataDescSecondMonth: Yup.string().when("radioPkpStatus", {
    is: true,
    then: () => Yup.string().required("Deskripsi Bulan Kedua harus diisi"),
    otherwise: () => Yup.string(),
  }),

  reportNumberThirdMonth: Yup.string().when("radioPkpStatus", {
    is: true,
    then: () => Yup.string().required("Nomor Laporan Bulan Ketiga harus diisi"),
    otherwise: () => Yup.string(),
  }),
  otherDataDescThirdMonth: Yup.string().when("radioPkpStatus", {
    is: true,
    then: () => Yup.string().required("Deskripsi Bulan Ketiga harus diisi"),
    otherwise: () => Yup.string(),
  }),

  pphNumberFirstMonth: Yup.string().when("is_income_cut", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{1,50}$/, "Nomor Laporan wajib diisi dengan angka")
        .required("Nomor laporan wajib diisi"),
    otherwise: () =>
      Yup.string().matches(/^\d{1,50}$/, "Form wajib diisi dengan angka"),
  }),
  pphDescFirstMonth: Yup.string().when("is_income_cut", {
    is: true,
    then: () => Yup.string().required("Deskripsi wajib diisi"),
    otherwise: () => Yup.string(),
  }),
  pphFileFirstMonth: Yup.mixed(),
  pphNumberSecondMonth: Yup.string().when("is_income_cut", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{1,50}$/, "Form wajib diisi dengan angka")
        .required("Nomor laporan wajib diisi"),
    otherwise: () =>
      Yup.string().matches(/^\d{1,50}$/, "Form wajib diisi dengan angka"),
  }),
  pphDescSecondMonth: Yup.string().when("is_income_cut", {
    is: true,
    then: () => Yup.string().required("Deskripsi wajib diisi"),
    otherwise: () => Yup.string(),
  }),
  pphFileSecondMonth: Yup.mixed(),
  pphNumberThirdMonth: Yup.string().when("is_income_cut", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{1,50}$/, "Form wajib diisi dengan angka")
        .required("Nomor laporan wajib diisi"),
    otherwise: () =>
      Yup.string().matches(/^\d{1,50}$/, "Form wajib diisi dengan angka"),
  }),
  pphDescThirdMonth: Yup.string().when("is_income_cut", {
    is: true,
    then: () => Yup.string().required("Deskripsi wajib diisi"),
    otherwise: () => Yup.string(),
  }),
  pphFileThirdMonth: Yup.mixed().when("is_income_cut", {
    is: true,
    then: () => Yup.mixed(),
    otherwise: () => Yup.mixed(),
  }),
  is_tax_activity: Yup.boolean().required("field wajib diisi"),
  firstSptYear: Yup.string().when("is_tax_activity", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{4}$/, "format tahun tidak valid")
        .required("Tahun wajib diisi"),
    otherwise: () => Yup.string(),
  }),
  url_file_tax_activity: Yup.string().when("is_tax_activity", {
    is: true,
    then: () => Yup.mixed(),
    otherwise: () => Yup.mixed(),
  }),
  firstSptNumber: Yup.string().when("is_tax_activity", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{1,50}$/, "Form wajib diisi dengan angka")
        .required(
          "Nomor Tanda Terima Elektronik pelaporan SPT Tahunan wajib diisi"
        ),
    otherwise: () =>
      Yup.string().matches(/^\d{1,50}$/, "Form wajib diisi dengan angka"),
  }),
  firstSptDate: Yup.string().when("is_tax_activity", {
    is: true,
    then: () => Yup.string().required("Tanggal wajib diisi"),
    otherwise: () => Yup.string(),
  }),
  firstSptDesc: Yup.string().when("is_tax_activity", {
    is: true,
    then: () => Yup.string().required("Deskripsi wajib diisi"),
    otherwise: () => Yup.string(),
  }),
  firstSptFile: Yup.mixed().when("is_tax_activity", {
    is: true,
    then: () => Yup.mixed().required("File wajib diisi"),
    otherwise: () => Yup.mixed(),
  }),

  secondSptYear: Yup.string().when("is_tax_activity", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{4}$/, "format tahun tidak valid")
        .required("Tahun wajib diisi"),
    otherwise: () => Yup.string(),
  }),
  secondSptNumber: Yup.string().when("is_tax_activity", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{1,50}$/, "Form wajib diisi dengan angka")
        .required(
          "Nomor Tanda Terima Elektronik pelaporan SPT Tahunan wajib diisi"
        ),
    otherwise: () =>
      Yup.string().matches(/^\d{1,50}$/, "Form wajib diisi dengan angka"),
  }),
  secondSptDate: Yup.string().when("is_tax_activity", {
    is: true,
    then: () => Yup.string().required("Tanggal wajib diisi"),
    otherwise: () => Yup.string(),
  }),
  secondSptDesc: Yup.string().when("is_tax_activity", {
    is: true,
    then: () => Yup.string().required("Deskripsi wajib diisi"),
    otherwise: () => Yup.string(),
  }),
  secondSptFile: Yup.mixed().when("is_tax_activity", {
    is: true,
    then: () => Yup.mixed().required("File wajib diisi"),
    otherwise: () => Yup.mixed(),
  }),
});

export default validationSchema;
