import React from "react";
import * as Yup from "yup";
import { InputForm } from "../../../../../components";
import CardForm from "../../../../../components/molecules/CardForm";

export const FieldLabel = {
  GROUP_NAME: "Nama Kelompok"
};

export const validationSchema = Yup.object().shape({
  group_name: Yup.string().trim().notRequired().label(FieldLabel.GROUP_NAME)
});

function FormulirSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(e => e === mode);
  
  return (
    <CardForm label="Formulir" {...rest}>
      <div className="grid grid-cols-1 gap-4">
        <InputForm
          controllerName={`${prefix}group_name`}
          className={`py-2 px-4 mt-2 border w-full rounded-md col-span-1`}
          label={FieldLabel.GROUP_NAME}
          placeholder={FieldLabel.GROUP_NAME}
          required
          readOnly
          disabled
          asText={isView}
        />
      </div>
    </CardForm>
  );
}

FormulirSection.propTypes = {};

export default FormulirSection;