import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Routes as WrapperRoutes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ToastProvider } from 'components/atoms/Toast';

import route from './route';

import My404Component from 'pages/My404Component';

import { BasicLayout, Spinner } from 'components';
import { AuthConsumer } from 'providers/authProvider';
import { SigninCallback } from '../components/auth/signinCallback';
import { SignoutCallback } from '../components/auth/signoutCallback';
import { SilentRenew } from '../components/auth/silentRenew';
import {
  updateMasterData,
  updateRegionDraftData,
  saveUserDraftData,
} from 'app/actions';
import {
  getCityService,
  getCountryService,
  getDistrictService,
  getPostCodeService,
  getProvinceService,
  getWardService,
} from 'services/masterData/region';
import { useMutation } from '@tanstack/react-query';
import {
  getCollaborationFields,
  getInstitutionTypes,
  getIssueFocuses,
  getStrategicPlans,
} from 'services/danaProgram/masterDataService';
import session from 'redux-persist/lib/storage/session';
import Logout from 'components/auth/logout';

const Routes = () => {
  const { userId } = useSelector(({ auth }) => auth);
  const userType = useSelector((state) =>
    state?.auth?.user?.TypeUser?.replace(/\["(.*?)"\]/, '$1')
  );

  useEffect(() => {
    console.log(userType);
  }, [userType]);

  return (
    <Router>
      <ToastProvider>
        <WrapperRoutes>
          <Route path="/signin-oidc" element={<SigninCallback />} />
          <Route path="/signout-oidc" element={<SignoutCallback />} />
          <Route exact={true} path="/logout" element={<Logout />} />
          <Route path="/silentrenew" component={<SilentRenew />} />
          {_.map(_.filter(route, { routeType: 'public' }), (val, key) => {
            const Component = val.element;
            return (
              <Route
                exact={true}
                key={key}
                path={val.path}
                element={<Component />}
              />
            );
          })}
          {_.map(_.filter(route, { routeType: 'private' }), (val, key) => {
            const Component = val.element;
            return (
              <Route
                exact={true}
                key={key}
                path={val.path}
                element={
                  <AuthConsumer>
                    {({ isAuthenticated, logout, signinRedirect }) => {
                      console.log(userType);
                      if (
                        (isAuthenticated() &&
                          (userType === 'LEMBAGA' ||
                            userType?.includes('FDB_LANGSUNG_PERORANGAN') ||
                            userType?.includes('FDB_TIDAK_LANGSUNG') ||
                            userType?.includes(
                              'FDB_LANGSUNG_NON_PERORANGAN'
                            ))) ||
                        userType?.includes('Beneficiaries')
                      ) {
                        return (
                          <BasicLayout
                            sidebar={val?.sidebar}
                            props={val?.sidebarProps}
                          >
                            <Component />
                          </BasicLayout>
                        );
                      } else {
                        if (!userType) {
                          signinRedirect();
                          return (
                            <div className="w-screen h-screen flex justify-center items-center">
                              <Spinner />
                            </div>
                          );
                        } else {
                          return (
                            <div className="w-screen h-screen flex justify-center items-center p-5 rounded-lg bg-white gap-3">
                              <div className="flex gap-2 border-r-[1px] pr-3">
                                <h1 className="font-extrabold">401</h1>
                                <p>Unauthorized</p>
                              </div>
                              <div>
                                <button
                                  className="hover:underline"
                                  onClick={logout}
                                >
                                  Logout
                                </button>
                              </div>
                            </div>
                          );
                        }
                      }
                    }}
                  </AuthConsumer>
                }
              />
            );
          })}
          <Route path="*" element={<My404Component />} />
        </WrapperRoutes>
      </ToastProvider>
    </Router>
  );
};

export default Routes;
