import React from "react";
import { InputForm } from "components";

const ContactPersonForm = () => {
  return (
    <div className="py-4">
      <div className="mb-4 font-semibold text-primary-700">
        Informasi Narahubung
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputForm
          controllerName={"contact_person.name"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          label={"Nama Narahubung"}
          placeholder={"Nama Narahubung"}
          required
        />
        <InputForm
          controllerName={"contact_person.position"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          label={"Jabatan"}
          placeholder={"Jabatan"}
          required
        />
        <InputForm
          controllerName={"contact_person.ktp"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          label={"NIK Narahubung"}
          placeholder={"NIK Narahubung"}
          required
          mask="################"
        />
        <InputForm
          controllerName={"contact_person.phone_number"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          label={"Telepon"}
          placeholder={"Telepon"}
          required
          mask="0"
          data-maska-tokens="0:\d:multiple"
        />
        <div className="col-span-2">
          <InputForm
            controllerName={"contact_person.email"}
            className={`py-2 px-4 mt-2 border w-full rounded-md`}
            label={"Email Narahubung"}
            placeholder={"Email Narahubung"}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default ContactPersonForm;
