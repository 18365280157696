import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { getListProject } from "services/report/progress";

const useReportProgressForm = () => {

  const {
    data: projectListData,
    isLoading: isLoadingProjectListData,
    isFetching: isFetchingProjectListData,
    refetch: refetchProjectListData,
  } = useQueryListProject();

  return {
    projectListData: projectListData?.data?.data || [],
    isLoadingProjectListData,
    isFetchingProjectListData,
    refetchProjectListData,
  };
}

export const useQueryListProject = () => {
  return useQuery({
    queryKey: ['list-project-report-progress'],
    queryFn: () => getListProject(),
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: 'error',
      })
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })
}

export default useReportProgressForm