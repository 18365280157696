import NonPeroranganRegister from '../pages/FDB/NonPerorangan/Register/index';
import NonPeroranganRequests from '../pages/FDB/NonPerorangan/Requests/index';
import NonPeroranganDashboard from '../pages/FDB/NonPerorangan/Dashboard/index';
import NonPeroranganProfileUmum from '../pages/FDB/NonPerorangan/ProfileUmum';
import FinancingRequest from 'pages/FDB/FinancingRequest';

const NonPeroranganProfileUmumComp = (props) => () =>
  <NonPeroranganProfileUmum {...props} />;
// const KeuanganComp = (props) => () => <Keuangan {...props} />;
const NonPeroranganComp = (props) => () => <NonPeroranganDashboard {...props} />;
// const KelembagaanComp = (props) => () => <Kelembagaan {...props} />;
const FinancingRequestComp = (props) => () => <FinancingRequest {...props} />

const PageTypeLembaga = {
  bumdes: 'bumdes',
  cv: 'cv',
  pt: 'pt',
  firma: 'firma'
};

const noPeroranganRoute = [
  {
    routeType: 'public',
    path: '/non-perorangan/register',
    element: NonPeroranganRegister,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/requests',
    element: NonPeroranganRequests,
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/bumdes',
    element: NonPeroranganComp({ type: PageTypeLembaga.bumdes }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/cv',
    element: NonPeroranganComp({ type: PageTypeLembaga.cv }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/pt',
    element: NonPeroranganComp({ type: PageTypeLembaga.pt }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/firma',
    element: NonPeroranganComp({ type: PageTypeLembaga.firma }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/bumdes/profile-umum',
    element: NonPeroranganProfileUmumComp({ type: PageTypeLembaga.bumdes }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/cv/profile-umum',
    element: NonPeroranganProfileUmumComp({ type: PageTypeLembaga.cv }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/pt/profile-umum',
    element: NonPeroranganProfileUmumComp({ type: PageTypeLembaga.pt }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/firma/profile-umum',
    element: NonPeroranganProfileUmumComp({ type: PageTypeLembaga.firma }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: 'private',
    path: '/non-perorangan/financing-request',
    element: FinancingRequestComp({ type: PageTypeLembaga.firma }),
    exact: true,
    sidebar: true,
  },
];

export default noPeroranganRoute;
