import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { Button, HeaderNoAuth, InputForm } from "components";
import { motion } from "framer-motion";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AtomSelect from "components/atoms/Select";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import {
  isMatchField,
  isPhoneValid,
  isPasswordValid,
} from "helpers/validation";
import RadioGroup from "components/molecules/RadioGroup";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { registerChannelingDirect } from "services";
import SelectFDBCountry from "components/organisms/inputs/SelectFDBCountry";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";
import { registerChannelingAgency } from "services/fdb/lembagaPenyalur";
import InputMaskForm from "components/V2/shared/input-mask-form";
import { map } from "lodash";

const lembagaPenyalurOptions = [
  {
    value: "3",
    label: "BUMDES",
    color: {
      outer: "bg-bluelight-50",
      inner: "bg-bluelight-100",
      text: "text-bluelight-600",
    },
    description: "Lorem ipsum dolor sit amet",
  },
  {
    value: "2",
    label: "BUMD",
    color: {
      outer: "bg-purple-50",
      inner: "bg-purple-100",
      text: "text-purple-600",
    },
    description: "Lorem ipsum dolor sit amet",
  },
  {
    value: "1",
    label: "BUMN",
    color: {
      outer: "bg-green-50",
      inner: "bg-green-100",
      text: "text-green-600",
    },
    description: "Lorem ipsum dolor sit amet",
  },
  {
    value: "4",
    label: "Badan Usaha Swasta",
    color: {
      outer: "bg-pink-50",
      inner: "bg-pink-100",
      text: "text-pink-600",
    },
    description: "Lorem ipsum dolor sit amet",
  },
];

const RegisterNonPeroranganBUMD = ({ children, sidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showAgency, setShowAgency] = useState(false);

  const validationSchema = useMemo(() => {
    return yup.object({
      name: yup
        .string()
        .trim()
        .required(`nama lembaga wajib diisi`)
        .label("Nama Lembaga")
        .matches(
          /^[a-zA-Z\s]*$/,
          "nama hanya boleh menggunakan huruf dan spasi"
        )
        .min(2, "nama lembaga minimal 2 karakter")
        .max(50, "nama lembaga maksimal 50 karakter"),
      category: yup
        .mixed()
        .nonNullable()
        .label("kategori penyalur")
        .required("kategori penyalur harus diisi"),
      certificate_number: yup
        .string()
        .required(`nomor akta wajib diisi`)
        .min(2, "nomor akta minimal 2 karakter")
        .max(50, "nomor akta maksimal 50 karakter"),
      npwp: yup
        .string()
        .required(`nomor npwp wajib diisi`)
        .label("NPWP")
        .min(20, `No. NPWP minmal 15 digit`),
      // country: yup
      //   .mixed()
      //   .nonNullable()
      //   .label("Negara")
      //   .required("negara wajib diisi"),
      province_id: yup
        .mixed()
        .nonNullable()
        .label("Provinsi")
        .required("provinsi wajib diisi"),
      city_id: yup
        .mixed()
        .nonNullable()
        .label("Kota / Kabupaten")
        .required("provinsi wajib diisi"),
      district_id: yup
        .mixed()
        .nonNullable()
        .label("Kecamatan")
        .required("kelurahan wajib diisi"),
      village_id: yup
        .mixed()
        .nonNullable()
        .label("Kelurahan")
        .required("kecamatan wajib diisi"),
      zip_code_id: yup.mixed().required("kode pos wajib diisi"),
      address: yup
        .string()
        .required(`alamat wajib diisi`)
        .max(250, "alamat maksimal 250 karakter"),
      contact_person_name: yup
        .string()
        .required(`nama narahubung wajib diisi`)
        .matches(
          /^[a-zA-Z\s]*$/,
          "nama hanya boleh menggunakan huruf dan spasi"
        )
        .min(2, "nama lembaga minimal 2 karakter")
        .max(50, "nama lembaga maksimal 50 karakter"),
      contact_person_position: yup
        .string()
        .required(`jabatan wajib diisi`)
        .min(2, "jabatan minimal 2 karakter")
        .max(50, "jabatan maksimal 50 karakter"),
      contact_person_phone: yup
        .string()
        .trim()
        .test(
          "contact_person.contact_person_phone",
          ({ label }) =>
            validationErrorMsg.mixed.default.replace("${path}", label),
          isPhoneValid
        )
        .required("No. Telepon wajib diisi")
        .label("Telepon"),
      email: yup
        .string()
        .trim()
        .email()
        .required()
        .label("Email Narahubung")
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Email Narahubung bukan email yang valid"
        ),
      password: yup
        .string()
        .test("password", validationErrorMsg.mixed.required, isPasswordValid)
        .required()
        .label("Kata Sandi"),
      password_confirmation: yup
        .string()
        .when(["password"], ([password]) => {
          if (password) {
            return yup
              .string()
              .test(
                "confpass",
                validationCustomErrorMsg.matchesField("Kata Sandi"),
                isMatchField("password")
              )
              .required()
              .label("Konfirmasi Kata Sandi");
          }

          return yup.string().required().label("Konfirmasi Kata Sandi");
        })
        .required()
        .label("Konfirmasi Kata Sandi"),
      distributor_institution: yup.string().when(["category"], ([category]) => {
        if (category?.value !== "1") {
          return yup.string().required().label("Lembaga Penyalur");
        }
      }),
      institution_type: yup
        .string()
        .when(
          ["category", "distributor_institution"],
          ([category, distributor_institution]) => {
            if (category?.value !== "1" && distributor_institution !== "3") {
              // 3 = BUMDES
              return yup.string().required().label("Jenis Lembaga");
            }
          }
        ),

      // lembagaFocusIssue: yup
      //   .array()
      //   .required(({ label }) => `${label} wajib diisi`)
      //   .min(1, ({ label }) => `${label} wajib diisi`)
      //   .label('Fokus isu atau Intervansi Lembaga'),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { watch, setValue } = methods;
  const categoryValue = watch("category");
  // Reset agency value when category is "Koperasi"
  // useEffect(() => {
  //   if (categoryValue?.label === "Koperasi") {
  //     setValue("distributor_institution", "");
  //   }
  // }, [categoryValue]);

  const provinceId = useWatch({
    control: methods.control,
    name: "province_id",
  });
  const cityId = useWatch({
    control: methods.control,
    name: "city_id",
  });
  const districtId = useWatch({
    control: methods.control,
    name: "district_id",
  });
  const villageId = useWatch({
    control: methods.control,
    name: "village_id",
  });
  const onError = useCallback((error) => {
    enqueueSnackbar({
      message: getErrorMessage(error),
      variant: "error",
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar({
      message: "Data berhasil disimpan",
      variant: "success",
    });
    navigate("/");
  }, [navigate]);

  const mutation = useMutation(
    ["REGISTER_CHANNELING_AGENCY"],
    (params) => registerChannelingAgency(params),
    {
      onError,
      onSuccess,
    }
  );

  useEffect(() => {
    if (
      watch("category.value") !== "1" &&
      watch("category.value") !== undefined
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [watch("category")]);

  useEffect(() => {
    // 3 = BUMDES
    if (
      watch("distributor_institution") !== "3" &&
      watch("distributor_institution") !== undefined
    )
      setShowAgency(true);
    else setShowAgency(false);
  }, [watch("distributor_institution")]);

  const onSubmit = () => {
    const payload = methods.getValues();

    const mapPayload = {
      payload: {
        ...payload,
        category: payload?.category ? payload.category?.value : null,
      },
      query: "Non Perorangan",
    };

    if (payload.category.value !== 1)
      mapPayload.payload.distributor_institution =
        payload?.distributor_institution
          ? payload?.distributor_institution
          : null;

    mutation.mutate(mapPayload);
  };

  return (
    <div className="w-full bg-gray-25">
      <div className="flex flex-col h-screen">
        <HeaderNoAuth />
        <div className="md:px-28 flex flex-row flex-1 py-20">
          <div className="flex-1 ">
            <motion.main
              className="flex-1 paragraph pl-10 mb-4"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
              <FormProvider {...methods}>
                {/* <form onSubmit={methods.handleSubmit(onSubmit)}> */}
                <div className="w-full space-y-4">
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col">
                      <label className="font-semibold text-[36px]">
                        Daftar Akun Penyalur Non Perorangan
                      </label>
                      <label className="text-[#475467]">
                        Silahkan lengkapi data-data di bawah ini untuk Anda bisa
                        mendapatkan Akun.
                      </label>
                    </div>

                    <div className="flex flex-col gap-5 py-1">
                      <div className="py-1">
                        <label className="font-semibold text-[#01A24A]">
                          General Informasi
                        </label>
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"name"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nama Lembaga"}
                          placeholder={"Nama Penyalur"}
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <AtomSelect
                          controllerName={"category"}
                          options={[
                            // { value: "1", label: "Koperasi" },
                            { value: "2", label: "Non Koperasi" },
                          ]}
                          className="basic-single mt-2"
                          label="Kategori Penyalur"
                          placeholder={"Koperasi / Non Koperasi"}
                          required={true}
                          customOnChange={(e) => {
                            methods.setValue("distributor_institution", "2");
                          }}
                        />
                      </div>
                      {show && (
                        <RadioGroup
                          options={lembagaPenyalurOptions}
                          label="Pilih Lembaga Penyalur"
                          controllerName="distributor_institution"
                          required
                        />
                      )}
                      {show && showAgency && (
                        <div className="flex gap-4">
                          <AtomSelect
                            controllerName={"institution_type"}
                            options={[
                              { value: "1", label: "CV" },
                              { value: "2", label: "PT" },
                              { value: "3", label: "Firma" },
                            ]}
                            className="basic-single mt-2"
                            label="Jenis Lembaga"
                            placeholder={"CV/PT/Firma"}
                            returnObject={false}
                            required={true}
                          />
                        </div>
                      )}
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"certificate_number"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={
                            "Nomor Akta Pendirian/SK Kemenkumham/Surat keterangan lainnya yang setara"
                          }
                          placeholder={
                            "Nomor Akta Pendirian/SK Kemenkumham/Surat keterangan lainnya yang setara"
                          }
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <InputMaskForm
                          label="NPWP"
                          name={`npwp`}
                          placeholder="NPWP"
                          mask="##.###.###.#-###.###"
                          replacement={{ "#": /\d/ }}
                          required
                        />
                      </div>
                      <div className="flex gap-4">
                        <AtomSelect
                          controllerName={"country"}
                          options={[
                            {
                              label: "Indonesia",
                              value: "indonesia",
                            },
                          ]}
                          className="basic-single mt-2"
                          label="Negara"
                          placeholder={"Negara"}
                          required={true}
                          defaultValue={{
                            label: "Indonesia",
                            value: "indonesia",
                          }}
                        />
                        {/* <SelectFDBCountry
                          controllerName={"country"}
                          className="basic-single mt-2"
                          label="Negara"
                          returnObject={false}
                          required={true}
                        /> */}
                        <SelectFDBProvince
                          controllerName={"province_id"}
                          className="basic-single mt-2"
                          label="Provinsi"
                          returnObject={false}
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <SelectFDBCity
                          controllerName={"city_id"}
                          label={"Kota / Kabupaten"}
                          placeholder={"Kota / Kabupaten"}
                          required
                          returnObject={false}
                          provinceId={provinceId}
                          // asText={isView}
                        />
                        <SelectFDBDistrict
                          controllerName={"district_id"}
                          label={"Kecamatan"}
                          placeholder={"Kecamatan"}
                          required
                          returnObject={false}
                          cityId={cityId}
                          // asText={isView}
                        />
                      </div>
                      <div className="flex gap-4">
                        <SelectFDBVillage
                          controllerName={"village_id"}
                          label={"Kelurahan"}
                          placeholder={"Kelurahan"}
                          required
                          returnObject={false}
                          districtId={districtId}
                          // asText={isView}
                        />
                        <SelectFDBZipCode
                          controllerName={"zip_code_id"}
                          label={"Kode Pos"}
                          placeholder={"Kode Pos"}
                          required
                          returnObject={false}
                          villageId={villageId}
                          // asText={isView}
                        />
                      </div>
                      <div className="flex">
                        <InputForm
                          controllerName={`address`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Alamat"}
                          placeholder={"alamat"}
                          textArea={true}
                          required
                        />
                      </div>
                    </div>

                    <div className="border-b" />

                    <div className="flex flex-col gap-5 py-1">
                      <div className="py-1">
                        <label className="font-semibold text-[#01A24A]">
                          Informasi Narahubung
                        </label>
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={"contact_person_name"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nama Narahubung"}
                          placeholder={"Nama Narahubung"}
                          required={true}
                        />
                        <InputForm
                          controllerName={"contact_person_position"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Jabatan"}
                          placeholder={"Jabatan"}
                          required={true}
                        />
                        <InputForm
                          controllerName={"contact_person_phone"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Telepon"}
                          placeholder={"Telepon"}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="border-b" />

                    <div className="flex flex-col gap-5 py-1">
                      <div className="py-1">
                        <label className="font-semibold text-[#01A24A]">
                          Informasi Akun Lembaga Penyalur
                        </label>
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"email"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Email"}
                          placeholder={"Email"}
                          required={true}
                        />
                      </div>
                      <div className="flex gap-4">
                        <InputForm
                          controllerName={"password"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Kata Sandi"}
                          placeholder={"Kata Sandi"}
                          required={true}
                          type="password"
                        />
                        <InputForm
                          controllerName={"password_confirmation"}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Konfirmasi Kata Sandi"}
                          placeholder={"Konfirmasi Kata Sandi"}
                          required={true}
                          type="password"
                        />
                      </div>
                    </div>

                    <div className="flex justify-end gap-4 cursor-pointer">
                      <button
                        className="border rounded-lg p-4 cursor-pointer"
                        onClick={() => navigate("/")}
                      >
                        Batal
                      </button>
                      <Button
                        className="bg-[#00BD52] text-white  rounded-lg p-4"
                        label={
                          <div className="flex items-center gap-2">
                            Daftar Akun
                          </div>
                        }
                        disabled={false}
                        onClick={() => methods.handleSubmit(onSubmit)()}
                      />
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </FormProvider>
            </motion.main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterNonPeroranganBUMD;
