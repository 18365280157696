import { InputForm, InputFormUpload } from "components"
import AtomSelect from "components/atoms/Select"
import CardForm from "components/molecules/CardForm"
import { useFormContext } from "react-hook-form"

const InformasiRekening = ({
  bankData = [],
  onUploadFile = () => {}
}) => {
  const {
    watch,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useFormContext()
  return (
    <CardForm label={"INFORMASI REKENING"}>
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <InputForm
            controllerName={`bank_account_information.account_number`}
            className={`py-2 px-4 mt-2 border w-full rounded-md ${
              errors?.bank_account_information?.account_number ? "border-[#F04438]" : "border-green"
            }`}
            label={"Nomor Rekening"}
            placeholder={"Nomor Rekening"}
            required={true}
          />
        </div>
        <div className="flex-1 pt-2">
          <InputForm
            controllerName={`bank_account_information.account_name`}
            className={`py-2 px-4 mt-2 border w-full rounded-md ${
              errors?.bank_account_information?.account_name ? "border-[#F04438]" : "border-green"
            }`}
            label={"Nama Pemilik Rekening"}
            placeholder={"Nama Pemilik Rekening"}
            required={true}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <AtomSelect
            controllerName={`bank_account_information.bank_name`}
            options={bankData}
            className="basic-single mt-2"
            label="Bank"
            returnObject={false}
            required={true}
          />
        </div>
        <div className="flex-1 pt-2">
          <InputFormUpload
            controllerName={`bank_account_information.bank_cover_document`}
            label={"Dokumen Cover Buku Tabungan"}
            uploadFile={onUploadFile}
          />
        </div>
      </div>
    </CardForm>
  )
}

export default InformasiRekening