import {
  BottomFormAction,
  ConfirmationModal,
  HeaderLabel,
  InputForm,
} from 'components';
import AlertBlankData from 'components/molecules/Alert';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import CardForm from 'components/molecules/CardForm';
import InputCurrency from '../../../../components/organisms/inputs/InputCurrency';
import AtomDatePicker from 'components/atoms/Datepicker';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth } from 'hooks/auth';
import {
  getChannelingDirectFinance,
  updateChannelingDirectFinance,
  updateDraftChannelingDirectFinance,
} from 'services';
import { convertCurrencyToFloat, numberToCurrency } from 'helpers';

const Keuangan = ({ draftData, type }) => {
  const InputCurrencyMemo = React.memo(InputCurrency);

  const navigate = useNavigate();
  const { id } = useAuth();

  const [activeStep, setActiveStep] = useState(1);
  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  const { data: financeData } = useQuery({
    queryKey: ['channeling-direct-finance', id],
    queryFn: async () => getChannelingDirectFinance(id),
  });

  const routes = [
    {
      label: 'Formulir Pendaftaran',
      path: '/',
    },
    {
      label: 'Registrasi Prospecting Lembaga Penyalur',
      path: `/lembaga-penyalur/${type}`,
    },
    {
      label: 'Keuangan',
      path: `/lembaga-penyalur/${type}`,
    },
  ];

  const validationSchema = Yup.object().shape({
    keuangan: Yup.array()
      .of(
        Yup.object().shape({
          total_asset: Yup.string().trim().required().label('Total Asset'),
          piutang: Yup.string().required().label('Piutang'),
          modal: Yup.string().required().label('Modal'),
          shu: Yup.string().required().label('SHU'),
          total_pinjaman: Yup.string().required().label('Total Pinjaman'),
          total_simpanan: Yup.string().required().label('Total Simpanan'),
          hutang: Yup.string().trim().required().label('Hutang'),
          presentase_npf: Yup.string()
            .required()
            .matches(
              /^[0-9]+(\.[0-9]+)?$/,
              'Presentase NPF/NPL number must contain only digits'
            )
            .label('Presentase NPF/NPL'),
        })
      )
      .min(1),
  });

  const currentDate = new Date();
  const twoYearsAgo = new Date(
    currentDate.getFullYear() - 1,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const methods = useForm(
    {
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
      defaultValues: {
        range_tahun: [twoYearsAgo, currentDate],
        keuangan: Array.from(
          { length: draftData?.keuangan?.length || 2 },
          (_, index) => ({
            total_asset: draftData?.keuangan?.[index]?.total_asset || '',
            piutang: draftData?.keuangan?.[index]?.piutang || '',
            modal: draftData?.keuangan?.[index]?.modal || '',
            shu: draftData?.keuangan?.[index]?.shu || '',
            total_pinjaman: draftData?.keuangan?.[index]?.total_pinjaman || '',
            total_simpanan: draftData?.keuangan?.[index]?.total_simpanan || '',
            hutang: draftData?.keuangan?.[index]?.hutang || '',
            presentase_npf: draftData?.keuangan?.[index]?.presentase_npf || '',
          })
        ),
      },
    },
    [validationSchema, twoYearsAgo, currentDate]
  );

  useEffect(() => {
    if (financeData) {
      const startYear = new Date(financeData?.data?.data?.[0]?.year, 0, 1);
      const endYear = new Date(
        financeData?.data?.data?.[financeData?.data?.data?.length - 1]?.year,
        0,
        1
      );

      const financeItems = financeData?.data?.data?.map((item) => ({
        total_asset: numberToCurrency(item.total_asset),
        piutang: numberToCurrency(item.receivable),
        modal: numberToCurrency(item.stock),
        shu: numberToCurrency(item.fixed_asset),
        total_pinjaman: numberToCurrency(item.debt),
        total_simpanan: numberToCurrency(item.capital),
        hutang: numberToCurrency(item.debt),
        presentase_npf: numberToCurrency(item.profit),
      }));

      setBodyToSend((state) => ({
        ...state,
        range_tahun: [startYear, endYear],
        keuangan: financeItems,
      }));

      methods.reset({
        range_tahun: [startYear, endYear],
        keuangan: financeItems,
      });
    }
    return;
  }, [methods, financeData]);

  const onBack = useCallback(() => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, navigate]);

  const onSubmit = useCallback(() => {
    const values = methods.getValues();
    setBodyToSend((state) => ({ ...state, ...values }));
    setConfirmModal(true);
  }, [methods]);

  const { setValue, getValues, watch } = methods || {};

  const rangeTahun = watch('range_tahun', [twoYearsAgo, currentDate]);

  const values = getValues();

  useEffect(() => {
    const [startDate, endDate] = rangeTahun;
    if (startDate && endDate) {
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const yearLength = endYear - startYear + 1;

      if (yearLength !== values.keuangan?.length) {
        setValue(
          'keuangan',
          Array.from({ length: yearLength }, (_, index) => ({
            total_asset: values.keuangan?.[index]?.total_asset || '',
            piutang: values.keuangan?.[index]?.piutang || '',
            modal: values.keuangan?.[index]?.modal || '',
            shu: values.keuangan?.[index]?.shu || '',
            total_pinjaman: values.keuangan?.[index]?.total_pinjaman || '',
            total_simpanan: values.keuangan?.[index]?.total_simpanan || '',
            hutang: values.keuangan?.[index]?.hutang || '',
            presentase_npf: values.keuangan?.[index]?.presentase_npf || '',
          }))
        );
      }
    }
  }, [rangeTahun, values, setValue]);

  const startFrom = rangeTahun[0].getFullYear();

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  const onDraft = useCallback(() => {
    const values = methods.getValues();
    setBodyToSend((state) => ({ ...state, ...values }));
    setDraftModal(true);
  }, [methods]);

  const { control } = methods;

  const { fields: shareKeuangan } = useFieldArray({
    control,
    name: 'keuangan',
  });

  const params = useMemo(() => {
    if (!bodyToSend) return {};

    const startYear = bodyToSend.range_tahun[0].getFullYear();
    // const endYear = bodyToSend.range_tahun[1].getFullYear();

    const body = new FormData();

    bodyToSend.keuangan?.forEach((item, index) => {
      body.append(`finance[${index}][year]`, +startYear + +index);
      body.append(
        `finance[${index}][total_asset]`,
        convertCurrencyToFloat(item.total_asset)
      );
      body.append(
        `finance[${index}][receivable]`,
        convertCurrencyToFloat(item.piutang)
      );
      body.append(
        `finance[${index}][stock]`,
        convertCurrencyToFloat(item.modal)
      );
      body.append(
        `finance[${index}][fixed_asset]`,
        convertCurrencyToFloat(item.shu)
      );
      body.append(
        `finance[${index}][debt]`,
        convertCurrencyToFloat(item.hutang)
      );
      body.append(
        `finance[${index}][capital]`,
        convertCurrencyToFloat(item.total_simpanan)
      );
      body.append(`finance[${index}][profit]`, 0);
    });

    return body;
  }, [bodyToSend]);

  const onError = useCallback((error) => {
    enqueueSnackbar(getErrorMessage(error), {
      variant: 'error',
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar('Data Berhasil Disimpan!', {
      variant: 'success',
    });
    navigate('/lembaga-penyalur/bank-ventura');
  }, [navigate]);

  const { mutate: update } = useMutation({
    mutationFn: () => {
      return updateChannelingDirectFinance(id, params);
    },
    onError,
    onSuccess,
  });

  const { mutate: updateDraft } = useMutation({
    mutationFn: () => {
      return updateDraftChannelingDirectFinance(id, params);
    },
    onError,
    onSuccess,
  });

  const onCreate = useCallback(() => {
    update();
  }, [update]);

  const onCreateDraft = useCallback(() => {
    updateDraft();
  }, [updateDraft]);

  return (
    <div>
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onCreate}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        open={draftModal}
        onClose={setDraftModal}
        onSubmit={onCreateDraft}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan menyimpan data ini sebagai draft?
        </div>
        <div className="text-sm font-[400]">
          Setelah Anda menyimpan data sebagai draft anda masih bisa
          melanjutkannya.
        </div>
      </ConfirmationModal>

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text="Keuangan"
          description="Lengkapi persyaratan Keuangan."
        />

        <div className="border-b" />
        <AlertBlankData
          title={'Masukan Data Keuangan'}
          body={'Masukan data keuangan Anda 2 atau 3 tahun terakhir.'}
        />

        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit()} className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AtomDatePicker
                controllerName={'range_tahun'}
                label="Range Tahun"
                required={true}
                isRangeDatePicker
                datepickerOptions={{
                  dateFormat: 'yyyy',
                  maxDate: new Date(),
                  showYearPicker: true,
                }}
                showErrorLabel
              />
            </div>
            {shareKeuangan.length > 0
              ? shareKeuangan.map((data, index) => (
                  <React.Fragment key={data.id}>
                    <CardForm
                      label={`Pos Keuangan - Tahun ${
                        index === 0 ? startFrom : startFrom + index
                      }`}
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputCurrencyMemo
                          controllerName={`keuangan.${index}.total_asset`}
                          wrapperClass={'col-span-1'}
                          label={'Total Asset'}
                          required
                        />
                        <InputCurrencyMemo
                          controllerName={`keuangan.${index}.piutang`}
                          wrapperClass={'col-span-1'}
                          label={'Piutang'}
                          required
                        />
                        <InputCurrencyMemo
                          controllerName={`keuangan.${index}.modal`}
                          wrapperClass={'col-span-1'}
                          label={'Modal'}
                          required
                        />
                        <InputCurrencyMemo
                          controllerName={`keuangan.${index}.shu`}
                          wrapperClass={'col-span-1'}
                          label={'Total Asset'}
                          required
                        />
                        <InputCurrencyMemo
                          controllerName={`keuangan.${index}.total_pinjaman`}
                          wrapperClass={'col-span-1'}
                          label={'Total Pinjaman'}
                          required
                        />
                        <InputCurrencyMemo
                          controllerName={`keuangan.${index}.total_simpanan`}
                          wrapperClass={'col-span-1'}
                          label={'Total Simpanan'}
                          required
                        />
                        <InputCurrencyMemo
                          controllerName={`keuangan.${index}.hutang`}
                          wrapperClass={'col-span-1'}
                          label={'Hutang'}
                          required
                        />
                        <InputForm
                          controllerName={`keuangan.${index}.presentase_npf`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                          label={'Presentase NPF/NPL'}
                          placeholder={'Presentase NPF/NPL'}
                          required
                        />
                      </div>
                    </CardForm>
                  </React.Fragment>
                ))
              : ''}
          </form>
        </FormProvider>
      </div>
      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onSubmit}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        disableButtonSubmit={false}
      />
    </div>
  );
};

export default Keuangan;
