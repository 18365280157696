import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Button, InputForm, InputFormUpload, Table } from "components";
import CardForm from "components/molecules/CardForm";
import { AnimatePresence, motion } from "framer-motion";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  RiCloseLine,
  RiDeleteBin5Line,
  RiDownloadCloud2Line,
  RiFile2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { getFile } from "services/danaProgram/fileService";
import {
  groupProfileMemberRemoveDocument,
  postFileServices,
} from "services/fdb/akunKelompok";
import * as yup from "yup";

export const validationSchema = yup.object().shape({});
export const RequiredDocType = {
  SKF: "swaphoto_ktp_file",
  HPF: "house_photo_file",
  BPF: "business_photo_file",
};
export const requiredDocs = [
  {
    id: null,
    file: "",
    name: "Dokumen Swafoto DenganKTP",
    desc: "Dokumen Swafoto DenganKTP",
    key: RequiredDocType.SKF,
    selected_file: undefined,
  },
  {
    id: null,
    file: "",
    name: "Dokumen Foto Rumah Debitur",
    desc: "Dokumen Foto Rumah Debitur",
    key: RequiredDocType.HPF,
    selected_file: undefined,
  },
  {
    id: null,
    file: "",
    name: "Dokumen foto usaha debitur",
    desc: "Dokumen foto usaha debitur",
    key: RequiredDocType.BPF,
    selected_file: undefined,
  },
];

const DocSection = () => {
  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.conntrol,
    name: "docs.docs",
  });

  const mutateFileService = useMutation(["File Service"], postFileServices);

  const handleUpload = useCallback(
    (field, files, index) => {
      const formData = new FormData();

      formData.append("file", files[0]);

      mutateFileService.mutate(formData, {
        onSuccess: (res) => {
          enqueueSnackbar({
            message: "Dokumen Berhasil Disimpan!",
            variant: "success",
          });
          field.onChange(res.data.data);
        },
        onError: (err) => {
          enqueueSnackbar({
            message: "Dokumen Gagal Disimpan!",
            variant: "failed",
          });
        },
      });
    },
    [mutateFileService]
  );

  const mutateDeleteDocument = useMutation(
    ["GROUP PROFILE MEMBER DELETE DOCUMENT"],
    (data) => {
      groupProfileMemberRemoveDocument(data);
    }
  );

  const handleDelete = useCallback(
    (field, doc, index) => {
      if (doc.id) {
        mutateDeleteDocument.mutate(doc.id);
      }
      remove(index);
      field.onChange("");
    },
    [mutateDeleteDocument, remove]
  );

  const handleMountingFile = useCallback(async () => {
    const docs = methods.getValues("docs.docs");

    for (let i = 0; i < docs.length; i++) {
      if (
        !docs[i].id ||
        typeof docs[i].selected_file === "object" ||
        docs[i].selected_file === "" ||
        docs[i].selected_file === "undefined"
      ) {
        continue;
      }

      const res = await getFile(docs[i].file);

      methods.setValue(`docs.docs[${i}].selected_file`, res.data.data);
    }
  }, [methods]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "no",
        dataIndex: "#",
        columnClassName: "w-[50px]",
        render: (_, __, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        id: "document",
        title: "document",
        dataIndex: "document",
        columnClassName: "",
        render: (_, data, index) => {
          return (
            <div className="flex flex-col">
              <p className="text-sm font-bold">
                {data.desc} <span className="text-sm text-red-600">*</span>
              </p>
            </div>
          );
        },
      },
      {
        id: "document",
        title: "Deskripsi",
        dataIndex: "document",
        columnClassName: "",
        render: (_, data, index) => {
          return (
            <div className="flex gap-2 items-center text-blue-500 cursor-pointer">
              <RiDownloadCloud2Line size={20} />
              <p className="text-sm font-semibold">Unduh Dokumen Rujukan</p>
            </div>
          );
        },
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        columnClassName: "w-min",
        fixed: "right",
        render: (_, data, index) => {
          return (
            <div className="flex flex-col">
              <Controller
                control={methods.control}
                name={`docs.docs[${index}].selected_file`}
                render={({ field }) => {
                  const doc = methods.watch(`docs.docs[${index}]`);
                  return (
                    <div className="flex flex-col gap-2">
                      {(typeof doc.selected_file === "undefined" ||
                        typeof doc.selected_file !== "object") && (
                        <label
                          htmlFor={`upload_files${index}`}
                          className="flex gap-2 items-center text-primary-700"
                          type="button"
                        >
                          <input
                            type="file"
                            name=""
                            id={`upload_files${index}`}
                            className="hidden"
                            onChange={(e) => {
                              handleUpload(field, e.target.files, index);
                            }}
                          />
                          <RiUploadCloud2Line size={20} />
                          <h5 className="text-sm font-semibold">Upload</h5>
                        </label>
                      )}

                      {typeof doc.selected_file === "object" && (
                        <div className="flex flex-col gap-3">
                          <div className="flex gap-2 items-center">
                            <div className="p-2 bg-primary-100 rounded-full text-primary-700">
                              <RiFile2Line size={18} />
                            </div>
                            <p className="whitespace-nowrap text-sm font-medium">
                              {doc.selected_file.name}
                            </p>
                          </div>

                          <div className="flex gap-4">
                            {index > 2 && (
                              <button
                                className="flex gap-1 items-center text-red-700 font-semibold text-sm"
                                onClick={() => handleDelete(field, doc, index)}
                                type="button"
                              >
                                <RiDeleteBin5Line size={20} />
                                <p>Delete</p>
                              </button>
                            )}

                            <label
                              htmlFor="upload_file"
                              className="flex gap-2 items-center text-primary-700"
                              type="button"
                            >
                              <input
                                type="file"
                                name=""
                                id="upload_file"
                                className="hidden"
                                onChange={(e) => {
                                  handleUpload(field, e.target.files, index);
                                }}
                              />
                              <RiUploadCloud2Line size={20} />
                              <h5 className="text-sm font-semibold">Upload</h5>
                            </label>
                          </div>
                        </div>
                      )}
                      <p>Tipe File : PDF ; Max Size : 5mb</p>
                    </div>
                  );
                }}
              />
            </div>
          );
        },
      },
    ];
  }, [methods, handleUpload, handleDelete]);

  useEffect(() => {
    handleMountingFile();
  }, [handleMountingFile]);

  const [isUploadOtherDoc, setIsUploadOtherDoc] = useState(false);

  const handleAddOtherDoc = (payload) => {
    const formData = new FormData();

    formData.append("file", payload.selected_file);

    mutateFileService.mutate(formData, {
      onSuccess: (res) => {
        enqueueSnackbar({
          message: "Dokumen Berhasil Disimpan!",
          variant: "success",
        });

        const otherDoc = {
          id: null,
          name: payload.selected_file.name,
          desc: payload.desc,
          key: "OTHERDOC",
          file: res.data.data.id,
          selected_file: res.data.data,
        };

        append(otherDoc);
        setIsUploadOtherDoc(false);

        // field.onChange(res.data.data);
      },
      onError: (err) => {
        enqueueSnackbar({
          message: "Dokumen Gagal Disimpan!",
          variant: "error",
        });
      },
    });
  };

  return (
    <section>
      {createPortal(
        <UploadOtherDocumentModal
          open={isUploadOtherDoc}
          onClose={() => setIsUploadOtherDoc(false)}
          onSubmitForm={handleAddOtherDoc}
        />,
        document.body
      )}

      <CardForm label="Dokumen Pendukung">
        <div className="flex justify-between">
          <h5 className="text-lg font-semibold text-primary-700">
            Daftar Dokumen
          </h5>
          <Button
            className="bg-primary-600 text-white"
            label={
              <div className="flex gap-2 items-center">
                <RiUploadCloud2Line size={20} />
                <h5>Upload Dokumen Lainnya</h5>
              </div>
            }
            type="button"
            onClick={() => setIsUploadOtherDoc(true)}
          />
        </div>

        <Table
          columns={tableColumns}
          dataSource={fields}
          withPagination={false}
        />
      </CardForm>
    </section>
  );
};

export default DocSection;

const otherSchemaValidation = yup.object({
  name: yup.string().required("Nama dokumen lainnya harus diisi"),
  selected_file: yup.string().required("Dokumen harus diisi"),
  desc: yup.string().required("Deskripsi harus diisi"),
});

const UploadOtherDocumentModal = (props) => {
  const methods = useForm({
    resolver: yupResolver(otherSchemaValidation),
    mode: "onChange",
  });

  const handleClose = () => {
    methods.reset();

    props.onClose();
  };

  const onSubmit = () => {
    const payload = methods.getValues();

    props.onSubmitForm(payload);

    methods.reset();
  };

  return (
    <AnimatePresence mode="wait">
      {props.open && (
        <div className="h-screen w-full bg-black/70 z-50 fixed inset-0 overflow-y-hidden flex justify-center items-center">
          <motion.div
            key="modal-confirmation"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="flex flex-col gap-5 px-8 py-6 bg-white rounded-xl min-w-[400px] shadow-md"
          >
            {/* HEADER */}
            <div className="flex items-center">
              <h5 className="flex flex-grow text-lg font-semibold">
                Upload Dokumen Lainnya
              </h5>
              <RiCloseLine
                size={24}
                className="text-gray-500 cursor-pointer"
                onClick={handleClose}
              />
            </div>

            {/* CONTENT */}
            <FormProvider {...methods}>
              <div className="flex flex-col space-y-5">
                <InputForm
                  label="Nama Dokumen Lainnya"
                  controllerName="name"
                  className="w-full"
                  required
                />

                <InputFormUpload
                  controllerName="selected_file"
                  label="Dokumen Lainnya"
                />

                <div className="flex flex-col gap-1">
                  <InputForm
                    label="Deskripsi"
                    controllerName="desc"
                    className="w-full h-[100px]"
                    required
                  />
                </div>
              </div>

              <div className="flex gap-3">
                <Button
                  label="Batal"
                  className="flex-grow"
                  outline
                  onClick={handleClose}
                  type="button"
                />
                <Button
                  label="Submit"
                  className="flex-grow bg-primary-600 hover:bg-primary-700 text-white"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </FormProvider>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

// import { InputFormUpload } from "components";
// import CardForm from "components/molecules/CardForm";
// import React, {
//   forwardRef,
//   useCallback,
//   useEffect,
//   useImperativeHandle,
//   useRef,
//   useState,
// } from "react";
// import { createPortal } from "react-dom";
// import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
// import {
//   RiDeleteBin2Line,
//   RiDownloadCloud2Line,
//   RiFile2Line,
//   RiUploadCloud2Line,
// } from "react-icons/ri";
// import { useSelector } from "react-redux";
// import * as Yup from "yup";
// import { DOC_UPLOAD_EXT, MAX_DOC_UPLOAD } from "../../../../../app/config";
// import Button from "../../../../../components/atoms/Button";
// import Tag from "../../../../../components/atoms/Tag";
// import UploadDocModal from "../../../../../components/molecules/Modal/UploadDocModal";
// import { getErrorMessage, humanizeFileSize } from "../../../../../helpers";
// import {
//   isFileSize,
//   isFileTypeMatches,
// } from "../../../../../helpers/validation";
// import {
//   validationCustomErrorMsg,
//   validationErrorMsg,
// } from "../../../../../locale/yup_id";
// import { MAX_FILE_UPLOAD } from "../../../Kelompok/FormPendaftaran/NonPerhutananSosial/constants";
// import { enqueueSnackbar } from "notistack";
// import { postFileServices } from "services/fdb/akunKelompok";
// import { useMutation } from "@tanstack/react-query";
// import { getFile } from "services/danaProgram/fileService";

// export const FieldLabel = {
//   DOCS: "Daftar Dokumen",
//   FILE: "Dokumen",
// };

// export const RequiredDocType = {
//   SKF: "swaphoto_ktp_file",
//   HPF: "house_photo_file",
//   BPF: "business_photo_file",
// };

// export const requiredDocs = [
//   {
//     id: null,
//     file: "",
//     name: "Dokumen Swafoto DenganKTP",
//     desc: "Dokumen Swafoto DenganKTP",
//     key: RequiredDocType.SKF,
//   },
//   {
//     id: null,
//     file: "",
//     name: "Dokumen Foto Rumah Debitur",
//     desc: "Dokumen Foto Rumah Debitur",
//     key: RequiredDocType.HPF,
//   },
//   {
//     id: null,
//     file: "",
//     name: "Dokumen foto usaha debitur",
//     desc: "Dokumen foto usaha debitur",
//     key: RequiredDocType.BPF,
//   },
// ];

// export const RequiredDocTypeLembaga = {
//   AP: "akta_pendirian",
//   DP: "dokumen_perubahan",
//   NIB: "dokumen_nib",
//   BUSINESS_PERMIT: "dokumen_izin_usaha",
//   SKDP: "dokumen_skdp",
//   SOP: "dokumen_sop",
// };

// export const requiredDocsLembaga = [
//   {
//     title: "Dokumen Akta Pendirian dan pengesahannya",
//     desc: "Dokumen Akta Pendirian dan pengesahannya",
//     key: RequiredDocTypeLembaga.AP,
//   },
//   {
//     title: "Dokumen Perubahan dan pengesahannya",
//     desc: "Dokumen Perubahan dan pengesahannya",
//     key: RequiredDocTypeLembaga.DP,
//   },
//   {
//     title: "Dokumen NIB (no induk usaha) / TDP(optional)",
//     desc: "Dokumen NIB (no induk usaha) / TDP(optional)",
//     key: RequiredDocTypeLembaga.NIB,
//   },
//   {
//     title: "Dokumen Izin Usaha Berlaku",
//     desc: "Dokumen Izin Usaha Berlaku",
//     key: RequiredDocTypeLembaga.BUSINESS_PERMIT,
//   },
//   {
//     title: "Dokumen SKDP(Apabila ada)",
//     desc: "Dokumen SKDP(Apabila ada)",
//     key: RequiredDocTypeLembaga.SKDP,
//   },
//   {
//     title: "Dokumen Standar Operasional (SOP)",
//     desc: "Dokumen Standar Operasional (SOP)",
//     key: RequiredDocTypeLembaga.SOP,
//   },
// ];

// export const validationSchema = Yup.object().shape({
//   // docs: Yup.array()
//   //   .of(
//   //     Yup.object().shape({
//   //       title: Yup.string(),
//   //       file: Yup.mixed()
//   //         .test("ktp", validationErrorMsg.mixed.required, (value, context) => {
//   //           const allowedTypes = DOC_UPLOAD_EXT;
//   //           // if has value, validate the file
//   //           if (value) {
//   //             // validate file type by extension
//   //             const v1 = isFileTypeMatches({ ext: allowedTypes })(
//   //               value,
//   //               context
//   //             );
//   //             if (!v1) {
//   //               return context.createError({
//   //                 message: validationCustomErrorMsg.isFiletype(
//   //                   allowedTypes.join(", ")
//   //                 )({ label: FieldLabel.FILE }),
//   //               });
//   //             }
//   //             // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
//   //             const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
//   //             if (!v2) {
//   //               return context.createError({
//   //                 message: validationCustomErrorMsg.isFileSize(
//   //                   humanizeFileSize(MAX_DOC_UPLOAD)
//   //                 )({
//   //                   label: FieldLabel.FILE,
//   //                 }),
//   //               });
//   //             }
//   //             return true;
//   //           }
//   //           // otherwise, return false to render the field required
//   //           return false;
//   //         })
//   //         .required()
//   //         .label(FieldLabel.FILE),
//   //       desc: Yup.string(),
//   //     })
//   //   )
//   //   .test(
//   //     "docs",
//   //     ({ label }) =>
//   //       validationErrorMsg.mixed.required.replace("${path}", label),
//   //     (value, context) => {
//   //       // check only first 4 values, as it is the required ones and set by default
//   //       const required = value?.slice(0, requiredDocs.length);
//   //       if (required?.length) {
//   //         const valid =
//   //           required.filter((e) => !!e?.file)?.length === requiredDocs.length;
//   //         return valid;
//   //       }
//   //       return true;
//   //     }
//   //   )
//   //   .required()
//   //   .label(FieldLabel.DOCS),
// });

// const DocsSection = forwardRef(
//   ({ onBack, onSubmit, onSaveDraft, initialValue, mode, formPrefix }, ref) => {
//     const prefix = formPrefix ? `${formPrefix}.` : "";
//     const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
//       (e) => e === mode
//     );

//     const uploadModalRef = useRef();

//     const [uploadModal, setUploadModal] = useState(false);
//     const [triggerValCount, setTriggerValCount] = useState(0);
//     const [formInit, setFormInit] = useState(false);

//     const userId = useSelector((state) => state.profile.data.userId);

//     const methods = useFormContext();

//     const { fields, append, remove, update } = useFieldArray({
//       control: methods.control,
//       name: `${prefix}docs`,
//     });
//     // const watchFieldArray = useWatch({
//     //   control: methods.control,
//     //   name: `${prefix}docs`,
//     // });
//     // const controlledFields = (fields || []).map((field, index) => {
//     //   return {
//     //     ...field,
//     //     ...(watchFieldArray && watchFieldArray[index]),
//     //   };
//     // });

//     const {
//       fields: otherFields,
//       append: otherAppend,
//       remove: otherRemove,
//     } = useFieldArray({
//       control: methods.control,
//       name: `${prefix}others`,
//     });
//     const watchOtherFieldArray = useWatch({
//       control: methods.control,
//       name: `${prefix}others`,
//     });
//     const controlledOtherFields = (otherFields || []).map((field, index) => {
//       return {
//         ...field,
//         ...(watchOtherFieldArray && watchOtherFieldArray[index]),
//       };
//     });

//     // useEffect(() => {
//     //   methods.reset(initialValue);
//     //   console.log("RESET");
//     // }, [methods.reset, initialValue]);

//     // useImperativeHandle(ref, () => ({
//     //   getFormValues() {
//     //     return methods.getValues();
//     //   },
//     // }));

//     useEffect(() => {
//       window.scrollTo({ top: 0, behavior: "smooth" });

//       // mark the form as finished initializing
//       setFormInit(true);
//     }, []);

//     // useEffect(() => {
//     //   if (formInit) {
//     //     // only trigger validation when array field value changes
//     //     triggerValidation();
//     //   }
//     // }, [watchFieldArray]);

//     const triggerValidation = () => {
//       methods.trigger(`${prefix}docs`);

//       // trigger rerender to update array field form state to
//       // as running `methods.trigger()` does not seem to trigger rerender
//       setTriggerValCount(triggerValCount + 1);
//     };

//     const onFormSubmit = (e) => {
//       e.preventDefault();
//       e.stopPropagation();

//       triggerValidation();

//       methods.handleSubmit((data) => {
//         onSubmit(data);
//       })(e);
//     };

//     const onSaveDraftClick = (e) => {
//       e?.preventDefault();

//       const formData = methods.getValues();

//       if (onSaveDraft) {
//         onSaveDraft(formData);
//       }
//     };

//     const onUploadMoreDocClick = (e) => {
//       e?.preventDefault();

//       setUploadModal(true);
//     };

//     const onRowDownloadClick = (e) => {
//       e.preventDefault();
//     };

//     const onRowDeleteClick = (index, key) => (e) => {
//       e.preventDefault();

//       if (key === "others") {
//         otherRemove(index);
//       } else {
//         // methods.setValue(`${prefix}docs.${index}.selected_file`, undefined);
//         const selectedDoc = methods.watch(`${prefix}docs.${index}`);

//         const newDoc = {
//           ...selectedDoc,
//           selected_file: undefined,
//         };

//         update(index, newDoc);
//       }
//     };

//     const doUpload = (formData) => {
//       // const data = new FormData();
//       // data.append("user_id", userId);
//       // data.append("file", formData.file);
//       // data.append("description", formData.desc);
//       // data.append("name", formData.title);
//       // // TODO: submit file to server here
//       // onUploadDocSuccess({
//       //   data: {
//       //     _id: "temp",
//       //     name: formData.title,
//       //     description: formData.desc,
//       //     original: formData.file,
//       //   },
//       // });
//     };

//     const onUploadDocSuccess = (response) => {
//       // otherAppend({
//       //   _id: response?.data?._id,
//       //   title: response?.data?.name,
//       //   desc: response?.data?.description,
//       //   original: response?.data?.file_url,
//       // });
//       // setUploadModal(false);
//     };

//     const getDocsMutation = useMutation({
//       mutationKey: ["get-detail-information-docs"],
//       mutationFn: async ({ payload, type = "" }) => {
//         const res = await getFile(payload);
//         return { res: res.data.data, type: type };
//       },
//     });

//     const handleGetLegalitasDoc = async () => {
//       const docs = methods.getValues("docs");

//       const temps = [];
//       for (let i = 0; i < docs.docs.length; i++) {
//         if (!docs.docs[i].id) {
//           temps.push(docs.docs[i]);
//           continue;
//         }

//         const response = await getFile(docs.docs[i].selected_file);

//         const doc = {
//           ...docs.docs[i],
//           selected_file: response.data.data,
//         };

//         temps.push(doc);
//       }

//       methods.setValue("docs.docs", temps);
//     };

//     const mutateFileService = useMutation(["File Service"], postFileServices);

//     const handleUpload = useCallback(
//       (idx) => {
//         const selectedDoc = methods.watch(`${prefix}docs.${idx}`);
//         const file = selectedDoc.selected_file;
//         const doc = {
//           ...selectedDoc,
//         };

//         console.log(selectedDoc);

//         if (!(selectedDoc.selected_file instanceof File)) {
//           if (selectedDoc.selected_file !== "") return;
//           doc.file = "";
//           update(idx, doc);
//           return;
//         }

//         if (selectedDoc.file !== "") return;

//         const formData = new FormData();
//         formData.append("file", file);
//         mutateFileService.mutateAsync(formData, {
//           onSuccess: (data) => {
//             enqueueSnackbar({
//               message: "Dokumen Berhasil Disimpan!",
//               variant: "success",
//             });
//             doc.file = data.data.data.id;
//             update(idx, doc);
//             return;
//           },
//           onError: (err) => console.log(err),
//         });
//       },
//       [methods, update]
//     );

//     useEffect(() => {
//       handleGetLegalitasDoc();
//     }, []);

//     return (
//       <>
//         {createPortal(
//           <UploadDocModal
//             ref={uploadModalRef}
//             open={uploadModal}
//             onClose={setUploadModal}
//             setOpenModal={setUploadModal}
//             onSubmit={doUpload}
//             className="space-y-2 mb-4"
//             allowedFileByExt={DOC_UPLOAD_EXT}
//             maxSize={MAX_FILE_UPLOAD}
//             onUploadSuccess={onUploadDocSuccess}
//           />,
//           document.body
//         )}

//         <CardForm label="Legalitas">
//           <div className={"flex items-center"}>
//             <div
//               className={
//                 "text-[#01A24A] font-semibold text-lg flex-1 overflow-hidden text-ellipsis"
//               }
//             >
//               Daftar Dokumen
//             </div>
//             <div>
//               {!isView && (
//                 <Button
//                   className={`p-3 border rounded-lg bg-[#6CB745] text-white`}
//                   label={
//                     <div className="flex items-center gap-2">
//                       <RiUploadCloud2Line className={"w-4 h-4"} />
//                       Upload Dokumen Lainnya
//                     </div>
//                   }
//                   onClick={onUploadMoreDocClick}
//                 />
//               )}
//             </div>
//           </div>

//           <div className="overflow-x-auto border rounded-xl overflow-hidden">
//             <table className="table tb-app tb-striped w-full tb-bordered-bottom">
//               <thead>
//                 <tr>
//                   <th>No</th>
//                   <th>Nama</th>
//                   <th>Deskripsi</th>
//                   <th>Aksi</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {fields.map((field, index) => (
//                   <tr key={`docs.${index}`}>
//                     <td>{index + 1}</td>
//                     <td>
//                       {field?.title}
//                       {field?.required ? (
//                         <span className={"text-error-500"}>*</span>
//                       ) : undefined}
//                     </td>
//                     <td>
//                       <div>{field?.desc}</div>
//                       <Button
//                         className={`py-2 px-1 bg-[transparent] text-[#2E90FA] font-semibold`}
//                         label={
//                           <div className="flex items-center gap-2">
//                             <RiDownloadCloud2Line className={"w-4 h-4"} />
//                             Unduh Dokumen Rujukan
//                           </div>
//                         }
//                         onClick={onRowDownloadClick}
//                       />
//                     </td>
//                     <td>
//                       {field?.selected_file ? (
//                         <div
//                           className={"flex gap-2 overflow-hidden items-start"}
//                         >
//                           <Tag theme={"success"}>
//                             <RiFile2Line />
//                           </Tag>
//                           <span
//                             className={
//                               "flex-1 overflow-hidden text-ellipsis font-semibold"
//                             }
//                           >
//                             {field?.selected_file?.name}
//                           </span>
//                         </div>
//                       ) : undefined}

//                       <div className={"space-x-3 my-2"}>
//                         {field?.selected_file && !field?.required ? (
//                           <Button
//                             className={`py-2 px-1 bg-[transparent] text-[#B42318] font-semibold`}
//                             label={
//                               <div className="flex items-center gap-2">
//                                 <RiDeleteBin2Line className={"w-4 h-4"} />
//                                 Hapus
//                               </div>
//                             }
//                             onClick={onRowDeleteClick(index)}
//                           />
//                         ) : undefined}

//                         {/* {console.log("TEST", field)} */}
//                         <MainDocs
//                           prefix={prefix}
//                           index={index}
//                           uploadCallback={handleUpload}
//                         />
//                       </div>

//                       {field?.file ? (
//                         <div>
//                           Tipe File:{" "}
//                           <span className={"uppercase"}>
//                             {field?.selected_file?.name?.split(".")?.pop()}
//                           </span>
//                           ; Size:{" "}
//                           {humanizeFileSize(field?.selected_file?.size, 0)}
//                         </div>
//                       ) : undefined}
//                     </td>
//                   </tr>
//                 ))}

//                 {controlledOtherFields.map((field, index) => (
//                   <tr key={field.id}>
//                     <td>{index + 1 + controlledOtherFields.length}</td>
//                     <td>{field?.title}</td>
//                     <td>
//                       <div>{field?.desc}</div>
//                     </td>
//                     <td>
//                       {field?.file ? (
//                         <div
//                           className={"flex gap-2 overflow-hidden items-start"}
//                         >
//                           <Tag theme={"success"}>
//                             <RiFile2Line />
//                           </Tag>
//                           <span
//                             className={
//                               "flex-1 overflow-hidden text-ellipsis font-semibold"
//                             }
//                           >
//                             {field?.file?.name}
//                           </span>
//                         </div>
//                       ) : undefined}

//                       {!field?.file && field?.original ? (
//                         <div
//                           className={"flex gap-2 overflow-hidden items-start"}
//                         >
//                           <Tag theme={"success"}>
//                             <RiFile2Line />
//                           </Tag>
//                           <span
//                             className={
//                               "flex-1 overflow-hidden text-ellipsis font-semibold"
//                             }
//                           >
//                             {field?.original?.split("/")?.pop()}
//                           </span>
//                         </div>
//                       ) : undefined}

//                       <div className={"space-x-3 my-2"}>
//                         {field?.file && !field?.required ? (
//                           <Button
//                             className={`py-2 px-1 bg-[transparent] text-[#B42318] font-semibold`}
//                             label={
//                               <div className="flex items-center gap-2">
//                                 <RiDeleteBin2Line className={"w-4 h-4"} />
//                                 Hapus
//                               </div>
//                             }
//                             onClick={onRowDeleteClick(index, "others")}
//                           />
//                         ) : undefined}

//                         <div className={"inline-block"}>
//                           <InputFormUpload
//                             controllerName={`others.${index}.file`}
//                             maxSize={MAX_DOC_UPLOAD}
//                             buttonLabel={
//                               <>
//                                 <div className="flex items-center gap-2 text-[#01A24A] font-semibold">
//                                   <RiUploadCloud2Line className={"w-4 h-4"} />
//                                   Upload
//                                 </div>
//                               </>
//                             }
//                           />
//                         </div>
//                       </div>

//                       {field?.file ? (
//                         <div>
//                           Tipe File:{" "}
//                           <span className={"uppercase"}>
//                             {field?.file?.name?.split(".")?.pop()}
//                           </span>
//                           ; Size: {humanizeFileSize(field?.file?.size, 0)}
//                         </div>
//                       ) : undefined}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>

//           {methods.getFieldState(`${prefix}docs`).invalid && (
//             <label className="label">
//               <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
//                 {methods.getFieldState(`${prefix}docs`).error?.message}
//               </div>
//             </label>
//           )}
//         </CardForm>
//       </>
//     );
//   }
// );

// const MainDocs = ({ prefix, index, uploadCallback }) => {
//   const methods = useFormContext();
//   const doc = methods.getValues(`docs.docs.${index}`);

//   useEffect(() => {
//     uploadCallback(index);
//   }, [doc.selected_file, index, uploadCallback]);

//   return (
//     <div className={"inline-block"}>
//       <InputFormUpload
//         controllerName={`${prefix}docs.${index}.selected_file`}
//         maxSize={MAX_DOC_UPLOAD}
//         buttonLabel={
//           <>
//             <div className="flex items-center gap-2 text-[#01A24A] font-semibold">
//               <RiUploadCloud2Line className={"w-4 h-4"} />
//               Upload
//             </div>
//           </>
//         }
//       />
//     </div>
//   );
// };

// export default DocsSection;
