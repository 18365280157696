import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, InputFormUpload } from "components";
import CardForm from "components/molecules/CardForm";
import { find } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import {
  RiDeleteBin2Line,
  RiDownloadCloud2Line,
  RiFile2Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { MAX_DOC_UPLOAD } from "../../../../app/config";
import Button from "../../../../components/atoms/Button";
import Tag from "../../../../components/atoms/Tag";
import UploadDocModal from "../../../../components/molecules/Modal/UploadDocModal";
import { humanizeFileSize } from "../../../../helpers";
import { isFileSize, isFileTypeMatches } from "../../../../helpers/validation";
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from "../../../../locale/yup_id";

import {
  ALLOWED_UPLOAD_TYPES,
  FieldLabel,
  MAX_FILE_UPLOAD,
  requiredDocsLembaga,
} from "pages/FDB/Kelompok/FormPendaftaran/NonPerhutananSosial/constants";

const validationSchema = Yup.object().shape({
  docs: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string(),
        file: Yup.mixed()
          .test(
            "ktp",
            ({ label }) =>
              validationErrorMsg.mixed.required.replace("${path}", label),
            (value, context) => {
              const allowedTypes = ["jpg", "png", "jpeg", "pdf", "docx"];

              // if has value, validate the file
              if (value) {
                // validate file type by extension
                const v1 = isFileTypeMatches({ ext: allowedTypes })(
                  value,
                  context
                );
                if (!v1) {
                  return context.createError({
                    message: validationCustomErrorMsg.isFiletype(
                      allowedTypes.join(", ")
                    )({ label: FieldLabel.FILE }),
                  });
                }

                // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
                const v2 = isFileSize(MAX_DOC_UPLOAD)(value, context);
                if (!v2) {
                  return context.createError({
                    message: validationCustomErrorMsg.isFileSize(
                      humanizeFileSize(MAX_DOC_UPLOAD)
                    )({
                      label: FieldLabel.FILE,
                    }),
                  });
                }

                return true;
              }

              // otherwise, return false to render the field required
              return false;
            }
          )
          .required()
          .label(FieldLabel.FILE),

        file2: Yup.mixed()
          .test(
            "file",
            ({ label }) =>
              validationErrorMsg.mixed.required.replace("${path}", label),
            (value, context) => {
              let allowedTypes = ["jpg", "png", "jpeg", "pdf", "docx"];

              const maxFileSize = MAX_FILE_UPLOAD;

              // if has value, validate the file
              if (value) {
                const data = find(allowedTypes, (at) =>
                  value.type.includes(at)
                );
                if (!data) {
                  return context.createError({
                    message: validationCustomErrorMsg.isFiletype(
                      allowedTypes.join(", ")
                    )({ label: FieldLabel.FILE }),
                  });
                }
                if (value.size > maxFileSize) {
                  return context.createError({
                    message: validationCustomErrorMsg.isFileSize(
                      humanizeFileSize(maxFileSize)
                    )({
                      label: FieldLabel.FILE,
                    }),
                  });
                }

                return true;
              }

              // otherwise, return false to render the field required
              return false;
            }
          )
          .required()
          .label(FieldLabel.FILE),
        desc: Yup.string(),
      })
    )
    .test(
      "docs",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        // check only first 4 values, as it is the required ones and set by default
        const required = value?.slice(0, requiredDocsLembaga.length);

        if (required?.length) {
          const valid =
            required.filter((e) => !!e?.file)?.length === requiredDocsLembaga.length;
          return valid;
        }

        return true;
      }
    )
    .required()
    .label(FieldLabel.DOCS),
  agree: Yup.string().required("Anda harus menyetujui pernyataan ini."),
});

const LegalityDocumentForm = forwardRef(
  ({ onBack, onSubmit, onDraft, initialValue, mode }, ref) => {
    const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
      (e) => e === mode
    );

    const uploadModalRef = useRef();

    const [uploadModal, setUploadModal] = useState(false);
    const [triggerValCount, setTriggerValCount] = useState(0);
    const [formInit, setFormInit] = useState(false);

    const userId = useSelector((state) => state.profile.data.userId);

    const methods = useForm({
      resolver: isView ? undefined : yupResolver(validationSchema),
      mode: "onChange",
      defaultValues: {
        docs: [
          ...requiredDocsLembaga.map((e) => ({
            ...e,
            file: undefined,
            required: true,
          })),
        ],
        others: [],
        created_at: new Date().toISOString(),
      },
    });

    const { fields, append, remove } = useFieldArray({
      control: methods.control,
      name: "docs",
    });
    const watchFieldArray = useWatch({
      control: methods.control,
      name: "docs",
    });
    const controlledFields = (fields || []).map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    });

    const {
      fields: otherFields,
      append: otherAppend,
      remove: otherRemove,
    } = useFieldArray({
      control: methods.control,
      name: "others",
    });
    const watchOtherFieldArray = useWatch({
      control: methods.control,
      name: "others",
    });
    const controlledOtherFields = (otherFields || []).map((field, index) => {
      return {
        ...field,
        ...watchOtherFieldArray[index],
      };
    });

    useEffect(() => {
      methods.reset(initialValue);
    }, [methods.reset, initialValue]);

    useImperativeHandle(ref, () => ({
      getFormValues() {
        return methods.getValues();
      },
    }));

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });

      // mark the form as finished initializing
      setFormInit(true);
    }, []);

    useEffect(() => {
      if (formInit) {
        // only trigger validation when array field value changes
        triggerValidation();
      }
    }, [watchFieldArray]);

    const triggerValidation = () => {
      methods.trigger("docs");

      // trigger rerender to update array field form state to
      // as running `methods.trigger()` does not seem to trigger rerender
      setTriggerValCount(triggerValCount + 1);
    };

    const onFormSubmit = (e) => {
      e.preventDefault();
      e.stopPropagation();

      triggerValidation();

      methods.handleSubmit((data) => {
        onSubmit(data);
      })(e);
    };

    const onSaveDraftClick = (e) => {
      e?.preventDefault();

      const formData = methods.getValues();

      if (onDraft) {
        onDraft(formData);
      }
    };

    const onSubmitClick = (e) => {
      e?.preventDefault();

      const formData = methods.getValues();

      if (onSubmit) {
        onSubmit(formData);
      }
    };

    const onUploadMoreDocClick = (e) => {
      e?.preventDefault();

      setUploadModal(true);
    };

    const onRowDownloadClick = (e) => {
      e.preventDefault();
    };

    const onRowDeleteClick = (index, key) => (e) => {
      e.preventDefault();

      if (key === "others") {
        otherRemove(index);
      } else {
        methods.setValue(`docs.${index}.file`, undefined);
      }
    };

    const doUpload = (formData) => {
      const data = new FormData();
      data.append("user_id", userId);
      data.append("file", formData.file);
      data.append("description", formData.desc);
      data.append("name", formData.title);

      // TODO: submit file to server here
      onUploadDocSuccess({
        data: {
          _id: "temp",
          name: formData.title,
          description: formData.desc,
          original: formData.file,
        },
      });
    };

    const onUploadDocSuccess = (response) => {
      otherAppend({
        _id: response?.data?._id,
        title: response?.data?.name,
        desc: response?.data?.description,
        original: response?.data?.file_url,
      });

      setUploadModal(false);
    };

    return (
      <>
        {createPortal(
          <UploadDocModal
            ref={uploadModalRef}
            open={uploadModal}
            onClose={setUploadModal}
            onSubmit={doUpload}
            className="space-y-2 mb-4"
            allowedFileByExt={ALLOWED_UPLOAD_TYPES}
            maxSize={MAX_FILE_UPLOAD}
            onUploadSuccess={onUploadDocSuccess}
          />,
          document.body
        )}
        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit} className="space-y-8">
            <CardForm label="Legalitas">
              <div className={"flex items-center"}>
                <div
                  className={
                    "text-[#01A24A] font-semibold text-lg flex-1 overflow-hidden text-ellipsis"
                  }
                >
                  Daftar Dokumen
                </div>
                <div>
                  {!isView && (
                    <Button
                      className={`p-3 border rounded-lg bg-[#6CB745] text-white`}
                      label={
                        <div className="flex items-center gap-2">
                          <RiUploadCloud2Line className={"w-4 h-4"} />
                          Upload Dokumen Lainnya
                        </div>
                      }
                      onClick={onUploadMoreDocClick}
                    />
                  )}
                </div>
              </div>

              <div className="overflow-x-auto border rounded-xl overflow-hidden">
                <table className="table tb-app tb-striped w-full tb-bordered-bottom">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>Deskripsi</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {controlledFields.map((field, index) => (
                      <tr key={field.id}>
                        <td>{index + 1}</td>
                        <td>
                          {field?.title}
                          {field?.required ? (
                            <span className={"text-error-500"}>*</span>
                          ) : undefined}
                        </td>
                        <td>
                          <div>{field?.desc}</div>
                          <Button
                            className={`py-2 px-1 bg-[transparent] text-[#2E90FA] font-semibold`}
                            label={
                              <div className="flex items-center gap-2">
                                <RiDownloadCloud2Line className={"w-4 h-4"} />
                                Unduh Dokumen Rujukan
                              </div>
                            }
                            onClick={onRowDownloadClick}
                          />
                        </td>
                        <td>
                          {field?.file ? (
                            <div
                              className={
                                "flex gap-2 overflow-hidden items-start"
                              }
                            >
                              <Tag theme={"success"}>
                                <RiFile2Line />
                              </Tag>
                              <span
                                className={
                                  "flex-1 overflow-hidden text-ellipsis font-semibold"
                                }
                              >
                                {field?.file?.name}
                              </span>
                            </div>
                          ) : undefined}

                          <div className={"space-x-3 my-2"}>
                            {field?.file && !field?.required ? (
                              <Button
                                className={`py-2 px-1 bg-[transparent] text-[#B42318] font-semibold`}
                                label={
                                  <div className="flex items-center gap-2">
                                    <RiDeleteBin2Line className={"w-4 h-4"} />
                                    Hapus
                                  </div>
                                }
                                onClick={onRowDeleteClick(index)}
                              />
                            ) : undefined}

                            <div className={"inline-block"}>
                              <InputFormUpload
                                controllerName={`docs.${index}.file`}
                                maxSize={MAX_DOC_UPLOAD}
                                buttonLabel={
                                  <>
                                    <div className="flex items-center gap-2 text-[#01A24A] font-semibold">
                                      <RiUploadCloud2Line
                                        className={"w-4 h-4"}
                                      />
                                      Upload
                                    </div>
                                  </>
                                }
                              />
                            </div>
                          </div>

                          {field?.file ? (
                            <div>
                              Tipe File:{" "}
                              <span className={"uppercase"}>
                                {field?.file?.name?.split(".")?.pop()}
                              </span>
                              ; Size: {humanizeFileSize(field?.file?.size, 0)}
                            </div>
                          ) : undefined}
                        </td>
                      </tr>
                    ))}
                    {controlledOtherFields.map((field, index) => (
                      <tr key={field.id}>
                        <td>{index + 1 + controlledFields.length}</td>
                        <td>{field?.title}</td>
                        <td>
                          <div>{field?.desc}</div>
                        </td>
                        <td>
                          {field?.file ? (
                            <div
                              className={
                                "flex gap-2 overflow-hidden items-start"
                              }
                            >
                              <Tag theme={"success"}>
                                <RiFile2Line />
                              </Tag>
                              <span
                                className={
                                  "flex-1 overflow-hidden text-ellipsis font-semibold"
                                }
                              >
                                {field?.file?.name}
                              </span>
                            </div>
                          ) : undefined}

                          {!field?.file && field?.original ? (
                            <div
                              className={
                                "flex gap-2 overflow-hidden items-start"
                              }
                            >
                              <Tag theme={"success"}>
                                <RiFile2Line />
                              </Tag>
                              <span
                                className={
                                  "flex-1 overflow-hidden text-ellipsis font-semibold"
                                }
                              >
                                {field?.original?.split("/")?.pop()}
                              </span>
                            </div>
                          ) : undefined}

                          <div className={"space-x-3 my-2"}>
                            {field?.file && !field?.required ? (
                              <Button
                                className={`py-2 px-1 bg-[transparent] text-[#B42318] font-semibold`}
                                label={
                                  <div className="flex items-center gap-2">
                                    <RiDeleteBin2Line className={"w-4 h-4"} />
                                    Hapus
                                  </div>
                                }
                                onClick={onRowDeleteClick(index, "others")}
                              />
                            ) : undefined}

                            <div className={"inline-block"}>
                              <InputFormUpload
                                controllerName={`others.${index}.file`}
                                maxSize={MAX_DOC_UPLOAD}
                                buttonLabel={
                                  <>
                                    <div className="flex items-center gap-2 text-[#01A24A] font-semibold">
                                      <RiUploadCloud2Line
                                        className={"w-4 h-4"}
                                      />
                                      Upload
                                    </div>
                                  </>
                                }
                              />
                            </div>
                          </div>

                          {field?.file ? (
                            <div>
                              Tipe File:{" "}
                              <span className={"uppercase"}>
                                {field?.file?.name?.split(".")?.pop()}
                              </span>
                              ; Size: {humanizeFileSize(field?.file?.size, 0)}
                            </div>
                          ) : undefined}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {methods.getFieldState("docs").invalid && (
                <label className="label">
                  <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                    {methods.getFieldState("docs").error?.message}
                  </div>
                </label>
              )}
            </CardForm>

            <BottomFormAction
              lastStep={true}
              hideSubmit={isView}
              submitButtonProps={{
                type: "submit",
              }}
              backButtonProps={{
                type: "button",
              }}
              backButtonAction={onBack}
              disableDrafButton={false}
              hideDraft={isView}
              draftButtonProps={{
                type: "button",
              }}
              submitActionButton={onSubmitClick}
              drafButtonAction={onSaveDraftClick}
              disableButtonSubmit={false}
            />
          </form>
        </FormProvider>
      </>
    );
  }
);

export default LegalityDocumentForm;
