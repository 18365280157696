import { BottomFormAction, InputForm } from 'components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import CardForm from 'components/molecules/CardForm';
import AtomDatePicker from 'components/atoms/Datepicker';

const PerkembanganAnggota = ({ draftData, onSubmit, onBack, onDraft }) => {
  function indexToAlphabet(index) {
    const alphabetStart = 'A'.charCodeAt(0); // Kode karakter 'A'
    return String.fromCharCode(alphabetStart + index); // Konversi indeks ke karakter
  }

  const validationSchema = Yup.object().shape({
    anggota: Yup.array()
      .of(
        Yup.object().shape({
          anggota_masuk: Yup.string()
            .required()
            .matches(
              /^[0-9]+(\.[0-9]+)?$/,
              'Anggota Masuk must contain only digits'
            )
            .label('Anggota Masuk'),
          anggota_keluar: Yup.string()
            .required()
            .matches(
              /^[0-9]+(\.[0-9]+)?$/,
              'Anggota Keluar must contain only digits'
            )
            .label('Anggota Keluar'),
        })
      )
      .min(1),
  });

  const currentDate = useMemo(() => new Date(), []); // Using useMemo to calculate once
  const twoYearsAgo = useMemo(
    () =>
      new Date(
        currentDate.getFullYear() - 4,
        currentDate.getMonth(),
        currentDate.getDate()
      ),
    [currentDate] // Depend on currentDate
  );

  const methods = useForm(
    {
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
      defaultValues: {
        // range_tahun: [twoYearsAgo, currentDate],
        first_year: twoYearsAgo,
        end_year: currentDate,
        anggota: Array.from(
          { length: draftData?.anggota?.length || 4 },
          (_, index) => {
            const isFirstElement = index === 0;
            const draftItem = draftData?.anggota?.[index] || {};

            const anggotaItem = {
              anggota_masuk: draftItem.anggota_masuk || '',
              anggota_keluar: draftItem.anggota_keluar || '',
              total_anggota: isFirstElement
                ? undefined
                : draftItem.total_anggota || '',
              presentase_tahun_sebelumnya: isFirstElement
                ? undefined
                : draftItem.presentase_tahun_sebelumnya || '',
            };

            return anggotaItem;
          }
        ),
      },
    },
    [validationSchema, currentDate, twoYearsAgo]
  );

  const { setValue, getValues, watch } = methods || {};

  const first_year = watch('first_year', twoYearsAgo);
  const end_year = watch('end_year', currentDate);

  const values = getValues();

  useEffect(() => {
    const startDate = first_year;
    const endDate = end_year;

    if (startDate && endDate) {
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const yearLength = endYear - startYear;

      if (yearLength !== values.anggota.length) {
        setValue(
          'anggota',
          Array.from({ length: yearLength }, (_, index) => {
            const draftItem = values.anggota?.[index] || {};
            const isFirstElement = index === 0;

            const anggotaItem = {
              anggota_masuk: draftItem.anggota_masuk || '',
              anggota_keluar: draftItem.anggota_keluar || '',
              total_anggota: isFirstElement
                ? undefined
                : draftItem.total_anggota || '',
              presentase_tahun_sebelumnya: isFirstElement
                ? undefined
                : draftItem.presentase_tahun_sebelumnya || '',
            };

            return anggotaItem;
          })
        );
      }
    }
  }, [first_year, end_year, values, setValue]);
  const startFrom = first_year.getFullYear();

  const onFormSubmit = () => {
    return methods.handleSubmit(
      (data) => {
        onSubmit(data);
      },
      (errors, e) => console.log(errors, e)
    );
  };

  const onSaveDraft = useCallback(() => {
    onDraft(getValues());
  }, [getValues, onDraft]);

  const {
    // formState: { errors, isValid },
    control,
  } = methods;

  const { fields: shareAnggota } = useFieldArray({
    control,
    name: 'anggota',
  });

  return (
    <div>
      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit()} className="space-y-8">
            <CardForm label="Perkembangan Anggota">
              Pilih Periode
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <AtomDatePicker
                  controllerName={'first_year'}
                  label="Tahun"
                  placeholder="2021"
                  required={true}
                  datepickerOptions={{
                    dateFormat: 'yyyy',
                    maxDate: end_year,
                    showYearPicker: true,
                    minDate: first_year,
                  }}
                  showErrorLabel
                />
                <AtomDatePicker
                  controllerName={'end_year'}
                  label="Tahun"
                  placeholder="2023"
                  required={true}
                  datepickerOptions={{
                    dateFormat: 'yyyy',
                    maxDate: end_year,
                    showYearPicker: true,
                    minDate: first_year,
                  }}
                  showErrorLabel
                />
              </div>
              {shareAnggota.length > 0
                ? shareAnggota.map((data, index) => (
                    <React.Fragment key={data.id}>
                      {indexToAlphabet(index)}. Periode{' '}
                      {index === 0 ? startFrom : startFrom + index}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={`anggota.${index}.anggota_masuk`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                          label={'Jumlah Anggota Masuk'}
                          placeholder={'Jumlah Anggota Masuk'}
                          required
                        />
                        <InputForm
                          controllerName={`anggota.${index}.anggota_keluar`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                          label={'Jumlah Anggota Keluar'}
                          placeholder={'Jumlah Anggota Keluar'}
                          required
                        />
                        <InputForm
                          controllerName={`anggota.${index}.total_anggota`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                          label={'Total Jumlah Anggota'}
                          placeholder={'Total Jumlah Anggota'}
                          required
                        />
                        {index !== 0 ? (
                          <>
                            <InputForm
                              controllerName={`anggota.${index}.presentase_tahun_sebelumnya`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                              label={'Presentase Dibandingkan Tahun Sebelumnya'}
                              placeholder={`0%`}
                              required
                              disabled
                            />
                          </>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ))
                : ''}
            </CardForm>
          </form>
        </FormProvider>
      </div>
      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onFormSubmit()}
        disableDrafButton={false}
        drafButtonAction={() => onSaveDraft()}
        disableButtonSubmit={false}
      />
    </div>
  );
};

export default PerkembanganAnggota;
