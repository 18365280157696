import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CardForm from "components/molecules/CardForm";
import {
  BottomFormAction,
  InputFormMultiple,
  InputFormRadio,
} from "components";

const ApplicationProvider = ({ prefix, onSubmit, onBack, onDraft, data }) => {
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      [prefix]: yup.object({
        has_app_provider: yup.mixed().required(),
        provider: yup.array().min(1),
      }),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      [prefix]: {
        has_app_provider: null,
        provider: [],
      },
    },
  });

  useEffect(() => {
    methods.reset({ [prefix]: data });
    return;
  }, []);

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-10">
        <CardForm label={"Penyedia Software / Aplikasi"}>
          <div className="space-y-6 pt-2 pb-4">
            <InputFormRadio
              label={"Apakah Ada Pihak penyedia software/aplikasi?"}
              controllerName={`${prefix}.has_app_provider`}
              required
              showErrorLabel={false}
              gridClassName="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2"
              values={[
                {
                  label: "Ya",
                  value: true,
                },
                {
                  label: "Tidak",
                  value: false,
                },
              ]}
            />
            <InputFormMultiple
              label={"Pihak penyedia Software/Aplikasi"}
              controllerName={`${prefix}.provider`}
              usingGrid
              required
              hideInputValue
              showErrorLabel={false}
              gridClassName="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2"
              values={[
                {
                  label: "Internal",
                  value: "Internal",
                },
                {
                  label: "External",
                  value: "External",
                },
              ]}
            />
          </div>
        </CardForm>
      </div>
      <BottomFormAction
        // lastStep={true}
        backButtonProps={{
          type: "button",
        }}
        submitButtonProps={{
          type: "submit",
        }}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        submitActionButton={onFormSubmit()}
        backButtonAction={onBack}
      />
    </FormProvider>
  );
};

export default ApplicationProvider;
