/* eslint-disable no-useless-escape */
import React, {
  useRef,
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { InputForm, Input } from 'components';
import {
  useWatch,
  useFormContext,
  useController,
} from 'react-hook-form';
import { numberToCurrency, formatCurrency } from 'helpers';
import { MaskInput } from 'maska';

export function FormHeader({ children, className }) {
  return <div className={`text-lg font-semibold ${className}`}>{children}</div>;
}

export function FormInput({ children, wrapperClass, className, ...props }) {
  return (
    <div className={`mb-4 ${wrapperClass}`}>
      <InputForm
        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 ${className}`}
        {...props}
      />
    </div>
  );
}

export function DisabledInput({
  value,
  label,
  placeholder,
  wrapperClass,
  hidden,
  required,
  className,
  ...props
}) {
  return (
    <div
      className={`form-control mb-4 w-full ${wrapperClass || ''} ${
        hidden && 'hidden'
      }`}
    >
      <label className="flex gap-1 label font-semibold text-[14px]">
        <span className={`label-text`}>{label}</span>
        {required && <span className="text-[#F04438]">*</span>}
      </label>
      <Input
        label={'Jumlah A'}
        placeholder={'Jumlah A'}
        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 ${className}`}
        disabled
        value={value}
        {...props}
      />
    </div>
  );
}

export const UncontrolledInput = ({
  wrapperClass,
  required,
  label,
  hidden,
  className,
  ...props
}) => {
  return (
    <div
      className={`form-control w-full ${wrapperClass || ''} ${
        hidden && 'hidden'
      }`}
    >
      <label className="flex gap-1 label font-semibold text-[14px]">
        <span className={`label-text`}>{label}</span>
        {required && <span className="text-[#F04438]">*</span>}
      </label>
      <Input
        className={['mt-2', className].join(' ')}
        label={label}
        {...props}
      />
    </div>
  );
};

export const TotalField = ({ index, calc, fieldName, className, ...props }) => {
  const { setValue, control } = useFormContext();
  const data = useWatch({
    control,
    name: `items.${index}`,
  });

  const total = useMemo(() => {
    return numberToCurrency(calc(data));
  }, [data, calc]);

  useEffect(() => {
    setValue(`items.${index}.${fieldName}`, total);
  }, [total, index, setValue, fieldName]);

  return (
    <UncontrolledInput
      wrapperClass={'mt-4'}
      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 ${className}`}
      controllerName={`items.${index}.${fieldName}`}
      disabled
      value={total}
      {...props}
    />
  );
};

export const CurrencyField = forwardRef(
  (
    {
      controllerName,
      label,
      required = false,
      showErrorLabel = true,
      className,
      prefix,
      disabled = false,
      onChangeInput,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const { control } = useFormContext();
    const {
      field: { onChange, onBlur, value },
      fieldState: { invalid, error },
    } = useController({
      name: controllerName,
      control,
    });
    const inputRef = useRef();
    const maskValue = useRef();

    useEffect(() => {
      maskValue.current = new MaskInput(inputRef?.current || '[data-maska]', {
        preProcess: (val) => val.replace(/[Rp\s?\.]/g, ''),
        postProcess: (val) => {
          if (!val) return '';

          const numericString = val.replace(/[\,]/g, '.');
          const sub =
            3 -
            (numericString.includes('.')
              ? numericString.length - numericString.indexOf('.')
              : 0);

          return formatCurrency(numericString).slice(0, sub ? -sub : undefined);
        },
      });
    }, [inputRef]);

    useImperativeHandle(ref, () => ({
      getInputRef: () => inputRef,
    }));

    return (
      <div>
        <label className="flex gap-1 label font-semibold text-sm">
          <span className={`label-text`}>{label}</span>
          {required && <span className="text-[#F04438]">*</span>}
        </label>

        <input
          ref={inputRef}
          {...props}
          data-maska="0,99"
          data-maska-tokens="0:\d:multiple|9:\d:optional"
          onInput={(e) => {
            onChange(e);
            if (onChangeInput) {
              onChangeInput(e);
            }
          }}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          className={
            className
              ? `${className} ${invalid ? 'border-[#F04438]' : ''}`
              : `input transition duration-300 focus:outline-primary-700 rounded-lg px-4 py-2 mt-1 w-full max-w-xs border ${
                  invalid ? 'input-error' : 'input-bordered'
                } ${invalid ? 'border-[#F04438]' : ''}`
          }
          prefix={prefix}
          disabled={disabled}
        />

        {invalid && showErrorLabel && (
          <label className="label">
            <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
              {error?.message}
            </div>
          </label>
        )}
      </div>
    );
  }
);
