import { ConfirmationModal, HeaderLabel, StepProgress } from 'components';
import AlertBlankData from 'components/molecules/Alert';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import GeneralInformationForm from './generalInfoForm';
import LegalityDocumentForm from './legalityDocumentForm';
import { useDispatch } from 'react-redux';
import { useAuth } from 'hooks/auth';
import {
  getChannelingDirectInstitutional,
  updateChannelingDirectInstitutionalPT,
  updateDraftChannelingDirectInstitutionalPT,
  getChannelingDirectInstitutionalAdvisor,
} from 'services';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

const routes = [
  {
    label: 'Formulir Pendaftaran',
    path: `/lembaga-penyalur/bank-ventura`,
  },
  {
    label: 'Registrasi Prospecting Lembaga Penyalur',
    path: `/lembaga-penyalur/bank-ventura`,
  },
  {
    label: 'Kelembagaan',
    path: `/lembaga-penyalur/bank-ventura/kelembagaan`,
  },
];

const stepProgress = [
  {
    label: 'General Informasi',
    step: 1,
  },
  {
    label: 'Dokumen Legalitas',
    step: 2,
  },
];

const KelembagaanBank = (props) => {
  // const { type } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(1);
  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  const { id } = useAuth();
  const { data } = useQuery(
    ['channeling-direct-institutional', id],
    async () => (await getChannelingDirectInstitutional(id)).data?.data
  );

  const { data: dataAdvisor } = useQuery(
    ['channeling-direct-institutional-advisor', id],
    async () => (await getChannelingDirectInstitutionalAdvisor(id)).data?.data
  );

  useEffect(() => {
    if (data && dataAdvisor) {
      setBodyToSend({
        latar_belakang: data.background,
        visi_misi: data.vision_mission,
        jumlah_pengguna: data.amount_of_member,
        jumlah_cabang: data.amount_of_branch,
        jumlah_pegawai: data.amount_of_employee,
        // struktur_organisasi_upload: data.organization_structure_file,
        daftardireksi:
          dataAdvisor?.map((item) => ({
            nama: item.name,
            jabatan: item.position,
            NIK: item.ktp,
            no_telepon: item.phone,
          })) || [],
      });
    }
  }, [data, dataAdvisor]);

  const onBack = useCallback(() => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, navigate]);

  const onDraft = useCallback((payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));
    setDraftModal(true);
  }, []);

  const onSubmit = useCallback(
    (payload) => {
      setBodyToSend((state) => ({ ...state, ...payload }));
      if (activeStep < stepProgress.length) {
        setActiveStep(activeStep + 1);
      } else {
        setConfirmModal(true);
      }
    },
    [activeStep]
  );

  const params = useMemo(() => {
    if (!bodyToSend) return {};

    const body = new FormData();
    body.append('background', bodyToSend.latar_belakang);
    body.append('vision_mission', bodyToSend.visi_misi);
    body.append('amount_of_member', bodyToSend.jumlah_pengguna);
    body.append('amount_of_branch', bodyToSend.jumlah_cabang);
    body.append('amount_of_employee', bodyToSend.jumlah_pegawai);
    body.append('nib', bodyToSend.nib ?? '-');
    body.append(
      'nib_date',
      bodyToSend.nib_date ?? dayjs().format('YYYY-MM-DD')
    );
    body.append('business_license', bodyToSend.business_license ?? '-');
    body.append(
      'business_license_date',
      bodyToSend.business_license_date ?? dayjs().format('YYYY-MM-DD')
    );

    bodyToSend.daftardireksi?.forEach((item, index) => {
      body.append(`director[${index}][name]`, item.nama);
      body.append(`director[${index}][position]`, item.jabatan);
      body.append(`director[${index}][ktp]`, item.NIK);
      body.append(`director[${index}][phone]`, item.no_telepon);
    });

    body.append(
      'organization_structure_file',
      bodyToSend.struktur_organisasi_upload
    );

    const docs = bodyToSend.docs || [];
    function findDocByKey(key) {
      return docs.find((doc) => doc.key === key)?.file;
    }
    body.append('establishment_file', findDocByKey('akta_pendirian'));
    body.append('amendment_file', findDocByKey('dokumen_perubahan'));
    body.append('nib_file', findDocByKey('dokumen_nib'));
    body.append('business_license_file', findDocByKey('dokumen_izin_usaha'));
    body.append('skdp_file', findDocByKey('dokumen_skdp'));
    body.append('sop_file', findDocByKey('dokumen_sop'));

    return body;
  }, [bodyToSend]);

  const onError = useCallback((error) => {
    enqueueSnackbar(getErrorMessage(error), {
      variant: 'error',
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar('Data Berhasil Disimpan!', {
      variant: 'success',
    });
    navigate('/lembaga-penyalur/bank-ventura');
  }, [navigate]);

  const { mutate: update } = useMutation({
    mutationFn: () => {
      return updateChannelingDirectInstitutionalPT(id, params);
    },
    onError,
    onSuccess,
  });

  const { mutate: updateDraft } = useMutation({
    mutationFn: () => {
      return updateDraftChannelingDirectInstitutionalPT(id, params);
    },
    onError,
    onSuccess,
  });

  const onCreate = () => {
    update();
  };

  const onCreateDraft = () => {
    updateDraft();
  };

  return (
    <div>
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onCreate}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        open={draftModal}
        onClose={setDraftModal}
        onSubmit={onCreateDraft}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan menyimpan data ini sebagai draft?
        </div>
        <div className="text-sm font-[400]">
          Setelah Anda menyimpan data sebagai draft anda masih bisa
          melanjutkannya.
        </div>
      </ConfirmationModal>
      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text="Kelembagaan"
          description="Lengkapi persyaratan Kelembagaan."
        />

        <div className="border-b" />

        <div className="w-full flex justify-center">
          <div className="w-[800px]">
            <StepProgress
              stepProgressData={stepProgress}
              activeStep={activeStep}
            />
          </div>
        </div>

        <AlertBlankData
          title={'Lengkapi Data'}
          body={
            'Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisisan ke tahap selanjutnya.'
          }
        />

        <div>
          {activeStep === 1 && (
            <GeneralInformationForm
              onBack={onBack}
              onSubmit={onSubmit}
              onDraft={onDraft}
            />
          )}
          {activeStep === 2 && (
            <LegalityDocumentForm
              onBack={onBack}
              onSubmit={onSubmit}
              onDraft={onDraft}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default KelembagaanBank;
