import axios from "axios";
import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

const user = JSON.parse(
  sessionStorage.getItem(
    "oidc.user:https://identity.bpldh-dev.groot.id:web_app_enduser"
  )
);

export const getHomepageStatus = (userId) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/lemtara?user_id=${userId}`,
    {
      headers,
    }
  );
};

export const submitAllData = (params) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/lemtara/submit-all-form`,
    params,
    {
      headers,
    }
  );
};
