import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getAgreementInstitutionList = async ({ queryKey }) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/agency-agreement`,
    {
      params: queryKey[1],
    }
  );
};

export const submitAgreementInstitution = async ({ id, payload }) => {
  return await axiosInstance.post(
    `${config_fdb_v2.BASE_URL}/v1/agency-agreement/${id}/change-status`,
    payload
  );
};
