import { BottomFormAction, ConfirmationModal, Header, HeaderLabel } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { FormProvider } from "react-hook-form";
import InformasiPenanggungJawab from "../RegisterGroup/Form/InformasiPenanggungJawab";
import SusunanDireksi from "../RegisterGroup/Form/SusunanDireksi";
import Alamat from "./Form/Alamat";
import AlamatGroup from "../RegisterGroup/Form/Alamat";
import InformasiAkun from "./Form/InformasiAkun";
import InformasiAkunGroup from "../RegisterGroup/Form/InformasiAkun";
import InformasiRekening from "./Form/InformasiRekening";
import InformasiRekeningGroup from "../RegisterGroup/Form/InformasiRekening";
import InformasiUmum from "./Form/InformasiUmum";
import useRegister from "./Hooks/useRegister";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import background from "../../../assets/backgrounds/Background.png";
import { motion } from "framer-motion";
import Footer from "components/atoms/Footer";
import Icon from "../../../assets/icons";

const RegisterBeneficiariesChild = () => {
  const {
    methods,
    optJenisBeneficiaries,
    setValue,
    getValues,
    watch,
    clearErrors,
    resetField,
    formState: { errors, isValid },
    handleSubmit,
    control,
    trigger,
    reset,
    countries,
    setCountries,
    provinces,
    setProvinces,
    cities,
    setCities,
    districts,
    setDistricts,
    wards,
    setWards,
    zipCodes,
    setZipCodes,
    directorsFields,
    appendDirectors,
    removeDirectors,
    bank,
    onSubmit,
    confirmModal,
    setConfirmModal,
    onSubmitConfirmModal,
    onUploadFile
  } = useRegister()

  useEffect(() => {
    clearErrors()
    if (watch('general_information.beneficiaries_type') === "Perorangan"){
      setValue(`general_information.isPersonal`, true)
      setValue(`pic_information.isGroup`, false)
      resetField('general_information.institution_name')
      resetField('general_information.deed_number')
      resetField('general_information.nib_number')
      resetField('general_information.npwp')
      resetField('general_information.est_date')
      resetField('general_information.office_phone_number')
      resetField('general_information.institution_description')

      setValue('director_composition', [])

      resetField('pic_information.name')
      resetField('pic_information.nik')
      resetField('pic_information.phone_number')
    }
    else{
      setValue(`general_information.isPersonal`, false)
      setValue(`pic_information.isGroup`, true)
      resetField("general_information.fullname");
      resetField('general_information.nik')
      resetField('general_information.gender')
      resetField('general_information.birthdate')
      resetField('general_information.phone_number')
    }
  }, [watch('general_information.beneficiaries_type')])

  useEffect(() => {
    clearErrors('director_composition')
  }, [removeDirectors])
  return (
    <>
      {createPortal(
        <ConfirmationModal 
          open={confirmModal}
          onClose={() => setConfirmModal(false)}
          onCancel={() => setConfirmModal(false)}
          onSubmit={async () => await onSubmitConfirmModal()}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan registrasi data ini?
          </div>
          {/* <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div> */}
        </ConfirmationModal>,
        document.body
      )}
      <div className="bg-[#FCFCFD]">
        <img src={background} alt="" className="absolute z-0 w-full" />
        <div className="md:px-28 flex flex-col h-full mx-auto relative">
        <nav className="w-full top-0 border-0 py-10">
          <div className="flex items-center gap-2">
            <div className="flex-1">
              <img className="w-28 h-12" alt="Logo" src={Icon.logo} />
            </div>
            <div className="dropdown dropdown-end">
              <div className="flex items-center space-x-2">
              </div>
            </div>
          </div>
        </nav>
          <div className="flex sm:flex-col lg:flex-row flex-1">
            {/* {sidebar && <Sidebar props={props}>{children}</Sidebar>} */}
            <div id="_layout-content" className="w-full">
              <motion.main
                className={`flex-1 paragraph mb-4`}
                key={window.location.pathname}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.05 }}
              >
                <FormProvider {...methods}>
                  <div className="w-full space-y-6 bg-white rounded-3xl border p-10 pb-28 mb-6">
                    <BreadCrumbs 
                      routes={[
                        {
                          label: watch('general_information.isPersonal') === true ? "Register Beneficiaries Personal" : "Register Beneficiaries Group",
                          path: "/beneficiaries/register"
                        }
                      ]}
                    />
          
                    <div className="flex">
                      <HeaderLabel 
                        text="Pendaftaran Beneficiaries"
                        description="Silahkan Lengkapi Data-Data Di Bawah Ini Untuk Anda Bisa Mendapatkan Akun"
                      />
                    </div>
                    <InformasiUmum 
                      optJenisBeneficiaries={optJenisBeneficiaries}
                      onUploadFile={onUploadFile}
                    />
                    <Alamat
                      countries={countries}
                      setCountries={setCountries}
                      provinces={provinces}
                      setProvinces={setProvinces}
                      cities={cities}
                      setCities={setCities}
                      districts={districts}
                      setDistricts={setDistricts}
                      wards={wards}
                      setWards={setWards}
                      zipCodes={zipCodes}
                      setZipCodes={setZipCodes}
                    />
                    <InformasiRekening 
                      bankData={bank}
                      onUploadFile={onUploadFile}
                    />
                    {watch('general_information.isPersonal') || (
                      <>
                        <SusunanDireksi 
                          directorsFields={directorsFields}
                          appendDirectors={appendDirectors}
                          removeDirectors={removeDirectors}
                        />
                        <InformasiPenanggungJawab 
                          onUploadFile={onUploadFile}
                        />
                      </>
                    )}
                    <InformasiAkun />
                    {/* {watch('jenis_beneficiaries') === 'Perorangan' ? (
                      <>
                        <Alamat
                          countries={countries}
                          setCountries={setCountries}
                          provinces={provinces}
                          setProvinces={setProvinces}
                          cities={cities}
                          setCities={setCities}
                          districts={districts}
                          setDistricts={setDistricts}
                          wards={wards}
                          setWards={setWards}
                          zipCodes={zipCodes}
                          setZipCodes={setZipCodes}
                        />
                        <InformasiRekening 
                          bankData={bank}
                        />
                        <InformasiAkun />
                      </>
                    ) : (
                      <>
                        <AlamatGroup
                          countries={countries}
                          setCountries={setCountries}
                          provinces={provinces}
                          setProvinces={setProvinces}
                          cities={cities}
                          setCities={setCities}
                          districts={districts}
                          setDistricts={setDistricts}
                          wards={wards}
                          setWards={setWards}
                          zipCodes={zipCodes}
                          setZipCodes={setZipCodes}
                        />
                        <InformasiRekeningGroup 
                          bankData={bank}
                        />
                        <SusunanDireksi 
                          directorsFields={directorsFields}
                          appendDirectors={appendDirectors}
                          removeDirectors={removeDirectors}
                        />
                        <InformasiPenanggungJawab />
                        <InformasiAkunGroup />
                      </>
                    )} */}
                    
                  </div>
                  
                  <BottomFormAction
                    lastStep={true}
                    backButtonAction={() => {
                      const redirectUrl = `${process.env.REACT_APP_AUTH_URL}/account/LoginEndUser?type=Dana%20Program`;
                      window.location.href = redirectUrl;
                    }}
                    disableDrafButton={true}
                    hideDraft={true}
                    // disableButtonSubmit={isValid ? false : true}
                    submitActionButton={handleSubmit(async (d) => {
                      await onSubmit(d)
                    })}
                  />
                </FormProvider>
              </motion.main>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default RegisterBeneficiariesChild;