/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Button, InputForm, InputFormUpload } from "../../../../../components";
import Radio from "../../../../../components/atoms/Radio";
import AtomSelect from "../../../../../components/atoms/Select";
import CardForm from "../../../../../components/molecules/CardForm";
import RichEditorForm from "../../../../../components/molecules/RichEditorForm";
import InputNumeric from "../../../../../components/organisms/inputs/InputNumeric";
import SelectJenisUsaha from "../../../../../components/organisms/inputs/SelectJenisUsaha";
import SelectKomoditasUsaha from "../../../../../components/organisms/inputs/SelectKomoditasUsaha";
import { isRTEEmpty } from "../../../../../helpers/validation";
import { validationErrorMsg } from "../../../../../locale/yup_id";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import InputCurrencyForm from "components/V2/shared/input-currency-form";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { postFileServices } from "services/fdb/akunKelompok";
import { useMutation } from "@tanstack/react-query";
import { getFile } from "services/danaProgram/fileService";
import FileInfoField from "components/atoms/FileInfoField";
import { RiAddFill, RiArrowUpSLine, RiDeleteBin6Line } from "react-icons/ri";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

export const FieldLabel = {
  PROJECT_TYPE: "Jenis Usaha PS",
  PROJECT_COMMODITY: "Komoditas Usaha PS",
  PROJECT_AGE: "Lama Usaha",
  PRODUCTIVITY: "Produktivitas/Hasil Panen Terakhir",
  BUSINESS_CAPACITY: "Kapasitas Usaha/Produksi",
  PRICE: "Harga Jual Terakhir",
  AREA: "Luasan lahan yang Digarap",
  GROSS: "Omzet Usaha",
  CAPITAL: "HPP/Modal",
  NET: "Pendapatan Bersih",
  UPLOAD: "Laporan Laba Rugi",
  GOAL: "Tujuan Pemasaran",
  PARTNER: "Mitra Usaha (Jika Ada)",
  TYPE: "Tipe Usaha",
  CYCLE: "Siklus Usaha",
  COMMODITY_QTY: "Kuantitas komoditas yang sudah ada",
  LOAN_GOAL: "Tujuan Pengajuan Pinjaman",
  LOAN_GOAL_ESSAY: "Jelaskan Tujuan Pengajuan Pinjaman Anda",
};

export const validationSchema = Yup.object().shape({
  bussiness_type: Yup.string().trim().required().label(FieldLabel.PROJECT_TYPE),
  bussiness_commodities: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.PROJECT_COMMODITY),
  bussiness_duration: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.PROJECT_AGE),
  bussiness_duration_type: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.PROJECT_AGE),
  last_productivity: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.PRODUCTIVITY),
  last_price: Yup.string().trim().required().label(FieldLabel.PRICE),
  farmed_acreage: Yup.string().trim().required().label(FieldLabel.AREA),
  revenue: Yup.string().trim().required().label(FieldLabel.GROSS),
  cost_price: Yup.string().trim().required().label(FieldLabel.CAPITAL),
  net_income: Yup.string().trim().required().label(FieldLabel.NET),
  market_target: Yup.string().trim().required().label(FieldLabel.GOAL),
  // partner: Yup.string().trim().required().label(FieldLabel.PARTNER),
  bussiness_manage_type: Yup.string().trim().required().label(FieldLabel.TYPE),
  bussiness_cycle: Yup.string().trim().required().label(FieldLabel.CYCLE),
  // cycle_type: Yup.string().trim().required().label(FieldLabel.CYCLE),
  qty_exist_commodity: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.COMMODITY_QTY),
  purpose_loan_application: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.LOAN_GOAL),
  purpose_loan_application_desc: Yup.string()
    .trim()
    .test("essay", validationErrorMsg.mixed.required, isRTEEmpty)
    .required()
    .label(FieldLabel.LOAN_GOAL_ESSAY),
});

function ProjectSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const [incomeStatementFile, setIncomeStatementFile] = useState();

  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "business_partner",
  });

  const incomeStatementFileField = methods.watch("income_statement_file");

  const openDocs = (name) => {
    getDocsMutation.mutate({ payload: methods.getValues(name), type: "" });
  };

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "income_statement_file") {
        setIncomeStatementFile(res);
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      // TODO: integrate submit api
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      if (type === "income_statement_file") {
        methods.setValue("income_statement_file", res.id);
        setIncomeStatementFile(res);
      }
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      // setDraftModal(false);
    },
  });

  useEffect(() => {
    if (incomeStatementFileField instanceof File) {
      postFile.mutate({
        payload: incomeStatementFileField,
        type: "income_statement_file",
      });
    } else if (
      typeof incomeStatementFileField === "string" &&
      incomeStatementFileField !== ""
    ) {
      getDocsMutation.mutate({
        payload: incomeStatementFileField,
        type: "income_statement_file",
      });
    }
  }, [incomeStatementFileField]);

  const businessType = methods.watch("temp_bussiness_type");

  const location = useLocation();

  return (
    <CardForm label="Data Usaha yang Akan Dibiayai" {...rest}>
      <div className="flex justify-between gap-4 bg-[#E1F1D6] px-6 py-5 rounded-md">
        <h5 className="text-lg font-semibold text-primary-700">
          Kondisi Usaha Saat Ini
        </h5>
        <div className="flex items-center text-base gap-2 text-primary-700 font-semibold cursor-pointer">
          Tutup <RiArrowUpSLine strokeWidth={1.5} />
        </div>
      </div>

      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        <SelectJenisUsaha
          controllerName={`${prefix}bussiness_type`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.PROJECT_TYPE}
          placeholder={FieldLabel.PROJECT_TYPE}
          required
          returnObject={false}
          asText={isView}
          customOnChange={(value) => {
            methods.setValue("temp_bussiness_type", value);
          }}
        />

        {/* {businessType?.label?.toLowerCase()?.includes("non") && (
          <SelectKomoditasUsaha
            controllerName={`${prefix}bussiness_commodities`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.PROJECT_COMMODITY}
            returnObject={false}
            required
            asText={isView}
          />
        )} */}

        {location.pathname.includes("non-perhutanan-sosial") && (
          <SelectKomoditasUsaha
            controllerName={`${prefix}bussiness_commodities`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.PROJECT_COMMODITY}
            returnObject={false}
            required
            asText={isView}
          />
        )}

        <div
          className={[
            "col-span-2 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
          ]}
        >
          <InputForm
            controllerName={`${prefix}bussiness_duration`}
            wrapperClass={"col-span-1 w-full flex gap-1"}
            label={FieldLabel.PROJECT_AGE}
            required
            asText={isView}
            className="w-full"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />

          <AtomSelect
            controllerName={`${prefix}bussiness_duration_type`}
            className="basic-single mt-2 flex-1"
            wrapperClass={"col-span-1"}
            returnObject={false}
            label={<span>&nbsp;</span>}
            options={[
              { value: "Bulan", label: "Bulan" },
              { value: "Tahun", label: "Tahun" },
            ]}
            asText={isView}
          />
        </div>

        <InputForm
          controllerName={`${prefix}last_productivity`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.PRODUCTIVITY}
          placeholder={FieldLabel.PRODUCTIVITY}
          required
          asText={isView}
        />

        <InputForm
          controllerName="capacity_productivity"
          wrapperClass={"col-span-1 gap-1 w-full"}
          className="w-full"
          label={FieldLabel.BUSINESS_CAPACITY}
          placeholder={FieldLabel.BUSINESS_CAPACITY}
          required
          asText={isView}
        />

        <InputCurrencyForm
          name={`${prefix}last_price`}
          label={FieldLabel.PRICE}
          required
          prefix="Rp "
          placeholder="Rp"
          asText={isView}
        />

        <div className={"col-span-2"}>
          {/* {incomeStatementFileField || isView ? (
            <>
              <label className="font-semibold text-sm my-1.5 block">
                {FieldLabel.UPLOAD}
              </label>
              <FileInfoField
                title={incomeStatementFile ? incomeStatementFile.name : ""}
                desc={
                  incomeStatementFile
                    ? `${incomeStatementFile.size} KB`
                    : "Tidak ada file"
                }
                showIcon
                showDownload={true}
                showClose={true}
                onCloseClick={() => {
                  methods.setValue(`income_statement_file`, "");
                }}
                buttonLabel="Lihat Berkas"
                onDownloadClick={() => {
                  openDocs(`income_statement_file`);
                }}
              />
            </>
          ) : (
            <InputFormUpload
              controllerName={`income_statement_file`}
              label={FieldLabel.UPLOAD}
              required
              readOnly={isView}
            />
          )} */}
        </div>

        <InputForm
          controllerName={`${prefix}market_target`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.GOAL}
          placeholder={FieldLabel.GOAL}
          required
          asText={isView}
        />

        {/* <InputForm
          controllerName={`${prefix}partner`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.PARTNER}
          placeholder={FieldLabel.PARTNER}
          required
          asText={isView}
        /> */}

        {fields.map((field, idx) => (
          <>
            <div
              key={field.id}
              className={classNames("col-span-2 flex gap-6 items-end")}
            >
              <InputForm
                controllerName={`business_partner.${idx}.value`}
                label={FieldLabel.PARTNER}
                placeholder={FieldLabel.PARTNER}
                className="py-2 px-4 mt-2 border w-full rounded-md flex-1 "
                asText={isView}
              />
              {idx > 0 && (
                <div
                  className={classNames(
                    "h-full flex items-end",
                    methods.formState.errors.business_partner[idx] &&
                      "items-center"
                  )}
                >
                  <button
                    className="h-10 w-10 bg-red-600 hover:bg-red-500 transition-all duration-200 flex justify-center items-center rounded-md text-white"
                    onClick={() => remove(idx)}
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
              )}
            </div>
          </>
        ))}
        <div className="col-span-2 flex justify-end">
          {!isView && (
            <Button
              label={
                <>
                  <RiAddFill />
                  <p>Mitra Usaha</p>
                </>
              }
              className="bg-white hover:bg-neutral-white hover:text-black border border-gray-300"
              outline
              type="button"
              onClick={() => {
                append();
              }}
            />
          )}
        </div>
        {/* <PartnerSection formPrefix={formPrefix} mode="view" /> */}
      </div>

      <div className="flex flex-col gap-2 ">
        <div className="flex justify-between gap-4 bg-[#E1F1D6] px-6 py-5 rounded-md mb-2">
          <h5 className="text-lg font-semibold text-primary-700">
            Prospek Usaha
          </h5>
          <div className="flex items-center text-base gap-2 text-primary-700 font-semibold cursor-pointer">
            Tutup <RiArrowUpSLine strokeWidth={1.5} />
          </div>
        </div>

        {/* {isView ? (
          <InputForm
            controllerName={`${prefix}bussiness_manage_type`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            wrapperClass={"col-span-2"}
            label={FieldLabel.TYPE}
            placeholder={FieldLabel.TYPE}
            asText={isView}
          />
        ) : (
          <div className={"col-span-2"}>
            <label className={"block font-semibold text-sm mb-2"}>
              {FieldLabel.TYPE}
            </label>

            <Radio
              controllerName={`${prefix}bussiness_manage_type`}
              inputWrapperClass={"flex gap-2"}
              wrapperClass={"col-span-1"}
              value={"Dikelola Sendiri"}
              label={<span>Dikelola Sendiri</span>}
            />
            <Radio
              controllerName={`${prefix}bussiness_manage_type`}
              inputWrapperClass={"flex gap-2"}
              wrapperClass={"col-span-1"}
              value={"Dikelola Orang Lain"}
              label={<span>Dikelola Orang Lain</span>}
            />
          </div>
        )} */}

        {isView ? (
          <InputForm
            controllerName={`${prefix}purpose_loan_application`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            wrapperClass={"col-span-2"}
            label={FieldLabel.LOAN_GOAL}
            placeholder={FieldLabel.LOAN_GOAL}
            asText={isView}
          />
        ) : (
          <div className={"col-span-2"}>
            <label className={"block font-semibold text-sm mb-2"}>
              {FieldLabel.LOAN_GOAL}
            </label>

            <Radio
              controllerName={`${prefix}purpose_loan_application`}
              inputWrapperClass={"flex gap-2 items-center"}
              wrapperClass={"col-span-1"}
              value={"Untuk Modal Kerja"}
              label={<span>Untuk Modal Kerja</span>}
            />
            <Radio
              controllerName={`${prefix}purpose_loan_application`}
              inputWrapperClass={"flex gap-2 items-center"}
              wrapperClass={"col-span-1"}
              value={"Untuk Investasi"}
              label={<span>Untuk Investasi</span>}
            />
          </div>
        )}

        <div className={"col-span-2"}>
          <RichEditorForm
            key={`loan_goal_essay--${mode}`}
            name={`${prefix}purpose_loan_application_desc`}
            label={FieldLabel.LOAN_GOAL_ESSAY}
            placeholder={isView ? "" : FieldLabel.LOAN_GOAL_ESSAY}
            className={isView ? "" : "quill-4"}
            required
            readOnly={isView}
            hideToolbar={isView}
            theme={isView ? "" : "snow"}
          />
        </div>

        <div className="col-span-2 md:grid md:grid-cols-3 gap-4 space-y-4 md:space-y-0">
          <InputCurrencyForm
            name={`${prefix}revenue`}
            label={FieldLabel.GROSS}
            required
            prefix="Rp "
            placeholder="Rp"
            asText={isView}
          />

          <InputCurrencyForm
            name={`${prefix}cost_price`}
            label={FieldLabel.CAPITAL}
            required
            prefix="Rp "
            placeholder="Rp"
            asText={isView}
          />

          <InputCurrencyForm
            name={`${prefix}net_income`}
            label={FieldLabel.NET}
            required
            prefix="Rp "
            placeholder="Rp"
            asText={isView}
          />
        </div>

        <div
          className={[
            "col-span-1 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
          ]}
        >
          {/* <InputNumeric
            controllerName={`${prefix}bussiness_cycle`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.CYCLE}
            required
            asText={isView}
          /> */}
          <InputForm
            controllerName="bussiness_cycle"
            wrapperClass={"col-span-1 w-full flex gap-1"}
            label={FieldLabel.CYCLE}
            required
            asText={isView}
            className="w-full"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />

          <AtomSelect
            controllerName={`${prefix}bussiness_cycle_unit`}
            className="basic-single mt-2 flex-1"
            wrapperClass={"col-span-1"}
            returnObject={false}
            label={<span>&nbsp;</span>}
            options={[
              { value: "Bulan", label: "Bulan" },
              { value: "Tahun", label: "Tahun" },
            ]}
            asText={isView}
          />
        </div>

        {/* <InputForm
          controllerName={`${prefix}qty_exist_commodity`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.COMMODITY_QTY}
          placeholder={FieldLabel.COMMODITY_QTY}
          required
          asText={isView}
        /> */}

        <div className={"col-span-2"}>
          {incomeStatementFileField || isView ? (
            <>
              <label className="font-semibold text-sm my-1.5 block">
                {FieldLabel.UPLOAD}
              </label>
              <FileInfoField
                title={incomeStatementFile ? incomeStatementFile.name : ""}
                desc={
                  incomeStatementFile
                    ? `${incomeStatementFile.size} KB`
                    : "Tidak ada file"
                }
                showIcon
                showDownload={true}
                showClose={!isView}
                onCloseClick={() => {
                  methods.setValue(`income_statement_file`, "");
                }}
                buttonLabel="Lihat Berkas"
                onDownloadClick={() => {
                  openDocs(`income_statement_file`);
                }}
              />
            </>
          ) : (
            <InputFormUpload
              controllerName={`income_statement_file`}
              label={FieldLabel.UPLOAD}
              required
              readOnly={isView}
            />
          )}
        </div>
      </div>
    </CardForm>
  );
}

const PartnerSection = forwardRef(({ formPrefix, mode, ...props }, ref) => {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `${prefix}bussiness_partner`,
  });

  const watchFieldArray = useWatch({
    control: methods.control,
    name: `${prefix}bussiness_partner`,
  });

  const [isEdit, isAdd, isView] = useMemo(
    () => ["edit", "add", "view"].map((e) => e === mode),
    []
  );

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  return (
    <>
      {controlledFields.map((field, key) => (
        <div className="flex items-center space-x-4 col-span-2">
          <InputForm
            key={field.id}
            controllerName={`${prefix}bussiness_partner.${key}.value`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            wrapperClass={"col-span-2"}
            label={FieldLabel.PARTNER}
            placeholder={FieldLabel.PARTNER}
            required
            asText={false}
          />
          {key > 0 && (
            <div className="space-y-2">
              <label>&nbsp;</label>
              <Button
                type="button"
                onClick={() => remove(key)}
                className="p-3 rounded-lg bg-error-600 flex-none"
                label={<BiTrash className="text-white" />}
              />
            </div>
          )}
        </div>
      ))}
      <div className="flex justify-end col-span-2 border-t pt-4">
        <Button
          type="button"
          onClick={() => append({ value: "" })}
          className="px-2 py-3 border rounded-lg bg-white w-fit h-fit"
          label={
            <div className="flex items-center gap-2 text-sm">
              <BiPlus />
              Mitra Usaha
            </div>
          }
        />
      </div>
    </>
  );
});

ProjectSection.propTypes = {};

export default ProjectSection;
