/* eslint-disable no-unused-vars */
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BottomFormAction, ConfirmationModal, HeaderLabel } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { enqueueSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import ProgressModal from "../../../../../components/molecules/Modal/ProgressModal";
import { getErrorMessage } from "../../../../../helpers";
import {
  getPengajuanAnggotaMemberDetail,
  putPengajuanAnggotaMemberPerhutananSosial,
} from "services/fdb/akunKelompok";
import { Formulir } from "./components/formulir";
import { DataCalonDebitur } from "./components/data-calon-debitur";
import { DataPasangan } from "./components/data-pasangan";
import { Alamat } from "./components/alamat";
import { InformasiKeuangan } from "./components/informasi-keuangan";
import { DataUsahaYangAkanDibiayai } from "./components/data-usaha-yang-akan-dibiayai";
import { Jaminan } from "./components/jaminan";
import { DataPermohonanPinjaman } from "./components/data-permohonan-pinjaman";
import { Agreement } from "./components/agreement";
import { permohonanSchemaPS } from "./validation-schema";
import dayjs from "dayjs";

const AkunKelompokPSPengajuanDaftarMemberForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const splitPath = location?.pathname?.split("/").filter((e) => !!e);
  const mode = params.id ? splitPath.at(-2) : splitPath?.pop();
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const [draftModal, setDraftModal] = useState();
  const [confirmModal, setConfirmModal] = useState();

  const routes = useMemo(
    () => [
      {
        label: "Formulir Pendaftaran",
        path: "/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan",
      },
      {
        label: "Pendaftaran Perorangan",
        path:
          "/" +
          location.pathname
            ?.split("/")
            ?.filter((e) => !!e)
            ?.slice(0, -3)
            .join("/"),
      },
      {
        label: "Formulir Permohonan Layanan Tunda Tebang",
        path: location.pathname,
      },
    ],
    [location.pathname]
  );

  const { data } = useQuery({
    queryKey: [
      "pengajuan-permohonan-perhutanan-sosial-detail",
      location?.state?.member?.group_profile_member_id,
    ],
    queryFn: async ({ queryKey }) => {
      const res = await getPengajuanAnggotaMemberDetail(queryKey[1]);
      return res.data.data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!location?.state?.member?.group_profile_member_id,
    onSuccess: (res) => {
      const state = location.state;

      methods.reset({
        group_name: state?.member?.group_name,

        email: res?.email ?? state?.member?.email,
        identity_number: res?.identity_number ?? state?.member?.identity_number,
        identity_file: res?.identity_file,
        name: res?.name ?? state?.member?.name_debtor,
        place_of_birth: res?.place_of_birth,
        date_of_birth: res?.date_of_birth
          ? new Date(res?.date_of_birth)
          : new Date(state?.member?.date_of_birth),
        religion: res?.religion,
        gender: res?.gender ?? state?.member?.gender,
        phone: res?.phone ?? state?.member?.phone,
        family_card_number: res?.family_card_number,
        family_card_file: res?.family_card_file,
        npwp: res?.npwp ?? state?.member?.group_profile_member?.npwp,
        main_job: res?.main_job,
        other_job: res?.other_job,
        job_doc: res?.job_doc,
        couple_identity_number: res?.couple_identity_number,
        couple_identity_file: res?.couple_identity_file,
        couple_name: res?.couple_name,
        couple_place_of_birth: res?.couple_place_of_birth,
        couple_date_of_birth: res?.couple_date_of_birth
          ? new Date(res?.couple_date_of_birth)
          : null,

        // ! TIDAK ADA FIELD
        couple_religion: res?.couple_religion,

        couple_gender: res?.couple_gender,
        couple_phone: res?.couple_phone,
        couple_job: res?.couple_job,
        province_id: res?.province_id,
        city_id: res?.city_id,
        district_id: res?.district_id,
        village_id: res?.village_id,
        zip_code_id: res?.zip_code_id,
        address: res?.address,
        rt: res?.rt,
        rw: res?.rw,
        same_address: res?.same_address,
        domicile_province_id: res?.domicile_province_id,
        domicile_city_id: res?.domicile_city_id,
        domicile_district_id: res?.domicile_district_id,
        domicile_village_id: res?.domicile_village_id,
        domicile_zip_code_id: res?.domicile_zip_code_id,
        domicile_address: res?.domicile_address,
        domicile_rt: res?.domicile_rt,
        domicile_rw: res?.domicile_rw,
        domicile_since_year: res?.domicile_since_year
          ? new Date(res?.domicile_since_year)
          : undefined,
        latitude: res?.latitude,
        longitude: res?.longitude,
        routine_outcome_per_month: res?.routine_outcome_per_month,
        biggest_outcome: res?.biggest_outcome,
        use_largest_outcome: res?.use_largest_outcome,
        salary_slip_file: res?.salary_slip_file,
        business_type_id: res?.business_type_id,
        business_commodity: res?.business_commodity,
        business_since: res?.business_since
          ? res?.business_since.split(" ")[0]
          : undefined,
        business_since_type: res?.business_since
          ? res?.business_since.split(" ")[1]
          : undefined,
        business_material: res?.business_material,
        business_capacity: res?.business_capacity,
        business_economic_values: res?.business_economic_values,
        business_partner:
          res?.business_partner?.length > 0
            ? res.business_partner.map((partner) => ({ value: partner }))
            : [""],
        business_target: res?.business_target,
        business_panning_application: res?.business_panning_application,
        business_revenue: res?.business_revenue,
        business_cost_price: res?.business_cost_price,
        business_net_income: res?.business_net_income,
        business_cycle: res?.business_cycle ?? 1,
        business_cycle_unit: res?.business_cycle_unit ?? "bulan",

        // ! TIDAK ADA FIELDNYA
        amount: "",

        business_loan_application:
          res?.applicant_member?.request?.social_forestry_applications
            ?.application_value,

        // ! TIDAK ADA FIELDNYA
        business_loan_application_desc: "",

        business_loan_application_period:
          res?.applicant_member?.request?.social_forestry_applications
            ?.period_value,
        business_loan_application_period_type:
          res?.applicant_member?.request?.social_forestry_applications
            ?.period_type,
        business_loan_application_paid:
          res?.applicant_member?.request?.social_forestry_applications
            ?.installment_value,
        business_loan_application_paid_desc:
          res?.applicant_member?.request?.social_forestry_applications
            ?.installment_spell,
        business_colleterals:
          res?.colleterals.length > 0
            ? res?.colleterals.map((collateral) => ({
                id: collateral.id,
                collateral_type: collateral.collateral_type,
                collateral_fee: collateral?.collateral_fee,
                collateral_file: collateral?.collateral_file,
              }))
            : [
                {
                  id: undefined,
                  collateral_type: "",
                  collateral_fee: 0,
                  collateral_file: undefined,
                },
              ],

        income_info:
          res?.income_info.length > 0
            ? [
                {
                  id: res?.income_info[0].id,
                  type: res?.income_info[0].type,
                  income: res?.income_info[0].income,
                  income_cycle: 1,
                  income_cycle_unit: "Bulan",
                },
              ]
            : [],
        agree: false,
        created_at: res?.created_at,
        created_at_display: `${dayjs(res?.created_at).format("DD MMMM YYYY")}`,
        created_at_place: res?.created_at_place,
      });
    },
  });

  const methods = useForm({
    resolver: yupResolver(permohonanSchemaPS),
    mode: "onChange",
  });

  const mutatePermohonan = useMutation(
    ["PENGAJUAN DAFTAR PERMOHONAN"],
    ({ payload, params }) =>
      putPengajuanAnggotaMemberPerhutananSosial(payload, params)
  );

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDraftClick = () => {
    setDraftModal(true);
  };

  const handleSubmitClick = (e) => {
    if (e) {
      e?.preventDefault();
    }

    methods.handleSubmit((data) => {
      setConfirmModal(true);
    })();
  };

  const handleSaveDraft = () => {
    const data = methods.getValues();

    const payload = {
      ...data,
      business_since:
        data?.business_since && data?.business_since_type
          ? `${data?.business_since} ${data?.business_since_type}`
          : null,
      date_of_birth: dayjs(data?.date_of_birth).format("YYYY-MM-DD"),
      couple_date_of_birth: data?.couple_date_of_birth
        ? dayjs(data?.couple_date_of_birth).format("YYYY-MM-DD")
        : null,
      domicile_since_year: dayjs(data?.domicile_since_year).format(
        "YYYY-MM-DD"
      ),
      business_colleterals: data.business_colleterals
        ? data.business_colleterals
            .filter((e) => typeof e.selected_file !== "undefined")
            .map((el) => ({
              ...el,
              collateral_file: el.selected_file.id,
            }))
        : [],
      business_partner: data?.business_partner
        .filter((e) => e.value !== "")
        .map((e) => e.value),
      income_info: data?.income_info
        ?.filter((incomeInfo) => incomeInfo.income !== "")
        .map((income) => ({
          ...income,
          income_cycle: 1,
          income_cycle_unit: "Bulan",
        })),
      application: {
        application_value: data?.business_loan_application,
        application_spell: data?.business_loan_application_desc,
        period_type: data?.business_loan_application_period_type,
        period_value: data?.business_loan_application_period,
        installment_value: data?.business_loan_application_paid,
        installment_spell: data?.business_loan_application_paid_desc,
      },
    };

    delete payload.business_loan_application;
    delete payload.business_loan_application_desc;
    delete payload.business_loan_application_period_type;
    delete payload.business_loan_application_period;
    delete payload.business_loan_application_paid;
    delete payload.business_loan_application_paid_desc;

    mutatePermohonan.mutate(
      {
        payload: payload,
        params: {
          is_draft: true,
          is_non_forestry: false,
          group_profile_member_id:
            location.state.member.group_profile_member_id,
          applicant_member_id: location.pathname.includes("member")
            ? location.state.member.id
            : location.state.member.applicant_member_id,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            message: "Data Berhasil Disimpan!",
            variant: "success",
          });

          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            message: getErrorMessage(err),
            variant: "error",
          });

          window.scrollTo({ behavior: "smooth", top: 0 });
        },
      }
    );
  };

  const handleSubmitRegister = () => {
    methods.handleSubmit((data) => {
      const payload = {
        ...data,
        business_since:
          data?.business_since && data?.business_since_type
            ? `${data?.business_since} ${data?.business_since_type}`
            : null,
        date_of_birth: dayjs(data?.date_of_birth).format("YYYY-MM-DD"),
        couple_date_of_birth: data?.couple_date_of_birth
          ? dayjs(data?.couple_date_of_birth).format("YYYY-MM-DD")
          : null,
        domicile_since_year: dayjs(data?.domicile_since_year).format(
          "YYYY-MM-DD"
        ),
        business_colleterals: data.business_colleterals
          ? data.business_colleterals
              .filter((e) => typeof e.selected_file !== "undefined")
              .map((el) => ({
                ...el,
                collateral_file: el.selected_file.id,
              }))
          : [],
        business_partner: data?.business_partner
          .filter((e) => e.value !== "")
          .map((e) => e.value),
        income_info: data?.income_info
          ?.filter((incomeInfo) => incomeInfo.income !== "")
          .map((income) => ({
            ...income,
            income_cycle: 1,
            income_cycle_unit: "Bulan",
          })),
        application: {
          application_value: data?.business_loan_application,
          application_spell: data?.business_loan_application_desc,
          period_type: data?.business_loan_application_period_type,
          period_value: data?.business_loan_application_period,
          installment_value: data?.business_loan_application_paid,
          installment_spell: data?.business_loan_application_paid_desc,
        },
      };

      delete payload.business_loan_application;
      delete payload.business_loan_application_desc;
      delete payload.business_loan_application_period_type;
      delete payload.business_loan_application_period;
      delete payload.business_loan_application_paid;
      delete payload.business_loan_application_paid_desc;
      delete payload.created_at;

      mutatePermohonan.mutate(
        {
          payload: payload,
          params: {
            is_draft: false,
            is_non_forestry: false,
            group_profile_member_id:
              location.state.member.group_profile_member_id,
            applicant_member_id: location.pathname.includes("member")
              ? location.state.member.id
              : location.state.member.applicant_member_id,
          },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              message: "Data Berhasil Disimpan!",
              variant: "success",
            });

            navigate(-1);
          },
          onError: (err) => {
            enqueueSnackbar({
              message: getErrorMessage(err),
              variant: "error",
            });

            window.scrollTo({ behavior: "smooth", top: 0 });
          },
        }
      );
    })();
  };

  console.log(data, "HERE");

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onClose={setDraftModal}
          onSubmit={handleSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={handleSubmitRegister}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={draftModal && mutatePermohonan.isLoading}
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text="Formulir Permohonan Perhutanan Sosial"
          description="Buat permohonan baru."
        />

        <div className="border-b" />

        <FormProvider {...methods}>
          <form onSubmit={handleSubmitClick} className="space-y-8">
            <Formulir />
            <DataCalonDebitur data={data} />
            <DataPasangan data={data} />
            <Alamat data={data} />
            <InformasiKeuangan />
            <DataUsahaYangAkanDibiayai />
            <Jaminan />
            <DataPermohonanPinjaman />
            <Agreement />

            <BottomFormAction
              lastStep={true}
              backButtonProps={{
                type: "button",
              }}
              draftButtonProps={{
                type: "button",
              }}
              submitButtonProps={{
                type: "submit",
              }}
              hideDraft={isView}
              hideSubmit={isView}
              backButtonAction={handleBackClick}
              submitActionButton={handleSubmitClick}
              disableDrafButton={false}
              drafButtonAction={handleDraftClick}
              disableButtonSubmit={mutatePermohonan.isLoading}
            />
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AkunKelompokPSPengajuanDaftarMemberForm;
