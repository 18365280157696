import React from "react";

import { Header, Sidebar } from "components";
import { motion } from "framer-motion";
import background from "../../../assets/backgrounds/Background.png";
import Footer from "components/atoms/Footer";

const BasicLayout = ({ children, sidebar, props }) => {
  return (
    <div className="bg-[#FCFCFD]">
      <img src={background} alt="" className="absolute z-0 w-full" />
      <div className="md:px-28 flex flex-col h-full mx-auto relative">
        <Header />
        <div className="flex sm:flex-col lg:flex-row flex-1">
          {sidebar && <Sidebar props={props}>{children}</Sidebar>}
          <div id="_layout-content" className="w-full">
            <motion.main
              className={`flex-1 paragraph ${
                sidebar && "md:pl-10"
              } mb-4`}
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
              {children}
            </motion.main>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BasicLayout;
