import {
  SAVE_USER_DRAFT_DATA,
  RESET_USER_DRAFT_DATA,
  UPDATE_USER_DRAFT_DATA,
} from "../actions";

const initialState = {
  data: { user_id: null },
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_DRAFT_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case UPDATE_USER_DRAFT_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_USER_DRAFT_DATA:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};
