import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { Button, InputFormUpload, Table } from "components";
import FileInfoField from "components/atoms/FileInfoField";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { humanizeFileSize } from "helpers";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { RiSearchLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import {
  followUpDebtor,
  getDisbursementDetail,
  updateDisbursement,
} from "services/fdb/disbursement";
import * as yup from "yup";

const statusBadge = {
  "Belum Melengkapi": "bg-[#FFFAEB] text-[#B54708]",
  "Sudah Melengkapi": "bg-[#ECFDF3] text-[#1C7D44]",
  "Belum Assesment": "bg-[#FFFAEB] text-[#B54708]",
  "Sudah Assesment": "bg-[#ECFDF3] text-[#1C7D44]",
};

const formScheme = yup.object({
  file: yup.string().required().label("Surat Permohonan Pencairan"),
});

const PermohonanPencairanDetail = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    filters: {
      serach: "",
    },
  });

  const handleChangeLimit = (e) => {
    setFilters((prev) => ({ ...prev, limit: e, page: 1 }));
  };

  const handleChangePage = (e) => {
    setFilters((prev) => ({ ...prev, page: e }));
  };

  const [stage, setStage] = useState(2);

  const params = useParams();
  const [searchParams] = useSearchParams();

  const isView = searchParams.get("status") !== "Belum Diajukan";

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["GET_DISBURSEMENT_DETAIL", params?.id, filters],
    getDisbursementDetail,
    {
      select: (data) => data?.data?.data,
    }
  );

  const mutateFollowUp = useMutation({ mutationFn: followUpDebtor });
  const mutateUpdate = useMutation({ mutationFn: updateDisbursement });

  const breadcrumbs = useMemo(
    () => [
      {
        label: "Perohonan Pencairan",
        path: "/kelompok/non-perhutanan-sosial/permohonan-pencairan",
      },
      {
        label: "Detail",
        path: "",
      },
    ],
    []
  );

  const paginator = useMemo(() => {
    return {
      page: filters.page,
      limit: filters.limit,
      total: data?.applicant?.meta?.total,
      previous_pages: data?.applicant?.meta?.previous_page_url,
      next_pages: data?.applicant?.meta?.next_page_url,
    };
  }, [data, filters]);

  const methods = useForm({ resolver: yupResolver(formScheme) });

  const handleFollowUp = useCallback(
    (data) => {
      mutateFollowUp.mutate(data.id, {
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil follow up anggota",
          });
          queryClient.invalidateQueries([
            "GET_DISBURSEMENT_DETAIL",
            params?.id,
            filters,
          ]);
        },
        onError: () => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal follow up anggota",
          });
        },
      });
    },
    [filters, mutateFollowUp, queryClient, params?.id]
  );

  const onSubmit = (data) => {
    mutateUpdate.mutate(
      { id: params?.id, data },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil mengajukan permohonan pencairan",
          });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Berhasil mengajukan permohonan pencairan",
          });
        },
      }
    );
  };

  const columns1 = useMemo(
    () => [
      {
        id: "debtor_name",
        title: "Nama Debitur",
        dataIndex: "debtor_name",
        className: "",
        columnClassName:
          "w-[213px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        // render: (value) => dayjs(value.created_at).format("DD MMM YYYY"),
      },
      {
        id: "identity_number",
        title: "NIK",
        dataIndex: "identity_number",
        className: "",
        columnClassName:
          "w-[213px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        // render: (value) => dayjs(value.created_at).format("DD MMM YYYY"),
      },
      {
        id: "phone",
        title: "No Telepon",
        dataIndex: "phone",
        className: "",
        columnClassName:
          "w-[213px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        // render: (value) => dayjs(value.created_at).format("DD MMM YYYY"),
      },
      {
        id: "disbursement_amount",
        title: "Nilai Pencairan",
        dataIndex: "disbursement_amount",
        className: "",
        columnClassName:
          "w-[213px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        // render: (value) => dayjs(value.created_at).format("DD MMM YYYY"),
      },
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      {
        id: "name",
        title: "Nama Debitur",
        dataIndex: "name",
        className: "",
        columnClassName:
          "w-[213px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        // render: (value) => dayjs(value.created_at).format("DD MMM YYYY"),
      },
      {
        id: "identity_number",
        title: "NIK",
        dataIndex: "identity_number",
        className: "",
        columnClassName:
          "w-[213px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        // render: (value) => dayjs(value.created_at).format("DD MMM YYYY"),
      },
      {
        id: "phone",
        title: "No Telepon",
        dataIndex: "phone",
        className: "",
        columnClassName:
          "w-[213px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        // render: (value) => dayjs(value.created_at).format("DD MMM YYYY"),
      },
      {
        id: "application_fee",
        title: "Nilai Pencairan",
        dataIndex: "application_fee",
        className: "",
        columnClassName:
          "w-[213px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) =>
          value ? `Rp ${Math.floor(value).toLocaleString()}` : "-",
      },
      {
        id: "status_string",
        title: "Status",
        dataIndex: "status_string",
        className: "",
        columnClassName:
          "w-[213px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => (
          <div
            className={classNames(
              "py-[2px] px-2 text-xs font-medium rounded-full w-max",
              statusBadge[value]
            )}
          >
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "id",
        className: "",
        sortable: true,
        columnClassName: "w-[150px] text-center text-ellipsis overflow-hidden",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <div className="flex justify-center">
              {data?.follow_up === false && (
                <Button
                  label="Follow Up"
                  size="sm"
                  className="bg-primary-600 text-white text-xs font-semibold"
                  onClick={() => handleFollowUp(data)}
                />
              )}
            </div>
          );
        },
      },
    ],
    [handleFollowUp]
  );

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-hidden");

    return () => {
      layouContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  useEffect(() => {
    if (data) {
      methods.reset({ file: data?.file });
    }
  }, [methods, data]);

  return (
    <section className="space-y-5 relative">
      <FormProvider {...methods}>
        <div className="bg-[#FFFFFF] rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs routes={breadcrumbs} />
            <div className="mt-6 flex flex-row">
              <div className="flex-1">
                <div className="flex flex-col">
                  <span className="font-semibold text-[24px] text-[#101828]">
                    Detail
                  </span>
                  <span className="font-normal text-[14px] text-[#667085]"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CardForm label="Informasi Perjanjian">
          <div className="flex flex-col gap-5">
            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col gap-2">
                <h5 className="text-sm font-semibold">Tahapan Pencairan</h5>
                <p>{data?.phase}</p>
              </div>
            </div>
          </div>
        </CardForm>

        <UploadSuratPermohonanPencairan isView={isView} />

        <CardForm label="Permohonan Anggota">
          <div
            className={
              "rounded-2xl border border-gray-200 bg-white p-3 space-y-4 shadow-sm"
            }
          >
            <div className={"flex flex-wrap items-center gap-4"}>
              <div className="relative w-full">
                <input
                  type="text"
                  className="h-12 border w-full rounded-md outline-none focus:outline-none pl-[50px] pr-4 focus:ring-1 focus:ring-primary-600"
                  placeholder="Cari"
                />
                <RiSearchLine
                  className="absolute top-3 left-4 text-[#98A2B3]"
                  size={24}
                />
              </div>
            </div>

            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={data?.phase !== "Tahap 1" ? columns1 : columns2}
              dataSource={data?.applicant?.data ?? []}
              isLoading={isLoading}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeLimit}
              pagination={paginator}
            />
          </div>
        </CardForm>

        <div className="fixed bottom-0 left-0 w-full p-3 flex justify-center items-center">
          <div className="flex w-[80%] bg-white rounded-3xl p-6 gap-4 border justify-end">
            {!isView && (
              <>
                <Button
                  label="Batal"
                  outline
                  className="text-sm font-medium border-gray-300"
                />

                <Button
                  label="Ajukan Permohonan Pencairan"
                  className="bg-primary-600 hover:bg-primary-700 text-white text-sm font-medium"
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </>
            )}
          </div>
        </div>
      </FormProvider>
    </section>
  );
};

const UploadSuratPermohonanPencairan = ({ isView }) => {
  const methods = useFormContext();

  const fileField = useWatch({ control: methods.control, name: "file" });

  const [disbursementFile, setDisbursementFile] = useState();

  const uploadFileDisburement = useCallback(async () => {
    if (
      typeof fileField !== "object" ||
      typeof fileField === "undefined" ||
      fileField === null
    )
      return;

    const formData = new FormData();
    formData.append("file", fileField);

    const response = await fileUpload(formData);

    setDisbursementFile(response.data.data);
    methods.setValue("file", response.data.data.id);
  }, [fileField, methods]);

  useEffect(() => {
    uploadFileDisburement();
  }, [uploadFileDisburement]);

  const getDocument = useCallback(async () => {
    if (
      typeof fileField !== "string" ||
      typeof fileField === "undefined" ||
      fileField === null ||
      fileField === ""
    )
      return;

    const response = await getFile(fileField);

    setDisbursementFile(response.data.data);
  }, [fileField]);

  useEffect(() => {
    getDocument();
  }, [getDocument]);

  return (
    <CardForm label="Upload Surat Permohonan Pencairan">
      <div className="space-y-6">
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Format Pencairan</h5>
          <FileInfoField
            title="Surat Permohonan Pencairan.docx"
            desc="200KB"
            showIcon
            showDownload={true}
            showClose={false}
            buttonLabel="Unduh Dokumen"
            onCloseClick={() => {}}
            onDownloadClick={() => {}}
          />

          {disbursementFile ? (
            <FileInfoField
              title="Surat Permohonan Pencairan.docx"
              desc={humanizeFileSize(disbursementFile.size)}
              showIcon
              showDownload={true}
              showClose={!isView}
              buttonLabel="Unduh Dokumen"
              onCloseClick={() => {
                setDisbursementFile(null);
                methods.setValue("file", null);
              }}
              onDownloadClick={() => {
                window.open(disbursementFile.url);
              }}
            />
          ) : (
            <InputFormUpload
              label="Upload Surat Permohonan"
              controllerName="file"
            />
          )}
        </div>
      </div>
    </CardForm>
  );
};

export default PermohonanPencairanDetail;
