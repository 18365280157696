import { InputForm } from "components"
import CardForm from "components/molecules/CardForm"
import { useFormContext } from "react-hook-form"

const InformasiAkun = () => {
  const {
    watch,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useFormContext()
  return (
    <CardForm label={"INFORMASI AKUN"}>
      <div className="flex-1 pt-2">
        <InputForm
          controllerName={`personal_account_information.email`}
          className={`py-2 px-4 mt-2 border w-full rounded-md ${
            errors?.personal_account_information?.email ? "border-[#F04438]" : "border-green"
          }`}
          label={"Email"}
          placeholder={"Email"}
          required={true}
        />
      </div>
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <InputForm
            controllerName={`personal_account_information.password`}
            className={`py-2 px-4 mt-2 border w-full rounded-md ${
              errors?.personal_account_information?.password ? "border-[#F04438]" : "border-green"
            }`}
            label={"Kata Sandi"}
            placeholder={"Kata Sandi"}
            type={"password"}
            required={true}
          />
        </div>
        <div className="flex-1 pt-2">
          <InputForm
            controllerName={`personal_account_information.confirmation_password`}
            className={`py-2 px-4 mt-2 border w-full rounded-md ${
              errors?.personal_account_information?.confirmation_password ? "border-[#F04438]" : "border-green"
            }`}
            label={"Ulangi Kata Sandi"}
            placeholder={"Ulangi Kata Sandi"}
            type={"password"}
            required={false}
          />
        </div>
      </div>
    </CardForm>
  )
}

export default InformasiAkun