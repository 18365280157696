import { InputForm } from "components";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import { useFormContext } from "react-hook-form";

const Alamat = ({
  countries = [],
  setCountries = () => {},
  provinces = [],
  setProvinces = () => {},
  cities = [],
  setCities = () => {},
  districts = [],
  setDistricts = () => {},
  wards = [],
  setWards = () => {},
  zipCodes = [],
  setZipCodes = () => {},
}) => {

  const {
    watch,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useFormContext()
  return (
    <CardForm label={"ALAMAT"}>
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <AtomSelect
            controllerName={`address.province`}
            options={provinces}
            className="basic-single mt-2"
            label="Provinsi"
            returnObject={false}
            required={true}
          />
        </div>
        <div className="flex-1 pt-2">
          <AtomSelect
            controllerName={`address.city`}
            options={cities}
            className="basic-single mt-2"
            label="Kota/Kabupaten"
            returnObject={false}
            required={true}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <AtomSelect
            controllerName={`address.district`}
            options={districts}
            className="basic-single mt-2"
            label="Kecamatan"
            returnObject={false}
            required={true}
          />
        </div>
        <div className="flex-1 pt-2">
          <AtomSelect
            controllerName={`address.village`}
            options={wards}
            className="basic-single mt-2"
            label="Kelurahan/Desa"
            returnObject={false}
            required={true}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <AtomSelect
            controllerName={`address.zip_code`}
            className={`basic-single mt-2 ${
              errors?.address?.zip_code ? "border-[#F04438]" : "border-green"
            }`}
            options={zipCodes}
            required={true}
            label={"Kode Pos"}
            returnObject={false}
            placeholder={"Kode Pos"}
          />
        </div>
        <div className="flex-1 pt-2">
          <InputForm
            controllerName={`address.full_address`}
            className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
            label={"Alamat Lengkap"}
            placeholder={"Alamat Lengkap"}
            textArea={true}
            required={true}
          />
        </div>
      </div>
    </CardForm>
  )
}

export default Alamat;