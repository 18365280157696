import axios from 'axios';
import { config_daprog } from 'app/config';
import axiosInstance from 'app/interceptors';

const headers = {
  'Content-Type': 'application/json',
};

export const getListNoteConcept = (params) => {
  return axiosInstance.get(`${config_daprog.BASE_URL}/v1/notes/list`, {
    params,
    headers,
  });
};

export const getDetailNoteConcept = (id) => {
  return axiosInstance.get(`${config_daprog.BASE_URL}/v1/notes/detail/${id}`, {
    headers,
  });
};

export const getListInvestmentPlan = (params) => {
  return axiosInstance.get(`${config_daprog.BASE_URL}/v1/investment-plan`, {
    params,
    headers,
  });
};

export const getDetailInvestmentPlan = (params) => {
  return axiosInstance.get(`${config_daprog.BASE_URL}/v1/cms/investment-plan`, {
    params,
    headers,
  });
};
