import { useMutation, useQuery } from "@tanstack/react-query";
import { BottomFormAction, ConfirmationModal, Spinner } from "components";
import CardForm from "components/molecules/CardForm";
import FileDetail from "components/molecules/FileDetail";
import InputFormDropzone from "components/molecules/InputFormDropzone";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  getDetailSafeguards,
  postSafeguardsStep7,
} from "services/danaProgram/callForProposalService";
import { fileUpload } from "services/danaProgram/fileService";

const Documents = ({ onBack }) => {
  const navigate = useNavigate();
  const [initDocs, setInitDocs] = useState([]);
  const [showConfirmModal, setConfirmModal] = useState({
    open: false,
    type: "draft",
    cancelLabel: "Tidak",
    confirmLabel: "Iya, simpan draft",
    title: "",
    description: "",
  });

  const { id } = useSelector((state) => state?.callForProposalData?.data);

  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    queryKey: ["get-detail-safeguard-7"],
    queryFn: async () => {
      const res = await getDetailSafeguards({ proposal_id: id });
      return res.data.data;
    },
  });

  const methods = useForm({
    finish: true,
    document: [],
  });

  const onSubmit = () => {
    postFile.mutate(methods.getValues());
  };

  const postFile = useMutation({
    mutationKey: ["post-file-document"],
    mutationFn: async (payload) => {
      const promise = payload?.document?.map(async (item) => {
        const formData = new FormData();
        formData.append("file", item);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });

      return Promise.all(promise);
    },
    onSuccess: async (data) => {
      submitStep7.mutate({
        id: dataDetail?._id || "",
        finish: true,
        document: data?.length > 0 ? [...data, ...(initDocs || [])] : initDocs,
      });
    },
  });

  const submitStep7 = useMutation({
    mutationKey: ["post-step-7"],
    mutationFn: async (payload) => {
      const res = await postSafeguardsStep7(payload);
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data Berhasil Disimpan", { variant: "success" });
      navigate(`/proposal/call-for-proposal/${id}`);
    },
  });

  useEffect(() => {
    if (dataDetail) {
      setInitDocs(dataDetail?.documents);
      methods.reset({
        id: dataDetail?._id,
        finish: true,
        document: [],
      });
    }
  }, [dataDetail]);

  return (
    <div>
      <ConfirmationModal
        useGraphic
        open={showConfirmModal.open}
        onCancel={() => {
          setConfirmModal((curr) => ({
            ...curr,
            open: false,
          }));
        }}
        cancelLabel={showConfirmModal.cancelLabel}
        confirmLabel={showConfirmModal.confirmLabel}
        onSubmit={onSubmit}
        isLoading={postFile.isLoading || submitStep7.isLoading}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {showConfirmModal.title}
          </div>
          <div className="text-sm text-gray-600">
            {showConfirmModal.description}
          </div>
        </div>
      </ConfirmationModal>
      <FormProvider {...methods}>
        <CardForm label="Dokumen Pendukung">
          {isFetchingDetail ? (
            <div className="w-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <>
              <p className="font-semibold">Upload Dokumen Pendukung</p>
              <InputFormDropzone
                name="document"
                accept={{
                  "image/png": [],
                  "image/jpeg": [],
                  "application/pdf": [],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [],
                }}
                maxFiles={10}
                maxSize={10}
                multiple={true}
                informationText="PNG, JPG, PDF, Docx"
              />
              {initDocs?.map((item, index) => (
                <FileDetail
                  key={index}
                  file={item}
                  onRemove={() => {
                    const filtered = initDocs.filter(
                      (file) => file.fileId !== item.fileId
                    );
                    setInitDocs(filtered);
                  }}
                />
              ))}
            </>
          )}
        </CardForm>
      </FormProvider>
      <BottomFormAction
        isLoading={submitStep7.isLoading}
        lastStep={true}
        draftLabel={"Kembali Ke Proposal"}
        drafButtonAction={() => {
          navigate(`/proposal/call-for-proposal/${id}`);
        }}
        disableDrafButton={false}
        backButtonAction={() => onBack()}
        submitActionButton={() => {
          setConfirmModal((curr) => ({
            ...curr,
            open: true,
            type: "submit",
            cancelLabel: "Tidak, ubah data",
            confirmLabel: "Ya, kirimkan",
            title: "Anda yakin akan mengirimkan semua data ini?",
            description:
              "Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku. ",
          }));
        }}
      />
    </div>
  );
};

export default Documents;
