import { useMutation, useQuery } from "@tanstack/react-query";
import TablePagination from "components/atoms/Pagination";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router";
import Button from "components/atoms/Button/Button";
import {
  getAgencyProfile,
  getAgencyRequest,
} from "services/fdb/lembagaPenyalur";
import Select from "pages/Report/Components/Select";
import { BsEyeFill } from "react-icons/bs";
import moment from "moment";
import { Table } from "components";
import dayjs from "dayjs";
import { formatCurrency } from "helpers";
import Tag from "components/atoms/Tag";
import { RiEyeLine } from "react-icons/ri";

const rowPerPageOptions = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

const Status = {
  ON_PROGRESS: "Sedang Berjalan",
  REJECTED: "Ditolak",
  VERIFIED: "Disetujui",
  DONE: "Selesai",
  WAIT_VERIF: "Sedang Direview",
  DRAFT: "Draft",
};

const FinancingRequest = (props) => {
  const { type, group } = props;
  const navigate = useNavigate();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      pageSize: 5,
      page: 1,
    },
  });

  const wrapperRef = useRef();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginate, setPaginate] = useState(true);
  const [dataRequest, setDataRequest] = useState({});

  const { data: profile } = useQuery({
    queryKey: ["agency-profile"],
    queryFn: getAgencyProfile,
    select: (data) => data?.data?.data,
  });

  const params = {
    page,
    limit,
    paginate,
  };

  const { data, isLoading, refetch } = useQuery(["list-proposal"], async () =>
    getAgencyRequest(params)
  );

  useEffect(() => {
    setDataRequest(data?.data?.data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [page, limit, paginate, refetch]);

  const changePage = (val) => {
    setPage(val);
    wrapperRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  const handleLimitPage = (page) => {
    setLimit(page);
    setPage(1);
    wrapperRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  const handleView = useCallback(
    (id, mode) => {
      if (mode === "edit") {
        navigate(
          `/permohonan-pembiayaan/${group}/${type}/buat-permohonan?step=1&request=${id}`
        );
      }
      if (mode === "view") {
        navigate(
          `/permohonan-pembiayaan/${group}/${type}/view?step=1&request=${id}`
        );
      }
    },
    [group, navigate, type]
  );

  const paginator = useMemo(() => {
    return {
      page,
      limit,
      total: dataRequest?.meta?.total,
      previous_pages: dataRequest?.meta?.previous_page_url,
      next_pages: dataRequest?.meta?.next_page_url,
    };
  }, [
    dataRequest?.meta?.total,
    dataRequest?.meta?.next_page_url,
    dataRequest?.meta?.previous_page_url,
    limit,
    page,
  ]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "request_date",
        title: "Tanggal Permohonan",
        dataIndex: "request_date",
        className: "",
        columnClassName:
          "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => dayjs(value).format("DD MMM YYYY"),
      },
      {
        id: "application_value",
        title: "Nilai Permohonan",
        dataIndex: "application_value",
        className: "",
        columnClassName:
          "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => (
          <div className="overflow text-elipsis w-full max-w-[150px]">
            {formatCurrency(value)}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        className: "",
        columnClassName:
          "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => (
          <div>
            {value === Status.ON_PROGRESS && (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"neutral"}
              >
                {Status.ON_PROGRESS}
              </Tag>
            )}
            {value === Status.WAIT_VERIF && (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"warning"}
              >
                {Status.WAIT_VERIF}
              </Tag>
            )}
            {value === Status.VERIFIED && (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"success"}
              >
                {Status.VERIFIED}
              </Tag>
            )}
            {value === Status.REJECTED && (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"error"}
              >
                {Status.REJECTED}
              </Tag>
            )}
            {value === Status.DRAFT && (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme="neutral"
              >
                {Status.DRAFT}
              </Tag>
            )}
            {value === Status.DONE && (
              <Tag
                wrapperClass={
                  "text-xs overflow-hidden text-ellipsis whitespace-nowrap"
                }
                theme={"success"}
              >
                {Status.DONE}
              </Tag>
            )}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "id",
        className: "",
        sortable: true,
        columnClassName: "w-[150px] text-center text-ellipsis overflow-hidden",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <div className="flex flex-col justify-center items-center">
              {data?.status === Status.DRAFT ? (
                <Button
                  type={"button"}
                  className="bg-primary-600 text-white py-2 px-3 rounded-lg font-semibold text-[12px]"
                  label={
                    <div className="flex items-center gap-2 font-[14px">
                      Lanjutkan
                    </div>
                  }
                  onClick={() => handleView(value, "edit")}
                  color={"primary"}
                />
              ) : (
                <Button
                  type={"button"}
                  className="p-4 bg-[transparent] rounded-full"
                  label={<RiEyeLine />}
                  onClick={() => handleView(value, "view")}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [handleView, paginator?.limit, paginator?.page]);

  const createApplication = useCallback(() => {
    navigate(`/permohonan-pembiayaan/${group}/${type}/buat-permohonan?step=1`);
  }, [group, type, navigate]);

  console.log(profile?.agency_profile?.status !== "2");

  return (
    <div className="space-y-6">
      <div className="w-full p-10 bg-white rounded-xl border border-gray-200 backdrop-blur-[80px] space-y-6">
        <BreadCrumbs
          routes={[
            {
              label: "Daftar Permohonan",
              path: `/permohonan-pembiayaan/${group}/${type}`,
            },
          ]}
        />
        <div className="flex items-center justify-between">
          <div
            className="text-gray-900 text-2xl font-semibold leading-loose"
            ref={wrapperRef}
          >
            Daftar Permohonan
          </div>
          <div className="justify-start items-center gap-3 inline-flex">
            <Button
              color="error"
              loading={isLoading}
              label={
                <div className="flex items-center space-x-2">
                  {!isLoading && <FiPlus />}
                  <p>Buat Permohonan Baru</p>
                </div>
              }
              disabled={
                profile?.agency_profile?.status !== "2" || (dataRequest?.data?.length && (dataRequest?.data[0]?.status !== "Selesai" && dataRequest?.data[0]?.status !== "Ditolak"))
                // (dataRequest?.data?.length > 0 &&
                //   dataRequest?.data?.every((el) => el?.status !== "Selesai" && el?.status !== "Ditolak")) // Changed || to &&
              }
              onClick={createApplication}
            />
          </div>
        </div>
      </div>

      <Table
        bordered
        stripped
        layout="fixed-4"
        className="mb-4"
        columns={tableColumns}
        dataSource={dataRequest?.data}
        onChangePage={changePage}
        onChangeRowsPerPage={handleLimitPage}
        pagination={paginator}
      />
    </div>
  );
};

export default FinancingRequest;
