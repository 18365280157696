import React, { useEffect, useMemo } from "react";
import { InputForm } from "components";
import AtomSelect from "components/atoms/Select";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";
import { useQuery } from "@tanstack/react-query";
import { getMasterBussinessType } from "services/fdb/bussinessType";
import { useState } from "react";

const GeneralInformationForm = ({
  cityId,
  provinceId,
  districtId,
  villageId,
}) => {
  const [options, setOptions] = useState([]);

  const { data } = useQuery({
    queryKey: ["buisness-type-fdb"],
    queryFn: async () => {
      const res = await getMasterBussinessType();

      return res?.data?.data?.data || [];
    },
    retry: false,
  });

  useEffect(() => {
    setOptions(
      data?.map((entry) => {
        return {
          value: entry?.id,
          label: entry?.name,
        };
      })
    );
  }, [data]);

  return (
    <div className="py-4">
      <div className="mb-4 font-semibold text-primary-700">
        General Informasi
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputForm
          controllerName={"general_information.group_name"}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={"Nama Kelompok"}
          placeholder={"Nama Kelompok"}
          required
        />
        <AtomSelect
          controllerName={"general_information.group_type"}
          options={[
            { value: "Perhutanan Sosial", label: "Perhutanan Sosial" },
            { value: "Non Perhutanan Sosial", label: "Non Perhutanan Sosial" },
          ]}
          className="basic-single mt-2 flex-1"
          label="Jenis Kelompok"
          placeholder="Pilih Jenis Kelompok"
          required={true}
        />
        <div className="col-span-2">
          <InputForm
            controllerName={"general_information.assignment"}
            className={`py-2 px-4 mt-2 border w-full rounded-md`}
            label="Dasar persetujuan/penetapan/perjanjian akses kawasan/SK Pembentukan atau berita acara pembentukan"
            placeholder="Dasar persetujuan/penetapan/perjanjian akses kawasan/SK Pembentukan atau berita acara pembentukan"
            required
          />
        </div>
        <SelectFDBProvince
          controllerName={"general_information.province"}
          label={"Provinsi"}
          placeholder={"Provinsi"}
          required
          returnObject={false}
        />
        <SelectFDBCity
          controllerName={"general_information.city"}
          label={"Kota / Kabupaten"}
          placeholder={"Kota / Kabupaten"}
          required
          returnObject={false}
          provinceId={provinceId}
        />
      </div>
      <div className="grid grid-cols-12 md:grid-cols-12 gap-4 mt-4">
        <div className="col-span-4">
          <SelectFDBDistrict
            controllerName={"general_information.district"}
            label={"Kecamatan"}
            placeholder={"Kecamatan"}
            required
            returnObject={false}
            cityId={cityId}
          />
        </div>

        <div className="col-span-4">
          <SelectFDBVillage
            controllerName={"general_information.village"}
            label={"Kelurahan"}
            placeholder={"Kelurahan"}
            required
            returnObject={false}
            districtId={districtId}
          />
        </div>
        <div className="col-span-4">
          <SelectFDBZipCode
            controllerName={"general_information.zip_code"}
            label={"Kode Pos"}
            placeholder={"Kode Pos"}
            required
            returnObject={false}
            villageId={villageId}
          />
        </div>
        <div className="col-span-12">
          <InputForm
            controllerName={"general_information.address"}
            className={`py-2 px-4 mt-2 border w-full rounded-md`}
            label={"Alamat Lengkap"}
            placeholder={"Alamat Lengkap"}
            textArea
            required
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralInformationForm;
