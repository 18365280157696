import React from "react";
import arrowRight from "assets/icons/arrowRight.svg";
import { Link } from "react-router-dom";
const MenuRegister = (props) => {
  const { color, iconColor, borderIconColor, title, description, link } = props;
  return (
    <Link to={link ? link : "#"}>
      <div className="flex hover:border-2 hover:border-[#00BD52] hover:bg-[#EEFFF4] relative flex-col px-6 py-11  w-full sm:w-full sm:h-full tab:w-full tab:h-full h-full bg-gray-50 rounded-md cursor-pointer">
        <div className="absolute flex items-center justify-center left-6 z-20 -top-6">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill={color} />
            <g clipPath="url(#clip0_3391_28680)">
              <path
                d="M36 18C36.552 18 37 18.448 37 19V22.757L35 24.757V20H21V36H35V33.242L37 31.242V37C37 37.552 36.552 38 36 38H20C19.448 38 19 37.552 19 37V19C19 18.448 19.448 18 20 18H36ZM37.778 24.808L39.192 26.222L31.414 34L29.998 33.998L30 32.586L37.778 24.808ZM29 28V30H24V28H29ZM32 24V26H24V24H32Z"
                fill={iconColor}
              />
            </g>
            <rect
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke={borderIconColor}
              strokeWidth="8"
            />
            <defs>
              <clipPath id="clip0_3391_28680">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(16 16)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>

        <h1 className="font-semibold text-2xl text-gray-900">{title}</h1>
        <p className="font-normal text-base tab:text-lg font-poppins text-gray-600">
          {description}
        </p>
        <button className="flex justify-end items-center gap-2 text-md font-semibold text-[#01A24A]">
          Pilih <img src={arrowRight} alt="" />
        </button>
      </div>
    </Link>
  );
};

export default MenuRegister;
