import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

export const getCompanionAffiliates = (userId) => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/companion-affiliates`,
    {
      headers: { Authorization: `Bearer ${userId}` },
    }
  );
};
