import angkaTerbilang from "@develoka/angka-terbilang-js";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { InputForm } from "../../../../../components";
import Radio from "../../../../../components/atoms/Radio";
import AtomSelect from "../../../../../components/atoms/Select";
import CardForm from "../../../../../components/molecules/CardForm";
import InputCurrency from "../../../../../components/organisms/inputs/InputCurrency";
import { formatCurrency } from "../../../../../helpers";
import useTimeout from "../../../../../helpers/useTimeout";
import { capitalize, debounce, toNumber } from "lodash";

export const FieldLabel = {
  AMOUNT: "Nominal Pinjaman",
  AMOUNT_WORD: "Terbilang",
  DURATION: "Jangka Waktu",
  INSTALLMENT: "Nominal Angsuran",
  INSTALLMENT_WORD: "Terbilang",
};

export const validationSchema = Yup.object().shape({
  amount: Yup.string().trim().required().label(FieldLabel.AMOUNT),
  business_loan_application: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.DURATION),
  business_loan_application_period: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.DURATION),
  business_loan_application_paid: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.INSTALLMENT),

  // ! INSTALLMENT
});

function LoanSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useFormContext();
  const loanAmount = useWatch({
    control: methods.control,
    name: `business_loan_application`,
  });
  const durType = useWatch({
    control: methods.control,
    name: `business_loan_application_period_type`,
  });
  const durLen = useWatch({
    control: methods.control,
    name: `business_loan_application_period`,
  });

  // effects
  // update depending fields on row changes
  useTimeout(
    () => {
      const numToWords = (number) => {
        let normalizedNum = "";

        try {
          normalizedNum = parseFloat(number);
          normalizedNum = isNaN(normalizedNum) ? undefined : number;
        } catch (err) {
          //stubbed
        }

        return normalizedNum ? angkaTerbilang(number) + " rupiah" : "";
      };

      const loanAmt = toNumber(loanAmount);
      const installmentDur = parseFloat(durLen);
      let installmentAmt = 0;

      if (installmentDur === 24) {
        if (loanAmt === 1000000) {
          installmentAmt = 45500;
        } else if (loanAmt === 500000) {
          installmentAmt = 22750;
        }
      } else if (installmentDur === 36) {
        if (loanAmt === 1000000) {
          installmentAmt = 31625;
        } else if (loanAmt === 500000) {
          installmentAmt = 15850;
        }
      }

      methods.setValue(`business_loan_application_desc`, numToWords(loanAmt));
      methods.setValue(`business_loan_application_paid`, installmentAmt);
      methods.setValue(
        `business_loan_application_paid_desc`,
        numToWords(installmentAmt)
      );
    },
    400,
    [loanAmount]
  );

  const loadApplication = methods.watch("business_loan_application");

  const handleSetAngsuran = debounce(() => {
    const amount = methods.watch("business_loan_application");
    const period = methods.watch("business_loan_application_period");
    const periodType = methods.watch("business_loan_application_period_type");

    if (amount === 1000000 && period === "24" && periodType === "Minggu") {
      methods.setValue("business_loan_application_paid", 45500);
      methods.setValue(
        "business_loan_application_paid_desc",
        angkaTerbilang(45500)
      );

      return;
    }

    if (amount === 1000000 && period === "36" && periodType === "Minggu") {
      methods.setValue("business_loan_application_paid", 31625);
      methods.setValue(
        "business_loan_application_paid_desc",
        angkaTerbilang(31625)
      );

      return;
    }

    if (amount === 500000 && period === "24" && periodType === "Minggu") {
      methods.setValue("business_loan_application_paid", 22750);
      methods.setValue(
        "business_loan_application_paid_desc",
        angkaTerbilang(22750)
      );

      return;
    }

    if (amount === 500000 && period === "36" && periodType === "Minggu") {
      methods.setValue("business_loan_application_paid", 15850);
      methods.setValue(
        "business_loan_application_paid_desc",
        angkaTerbilang(15850)
      );

      return;
    }
  }, 1000);

  useEffect(() => {
    handleSetAngsuran();
  }, [methods]);

  return (
    <CardForm label="Data Permohonan Pinjaman" {...rest}>
      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        <div className={"col-span-2"}>
          <label className={"block font-semibold text-sm mb-2"}>
            {FieldLabel.AMOUNT}
          </label>

          {isView ? (
            <span>{formatCurrency(loanAmount, 0)}</span>
          ) : (
            <>
              <Radio
                controllerName={`business_loan_application`}
                inputWrapperClass={"flex gap-2 items-center"}
                wrapperClass={"col-span-1 flex justify-center"}
                value={500000}
                label={<span>{formatCurrency(500000, 0)}</span>}
                showErrorLabel={false}
              />
              <Radio
                controllerName={`business_loan_application`}
                inputWrapperClass={"flex gap-2 items-center"}
                wrapperClass={"col-span-1"}
                value={1000000}
                label={<span>{formatCurrency(1000000, 0)}</span>}
                showErrorLabel={false}
              />
              <Radio
                controllerName={`business_loan_application`}
                inputWrapperClass={"flex gap-2 mt-1"}
                wrapperClass={"w-full"}
                labelClass={"flex-1 col-span-2"}
                value={0}
                label={
                  <div className="-mt-[7px] flex flex-col gap-2">
                    <div>Lainnya</div>
                    <InputForm
                      controllerName={`other_options`}
                      className={"w-full rounded-md"}
                      disabled={loadApplication == 0 ? false : true}
                    />
                  </div>
                }
              />
            </>
          )}
        </div>

        <InputForm
          controllerName={`business_loan_application_desc`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case`}
          wrapperClass={"col-span-2 lg:col-span-1"}
          label={FieldLabel.AMOUNT_WORD}
          placeholder={FieldLabel.AMOUNT_WORD}
          disabled
          readOnly
          asText={isView}
          asTextClass={"uppercase"}
        />

        <section
          className={
            "col-span-2 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0"
          }
        >
          <h1
            className={
              "text-primary-maincolor-700 text-lg font-semibold col-span-2"
            }
          >
            Jangka Waktu
          </h1>

          {isView ? (
            <div className={"col-span-2"}>
              {durLen} {durType}
            </div>
          ) : (
            <>
              <AtomSelect
                controllerName={`business_loan_application_period`}
                className="basic-single mt-2 flex-1"
                wrapperClass={"col-span-1"}
                returnObject={false}
                options={[
                  // { value: 12, label: 12 },
                  { value: 24, label: 24 },
                  { value: 36, label: 36 },
                ]}
                required
                asText={isView}
              />

              <AtomSelect
                controllerName={`business_loan_application_period_type`}
                className="basic-single mt-2 flex-1"
                wrapperClass={"col-span-1"}
                returnObject={false}
                options={[
                  { value: "Minggu", label: "Minggu" },
                  // { value: "bulan", label: "Bulan" },
                  // { value: "tahun", label: "Tahun" },
                ]}
                required
                asText={isView}
              />
            </>
          )}
        </section>

        <InputForm
          controllerName={`business_loan_application_paid`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.INSTALLMENT}
          required
          disabled
          readOnly
          asText={isView}
        />

        <InputForm
          controllerName={`business_loan_application_paid_desc`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 uppercase placeholder:!normal-case`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.INSTALLMENT_WORD}
          placeholder={FieldLabel.INSTALLMENT_WORD}
          required
          disabled
          readOnly
          asText={isView}
          asTextClass={"uppercase"}
        />
      </div>
    </CardForm>
  );
}

LoanSection.propTypes = {};

export default LoanSection;
