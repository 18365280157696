import {
  SAVE_MASTER_DATA,
  RESET_MASTER_DATA,
  UPDATE_MASTER_DATA,
  updateDraftData,
} from "../actions";

const initialState = {
  data: {
    fokusIsu: [],
    bidangKerjasama: [],
    jenisLembaga: [],
    rencanaStrategis: [],
  },
};

export const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_MASTER_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case UPDATE_MASTER_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_MASTER_DATA:
      return {
        ...state,
        data: {
          fokusIsu: [],
          bidangKerjasama: [],
          jenisLembaga: [],
          rencanaStrategis: [],
        },
      };
    default:
      return state;
  }
};
