import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  BottomFormAction,
  ConfirmationModal,
  HeaderLabel,
  InputForm,
} from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
// import { AUTOSAVE_DURATION } from "../../../app/config";
import CardForm from "../../../components/molecules/CardForm";
import ProgressModal from "../../../components/molecules/Modal/ProgressModal";
import StepProgress from "../../../components/molecules/StepProgress";
import { getErrorMessage } from "../../../helpers";
import { createSelfAssesment } from "services/fdb/permohonan-pembiayaan";
// import useInterval from "../../../helpers/useInterval";

const questions1 = [
  {
    id: 1,
    judulPertanyaan:
      "Apa usaha utama penghidupan sehari-hari dari calon pemohon?",
    key: "financed_livelihood_prospective",
  },
  {
    id: 2,
    judulPertanyaan: "Apa tujuan dari penggunaan dana pinjaman tersebut?",
    key: "financed_purpose_loan_funds",
  },
  {
    id: 3,
    judulPertanyaan:
      "Sudah berapa lama usaha yang akan dibiayai sampai dengan saat ini? ",
    key: "financed_business_funded",
  },
  {
    id: 4,
    judulPertanyaan:
      "Berapa total perkiraan asset/modal yang sudah dimiliki saat ini?",
    key: "financed_assest_currently",
  },
  {
    id: 5,
    judulPertanyaan:
      "Apakah usahanya dikerjakan sendiri atau dengan orang lain?",
    key: "financed_is_self_business",
  },
  {
    id: 6,
    judulPertanyaan: "apabila dengan orang lain bagaimana mekanisme kerjanya?",
    key: "financed_mechanism_work",
  },
  {
    id: 7,
    judulPertanyaan: "Berapa penjualan/penghasilan dari usaha saat ini? ",
    key: "financed_selling_income",
  },
  {
    id: 8,
    judulPertanyaan:
      "Berapa harga beli/HPP/Modal yang dibutuhkan dari usaha saat ini?",
    key: "financed_capital_price",
  },
  {
    id: 9,
    judulPertanyaan:
      "Dimana saja cakupan wilayah usaha yang dijalankan saat ini?",
    key: "financed_current_business_areas",
  },
  {
    id: 10,
    judulPertanyaan: "Bagaimana siklus usaha dari penjualan pemohon? ",
    key: "financed_sales_business_cycle",
  },
];

const validationSchema1 = yup.object({
  financed_livelihood_prospective: yup.string().required().label("Jawaban"),
  financed_purpose_loan_funds: yup.string().required().label("Jawaban"),
  financed_business_funded: yup.string().required().label("Jawaban"),
  financed_assest_currently: yup.string().required().label("Jawaban"),
  financed_is_self_business: yup.string().required().label("Jawaban"),
  financed_mechanism_work: yup.string().required().label("Jawaban"),
  financed_selling_income: yup.string().required().label("Jawaban"),
  financed_capital_price: yup.string().required().label("Jawaban"),
  financed_current_business_areas: yup.string().required().label("Jawaban"),
  financed_sales_business_cycle: yup.string().required().label("Jawaban"),
});

const questions2 = [
  {
    id: 1,
    judulPertanyaan:
      "Apa jenis usaha lain yang menjadi sumber pendapatan lain (termasuk usaha pasangan)?",
    key: "other_sources_income",
  },
  {
    id: 2,
    judulPertanyaan:
      "Penghasilan didapatkan per bulan / sesuai dengan siklus usaha? Berapa nominalnya?",
    key: "other_obtained_income",
  },
  {
    id: 3,
    judulPertanyaan: "Berapa penjualan/penghasilan dari usaha saat ini?",
    key: "other_selling_income",
  },
  {
    id: 4,
    judulPertanyaan:
      "Berapa harga beli/HPP/Modal yang dibutuhkan dari usaha saat ini?",
    key: "other_capital_price",
  },
  {
    id: 5,
    judulPertanyaan: "Bagaimana siklus usaha dari penjualan pemohon?",
    key: "other_sales_business_cycle",
  },
  {
    id: 6,
    judulPertanyaan: "Berapa perkiraan pengeluaran rutin setiap bulannya?",
    key: "other_outcome_per_month",
  },
];

const validationSchema2 = yup.object({
  other_sources_income: yup.string().required().label("Jawaban"),
  other_obtained_income: yup.string().required().label("Jawaban"),
  other_selling_income: yup.string().required().label("Jawaban"),
  other_capital_price: yup.string().required().label("Jawaban"),
  other_sales_business_cycle: yup.string().required().label("Jawaban"),
  other_outcome_per_month: yup.string().required().label("Jawaban"),
});

const AkunPeroranganSelfAssessmentForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const formData = useRef({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [activeStep, setActiveStep] = useState();
  const routes = useMemo(
    () => [
      {
        label: "Self Assessment",
        path: "/perorangan/self-assessment",
      },
      {
        label: "Formulir",
        path: location.pathname,
      },
    ],
    []
  );

  const activeStepKey = (activeStep > -1 && tabs?.at(activeStep)?.key) || "";

  // setup form
  const methods = useForm({
    resolver: yupResolver(
      activeStep === 0 ? validationSchema1 : validationSchema2
    ),
    mode: "onChange",
  });

  // api call
  const { data: tabData } = useQuery({
    queryKey: ["fdb-perorangan-self-assessment-ctg"],
    queryFn: async () => {
      // TODO: Integrate API here
      // let res = await getSelfAssessmentCategories();
      let res = {
        data: [
          {
            idKategori: "98385f64-0304-4562-b3fc-2c963f66afa6",
            idSubKategori: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            kategori: "Kondisi Usaha Dibiayai",
            subKategori: "string",
            jumlahPertanyaan: 0,
          },
          {
            idKategori: "f53285f64-0304-4562-b3fc-2c963f66afa6",
            idSubKategori: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            kategori: "Usaha Lain Selain yang Dibiayai Serta Pengeluaran",
            subKategori: "string",
            jumlahPertanyaan: 0,
          },
        ],
      };

      return res?.data || [];
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const draftMutation = useMutation({
    mutationFn: (params) => {
      // TODO: integrate submit api
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // resolve(true)
          reject(true);
        }, 3000);
      });
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      onBack();
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setDraftModal(false);
    },
  });

  const submitMutation = useMutation({
    mutationFn: createSelfAssesment,
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });

      navigate(`/${location.pathname.split("/")[1]}/self-assessment`);
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {
      setConfirmModal(false);
    },
  });

  // effects
  useEffect(() => {
    const layoutContentEl = document.getElementById("_layout-content");

    layoutContentEl?.classList?.add("overflow-hidden");

    return () => {
      layoutContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  useEffect(() => {
    setTabs(
      tabData?.map((entry, idx) => {
        return {
          label: entry?.kategori,
          step: idx,
          key: entry?.idKategori,
        };
      })
    );

    setActiveStep(0);
  }, [tabData]);

  // methods
  const onBack = () => {
    if (!!tabs?.length) {
      if (activeStep === 0) {
        navigate("/perorangan/self-assessment");
      } else {
        formData.current = {
          ...formData.current,
          ...methods.getValues(),
        };
        setActiveStep(activeStep - 1);
      }
    } else {
      navigate("/perorangan/self-assessment");
    }
  };

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      formData.current = { ...formData.current, ...data };

      if (!!tabs?.length) {
        if (activeStep >= tabs?.length - 1) {
          setConfirmModal(data);
        } else {
          // doSaveDraft(data);
          setActiveStep(activeStep + 1);
        }
      }
    });
  };

  const onSaveDraftClick = (e) => {
    e?.preventDefault();

    const data = methods.getValues();

    setConfirmModal(false);
    setDraftModal(data);
  };

  const doSaveDraft = (data) => {
    draftMutation.mutate(data || draftModal);
  };
  const doSubmit = () => {
    submitMutation.mutate(confirmModal);
  };

  // // setup autosave
  // useInterval(() => {
  //   const val = methods?.getValues() || {};
  //   formData.current = { ...formData.current, ...val };

  //   doSaveDraft(val);
  // }, AUTOSAVE_DURATION);

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onClose={setDraftModal}
          onSubmit={doSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={doSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ProgressModal
          open={
            (draftModal && draftMutation.isLoading) ||
            (confirmModal && submitMutation.isLoading)
          }
          className="space-y-2 mb-4"
        />,
        document.body
      )}

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel text="Self Assessment" description="Penilaian." />

        <div className="border-b" />

        {!tabs?.length && (
          <div
            className={"px-4 py-20 text-center text-neutral-gray3 font-italic"}
          >
            Maaf, saat ini self-assessment belum tersedia.
          </div>
        )}

        {!!tabs?.length && (
          <div className="w-full flex justify-center">
            <div className="w-[800px] mb-10">
              <StepProgress
                stepProgressData={tabs}
                activeStep={activeStep}
                showIfSingleStep
              />
            </div>
          </div>
        )}

        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit()} className="space-y-8">
            {activeStep === 0 &&
              questions1.map((question) => (
                <InputForm
                  label={question.judulPertanyaan}
                  controllerName={question.key}
                  wrapperClass="gap-1"
                  className="py-2 px-4 mt-2 border w-full rounded-md col-span-1"
                  textArea
                />
              ))}

            {activeStep === 1 &&
              questions2.map((question) => (
                <InputForm
                  label={question.judulPertanyaan}
                  controllerName={question.key}
                  wrapperClass="gap-1"
                  className="py-2 px-4 mt-2 border w-full rounded-md col-span-1"
                  textArea
                />
              ))}

            <BottomFormAction
              lastStep={activeStep === tabs?.length - 1}
              backButtonAction={onBack}
              backButtonProps={{ type: "button" }}
              draftButtonProps={{ type: "button" }}
              submitButtonProps={{ type: "submit" }}
              submitActionButton={onFormSubmit}
              disableDrafButton={!tabs?.length}
              drafButtonAction={onSaveDraftClick}
              hideSubmit={!tabs?.length}
              hideDraft
              disableButtonSubmit={!tabs?.length}
            />
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AkunPeroranganSelfAssessmentForm;
