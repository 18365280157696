import React from "react";
import { BiSearch } from "react-icons/bi";

const SearchInput = ({placeholder="Cari", onChange}) => {
  return (
    <div className="flex flex-1 items-center rounded-md border-gray-200 px-4 space-x-3 border-solid border-[1px]">
      <BiSearch className="text-gray-400" size={20} />
      <input
        className="bg-white text-gray-400 w-full text-sm p-2 outline-none focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-gray-200"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchInput;
