import React, { useState } from "react";
import { InputForm } from "components";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const AccountGroupInformation = () => {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  return (
    <div className="py-4">
      <div className="mb-4 font-semibold text-primary-700">
        Informasi Akun Kelompok
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputForm
          controllerName={"account_group_information.email"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          label={"Email"}
          placeholder={"Email"}
          required
        />
        <div>&nbsp;</div>
        <InputForm
          type={showPass ? "text" : "password"}
          controllerName={"account_group_information.password"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          label={"Kata Sandi"}
          placeholder={"Kata Sandi"}
          required
          rightIcon={
            showPass ? (
              <IoMdEye
                className="cursor-pointer text-lg"
                onClick={() => setShowPass(!showPass)}
              />
            ) : (
              <IoMdEyeOff
                className="cursor-pointer text-lg"
                onClick={() => setShowPass(!showPass)}
              />
            )
          }
        />
        <InputForm
          type={showConfirmPass ? "text" : "password"}
          controllerName={"account_group_information.confirm_password"}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          label={"Konfirmasi Kata Sandi"}
          placeholder={"Ulangi Kata Sandi"}
          required
          rightIcon={
            showConfirmPass ? (
              <IoMdEye
                className="cursor-pointer text-lg"
                onClick={() => setShowConfirmPass(!showConfirmPass)}
              />
            ) : (
              <IoMdEyeOff
                className="cursor-pointer text-lg"
                onClick={() => setShowConfirmPass(!showConfirmPass)}
              />
            )
          }
        />
      </div>
    </div>
  );
};

export default AccountGroupInformation;
