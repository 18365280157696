import RegisterBeneficiariesChild from "./Register";

const RegisterBeneficiaries = () => {
  return (
    <>
      <RegisterBeneficiariesChild />
    </>
  )
}

export default RegisterBeneficiaries;