import axios from "axios";
import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};


export const submitFinancialData = (params) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/financial-profile/submit`,
    params,
    {
      headers,
    }
  );
};
export const getFinancialData = () => {
  const userId = localStorage.getItem("userId");
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/financial-profile/findByUserId/${userId}`,
    { headers }
  );
};
