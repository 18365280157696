import React from "react";

const Footer = () => {
  return (
    <footer className="p-12 flex items-end self-stretch">
      <p className="text-gray-600">© 2023 BPLDH</p>
    </footer>
  );
};

export default Footer;
