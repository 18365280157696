import { ConfirmationModal, HeaderLabel, StepProgress } from 'components';
import { AlertWarning } from 'components/molecules/Alert';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import React, { useCallback, useMemo, useState } from 'react';
// import { RiAlertLine } from "react-icons/ri";
import LabaRugi from './LabaRugi';
import NeracaKeuanganLembaga from './NeracaKeuanganLembaga';
import KlasifikasiFinansial from './KlasifikasiFinansial';
import Pajak from './Pajak';
import { useNavigate } from 'react-router';
import DokumenLegalitas from './DokumenLegalitas';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth } from 'hooks/auth';
import {
  getChannelingDirectApplicationFinance,
  updateChannelingDirectApplicationFinanceBumdes,
  updateDraftChannelingDirectApplicationFinanceBumdes,
} from 'services';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'helpers';

const breadCrumbs = [
  {
    label: 'Daftar Permohonan',
    path: '/firma',
  },
  {
    label: 'Daftar Permohonan Baru',
    path: '/firma/supplier-application',
  },
  {
    label: 'Formulir Neraca Keuangan Lembaga',
  },
];

const stepProgress = [
  {
    label: 'Neraca Keuangan Lembaga',
    step: 1,
  },
  {
    label: 'Laba Rugi',
    step: 2,
  },
  {
    label: 'Klasifikasi Finansial',
    step: 3,
  },
  {
    label: 'Pajak',
    step: 4,
  },
  {
    label: 'Dokumentasi Legalitas',
    step: 5,
  },
];

const NeracaKeuanganForm = () => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(1);
  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  const { id } = useAuth();
  const { data: financeData } = useQuery(['application-finance', id], () =>
    getChannelingDirectApplicationFinance(id)
  );

  console.log(financeData);

  const onBack = useCallback(() => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, navigate]);

  const onDraft = useCallback((payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));
    setDraftModal(true);
  }, []);

  const onSubmit = useCallback(
    (payload) => {
      setBodyToSend((state) => ({ ...state, ...payload }));
      if (activeStep < stepProgress.length) {
        setActiveStep(activeStep + 1);
      } else {
        setConfirmModal(true);
      }
    },
    [activeStep]
  );

  const params = useMemo(() => {
    if (!bodyToSend) return {};

    const startYear = dayjs(bodyToSend.first_year).format('YYYY');
    const formData = new FormData();

    let financeDetails = [];
    bodyToSend.items.forEach((item, index) => {
      financeDetails.push({
        year: startYear + index,
        cash: item.kas,
        bank: item.bank,
        receivable: item.piutang,
        inventory_item: item.persediaan_barang,
        prepaid_expense: item.biaya_dibayar_di_muka,
        equipment_machinery: item.peralatan_mesin,
        inventory: item.inventaris,
        building: item.gedung,
        other_asset: item.aktiva_lainnya,
        account_debt: item.utang_dagang,
        tax_debt: item.utang_pajak,
        other_debt: item.utang_lainnya,
        debt: item.utang,
        paid_in_capital: item.modal_disetor,
        additional_paid_in_capital: item.tambahan_modal_disetor,
        equity_other_income: item.penghasilan_lainnya,
        backup: item.cadangan,
        profit_loss: item.laba_rugi,
        // profit loss
        openational_income:
          bodyToSend.profitLoss?.[index]?.pendapatan_operational,
        openational_expense: bodyToSend.profitLoss?.[index]?.beban_operational,
        other_openational_income:
          bodyToSend.profitLoss?.[index]?.pendapatan_operational_lainnya,
        other_openational_expense:
          bodyToSend.profitLoss?.[index]?.beban_operational_lainnya,
        non_openational_income:
          bodyToSend.profitLoss?.[index]?.pendapatan_non_operational,
        non_openational_expense:
          bodyToSend.profitLoss?.[index]?.beban_non_operational,
        other_income: bodyToSend.profitLoss?.[index]?.pendapatan_lainnya,
        profit_loss_for_period:
          bodyToSend.profitLoss?.[index]?.laba_rugi_periode_berjalan,
      });
    });

    financeDetails.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        formData.append(`finance_detail[${key}]`, value);
      });
    });

    formData.append('clasification', bodyToSend.jenis_klasifikasi);
    formData.append(
      'forestry_financing_experience',
      bodyToSend.financing_experience
    );
    formData.append(
      'preparing_report_experience',
      bodyToSend.reporting_experience
    );
    formData.append('skb', bodyToSend.skb);
    formData.append('skb_registration_date', bodyToSend.skb_register_date);
    formData.append('kap_number', bodyToSend.no_izin_kap);
    formData.append('kap_auditor', bodyToSend.nama_kap);
    formData.append('skb_file', bodyToSend.skb_ducument);
    formData.append('skt_file', bodyToSend.skt_file);
    formData.append('tax_other_file', bodyToSend.tax_other_document);
    formData.append(
      'audited_financial_file',
      bodyToSend.audited_financial_file
    );
    formData.append('sfirma_file', bodyToSend.sfirma_file);
    formData.append(
      'current_audited_financial_file',
      bodyToSend.current_audited_financial_file
    );

    return formData;
  }, [bodyToSend]);

  const onError = useCallback((error) => {
    enqueueSnackbar({
      message: getErrorMessage(error),
      variant: 'error',
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar({
      message: 'Data berhasil disimpan',
      variant: 'error',
    });
    navigate('/firma/supplier-application');
  }, [navigate]);

  const { mutate: updateDebtor } = useMutation({
    mutationFn: () => {
      return updateChannelingDirectApplicationFinanceBumdes(id, params);
    },
    onError,
    onSuccess,
  });

  const { mutate: updateDraftDebtor } = useMutation({
    mutationFn: () => {
      return updateDraftChannelingDirectApplicationFinanceBumdes(id, params);
    },
    onError,
    onSuccess,
  });

  const onCreate = () => {
    updateDebtor();
  };

  const onCreateDraft = () => {
    updateDraftDebtor();
  };

  return (
    <div>
      {/* <pre>{JSON.stringify(bodyToSend, null, 2)}</pre> */}
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onCreate}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        open={draftModal}
        onClose={setDraftModal}
        onSubmit={onCreateDraft}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan menyimpan data ini sebagai draft?
        </div>
        <div className="text-sm font-[400]">
          Setelah Anda menyimpan data sebagai draft anda masih bisa
          melanjutkannya.
        </div>
      </ConfirmationModal>

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-36">
        <BreadCrumbs routes={breadCrumbs} />

        <div className="flex">
          <HeaderLabel
            text="Formulir Permohonan"
            descrifirmaion="Buat permohonan baru."
          />
        </div>

        <div className="border-b" />

        <div className="w-full flex justify-center">
          <div className="w-[800px] mb-10">
            <StepProgress
              stepProgressData={stepProgress}
              activeStep={activeStep}
            />
          </div>
        </div>

        <AlertWarning>
          masukan data keuangan 2/3 taun terakhir & isi semua data di bawah ini
          untuk bisa melanjutkan pengisian ke tahap selanjutnya
        </AlertWarning>

        {activeStep === 1 && (
          <NeracaKeuanganLembaga
            prefix={'neraca_keuangan_lembaga'}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
        {activeStep === 2 && (
          <LabaRugi
            prefix={'laba_rugi'}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
        {activeStep === 3 && (
          <KlasifikasiFinansial
            prefix={'klasifikasi_finansial'}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
        {activeStep === 4 && (
          <Pajak
            prefix={'pajak'}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
        {activeStep === 5 && (
          <DokumenLegalitas
            prefix={'dokumen_legalitas'}
            onSubmit={onSubmit}
            onDraft={onDraft}
            onBack={onBack}
          />
        )}
      </div>
    </div>
  );
};

export default NeracaKeuanganForm;
