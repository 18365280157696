import React, { useEffect, useState } from "react";
import { InputForm, Spinner } from "components";

import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";

const Form = ({
  methods,
  jenisLembaga,
  jenisLembagaLoading = false,
  fokusIsu,
  fokusIsuLoading = false,
  isMapping,
}) => {
  const [currentIsMapping, setCurrentIsMapping] = useState(isMapping);

  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    setCurrentIsMapping(isMapping);
  }, [isMapping]);

  return (
    <div>
      {!currentIsMapping && (
        <>
          <CardForm label={"Informasi Umum"}>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"lemtara_name"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.nama_lemtara ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Nama LEMTARA"}
                  placeholder={"nama lemtara"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2 flex items-end">
                {jenisLembagaLoading ? (
                  <Spinner />
                ) : (
                  <AtomSelect
                    controllerName={"institution_type"}
                    options={jenisLembaga ? jenisLembaga : []}
                    className="basic-single mt-2"
                    label="Jenis Lembaga"
                    required={true}
                    disable={true}
                  />
                )}
              </div>
            </div>

            {fokusIsuLoading ? (
              <Spinner />
            ) : (
              <AtomSelect
                controllerName={"intervention"}
                options={fokusIsu ? fokusIsu : []}
                className="basic-single mt-2"
                label="Pilih Fokus Isu atau Intervansi Lembaga"
                required={true}
                isMulti
              />
            )}

            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"email"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.email ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Email"}
                  placeholder={"email"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"phone"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.phone ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Telepon"}
                  placeholder={"xxxx xxxx xxxx"}
                  required={true}
                />
              </div>
            </div>
          </CardForm>

          <CardForm label={"Informasi Penanggung Jawab"}>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"contact_person"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.contact_person ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Nama Narahubung"}
                  placeholder={"nama narahubung"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <AtomSelect
                  controllerName={"gender_pic"}
                  options={[
                    { value: "L", label: "Laki-laki" },
                    { value: "P", label: "Perempuan" },
                  ]}
                  className="basic-single mt-2"
                  label="Jenis Kelamin"
                  required={true}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"directors"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.directors ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Nama Direktur / Pimpinan"}
                  placeholder={"nama direktur / pimpinan"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"email_pic"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.email_pic ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Email"}
                  placeholder={"email"}
                  required={true}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"phone_pic"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.phone_pic ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Telepon"}
                  placeholder={"xxxx xxxx xxxx"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2"></div>
            </div>
          </CardForm>

          <CardForm label={"Website & Social Media"}>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"general_social_media.website"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.website ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Website"}
                  placeholder={"website"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"general_social_media.instagram"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.instagram ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Instagram"}
                  placeholder={"instagram"}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"general_social_media.facebook"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.facebook ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Facebook"}
                  placeholder={"facebook"}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"general_social_media.twitter"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.twitter ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Twitter"}
                  placeholder={"twitter"}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"general_social_media.youtube"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.youtube ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Youtube"}
                  placeholder={"youtube"}
                />
              </div>
              <div className="flex-1 pt-2"></div>
            </div>
          </CardForm>
        </>
      )}
    </div>
  );
};

export default Form;
