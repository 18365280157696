import React, {
  useEffect,
  useCallback,
  useState,
  Suspense,
  useContext,
} from "react";
import { removeToken } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import Icon from "../../../assets/icons";
import { RiArrowDownSLine } from "react-icons/ri";
import {
  AuthConsumer,
  AuthContext,
  useAuthContext,
} from "providers/authProvider";
import {
  resetDraftData,
  resetFinancialDraftData,
  resetOrganizationDraftData,
  resetResourcesDraftData,
} from "app/actions";

import store from "app/store";

const Header = () => {
  return (
    <nav className="w-full top-0 border-0 py-10">
      <div className="flex items-center gap-2">
        <div className="flex-1">
          <img className="w-28 h-12" alt="Logo" src={Icon.logo} />
        </div>
        <div className="dropdown dropdown-end">
          <div className="flex items-center space-x-2">
            {/* <div className="text-sm text-gray-500">incubagong@gmail.com</div> */}
            <Suspense fallback={<div>Loading...</div>}>
              <UserInfo />
            </Suspense>
          </div>
        </div>
      </div>
    </nav>
  );
};

const UserInfo = () => {
  const dispatch = useDispatch();
  const authContext = useAuthContext();
  const [userData, setUserData] = useState();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await authContext.getUser();
        setUserData(user);
        localStorage.setItem("userId", user["profile"]?.["UserId"]);
      } catch (e) {
        console.log(e);
      }
    };
    getUserData();
  }, [authContext]);

  // const handleLogout = useCallback(() => {
  //   if (window.confirm("Logout?")) {
  //     dispatch(resetFinancialDraftData());
  //     dispatch(resetOrganizationDraftData());
  //     dispatch(resetResourcesDraftData());
  //     dispatch(resetDraftData());
  //     authContext.logout();
  //   }
  // }, [authContext]);

  return (
    <>
      <AuthConsumer>
        {({ logout }) => {
          return (
            <button
              className="flex items-center gap-2"
              onClick={() => {
                if (window.confirm("Logout?")) {
                  dispatch(resetFinancialDraftData());
                  dispatch(resetOrganizationDraftData());
                  dispatch(resetResourcesDraftData());
                  dispatch(resetDraftData());
                  localStorage.removeItem("userId");
                  logout();
                }
              }}
            >
              <img
                className="rounded-full w-8 h-8"
                src="https://picsum.photos/id/1005/200/200"
                alt=""
              />
              <label className="font-semibold text-[16px] text-white">
                {userData?.profile?.Email}
              </label>
              <RiArrowDownSLine className="text-white" />
            </button>
          );
        }}
      </AuthConsumer>
    </>
  );
};

export default Header;
