import React from "react";
import * as Yup from "yup";
import AtomSelect from "../../../../../components/atoms/Select";
import CardForm from "../../../../../components/molecules/CardForm";
import { InputForm } from "components";

export const FieldLabel = {
  EST_INCOME: "Perkiraan Pendapatan (Bulan/Tahun)",
  INCOME_CYCLE: "Siklus Pendapatan",
  REG_EXPENSE: "Pengeluaran Rutin Per Bulan",
  BIGGEST_EXPENSE: "Pengeluaran Terbesar",
  LARGEST_SPENDING: "Penggunaan Pengeluaran Terbesar",
};

export const validationSchema = Yup.object().shape({
  routine_outcome_per_month: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.REG_EXPENSE),
  biggest_outcome: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.BIGGEST_EXPENSE),
  use_largest_outcome: Yup.string()
    .trim()
    .required()
    .label(FieldLabel.LARGEST_SPENDING),
});

function FinanceSection({ formPrefix, mode, ...rest }) {
  const prefix = formPrefix ? `${formPrefix}.` : "";
  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  return (
    <CardForm label="Informasi Keuangan" {...rest}>
      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        <div className="col-span-2">
          <InputForm
            controllerName={`income_info.0.income`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.EST_INCOME}
            required
            asText={isView}
          />
        </div>

        <div
          className={[
            "col-span-2 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0",
          ]}
        >
          <InputForm
            controllerName={`income_info.0.income_cycle`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.INCOME_CYCLE}
            required
            asText={isView}
          />

          <AtomSelect
            controllerName={`income_info.0.income_cycle_unit`}
            className="basic-single mt-2 flex-1"
            wrapperClass={"col-span-1"}
            returnObject={false}
            label={<span>&nbsp;</span>}
            options={[
              { value: "Bulan", label: "Bulan" },
              { value: "Tahun", label: "Tahun" },
            ]}
            asText={isView}
          />
        </div>

        <div className="col-span-2 space-y-4 flex flex-col md:flex-row md:space-x-4 md:space-y-0">
          <InputForm
            controllerName={`routine_outcome_per_month`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.REG_EXPENSE}
            required
            asText={isView}
          />

          <InputForm
            controllerName={`biggest_outcome`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.BIGGEST_EXPENSE}
            required
            asText={isView}
          />

          <InputForm
            controllerName={`use_largest_outcome`}
            className={`py-2 px-4 mt-2 border w-full rounded-md col-span-1`}
            label={FieldLabel.LARGEST_SPENDING}
            required
            asText={isView}
          />
        </div>
      </div>
    </CardForm>
  );
}

FinanceSection.propTypes = {};

export default FinanceSection;
