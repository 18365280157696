import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import AtomSelect from "../../../atoms/Select";
import { getMasterBussinessType } from "services/fdb/bussinessType";

const SelectJenisUsaha = React.forwardRef((props, ref) => {
  const { className, ...restProps } = props;

  const [options, setOptions] = useState([]);

  const { data } = useQuery({
    queryKey: ["jenis-usaha-ddl"],
    queryFn: async () => {
      // const res = await getJenisUsahaDDL();
      // TODO: Replace this with actual API call
      const res = await getMasterBussinessType();

      return res?.data?.data?.data || [];
    },
    retry: 1,
  });

  useEffect(() => {
    setOptions(
      data?.map((entry) => {
        return {
          value: entry?.id,
          label: entry?.name,
        };
      })
    );
  }, [data]);

  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      {...restProps}
    />
  );
});

export default SelectJenisUsaha;
