import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

export const getMasterBussinessCategory = () => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/finance-business-category`
  );
};
export const getBussinessCategory = (UserId) => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/finance-business-category/${UserId}`
  );
};
