export const PERIOD_OPTIONS = [
  {
    value: 'Quartal',
    label: 'Quartal',
  },
  {
    value: 'Semester',
    label: 'Semester',
  },
  {
    value: 'Tahunan',
    label: 'Tahunan',
  },
];
