import { yupResolver } from '@hookform/resolvers/yup';
import { BottomFormAction, InputFormUpload } from 'components';
import CardForm from 'components/molecules/CardForm';
import RichEditorForm from 'components/molecules/RichEditorForm';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { isRTEEmpty } from '../../../../helpers/validation';
import { validationErrorMsg } from '../../../../locale/yup_id';
import { AiOutlineFile } from 'react-icons/ai';

const validationSchema = yup.object({
  become_agency: yup
    .string()
    .trim()
    .test(
      'become_agency',
      ({ label }) =>
        validationErrorMsg.mixed.required.replace('${path}', label),
      isRTEEmpty
    )
    .required()
    .label('Hal yang Mendasari untuk menjadi Lembaga Penyalur'),
  experience: yup
    .string()
    .trim()
    .test(
      'experience',
      ({ label }) =>
        validationErrorMsg.mixed.required.replace('${path}', label),
      isRTEEmpty
    )
    .required()
    .label(
      'Pengalaman Lembaga dalam Pembiayaan Khususnya Usaha Kehutanan Dan/Atau Investasi Lingkungan'
    ),
});

const BackgroundDealerForm = ({ onBack, onDraft, draftData, onSubmit }) => {
  const options = useMemo(
    () => [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
    ],
    []
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      become_agency: draftData?.become_agency,
      experience: draftData?.experience,
    },
  });

  useEffect(() => {
    methods.reset({ ...draftData });
    return;
  }, [draftData, methods]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      onSubmit(data);
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit()} className="space-y-8">
        <CardForm label="Landasan Hukum Pendirian Lembaga">
          <div className="space-y-2 flex-1">
            <div className="space-y-1">
              <label className="label font-semibold text-[14px] ">
                <span className={`label-text`}>
                  Format Surat Menjadi Lembaga Penyalur
                </span>
              </label>
              <div className="flex space-x-4 bg-white border rounded-md p-4">
                <div className="bg-primary-50 rounded-full p-2 w-fit h-fit">
                  <AiOutlineFile className="text-primary-600" />
                </div>
                <div className="space-y-1">
                  <div>
                    <p className="text-sm font-medium text-gray-700">
                      Format Surat Menjadi Lembaga Penyalur.docx
                    </p>
                    <p className="text-sm font-normal text-gray-700">2MB</p>
                  </div>
                  <div className="text-sm font-semibold text-primary-600 hover:cursor-pointer hover:text-primary-700">
                    Unduh Dokumen
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-2 flex-1">
            <InputFormUpload
              controllerName={`programs`}
              label={'Upload surat menjadi lembaga penyalur'}
              fileType="Docx"
              maxSize={1024 * 10000}
            />
          </div>
          <div className="space-y-2 flex-1">
            <RichEditorForm
              name={`become_agency`}
              label="Hal yang Mendasari untuk menjadi Lembaga Penyalur"
              required
            />
          </div>
          <div className="space-y-2 flex-1">
            <RichEditorForm
              name={`experience`}
              label="Pengalaman Lembaga dalam Pembiayaan Khususnya Usaha Kehutanan Dan/Atau Investasi Lingkungan"
              required
            />
          </div>
        </CardForm>
        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={onFormSubmit()}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
        />
      </form>
    </FormProvider>
  );
};

export default BackgroundDealerForm;
