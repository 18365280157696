import axios from "axios";
import { config_fdb, config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getCountryFDBService = (params) => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/countries`, {
    params,
  });
};

export const getProvinceFDBService = (params) => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/provinces`, {
    baseURL: "",
    params: params,
  });
};

export const getCityFDBService = (params) => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/cities`, { params });
};

export const getDistrictFDBService = (params) => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/districts`, {
    params,
  });
};

export const getVillageFDBService = (params) => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/villages`, { params });
};
export const getZipCodeFDBService = (params) => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/zip-codes`, {
    params,
  });
};
