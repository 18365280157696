import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { getListCallforProposal } from "services/danaProgram/callForProposalService";

const status = [
  "Konsep Note Disetujui",
  "Draft",
  "Kegiatan Selesai",
  "Perlu Set Komitmen",
  "Menunggu Persetujuan",
  "Selesai",
  "Ditutup",
  "Ditolak",
];
const randomDate = (start, end) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};
const DUMMY_DATA = Array.from(Array(25)).map((x, idx) => ({
  title: "Proposal penanaman bibit pohon di hutan lindung Sumbawa",
  program:
    "Dana untuk Kesejahteraan dan Ekonomi Berkelanjutan Masyarakat Adat dan Komunitas Lokal (Dana TERRA)",
  submission_date: randomDate(new Date(2022, 0, 1), new Date()),
  status: status[Math.floor(Math.random() * status.length)],
}));

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const useProposal = ({ params }) => {
  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const {
    data: proposalData,
    isLoading: isLoadingProposalData,
    isFetching: isFetchingProposalData,
    refetch: refetchProposalData,
    isError: isErrorProposalData,
  } = useQuery(["list-proposal"], async () =>
    getListCallforProposal(queryParams)
  );

  return {
    proposalData: proposalData?.data?.data?.data || [],
    paginationProposalData: {
      lastPage: proposalData?.data?.data?.lastPage,
      page: proposalData?.data?.data?.page,
      perPage: proposalData?.data?.data?.perPage,
      total: proposalData?.data?.data?.total,
    },
    isLoadingProposalData,
    isFetchingProposalData,
    isErrorProposalData,
    refetch: refetchProposalData,
  };
};
