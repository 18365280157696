import { ConfirmationModal, HeaderLabel, StepProgress } from "components";
import AlertBlankData from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import GeneralInformationForm from "./generalInfoForm";
import ManagementInfoForm from "./managementInfoForm";
import BusinessLicenseForm from "./businessLicenseForm";
import LegalityDocumentForm from "./legalityDocumentForm";
import { useAuth } from "hooks/auth";
import {
  getChannelingDirectInstitutional,
  updateChannelingDirectInstitutionalBumdes,
  updateDraftChannelingDirectInstitutionalBumdes,
} from "services";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { useMutation, useQuery } from "@tanstack/react-query";

const stepProgress = [
  {
    label: "General Informasi",
    step: 1,
  },
  {
    label: "Info Kepengurusan",
    step: 2,
  },
  {
    label: "Perijinan Usaha",
    step: 3,
  },
  {
    label: "Dokumen Legalitas",
    step: 4,
  },
];

const Kelembagaan = (props) => {
  const { type } = props;
  const navigate = useNavigate();
  const { id } = useAuth();
  const { data } = useQuery(
    ["channeling-direct-institutional", id],
    async () => (await getChannelingDirectInstitutional(id)).data?.data
  );

  console.log("DATA", data);

  const [activeStep, setActiveStep] = useState(1);
  const [bodyToSend, setBodyToSend] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [draftModal, setDraftModal] = useState(false);

  console.log({ stepProgress });

  const routes = useMemo(
    () => [
      {
        label: "Formulir Pendaftaran",
        path: `/lembaga-penyalur/${type}`,
      },
      {
        label: "Registrasi Prospecting Lembaga Penyalur",
        path: `/lembaga-penyalur/${type}`,
      },
      {
        label: "Kelembagaan",
        path: `/lembaga-penyalur/${type}/kelembagaan`,
      },
    ],
    [type]
  );

  const onBack = useCallback(() => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, navigate]);

  const onDraft = useCallback((payload) => {
    setBodyToSend((state) => ({ ...state, ...payload }));
    setDraftModal(true);
  }, []);

  const onSubmit = useCallback(
    (payload) => {
      setBodyToSend((state) => ({ ...state, ...payload }));
      if (activeStep < stepProgress.length) {
        setActiveStep(activeStep + 1);
      } else {
        setConfirmModal(true);
      }
    },
    [activeStep]
  );

  const params = useMemo(() => {
    if (!bodyToSend) return {};

    const body = new FormData();
    body.append("background", bodyToSend.latar_belakang);
    body.append("vision_mission", bodyToSend.visi_misi);
    body.append("amount_of_member", bodyToSend.jumlah_pengguna);
    body.append("amount_of_branch", bodyToSend.jumlah_cabang);
    body.append("amount_of_employee", bodyToSend.jumlah_pegawai);
    body.append("nib", bodyToSend.nib);
    body.append("nib_date", bodyToSend.nib_date);
    body.append("business_license", bodyToSend.business_license);
    body.append("business_license_date", bodyToSend.business_license_date);

    bodyToSend.daftardireksi?.forEach((item, index) => {
      body.append(`advisor[${index}][name]`, item.nama);
      body.append(`advisor[${index}][position]`, item.jabatan);
      body.append(`advisor[${index}][ktp]`, item.NIK);
      body.append(`advisor[${index}][phone]`, item.no_telepon);
    });

    body.append(
      "organization_structure_file",
      bodyToSend.struktur_organisasi_upload
    );

    return body;
  }, [bodyToSend]);

  console.log(bodyToSend);

  const onError = useCallback((error) => {
    enqueueSnackbar(getErrorMessage(error), {
      variant: "error",
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar("Data Berhasil Disimpan!", {
      variant: "success",
    });
    navigate("/bumdes/supplier-application");
  }, [navigate]);

  const { mutate: update } = useMutation({
    mutationFn: () => {
      return updateChannelingDirectInstitutionalBumdes(id, params);
    },
    onError,
    onSuccess,
  });

  const { mutate: updateDraft } = useMutation({
    mutationFn: () => {
      return updateDraftChannelingDirectInstitutionalBumdes(id, params);
    },
    onError,
    onSuccess,
  });

  const onCreate = () => {
    update();
  };

  const onCreateDraft = () => {
    updateDraft();
  };

  return (
    <div>
      <ConfirmationModal
        open={confirmModal}
        onClose={setConfirmModal}
        onSubmit={onCreate}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan submit semua data ini?
        </div>
        <div className="text-sm font-[400]">
          Setelah anda submit anda masih bisa mengubah data.
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        open={draftModal}
        onClose={setDraftModal}
        onSubmit={onCreateDraft}
        className="space-y-2 mb-4"
      >
        <div className="text-lg font-[600]">
          Anda yakin akan menyimpan data ini sebagai draft?
        </div>
        <div className="text-sm font-[400]">
          Setelah Anda menyimpan data sebagai draft anda masih bisa
          melanjutkannya.
        </div>
      </ConfirmationModal>

      <div className="w-full space-y-6 bg-white rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={routes} />

        <HeaderLabel
          text="Kelembagaan"
          description="Lengkapi persyaratan Kelembagaan."
        />

        <div className="border-b" />

        <div className="w-full flex justify-center">
          <div className="w-[800px]">
            <StepProgress
              stepProgressData={stepProgress}
              activeStep={activeStep}
            />
          </div>
        </div>

        <AlertBlankData
          title={"Lengkapi Data"}
          body={
            "Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisisan ke tahap selanjutnya."
          }
        />

        <div>
          {activeStep === 1 && (
            <GeneralInformationForm
              onBack={onBack}
              onSubmit={onSubmit}
              onDraft={onDraft}
              props={props}
            />
          )}
          {activeStep === 2 && (
            <ManagementInfoForm
              onBack={onBack}
              onSubmit={onSubmit}
              onDraft={onDraft}
            />
          )}
          {activeStep === 3 && (
            <BusinessLicenseForm
              onBack={onBack}
              onSubmit={onSubmit}
              onDraft={onDraft}
              props={props}
            />
          )}
          {activeStep === 4 && (
            <LegalityDocumentForm
              onBack={onBack}
              onSubmit={onSubmit}
              onDraft={onDraft}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Kelembagaan;
