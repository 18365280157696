import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import {
  RiUploadCloud2Line,
  RiComputerLine,
  RiBankLine,
  RiCoinsLine,
  RiApps2Line,
  RiShieldCheckLine,
  RiEdit2Line,
} from "react-icons/ri";
import { useNavigate } from "react-router";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import AlertBlankData from "components/molecules/Alert";
import { AlertDataComplete } from "components/molecules/Alert";
import ProfileUmumGlobalState from "services/danaProgram/globalState";
import { ToastContext, SuccessToast } from "components/atoms/Toast";

import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import {
  getHomepageStatus,
  submitAllData,
} from "services/danaProgram/homepage";
import { stringify } from "postcss";
import { useAuthContext } from "providers/authProvider";
import PenilaianLemtaraChild from "./penilaianLemtaraChild";

const PenilaianLemtara = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const [statusComplete, setStatusComplete] = useState(false);
  const [homepageData, setHomepageData] = useState({});

  let userId = localStorage.getItem("userId");

  const mutation = useMutation({
    mutationFn: async () => {
      try {
        const promise = await getHomepageStatus(userId);
        setHomepageData(promise?.data?.body);
        setStatusComplete(areAllStatusesSubmitted(promise?.data?.body));
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
  });

  function areAllStatusesSubmitted(data) {
    for (let key in data) {
      if (data[key].status !== "SUBMITTED") {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    mutation.mutate();
  }, [userId]);

  return (
    <div className="w-full space-y-4">
      <PenilaianLemtaraChild
        statusComplete={statusComplete}
        data={homepageData}
        userId={userId}
      />
    </div>
  );
};

export default PenilaianLemtara;
