import { BottomFormAction, ConfirmationModal, StepProgress } from "components";
import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RequestSection from "./requestSection";
import ProjectSection from "./projectSection";
import CollateralSection from "../FormPembiayaan/collateralSection";
import DocsSection, { requiredDocsTT } from "./docsSection";
import { AgreementSection } from "../FormPembiayaan/agreementSection";
import { useLocation, useNavigate } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getDetailPermohonanPembiayaan,
  sendPermohonanPembiayaan,
} from "services/fdb/permohonan-pembiayaan";
import { createPortal } from "react-dom";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";

const TabItems = {
  INFO: 1,
  LEGAL_DOCS: 2,
};

const TabSteps = [
  {
    label: "Data Usaha & Jaminan",
    step: TabItems.INFO,
  },
  {
    label: "Dokumen Penguasaan Lahan",
    step: TabItems.LEGAL_DOCS,
  },
];

// const schema = yup.object({
//   activeStep: yup.number(),
//   step1: yup.object().when("activeStep", (activeStep, schema) => {}),
//   step2: yup.object({
//     document: yup.array().of(
//       yup.object({
//         id: yup.string().nullable(),
//         name: yup.string().nullable(),
//         desc: yup.string().nullable(),
//         file: yup.string().nullable(),
//       })
//     ),
//   }),
// });

const schemaStep1 = yup.object({
  application_fee: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .notRequired()
    .label("Nominal Permohonan"),
  application_fee_spel: yup.string().notRequired().label("Terbilang"),
  year_duration: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .notRequired()
    .label("Jangka Waktu (Tahun)"),
  finance_scheme: yup.string().required().label("Skema Pembiayaan"),
  guarantee_plan: yup.string().notRequired().label("Rencana Jaminan"),

  // ? PROJECT SECTION
  bussiness_type: yup.string().required().label("Jenis Usaha"),
  temp_bussiness_type: yup.object().nullable(),
  bussiness_commodities: yup
    .string()
    .when("temp_bussiness_type", ([businessType], schema) => {
      if (businessType?.label?.toLowerCase()?.includes("non")) {
        return schema.required().label("Komoditas Usaha PS");
      }
      return schema.notRequired();
    }),
  bussiness_duration: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .label("Lama Usaha"),
  bussiness_duration_type: yup.string().required().label("Lama Usaha"),
  capacity_productivity: yup
    .string()
    .required()
    .label("Kapasitas Usaha/Produksi"),
  last_productivity: yup
    .string()
    .required()
    .label("Produktivitas/Hasil Panen Terakhir"),
  last_price: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .label("Harga (Penjualan Terakhir)"),
  farmed_acreage: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .notRequired()
    .label("Luasan Lahan yang di Garap"),
  revenue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .label("Omzet Usaha"),
  cost_price: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .label("HPP/Modal"),
  net_income: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .label("Pendapatan Bersih"),
  income_statement_file: yup.string().required().label("Laporan Laba Rugi"),
  market_target: yup.string().required().label("Tujuan Pemasaran"),
  business_partner: yup.array().of(
    yup.object({
      value: yup.string(),
    })
  ),
  bussiness_manage_type: yup.string().notRequired().label("Tipe Usaha"),
  bussiness_cycle: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .label("Siklus Usaha"),
  bussiness_cycle_unit: yup.string().required().label("Siklus Usaha"),
  qty_exist_commodity: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .notRequired()
    .label("Kuantitas komoditas yang sudah ada"),
  purpose_loan_application: yup
    .string()
    .notRequired()
    .label("Tujuan Pengajuan Pinjaman"),
  purpose_loan_application_desc: yup
    .string()
    .required()
    .label("Jelaskan Tujuan Pengajuan Pinjaman Anda"),
  // created_at_place: "string",
  tally_sheet_file: yup.string().required().label("Upload Data Jaminan"),
  // tally_sheet_url: "string",
  colleterals: yup.array().of(
    yup.object({
      id: yup.string().nullable(),
      collateral_type: yup
        .string()
        .notRequired()
        .nullable()
        .label("Bentuk Jaminan"),
      collateral_fee: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .notRequired()
        .label("Kuantitas komoditas yang sudah ada"),
      collateral_file: yup
        .string()
        .nullable()
        .notRequired()
        .label("File Jaminan"),
    })
  ),
});

const schemaStep2 = yup.object({
  document: yup.array().of(
    yup.object({
      id: yup.string().nullable(),
      name: yup.string().nullable(),
      desc: yup.string().nullable(),
      file: yup.string().required().nullable(),
    })
  ),
  created_at_place: yup.string().required().label("Dibuat Pada (Tempat)"),
  agree: yup
    .bool()
    .required("Anda harus menyetujui pernyataan ini.")
    .oneOf([true], "Anda harus menyetujui pernyataan ini."),
});

const FormTundaTebang = ({ mode }) => {
  const docsRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;

  const [activeStep, setActiveStep] = useState(1);
  const [draftModal, setDraftModal] = useState();
  const [confirmModal, setConfirmModal] = useState();

  const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
    (e) => e === mode
  );

  const methods = useForm({
    resolver:
      activeStep === 1 ? yupResolver(schemaStep1) : yupResolver(schemaStep2),
    defaultValues: {
      application_fee: state.value,
      application_fee_spel: `${angkaTerbilangJs(state.value)} rupiah`,
      colleterals: state?.deferred_logging_application_id == null && [
        {
          id: "",
          colleteral_id: "",
          collateral_type: "",
          collateral_fee: "",
          collateral_file: undefined,
        },
      ],
      document: requiredDocsTT.map((data) => ({
        id: null,
        name: data.title,
        desc: data.desc,
        key: data.key,
        file: null,
        selected_file: undefined,
      })),
      agree: false,
    },
  });

  useQuery(
    ["GET DETAIL PERMOHONAN PEMBIAYAAN", state.deferred_logging_application_id],
    ({ queryKey }) => getDetailPermohonanPembiayaan(queryKey[1]),
    {
      enabled: !!state.deferred_logging_application_id,
      onSuccess: (res) => {
        const colleterals =
          res.data.data.colleterals.length > 0
            ? res?.data?.data?.colleterals.map((colleteral) => ({
                id: colleteral.id,
                colleteral_id: colleteral.id,
                collateral_type: colleteral.collateral_type,
                collateral_fee: colleteral.collateral_fee,
                collateral_file: colleteral.collateral_file,
              }))
            : [
                {
                  id: "",
                  colleteral_id: "",
                  collateral_type: "",
                  collateral_fee: "",
                  collateral_file: undefined,
                },
              ];

        const documents =
          res.data.data?.document.length > 0
            ? res.data.data.document
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                .map((doc) => ({
                  id: doc.id,
                  file: doc.file,
                  desc: doc.desc,
                  name: doc.name,
                  selected_file: doc.file ?? undefined,
                }))
            : requiredDocsTT.map((data) => ({
                id: null,
                name: data.title,
                desc: data.desc,
                key: data.key,
                file: null,
                selected_file: undefined,
              }));

        methods.reset({
          ...res.data.data,
          last_price: res.data.data.last_price,
          colleterals: colleterals,
          business_partner:
            res.data.data.bussiness_partner.length > 0
              ? res.data.data.bussiness_partner.map((partner) => ({
                  value: partner,
                }))
              : [""],
          document: documents,
          agree: false,
        });
      },
    }
  );

  const mutatePermohonanPembiayaan = useMutation(
    ["PATCH PERMOHONAN PEMBIAYAAN"],
    ({ data, params }) => {
      return sendPermohonanPembiayaan(data, params);
    }
  );

  const onFormSubmit = (data) => {
    if (activeStep === 1) {
      setActiveStep(2);

      return;
    }

    setConfirmModal(true);
  };

  const handleDraftButton = () => {
    setDraftModal(true);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleSaveDraft = () => {
    const payload = methods.getValues();

    const mapPayload = {
      ...payload,
      colleterals: payload?.colleterals.filter(
        (e) =>
          e.collateral_fee !== "" &&
          e.collateral_type !== "" &&
          e.collateral_file !== ""
      ),
      bussiness_partner: payload.business_partner.map((data) => data.value),
    };

    mutatePermohonanPembiayaan.mutateAsync(
      {
        data: mapPayload,
        params: {
          is_draft: true,
          deferred_id: state.deferred_logging_application_id,
          request_id: state.id,
          applicant_member_id: state.applicant_member_id,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            message: "Data Berhasil Disimpan!",
            variant: "success",
          });

          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            message: getErrorMessage(err),
            variant: "error",
          });

          window.scrollTo({ behavior: "smooth", top: 0 });
        },
      }
    );
  };

  const handleSubmit = () => {
    const payload = methods.getValues();

    const mapPayload = {
      ...payload,
      colleterals: payload?.colleterals.filter(
        (e) =>
          e.collateral_fee !== "" &&
          e.collateral_type !== "" &&
          e.collateral_file !== ""
      ),
      bussiness_partner: payload.business_partner.map((data) => data.value),
    };

    console.log(mapPayload);

    mutatePermohonanPembiayaan.mutateAsync(
      {
        data: mapPayload,
        params: {
          is_draft: false,
          deferred_id: state.deferred_logging_application_id,
          request_id: state.id,
          applicant_member_id: state.applicant_member_id,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            message: "Data Berhasil Disimpan!",
            variant: "success",
          });

          navigate(`/${location.pathname.split("/")[1]}/self-assessment/start`);
        },
        onError: (err) => {
          enqueueSnackbar({
            message: getErrorMessage(err),
            variant: "error",
          });

          window.scrollTo({ behavior: "smooth", top: 0 });
        },
      }
    );
  };

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={draftModal}
          onCancel={setDraftModal}
          onSubmit={handleSaveDraft}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menyimpan data ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah Anda menyimpan data sebagai draft anda masih bisa
            melanjutkannya.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onCancel={setConfirmModal}
          onSubmit={handleSubmit}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}

      {/* TODO: IMPL LOADING PROGRESS */}
      {/* {createPortal(
        <ProgressModal
          open={
            (draftModal && draftMutation.isLoading) ||
            (confirmModal && submitMutation.isLoading)
          }
          className="space-y-2 mb-4"
        />,
        document.body
      )} */}

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onFormSubmit)}
          className="space-y-8"
        >
          <div className="w-full flex justify-center">
            <div className="w-[800px] mb-10">
              <StepProgress
                stepProgressData={TabSteps}
                activeStep={activeStep}
              />
            </div>
          </div>

          <div className={"space-y-8"}>
            {activeStep === 1 && (
              <>
                <RequestSection mode={mode} />
                <ProjectSection mode={mode} />
                <CollateralSection mode={mode} isUploading uploadProgress />
              </>
            )}

            {activeStep === 2 && <DocsSection ref={docsRef} mode={mode} />}
          </div>

          {activeStep === 2 && (
            <AgreementSection
              mode={mode}
              message={
                "Segala isi dan pernyataan yang terdapat pada formulir ini kami buat dengan sebenar-benarnya tanpa ada paksaan dari suatu apapun dan bertanggung jawab apabila data disebutkan diatas tidak benar adanya"
              }
            />
          )}

          <BottomFormAction
            lastStep={activeStep === TabItems.LEGAL_DOCS}
            backButtonProps={{
              type: "button",
            }}
            draftButtonProps={{
              type: "button",
            }}
            submitButtonProps={{
              type: "submit",
            }}
            backButtonAction={handleBackButton}
            hideDraft={isView}
            hideSubmit={activeStep === TabItems.LEGAL_DOCS && isView}
            disableDrafButton={false}
            drafButtonAction={handleDraftButton}
            disableButtonSubmit={false}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default FormTundaTebang;

// import { yupResolver } from "@hookform/resolvers/yup";
// import { useMutation, useQuery } from "@tanstack/react-query";
// import { getErrorMessage } from "helpers";
// import { enqueueSnackbar } from "notistack";
// import React, { useEffect, useRef, useState } from "react";
// import { FormProvider, useForm } from "react-hook-form";
// import { useLocation, useParams } from "react-router";
// import { getDetailDeffered, postFileServices } from "services/fdb/akunKelompok";
// import * as Yup from "yup";
// import { BottomFormAction } from "../../../../../components";
// import StepProgress from "../../../../../components/molecules/StepProgress";
// import {
//   AgreementSection,
//   validationSchema as AgreeValSchema,
// } from "../../agreementSection";
// import CollateralSection, {
//   validationSchema as CollateralValSchema,
// } from "../FormPembiayaan/collateralSection";
// import DocsSection, {
//   requiredDocs,
//   validationSchema as DocsValSchema,
// } from "./docsSection";
// import ProjectSection, {
//   validationSchema as ProjectValSchema,
// } from "./projectSection";
// import RequestSection, {
//   validationSchema as RequestValSchema,
// } from "./requestSection";

// const TabItems = {
//   INFO: 1,
//   LEGAL_DOCS: 2,
// };

// const TabSteps = [
//   {
//     label: "Data Usaha & Jaminan",
//     step: TabItems.INFO,
//   },
//   {
//     label: "Dokumen Penguasaan Lahan",
//     step: TabItems.LEGAL_DOCS,
//   },
// ];

// const validationSchema = Yup.object().shape({
//   request: RequestValSchema,
//   // project: ProjectValSchema,
//   // collateral: CollateralValSchema,
//   // docs: DocsValSchema,
//   // agree: AgreeValSchema,
// });

// function FormTundaTebang({
//   onSubmit,
//   onDraft,
//   onBack,
//   isUploading,
//   uploadProgress,
//   setBodyToSend,
//   mode,
//   ...other
// }) {
//   const docsRef = useRef();
//   const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
//     (e) => e === mode
//   );

//   const [activeStep, setActiveStep] = useState(1);

//   const params = useParams();
//   const { id } = params || {};

//   const { state } = useLocation();
//   const { application_fee, request_id, applicant_member_id } = state;
//   const [documentPayload, setDocumentPayload] = useState([]);

//   const { data: detail } = useQuery({
//     queryKey: ["fdb-tunda-tebang--detail", id],
//     queryFn: async () => {
//       const res = await getDetailDeffered(id);
//       return res.data.data;
//     },

//     refetchOnWindowFocus: false,
//     refetchOnReconnect: false,
//     enabled: !!id,
//   });

//   const [initForm, setInitForm] = useState({
//     request: {
//       application_fee: detail?.application_fee,
//       year_duration: detail?.year_duration,
//       finance_scheme: detail?.finance_scheme,
//       guarantee_plan: detail?.guarantee_plan,
//     },
//     project: {
//       bussiness_type: detail?.bussiness_type,
//       bussiness_commodities: detail?.bussiness_commodities,
//       bussiness_duration: detail?.bussiness_duration,
//       bussiness_duration_type: detail?.bussiness_duration_type,
//       bussiness_manage_type: detail?.bussiness_manage_type,
//       bussiness_cycle: detail?.bussiness_cycle,
//       bussiness_cycle_unit: detail?.bussiness_cycle_unit,
//       qty_exist_commodity: detail?.qty_exist_commodity,
//       last_productivity: detail?.last_productivity,
//       last_price: detail?.last_price,
//       farmed_acreage: detail?.farmed_acreage,
//       revenue: detail?.revenue,
//       cost_price: detail?.cost_price,
//       net_income: detail?.net_income,
//       market_target: detail?.market_target,
//       purpose_loan_application: detail?.purpose_loan_application,
//       purpose_loan_application_desc: detail?.purpose_loan_application_desc,
//       partner: [{ value: "" }],
//       tally_sheet_data: detail?.tally_sheet,
//       tally_sheet_file: detail?.tally_sheet_file,
//       income_statement_file: detail?.income_statement_file,
//     },
//     docs: {
//       docs: [
//         ...requiredDocs.map((e) => ({ ...e, file: undefined, required: true })),
//       ],
//     },
//     agree: {
//       created_at: new Date().toISOString(),
//     },
//     colleterals: {
//       tally_sheet_file: detail?.tally_sheet_file,
//       collateral_type: detail?.colleterals?.collateral_type,
//       collateral_fee: detail?.colleterals?.collateral_fee,
//       collateral_file: detail?.colleterals?.collateral_file,
//     },
//     created_from: detail?.created_at_place,
//   });

//   const methods = useForm({
//     resolver: isView ? undefined : yupResolver(validationSchema),
//     mode: "onChange",
//     defaultValues: initForm,
//   });

//   useEffect(() => {
//     methods.reset({
//       request: {
//         application_fee: detail?.application_fee,
//         year_duration: detail?.year_duration,
//         finance_scheme: detail?.finance_scheme,
//         guarantee_plan: detail?.guarantee_plan,
//       },
//       project: {
//         bussiness_type: detail?.bussiness_type,
//         bussiness_commodities: detail?.bussiness_commodities,
//         bussiness_duration: detail?.bussiness_duration,
//         bussiness_duration_type: detail?.bussiness_duration_type,
//         bussiness_manage_type: detail?.bussiness_manage_type,
//         bussiness_cycle: detail?.bussiness_cycle,
//         bussiness_cycle_unit: detail?.bussiness_cycle_unit,
//         qty_exist_commodity: detail?.qty_exist_commodity,
//         last_productivity: detail?.last_productivity,
//         last_price: detail?.last_price,
//         farmed_acreage: detail?.farmed_acreage,
//         revenue: detail?.revenue,
//         cost_price: detail?.cost_price,
//         net_income: detail?.net_income,
//         market_target: detail?.market_target,
//         purpose_loan_application: detail?.purpose_loan_application,
//         purpose_loan_application_desc: detail?.purpose_loan_application_desc,
//         partner: [{ value: "" }],
//         tally_sheet_data: detail?.tally_sheet,
//         tally_sheet_file: detail?.tally_sheet_file,
//         income_statement_file: detail?.income_statement_file,
//       },
//       docs: {
//         docs: [
//           ...requiredDocs.map((e) => ({
//             ...e,
//             file: undefined,
//             required: true,
//           })),
//         ],
//       },
//       agree: {
//         created_at: new Date().toISOString(),
//       },
//       colleterals: {
//         tally_sheet_file: detail?.tally_sheet_file,
//         collateral_type: detail?.colleterals?.collateral_type,
//         collateral_fee: detail?.colleterals?.collateral_fee,
//         collateral_file: detail?.colleterals?.collateral_file,
//       },
//       created_from: detail?.created_at_place,
//     });
//   }, [detail]);

//   const { mutateAsync } = useMutation({
//     mutationFn: async (params) => {
//       return postFileServices(params);
//     },
//     onSuccess: (res) => {
//       enqueueSnackbar({
//         message: "File Berhasil Disimpan!",
//         variant: "success",
//       });
//       return res;
//     },
//     onError: (error) => {
//       enqueueSnackbar({
//         message: getErrorMessage(error),
//         variant: "error",
//       });
//     },
//   });

//   const saveFilePayload = async (file) => {
//     const fileFormData = new FormData();

//     if (file instanceof File) {
//       fileFormData.append("file", file);

//       try {
//         const response = await mutateAsync(fileFormData);

//         if (response.status === 200) {
//           const doc = response.data.data;
//           return doc.id;
//         }
//       } catch (error) {
//         console.error("An error occurred:", error);
//         return null; // or handle error cases differently
//       }
//     }
//   };

//   const getUrlTallySheet = async (file) => {
//     const fileFormData = new FormData();

//     if (file instanceof File) {
//       fileFormData.append("file", file);

//       try {
//         const response = await mutateAsync(fileFormData);

//         if (response.status === 200) {
//           const doc = response.data.data;
//           return doc.url;
//         }
//       } catch (error) {
//         console.error("An error occurred:", error);
//         return null; // or handle error cases differently
//       }
//     }
//   };

//   const saveDocumentPayload = async () => {
//     const formData = methods?.getValues();

//     const documents = formData.docs.docs.filter(
//       (item) => item.file !== "undefined"
//     );

//     const updatedDocumentPayload = await Promise.all(
//       documents.map(async (item) => {
//         const fileFormData = new FormData();

//         if (item.file instanceof File) {
//           fileFormData.append("file", item.file);

//           try {
//             const response = await mutateAsync(fileFormData);

//             if (response.status === 200) {
//               const doc = response.data.data;

//               return {
//                 ...item,
//                 file: doc.id,
//                 url: doc.url,
//               };
//             }
//           } catch (error) {
//             console.error("An error occurred:", error);
//             return null;
//           }
//         }
//       })
//     );

//     const filteredDocumentPayload = updatedDocumentPayload.filter(Boolean);
//     setDocumentPayload(filteredDocumentPayload);
//   };

//   const onBackClick = () => {
//     if (activeStep === 1 && onBack) {
//       onBack();
//     } else {
//       setActiveStep(activeStep - 1);
//     }
//   };

//   const getPayload = async () => {
//     await saveDocumentPayload();

//     const data = methods.getValues();
//     console.log(data);

//     const { request, project, colleterals, docs } = data;

//     const payload = {
//       application_fee: application_fee ?? parseInt(request.application_fee),
//       application_fee_spel: request.amount_word,
//       year_duration: request.year_duration,
//       finance_scheme: request.finance_scheme,
//       guarantee_plan: request.guarantee_plan,
//       bussiness_type: project.bussiness_type,
//       bussiness_commodities: project.bussiness_commodities,
//       bussiness_duration: project.bussiness_duration,
//       bussiness_duration_type: project.bussiness_duration_type,
//       last_productivity: project.last_productivity,
//       last_price: parseInt(project.last_price),
//       farmed_acreage: project.farmed_acreage,
//       revenue: parseInt(project.revenue),
//       cost_price: parseInt(project.cost_price),
//       net_income: parseInt(project.net_income),
//       income_statement_file:
//         detail?.income_statement_file ??
//         (await saveFilePayload(project?.income_statement_file)),
//       market_target: project.market_target,
//       bussiness_partner: ["string"],
//       bussiness_manage_type: project.bussiness_manage_type,
//       bussiness_cycle: project.bussiness_cycle,
//       bussiness_cycle_unit: project?.bussiness_cycle_unit,
//       qty_exist_commodity: parseInt(project?.qty_exist_commodity),
//       purpose_loan_application: project.purpose_loan_application,
//       purpose_loan_application_desc: project.purpose_loan_application_desc,
//       tally_sheet_file:
//         data?.project?.tally_sheet_file ??
//         (await saveFilePayload(data?.colleterals?.tally_sheet_file)),
//       created_at_place: data?.created_from,
//       document: docs?.doc ?? documentPayload,
//       colleterals: [
//         {
//           collateral_type: colleterals.collateral_type,
//           collateral_fee: colleterals.collateral_fee,
//           collateral_file:
//             detail?.collateral_file ??
//             (await saveFilePayload(colleterals.collateral_file)),
//         },
//       ],
//       tally_sheet_url: await getUrlTallySheet(
//         data?.colleterals?.tally_sheet_file
//       ),
//     };
//     console.log(payload);

//     return payload;
//   };

//   const onSaveDraft = async () => {
//     const payload = await getPayload();
//     setBodyToSend(payload);
//     onDraft();
//   };

//   const onFormSubmit = () => {
//     return methods.handleSubmit(async (data) => {
//       const isLast = activeStep === TabSteps.at(-1)?.step;
//       const payload = await getPayload();
//       setBodyToSend(payload);

//       if (isLast) {
//         await onSubmit(isLast, data);
//       }

//       if (!isLast) {
//         const curr = TabSteps.findIndex((e) => e.step === activeStep);
//         setActiveStep(TabSteps.at(curr + 1)?.step);
//       }
//     });
//   };

//   return (
//     <FormProvider {...methods}>
//       <form onSubmit={onFormSubmit()} className="space-y-8">
//         <div className="w-full flex justify-center">
//           <div className="w-[800px] mb-10">
//             <StepProgress stepProgressData={TabSteps} activeStep={activeStep} />
//           </div>
//         </div>

//         <div className={"space-y-8"}>
//           {activeStep === 1 && (
//             <>
//               <RequestSection formPrefix={"request"} mode={mode} />
//               <ProjectSection formPrefix={"project"} mode={mode} />
//               <CollateralSection
//                 formPrefix={"colleterals"}
//                 mode={mode}
//                 isUploading
//                 uploadProgress
//               />
//             </>
//           )}

//           {activeStep === 2 && (
//             <DocsSection ref={docsRef} formPrefix={"docs"} mode={mode} />
//           )}
//         </div>

//         {activeStep === 2 && (
//           <AgreementSection
//             mode={mode}
//             formPrefix={"agree"}
//             message={
//               "Segala isi dan pernyataan yang terdapat pada formulir ini kami buat dengan sebenar-benarnya tanpa ada paksaan dari suatu apapun dan bertanggung jawab apabila data disebutkan diatas tidak benar adanya"
//             }
//           />
//         )}

//         <BottomFormAction
//           lastStep={activeStep === TabItems.LEGAL_DOCS}
//           backButtonProps={{
//             type: "button",
//           }}
//           draftButtonProps={{
//             type: "button",
//           }}
//           submitButtonProps={{
//             type: "submit",
//           }}
//           backButtonAction={onBackClick}
//           hideDraft={isView}
//           hideSubmit={activeStep === TabItems.LEGAL_DOCS && isView}
//           disableDrafButton={false}
//           drafButtonAction={onSaveDraft}
//           disableButtonSubmit={false}
//         />
//       </form>
//     </FormProvider>
//   );
// }

// FormTundaTebang.propTypes = {};

// export default FormTundaTebang;
