/* eslint-disable import/no-anonymous-default-export */
// import InfoAkun from "pages/FDB/Kelompok/InfoAkun";
import { lazy } from "react";
import AkunKelompokRegisForm from "../pages/FDB/Kelompok/FormPendaftaran";
import AkunKelompokNPSFormMain from "../pages/FDB/Kelompok/FormPendaftaran/NonPerhutananSosial";
import AkunKelompokPSFormMain from "../pages/FDB/Kelompok/FormPendaftaran/PerhutananSosial";

import AkunKelompokPengajuanDaftarPermohonan from "../pages/FDB/Kelompok/PengajuanDaftarPermohonan";
import AkunKelompokNonPerhutananSosialPengajuanDaftar from "../pages/FDB/Kelompok/PengajuanDaftarPermohonan/NonPerhutananSosial";
import AkunKelompokNPSPengajuanDaftarMemberForm from "../pages/FDB/Kelompok/PengajuanDaftarPermohonan/NonPerhutananSosial/memberForm";
import AkunKelompokPSPengajuanDaftar from "../pages/FDB/Kelompok/PengajuanDaftarPermohonan/PerhutananSosial";
import AkunKelompokPSPengajuanDaftarMemberForm from "../pages/FDB/Kelompok/PengajuanDaftarPermohonan/PerhutananSosial/memberForm";
import FormulirPendaftaranPS from "pages/FDB/Kelompok/FormulirPendaftaran/PerhutananSosial";
import PermohonanPencairan from "pages/FDB/Kelompok/PermohonanPencairan";
import PermohonanPencairanDetail from "pages/FDB/Kelompok/PermohonanPencairan/Detail";
import PermohonanPencairanDetailAssessment from "pages/FDB/Kelompok/PermohonanPencairan/Detail/DetailSelfAssessment";
import FormulirPendaftaranNPS from "pages/FDB/Kelompok/FormulirPendaftaran/NonPerhutananSosial";

const PageType = {
  PS: "perhutanan-sosial",
  NPS: "non-perhutanan-sosial",
};

const generateRegisDashboardComp = (props) => () =>
  <AkunKelompokRegisForm {...props} />;
const generatePengajuanPermohonanDashboardComp = (props) => () =>
  <AkunKelompokPengajuanDaftarPermohonan {...props} />;
const generatePengajuanPSPermohonanFormComp = (props) => () =>
  <AkunKelompokPSPengajuanDaftar {...props} />;
const generatePengajuanNPSPermohonanFormComp = (props) => () =>
  <AkunKelompokNonPerhutananSosialPengajuanDaftar {...props} />;

const generatePermohonanPencairanComp = (props) => () =>
  <PermohonanPencairan {...props} />;
const generatePermohonanPencairanDetailComp = (props) => () =>
  <PermohonanPencairanDetail {...props} />;
const generatePermohonanPencairanDetailAssessment = (props) => () =>
  <PermohonanPencairanDetailAssessment {...props} />;
// ? INFO AKUN
const InfoAkun = lazy(() => import("../pages/FDB/Kelompok/InfoAkun"));

const generateInfoAkun = (props) => () => <InfoAkun {...props} />;

export default [
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/formulir-pendaftaran",
    element: generateRegisDashboardComp({ type: PageType.PS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/formulir-pendaftaran",
    element: generateRegisDashboardComp({ type: PageType.NPS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/formulir-pendaftaran/edit",
    element: AkunKelompokPSFormMain,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/formulir-pendaftaran/:id/edit",
    element: FormulirPendaftaranPS,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/formulir-pendaftaran/edit",
    element: AkunKelompokNPSFormMain,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/formulir-pendaftaran/:id/edit",
    element: FormulirPendaftaranNPS,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/formulir-pendaftaran/view",
    element: AkunKelompokPSFormMain,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/formulir-pendaftaran/:id/view",
    element: FormulirPendaftaranPS,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/formulir-pendaftaran/view",
    element: AkunKelompokNPSFormMain,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/formulir-pendaftaran/:id/view",
    element: FormulirPendaftaranNPS,
    exact: true,
    sidebar: false,
  },

  // PENGAJUAN DAFTAR PERMOHONAN
  // =======================================================================
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan",
    element: generatePengajuanPermohonanDashboardComp({ type: PageType.PS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan",
    element: generatePengajuanPermohonanDashboardComp({ type: PageType.NPS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan/add",
    element: generatePengajuanPSPermohonanFormComp({ mode: "add" }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan/add",
    element: generatePengajuanNPSPermohonanFormComp({ mode: "add" }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan/edit/:id",
    element: generatePengajuanPSPermohonanFormComp({ mode: "edit" }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan/edit/:id",
    element: generatePengajuanNPSPermohonanFormComp({ mode: "edit" }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan/view/:id",
    element: generatePengajuanPSPermohonanFormComp({ mode: "view" }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan/view/:id",
    element: generatePengajuanNPSPermohonanFormComp({ mode: "view" }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan/edit/:id/member/edit/:memberId",
    element: AkunKelompokPSPengajuanDaftarMemberForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan/edit/:id/member/edit/:memberId/:applicantId",
    element: AkunKelompokNPSPengajuanDaftarMemberForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan/view/:id/member/view/:memberId",
    element: AkunKelompokPSPengajuanDaftarMemberForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan/view/:id/member/view/:memberId",
    element: AkunKelompokNPSPengajuanDaftarMemberForm,
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/info-akun",
    element: InfoAkun,
    exact: true,
    sidebar: true,
  },

  // ? KELOMPOK AKUN
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/info-akun",
    element: generateInfoAkun({ mode: PageType.PS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/info-akun",
    element: generateInfoAkun({ mode: PageType.NPS }),
    exact: true,
    sidebar: true,
  },

  // ? KELOMPOK PENGAJUAN DAFTAR PERMOHONAN
  // {
  //   routeType: "private",
  //   path: "/kelompok/perhutanan-sosial/pengajuan-daftar-permohonan/edit/:id/member/edit/:memberId/:applicantId",
  //   element: generateMemberForm({ mode: PageType.PS }),
  //   exact: true,
  //   sidebar: false,
  // },
  // {
  //   routeType: "private",
  //   path: "/kelompok/non-perhutanan-sosial/pengajuan-daftar-permohonan/edit/:id/member/edit/:memberId/:applicantId",
  //   element: generateMemberForm({ mode: PageType.NPS }),
  //   exact: true,
  //   sidebar: false,
  // },

  // ? PERMOHONAN PENCAIRAN
  // ! NPS
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/permohonan-pencairan",
    element: generatePermohonanPencairanComp({ mode: PageType.NPS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/permohonan-pencairan/:id/detail",
    element: generatePermohonanPencairanDetailComp({ mode: PageType.NPS }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/non-perhutanan-sosial/permohonan-pencairan/:id/detail/:idAssessment/assessment",
    element: generatePermohonanPencairanDetailAssessment({
      mode: PageType.NPS,
    }),
    exact: true,
    sidebar: false,
  },
  // ! PS
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/permohonan-pencairan",
    element: generatePermohonanPencairanComp({ mode: PageType.PS }),
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/permohonan-pencairan/:id/detail",
    element: generatePermohonanPencairanDetailComp({ mode: PageType.PS }),
    exact: true,
    sidebar: false,
  },
  {
    routeType: "private",
    path: "/kelompok/perhutanan-sosial/permohonan-pencairan/:id/detail/:idAssessment/assessment",
    element: generatePermohonanPencairanDetailAssessment({
      mode: PageType.PS,
    }),
    exact: true,
    sidebar: false,
  },
];
