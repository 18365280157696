/* eslint-disable react-hooks/exhaustive-deps */
import { InputForm, InputFormUpload } from "components";
import CardForm from "components/molecules/CardForm";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useLocation } from "react-router";
import { FieldLabel } from "../validation-schema";
import AtomDatePicker from "components/atoms/Datepicker";
import SelectGender from "components/organisms/inputs/SelectGender";
import FileInfoField from "components/atoms/FileInfoField";
import { useMutation } from "@tanstack/react-query";
import { postFileServices } from "services/fdb/akunKelompok";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { getFile } from "services/danaProgram/fileService";
import SelectReligion from "components/organisms/inputs/SelectReligion";

export const DataPasangan = ({ data }) => {
  const location = useLocation();
  const isView = location.pathname.includes("view");

  const [coupleCidFile, setCoupleCidFile] = useState();

  const methods = useFormContext();

  const cidCoupleField = useWatch({
    control: methods.control,
    name: "couple_identity_file",
  });

  const getDocsMutation = useMutation({
    mutationKey: ["get-detail-information-docs"],
    mutationFn: async ({ payload, type = "" }) => {
      const res = await getFile(payload);
      return { res: res.data.data, type: type };
    },
    onSuccess: ({ res, type }) => {
      if (type === "couple_identity_file") {
        methods.setValue(`couple_identity_file`, res.id);
        setCoupleCidFile(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
  });

  const postFile = useMutation({
    mutationFn: async ({ payload, type }) => {
      let formData = new FormData();
      formData.append("file", payload);
      const res = await postFileServices(formData);
      return { res: res.data.data, type };
    },
    onSuccess: ({ res, type }) => {
      enqueueSnackbar({
        message: "Data Berhasil Disimpan!",
        variant: "success",
      });
      if (type === "couple_identity_file") {
        methods.setValue(`couple_identity_file`, res.id);
        setCoupleCidFile(res);
      } else {
        window.open(res.url, "_blank");
      }
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onSettled: () => {},
  });

  useEffect(() => {
    if (cidCoupleField instanceof File) {
      postFile.mutate({
        payload: cidCoupleField,
        type: "couple_identity_file",
      });
    } else if (typeof cidCoupleField === "string" && cidCoupleField !== "") {
      getDocsMutation.mutate({
        payload: cidCoupleField,
        type: "couple_identity_file",
      });
    }
  }, [cidCoupleField]);

  return (
    <CardForm label="Data Pasangan">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputForm
          controllerName={`couple_identity_number`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={FieldLabel.CID}
          placeholder={FieldLabel.CID}
          asText={isView}
          maxLength={16}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />

        <InputForm
          controllerName={`couple_name`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.FULL_NAME}
          placeholder={FieldLabel.FULL_NAME}
          asText={isView}
        />

        <AtomDatePicker
          controllerName={`couple_date_of_birth`}
          className={`py-2 px-4 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          datepickerOptions={{
            maxDate: new Date(),
            showMonthDropdown: true,
            showYearDropdown: true,
            scrollableYearDropdown: true,
            dropdownMode: "select",
          }}
          datePickerCommonStyles="!border border-gray-200"
          customInputClassname="border border-gray-200 h-[42px]"
          label={FieldLabel.BIRTHDAY}
          placeholder={FieldLabel.BIRTHDAY}
          asText={isView}
        />

        <InputForm
          controllerName={`couple_place_of_birth`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.BIRTHPLACE}
          placeholder={FieldLabel.BIRTHPLACE}
          asText={isView}
        />

        {isView ? (
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">{FieldLabel.GENDER}</h5>
            <p>
              {data?.couple_gender
                ? data?.couple_gender === "f"
                  ? "Perempuan"
                  : "Laki-laki"
                : ""}
            </p>
          </div>
        ) : (
          <SelectGender
            controllerName={`couple_gender`}
            label={FieldLabel.GENDER}
            placeholder="Jenis Kelamin"
            wrapperClass={"col-span-1"}
            returnObject={false}
            menuPortalTarget={document.body}
            asText={isView}
          />
        )}

        <SelectReligion
          controllerName={`couple_religion`}
          wrapperClass={"col-span-1 gap-2"}
          label={FieldLabel.RELIGION}
          placeholder={FieldLabel.RELIGION}
          returnObject={false}
          asText={isView}
        />

        <InputForm
          controllerName={`couple_phone`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={"Nomor Telepon"}
          placeholder={"Nomor Telepon"}
          readOnly={isView}
          asText={isView}
          maxLength={14}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />

        <InputForm
          controllerName={`couple_job`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.COUPLE_JOB}
          placeholder={FieldLabel.COUPLE_JOB}
          readOnly={isView}
          asText={isView}
        />

        <div className={"col-span-2"}>
          <div className={"col-span-1 row-span-2"}>
            {cidCoupleField || isView ? (
              <>
                <label className="font-semibold text-sm my-1.5 block">
                  {FieldLabel.CID_IMG}
                </label>
                {coupleCidFile ? (
                  <FileInfoField
                    title={coupleCidFile ? coupleCidFile.name : ""}
                    desc={
                      coupleCidFile
                        ? `${coupleCidFile.size} KB`
                        : "Tidak ada file"
                    }
                    showIcon
                    showDownload={true}
                    // showClose={true}
                    showClose={!isView}
                    onCloseClick={() => {
                      methods.setValue(`couple_identity_file`, "");
                    }}
                    buttonLabel="Lihat Berkas"
                    // onDownloadClick={() => {
                    //   openDocs(`couple_identity_file`);
                    // }}
                  />
                ) : null}
              </>
            ) : (
              <InputFormUpload
                controllerName={`couple_identity_file`}
                label={FieldLabel.CID_IMG}
              />
            )}
          </div>
        </div>
      </div>
    </CardForm>
  );
};
