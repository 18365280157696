import * as yup from "yup";

export const FieldLabel = {
  GROUP_NAME: "Nama Kelompok",
  CID: "KTP",
  CID_IMG: "KTP",
  FULL_NAME: "Nama Lengkap Sesuai KTP",
  BIRTHPLACE: "Tempat Lahir",
  BIRTHDAY: "Tanggal Lahir",
  FAMILY_REG_NO: "No Kartu Keluarga",
  RELIGION: "Agama",
  FAMILY_REG_IMG: "Kartu Keluarga",
  MAIN_JOB: "Pekerjaan Utama",
  OTHER_JOB: "Pekerjaan/Usaha Lainnya",
  GENDER: "Jenis Kelamin Debitur",
  NPWP: "Nomor NPWP Debitur",
  PHONE: "Nomor Telepon Debitur",
  PROVINCE: "Provinsi",
  CITY: "Kota/Kabupaten",
  DISTRICT: "Kecamatan",
  SUBDISTRICT: "Kelurahan/Desa",
  RT: "RT",
  RW: "RW",
  ADDRESS: "Alamat",
  GEOTAG: "Geotag Location",
  LAT: "Latitude",
  LNG: "Longitude",
  LIVE_SINCE: "Berdomisili Di Alamat Diatas Sejak Tahun",
  ZIPCODE: "Kode Pos",
  EST_INCOME: "Perkiraan Pendapatan Perbulan",
  INCOME_CYCLE: "Siklus Pendapatan",
  REG_EXPENSE: "Pengeluaran Rutin Per Bulan",
  BIGGEST_EXPENSE: "Pengeluaran Terbesar",
  LARGEST_SPENDING: "Penggunaan Pengeluaran Terbesar",
  PROJECT_TYPE: "Jenis Usaha PS",
  PROJECT_COMMODITY: "Komoditas Usaha PS",
  PROJECT_AGE: "Lama Usaha",
  SOURCE: "Sumber Bahan Produksi/Jasa",
  CAPACITY: " Kapasitas Usaha/Produksi ",
  VALUE: "Nominal Angsuran",
  COLLATERAL_NAME: "Bentuk Jaminan",
  COLLATERAL_VALUE: "Nilai Jaminan",
  GOAL: "Tujuan Pemasaran",
  PARTNER: "Mitra Usaha (Jika Ada)",
  PLAN: "Rencana penggunaan modal pinjaman",
  EST_GROSS_EARN: "Perkiraan omzet/harga jual/pendapatan kotor",
  EST_COST: "Perkiraan Biaya Produksi / HPP / Modal Yang Telah Dikeluarkan",
  EST_NET_EARN: "Perkiraan pendapatan bersih perbulan",
  CYCLE: "Siklus Usaha Produksi",
  AMOUNT: "Nominal Pinjaman",
  AMOUNT_WORD: "Terbilang",
  DURATION: "Jangka Waktu",
  INSTALLMENT: "Nominal Angsuran",
  INSTALLMENT_WORD: "Terbilang",
  CREATED_AT: "Dibuat Pada Tanggal",
  CREATED_LOC: "Dibuat Pada (tempat)",
  COUPLE_JOB: "Pekerjaan Pasangan",
};

export const permohonanSchemaPS = yup.object({
  group_name: yup.string().trim().notRequired().label(FieldLabel.GROUP_NAME),
  identity_number: yup
    .string()
    .trim()
    // .test("cid", validationErrorMsg.mixed.default, isCitizenIdValid)
    .required()
    .min(16)
    .label(FieldLabel.CID),
  identity_file: yup.mixed().notRequired().label(FieldLabel.CID_IMG),
  name: yup.string().trim().required().label(FieldLabel.FULL_NAME),
  place_of_birth: yup.string().trim().required().label(FieldLabel.BIRTHPLACE),
  date_of_birth: yup.string().trim().required().label(FieldLabel.BIRTHDAY),
  religion: yup.string().trim().required().label(FieldLabel.RELIGION),
  gender: yup.string().trim().required().label(FieldLabel.GENDER),
  phone: yup.string().trim().required().label(FieldLabel.PHONE),
  family_card_number: yup
    .string()
    .trim()
    .notRequired()
    .label(FieldLabel.FAMILY_REG_NO),
  family_card_file: yup.mixed().notRequired().label(FieldLabel.FAMILY_REG_IMG),
  npwp: yup
    .string()
    .trim()
    .notRequired()
    .test("lengthIfFilled", "NPWP harus tepat 15 karakter", (val) => {
      if (typeof val !== "undefined" && val !== "") {
        return val.length >= 20;
      }

      return true;
    })
    .label(FieldLabel.NPWP),
  main_job: yup.string().trim().required().label(FieldLabel.MAIN_JOB),
  other_job: yup.string().trim().required().label(FieldLabel.OTHER_JOB),

  // ? TIDAK ADA FIELDNYA
  job_doc: yup.string().notRequired().nullable(),

  couple_identity_number: yup
    .string()
    .trim()
    // .test("cid", validationErrorMsg.mixed.default, isCitizenIdValid)
    .min(16)
    .notRequired()
    .label(FieldLabel.CID),
  couple_identity_file: yup.mixed().notRequired().label(FieldLabel.CID_IMG),
  couple_name: yup.string().trim().notRequired().label(FieldLabel.FULL_NAME),
  couple_place_of_birth: yup
    .string()
    .trim()
    .notRequired()
    .label(FieldLabel.BIRTHPLACE),
  couple_date_of_birth: yup
    .string()
    .trim()
    .notRequired()
    .label(FieldLabel.BIRTHDAY),
  couple_religion: yup.string().trim().notRequired().label(FieldLabel.RELIGION),
  couple_gender: yup.string().trim().notRequired().label(FieldLabel.GENDER),
  couple_phone: yup.string().trim().notRequired().label(FieldLabel.PHONE),
  couple_job: yup.string().trim().notRequired().label(FieldLabel.JOB),

  province_id: yup.mixed().required().label(FieldLabel.PROVINCE),
  city_id: yup.mixed().required().label(FieldLabel.CITY),
  district_id: yup.mixed().required().label(FieldLabel.DISTRICT),
  village_id: yup.mixed().required().label(FieldLabel.SUBDISTRICT),
  zip_code_id: yup.mixed().required().label(FieldLabel.ZIPCODE),
  address: yup.string().trim().required().label(FieldLabel.ADDRESS),
  rt: yup.string().required().label(FieldLabel.RT),
  rw: yup.string().required().label(FieldLabel.RW),
  same_address: yup.string().notRequired(),
  domicile_province_id: yup.mixed().required().label(FieldLabel.PROVINCE),
  domicile_city_id: yup.mixed().required().label(FieldLabel.CITY),
  domicile_district_id: yup.mixed().required().label(FieldLabel.DISTRICT),
  domicile_village_id: yup.mixed().required().label(FieldLabel.SUBDISTRICT),
  domicile_zip_code_id: yup.mixed().required().label(FieldLabel.ZIPCODE),
  domicile_address: yup.string().trim().required().label(FieldLabel.ADDRESS),
  domicile_rt: yup.string().required().label(FieldLabel.RT),
  domicile_rw: yup.string().required().label(FieldLabel.RW),
  domicile_since_year: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.LIVE_SINCE),
  latitude: yup.string().trim().required().label(FieldLabel.LAT),
  longitude: yup.string().trim().required().label(FieldLabel.LNG),

  routine_outcome_per_month: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.REG_EXPENSE),
  biggest_outcome: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.BIGGEST_EXPENSE),
  use_largest_outcome: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.LARGEST_SPENDING),

  // ? TIDAK ADA FIELD NYA
  salary_slip_file: yup.string().notRequired().nullable(),
  // created_at_place: yup.string(),

  business_type_id: yup
    .string()
    .trim()
    .notRequired()
    .label(FieldLabel.PROJECT_TYPE),

  business_commodity: yup
    .string()
    .trim()
    .notRequired()
    .label(FieldLabel.PROJECT_COMMODITY),

  // ! JOIN SINCE AND TYPE
  business_since: yup.string().trim().required().label(FieldLabel.PROJECT_AGE),
  business_since_type: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.PROJECT_AGE),
  // business_since: yup.string().trim().required().label(FieldLabel.PROJECT_AGE),

  business_material: yup.string().trim().notRequired().label(FieldLabel.SOURCE),
  business_capacity: yup
    .string()
    .trim()
    .notRequired()
    .label(FieldLabel.CAPACITY),
  business_economic_values: yup
    .string()
    .trim()
    .notRequired()
    .label(FieldLabel.VALUE),

  // ! BUSINESS PARTNER?
  business_partner: yup
    .array(
      yup.object({
        id: yup.string().notRequired().nullable(),
        value: yup.string().notRequired().label(FieldLabel.PARTNER),
      })
    )
    .notRequired(),

  business_target: yup.string().trim().required().label(FieldLabel.GOAL),
  business_panning_application: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.PLAN),
  business_revenue: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.EST_GROSS_EARN),
  business_cost_price: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.EST_COST),
  business_net_income: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.EST_NET_EARN),
  business_cycle: yup.string().trim().required().label(FieldLabel.CYCLE),
  business_cycle_unit: yup.string().trim().required().label(FieldLabel.CYCLE),

  // ! AMOUNT?
  amount: yup.string().trim().notRequired().label(FieldLabel.AMOUNT),

  business_loan_application: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.AMOUNT),

  // ? TIDAK ADA FIELDNYA
  business_loan_application_desc: yup.string(),

  business_loan_application_period: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.DURATION),
  business_loan_application_period_type: yup
    .string()
    .required()
    .label(FieldLabel.DURATION),
  business_loan_application_paid: yup
    .string()
    .trim()
    .required()
    .label(FieldLabel.INSTALLMENT),
  business_loan_application_paid_desc: yup
    .string()
    .trim()
    .notRequired()
    .label(FieldLabel.INSTALLMENT),

  business_colleterals: yup.array(
    yup.object({
      id: yup.string().notRequired().nullable(),
      collateral_type: yup
        .string()
        .notRequired()
        .nullable()
        .label(FieldLabel.COLLATERAL_NAME),
      collateral_fee: yup
        .number()
        .transform((val) => (isNaN(val) ? null : val))
        .nullable()
        .label(FieldLabel.COLLATERAL_VALUE),
      collateral_file: yup.string(),
    })
  ),
  income_info: yup.array().of(
    yup.object().shape({
      id: yup.string().notRequired(),
      type: yup.string().notRequired(),
      income: yup.string().required().label("Pendapatan"),
      income_cycle: yup.string().notRequired().label("Siklus Pendapatan"),
      income_cycle_unit: yup.string().notRequired().label("Siklus Pendapatan"),
    })
  ),

  agree: yup
    .bool()
    .required("Anda harus menyetujui pernyataan ini.")
    .oneOf([true], "Anda harus menyetujui pernyataan ini.")
    .default(false),
  created_at: yup.string().label(FieldLabel.CREATED_DATE),
  created_at_place: yup.string().required("Tempat dibuat harus diisi"),
});
