import React, { useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { BottomFormAction, Button, InputForm } from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import CardForm from "components/molecules/CardForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiAddLine, RiDeleteBin6Line, RiQuestionLine } from "react-icons/ri";

const Kompetensi = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const navigate = useNavigate();

  const validationSchema = useMemo(() => {
    return yup.object({
      competences: yup
        .array()
        .of(
          yup.object().shape({
            competency: yup.string().required("Bidang kompetensi wajib diisi"),
            desc: yup.string().required("Deskripsi kompetensi wajib diisi"),
          })
        )
        .min(5),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    console.log(errors, isValid);
  }, []);

  const { setValue } = methods;

  useEffect(() => {
    methods.resetField("competences");
    if (draftData?.length > 0) {
      if (draftData?.length < 5) {
        for (let index = 0; index < 5; index++) {
          if (draftData[index]) {
            appendcompetences(draftData[index]);
          } else {
            appendcompetences({
              competency: "",
              desc: "",
            });
          }
        }
      } else {
        draftData.forEach((data, index) => {
          appendcompetences(data);
        });
      }
      methods.trigger();
    } else {
      for (let i = 0; i < 5; i++) {
        appendcompetences({
          competency: "",
          desc: "",
        });
      }
    }
  }, [draftData, setValue, methods]);

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const {
    fields: competencesFields,
    append: appendcompetences,
    remove: removecompetences,
  } = useFieldArray({
    control,
    name: "competences",
  });

  return (
    <>
      <FormProvider {...methods}>
        <CardForm label={"Daftar Rekanan/Mitra"}>
          <>
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center p-4 border rounded-lg">
                <RiQuestionLine />
                Apa saja kompetensi utama yang lembaga/ institusi/ perusahaan
                Anda miliki? (minimal 5 kompetensi)
              </div>
            </div>

            <>
              {competencesFields?.length > 0
                ? competencesFields?.map((data, index) => {
                    return (
                      <>
                        <CardForm
                          label={`Kompetensi ${index + 1}`}
                          buttonCollapse={true}
                          childerForm={true}
                        >
                          <div
                            className="flex gap-4 items-end border-t pt-2"
                            key={data.id}
                          >
                            <div className="w-full">
                              <div className="flex gap-4">
                                <div className="flex-1 pt-2">
                                  <InputForm
                                    controllerName={`competences.${index}.competency`}
                                    className={`py-2 px-4 mt-2 border w-full rounded-md ${
                                      errors.nama_lemtara
                                        ? "border-[#F04438]"
                                        : "border-green"
                                    }`}
                                    label={"Bidang Kompetensi"}
                                    placeholder={"Bidang Kompetensi"}
                                    required={true}
                                  />
                                </div>
                                <div className="flex-1 pt-2">
                                  <InputForm
                                    controllerName={`competences.${index}.desc`}
                                    className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                                    label={"Keterangan"}
                                    textArea={true}
                                    required={true}
                                  />
                                </div>
                              </div>
                            </div>
                            {index > 5 && (
                              <button
                                className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                                onClick={() => removecompetences(index)}
                              >
                                <RiDeleteBin6Line />
                              </button>
                            )}
                          </div>
                          <div className="border"></div>
                        </CardForm>
                      </>
                    );
                  })
                : ""}
              <div className="flex flex-row-reverse my-5 mx-4 ">
                <Button
                  className="p-4 border rounded-lg"
                  label={
                    <div className="flex items-center gap-2 font-[14px]">
                      <RiAddLine />
                      Tambah Data Lain
                    </div>
                  }
                  onClick={() => {
                    appendcompetences({
                      name: "",
                      desc: "",
                    });
                  }}
                />
              </div>
            </>
          </>
        </CardForm>

        <BottomFormAction
          lastStep={true}
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            console.log(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default Kompetensi;
