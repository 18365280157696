import { InputForm, Button, ConfirmationModal } from "components";
import CardForm from "components/molecules/CardForm";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import {
  useFormContext,
  useWatch,
  useFieldArray,
  useForm,
} from "react-hook-form";
import * as Yup from "yup";
import AtomDatePicker from "../../../../../components/atoms/Datepicker";
import RichEditorForm from "../../../../../components/molecules/RichEditorForm";
import InputKTP from "../../../../../components/organisms/inputs/InputKTP";
import InputNumeric from "../../../../../components/organisms/inputs/InputNumeric";
import InputPhone from "../../../../../components/organisms/inputs/InputPhone";
import SelectCity from "../../../../../components/organisms/inputs/SelectCity";
import SelectDistrict from "../../../../../components/organisms/inputs/SelectDistrict";
import SelectFungsiKawasan from "../../../../../components/organisms/inputs/SelectFungsiKawasan";
import SelectProvince from "../../../../../components/organisms/inputs/SelectProvince";
import SelectSubdistrict from "../../../../../components/organisms/inputs/SelectSubdistrict";
import {
  isCitizenIdValid,
  isRTEEmpty,
} from "../../../../../helpers/validation";
import { validationErrorMsg } from "../../../../../locale/yup_id";
import { findIndex, map, method } from "lodash";
import { BiTrash } from "react-icons/bi";
import { RiAddLine } from "react-icons/ri";
import AtomSelect from "../../../../../components/atoms/Select";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";
import { AuthContext, useAuthContext } from "providers/authProvider";
import store from "app/store";
import { useMutation } from "@tanstack/react-query";
import {
  deleteActivity,
  deleteFinanceBusiness,
  deletePartner,
} from "services/fdb/perhutananSosial/registerForm";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { createPortal } from "react-dom";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export const FieldLabel = {
  GROUP_NAME: "Nama KPS",
  KUPS_NAME: "Nama KUPS (Jika Ada)",
  SK_NUMBER: "Dasar persetujuan/penetapan/perjanjian akses kawasan",
  KUPS_NUMBER: "Dasar Pembentukan / No Izin KUPS (Jika Ada)",
  FOUNDING_DATE: "Tanggal dan tahun berdiri",
  SOCIAL_AREA_FUNCTION_ID: "Fungsi Kawasan & Skema PS",
  PROVINCE: "Provinsi",
  CITY: "Kota/Kabupaten",
  DISTRICT: "Kecamatan",
  VILLAGE: "Kelurahan/Desa",
  ADDRESS: "Alamat",
  ZIPCODE: "Zip Code",
  LEADER_NAME: "Nama Ketua KPS",
  LEADER_PHONE: "Nomer Telepon Ketua KPS",
  LEADER_IDENTITY: "NIK KTP Ketua KPS",
  LEADER_GENDER: "Jenis Kelamin Ketua",
  SECRETARY_NAME: "Nama Sekertaris KPS",
  SECRETARY_PHONE: "Nomer Telepon Sekertaris KPS",
  SECRETARY_GENDER: "Jenis Kelamin Sekretaris",
  TREASURER_NAME: "Nama Bendahara KPS",
  TREASURER_PHONE: "Nomer Telepon Bendahara KPS",
  TREASURER_GENDER: "Jenis Kelamin Bendahara",
  COMPANION_NAME: "Nama Pendamping PS",
  COMPANION_AFFILIATE: "Afiliasi Pendamping PS",
  COMPANION_PHONE: "Nomor Telepon Pendamping PS",
  COMPANION_GENDER: "Jenis Kelamin Pendamping",
  END_DATE: "Batas Akhir Kepengurusan",
  PAST_ACTIVITIES: "Kegiatan - Kegiatan Kelompok yang telah dilaksanakan",
  MEMBERSHIP_COUNT: "Jumlah Anggota KPS/KUPS yang Mengajukan Permohonan",
  ANDIL_GARAPAN: "Jumlah Andil Garapan",
  TOTAL_AREA: "Total Luas Lahan",
  MEMBERSHIP_COUNT_ALL:
    "Jumlah Anggota KPS/KUPS (Termasuk yang tidak mengajukan pinjaman)",
  ANDIL_GARAPAN_ALL: "Jumlah Andil Garapan",
  TOTAL_AREA_ALL: "Total Luas Lahan",
  PARTNER: "Mitra Usaha Kelompok (Jika Ada)",
  GENERAL_OVERVIEW:
    "Gambaran umum jenis - jenis usaha anggota kelompok yang dibiayai",
};

const AkunKelompokPSFormInfo = React.forwardRef(
  (
    {
      formPrefix,
      onBack,
      onSubmit,
      onSaveDraft,
      mode,
      activityCategory,
      companionAffiliates,
      socialArea,
      bussinessCategory,
    },
    ref
  ) => {
    const prefix = formPrefix ? `${formPrefix}.` : "";
    const [isEdit, isAdd, isView] = ["edit", "add", "view"].map(
      (e) => e === mode
    );

    const methods = useFormContext();

    const provinceId = useWatch({
      control: methods.control,
      name: `province_id`,
    });
    const cityId = useWatch({
      control: methods.control,
      name: `city_id`,
    });
    const districtId = useWatch({
      control: methods.control,
      name: `district_id`,
    });
    const villageId = useWatch({
      control: methods.control,
      name: `village_id`,
    });

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    return (
      <>
        <CardForm label="General Informasi">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputForm
              controllerName={`group_name`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={FieldLabel.GROUP_NAME}
              placeholder={FieldLabel.GROUP_NAME}
              asText={isView}
              disabled
            />
            <InputForm
              controllerName={`sk_number`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={FieldLabel.SK_NUMBER}
              placeholder={FieldLabel.SK_NUMBER}
              asText={isView}
              disabled
            />
            <InputForm
              controllerName={`kups_name`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={FieldLabel.KUPS_NAME}
              placeholder={FieldLabel.KUPS_NAME}
              asText={isView}
            />
            <InputForm
              controllerName={`kups_number`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={FieldLabel.KUPS_NUMBER}
              placeholder={FieldLabel.KUPS_NUMBER}
              asText={isView}
            />
            <AtomDatePicker
              controllerName={`founding_date`}
              className={`py-2 px-4 border w-full rounded-md`}
              label={FieldLabel.FOUNDING_DATE}
              placeholder={FieldLabel.FOUNDING_DATE}
              datepickerOptions={{
                maxDate: dayjs().endOf("day").toDate(),
              }}
              format={"yyyy-MM-dd"}
              showErrorLabel
              asText={isView}
            />
            <AtomSelect
              controllerName={`social_area_function_id`}
              label={FieldLabel.SOCIAL_AREA_FUNCTION_ID}
              placeholder={FieldLabel.SOCIAL_AREA_FUNCTION_ID}
              className={"mt-2"}
              options={socialArea}
              returnObject={false}
            />
            <SelectFDBProvince
              controllerName={`province_id`}
              label={FieldLabel.PROVINCE}
              placeholder={FieldLabel.PROVINCE}
              returnObject={false}
              asText={isView}
            />
            <SelectFDBCity
              controllerName={`city_id`}
              label={FieldLabel.CITY}
              placeholder={FieldLabel.CITY}
              returnObject={false}
              provinceId={provinceId}
              asText={isView}
            />
          </div>
          <div className="grid grid-cols-3 gap-3">
            <SelectFDBDistrict
              controllerName={`district_id`}
              label={FieldLabel.DISTRICT}
              placeholder={FieldLabel.DISTRICT}
              returnObject={false}
              cityId={cityId}
              asText={isView}
            />
            <SelectFDBVillage
              controllerName={`village_id`}
              label={FieldLabel.VILLAGE}
              asText={isView}
              returnObject={false}
              districtId={districtId}
            />
            <SelectFDBZipCode
              controllerName={`zip_code_id`}
              label={FieldLabel.ZIPCODE}
              placeholder={FieldLabel.ZIPCODE}
              returnObject={false}
              villageId={villageId}
              asText={isView}
            />
          </div>
          <InputForm
            controllerName={`address`}
            className={`py-2 px-4 mt-2 border w-full rounded-md`}
            label={FieldLabel.ADDRESS}
            placeholder={FieldLabel.ADDRESS}
            textArea
            asText={isView}
          />
        </CardForm>

        <CardForm label="Informasi Kelompok">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className={"col-span-3"}>
              <InputForm
                controllerName={`leader_name`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.LEADER_NAME}
                placeholder={FieldLabel.LEADER_NAME}
                asText={isView}
              />
            </div>
            <div className={"col-span-3"}>
              <InputForm
                controllerName={`leader_phone`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.LEADER_PHONE}
                placeholder={FieldLabel.LEADER_PHONE}
                asText={isView}
              />
            </div>
            <div className={"col-span-3"}>
              <InputForm
                controllerName={`leader_identity`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.LEADER_IDENTITY}
                placeholder={FieldLabel.LEADER_IDENTITY}
                asText={isView}
              />
            </div>
            <div className={"col-span-3"}>
              <AtomSelect
                controllerName={`leader_gender`}
                className={"mt-2"}
                label={FieldLabel.LEADER_GENDER}
                placeholder={FieldLabel.LEADER_GENDER}
                asText={isView}
                returnObject={false}
                options={[
                  {
                    value: "m",
                    label: "Male",
                  },
                  {
                    value: "f",
                    label: "Female",
                  },
                ]}
              />
            </div>
            <div className={"col-span-4"}>
              <InputForm
                controllerName={`secretary_name`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.SECRETARY_NAME}
                placeholder={FieldLabel.SECRETARY_NAME}
                asText={isView}
              />
            </div>
            <div className={"col-span-4"}>
              <InputForm
                controllerName={`secretary_phone`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.SECRETARY_PHONE}
                placeholder={FieldLabel.SECRETARY_PHONE}
                asText={isView}
              />
            </div>
            <div className="col-span-4">
              <AtomSelect
                controllerName={`secretary_gender`}
                label={FieldLabel.LEADER_GENDER}
                className={"mt-2"}
                placeholder={FieldLabel.LEADER_GENDER}
                asText={isView}
                returnObject={false}
                options={[
                  {
                    value: "m",
                    label: "Male",
                  },
                  {
                    value: "f",
                    label: "Female",
                  },
                ]}
              />
            </div>
            <div className={"col-span-4"}>
              <InputForm
                controllerName={`treasurer_name`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={FieldLabel.TREASURER_NAME}
                placeholder={FieldLabel.TREASURER_NAME}
                asText={isView}
              />
            </div>
            <div className={"col-span-4"}>
              <InputForm
                controllerName={`treasurer_phone`}
                label={FieldLabel.TREASURER_PHONE}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                placeholder={FieldLabel.TREASURER_PHONE}
                asText={isView}
              />
            </div>
            <div className="col-span-4">
              <AtomSelect
                controllerName={`treasurer_gender`}
                className={"mt-2"}
                label={FieldLabel.TREASURER_GENDER}
                placeholder={FieldLabel.TREASURER_GENDER}
                asText={isView}
                returnObject={false}
                options={[
                  {
                    value: "m",
                    label: "Male",
                  },
                  {
                    value: "f",
                    label: "Female",
                  },
                ]}
              />
            </div>
            <div className={"col-span-3"}>
              <InputForm
                controllerName={`companion_name`}
                className={`mt-2`}
                label={FieldLabel.COMPANION_NAME}
                placeholder={FieldLabel.COMPANION_NAME}
                asText={isView}
              />
            </div>
            <div className={"col-span-3"}>
              <AtomSelect
                controllerName={`companion_affiliate_id`}
                className={`mt-2`}
                label={FieldLabel.COMPANION_AFFILIATE}
                placeholder={FieldLabel.COMPANION_AFFILIATE}
                returnObject={false}
                options={companionAffiliates}
              />
            </div>
            <div className={"col-span-3"}>
              <InputForm
                controllerName={`companion_phone`}
                label={FieldLabel.COMPANION_PHONE}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                placeholder={FieldLabel.COMPANION_PHONE}
                asText={isView}
              />
            </div>
            <div className="col-span-3">
              <AtomSelect
                controllerName={`companion_gender`}
                label={FieldLabel.COMPANION_GENDER}
                placeholder={FieldLabel.COMPANION_GENDER}
                asText={isView}
                returnObject={false}
                className={"mt-2"}
                options={[
                  {
                    value: "m",
                    label: "Male",
                  },
                  {
                    value: "f",
                    label: "Female",
                  },
                ]}
              />
            </div>
            <div className={"col-span-12"}>
              <AtomDatePicker
                controllerName={`end_date_management`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={FieldLabel.END_DATE}
                placeholder={FieldLabel.END_DATE}
                showErrorLabel
                format={"yyyy-MM-dd"}
                asText={isView}
              />
            </div>
            <div className={"col-span-12"}>
              <InputNumeric
                controllerName={`total_member_not_apply`}
                label={FieldLabel.MEMBERSHIP_COUNT_ALL}
                placeholder={0}
                asText={isView}
              />
            </div>
            <div className={"col-span-6"}>
              <InputNumeric
                controllerName={`total_member_apply`}
                label={FieldLabel.MEMBERSHIP_COUNT}
                placeholder={0}
                asText={isView}
              />
            </div>
            <div className={"col-span-3"}>
              <InputNumeric
                controllerName={`total_member_apply_persil`}
                label={FieldLabel.ANDIL_GARAPAN}
                placeholder={0}
                asText={isView}
              />
            </div>
            <div className={"col-span-3"}>
              <InputNumeric
                controllerName={`total_member_apply_area`}
                label={FieldLabel.TOTAL_AREA}
                placeholder={0}
                asText={isView}
              />
            </div>
            <div className={"col-span-12"}>
              <p className="text-lg text-primary-700 font-semibold">
                Kegiatan - Kegiatan Kelompok yang telah Dilaksanakan
              </p>
              <FormDataKegiatanDilaksanakan
                activityCategory={activityCategory}
              />
            </div>
            <div className={"col-span-12"}>
              <FormDataMitraUsaha activityCategory={activityCategory} />
            </div>
            <div className={"col-span-12"}>
              <p className="text-lg text-primary-700 font-semibold">
                Gambaran Umum Jenis - Jenis Usaha Anggota Kelompok yang Dibiayai
              </p>
              <FormGambaranUmumDibiayai bussinessCategory={bussinessCategory} />
            </div>
          </div>
        </CardForm>
      </>
    );
  }
);
const FormDataMitraUsaha = ({ activityCategory }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "partners",
    keyName: "_id",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedKey, setSelectedKey] = useState("");

  const userId = useSelector((state) => state?.oidc?.oidc_user?.access_token);

  const onClickDelete = (id, key) => {
    if (id) {
      setConfirmModal(true);
      setSelectedId(id);
      setSelectedKey(key);
    } else {
      remove(key);
    }
  };

  const onSubmitDelete = () => {
    submitDelete.mutate();
  };

  const submitDelete = useMutation({
    mutationFn: (payload) => {
      return deletePartner(userId, selectedId);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Dihapus!",
        variant: "success",
      });

      setSelectedId("");
      setConfirmModal(false);
      remove(selectedKey);

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });
  return (
    <div>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmitDelete}
          className="space-y-2 mb-4"
          useGraphic={false}
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menghapus data mitra ini?
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {map(fields, (field, key) => (
        <div key={field._id} className="my-4 pb-8 border-b flex space-x-4">
          <InputForm
            controllerName={`partners.${key}.name`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={FieldLabel.PARTNER}
          />
          {key > 0 && (
            <div className="flex items-end">
              <Button
                type="button"
                onClick={() => {
                  onClickDelete(field.id, key);
                }}
                className="p-3 rounded-lg bg-error-600 flex-none"
                label={<BiTrash className="text-white" />}
              />
            </div>
          )}
        </div>
      ))}
      <div className="flex items-center justify-end pt-2">
        <Button
          type="button"
          className="p-4 border rounded-lg bg-white"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah Mitra Usaha Kelompok
            </div>
          }
          onClick={() => {
            append({
              name: "",
            });
          }}
        />
      </div>
    </div>
  );
};

const FormDataKegiatanDilaksanakan = ({ activityCategory }) => {
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "activities",
    keyName: "_id",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedKey, setSelectedKey] = useState("");

  const userId = useSelector((state) => state?.oidc?.oidc_user?.access_token);

  const onClickDelete = (id, key) => {
    if (id) {
      setConfirmModal(true);
      setSelectedId(id);
      setSelectedKey(key);
    } else {
      remove(key);
    }
  };

  const onSubmitDelete = () => {
    submitDelete.mutate();
  };

  const submitDelete = useMutation({
    mutationFn: (payload) => {
      return deleteActivity(userId, selectedId);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Dihapus!",
        variant: "success",
      });

      setSelectedId("");
      setConfirmModal(false);
      remove(selectedKey);

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });
  return (
    <div>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmitDelete}
          useGraphic={false}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menghapus data aktivitas ini?
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {map(fields, (field, key) => (
        <div key={field._id} className="my-4 pb-8 border-b flex space-x-4">
          <AtomSelect
            controllerName={`activities.${key}.activity_category_id`}
            options={activityCategory}
            className="basic-single mt-2"
            label={"Kegiatan"}
            returnObject={false}
          />
          <InputForm
            controllerName={`activities.${key}.description`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Deskripsi"}
            placeholder={"Deskripsi"}
            textArea={true}
          />
          {key > 0 && (
            <div className="flex items-end">
              <Button
                type="button"
                onClick={() => {
                  onClickDelete(field.id, key);
                }}
                className="p-3 rounded-lg bg-error-600 flex-none"
                label={<BiTrash className="text-white" />}
              />
            </div>
          )}
        </div>
      ))}
      <div className="flex items-center justify-end pt-2">
        <Button
          type="button"
          className="p-4 border rounded-lg bg-white"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah Kegiatan Kelompok
            </div>
          }
          onClick={() => {
            append({
              activity_category_id: "",
              description: "",
            });
          }}
        />
      </div>
    </div>
  );
};

const FormGambaranUmumDibiayai = ({ bussinessCategory }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "finance_businesses",
    keyName: "_id",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedKey, setSelectedKey] = useState("");

  const userId = useSelector((state) => state?.oidc?.oidc_user?.access_token);

  const onClickDelete = (id, key) => {
    if (id) {
      setConfirmModal(true);
      setSelectedId(id);
      setSelectedKey(key);
    } else {
      remove(key);
    }
  };

  const onSubmitDelete = () => {
    submitDelete.mutate();
  };

  const submitDelete = useMutation({
    mutationFn: (payload) => {
      return deleteFinanceBusiness(userId, selectedId);
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: "Data Berhasil Dihapus!",
        variant: "success",
      });

      setSelectedId("");
      setConfirmModal(false);
      remove(selectedKey);

      window.scrollTo({ behavior: "smooth", top: 0 });
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });
  return (
    <div>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={onSubmitDelete}
          useGraphic={false}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan menghapus data finance ini?
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {map(fields, (field, key) => (
        <div key={field._id} className="my-4 pb-8 border-b flex space-x-4">
          <AtomSelect
            controllerName={`finance_businesses.${key}.finance_business_category_id`}
            options={bussinessCategory}
            className="basic-single mt-2"
            label={"Kegiatan"}
            returnObject={false}
          />
          <InputForm
            controllerName={`finance_businesses.${key}.description`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Deskripsi"}
            placeholder={"Deskripsi"}
            textArea={true}
          />
          {key > 0 && (
            <div className="flex items-end">
              <Button
                type="button"
                onClick={() => onClickDelete(field.id, key)}
                className="p-3 rounded-lg bg-error-600 flex-none"
                label={<BiTrash className="text-white" />}
              />
            </div>
          )}
        </div>
      ))}
      <div className="flex items-center justify-end pt-2">
        <Button
          type="button"
          className="p-4 border rounded-lg bg-white"
          label={
            <div className="flex items-center gap-2 font-[14px]">
              <RiAddLine />
              Tambah Gambaran Umum
            </div>
          }
          onClick={() => {
            append({
              finance_business_category_id: "",
              description: "",
            });
          }}
        />
      </div>
    </div>
  );
};

export default AkunKelompokPSFormInfo;
