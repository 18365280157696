import React from "react";

const Header = ({ text = "", description = "" ,className = "", ...props }) => {
  return (
    <div className="flex flex-col">
        <span className="font-semibold text-[24px] text-[#101828]">
            {text}
        </span>
        <span className="font-normal text-[14px] text-[#667085]">
            {description}
        </span>
    </div>
  );
};

export default Header;
