import { Fragment } from "react";
import { useNavigate } from "react-router";

const { RiHome6Line, RiArrowRightSLine } = require("react-icons/ri");

const BreadCrumbs = ({ routes }) => {
  const navigate = useNavigate();

  return (
    <div className="flex gap-2 items-center">
      <button onClick={() => navigate("/penilaian-lemtara")}>
        <RiHome6Line className="text-[#667085]" />
      </button>
      {routes.length > 0 &&
        routes.map((data, index) => {
          return (
            <Fragment key={index}>
              <RiArrowRightSLine className="text-[#D0D5DD]" />
              <button
                onClick={() => navigate(`${data.path}`)}
                className={`
                                ${
                                  index === routes.length - 1
                                    ? "text-primary-700 font-semibold"
                                    : "text-gray-600"
                                }
                                ${
                                  index === routes.length - 1
                                    ? "bg-[#EEFFF4]"
                                    : ""
                                }
                                text-sm  px-2 py-1 rounded-md`}
              >
                {data.label}
              </button>
            </Fragment>
          );
        })}
    </div>
  );
};

export default BreadCrumbs;
