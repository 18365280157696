import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

export const getAwpList = (params) => {
  return axiosInstance.get(`${config_daprog.BASE_URL}/v1/cms/awp/list`, {
    params,
  });
};

export const getAwpDetail = (id) => {
  return axiosInstance.get(`${config_daprog.BASE_URL}/v1/cms/awp`, {
    params: {
      id,
    },
  });
};
