import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getCityDDL } from "../../../../services/master";
import AtomSelect from "../../../atoms/Select";

const SelectCity = React.forwardRef((props, ref) => {
  const { className, provinceId, ...restProps } = props;
  
  const [options, setOptions] = useState([]);
  
  const { data } = useQuery({
    queryKey: ["city-ddl", provinceId],
    queryFn: async () => {
      // TODO: Adjust logic once actual api exist
      const res = await getCityDDL(provinceId);
      
      return res?.data?.data || [];
    },
    retry: 1
  });
  
  useEffect(() => {
    setOptions(data?.map((entry) => {
      return {
        value: entry?.id,
        label: entry?.name
      };
    }));
  }, [data]);
  
  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      {...restProps}
    />
  );
});

export default SelectCity;