import React, { useMemo, useCallback } from "react";
import _ from "lodash";
import {
  RiUploadCloud2Line,
  RiComputerLine,
  RiBankLine,
  RiCoinsLine,
  RiEdit2Line,
} from "react-icons/ri";
import { useNavigate } from "react-router";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import AlertBlankData from "components/molecules/Alert";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getChannelingDirectProfile,
  getChannelingDirectInstitutional,
  getChannelingDirectFinance,
  submitChannelingDirect,
} from "services";
import { useAuth } from "hooks/auth";
import Button from "components/atoms/Button/Button";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import Kelembagaan from "../../../assets/illustrations/Kelembagaan.png";
import KeuanganGeneral from "../../../assets/illustrations/Keuangan General.png";
import ProfileUmum from "../../../assets/illustrations/Profil Umum.png";
import Alert from "components/atoms/Alert";
import Tag from "components/atoms/Tag";
const LembagaPenyalur = (props) => {
  const { type } = props;
  // console.log(type, 'agung type');
  const navigate = useNavigate();

  const { id } = useAuth();

  const { data: profileData } = useQuery({
    queryKey: ["channeling-direct-profile-summary", id],
    queryFn: async () => {
      return (await getChannelingDirectProfile(id)).data?.data;
    },
  });

  const { data: institutionalData } = useQuery({
    queryKey: ["channeling-direct-institutional-summary", id],
    queryFn: async () => {
      return (await getChannelingDirectInstitutional(id)).data?.data;
    },
  });

  const { data: financeData } = useQuery({
    queryKey: ["channeling-direct-finance-summary", id],
    queryFn: async () => {
      return (await getChannelingDirectFinance(id)).data?.data;
    },
  });

  const moduleItems = useMemo(() => {
    return [
      {
        icon: <RiComputerLine className="text-white" />,
        label: "profile umum",
        path: `/lembaga-penyalur/${type}/profile-umum/`,
        background: "bg-orangedark-600",
        backgroundOuter: "bg-orangedark-100",
        bgIlustration: ProfileUmum,
        isDraft: false,
        status: profileData?.status,
        isSubmitted: false,
        isSubmittable: profileData?.is_submittable,
      },
      // {
      //   icon: <RiBankLine className="text-[#1570EF]" />,
      //   label: "Kelembagaan",
      //   path: `/lembaga-penyalur/${type}/kelembagaan/`,
      //   background: "bg-[#D1E9FF]",
      //   backgroundOuter: "bg-[#EFF8FF]",
      //   bgIlustration: Kelembagaan,
      //   isDraft: true,
      //   status: institutionalData?.status,
      //   isSubmitted: false,
      //   isSubmittable: institutionalData?.is_submittable,
      // },
      // {
      //   icon: <RiCoinsLine className="text-[#099250]" />,
      //   label: "Keuangan",
      //   path: `/lembaga-penyalur/${type}/keuangan/`,
      //   background: "bg-[#D3F8DF]",
      //   backgroundOuter: "bg-[#EDFCF2]",
      //   bgIlustration: KeuanganGeneral,
      //   isDraft: true,
      //   status: null,
      //   isSubmitted: true,
      //   isSubmittable: financeData?.every((item) => item.is_submittable),
      // },
    ];
  }, [profileData, institutionalData, financeData, type]);

  const isSubmittable = useMemo(() => {
    return moduleItems.every((item) => item.isSubmittable);
  }, [moduleItems]);

  // const isSubmitted = useMemo(() => {
  //   return moduleItems.every((item) => item.isSubmitted);
  // }, [moduleItems]);

  const onError = useCallback((error) => {
    enqueueSnackbar(getErrorMessage(error), {
      variant: "error",
    });
  }, []);

  const onSuccess = useCallback(() => {
    enqueueSnackbar("Data Berhasil Disimpan!", {
      variant: "success",
    });
  }, []);

  const { mutate } = useMutation({
    mutationFn: () => {
      const data = new FormData();
      data.append("user_id", id);
      return submitChannelingDirect(data);
    },
    onSuccess,
    onError,
  });

  const submitProfile = useCallback(() => {
    mutate();
  }, [mutate]);

  // console.log({
  //   profileData,
  //   institutionalData,
  //   financeData,
  //   moduleItems,
  //   isSubmittable,
  //   isSubmitted,
  // });

  return (
    <div className="w-full space-y-4">
      <div className="flex flex-col gap-y-6">
        {/* Header Section */}
        <div className="bg-[#FFFFFF] rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Formulir Pendaftaran",
                  path: `/lembaga-penyalur/${type}`,
                },
                {
                  label: "Registrasi Prospecting Lembaga Penyalur",
                  path: `/lembaga-penyalur/${type}`,
                },
              ]}
            />
            <div className="mt-6 flex flex-row">
              <div className="flex-1">
                <div className="flex flex-col">
                  <span className="font-semibold text-[24px] text-[#101828]">
                    Registrasi Prospecting Lembaga Penyalur
                  </span>
                  <span className="font-normal text-[14px] text-[#667085]">
                    Lengkapi Data Registrasi Prospecting Lembaga Penyalur.
                  </span>
                </div>
              </div>
              <div className="flex-1 text-right">
                <Button
                  type="button"
                  disabled={!isSubmittable}
                  color="orange"
                  label={
                    <>
                      <RiUploadCloud2Line />
                      <span className="font-semibold font-[14px]">
                        Upload Semua Persyaratan
                      </span>
                    </>
                  }
                  onClick={submitProfile}
                />
              </div>
            </div>
          </div>
        </div>

        <AlertBlankData
          title={"Harap Kembali Upload Persyaratan"}
          body={
            "Setelah proses mengubah data yang ditolak harap kembali mengupload persyaratan."
          }
        />

        <div className="flex flex-wrap gap-4">
          {_.map(
            moduleItems,
            (
              {
                icon,
                label,
                path,
                background,
                backgroundOuter,
                isSubmittable,
                bgIlustration,
                isDraft,
                isSubmitted,
              },
              index
            ) => (
              <div
                className="flex flex-col items-center w-[285px] gap-4 bg-white shadow-xl rounded-xl relative"
                key={index}
              >
                <div className="p-4 absolute -top-10">
                  <div className="flex items-center gap-2">
                    <div className={`${backgroundOuter} p-3 rounded-full`}>
                      <div className={`${background} p-3 rounded-full`}>
                        {icon}
                      </div>
                    </div>
                  </div>
                </div>
                <span className="mt-12 font-semibold">
                  {_.upperFirst(label)}
                </span>
                <img src={bgIlustration} alt="general-information-img" />

                <div className="px-4 space-y-4">
                  {isDraft == false && isSubmitted == false ? (
                    <>
                      <Alert
                        theme={"warning"}
                        title={"Lengkapi Dokumen"}
                        description={
                          "Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan"
                        }
                      />
                    </>
                  ) : isDraft == true && isSubmitted == false ? (
                    <>
                      <Alert
                        theme={"warning"}
                        title={"Lengkapi Dokumen"}
                        description={
                          "Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan"
                        }
                      />
                      <Tag label={"Data Belum Lengkap"} theme={"warning"} />
                    </>
                  ) : (
                    <>
                      <Alert
                        theme={"success"}
                        title={"Data Sudah Lengkap"}
                        description={"Anda sudah melengkapi seluruh data"}
                      />
                    </>
                  )}
                </div>

                <div className="p-4 w-full border-t">
                  <div className="text-right">
                    {isDraft == false && isSubmitted == false ? (
                      <button
                        onClick={() => navigate(`${path}`)}
                        className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                      >
                        Lengkapi
                      </button>
                    ) : undefined}

                    {isDraft == true && isSubmitted == true ? (
                      <button
                        onClick={() => navigate(`${path}`)}
                        className="bg-[#00BD52] text-white py-2 px-4 rounded-lg w-full font-semibold text-[12px]"
                      >
                        Ubah
                      </button>
                    ) : undefined}

                    {isDraft == true && isSubmitted == false ? (
                      <button
                        onClick={() => navigate(`${path}`)}
                        className="bg-white text-gray-700 border-[1px] border-gray-700 py-2 px-4 rounded-lg  w-full font-semibold text-[12px]"
                      >
                        <RiEdit2Line className="inline-block w-4 h-4 mr-2 align-middle " />
                        <span className={"align-middle "}>Ubah</span>
                      </button>
                    ) : undefined}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default LembagaPenyalur;
