import React from "react";
import _ from "lodash";
import { HeaderLabel, HeaderNoAuth } from "components";
import { motion } from "framer-motion";
import background from '../../assets/backgrounds/Background.png';
import { RiFile2Line } from "react-icons/ri";

const RegistrationTermsAndConditions = ({ children, sidebar }) => {

  return (
    <div className="w-full bg-[#FCFCFD]">
      <div className="flex flex-col h-screen">
        <img src={background} className="absolute z-0 w-full"></img>
        <HeaderNoAuth bg={false}/>
        <div className="md:px-28 flex flex-row flex-1 py-5">
          <div className="flex-1 ">
            <motion.main
              className="flex-1 paragraph pl-10 mb-4"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
                <span className="text-white font-semibold text-[32px] ">
                Selamat Datang di Portal BDPLH
                </span>
                <div className="pb-10"/>
                <div className="w-full space-y-6 bg-white  rounded-3xl border p-10 mb-6">
                    <div className="flex">
                        <HeaderLabel
                            text = 'syarat Dan ketentuan registrasi LEMTARA'
                        />
                    </div>
                    <div className="flex flex-col">
                        <span>
                            Data Jaminan
                        </span>
                        <div className="border p-4 rounded-2xl">
                            <div className="flex gap-4">
                                <div>
                                    <div className="bg-[#EEFFF4] rounded-full p-4">
                                        <RiFile2Line className="text-[#00BD52]"/>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <span className="font-medium">
                                        Syarat dan Ketentuan.pdf
                                    </span>
                                    <span>
                                        100 mb
                                    </span>
                                    <span className="font-semibold text-[#00BD52]">
                                        Download
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-b"/>
                    <div className="flex flex-col">
                        <span className="font-semibold text-[#01A24A]">
                            BAGIAN PERTAMA : TENTANG SYARAT DAN KETENTUAN UMUM
                        </span>
                        <div className="py-2"/>
                        <span className="font-semibold">
                            PASAL 1: DEFINISI
                        </span>
                        <div className="py-4">
                            <span>Kecuali secara tegas ditentukan lain dalam hubungan kalimat di dalam Ketentuan ini, istilah-istilah yang digunakan dalam Ketentuan dan/atau KONTRAK ini mempunyai arti sebagai berikut:
                            <ol className="p-5" style={{listStyle:'decimal'}}>
                                <li key={1}>
                                    "Electronic Data Capture (Ec)" adalah terminal atau peralatan khusus yang umumnya digunakan pada ekosistem MERCHANT yang berfungsi untuk membaca dan memproses data elektronik Transaksi pembayaran.
                                </li>
                                <li key={2}>
                                    "Fasilitas" adalah alat atau teknologi yang disediakan oleh PARA PIHAK atau salah satu PIHAK yang berguna untuk melakukan transaksi menggunakan MyPertamina termasuk namun tidak terbatas pada EDC, QR Code, NFC dan/atau fasilitas lain yang berkembang dari waktu ke waktu.
                                </li>
                                <li key={3}>
                                    "Formulir Aplikasi MERCHANT MyPertamina" adalah formulir berisi data diri MERCHANT dan kesepakatan persetujuan terkait kerja sama Kegiatan ini dan
                                </li>
                            </ol>
                            </span>
                        </div>
                        <div className="bg-[#EEFFF4] p-6 flex gap-4 rounded-lg">
                            <div className="topping">
                                <input type="checkbox" id="topping" name="topping" value="Paneer" />
                            </div>
                            <span>
                                Saya memahami syarat dan ketentuan ini
                            </span>
                        </div>
                    </div>
                    <div className="border-b"/>
                    <div className="flex gap-4 items-end justify-end">
                        <button className="p-4 border rounded-lg">Kembali</button>
                        <button className="p-4 text-white bg-[#00BD52] rounded-lg">Mulai Mendaftar</button>
                    </div>
                </div>
            </motion.main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationTermsAndConditions;
