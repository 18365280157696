import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  url_file_completion_report: Yup.mixed().required("laporan wajib diisi"),
  description_completion_report: Yup.string().required("deskripsi wajib diisi"),
  url_file_cooperative_contract: Yup.mixed().required("laporan wajib diisi"),
  description_cooperative_contract: Yup.string().required(
    "deskripsi wajib diisi"
  ),
  is_running_mechanism_procurement: Yup.boolean().required("wajib diisi"),
  is_any_sop: Yup.boolean().required("wajib diisi"),
  custom_option_minimum_value_procurement: Yup.string(),

  url_file_sop: Yup.mixed().when("is_any_sop", {
    is: true,
    then: () => Yup.mixed().required("wajib diisi"),
    otherwise: () => Yup.mixed().nullable(),
  }),
  description_sop: Yup.string().when("is_any_sop", {
    is: true,
    then: () => Yup.string().required("wajib diisi"),
    otherwise: () => Yup.string().nullable(),
  }),

  formulate_strategic_plan: Yup.mixed().required("wajib diisi"),
  minimum_value_procurement: Yup.string().when(
    "custom_option_minimum_value_procurement",
    {
      is: (val) => val == null,
      then: (e) => e.required("wajib diisi"),
      otherwise: (e) => e,
    }
  ),
  value_implementation_procurement: Yup.number()
    .oneOf([1, 2, 3, 4, 5])
    .required("wajib diisi"),

  // Validate the structure_member array of objects
  structure_member: Yup.array().of(
    Yup.object().shape({
      position: Yup.string().required("wajib diisi"),
      total_member: Yup.number().required("wajib diisi"),
    })
  ),

  procurement_experience: Yup.array().of(
    Yup.object().shape({
      job_name: Yup.string().required("wajib diisi"),
      location: Yup.string().required("wajib diisi"),
      employer_name: Yup.string().required("wajib diisi"),
      employer_address: Yup.string().required("wajib diisi"),
      no_contract: Yup.string().required("wajib diisi"),
      date_contract: Yup.date().required("wajib diisi"),
      value_contract: Yup.string().required("wajib diisi"),
      end_date_contract: Yup.date().required("wajib diisi"),
      end_date_bast: Yup.date().required("wajib diisi"),
    })
  ),

  description_cooperative_contract: Yup.string().required("wajib diisi"),
  description_completion_report: Yup.string().required("wajib diisi"),
});

export default validationSchema;
