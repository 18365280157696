import { useMutation, useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "helpers";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  getExperienceProgramme,
  submitExperienceProgramme,
} from "services/danaProgram/experienceProgramme";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ToastContext } from "components/atoms/Toast";
import { useNavigate } from "react-router";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import { getThematicProgrammes } from "services/danaProgram/masterDataService";

const useExperienceProgramme = () => {
  const [temp, setTemp] = useState();
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const [confirmDraftModal, setConfirmDraftModal] = useState(false);
  const [experienceProgramDraftData, setExperienceProgramDraftData] =
    useState();
  const { showToastMessage } = useContext(ToastContext);
  const navigate = useNavigate();

  const userId = localStorage.getItem(`userId`);

  const validationSchema = useMemo(() => {
    return yup.object({
      programme: yup.array().of(
        yup.object().shape({
          name: yup.string().required(`Nama program wajib diisi`),
          desc: yup.string().required(`Deskripsi wajib diisi`),
          program_relevance: yup
            .string()
            .required("Relevansi program wajib diisi"),
          year: yup.mixed().required("Tahun pelaksanaan wajib diisi"),
          execution_duration: yup
            .string()
            .required(`Durasi Pelaksanaan wajib diisi`),
          donor_name: yup.string().required(`Nama Donor wajib diisi`),
          contract_value: yup
            .string()
            .matches(/^[0-9.]+$/, "harus diisi menggunakan angka")
            .required(`Nilai Kontrak wajib diisi`),
          document_contract: yup.mixed(),
          is_audit: yup.boolean().required("Kap Audit wajib di pilih"),
          auditor: yup.string().when("is_audit", {
            is: true,
            then: () =>
              yup.string().required(`Kap yang mengaudit wajib di pilih`),
            otherwise: () => yup.string(),
          }),
          result_audit: yup.number().when("is_audit", {
            is: true,
            then: () =>
              yup
                .string()
                .matches(/^[0-9]*$/, "Hasil penilaian audit tidak valid")
                .required(`Hasil Penilaian Audit wajib diisi`),
            otherwise: () => yup.string().nullable(),
          }),
          document_audit: yup.mixed(),
          report_programme: yup.mixed(),
          desc_report_programme: yup
            .string()
            .required("Uraian Singkat Dokumen Program wajib diisi"),
        })
      ),
      // supporting_documents: yup.array().of(
      //   yup.object().shape({
      //     flag: yup.string().required("Wajib diisi"),
      //     path: yup.mixed(),
      //     desc: yup.string().required("Wajib diisi"),
      //   })
      // ),
    });
  });

  const {
    data: experienceProgrammeData,
    isLoading: isLoadingExperienceProgrammeData,
    isFetching: isFetchingExperienceProgrammeData,
    refetch: refetchExperienceProgrammeData,
  } = useQueryExperienceProgramme();

  const {
    data: ThematicProgramsOption,
    isLoading: isLoadingThematicProgramsOption,
    isFetching: isFetchingThematicProgramsOption,
    refetch: refetchThematicProgramsOption,
  } = GetThematicPrograms();

  let defaultValue = {
    desc: "",
    year: "",
    execution_duration: "",
    donor_name: "",
    document_contract: "",
    contract_value: "",
    is_audit: "",
    auditor: "",
    result_audit: null,
    document_audit: "",
    report_programme: "",
    desc_report_programme: "",
    name: "",
    program_relevance: "",
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      user_id: userId,
      supporting_documents: [
        {
          flag: `sop`,
          path: ``,
          desc: `desc`,
        },
        {
          flag: `sop-monitoring`,
          path: ``,
          desc: `desc`,
        },
        {
          flag: `mechanism-management`,
          path: ``,
          desc: `desc`,
        },
        {
          flag: `strategy_partner_document`,
          path: ``,
          desc: `desc`,
        },
      ],
    },
  });

  const { control, setValue, reset, trigger, clearErrors } = methods;

  const formMutation = useFormExperienceProgrammeMutation(reset);

  const {
    fields: programFields,
    append: appendPrograms,
    remove: removePrograms,
  } = useFieldArray({
    control,
    name: "programme",
  });

  const {
    fields: supportingDocumentsFields,
    append: appendSupportingDocumentsFields,
    remove: removeSupportingDocumentsFields,
  } = useFieldArray({
    control,
    name: "supporting_documents",
  });

  useEffect(() => {
    if (programFields.length < 1) {
      setValue("programme", [defaultValue]);
    }
  }, [programFields]);

  useEffect(() => {
    const flatData = experienceProgrammeData?.data?.data;
    if (![undefined, null].includes(flatData)) {
      setExperienceProgramDraftData(flatData);
      if (Object.keys(flatData).length) {
        Object.keys(flatData).forEach((key) => {
          if (typeof flatData[key] === "object") {
            onLoadFile.mutate({ data: flatData[key], key: key, array: true });
          } else {
            setValue(key, flatData[key]);
          }
        });
      }
      trigger();
    }
  }, [experienceProgrammeData, setValue, methods]);

  const consumeAPI = async (data, statusDocs) => {
    const {
      status,
      _id,
      created_at,
      updated_at,
      supporting_documents,
      programme,
      ...rest
    } = data;
    let params = {
      ...rest,
      supporting_documents: supporting_documents.map((e) => {
        return {
          flag: e.flag,
          path: e.path.id,
          desc: "desc",
        };
      }),
      programme: programme.map((e) => {
        return {
          _id: e._id,
          desc: e.desc,
          year: e.year,
          execution_duration: e.execution_duration,
          donor_name: e.donor_name,
          document_contract: e.document_contract.id,
          contract_value: e.contract_value,
          is_audit: e.is_audit,
          auditor: e.auditor,
          result_audit: e.result_audit,
          document_audit: e.document_audit.id,
          report_programme: e.report_programme.id,
          desc_report_programme: e.desc_report_programme,
          name: e.name,
          program_relevance: e.program_relevance,
        };
      }),
      status: statusDocs,
    };
    const res = await formMutation.mutateAsync(params);
    return res;
  };

  const onDraftSubmit = async (data) => {
    setTemp(data);
    setConfirmDraftModal(true);
  };

  const onBack = async (data) => {
    setTemp(data);
    setConfirmBackModal(true);
  };

  const onSubmit = async (data) => {
    setTemp(data);
    setConfirmModal(true);
  };

  const onUploadFile = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      let temp = {
        url: response?.data?.data?.url,
        id: response?.data?.data?.id,
        type: response?.data?.data?.mimeType,
        size: Math.round(parseInt(response?.data?.data?.size) / 100),
        name: response?.data?.data?.name,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const onLoadFile = useMutation(async ({ data, key, array = false }) => {
    try {
      let newResult = [];
      if (array) {
        if (key === "supporting_documents") {
          for (const p in data) {
            let pathResult = await getFile(data[p]["path"]);
            pathResult = {
              url: pathResult?.data?.data?.url,
              id: pathResult?.data?.data?.id,
              size: Math.round(parseInt(pathResult?.data?.data?.size) / 1000),
              type: pathResult?.data?.data?.mimeType,
              name: pathResult?.data?.data?.name,
            };
            newResult.push({
              flag: data[p].flag,
              path: pathResult,
              desc: data[p].desc,
            });
            setValue("supporting_documents", newResult);
          }
        } else if (key === "programme") {
          for (const p in data) {
            if (data[p]) {
              if (data[p].document_contract) {
                var documentContractResult = await getFile(
                  data[p]?.document_contract
                );
                documentContractResult = {
                  url: documentContractResult?.data?.data?.url,
                  id: documentContractResult?.data?.data?.id,
                  size: Math.round(
                    parseInt(documentContractResult?.data?.data?.size) / 1000
                  ),
                  type: documentContractResult?.data?.data?.mimeType,
                  name: documentContractResult?.data?.data?.name,
                };
              }

              if (data[p].document_audit) {
                var documentAuditResult = await getFile(
                  data[p]?.document_audit
                );
                documentAuditResult = {
                  url: documentAuditResult?.data?.data?.url,
                  id: documentAuditResult?.data?.data?.id,
                  type: documentAuditResult?.data?.data?.mimeType,
                  name: documentAuditResult?.data?.data?.name,

                  size: Math.round(
                    parseInt(documentAuditResult?.data?.data?.size) / 1000
                  ),
                };
              }
              if (data[p].report_programme) {
                var reportProgrammeResult = await getFile(
                  data[p]?.report_programme
                );
                reportProgrammeResult = {
                  url: reportProgrammeResult?.data?.data?.url,
                  id: reportProgrammeResult?.data?.data?.id,
                  type: reportProgrammeResult?.data?.data?.mimeType,
                  name: reportProgrammeResult?.data?.data?.name,
                  size: Math.round(
                    parseInt(reportProgrammeResult?.data?.data?.size) / 1000
                  ),
                };
              }
              let temp = {
                _id: data[p]["_id"] ?? "",
                desc: data[p]["desc"] ?? "",
                year: Array.isArray(data[p]["year"]) && data[p]["year"].length === 2 ? [
                  new Date(data[p]["year"][0]),
                  new Date(data[p]["year"][1]),
                ] : "",
                execution_duration: data[p]["execution_duration"] ?? "",
                donor_name: data[p]["donor_name"] ?? "",
                document_contract: documentContractResult ?? "",
                contract_value: data[p]["contract_value"] ?? "",
                is_audit: data[p]["is_audit"] ?? "",
                auditor: data[p]["auditor"] ?? "",
                result_audit: data[p]["result_audit"] ?? "",
                document_audit: documentAuditResult ?? "",
                report_programme: reportProgrammeResult ?? "",
                desc_report_programme: data[p]["desc_report_programme"] ?? "",
                name: data[p]["name"] ?? "",
                program_relevance: data[p]["program_relevance"] ?? "",
              };

              if (
                programFields[0].document_audit === defaultValue.document_audit
              ) {
                removePrograms(0);
              }
              appendPrograms(temp);

              trigger();
            }
          }
        }
      } else {
        let reportResult = await getFile(data);
        reportResult = {
          url: reportResult?.data?.result?.url,
          id: reportResult?.data?.result?.file?._id,
          type: reportResult?.data?.result?.file?.mimeType,
          size: Math.round(
            parseInt(reportResult?.data?.result?.file?.size) / 1000
          ),
          name: reportResult?.data?.result?.file?.name,
        };
        newResult = reportResult;
        setValue(key, newResult);
      }
    } catch (error) {
      // throw error;
    }
  });

  const onSubmitConfirmModal = async () => {
    const response = await consumeAPI(temp, "SUBMITTED");
    if (response.status === 200) {
      setConfirmModal(false);
      navigate("/penilaian-lemtara");
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      );
    }
  };

  const onSubmitConfirmBackModal = async () => {
    const response = await consumeAPI(temp, "DRAFTED");
    if (response.status === 200) {
      setConfirmBackModal(false);
      navigate("/penilaian-lemtara");
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      );
    }
  };

  const onSubmitConfirmDraftModal = async () => {
    const response = await consumeAPI(temp, "DRAFTED");
    if (response.status === 200) {
      setConfirmDraftModal(false);
      navigate("/penilaian-lemtara");
      showToastMessage(
        "Data Berhasil Disimpan!",
        "Data yang anda masukan telah berhasil disimpan."
      );
    }
  };

  return {
    ...methods,
    onDraftSubmit,
    onBack,
    onSubmit,
    onSubmitConfirmModal,
    onSubmitConfirmBackModal,
    onSubmitConfirmDraftModal,
    programFields,
    appendPrograms,
    removePrograms,
    supportingDocumentsFields,
    appendSupportingDocumentsFields,
    removeSupportingDocumentsFields,
    methods,
    temp,
    setTemp,
    consumeAPI,
    dispatch,
    confirmModal,
    setConfirmModal,
    confirmBackModal,
    setConfirmBackModal,
    confirmDraftModal,
    setConfirmDraftModal,
    experienceProgramDraftData,
    setExperienceProgramDraftData,
    experienceProgrammeData,
    isLoadingExperienceProgrammeData,
    isFetchingExperienceProgrammeData,
    refetchExperienceProgrammeData,
    onUploadFile,
    ThematicProgramsOption,
    refetchThematicProgramsOption,
    isFetchingThematicProgramsOption,
    isLoadingThematicProgramsOption,
  };
};

export const useQueryExperienceProgramme = (userId) => {
  return useQuery(
    ["list-experience-programme"],
    () => getExperienceProgramme(userId),
    {
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        });
      },
      refetchOnWindowFocus: false,
    }
  );
};

export const useFormExperienceProgrammeMutation = (reset) => {
  return useMutation(async (payload) => submitExperienceProgramme(payload), {
    onSuccess: () => {
      // enqueueSnackbar({
      //   message: "Success",
      //   variant: "success",
      // });
      // reset();
    },
  });
};
export const GetThematicPrograms = () => {
  return useQuery(["list-thematic-programme"], () => getThematicProgrammes(), {
    refetchOnWindowFocus: false,
  });
};

export default useExperienceProgramme;
