import CardForm from "components/molecules/CardForm";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import { useFormContext, useWatch } from "react-hook-form";
import { useLocation } from "react-router";
import { FieldLabel } from "../validation-schema";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import SelectFDBZipCode from "components/organisms/inputs/SelectFDBZipCode";
import { InputForm } from "components";
import GeotagLocationButton from "components/atoms/Button/GeotagLocationButton";
import AtomDatePicker from "components/atoms/Datepicker";
import { useEffect, useState } from "react";

export const Alamat = ({ data }) => {
  const location = useLocation();
  const isView = location.pathname.includes("view");

  const [checked, setChecked] = useState();

  const methods = useFormContext();

  const cidProvinceId = useWatch({
    control: methods.control,
    name: `province_id`,
  });
  const cidCityId = useWatch({
    control: methods.control,
    name: `city_id`,
  });
  const cidDistrictId = useWatch({
    control: methods.control,
    name: `district_id`,
  });
  const cidVillageId = useWatch({
    control: methods.control,
    name: `village_id`,
  });
  const zipField = useWatch({
    control: methods.control,
    name: `zip_code_id`,
  });
  const rtField = useWatch({
    control: methods.control,
    name: `rt`,
  });
  const rwField = useWatch({
    control: methods.control,
    name: `rw`,
  });
  const addressField = useWatch({
    control: methods.control,
    name: `address`,
  });

  const livingProvinceId = useWatch({
    control: methods.control,
    name: `domicile_province_id`,
  });
  const livingCityId = useWatch({
    control: methods.control,
    name: `domicile_city_id`,
  });
  const livingDistrictId = useWatch({
    control: methods.control,
    name: `domicile_district_id`,
  });
  const livingVillageId = useWatch({
    control: methods.control,
    name: `domicile_village_id`,
  });

  const onGeotagClick = (key) => (value) => {
    console.log(value.latitude);
    methods.setValue(`latitude`, `${value.latitude}`);
    methods.setValue(`longitude`, `${value.longitude}`);
  };

  const onSameAddressChange = (e) => {
    setChecked(e.target.value);
    const isChecked = e?.target?.checked;
    const { setValue, getValues } = methods || {};

    if (isChecked) {
      const values = getValues();
      setValue(`domicile_province_id`, values?.province_id);
      setValue(`domicile_city_id`, values?.city_id);
      setValue(`domicile_district_id`, values?.district_id);
      setValue(`domicile_village_id`, values?.village_id);
      setValue(`domicile_zip_code_id`, values?.zip_code_id);
      setValue(`domicile_address`, values?.address);
      setValue(`domicile_rt`, values?.rt);
      setValue(`domicile_rw`, values?.rw);

      // methods.clearErrors(`${prefix}living`);
    }
  };

  useEffect(() => {
    if (checked) {
      methods.setValue(`domicile_province_id`, cidProvinceId);
    }
  }, [cidProvinceId, methods, checked]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`domicile_city_id`, cidCityId);
    }
  }, [checked, cidCityId, methods]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`domicile_district_id`, cidDistrictId);
    }
  }, [checked, cidDistrictId, methods]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`domicile_village_id`, cidVillageId);
    }
  }, [checked, cidVillageId, methods]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`domicile_zip_code_id`, zipField);
    }
  }, [checked, zipField, methods]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`domicile_rt`, rtField);
    }
  }, [checked, methods, rtField]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`domicile_rw`, rwField);
    }
  }, [checked, methods, rwField]);

  useEffect(() => {
    if (checked) {
      methods.setValue(`domicile_address`, addressField);
    }
  }, [checked, methods, addressField]);

  return (
    <CardForm label="Alamat">
      <div className={"font-lg font-semibold text-[#01A24A]"}>Alamat KTP</div>

      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.PROVINCE}
            </label>
            <p>{data?.province?.name}</p>
          </div>
        ) : (
          <SelectFDBProvince
            controllerName={`province_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.PROVINCE}
            placeholder={FieldLabel.PROVINCE}
            required={true}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">{FieldLabel.CITY}</label>
            <p>{data?.city?.name}</p>
          </div>
        ) : (
          <SelectFDBCity
            controllerName={`city_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.CITY}
            placeholder={FieldLabel.CITY}
            required={true}
            provinceId={cidProvinceId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.DISTRICT}
            </label>
            <p>{data?.district?.name}</p>
          </div>
        ) : (
          <SelectFDBDistrict
            controllerName={`district_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.DISTRICT}
            placeholder={FieldLabel.DISTRICT}
            required={true}
            cityId={cidCityId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.SUBDISTRICT}
            </label>
            <p>{data?.district?.name}</p>
          </div>
        ) : (
          <SelectFDBVillage
            controllerName={`village_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.SUBDISTRICT}
            placeholder={FieldLabel.SUBDISTRICT}
            required={true}
            districtId={cidDistrictId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.ZIPCODE}
            </label>
            <p>{data?.zip_code?.zip_code}</p>
          </div>
        ) : (
          <SelectFDBZipCode
            controllerName={`zip_code_id`}
            label={FieldLabel.ZIPCODE}
            placeholder={FieldLabel.ZIPCODE}
            required
            returnObject={false}
            villageId={cidVillageId}
            asText={isView}
          />
        )}

        <InputForm
          controllerName={`rt`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RT}
          placeholder={FieldLabel.RT}
          required
          asText={isView}
        />
        <InputForm
          controllerName={`rw`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RW}
          placeholder={FieldLabel.RW}
          required
          asText={isView}
        />

        <InputForm
          controllerName={`address`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.ADDRESS}
          placeholder={FieldLabel.ADDRESS}
          rows={4}
          textArea
          required
          asText={isView}
        />
      </div>

      <div className="border-b" />

      <div className={"flex flex-wrap items-center gap-4"}>
        <div className={"font-lg font-semibold text-[#01A24A]"}>
          Alamat Domisili
        </div>

        {!isView ? (
          <label
            className={"bg-[#EEFFF4] p-3 flex gap-3 items-start rounded-lg"}
          >
            <input
              className={"h-[1em] w-[1rem] my-[0.2em]"}
              type={"checkbox"}
              onChange={onSameAddressChange}
            />
            <span>Alamat Sama Dengan KTP</span>
          </label>
        ) : undefined}
      </div>

      <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.PROVINCE}
            </label>
            <p>{data?.domicile_province?.name}</p>
          </div>
        ) : (
          <SelectFDBProvince
            controllerName={`domicile_province_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.PROVINCE}
            placeholder={FieldLabel.PROVINCE}
            required={true}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">{FieldLabel.CITY}</label>
            <p>{data?.domicile_city?.name}</p>
          </div>
        ) : (
          <SelectFDBCity
            controllerName={`domicile_city_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.CITY}
            placeholder={FieldLabel.CITY}
            required={true}
            provinceId={livingProvinceId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.DISTRICT}
            </label>
            <p>{data?.domicile_district?.name}</p>
          </div>
        ) : (
          <SelectFDBDistrict
            controllerName={`domicile_district_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.DISTRICT}
            placeholder={FieldLabel.DISTRICT}
            required={true}
            cityId={livingCityId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.SUBDISTRICT}
            </label>
            <p>{data?.domicile_village?.name}</p>
          </div>
        ) : (
          <SelectFDBVillage
            controllerName={`domicile_village_id`}
            wrapperClass={"col-span-1"}
            label={FieldLabel.SUBDISTRICT}
            placeholder={FieldLabel.SUBDISTRICT}
            required={true}
            districtId={livingDistrictId}
            returnObject={false}
            asText={isView}
          />
        )}

        {isView ? (
          <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold">
              {FieldLabel.ZIPCODE}
            </label>
            <p>{data?.domicile_zip_code?.zip_code}</p>
          </div>
        ) : (
          <SelectFDBZipCode
            controllerName={`domicile_zip_code_id`}
            label={FieldLabel.ZIPCODE}
            placeholder={FieldLabel.ZIPCODE}
            required
            returnObject={false}
            villageId={livingVillageId}
            asText={isView}
          />
        )}

        <InputForm
          controllerName={`domicile_rt`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RT}
          placeholder={FieldLabel.RT}
          required
          asText={isView}
        />
        <InputForm
          controllerName={`domicile_rw`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.RW}
          placeholder={FieldLabel.RW}
          required
          asText={isView}
        />

        <InputForm
          controllerName={`domicile_address`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.ADDRESS}
          placeholder={FieldLabel.ADDRESS}
          rows={4}
          textArea
          required
          asText={isView}
        />

        {!isView ? (
          <GeotagLocationButton
            label={FieldLabel.GEOTAG}
            className={"w-full"}
            onSuccess={onGeotagClick()}
          />
        ) : undefined}

        <InputForm
          controllerName={`latitude`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.LAT}
          placeholder={FieldLabel.LAT}
          required
          disabled
          readOnly
          asText={isView}
        />

        <InputForm
          controllerName={`longitude`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-1"}
          label={FieldLabel.LNG}
          placeholder={FieldLabel.LNG}
          required
          disabled
          readOnly
          asText={isView}
        />

        <AtomDatePicker
          controllerName={`domicile_since_year`}
          className={`py-2 px-4 mt-2 border w-full rounded-md`}
          wrapperClass={"col-span-2"}
          label={FieldLabel.LIVE_SINCE}
          placeholder={FieldLabel.LIVE_SINCE}
          datepickerOptions={{
            dateFormat: "yyyy",
            maxDate: new Date(),
            showYearPicker: true,
          }}
          required
          showErrorLabel
          asText={isView}
        />
      </div>
    </CardForm>
  );
};
