import { config_fdb, config_fdb_v2, general_config } from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

// DASHBOARD

// AUTH REGIS
export const getGroupAccount = (userId) => {
  return axios.get(`/v1/group-account/${userId}`);
};

export const getGroupAccountDraft = (userId) => {
  return axios.get(`/v1/group-account/${userId}`);
};

// REGISTRATION

const header = {
  accept: "application/json",
  "Content-Type": "multipart/form-data",
};
export const registerAccGroup = (params) => {
  return axios.post(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/register`,
    params,
    {
      header,
    }
  );
};
export const getAccGroup = (userId) => {
  return axios.get(`${config_fdb.BASE_URL}/v1/group-profile/${userId}`);
};

export const getAccGroupDraft = (userId) => {
  return axios.get(`/api/v1/group-account/draft/${userId}`);
};

// REGIS FORM CONTD. SOCIAL FORESTRY
export const getRegisPerhutananSosialDraft = (userId) => {
  return axios.get(`/api/v1/group-account/draft/${userId}`);
};

export const putRegisPerhutananSosialDraft = (userId, data) => {
  return axios.put(`/api/v1/group-account/social-forestry/draft`, data);
};

export const putRegisPerhutananSosial = (userId, params) => {
  return axios.put(`/api/v1/group-account/social-forestry`, params);
};

// REGIS FORM CONTD. NON SOCIAL FORESTRY

export const getRegisNonPerhutananSosialDraft = (token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return axios.get(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/non-perhutanan-sosial`,
    config
  );
};

export const getRegisNonPerhutananSosialAdditionalDocsDraft = (userId) => {
  return axios.get(
    `${config_fdb.BASE_URL}/v1/group-profile/other-document/${userId}`
  );
};

export const putRegisNonPerhutananSosialDraft = (token, params) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return axios.put(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/non-perhutanan-sosial`,
    params,
    config
  );
};
export const putRegisNonPerhutananSosialSubmit = (userId, params) => {
  return axios.put(
    `${config_fdb.BASE_URL}/v1/group-profile/non-social-forestry/${userId}`,
    params
  );
};

export const postFileServices = (file) => {
  return axiosInstance.post(
    `${general_config.BASE_URL}/file-service/v1/files`,
    file
  );
};

export const postRegisNonPerhutananSosiaAdditionalDocslDraft = (data) => {
  return axios.post(`/api/v1/group-account/other-document/draft`, data);
};
export const deleteRegisNonPerhutananSosialAdditionalDocs = (docId) => {
  return axios.delete(`/api/v1/group-account/other-document/${docId}`);
};

// PENGAJUAN ANGGOTA

export const getPengajuanAnggota = (params) => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/submission/list`,
    { params }
  );
};
export const postPengajuanAnggota = (data) => {
  return axiosInstance.post(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/create-applicant-member`,
    data
  );
};

export const getPengajuanAnggotaMembers = (applicationId) => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/submission/detail/${applicationId}`
  );
};

export const getPengajuanAnggotaMembersDraft = (applicationId) => {
  return axios.get(`/api/v1/application/${applicationId}/member-draft`);
};

export const submitPengajuanAnggota = (id) => {
  return axiosInstance.put(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/submission/submit/${id}`
  );
};

export const getPengajuanAnggotaMemberDetail = (applicationMemberId) => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/${applicationMemberId}`
  );
};

export const getPengajuanAnggotaMemberDetailDraft = (applicationMemberId) => {
  return axios.get(`/api/v1/application/member/draft/${applicationMemberId}`);
};

export const postPengajuanAnggotaMembers = (data) => {
  return axios.get(`/api/v1/application/member`, data);
};

// PENGAJUAN ANGGOTA CONT. PERHUTANAN SOSIAL

// export const putPengajuanAnggotaMemberPerhutananSosial = (
//   applicationMemberId,
//   data
// ) => {
//   return axios.put(
//     `/api/v1/application/member/social-forestry/${applicationMemberId}`,
//     data
//   );
// };

export const putPengajuanAnggotaMemberPerhutananSosialDraft = (
  applicationMemberId,
  data
) => {
  return axios.put(
    `${config_fdb.BASE_URL}/v1/application/member/social-forestry/draft/${applicationMemberId}`,
    data
  );
};

// PENGAJUAN ANGGOTA CONT. NON PERHUTANAN SOSIAL

export const registerDebtur = (data, isDraft, applicantId) => {
  return axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/register?is_draft=${isDraft}&applicant_member_id=${applicantId}&is_non_forestry=true`,
    data
  );
};

export const registerDebturPS = (data, isDraft, applicantId) => {
  return axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/register?is_draft=${isDraft}&applicant_member_id=${applicantId}&is_non_forestry=false`,
    data
  );
};

export const putPengajuanAnggotaMemberPerhutananSosial = (data, params) => {
  return axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/register`,
    data,
    { params }
  );
};

export const putPengajuanAnggotaMemberNonPerhutananSosial = (
  data,
  is_draft,
  group_profile_member_id,
  applicantId
) => {
  const member = group_profile_member_id
    ? `&group_profile_member_id=${group_profile_member_id}`
    : "";
  return axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/register?is_draft=${is_draft}&applicant_member_id=${applicantId}&is_non_forestry=true${member}`,
    data
  );
};

export const putPengajuanAnggotaMemberNonPerhutananSosialDebitur = ({
  data,
  is_draft,
  group_profile_member_id,
}) => {
  console.log(is_draft);
  const member = group_profile_member_id
    ? `&group_profile_member_id=${group_profile_member_id}`
    : "";
  return axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/completely-member?is_draft=${is_draft}&is_non_forestry=true${member}`,
    data
  );
};

export const groupProfileMemberRemoveIncomeInfo = (id) => {
  return axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/remove-income-info`,
    { params: { id } }
  );
};

export const groupProfileMemberRemoveDocument = (id) => {
  return axiosInstance.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile-member/remove-document`,
    { params: { id } }
  );
};

export const putPengajuanAnggotaMemberNonPerhutananSosialDraft = (
  applicationMemberId,
  data
) => {
  return axios.put(
    `${config_fdb.BASE_URL}/v1/member-application/non-social-forestry/draft/${applicationMemberId}`,
    data
  );
};

export const getPengajuanAnggotaMemberNonPerhutananSosialAdditionalDocs = (
  application_member_id
) => {
  return axios.get(
    `/api/v1/application/member/other-document/${application_member_id}`
  );
};

export const getPengajuanAnggotaMemberNonPerhutananSosialAdditionalDocsDraft = (
  application_member_id
) => {
  return axios.get(
    `/api/v1/application/member/other-document/draft/${application_member_id}`
  );
};
export const getPartner = (userId) => {
  return axios.get(`${config_fdb.BASE_URL}/v1/group-profile/partner/${userId}`);
};

export const postPengajuanAnggotaMemberNonPerhutananSosialAdditionalDocs = (
  data
) => {
  return axios.post(`/api/v1/application/member/other-document`, data);
};

export const deletePengajuanAnggotaMemberNonPerhutananSosialAdditionalDocs = (
  docId
) => {
  return axios.delete(`/api/v1/application/member/other-document/${docId}`);
};

export const getPengajuanAnggotaKelompok = ({ params }) => {
  return axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/request`, {
    params,
  });
};

export const updateServiceType = (id, data) => {
  return axiosInstance.put(`${config_fdb_v2.BASE_URL}/v1/request/${id}`, data);
};

export const saveDraftService = (
  isDraft,
  applicantId,
  requestId,
  deferredId,
  data
) => {
  return axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/request/send?is_draft=${
      isDraft ?? true
    }&applicant_member_id=${applicantId}&request_id=${requestId}&deferred_id=${deferredId}`,
    data
  );
};

export const registerServiceForm = (isDraft, applicantId, requestId, data) => {
  return axiosInstance.patch(
    `${config_fdb_v2.BASE_URL}/v1/request/send?is_draft=${
      isDraft ?? true
    }&applicant_member_id=${applicantId}&request_id=${requestId}`,
    data
  );
};

export const getDetailDeffered = (id) => {
  return axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/request/deferred-detail/${id}`
  );
};

export const deleteDocument = (userId, itemId) => {
  return axios.delete(
    `${config_fdb_v2.BASE_URL}/v1/group-profile/active-submission/delete-document/${itemId}`,
    {
      headers: {
        Authorization: `Bearer ${userId}`,
      },
    }
  );
};
