import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { Button, Table } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { useEffect, useMemo, useState } from "react";
import { RiEyeLine, RiFile3Line, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getDisbursementrList } from "services/fdb/disbursement";

const statusBadge = {
  "Belum Diajukan": "bg-[#FFFAEB] text-[#B54708]",
  "Sedang Direview": "bg-[#FFFAEB] text-[#B54708]",
  "Sedang Proses Pencairan": "bg-[#FFFAEB] text-[#B54708]",
  Tersalurkan: "bg-[#ECFDF3] text-[#1C7D44]",
};

const PermohonanPencairan = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    filter: {
      keyword: "",
    },
  });

  const handleChangeLimit = (e) => {
    setFilters((prev) => ({ ...prev, limit: e, page: 1 }));
  };

  const handleChangePage = (e) => {
    setFilters((prev) => ({ ...prev, page: e }));
  };

  const { data } = useQuery(
    ["GET_DISBURSEMENT_LIST", filters],
    getDisbursementrList,
    {
      select: (data) => data?.data?.data,
    }
  );

  const breadcrumbs = useMemo(
    () => [
      {
        label: "Perohonan Pencairan",
        path: "#",
      },
    ],
    []
  );

  const paginator = useMemo(() => {
    return {
      page: filters.page,
      limit: filters.limit,
      total: data?.meta?.total,
      previous_pages: data?.meta?.previous_page_url,
      next_pages: data?.meta?.next_page_url,
    };
  }, [data, filters]);

  const columns = useMemo(
    () => [
      {
        id: "phase",
        title: "Tahapan Pencairan",
        dataIndex: "phase",
        className: "",
        columnClassName:
          "w-[150px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        // render: (value) => dayjs(value.created_at).format("DD MMM YYYY"),
      },
      {
        id: "total_member",
        title: "Anggota Melakukan Pencairan Dana",
        dataIndex: "total_member",
        className: "",
        columnClassName:
          "w-[195px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => `${value} Orang`,
      },
      {
        id: "total_completed",
        title: "Anggota yg Sudah Melengkapi",
        dataIndex: "total_completed",
        className: "",
        columnClassName:
          "w-[171px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => `${value} Orang`,
      },
      {
        id: "total_uncompleted",
        title: "Anggota yg Belum Melengkapi",
        dataIndex: "total_uncompleted",
        className: "",
        columnClassName:
          "w-[195px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => `${value} Orang`,
      },
      {
        id: "status_string",
        title: "Status",
        dataIndex: "status_string",
        className: "",
        columnClassName:
          "w-[166px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => (
          <div
            className={classNames(
              "py-[2px] px-2 text-xs font-medium rounded-full w-max",
              statusBadge[value]
            )}
          >
            {value}
          </div>
        ),
      },
      {
        id: "disbursement_document",
        title: "Dokumen Penyaluran",
        dataIndex: "disbursement_document",
        className: "",
        columnClassName:
          "w-[135px] text-ellipsis overflow-hidden whitespace-break-spaces",
        sortable: true,
        render: (value) => (
          <div className="flex justify-center">
            <RiFile3Line />
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "id",
        className: "",
        sortable: true,
        columnClassName: "w-[150px] text-center text-ellipsis overflow-hidden",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <div className="flex justify-center">
              {data?.status_string === "Belum Diajukan" ? (
                <Button
                  size="sm"
                  label="Ajukan"
                  className="bg-primary-600 text-white text-sm font-semibold"
                  onClick={() =>
                    navigate(`${data?.id}/detail?status=${data?.status_string}`)
                  }
                />
              ) : (
                <Button
                  type={"button"}
                  className="p-4 bg-[transparent] rounded-full"
                  label={<RiEyeLine />}
                  onClick={() =>
                    navigate(`${data?.id}/detail?status=${data?.status_string}`)
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    [navigate]
  );

  useEffect(() => {
    const layouContentEl = document.getElementById("_layout-content");

    layouContentEl?.classList?.add("overflow-hidden");

    return () => {
      layouContentEl?.classList?.remove("overflow-hidden");
    };
  }, []);

  return (
    <section className="space-y-5">
      <div className="bg-[#FFFFFF] rounded-xl p-10">
        <div className="flex flex-col w-full">
          <BreadCrumbs routes={breadcrumbs} />
          <div className="mt-6 flex flex-row">
            <div className="flex-1">
              <div className="flex flex-col">
                <span className="font-semibold text-[24px] text-[#101828]">
                  Permohonan Pencairan
                </span>
                <span className="font-normal text-[14px] text-[#667085]"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          "rounded-2xl border border-gray-200 bg-white p-3 space-y-4 shadow-sm"
        }
      >
        <div className={"flex flex-wrap items-center gap-4"}>
          <div className="relative w-full">
            <input
              type="text"
              className="h-12 border w-full rounded-md outline-none focus:outline-none pl-[50px] pr-4 focus:ring-1 focus:ring-primary-600"
              placeholder="Cari"
            />
            <RiSearchLine
              className="absolute top-3 left-4 text-[#98A2B3]"
              size={24}
            />
          </div>
        </div>

        <Table
          bordered
          stripped
          layout="fixed"
          className="mb-4"
          columns={columns}
          dataSource={data?.data ?? []}
          // dataSource={data?.results}
          // isLoading={listLoading}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeLimit}
          pagination={paginator}
        />
      </div>
    </section>
  );
};

export default PermohonanPencairan;
