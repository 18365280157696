import React from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';

const { RiArrowDownSLine } = require('react-icons/ri');

const Disclosure = ({
  children,
  title,
  titleClass,
  contentClass,
  defaultValue = false,
  isError = false,
}) => {
  const [open, setOpen] = React.useState(defaultValue);

  React.useEffect(() => {
    setOpen(defaultValue);
  }, [defaultValue]);

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <div
        className={[
          `bg-[#E1F1D6] border px-4 py-3 flex justify-between items-center gap-4`,
          open ? 'rounded-t-lg' : 'rounded-lg',
          isError ? 'border-[#D92D20]' : 'border-[#E1F1D6]',
        ].join(' ')}
      >
        <span
          className={`text-lg text-primary-700 font-semibold ${titleClass}`}
        >
          {title}
        </span>
        <Collapsible.Trigger asChild>
          <button className="text-primary-700 text-base font-semibold flex items-center gap-2">
            Tutup
            <RiArrowDownSLine
              className={`transform text-2xl transition-transform ${
                open ? 'rotate-180' : ''
              }`}
            />
          </button>
        </Collapsible.Trigger>
      </div>
      <Collapsible.Content
        className={`px-6 py-5 border rounded-b-lg ${contentClass}`}
      >
        {children}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default Disclosure;
