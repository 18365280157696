import React from "react";
import eyeClose from "assets/icons/eyeClose.svg";

const InputPassword = React.forwardRef(
  (
    {
      isError = {
        value: false,
        message: "",
      },
      label = "",
      className,
      ...props
    },
    ref
  ) => (
    <div ref={ref} className="form-control w-full ">
      {label && (
        <label className="label">
          <span
            className={`label-text font-bold  ${
              isError?.value ? "text-error" : ""
            }`}
          >
            {label}
          </span>
        </label>
      )}

      <div className="w-full h-full relative input mt-2 border border-gray-200 rounded-md">
        <input ref={ref} {...props} className="px-4 py-2 w-full rounded-md" />
        <button
          onClick={() => console.log("ok")}
          className="absolute z-20 right-4 top-[50%]"
        >
          <img src={eyeClose} alt="" />
        </button>
      </div>

      {isError?.value && (
        <label className="label">
          <span className="label-text-alt text-red-500 text-error">
            {isError?.message}
          </span>
        </label>
      )}
    </div>
  )
);

export default InputPassword;
