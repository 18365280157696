import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import AtomSelect from "../../../atoms/Select";

const SelectStatusPendamping = React.forwardRef((props, ref) => {
  const { className, ...restProps } = props;

  const [options, setOptions] = useState([]);

  const { data } = useQuery({
    queryKey: ["komoditas-usaha-ddl"],
    queryFn: async () => {
      // const res = await getKomoditasUsahaDDL();
      // TODO: Replace this with actual API call
      const res = await new Promise((resolve) => {
        resolve({
          data: new Array(10).fill({}).map((_, idx) => {
            return {
              id: `Komoditas Usaha ${idx + 1}`,
              nama: `Komoditas Usaha ${idx + 1}`,
            };
          }),
        });
      });
      return res?.data || [];
    },
    retry: 1,
  });

  useEffect(() => {
    setOptions(
      data?.map((entry) => {
        return {
          value: entry?.id,
          label: entry?.nama,
        };
      })
    );
  }, [data]);

  return (
    <AtomSelect
      ref={ref}
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      {...restProps}
    />
  );
});

export default SelectStatusPendamping;
