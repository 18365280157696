import { Button, InputForm } from "components"
import CardForm from "components/molecules/CardForm"
import { useFormContext } from "react-hook-form"
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri"

const SusunanDireksi = ({
  directorsFields = [],
  appendDirectors = () => {},
  removeDirectors = () => {}
}) => {
  const {
    watch,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useFormContext()
  return (
    <CardForm label={"SUSUNAN DIREKSI/ PENGURUS"}>
      {directorsFields?.length > 0 &&
        directorsFields?.map((data, index) => {
          return (
            <div className="flex gap-4" key={data.id}>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`director_composition.${index}.name`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.director_composition?.index?.name ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Nama Direksi/ Pengurus"}
                  placeholder={"Nama Direksi/ Pengurus"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`director_composition.${index}.nik`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.director_composition?.index?.nik ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"NIK"}
                  placeholder={"NIK"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`director_composition.${index}.position`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md ${
                    errors?.director_composition?.index?.position ? "border-[#F04438]" : "border-green"
                  }`}
                  label={"Jabatan"}
                  placeholder={"Jabatan"}
                  required={true}
                />
              </div>
              <div className="pt-2 flex items-end">
                {index !== 0 ? (
                  <button
                    className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                    onClick={() => removeDirectors(index)}
                  >
                    <RiDeleteBin6Line />
                  </button>
                ) : (
                  <div className="w-11 h-11 p-3"></div>
                )}
              </div>
            </div>
          )
        }
      )}
      <div className="w-full flex border-[1px]">
        <div className="w-4-12 flex justify-center">
          <Button
            className="p-4 border rounded-lg"
            label={
              <div className="flex items-center gap-2 font-[14px]">
                <RiAddLine />
                Tambah Direksi / Pengurus
              </div>
            }
            onClick={() => {
              appendDirectors({
                name: "",
                nik: "",
                position: "",
              });
            }}
          />
        </div>
      </div>
    </CardForm>
  )
}

export default SusunanDireksi