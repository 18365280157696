import { config_fdb_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getDisbursementDebtorList = async ({ queryKey }) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/disbursement-debtor`,
    {
      params: queryKey[1],
    }
  );
};

export const getDisbursementDebtorDetail = async ({ queryKey }) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/disbursement-debtor/${queryKey[1]}`
  );
};

export const updateDisbursementDebtor = async ({ id, data }) => {
  return await axiosInstance.put(
    `${config_fdb_v2.BASE_URL}/v1/disbursement-debtor/${id}`,
    data
  );
};

export const getDisbursementrList = async ({ queryKey }) => {
  return await axiosInstance.get(`${config_fdb_v2.BASE_URL}/v1/disbursement`, {
    params: queryKey[1],
  });
};

export const getDisbursementDetail = async ({ queryKey }) => {
  return await axiosInstance.get(
    `${config_fdb_v2.BASE_URL}/v1/disbursement/${queryKey[1]}`
  );
};

export const updateDisbursement = async ({ id, data }) => {
  return await axiosInstance.put(
    `${config_fdb_v2.BASE_URL}/v1/disbursement/${id}`,
    data
  );
};

export const followUpDebtor = async (id) => {
  return await axiosInstance.put(
    `${config_fdb_v2.BASE_URL}/v1/disbursement/follow-up/${id}`
  );
};
